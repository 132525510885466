import React from 'react'
import {
  Accordion
} from '@trussworks/react-uswds'
import DashboardNav from '../components/DashboardNav'
import './FaqPage.css'

const FaqPage = () => {

  const accordionItems = [
    {
      title: 'About RAISE FAQs',
      content: (
        <>
        <h4 style={{paddingTop: '1em'}}>About RAISE</h4>
        <p className='faq-p'>
          <strong>What data is currently on the RAISE Platform?</strong> 
        <br />
          The following types of data are collected directly on the RAISE Platform and utilized for development of exceedances, metrics and visualization.
        </p>
        {/* <h4>Flight Data Recorder (FDR)</h4> */}
        <p className='faq-p'>
        <strong>Flight Data Recorder (FDR)</strong> data from individual operators which is received directly to RAISE via operator uploads or via Data Transfer Organizations (DTOs).  The data is brought to RAISE and indexed by operators, tail, number and date.  Individual operators can only see their own data and their own data compared against aggregate data (i.e. They see their own exceedances and metrics and benchmarks).
        </p>
        
        {/* <h4>What is the R-IAT?</h4> */}
        <p className='faq-p'>
        <strong>Aggregate Metrics & Exceedances</strong> are available from the FDR data and are aggregated in accordance with guidance from the IAT and ASIAS program office along with the applicability of the 50% rule to ensure that no individual operator data is expose when viewed in aggregate form.  
        <br />Aggregate metrics and Exceedance include:
        </p>
        <p className='faq-p'>
        <strong>Shuttle Radar Terrain Mapping (SRTM)</strong> data is utilized for ensuring that FDR data is  supplemented with AGL values for recorders that only provide MSL parameters for altitude.  The tiles for SRTM are planned for use in visualization in future RAISE releases.
        </p>
        <p className='faq-p'>
        <strong>Obstacles</strong> – r-ASIAS uses a publically available obstacle data integrate within its proximity to obstacles metric within r-ASIAS.
        </p>
        <p className='faq-p'>
        <strong>Helipads</strong> – r-ASIAS uses a publically and private helipad data (i.e. Sourced from LZ controls) for visualization of helipads, the integration of the geospatially based 50% rules and polygon around the  helipads in development of arrival and departure metrics
        </p>

        <h4 style={{paddingTop: '1em'}}>What data outside of RAISE does the r-ASIAS use for developing metrics?</h4>
        <p className='faq-p'>
        The EIM Platform is used for developing metrics for the  r-ASIAS Platform.  The FAA has an Enterprise program for collecting large quantities of FAA data and the r-ASIAS Platform leverages that to supplement FDR metrics and provide additional insight for operators and the IAT on safety factors outside of the FDR data itself.
        <br />
        Current data includes the following:
        </p>
        <p className='faq-p'>
        <strong>Corridor Integrated Weather System (CIWS)</strong> – r-ASIAS uses a proximity to Wx service to determine a standardized weather level (0 through 5) on the amount of precipitation (i.e. Viscous Integrated Liquids – VIL) at the time and latitude/longitude of a given FDR Flight
        </p>
        <p className='faq-p'>
        <strong>ADS-B & Radar (Future)</strong> – r-ASIAS is in the process of fully  implementing ADS-B and radar based metrics within the FAA EIM Platform.  Aggregate and flight-level analytics are being developed on FAA data and integrated within the RAISE platform.  Although not specific to FDR data the r-ASIAS program is going to maximize  FAA data availability for the r-ASIAS program
        </p>
        </>
      ),
      expanded: false,
      id: '0'
    },
    {
      title: 'About ASIAS FAQs',
      content: (
        <>
        <h4 style={{paddingTop: '1em'}}>What is Aviation Safety Information Analysis and Sharing (ASIAS)?</h4>
        <p className='faq-p'>
          ASIAS is a Federal Aviation Administration (FAA)-funded, joint government-industry, collaborative information sharing program to proactively analyze broad and extensive data sources towards the advancement of safety initiatives and the discovery of vulnerabilities in the National Airspace System (NAS). The primary objective of ASIAS is to provide a national resource for use in discovering common, systemic safety problems that span multiple operators, fleets, and regions of the airspace. Safety information discovered through ASIAS activities is used across the aviation industry to drive improvements and support Safety Management Systems. <br /><br />
          ASIAS is not a substitute for your own safety program. It is not an FAA sanctioned Flight Operations Quality Assurance (FOQA) or Aviation Safety Action Program (ASAP). As stated above, it is a separate, joint government-industry, collaborative information sharing program to proactively analyze data sources towards the advancement of safety. 

        </p>

        <h4 style={{paddingTop: '1em'}}>What are the benefits of participating in ASIAS? </h4>
        <p className='faq-p'>
        There are many benefits to participating in ASIAS. They include, but are not limited to: 
        </p>
        <ul>
          <li style={{maxWidth: "none"}}>Sharing your data with the only national data repository that is proactively analyzing rotorcraft, commercial, and general aviation data looking for systemic safety issues will provide you a broader understanding of safety issues and trends and how they can benefit your SMS program and day-to-day operations. For many operators, you may not generate enough data to accurately understand the potential risks for your operation. By participating in this program, your data becomes part of a larger industry-wide repository, and you have access to the findings derived from this large, de-identified, aggregate data source. The result is you are learning with others who are also sharing their data with this repository. </li>
          <li style={{maxWidth: "none"}}>Visibility on how your operations and safety concerns compare with the rest of the aviation industry. </li>
          <li style={{maxWidth: "none"}}>Access to the <a href="https://portal.asias.aero/">ASIAS Portal</a> and dashboards presenting the ASIAS participants’ aggregate safety and trend information derived from the ASIAS database. </li>
          <li style={{maxWidth: "none"}}>Contributing to the development of Rotorcraft Issue Analysis Team (R-IAT) and United States Helicopter Safety Team (USHST) Helicopter Safety Enhancements (H-SE) resulting from ASIAS analyses. </li>
          <li style={{maxWidth: "none"}}>Invite to the twice-yearly Aviation Safety InfoShare conference. </li>
          <li style={{maxWidth: "none"}}>Recommending and participating in special studies looking at specific safety issues.</li>
          <li style={{maxWidth: "none"}}>Becoming part of the ASIAS community. </li>
        </ul>
        

        <h4 style={{paddingTop: '1em'}}>How can I best leverage ASIAS to help understand potential issues?</h4>
        <p className='faq-p'>
        R-ASIAS hosts a wealth of information to help you build knowledge in two primary ways: Known Risk Monitoring and Vulnerability Discovery. The R-<span style={{color:'blue'}}>ASIAS Portal</span> can assist with discovering potential hazards that may affect your areas of operation and uncover issues either by specific exceedance events, fleet type, region, mission segment based on quantified data and information that an operator’s data may not provide on its own. You will also have the capability to compare your operations to industry norms relative to safety issues identified through aggregate results of de-identified FDM data, Aviation Safety Reports (ASRs), and other available operations and safety Data as directed by the ASIAS Executive Board (AEB).
        <br /> <br />
        <span style={{color:'blue'}}>Aviation Safety InfoShare</span> is another great opportunity that ASIAS operators can leverage to not only understand potential issues, but to connect with other operators and learn how they have mitigated similar issues. The R-IAT working in collaboration with USHST’s Safety Analysis Team (SAT) will be responsible for sharing ASIAS information as appropriate at these events to raise awareness of safety issues among the aviation community. The R-IAT will also review information shared at these events for potential analysis by ASIAS.
        </p>
        
        <h4 style={{paddingTop: '1em'}}>Who else participates in ASIAS today?</h4>
        <p className='faq-p'>
        In addition to the growing Rotorcraft Community in ASIAS, there are 45 Commercial Airline operators, over 150 General Aviation (GA) fixed wing operators, and several flight training institutions participating in ASIAS. Commercial airline operators in ASIAS represent nearly all of the Part 121 commercial operations in the National Airspace System (NAS), including both small and large operators. For Corporate/Business GA, the participants consist of Part 91, 91k, and 135 operators. Some operators provide digital flight data, some provide safety reports, and some provide both. Fleet sizes range from one aircraft to several hundred. About a third of the Corporate/Business operators are Fortune 500 flight departments, another third are smaller corporate flight departments, and the last third are Part 135/91k/management companies.  <br /> <br />
        Light GA operators may also submit data via the National General Aviation Flight Information Database (<a href="https://ngafid.org/">NGAFID</a>). The majority of data stored in the NGAFID today is collected from Part 141 flight schools, primarily university training fleets. The NGAFID also collects data from private GA operators via two methods: the General Aviation Airborne Recording Device (<a href="https://www.mitre.org/research/technology-transfer/technology-licensing/gaard-general-aviation-airborne-recording-device">GAARD</a>) application and from operators equipped with avionics capable of collecting flight data (e.g., Garmin G1000). <br /> <br />
        Several industry organizations also participate in and support ASIAS as part of the <a href="https://portal.asias.aero/">industry/government groups</a> that guide the ASIAS program. These organizations include manufacturers, trade associations, labor groups, and safety foundations. 

        </p>

        
        <h4 style={{paddingTop: '1em'}}>Can I see a list of the operators who already participate in ASIAS? </h4>
        <p className='faq-p'>
        All the commercial airline operators participating in ASIAS today are listed on the commercial/GA ASIAS home page; however, GA operator participants are not publicly disclosed without their express permission. After joining ASIAS, rotorcraft operators will inform their Data Transfer Organization if they want their name publicly disclosed as an ASIAS participant. 
        </p>

        <h4 style={{paddingTop: '1em'}}>What do I need to do to participate in ASIAS?</h4>
        <p className='faq-p'>
        Operators providing data to ASIAS have three requirements to participate:
        </p>
        <ol>
          <li style={{maxWidth: "none"}}>You must voluntarily provide data to ASIAS; either digital flight data (e.g., HFDM) or aviation safety reports (e.g., ASAP, aviation safety reports, or other voluntary aviation safety reports). Commercial airline operators must have FAA approved data collection programs. For Rotorcraft ASIAS participation, there is no requirement for your data collection programs to be FAA-approved such as an approved FOQA program or approved ASAP program. </li>
          <li style={{maxWidth: "none"}}>Your company must have a United States (U.S.) presence and fly U.S registered aircraft.</li>
          <li style={{maxWidth: "none"}}>You must sign an ASIAS Cooperative Agreement with an Operational Support Entity/Data Transfer Organization, as applicable. </li>
        </ol> 
        <p className='faq-p'>
        Organizations not providing data to ASIAS, but participating in the program, must sign an ASIAS Statement of Intent.
        </p>

        <h4 style={{paddingTop: '1em'}}>Once I join the ASIAS program, what are my commitments?</h4>
        <p className='faq-p'>
        </p>
        <ul>
          <li style={{maxWidth: "none"}}>Abide by the ASIAS guiding principles as stated in the appropriate Procedures and Operations (P&O) Plan for your community, and the applicable Cooperative Agreement or Statement of Intent. </li>
          <li style={{maxWidth: "none"}}>Provide digital flight data and/or safety reports on a regular basis, for data providing stakeholders. </li>
          <li style={{maxWidth: "none"}}>You can leave the ASIAS program at any time for any reason. If you leave the program and you provided data to ASIAS, you can elect to leave you data in the ASIAS aggregate database or have your data deleted from the database. </li>
        </ul>
        

        <h4 style={{paddingTop: '1em'}}>As an ASIAS participant, what are the basic guiding principles of handling ASIAS data?</h4>
        <p className='faq-p'>
        </p>
        <ul>
          <li style={{maxWidth: "none"}}>ASIAS information and data is considered sensitive, confidential, and/or proprietary and shall not be shared outside of ASIAS without permission of the data owner. </li>
          <li style={{maxWidth: "none"}}>ASIAS information and data shall not be used for commercial, competitive, punitive, or litigation purposes. </li>
          <li style={{maxWidth: "none"}}>ASIAS information and data shall not be discussed or shared with the media or any form of social media. </li>
          <li style={{maxWidth: "none"}}>ASIAS information and data shall only be used to improve aviation safety. </li>
          <li style={{maxWidth: "none"}}>Knowledge gained through participation in ASIAS activities may be shared within your organization for the sole purpose of improving aviation safety. </li>
          <li style={{maxWidth: "none"}}>These and other ASIAS principles are included in the ASIAS Procedures and Operations Plan (P&O), also provided to you along with the Cooperative Agreement. This document fully describes the ASIAS governance that all participants abide by. </li>
        </ul>
        

        <h4 style={{paddingTop: '1em'}}>What does it cost to participate in ASIAS? </h4>
        <p className='faq-p'>
        There is no cost to participate in ASIAS for domestic participants. The FAA funds the ASIAS program for domestic operators. 
        </p>

        <h4 style={{paddingTop: '1em'}}>Once I join ASIAS, what happens next?</h4>
        <p className='faq-p'>
        For data providing stakeholders, after you have retuned the signed Cooperative Agreement, the Operational Support Entity/Data Transfer Organization liaison will contact you to talk about next steps. The basic next steps included in the FAQs are as follows: 
        </p>
        <ul>
          <li style={{maxWidth: "none"}}>Notify your Operational Support Entity and/or your Data Transfer Organization to send your data to ASIAS. </li>
          <li style={{maxWidth: "none"}}>Provide your ASIAS liaison with a point of contact (POC) from your company along with other names from your company who will need access to the ASIAS Portal. This information should include the employee(s) name, email address, and phone number. </li>
          <li style={{maxWidth: "none"}}>GDIT (General Dynamics Information Technology) will establish <a href="https://portal.asias.aero/">ASIAS Portal</a> accounts for those employees provided by the operator’s POC. </li>
          <li style={{maxWidth: "none"}}>The operator’s POC will be added to the invite list for the Aviation Safety InfoShare conferences. </li>
          <li style={{maxWidth: "none"}}>For non-data providing stakeholders, GDIT will establish an <a href="https://portal.asias.aero/">ASIAS Portal</a> account for the organization’s POC, as applicable. </li>
        </ul>
        

        <h4 style={{paddingTop: '1em'}}>How do I provide digital flight data to ASIAS? </h4>
        <p className='faq-p'> 
        For operators flying helicopters with a digital flight data collection capability (e.g., Appareo, Garmin, Skytrac, Outerlink, etc.) ASIAS will work with the operator to acquire the digital flight data via a data transfer organization (DTO) or directly from the operator (future). ASIAS can accept digital flight data for up to 10 years back. 
        </p>

        <h4 style={{paddingTop: '1em'}}>How do I provide safety reports to ASIAS?</h4>
        <p className='faq-p'>
        Safety Reports are shared to ASIAS through a commercial entity that the operator is using as their data ingest platform for programs such as SMS or ASAP. Listed are several of the current commercial companies providing safety reports to ASIAS: 
        </p>
        <ul>
          <li style={{maxWidth: "none"}}>UTRS/WBAT</li>
          <li style={{maxWidth: "none"}}>Baldwin Aviation</li>
          <li style={{maxWidth: "none"}}>PRISM/ARGUS</li>
          <li style={{maxWidth: "none"}}>Polaris Aero/Vector </li>
          <li style={{maxWidth: "none"}}>ProSafeT</li>
        </ul>
        <p className='faq-p'>
        If you are a customer of one of these companies, then your data can easily be provided to ASIAS. If you currently use another company or use a custom format unique to your company, then let us know so we can explore options with you. 
        </p>

        <h4 style={{paddingTop: '1em'}}>What is the ASIAS Portal? </h4>
        <p className='faq-p'>
        The <a href="https://portal.asias.aero/">ASIAS Portal</a> is the ASIAS stakeholders’ primary access to ASIAS results and information. It contains multiple dashboards presenting trending and hazard analysis results of metrics developed from ASIAS participant-supplied data and public information—these aggregated metrics are computed monthly (depending upon the metric). The Portal also contains ASIAS-related documentation, training materials, and results of special studies. The dashboards allow you to look at de-identified aggregate safety trends and statistics, and, in some cases, these drill down to the airport and runway level for both domestic and international locations. The dashboards and studies will be specific to safety-related issues, such as unstabilized approach, loss of control, and unintended flight into IMC (UIMC). Historical weather information is also available. <br /> <br />
        The Portal is not a public entity—only authorized users may access the Portal. The stakeholder’s POC provides a list of users who are authorized from within their organization. Each user is a member of one or more “communities” and each community has access to different information on the Portal on a need-to-know basis. Users can only see de-identified aggregated data and results; users can never see any other stakeholder’s information in a non-aggregated form. 
        </p>

        <h4 style={{paddingTop: '1em'}}>What is the Rotorcraft ASIAS Database?</h4>
        <p className='faq-p'>
        Centralized IT architecture being developed by “third party,” General Dynamics Information Technology (GDIT), that will support ASIAS expansion to rotorcraft community, enabling data collection and processing functions for the development of rotorcraft safety analyses. 
        </p>

        <h4 style={{paddingTop: '1em'}}>What is an Operational Support Entity (OSE), and what is its role in ASIAS? </h4>
        <p className='faq-p'>
        Organizations that have been designated by the ASIAS Executive Board (AEB) to develop and support the operational infrastructure for the ASIAS program, and/or to conduct analysis supporting ASIAS. An ASIAS Operational Support Entity implements ASIAS requirements for protections of data and results and supports collaboration. R-IAT and AEB oversee the activities of Operational Support Entities. FAA provides program management of the operational support entities. A list of approved ASIAS OSEs appears in the CA. 
        </p>

        <h4 style={{paddingTop: '1em'}}>Who are the current Operational Support Entities for Rotorcraft ASIAS and their roles?</h4>
        <p className='faq-p'>
        
        </p>
        <ul>
          <li style={{maxWidth: "none"}}>GDIT – Database provider and Systems Administrator</li>
          <li style={{maxWidth: "none"}}>Georgia Institute of Technology – New data tools, analytics, outreach, training/data transfer organization </li>
          <li style={{maxWidth: "none"}}>MITRE – New data tools, analytics, outreach, training/data transfer organization </li>
          <li style={{maxWidth: "none"}}>TruthData – New data tools, analytics, outreach, training/data transfer organization</li>
        </ul>

        <h4 style={{paddingTop: '1em'}}>What are some Operational Support Entity Roles/Responsibilities?</h4>
        <p className='faq-p'>
        
        </p>
        <ul>
          <li style={{maxWidth: "none"}}>Implement ASIAS governance, policies, and procedures.</li>
          <li style={{maxWidth: "none"}}>Provide full transparency about processes for managing data and conducting analysis.</li>
          <li style={{maxWidth: "none"}}>Identify and act on data quality issues and coordinate with Data Transfer Organizations to resolve issues.</li>
          <li style={{maxWidth: "none"}}>Conduct analysis in alignment with AEB and R-IAT direction.</li>
        </ul>

        <h4 style={{paddingTop: '1em'}}>What is the purpose of having multiple Operational Support Entities?</h4>
        <p className='faq-p'>
        As ASIAS continues growing and evolving, multiple support entities enable new and different resources conducting analysis. This includes supporting many different segments of the rotorcraft industry (i.e., Personal/Private, Helicopter Air Ambulance, Utility, Air Tour, Offshore, etc.) with a variety of data ingest points and requiring different analysis expertise. A diverse set of support entities enables a more comprehensive set of capabilities that can be leveraged to support the identification of systemic risks in the National Airspace System (NAS).
        </p>

        <h4 style={{paddingTop: '1em'}}>Is it a requirement to share my data with all Operational Support Entities?</h4>
        <p className='faq-p'>
        Yes, through the ASIAS rotorcraft IT database which provides for encryption and aggregation of data. To maximize safety insights derived from data, ASIAS must draw from a broad set of datasets. Therefore, rotorcraft analysis should leverage data from all support entities. Data aggregation will occur through the ASIAS database and will be made available to support entities, as determined appropriate by R-IAT, for use in analysis. 
        </p>

        <h4 style={{paddingTop: '1em'}}>Will there be different data access privileges based on user role(s) for the ASIAS database?</h4>
        <p className='faq-p'>
        Access privileges will be strictly controlled and will depend on the need to access data for those purposes as outlined by the R-IAT. 
        </p>

        <h4 style={{paddingTop: '1em'}}>What is a Data Transfer Organization (DTO), and what is its role in ASIAS?</h4>
        <p className='faq-p'>
        ASIAS Stakeholder Organizations that are responsible for authorizing access to or transferring data to ASIAS. A Data Transfer Organization can provide data on a routine basis (see Routine Responsibilities) or it can enter into temporary or intermittent arrangements with ASIAS (see Non-Routine Responsibilities) in the ASIAS Procedures and Operations Plan for Rotorcraft. 
        </p>

        <h4 style={{paddingTop: '1em'}}>Who are the current Data Transfer Organizations for Rotorcraft ASIAS?</h4>
        <p className='faq-p'>
        
        </p>
        <ul>
        <li style={{maxWidth: "none"}}>Helicopter Association International (HAI)</li>
        <li style={{maxWidth: "none"}}><a href="https://www.mitre.org/">MITRE</a> Corporation</li>
        <li style={{maxWidth: "none"}}>Skytrac </li>
        <li style={{maxWidth: "none"}}>TruthData</li>
        </ul>

        <h4 style={{paddingTop: '1em'}}>Why don’t I just sign a Cooperative Agreement with GDIT, the developer of the ASIAS database?</h4>
        <p className='faq-p'>
        The support entities will provide data de-identification and a means for it to be transferred to the ASIAS database environment. GDIT will not be accepting data directly at the current time. This is a future goal.
        </p>

        <h4 style={{paddingTop: '1em'}}>What if an OSE or DTO receives a Freedom of Information Act (FOIA) request or a subpoena for ASIAS data?</h4>
        <p className='faq-p'>
        Since OSEs and DTOs are private companies, they are not subject to FOIA. As for a subpoena, like any other company, protection from a subpoena cannot be guaranteed. While there have been no subpoenas issued for any similar data sharing programs, the OSE/DTO’s legal team intends on raising available defenses to ensure protection of non-responsive information. Other similar defenses have succeeded with proprietary data collection programs like ASIAS. Many operators believe that in the event of a subpoena, the subpoena would be delivered directly to the operator and not to ASIAS.
        </p>

        <h4 style={{paddingTop: '1em'}}>How will my data be protected?</h4>
        <p className='faq-p'>
        Identifying information in the data (e.g., names, aircraft identification) will be removed by the operator or their support entity per the ASIAS Procedures and Operations Plan for Rotorcraft before being transferred to the ASIAS database. Information in the aggregate ASIAS database will be encrypted. These terms are further defined: </p>
        <p className='faq-p' style={{marginLeft: '40px'}}><strong><u>Data de-identification</u></strong> is described as the process by which all identifying information regarding the operator, aircraft, sector, and personnel are scrubbed from all data being submitted to ASIAS. This scrubbing will often take place in two phases: (1) an initial pass within the operator’s data repository prior to exporting to the data transfer entity, and (2) a follow-up pass (i.e., double check) within the Data Transfer Organization’s staging repository prior to exporting to the ASIAS central repository. </p>
        <p className='faq-p' style={{marginLeft: '40px'}}><strong><u>Data encryption</u></strong> - All data residing in the ASIAS database is encrypted (converted into code that hides the information's true nature) both during transport to the ASIAS data base via secure FTP protocols and during storage in the ASIAS database using Amazon Web Services (AWS) security, which is at or above cybersecurity community (government and industry standards and best practices). This protects against unauthorized disclosure as the encryption keys are kept separately from the ASIAS database.</p>
        

        <h4 style={{paddingTop: '1em'}}>The company data I provide to ASIAS is sensitive—how is it secured? </h4>
        <p className='faq-p'>
        The data you provide to ASIAS is stored by GDIT on a secure cloud environment using Amazon Web Services (AWS) infrastructure, and GDIT is responsible for securing that data. GDIT, a General Dynamics subsidiary, routinely coordinates sensitive data storage for the FAA and other governmental and industry sources as part of its public interest role. GDIT employs secure, encrypted data feeds; strictly limits access to data; and employs secure firewalls with traffic monitoring, intrusion detection systems, log monitoring, and user audits.
        </p>

        <h4 style={{paddingTop: '1em'}}>How is ASIAS governed?</h4>
        <p className='faq-p'>
        The ASIAS Executive Board (AEB), which is composed of representatives from Government and Industry, directs and authorizes ASIAS analyses including directed studies, assessment of safety enhancements, known risk monitoring, and vulnerability discovery. In the interest of enhancing aviation safety, the results of these analyses are shared with the ASIAS participants. There are three governing bodies under the AEB: the Commercial Issue Analysis Team (IAT) overseeing the commercial airline portion of ASIAS, the GA Issue Analysis Team (GA-IAT), overseeing the GA portion of ASIAS and the Rotorcraft Issue Analysis Team (R-IAT) overseeing the rotorcraft portion for ASIAS. All three are chaired and composed of Government and Industry representatives. In addition to the main IAT bodies, there are working groups concentrating on specific issues or tasks working in support of the IATs.
        </p>

        <h4 style={{paddingTop: '1em'}}>What is the FAA’s role in ASIAS? Can they see my data? </h4>
        <p className='faq-p'>
        ASIAS is an FAA-funded program jointly developed by the FAA and industry with the sole purpose of improving aviation safety. The FAA works with industry to oversee and direct the program as part of the AEB. The FAA also co-chairs, with industry, several of the safety and governing bodies that steer and contribute to ASIAS. The FAA cannot see any individual operator’s data—they can see the same aggregate, de-identified results the ASIAS operators can see. The FAA cannot use any ASIAS data or information for punitive or enforcement purposes per the ASIAS governance documented in the ASIAS Procedures and Operations Plan. 
        </p>

        <h4 style={{paddingTop: '1em'}}>What is the Aviation Safety InfoShare conference?  </h4>
        <p className='faq-p'>
        Aviation Safety InfoShare is a confidential, twice-yearly conference sponsored by the FAA, in which government and industry representatives share aviation safety concerns and discuss aviation safety issues and mitigations. InfoShare offers the safety community a unique opportunity to come together in a private setting to learn from each other’s experiences. The success of InfoShare relies on the active participation of the community—all ASIAS participants are invited to attend InfoShare and discuss how voluntary safety programs such as SMS, HFDM/FOQA, ASAP, and Line Operations Safety Assessments (LOSA) are used to manage safety within your respective organizations. InfoShare offers a singular opportunity to share experiences, advancing aviation safety for the industry. The conference is held in the spring and fall, and the location varies amongst major cities in the continental U.S. It is typically attended by 1,000+ aviation professionals from various aviation communities. There is no charge to attend the conference.
        </p>



        </>
      ),
      expanded: false,
      id: '1'
    },
    {
      title: 'Operator FAQs',
      content: (
        <>
          <h4>How do I register for ASIAS?</h4>
          <p className='faq-p'>
            Go to User Registration and submit your registration information. 
            An FAA Approver will review your registration request and notify you when your account is created.
            The FAA Approver may contact you with any questions to complete your registration
          </p>

          <h4>How do I add an aircraft to my fleet?</h4>
          <p className='faq-p'>
            Go to My Fleet and select Add Aircraft. Submit your aircraft information. 
            An FAA Approver will review your aircraft request and notify you when your aircraft is added. 
            The FAA Approver may contact you with any questions to complete your aircraft request.
          </p>

          <h4>How do I upload flight information?</h4>
          <p className='faq-p'>
            Go to My Fleet and find the Make/Model/Series/Recorder for the flight you wish to upload.  At the end of the table row will be an upload flight button, which when clicked will launch an upload dialog for that flight with the expected defaults filled out.  Adjust the information and either drag/drop a file onto the window, or launch the file upload browser to select a file to upload.  When selecting to Upload, you'll be prompted to confirm the upload information before submitting.<br />
            You can view your uploaded flight information on My Flights.
          </p>

          <h4>How do I view flight metrics?</h4>
          <p className='faq-p'>
            Go to My Flights and select Metrics for the flight you want to view metrics for. 
            Select a topic to view more details.
          </p>

          <h4>How do I see aggregate metrics?</h4>
          <p className='faq-p'>
            Go to My Metrics to view aggregated metrics for ASIAS users. Select a topic to view more details.
          </p>

          <h4>Where do I go for additional help?</h4>
          <p className='faq-p'>
            Contact Charles (Cliff) Johnson at 609-485-6181 or Charles.C.Johnson@faa.gov
          </p>

          <h4>I created a flight with erroneous information, how can I remove it?</h4>
            <p className='faq-p'>
              Contact the site administrators at ContactUs@asias.info
            </p>
        </>
      ),
      expanded: false,
      id: '2'
    },
    {
      title: 'DTO FAQs',
      content: (
        <>
            <h4>How do I see my operator's flight uploads?</h4>
            <p className='faq-p'>Go to your SFTP upload location to view flight uploads</p>

            <h4>How do I add an operator?</h4>
            <p className='faq-p'>Contact an FAA Approver and send them an updated DTO to Operator spreadsheet.</p>

            <h4>Where do I go for additional help?</h4>
            <p className='faq-p'>
              Contact Charles (Cliff) Johnson at 609-485-6181 or Charles.C.Johnson@faa.gov
            </p>

            <h4>I created a flight with erroneous information, how can I remove it?</h4>
            <p className='faq-p'>
              Contact the site administrators at ContactUs@asias.info
            </p>
        </>
      ),
      expanded: false,
      id: '3'
    },
    {
      title: 'OSE FAQs',
      content: (
        <>
          <h4>I am unable to see resources in Athena</h4>
          <p className='faq-p'>
            Athena resources are governed by AWS regions.  In the upper right corner of the AWS console next to the username make sure N. Virginia (us-east-1) is your chosen region.  
            <br />Not all parts of AWS acknowledge or use regions, so this value may be changed on you depending on which screens in the AWS console you visit.
          </p>
        </>
      ),
      expanded: false,
      id: '4'
    },
    {
      title: 'QuickSight',
      content: (
        <>
          <h4>What is QuickSight and what is it used for?</h4>
          <a href="https://docs.aws.amazon.com/quicksight/latest/user/welcome.html">Amazon QuickSight</a> is a cloud-scale business intelligence (BI) service that you can use to deliver easy-to-understand insights to the people who you work with, wherever they are. Amazon QuickSight connects to your data in the cloud and combines data from many different sources. In a single data dashboard, QuickSight can include AWS data, third-party data, big data, spreadsheet data, SaaS data, B2B data, and more. As a fully managed cloud-based service, Amazon QuickSight provides enterprise-grade security, global availability, and built-in redundancy. It also provides the user-management tools that you need to scale from 10 users to 10,000, all with no infrastructure to deploy or manage.
          <br />
          QuickSight gives decision-makers the opportunity to explore and interpret information in an interactive visual environment. They have secure access to dashboards from any device on your network and from mobile devices.
          <br />
          <a href="https://docs.aws.amazon.com/quicksight/latest/user/add-a-filter.html">Adding a Filter - Amazon QuickSight</a>
          <br />
          <a href="https://docs.aws.amazon.com/quicksight/latest/user/exporting-data.html">Exporting Data - Amazon QuickSight</a>
          <br />
        </>
      ),
      expanded: false,
      id: '5'
    }
  ]

  return (
    <>
    <DashboardNav />
    <div className="stretch_grid-container">
      
      <h1 style={{paddingTop: '1em'}}>ASIAS FAQS</h1> 

      <Accordion bordered={false} multiselectable={true} items={accordionItems}></Accordion>
      
    </div>
    </>
  )
}

export default FaqPage;
