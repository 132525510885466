import React, { useContext } from 'react'
import Button from 'react-bootstrap/Button'
import getDocument from '../services/getDocument'
import UserContext from '../components/UserContext'
import DashboardNav from '../components/DashboardNav'
import { Link } from 'react-router-dom'
import { ConstructionOutlined } from '@mui/icons-material'



function DocumentPage() {
    const contextUser = useContext(UserContext)

    const downloadItems = [
        {
            displayName: "ASIAS Brochure 1March2022.pdf",
            fileID : "BROCHURE"
        },
        {
            displayName: "ASIAS one pager 2021-07.pdf",
            fileID : "ONEPAGE"
        },
        {
            displayName: "ASIAS Procedures and Operations Plan for Rotorcraft Nov 2020.pdf",
            fileID : "OPSMAN"
        },
        process.env.REACT_APP_USER_BRANCH !== 'prod' ? {
            displayName: "UIMC_Summary.docx",
            fileID : "UIMCSUM"
        } : {},
    ]

    function handleClick(fileID) {
        getDocument(contextUser.user, fileID).then((result) => {
            const win = window.open(result.preSignedUrl, '_blank');
        })
    } 


    return (
        <>
        <DashboardNav />
        <div className="stretch_grid-container">
          
          <h1 style={{paddingTop: '1em'}}>Additional Documentation</h1> 
            <div>You may view/download the below files for additional information:</div>
            <div>
                {downloadItems.map(item => {
                    return(
                    <div key={item.displayName}>
                    <Button variant="link" onClick={evt => handleClick(item.fileID)}>{item.displayName}</Button>
                    </div>
                    )
                })}
            </div>
          
        </div>
        </>
      )

}

export default DocumentPage