import React, { useState, useRef, useEffect, useContext } from 'react';
import { DataContext } from "../../DataContext";
// import PropTypes from 'prop-types';
import CanvasJSReact from '../../../canvasjs-commercial-3.4.12/canvasjs.react';
import { useResizeDetector } from 'react-resize-detector';
import '../Chart.css';

// const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const propTypes = {

};

const defaultProps = {

};

// Control/Configure the size of the markers and lines in the chart
const DATA_POINT_SIZE = 8;
const LINE_THICKNESS = 2;

function SUasSightingsScatterChart() {
  // Obtain reference to global data context consumer
  const dataContext = useContext(DataContext);

  const { width, height, ref: resizeDetectorRef } = useResizeDetector();

  // Keep reference of chart for event listeners/actions
  const chartRef = useRef(null);
  const isMounted = useRef(false);

  // Determine if data exists
  const d = dataContext.filteredData ? dataContext.filteredData : dataContext.baseData;
  const dataExists = d && d.uas_sightings && d.uas_sightings.data && d.uas_sightings.data.length > 0;

  const [dataOfInterestExists, setDataOfInterestExists] = useState(true);

  /**
   * Handler for parsing date object to a formatted string version.
   * 
   * @param {object} e CanvasJS label event object containing the current date value.
   * @returns The formatted date string.
   */
  const dateLabelFormatter = (e) => {
    // Display dates as MMM/YYYY
    return e.value.toLocaleString('en-US', { month: 'short', year: 'numeric' });
  }

  /**
   * Add days to a given date. Does not modify the provided date by reference. Returns
   * new date object instead.
   * 
   * @param {Date} date Date object to add days to.
   * @param {number} days Number of days to add.
   * @returns New date object representing the original date plus the desired number of days.
   */
  const addDays = (date, days) => {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  /**
   * Build and retrieve formatted HTML string representing a featured
   * tooltip given a set of values for a particular row of interest.
   * 
   * row = {
   *   "key_1": "value_1",
   *   "key_2": "value_2",
   *   ...
   *   "key_n": "value_n"
   * }
   * 
   * @param {object} row Fields of interest for current row in dataset.
   * @param {string} color Hexidecimal or RGB(A) color string.
   * @returns Formatted HTML string representing a featured tooltip.
   */
  const getFeaturedTooltip = (row, color = null) => {
    // Build tool tip off all properties
    var tooltipText = "";

    // Start table
    tooltipText += "<table>";
    for (const [key, value] of Object.entries(row)) {
      let iconData = `<td><i class="fa fa-square tooltip-icon-fonts" style="color: ${color}; margin-right: 5px;"></i></td>`;
      tooltipText += `
        <tr>
          ${color !== null && iconData}
          <td style="color: #989898"><b>${key}&nbsp</b></td>
          <td><b>${value}</b></td>
        </tr>
      `;
    }

    // End table
    tooltipText += "</table>";
    return tooltipText;
  }

  // See CanvasJS configuration options at https://canvasjs.com/docs/charts/basics-of-creating-html5-chart/
  // Value format string: https://canvasjs.com/docs/charts/chart-options/axisx/valueformatstring/
  const [options, setOptions] = useState({
    zoomEnabled: true,
    animationEnabled: true,
    title: {
      text: ""
    },
    theme: dataContext.darkMode ? "dark1" : "light1",
    toolTip: {
      enabled: true,          //disable here
      animationEnabled: true, //disable here
      contentFormatter: e => {
        var content = "";
        for (var i = 0; i < e.entries.length; i++) {
          let dataPoint = e.entries[i].dataPoint;
          content = `${content}${dataPoint.tooltipText}`;
        }

        return content;
      }
    },
    axisX: {
      labelFontFamily: dataContext.CHART_FONTS.fontFamily,
      labelFontWeight: dataContext.CHART_FONTS.fontWeight,
      labelAutoFit: true,
      labelAngle: 0,
      title: "",
      gridThickness: 0,
      // See: https://canvasjs.com/docs/charts/basics-of-creating-html5-chart/formatting-date-time/
      labelFormatter: dateLabelFormatter,
      labelFontSize: 13,
      // crosshair: {
      //   enabled: true,
      //   snapToDataPoint: true,
      //   labelFormatter: dateLabelFormatter,
      // },
      // interval: 1,
      intervalType: "month",
    },
    axisY: {
      gridThickness: 0.5,
      labelFontFamily: dataContext.CHART_FONTS.fontFamily,
      labelFontSize: dataContext.CHART_FONTS.fontSize,
      labelFontWeight: dataContext.CHART_FONTS.fontWeight,
      title: ""
    },
    data: [
      { type: "spline", dataPoints: [] }
    ]
  });

  /**
   * Get grouped counts for each unique data point and produce a consolidated
   * object containing the rolled up counts, grouped by the desired field.
   * 
   * @param {object} dataTarget Base dataset or filtered dataset to extract desired group counts from.
   * @returns Object containing desired group counts.
   */
  const getGroupedData = (dataTarget) => {
    // Extract lookup table and data from data target
    const { uas_sightings: { data, lookup } } = dataTarget;
    let groupedData = dataContext.groupByCount(data, lookup.month_year);
    return groupedData;
  }

  // Component mount/unmount
  useEffect(() => {
    isMounted.current = true;

    // NOTE: identify document element by id on component mount to ensure canvas js chart wraps to parent container
    if (resizeDetectorRef.current) {
      let chartContainer = resizeDetectorRef.current.children[0]; // e.g., canvasjs-react-chart-container-2
      if (chartContainer) {
        chartContainer.style.height = "100%";
        chartContainer.style.width = "100%";
      }
    }

    return () => {
      isMounted.current = false;

      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }

      setOptions({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Detect changes to incomming/ingested data
  useEffect(() => {
    if (isMounted.current) {
      // Obtain reference to active data from context
      let dataTarget = dataContext.filteredData ? dataContext.filteredData : dataContext.baseData;
      let dataPoints = [];

      if (dataTarget) {
        // Extract lookup table and data from data target
        // const { uas_sightings : { data, lookup } } = dataTarget;

        // TODO Get aggregated count on timestamp field (group by month and year)
        let counts = getGroupedData(dataTarget);
        let keys = Object.keys(counts);

        // console.log("sUAS Sightings Over Time Counts:", counts);
        if (keys.length > 0) {
          let color = "#9FDEF1";
          dataPoints = keys.map(item => {
            let count = counts[item].count;
            let value = counts[item].value;

            // Convert month-year to month-day-year and make the day always equal to 1
            let sightingsDate = new Date(value.split("/").join("/1/"));

            let fieldsOfInterest = {
              "Sightings Date": dateLabelFormatter({ value: sightingsDate }),
              "Count": `${count.toLocaleString('en-US')}`,
            };

            let tooltipText = getFeaturedTooltip(fieldsOfInterest, color);

            return {
              label: item,
              value: value,
              x: sightingsDate,
              y: count,
              color: color,
              tooltipText: tooltipText,
            }
          }).filter(point => point.x !== null && point.y !== null); // Filter out null x or y values to prevent crash

          // Iterate through data points and set the line colors to the color of the next point (for variable color shading support)
          Array.from(dataPoints).forEach((currentPoint, idx) => {
            let nextPointExists = (idx + 1) <= dataPoints.length - 1;
            let nextPoint = nextPointExists ? dataPoints[idx + 1] : null;
            currentPoint.lineColor = nextPoint !== null ? nextPoint.color : currentPoint.color;
          });

          // Get the minimum and maximum possible y values to set appropriate scale for y-axis (and doesn't change when zooming)
          let sortedKeys = keys.sort((a, b) => counts[a].count - counts[b].count);
          let min = counts[sortedKeys[0]].count;
          let max = counts[sortedKeys[sortedKeys.length - 1]].count;
          let padding = (dataContext.getMagnitude(max) / 10) * 5;

          // console.log(`sUAS Sightings Scatter Chart Counts -> min = ${min}, max = ${max}`);

          // Adjust padding if it is 0, which indicates all data points have the same y-axis value (e.g., min and max are equal)
          padding = padding === 0 ? 0.1 : padding;

          // Compute min-max date values for x-axis. Add buffer in days to maximum so the label doesn't get cut off
          let dateMin = dataPoints.length > 0 ? dataPoints[0].x : undefined;
          let dateMax = dataPoints.length > 0 ? addDays(dataPoints[dataPoints.length - 1].x, 30) : undefined;

          // console.log("sUAS Sightings Scatter Chart Data Points", dataPoints);

          // Create formatted data structure for canvasjs chart
          // See data series attributes at https://canvasjs.com/docs/charts/chart-options/data/
          // See performance improvement considerations at https://canvasjs.com/forums/topic/performance-improvement-in-scatter-chart/
          setOptions({
            ...options,
            axisX: {
              ...options.axisX,
              minimum: dataPoints.length > 1 ? dateMin : undefined,
              maximum: dataPoints.length > 1 ? dateMax : undefined,
            },
            axisY: {
              ...options.axisY,
              minimum: dataPoints.length > 1 ? min - padding : undefined,
              maximum: dataPoints.length > 1 ? max + padding : undefined,
            },
            data: [
              {
                type: "spline",
                markerType: "square",
                lineThickness: LINE_THICKNESS,
                markerSize: DATA_POINT_SIZE,
                dataPoints: dataPoints
              }
            ]
          });
        }
      }

      // Data points of interest exist when at least one data point exists in the list
      setDataOfInterestExists(dataPoints.length > 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.baseData, dataContext.filteredData]);

  useEffect(() => {
    if (isMounted) {
      // Re-render chart if there's a change in theme
      if (chartRef.current) {
        // NOTE: must do it this way since options are copied to chart reference (e.g., not bound by state/props)
        chartRef.current.options.theme = dataContext.darkMode ? "dark1" : "light1";
        chartRef.current.render();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.darkMode]);

  // Dynamically update width and height of chart to fill parent container on dimension change
  useEffect(() => {
    if (isMounted) {
      if (chartRef.current) {
        let yOffset = 20;
        let dh = height - yOffset;
        if (width > 0 && dh > 0) {
          chartRef.current.options.width = width;
          chartRef.current.options.height = dh;
          chartRef.current.render();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height]);

  return (
    <div ref={resizeDetectorRef} style={{ width: "100%", height: "100%" }}>
      {(dataExists && dataOfInterestExists) && (
        <CanvasJSChart options={options} onRef={ref => chartRef.current = ref} />
      )}
      {(!dataExists) && (
        <div>
          <b>No Data</b>
        </div>
      )}
      {(dataExists && !dataOfInterestExists) && (
        <div>
          {/* Customize message as needed */}
          <b>No Data</b>
        </div>
      )}
    </div>
  );
}

SUasSightingsScatterChart.propTypes = propTypes;
SUasSightingsScatterChart.defaultProps = defaultProps;

export default SUasSightingsScatterChart;
