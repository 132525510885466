import React, {useState} from 'react'
import { 
    Header, 
    ExtendedNav,
    NavMenuButton, 
    Button,
  } from '@trussworks/react-uswds'
import { Link, useHistory } from 'react-router-dom'
import './UnauthNav.css'


const UnauthNav = () => {
    const [expanded, setExpanded] = useState(false)
    const onClick = () => setExpanded((prvExpanded) => !prvExpanded)  
    const [isOpen, setIsOpen] = useState([false, false, false, false, false, false, false])
    
    // const history = useHistory();
    
    
    // console.log(userContext)
  
    // function onToggle(n) {
    //   let isOpenCopy = [false, false, false, false, false, false, false]
  
    //   if (isOpen[n] === false) {
    //     isOpenCopy[n] = true
    //   } else if (isOpen[n] === true) {
    //     isOpenCopy[n] = false
    //   }
    //   setIsOpen(isOpenCopy)
    // }

  
    const testMenuItems = [
      <></>,
      
    ]
  
  
    
    const unauthNavMenu = [
      <div className="menuDiv">
      <>
      <Link to='/home'>
          <Button type="button">
            Home </Button>
        </Link>
      </>
      <>
      <Link to='/about'>
          <Button type="button">
            About </Button>
        </Link>
      </>
      <>
      <Link to='/contact'>
          <Button type="button">
            Contact </Button>
        </Link>
      </>
      <>
      <Link to='/help'>
          <Button type="button">
            Help </Button>
        </Link>
      </>
      <div className="signInDiv">
      {/* <div> */}
      <>
      <Link to='/login'>
          <Button type="button">
            Sign In </Button>
        </Link>
      </>
      <>
      <Link to='/register'>
          <Button type="button" className="signUp">
            Sign Up </Button>
        </Link>
      </>
      </div>
      </div>
    ]




    return (
        <>
            <div className={`usa-overlay ${expanded ? 'is-visible' : ''}`}></div>
            <Header extended={true}>
            <div style={{display:'block'}} className="unauthNavBar" id="unauthNavBarOverride">
            <div style={{position: "relative", height: "auto",paddingTop:'10px'}} className="usa-navbar">
                    <Link to='/home'>
                        <h3 className='unauthBrand'>ASIAS (Rotorcraft)</h3>
                    </Link>
                    
                      <NavMenuButton style={{position: "absolute", right: "0px", height: "-webkit-fill-available"}}onClick={onClick} label="Menu" />
                </div>
                <div>
                <ExtendedNav
                    primaryItems={unauthNavMenu}
                    secondaryItems={testMenuItems}
                    mobileExpanded={expanded}
                    onToggleMobileNav={onClick}>
                </ExtendedNav>
                </div>
                </div>
            </Header>
        </>
    )
}

export default UnauthNav
