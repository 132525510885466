import React, {useState, useContext}  from 'react'
import Button from 'react-bootstrap/Button';
import { Auth } from 'aws-amplify'
import UserContext from '../components/UserContext'
import {
  Label,
  TextInput,
  Fieldset
} from '@trussworks/react-uswds'
import SignInRegSuccess from './SignInRegSuccess';


const SignInRegConfirm = (props) =>{
    // const [show, setShow] = useState(false);
  
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
    const [registerConfirmCode, setRegisterConfirmCode] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(''); 
    const [showRegConfirm, setShowRegConfirm] = useState('');
    const [regStatusMessage, setRegStatusMessage] = useState('Click the Resend button if your code is more than 24 hours old.')
    const [processingsignIn, setProcessingsignIn] = useState(false);
    const [signInRegSuccess, setSignInRegSuccess] = useState(false)
    
    const contextType = useContext(UserContext);

    const resendCode = async (e) => {
      e.preventDefault()
      let resendCode = await Auth.resendSignUp(props.username)
      setRegStatusMessage("Confirmation email resent")
      // find a way to get a success from the call and show a message?
    }
  
    const confirmRegistration = async (e) => {
      e.preventDefault();
      if(!processingsignIn){
        setProcessingsignIn(true)
      try{
      // const { updateCurrentUser } = contextType
      const userSent = props.username;
      const registerConfirmCodeSent = registerConfirmCode;
      
      let signUpR = await Auth.confirmSignUp(userSent, registerConfirmCodeSent)
      
      if(signUpR ==="SUCCESS"){
        setSignInRegSuccess(true)
      }
      else {
        //Problem confriming, request new code.
      }
          
        // })
        // .catch(err => console.log('error confirming signing in...: ', err))
      }
      catch (error) {
        setProcessingsignIn(false)
        if(error.name === "CodeMismatchException"){
          setRegStatusMessage("Your confirmation code is incorrect.")
          
        }
        else{
          setRegStatusMessage(error.message)
        }
      }
    }
    }


    return (
      
        <div>
          {signInRegSuccess && (
        <SignInRegSuccess />
      )
        
        
      }
      {!signInRegSuccess && (
            <form onSubmit={evt => {confirmRegistration(evt)}}>
            <Fieldset legend="" legendStyle="large">
              Your account is not yet registered.  We've sent you an email with a confirmation code, please enter it below to finish registration.  If you did not receive an email, please check your spam folder, or email ContactUs@asias.info for more assistance.
              <br />
              <br />
              {regStatusMessage}
            <Label htmlFor="reg-code">Registration Code</Label>
            <TextInput
                name="reg-code"
                autoFocus
                onChange={evt => setRegisterConfirmCode(evt.target.value)}
                autoComplete="off"
                placeholder='Registration Code'
                
              />
              <br />
              <Button type="submit"> Confirm Sign Up</Button>
              <Button onClick={resendCode}> Resend Code</Button>
              </Fieldset>
              </form>
      )}
            </div>
    );
  
}
  
export default SignInRegConfirm;