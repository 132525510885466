import * as React from 'react';
import PropTypes from 'prop-types';
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

const propTypes = {};
const defaultProps = {};

// Based on examples from https://mui.com/material-ui/react-slider/
const StyledSlider = styled(Slider)(({ theme }) => ({
  // color: '#C1C1C1', // 3a8589
  color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
  height: 3,
  // padding: '13px 0',
  '& .MuiSlider-thumb': {
    height: 15,
    width: 15,
    backgroundColor: '#0053a1', // #fff
    // border: '1px solid currentColor',
    '&:hover': {
      boxShadow: 'none', // '0 0 0 8px rgba(58, 133, 137, 0.16)'
    },
    '& .styled-slider-bar': {
      height: 9,
      width: 1,
      // backgroundColor: 'currentColor',
      backgroundColor: '#C1C1C1',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  '& .Mui-focusVisible': {
    boxShadow: "none !important",
  },
  '& .MuiSlider-track': {
    height: 3,
    width: 3,
  },
  '& .MuiSlider-rail': {
    color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
    opacity: theme.palette.mode === 'dark' ? undefined : 1,
    height: "100%", // 3
  },
}));

StyledSlider.propTypes = propTypes;
StyledSlider.defaultProps = defaultProps;

export default StyledSlider;

export const StyledThumbComponent = (props) => {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="styled-slider-bar" />
      <span className="styled-slider-bar" />
      <span className="styled-slider-bar" />
    </SliderThumb>
  );
}

StyledThumbComponent.propTypes = {
  children: PropTypes.node,
};
