import React, { useContext, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { DataContext } from "../DataContext";
import { useResizeDetector } from 'react-resize-detector';
import { Box } from "@mui/material";

const propTypes = {
  source: PropTypes.string,
  column: PropTypes.string.isRequired,
  sumColumn: PropTypes.string,
  aggColumn: PropTypes.string,
  value: PropTypes.any,
  subheader: PropTypes.string,
  metric: PropTypes.oneOf(['count', 'sum', 'average', 'first', "groupcount", "groupaverage"]),
  modifier: PropTypes.func,
  decimalPlaces: PropTypes.number,
  simplifyNumber: PropTypes.bool,
  darkerFont: PropTypes.bool,
  smallerFont: PropTypes.bool,
};

const defaultProps = {
  source: "track_points",
  metric: "count",
  decimalPlaces: 2,
  simplifyNumber: true,
  darkerFont: false,
  smallerFont: false,
};

const styles = {
  container: {
    width: "100%",
    height: "100%",
    textAlign: "center",
  },
  content: {
    fontWeight: "bold",
    height: "50%",
    lineHeight: 1,
    verticalAlign: "bottom",
  },
  subcontent: {
    textAlign: "center",
    height: "50%",
    lineHeight: 1,
    verticalAlign: "top",
  },
};

const SHOW_DEBUG_LINES = false;

const BigNumber = (props) => {
  const { source, column, sumColumn, aggColumn, value, subheader, subheaderSize, metric, modifier, decimalPlaces, simplifyNumber, darkerFont, smallerFont } = props;
  const { width, height, ref } = useResizeDetector();
  const dataContext = useContext(DataContext);
  const targetData = dataContext.filteredData ? dataContext.filteredData : dataContext.baseData;
  const valid = targetData && targetData[source] && targetData[source].data && targetData[source].data.length > 0;
  const lookup = valid && source in targetData ? targetData[source].lookup : null;
  const data = valid && source in targetData ? targetData[source].data : null;
  const [aggValue, setAggValue] = useState();

  const [worker, setWorker] = useState(null)
  
  useEffect(() => {
    const asyncWorker = new Worker("uimcBigNumberAsync.js")

    asyncWorker.onmessage = function (event){
      // console.log({event})
      setAggValue(event.data);
    }

    setWorker(asyncWorker)

    doAggregation(asyncWorker)
    // asyncWorker.postMessage(doAggregation())

    return () => {
      asyncWorker.terminate();
    }
  }, [])

  
  /**
   * Compute aggregation based on provided props and data values.
   * @returns Computed aggregation value with big number formatting applied.
   */
  const doAggregation = (worker) => {
    let result = 0;
    let modVal = typeof (value) === "string" ? value.toUpperCase() : value;
    let aggregate = aggColumn && aggColumn in lookup;
    worker.postMessage({result,modVal,aggregate, metric, data, lookup, aggColumn, column, modifier, simplifyNumber, decimalPlaces})
    // if (metric) {
    //   // Reusable helper functions
    //   const getAggData = () => {
    //     // Filter data by aggregate colum first then get count based on filtered results
    //     let aggVals = dataContext.groupBy(data, lookup[aggColumn]);
    //     if (modVal) {
    //       aggVals = aggVals.filter(val => val === modVal);
    //     }
    //     let aggData = data.filter(record => aggVals.includes(record[lookup[aggColumn]]));
    //     return aggData;
    //   }

    //   const getCount = (d, { doSearch = true } = {}) => {
    //     let count = 0;

    //     if (doSearch) {
    //       // Get case-insensitive counts
    //       let counts = dataContext.groupByCount(d, lookup[column], { caseSensitive: false });
    //       // console.log(value, counts);
    //       if (modVal in counts) {
    //         count = counts[modVal].count;
    //       }
    //     } else {
    //       let groupedVals = dataContext.groupBy(d, lookup[column]);
    //       count = groupedVals.length;
    //     }

    //     return count;
    //   }

    //   const getGroupedCount = (d, { doSearch = true } = {}) => {
    //     let count = 0;

    //     let aggVals = dataContext.groupBy(data, lookup[aggColumn]);
    //     if (modVal) {
    //       aggVals = aggVals.filter(val => val === modVal);
    //     }
    //     count = aggVals.length

    //     return count;
    //   }

    //   const getAverage = (d) => {
    //     let sum = 0;
    //     let average = 0;
    //     let count = d.length;
    //     let currentVal;
    //     if (count > 0) {
    //       for (let record of d) {
    //         currentVal = record[lookup[column]];

    //         if (typeof (currentVal) === "number") {
    //           sum += currentVal;
    //         }
    //       }

    //       average = sum / count;
    //     }

    //     return average;
    //   }

    //   // Determine data target based on whether aggregation should be done first or not
    //   let dataTarget = data;
    //   if (aggregate) {
    //     // Filter data by aggregate colum first then get count based on filtered results
    //     dataTarget = getAggData();
    //   }
    //   switch (metric.toUpperCase()) {
    //     case "COUNT":
    //       result = getCount(dataTarget, { doSearch: !aggregate });
    //       break;
    //     case "SUM":
    //       let metricCol = sumColumn ? sumColumn : column;
    //       let currentVal;

    //       for (let row of data) {
    //         currentVal = row[lookup[column]];

    //         // Ensure case-insensitive comparison
    //         if (typeof (currentVal) === "string") {
    //           currentVal = currentVal.toUpperCase();
    //         }

    //         if (currentVal === modVal) {
    //           result += row[lookup[metricCol]];
    //         }
    //       }

    //       break;
    //     case "AVERAGE":
    //       result = getAverage(dataTarget);
    //       break;
    //     case "FIRST":
    //       // Get first record value (used when every record has the same value)
    //       if (data && data.length > 0) {
    //         result = data[0][lookup[column]];
    //       }
    //     case "GROUPCOUNT":
    //       result = getGroupedCount(dataTarget)  
    //       break;
    //     case "GROUPAVERAGE": 
    //       result = getCount(dataTarget, { doSearch: !aggregate }) / getGroupedCount(dataTarget);
    //       break;
    //     default:
    //       break;
    //   }
    // }

    // // Modify result as needed
    // if (modifier) {
    //   result = modifier(result);
    // }
    
    // setAggValue(simplifyNumber ? dataContext.bigNumberFormatter(result, decimalPlaces) : result.toLocaleString());
  }

  /**
   * Compute and retrieve font size based on factor relative to the minimum bounds
   * of the parent container.
   * 
   * @param {number} factor Amount to divide minimum bound value between width and height.
   * @param {any} fallback Font size to fall back on if computed font size less than or equal to 0.
   * @returns Computed font size based on factor. Default to fall back value if computation results in an invalid font size.
   */
  const getFontSize = (factor, fallback) => {
    let size = 0;
    let min = Math.min(width, height);
    let computedSize = 0;

    // Only compute font size if within bounds of parent container
    if (factor > 0) {
      computedSize = min / factor;
      size = computedSize;
    }

    // console.log(
    //   `
    //   width = ${width}, 
    //   height = ${height}, 
    //   min = ${min},
    //   factor = ${factor}
    //   calculated factor = ${computedSize > 0 ? min / computedSize : 0},
    //   calculated font = ${computedSize},
    //   actual font = ${size > 0 ? size : fallback}
    //   `
    // );

    return size > 0 ? size : fallback;
  }

  return (
    <Box ref={ref} sx={{ width: "100%", height: "100%" }}>
      {valid ? (
        <table style={{ ...styles.container, color: dataContext.darkMode ? darkerFont ? "white" :  "#eceef0" : darkerFont ? "black" : "#808080" }}>
          <tbody>
            <tr style={{ border: SHOW_DEBUG_LINES ? "solid 1px red" : "none" }}>
              {/* { ...styles.content, fontSize: getFontSize(2, '5em') } */}
              <td style={styles.content}>
                {/* viewBox attribute is a list of four numbers: min-x, min-y, width, and height */}
                <svg
                  width={smallerFont ? "40%" : "100%"}
                  height={height ? height / 2 : "100%"}
                  viewBox="0 0 56 18" // 0 0 56 18
                >
                  <text
                    x="50%" // 0
                    y="50%" // 15
                    fill={dataContext.darkMode ? darkerFont ? "white" :  "#eceef0" : darkerFont ? "black" : "#808080"}
                    textAnchor="middle"         // Horizontal anchor control
                    dominantBaseline="middle"   // Vertical anchor control
                  >
                    {aggValue}
                  </text>
                </svg>
              </td>
            </tr>
            {subheader && (
              <tr style={{ border: SHOW_DEBUG_LINES ? "solid 1px red" : "none" }}>
                <td style={{ ...styles.subcontent, fontSize: getFontSize(subheaderSize? subheaderSize : 6, '1.5em') }}>
                  {subheader}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        <div>
          <b>No Data</b>
        </div>
      )}
    </Box>
  );
};

BigNumber.propTypes = propTypes;
BigNumber.defaultProps = defaultProps;

export default BigNumber;
