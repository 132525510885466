import React from 'react'
import {
    Tag
} from '@trussworks/react-uswds'
import { FaCheckCircle, FaMinusCircle } from 'react-icons/fa'
import'./UploadStatus.css'

const UploadStatusTag = (props) => {
    var color = 'black'
    if (props.message === "failure") {
        color = 'red'
    }
    else if(props.message === "success") {
        color = '#94FA8B'
    }

    


    return (
        
        <div>
            {(props.message === "success") &&
            <div style={{paddingLeft: "1rem"}}>
                <div>
                    File Upload State:
                </div>
                <div>
                    <Tag 
                        style={{color: 'black', backgroundColor: color, 'textDecoration': 'none'}}>
                        <FaCheckCircle /> {props.message}
                    </Tag>
                </div>
            </div>
            }  
            {(props.message === "failure") &&
            <div style={{paddingLeft: "1rem"}}>
                <div>
                    File Upload State:
                </div>
                <div>
                    <Tag 
                        style={{color: 'black', backgroundColor: color, 'textDecoration': 'none'}}>
                        <FaMinusCircle /> {props.message}
                    </Tag>
                </div>
            </div>
            }  
            {(props.message === "inprogress") &&
            <div style={{display:'inline-flex', alignItems:'end'}}>
            <div className="lds-dual-ring" style={{marginRight:'10px'}}>
            </div>
            Uploading file(s), Please wait.
            </div>
            }  
        </div>
        
    )
}

export default UploadStatusTag
