import React, { useState, useEffect, useContext, useRef } from 'react'
import {
  Alert,
  CardGroup,
  CardMedia,
  CardFooter,
  Card,
  Button,
} from '@trussworks/react-uswds'
import Carousel from 'react-bootstrap/Carousel';
import '@trussworks/react-uswds/lib/uswds.css'
import '@trussworks/react-uswds/lib/index.css'
import './Dashboard.css'
import Footer from '../components/PageFooter'
import DashboardNav from '../components/DashboardNav'
import getSystemAggs from '../services/getSystemAggs';
import UserContext from '../components/UserContext';
import DashboardWidgets from '../components/DashboardWidgets'
import { Container, Row, Col } from 'react-bootstrap';
import hasRole from '../services/hasRole'
import FlightHourCards from "../components/homePageWidgets/FlightHourCards"
import { IconButton, Tooltip } from "@mui/material";
import AddList from "../components/AddList";
import SaveIcon from '@mui/icons-material/Save';

// Dash customization imports
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { withSize } from "react-sizeme";
import DashboardWidget from "../components/DashboardWidget";
import LoadingSkeleton from "../components/utils/LoadingSkeleton";
import { saveToLS, getFromLS, savePrefsToS3 } from '../helper-functions/LsHelper';

// import savePreferences from '../services/savePreferences';

import Slider from '../components/DashboardSlider'

// CSS
import './Dashboard.css';
// Need to import styles for core functionality
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

function Dashboard({ size: { width } }) {

  const contextUser = useContext(UserContext)
  const isMounted = useRef(false);
  // console.log("Debugging Dash")
  // console.log({contextUser})
  const [index, setIndex] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [results, setResults] = useState([]);
  const [error, setError] = useState([]);
  const carouselCount = 2; //0 indexed
  const [aggregateHours, setAggregateHours] = useState();
  const [aggreagateFlights, setAggregateFlights] = useState();
  const [operatorHours, setOperatorHours] = useState();
  const [operatorFlights, setOperatorFlights] = useState();
  const [preferences, setPreferences] = useState({})

  const BASE_APP_SCALE = 0.8;

  // Move this into a constant for ref?
  const styles = {
    toolbarContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
    toolbarBanner: {
      marginRight: "auto",
    },
  };


  //Layouts
  const preferenceKey = "DashboardPref";

  const dashboardLayoutsConfig = {
    operatorInsights: {
      w: 6,
      h: 4,
      x: 2,
      y: 0,
      i: "operatorInsights",
      moved: false,
      static: false,
      name: "Operator Insights"
    },
    flightData: {
      w: 2,
      h: 4,
      x: 0,
      y: 0,
      i: "flightData",
      moved: false,
      static: false,
      name: "Flight Data"
    }
  }

  
  const componentList = {
    operatorInsights:
        <DashboardWidgets dashboardType='HomePageExceedancesDashboardID' />,
    flightData:    
      // (operatorHours && operatorFlights) ? <LoadingSkeleton /> :
      <>
        <FlightHourCards setError={setError}/>
        </>,
  }



  const originalItems = Object.keys(dashboardLayoutsConfig);
  const initialLayouts = {
    lg: originalItems.map(item => dashboardLayoutsConfig[item])
  };
  // const loadedLayouts = getFromLS(contextUser.user, preferenceKey) || initialLayouts;
  
  const loadedLayouts = initialLayouts;
  const [layouts, setLayouts] = useState(
    loadedLayouts
  );

  // Initial set of widgets will be based on saved layouts data (defaults to initial layouts if no saved data found)
  const [items, setItems] = useState(loadedLayouts ? loadedLayouts.lg.map(item => item.i) : {});

  
  const onLayoutChange = (_, allLayouts) => {
    setLayouts(allLayouts);
    saveToLS(preferenceKey,allLayouts)
  };

  const onLayoutSave = (e) => {
    e.stopPropagation();
    savePrefsToS3(contextUser.user)
    
  };

  const onRemoveItem = (itemId) => {
    // console.log(`Removing ${itemId} from the dashboard...`);
    // setItems(items.filter((i) => i !== itemId));
  };

  const onAddItem = (itemId) => {
    // setItems([...items, itemId]);
  };
  

  useEffect(() => {
    console.log(layouts)
  }, [layouts])

  

  // Card titles
  const flightTile = "flightTitle"
  const flightHoursTitle = "flightHoursTitle"
  const cardTitles = {
    "0": {
      flightTitle: "Operator Flights",
      flightHoursTitle: "Operator Flight Hours"
    },
    // "1": {
    //   flightTitle: "Total System Flights",
    //   flightHoursTitle: "Total System Flight Hours"
    // }
  }

  const ref = useRef(null);

  const onPrevClick = () => {
    ref.current.prev();
  };
  const onNextClick = () => {
    ref.current.next();
  };


  function handleSelect(offset) {
    let newIndex = index + offset
    setIndex(newIndex > carouselCount ? 0 : newIndex < 0 ? carouselCount : newIndex);
  };

  // console.log("Debugging dash")
  // console.log(contextUser.user)

  useEffect(() => {

    console.log(contextUser.user)
    getFromLS(contextUser.user, preferenceKey).then((result) => {
      console.log({result})
      setLayouts(result)
    })
    
    
    isMounted.current = true;
    // saveToLS(preferenceKey,preferences)
    
    
      
      return () => {
        isMounted.current = false;
      }
  }, [])

  return (
    <>
      <DashboardNav />

      <div style={styles.toolbarContainer}>
        {/* <FlightIdBanner style={styles.toolbarBanner} /> */}
        <div>
          <IconButton aria-label="save" onClick={onLayoutSave}>
            <Tooltip title="Save Dashboard Tab Layout" placement="top" arrow>
              <SaveIcon />
            </Tooltip>
          </IconButton>

          {/* <AddList
            items={items}
            onRemoveItem={onRemoveItem}
            onAddItem={onAddItem}
            originalItems={originalItems}
            dashboardKey={preferenceKey}
            dashboardConfig={dashboardLayoutsConfig}
          /> */}
        </div>
      </div>

      Start test
      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={60}
        width={width * (1 / .8)}  //TODO: Parameterize app scale at a more global level?  Context?  Figure out what it impacts
        draggableHandle=".drag-handle"
        onLayoutChange={onLayoutChange}
        transformScale={BASE_APP_SCALE} // Required to adapt transform translation offset when dragging
      >
      Hello

            {items.map((key) => (

              <div
                key={key}
                data-grid={{ w: 6, h: 8, x: 0, y: Infinity }}
              >
                <DashboardWidget
                  id={key}
                  name={dashboardLayoutsConfig[key].name}
                  className="drag-handle"
                  onRemoveItem={onRemoveItem}
                  fullscreenEnabled={true}
                >
                  {componentList[key]}
                </DashboardWidget>
              </div>
              ))}


      </ResponsiveGridLayout>
      

        <Footer />


    </>
  )
}

export default withSize({ refreshMode: "debounce", refreshRate: 60 })(Dashboard);
