import React, { useState, useContext, useEffect } from 'react'
import {
  Search,
  Dropdown,
  Button,
  Checkbox,
  Tag
} from '@trussworks/react-uswds'
import DashboardNav from '../components/DashboardNav'

import { Link } from 'react-router-dom'
import UserContext from '../components/UserContext'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import getMyFlights from '../services/getMyFlights'
import TestDashboardWidgets from '../components/TestDashboardWidgets'
import FlightMetrics from './FlightMetrics'


import './MyFlights.css'



const TestPrototypeDemo = () => {

  // Handle API call
  const [state, setState] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState([]);
  const [showEmbedDiv, setShowEmbedDiv] = useState(1);
  const [tailsDropdownList, setTailsDropdownList] = useState(false);

  // Handle window autoscrolling
  const [scrollPosition, setScrollPostion] = useState(0);
  
  // Selected row vars
  const [selectedFlight, setSelectedFlight] = useState();
  const [selectedExceedance, setSelectedExceedance] = useState();

  // Handle user context
  const userContext = useContext(UserContext)
  var operator = userContext.user.attributes['custom:operator']

  //Select related
  const animatedComponents = makeAnimated();
  const [exceedanceList, setExceedanceList] = useState([
    {label : "Excessive Rate of Decent Below 1000 ft" , value : "Excessive Rate of Decent Below 1000 ft" },
    {label : "Vortex Ring State" , value : "Vortex Ring State" },
    {label : "High Ground Speed Below 500 ft" , value : "High Ground Speed Below 500 ft" },
    {label : "Excessive Rate of Climb Below 1000 ft" , value : "Excessive Rate of Climb Below 1000 ft" },
    {label : "Main Rotor Over Torque" , value : "Main Rotor Over Torque" },
    {label : "Pitch" , value : "Pitch" },
    {label : "Roll" , value : "Roll" },
    {label : "Yaw Rate" , value : "Yaw Rate" }
  ]);


  useEffect(() => {

    let execStart = Date.now()
    let intervDate = new Date()
    intervDate.setMonth(intervDate.getMonth() - 6)
      let searchObject = {
        startDate: intervDate,
        endDate: new Date(),
      }
      // console.log({searchObject})
    getMyFlights(userContext.user, operator, searchObject)
    .then((result) => {
      let execFinish = Date.now()
      let timeTaken = execFinish - execStart
      // console.log("Service took " + timeTaken)
      setIsLoaded(true)
      if (result != null) {
        // console.log("Results gathered")
        // console.log({result})
        setState(result)
        setTailsDropdownList(buildOptionsList(result, "flightmetadata__id"))
        
      }

    })
    .catch((error) => {
    // console.log(error)
      setIsLoaded(true)
      setError(error)
    })
  }, [])

  useEffect(() => {
    if(showEmbedDiv === 1){
      // console.log("I've been triggered!")
      window.scrollTo(0, scrollPosition)
    }
    }, [showEmbedDiv])

  

  const enableShowEmbedDiv = (rows) => {
    //mkae service call and mount dashboard
    window.scrollTo(0, 0)
    setSelectedFlight(rows.row.original);
    setScrollPostion(window.pageYOffset);
    setShowEmbedDiv(2);
  }


  const buildOptionsList = ((response, displayIdentifier) => {

    var multiSelectGroup = [];
    response.forEach((groupObj) => {
      multiSelectGroup.push({ label: groupObj[displayIdentifier], value: groupObj})
    })
    setSelectedFlight(multiSelectGroup[0])
    return multiSelectGroup
  })

  const setFlightSelectedHandler = ((event) => {
    // console.log({event})
    setSelectedFlight(event)
  })

  const setExceedanceSelectedHandler = ((event) => {
    // console.log({event})
    let selectedList = []
    if(event.length === 0){
      selectedList.push("[ALL]")
    } else {
    event.forEach((eventObj) => {
      selectedList.push(eventObj.value)
    })
  }
    // console.log(selectedList)
    setSelectedExceedance(selectedList)
  })

 


  return (
    <div>
      <DashboardNav />

      <div className="myFlights_header">
        <h1>Leaflet Integration Demo</h1>
      </div>
      <div >
        <div className="grid-flight-bar">
          {/* Filter Div, add dropdown list of FLight meta ids. */}
          {<Select
                      id="demo-flight-select"
                      style={{ borderColor: "black" }}
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      // defaultValue={tailsDropdownList[0]}
                      // isMulti
                      value={selectedFlight}
                      onChange={(event) => setFlightSelectedHandler(event)}
                      options={tailsDropdownList}
                    />
          }
          {<Select
                      id="demo-exceedance-select"
                      style={{ borderColor: "black" }}
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      onChange={(event) => setExceedanceSelectedHandler(event)}
                      options={exceedanceList}
                    />
          }
        </div>
        {/* Flight Dashboard Div - Add Dashboard for embed with on change from dropdown */}
        <div style={{maxWidth:'50%'}}>
        { selectedFlight && <TestDashboardWidgets dashboardType="TestDashboardID" flight={selectedFlight.value} exceedance={selectedExceedance} /> }
        </div>
        {/* Leaflet Div add leaflet */}
        <div>

        </div>
      </div>
    </div>
  )
}

export default TestPrototypeDemo
