import React, { useState, useEffect, useContext, useRef } from 'react'
import {
  Alert,
  CardGroup,
  CardMedia,
  CardFooter,
  Card,
  Button,
} from '@trussworks/react-uswds'
import Carousel from 'react-bootstrap/Carousel';
import '@trussworks/react-uswds/lib/uswds.css'
import '@trussworks/react-uswds/lib/index.css'
import './Dashboard.css'
import Footer from '../components/PageFooter'
import DashboardNav from '../components/DashboardNav'
import getSystemAggs from '../services/getSystemAggs';
import UserContext from '../components/UserContext';
import DashboardWidgets from '../components/DashboardWidgetsDeprecated'
import { Container, Row, Col } from 'react-bootstrap';
import hasRole from '../services/hasRole'
import { getFromLS } from '../helper-functions/LsHelper';
// import savePreferences from '../services/savePreferences';

import Slider from '../components/DashboardSlider'

// CSS
import './Dashboard.css';
import getLastFlight from '../services/getLastFlight';
import { ConstructionOutlined } from '@mui/icons-material';

function Dashboard(props) {

  const contextUser = useContext(UserContext)
  const isMounted = useRef(false);
  // console.log("Debugging Dash")
  // console.log({contextUser})
  const [index, setIndex] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [results, setResults] = useState([]);
  const [error, setError] = useState([]);
  const carouselCount = 2; //0 indexed
  const [aggregateHours, setAggregateHours] = useState();
  const [aggreagateFlights, setAggregateFlights] = useState();
  const [operatorHours, setOperatorHours] = useState();
  const [operatorFlights, setOperatorFlights] = useState();
  const [preferences, setPreferences] = useState({})
  const [lastFlight, setLastFlight] = useState();


  const preferenceKey = "DashboardPref"

  // Card titles
  const flightTile = "flightTitle"
  const flightHoursTitle = "flightHoursTitle"
  const cardTitles = {
    "0": {
      flightTitle: "Operator Flights",
      flightHoursTitle: "Operator Flight Hours"
    },
    // "1": {
    //   flightTitle: "Total System Flights",
    //   flightHoursTitle: "Total System Flight Hours"
    // }
  }

  const ref = useRef(null);

  const onPrevClick = () => {
    ref.current.prev();
  };
  const onNextClick = () => {
    ref.current.next();
  };


  function handleSelect(offset) {
    let newIndex = index + offset
    setIndex(newIndex > carouselCount ? 0 : newIndex < 0 ? carouselCount : newIndex);
  };

  // console.log("Debugging dash")
  // console.log(contextUser.user)

  useEffect(() => {

    if(contextUser && contextUser.user && hasRole(contextUser, ['dto'])){
      // console.log("navigate home")
      props.history.push("/fileStatus")
    }

    if(contextUser && contextUser.user && hasRole(contextUser, ['r-iat'])){
      // console.log("navigate home")
      props.history.push("/metrics")
    }
    
    getFromLS(contextUser.user, preferenceKey).then((result) => {
      setPreferences(result)
    })
    
    
    isMounted.current = true;
    // saveToLS(preferenceKey,preferences)
    
    getSystemAggs(contextUser.user)
      .then((result) => {
        for(const [index, value] of result.entries()){
          if (value['operator'] === "Aggregate"){
            setAggregateHours(value['total_flight_hours'])
            setAggregateFlights(value['total_flights'])
          } 
          else {
            setOperatorHours(value['total_flight_hours'])
            setOperatorFlights(value['total_flights'])
          }
        }

        
        // console.log({result})
        //   setAggregateFlights(results[0].total_flights)
        //   setAggregateHours(results[0].total_flight_hours)
        // if(results.length > 2){
        //     setOperatorFlights(results[1].total_flights)
        //     setOperatorHours(results[1].total_flight_hours)
        // }
        if(isMounted.current){
          setResults(result)
          setIsLoaded(true)
        }
      })
      .catch((error) => {
        // console.log(error)
        setError(error)
      })

      if(contextUser.user.attributes['custom:operator'] && contextUser.user.attributes['custom:operator'] !== ''){
        getLastFlight(contextUser.user).then((result) =>{
          let d = result['0']['flightmetadata_starttime']
          setLastFlight(d.substr(0, d.lastIndexOf('.')))
        })
      }
      

      return () => {
        isMounted.current = false;
      }
  }, [])

  // useEffect (() => {
  //   console.log(contextUser)

    
  // }, [contextUser])

  return (
    <>
      <DashboardNav />
      <div className="stretch_grid-container">
        <Row style={{ display: 'flex' }}>
          <Col style={{ display: "contents" }}>
            {/* <div>
          <Alert 
            type="info" 
            className="alert"
            heading="System Maintenance 3/26/2021 from 3am-6am" slim>
          </Alert>
        </div> */}
            {/* <div>
          <Alert 
            type="success" 
            className="alert"
            heading="Your file was uploaded successfully" slim>
            Click on my flights to view.
          </Alert> 
          
      </div> */}

            {/* <div className="dashboard-container">
              <Slider />
            </div> */}

          </Col>
          <Col>
            <Container fluid>
              <Row className="dashboard-row" >
                <h4 style={{ minWidth: "200px", alignSelf: "center" }}>Flight Data</h4>
                <div className="card-container" style={{display:"inline-flex",}}>
                <div style={{display:'flex'}}>
                  {
                    
                    results && Object.keys(cardTitles).map((index) => (
                      
                      <div key={index+"frag"} style={{display:'inline-flex'}}>
                      {index === '0' && operatorHours || index === '1' && aggregateHours ? 
                      <div key={index+"adda-frag"} style={{display:"inline-flex"}}>
                          <CardGroup key={index + "cg"}>
                            <Card key={index + "c"}>
                              <CardMedia key={index + "cm"}>
                                <div key={index + "d"} style={{ width: '200px', textAlign: 'center' }}>
                                  <h6>{cardTitles[index].flightHoursTitle}</h6>
                                </div>
                              </CardMedia>
                              <CardFooter key={index + "cf"}>
                                <h4 style={{ marginBottom: '0px' }}>{index === '0' ? operatorHours : aggregateHours}</h4>
                              </CardFooter>
                            </Card>
                          </CardGroup>
                        
                        
                          <CardGroup key={index + "acg"}>
                            <Card key={index + "ac"}>
                              <CardMedia key={index + "acm"}>
                                <div key={index + "ad"} style={{ width: '200px', textAlign: 'center' }}>
                                <h6>{cardTitles[index].flightTitle}</h6>
                                </div>
                              </CardMedia>
                              <CardFooter key={index + "acf"}>
                                <h4 style={{ marginBottom: '0px' }}>{index === '0' ? operatorFlights : aggreagateFlights}</h4>
                              </CardFooter>
                            </Card>
                          </CardGroup>
                        </div>
                        : '' }
                       

                          </div>
                    ))
                    
                  }
                  </div>
                  {lastFlight &&
                        <CardGroup key={"upload-process-group"}>
                            <Card key={"upload-process-card"}>
                              <CardMedia key={"upload-process-media"}>
                                <div key={"upload-process-div"} style={{ width: '250px', textAlign: 'center' }}>
                                <h6>Last Flight Flown Timestamp</h6>
                                </div>
                              </CardMedia>
                              <CardFooter key={"upload-process-footer"}>
                                <h4 style={{ marginBottom: '0px' }}>{lastFlight}</h4>
                              </CardFooter>
                            </Card>
                          </CardGroup>
                  }
                  </div>

              </Row> 

              <Row className="dashboard-row" >
              {contextUser.user.attributes['custom:operator'] && contextUser.user.attributes['custom:operator'] !== '' &&
              <>
              <h4 style={{ minWidth: "200px", alignSelf: "center" }}>Operator Insights</h4>
                 <DashboardWidgets dashboardType='HomePageExceedancesDashboardID' />
                 </>
                }
              </Row>
              {/* <Row className="dashboard-row" >

          <DashboardWidgets dashboardType='HomePageExceedancesDashboardID2'/>
        
        </Row> */}

              {/* <div style={{width:'200px',height:'200px'}}> 
          <DashboardWidgets dashboardType='Type 1' dashboardTitle='Placeholder'/>
        </div> */}
            </Container>
          </Col>

        </Row>

        <Footer />

      </div>

    </>
  )
}

export default Dashboard
