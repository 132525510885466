import {API} from "aws-amplify";

export function sendApproverEmail(authUser, email) {
    const token = authUser.signInUserSession.idToken.jwtToken;
    const requestInfo = {
      headers: {
        Authorization: token
      },
      body: {
        emailSubject: email.emailSubject,
        emailBodyText: email.emailBodyText,
		    emailBodyHTML: email.emailBodyText
      }
    }
  // console.log({requestInfo})
    
  // console.log("Debugging")
    // console.log({url})

    const data = API.post('raise-gui-api-dev', "/send-approver-email", requestInfo)
    // console.log({data})
      return data;
      // return null;

   
}

export default sendApproverEmail;