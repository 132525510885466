import React from 'react'
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

const AutoplaySlider = withAutoplay(AwesomeSlider);

const Slider = () => {
    return (
        <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
            <h4>Safety Trends</h4>
            <AutoplaySlider
                play={true}
                interval={20000}
                bullets={false}
                style={{ width: '550px', height: '600px', }}

            >
                <div style={{ backgroundColor: 'white', }}>

                    <img src={process.env.PUBLIC_URL + '/img/estimate.png'} alt={"Graph of Estimated U.S. Rotorcraft FATAL Accident Rate by FY"}></img>
                    <h3 style={{ paddingLeft: '60px', paddingRight: '40px' }}>Estimated U.S. Rotorcraft FATAL Accident Rate by FY</h3>
                    <h5 style={{ paddingLeft: '60px', paddingRight: '40px' }}>10 Year Lookback - Rates per 100,000 Hours</h5>
                    <p style={{ paddingBottom: '15px', backgroundColor: 'white', fontSize: '14px', paddingLeft: '60px', paddingRight: '40px' }}>
                        Uncertainty in FY20-21 regarding ongoing effect of COVID-19.
                        Estimates of the following reductions in FY20 flight hours: 16% in March, 29% in April,
                        and 7% in May. Updates expected with next FAA Forecast in early 2021 and 2020’s GA Survey results in late 2021.
                    </p>
                </div>
                <div style={{ backgroundColor: 'white' }} >
                    <img src={process.env.PUBLIC_URL + '/img/5yearAverageFatalAccidentRate.png'} alt={"Graph of Estimated U.S. Rotorcraft FATAL Accident Rate by FY"}></img>
                    <h3 style={{ paddingLeft: '60px', paddingRight: '40px' }}>Estimated U.S. Rotorcraft FATAL Accident Rate by FY</h3>
                    <h5 style={{ paddingLeft: '60px', paddingRight: '40px' }}>10 Year Lookback - Rates per 100,000 Hours</h5>
                    <p style={{ paddingBottom: '15px', backgroundColor: 'white', fontSize: '14px', paddingLeft: '60px', paddingRight: '40px' }}>
                        Uncertainty in FY20-21 regarding ongoing effect of COVID-19.
                        Estimates of the following reductions in FY20 flight hours: 16% in March, 29% in April,
                        and 7% in May. Updates expected with next FAA Forecast in early 2021 and 2020’s GA Survey results in late 2021.
                    </p>
                </div>
                <div style={{ backgroundColor: 'white' }} >
                    <img src={process.env.PUBLIC_URL + '/img/keyFatalAccidentRateMarkers.png'} alt={"Graph of U.S. Helicopter Safety Team (USHST) Key Fatal Accident Rate Markers (CY)"}></img>
                    <h4 style={{ paddingLeft: '60px', paddingRight: '60px' }}>U.S. Helicopter Safety Team (USHST) Key Fatal Accident Rate Markers (CY)</h4>
                    <p style={{ paddingBottom: '15px', backgroundColor: 'white', fontSize: '14px', paddingLeft: '60px', paddingRight: '40px' }}>
                        Uncertainty in FY20-21 regarding ongoing effect of COVID-19.
                        Estimates of the following reductions in FY20 flight hours: 16% in March, 29% in April,
                        and 7% in May. Updates expected with next FAA Forecast in early 2021 and 2020’s GA Survey results in late 2021.
                        <br></br>
                        <strong>Goal: By 2025, reduce the 5 year average fatal accident rate to 0.55 per 100k hours</strong>
                    </p>
                </div>
            </AutoplaySlider>
        </div>
    )
};

export default Slider;