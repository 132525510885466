import {API} from "aws-amplify";

export function addAircraft(authUser, messageBody) {
    const token = authUser.signInUserSession.idToken.jwtToken;
    const requestInfo = {
      headers: {
        Authorization: token
      },
      body: {
        operator : messageBody.operator ,
        tail : messageBody.tail ,
        alias: messageBody.alias,
        icao_mms_id : messageBody.icao_mms_id ,
        icao_make : messageBody.icao_make,
        icao_make_short : messageBody.icao_make_short ,
        icao_model : messageBody.icao_model ,
        icao_model_short : messageBody.icao_model_short ,
        icao_series : messageBody.icao_series ,
        icao_series_short : messageBody.icao_series_short ,
        recorder_make : messageBody.recorder_make ,
        recorder_make_short : messageBody.recorder_make_short ,
        recorder_model : messageBody.recorder_model ,
        recorder_model_short : messageBody.recorder_model_short ,
        recorder_version : messageBody.recorder_version ,
        default_mission : messageBody.default_mission ,
        default_mission_short : messageBody.default_mission_short ,
        exclude_aggregates : messageBody.exclude_aggregates ,
      } 
    }
  // console.log({requestInfo})
    
    const data = API.post('raise-gui-api-dev', "/add-aircraft", requestInfo)
      return data;
    // return null;
   
}

export default addAircraft;