/**
 * Custom helicopter SVG icon wrapper for integrating into Leaflet maps.
 * 
 * @param {number | string} [props.width] Width of the icon.
 * @param {number | string} [props.height] Height of the icon.
 * @param {string}  [props.fill] The internal color of the icon (this will change the base color of the icon unlike background and color styles).
 * @param {string} [props.className] Custom style class name from a CSS file.
 * @param {boolean} [props.animate] Whether to show an animated version of the icon (currently a pulse animation).
 * @param {number} [props.rotate] Amount to rotate the icon in degrees.
 * @returns HTML string representing the SVG icon contained within a div component for additional styling.
 */
const Helicopter = (props) => {
  const { 
    width = 20, 
    height = 20, 
    fill = null, 
    className = null,
    animate = false,
    rotate = 0,
    // style = null,
  } = props || {};

  // Source:    https://www.svgrepo.com/svg/128811/helicopter-bottom-view-silhouette
  // Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)
  return (
    `
    <div 
      ${animate ? 'class="helicopter-svg-container"' : ""}
    >
      <svg 
        version="1.1" 
        id="Layer_1" 
        xmlns="http://www.w3.org/2000/svg" 
        xmlns:xlink="http://www.w3.org/1999/xlink" 
        x=${typeof(width) === "number" ? width / 2 : "0px"}
        y=${typeof(height) === "number" ? height / 2 : "0px"}
        viewBox="0 0 478.874 478.873" 
        ${rotate ? 'style="transform: rotate(' + rotate + 'deg);"' : ""}
        ${className ? "class=" + className : "helicopter-svg"}
        width=${width}
        height=${height}
        ${fill && "fill=" + fill}
        xml:space="preserve"
      >
        <g>
            <g>
                <path d="M463.096,252.605l-133.38-52.861V78.503V47.101c0-4.338-3.519-7.851-7.851-7.851s-7.851,3.513-7.851,7.851v31.402h-11.569
                    C293.433,32.987,266.884,0,235.512,0c-31.37,0-57.919,32.987-66.938,78.503h-19.416V47.101c0-4.338-3.519-7.851-7.851-7.851
                    s-7.85,3.513-7.85,7.851v31.402v43.46l-109-43.2c-6.987-2.771-14.597-0.112-16.99,5.933c-2.395,6.045,1.327,13.187,8.312,15.961
                    l117.678,46.639v80.363v23.551c0,4.341,3.518,7.851,7.85,7.851s7.851-3.51,7.851-7.851V227.66h48.1
                    c7.64,25.239,14.703,58.196,14.703,94.207v78.502h7.851v39.528c0,8.079,7.027,14.644,15.701,14.644
                    c8.674,0,15.699-6.564,15.699-14.644v-39.528h7.851v-78.502c0-35.618,6.984-68.655,14.606-94.207h40.347v23.551
                    c0,4.341,3.519,7.851,7.851,7.851s7.851-3.51,7.851-7.851V227.66v-2.583l124.703,49.425c6.981,2.773,14.596,0.121,16.987-5.935
                    C473.799,262.512,470.081,255.383,463.096,252.605z M314.015,94.204v99.322l-24.132-9.567
                    c9.91-19.424,15.877-44.248,15.877-71.307c0-6.297-0.409-12.435-1.03-18.448H314.015z M149.158,94.204h17.132
                    c-0.621,6.014-1.023,12.151-1.023,18.448c0,7.694,0.486,15.207,1.406,22.468l-17.515-6.939V94.204z M149.158,211.958v-58.436
                    l23.536,9.327c1.775,5.688,3.829,11.093,6.155,16.186l-0.433-0.148c0,0,6.476,12.457,13.74,33.071H149.158z M278.714,211.958
                    c0.749-2.18,1.479-4.208,2.22-6.215l15.682,6.215H278.714z"/>
                <path d="M266.913,408.219c-4.328,0-7.851,3.518-7.851,7.85v54.954c0,4.332,3.522,7.851,7.851,7.851c4.332,0,7.85-3.519,7.85-7.851
                    v-54.954C274.762,411.736,271.245,408.219,266.913,408.219z"/>
            </g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
      </svg>
    </div>
    `
  );
}

export default Helicopter;
