import { API } from "aws-amplify";

export function getUimcAggregateMetrics(user) {
  const token = user.signInUserSession.idToken.jwtToken;
  const mode = "uimc-aggregate-metrics";
  const requestInfo = {
    headers: {
      Authorization: token
    },
    body: {
      mode: mode,
    }
  }

  let url = "/get-viz-presigned-url"

  const data = API.post('raise-gui-api-dev', url, requestInfo)
  return data;
}

export default getUimcAggregateMetrics;
