import {API} from "aws-amplify";

export function sendDenyEmail(authUser, email) {
    const token = authUser.signInUserSession.idToken.jwtToken;
    const requestInfo = {
      headers: {
        Authorization: token
      },
      body: {
        emailSubject: "Request for RAISE Account Denied",
        emailBodyText: "Your recent request for a RAISE account has been denied by an approver.  To appeal this decision, please contact ~~~~",
		    emailBodyHTML: "Your recent request for a RAISE account has been denied by an approver.  To appeal this decision, please contact ~~~~",
		    emailRecipients: email
      }
    }
  // console.log({requestInfo})
    var url = "/send-email"
    
  // console.log("Debugging")
  // console.log({url})

    const data = API.post('raise-gui-api-dev', url, requestInfo)
  // console.log({data})
      return data;

   
}

export default sendDenyEmail;