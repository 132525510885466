import { API } from "aws-amplify";

export function getFlightsPresignedURL(user, flightIds, staging_dir) {
  const token = user.signInUserSession.idToken.jwtToken;
  const mode = "flight-metrics";
  const requestInfo = {
    headers: {
      Authorization: token
    },
    body: {
      flights: flightIds,
      staging_dir : staging_dir,
    }
  }

  let url = "/get-flights-presigned-url"

  const data = API.post('raise-gui-api-dev', url, requestInfo)
  return data;
}

export default getFlightsPresignedURL;