import React, { useContext } from 'react';
import UserContext from '../components/UserContext';
import { DataProvider } from '../components/DataContext';
import AggregateMetricsThemeProvider from '../components/styles/AggregateMetricsThemeProvider';
import ApproachFlightsMetrics from './ApproachFlightsMetrics';

const propTypes = {};
const defaultProps = {};

const ApproachFlightsMetricsWrapper = () => {
  const userContext = useContext(UserContext);
  const operator = userContext.user.attributes['custom:operator'];
  
  return (
    <DataProvider>
      <AggregateMetricsThemeProvider>
        <ApproachFlightsMetrics 
          user={userContext.user}
          operator={operator}
        />
      </AggregateMetricsThemeProvider>
    </DataProvider>
  );
}

ApproachFlightsMetricsWrapper.propTypes = propTypes;
ApproachFlightsMetricsWrapper.defaultProps = defaultProps;

export default ApproachFlightsMetricsWrapper;
