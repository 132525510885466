import React from 'react'
import {
    Footer,
    FooterNav
} from '@trussworks/react-uswds'
import { Link } from 'react-router-dom'

const PageFooter = (props) => {
    const { style = {} } = props;

    return (
        <div style={style}>
            <Footer
                size="big"
                primary={
                <FooterNav
                    style={{textAlign:'left', paddingLeft: '20px'}}
                    aria-label="Footer navigation"
                    size="big"
                    links={[
                    ['Documentation', <Link to="/faq-page">FAQ Page</Link>],
                    // [
                    //     'Features',
                    //     <Link key="2" to="/">
                    //     Secondary link 
                    //     </Link>,
                    //     ...Array(2).fill(<Link to="/dashboard">Secondary link</Link>),
                    // ],
                    // ['Getting started', ...Array(3).fill(<Link to="/home">Secondary link</Link>)],
                    ['About us', <Link to='/faq-page'>About us page</Link>]
                    ]}
                />
                }
                secondary={<></>}
                />
        </div>
    )
}

export default PageFooter
