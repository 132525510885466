import {API} from "aws-amplify";

export async function getOperatorsList(user) {

    const token = user.signInUserSession.idToken.jwtToken;
    const requestInfo = {
      headers: {
        Authorization: token
      }
    }

    const data = await API.get('raise-gui-api-dev', "/get-operators-list", requestInfo)
    if(data){
      // console.log(data)
      return data;
    }
    else{
      return {};
    }
}

export default getOperatorsList;