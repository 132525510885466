import { API } from "aws-amplify";

export function getDocument(user, fileID) {
  // console.log("Calling wih file ID = " + fileID)
  const token = user.signInUserSession.idToken.jwtToken;
  const requestInfo = {
    headers: {
      Authorization: token
    },
    body: {
      fileID: fileID
    }
  }

  let url = "/getDocument"

  const data = API.post('raise-gui-api-dev', url, requestInfo)
  return data;
}

export default getDocument;