import {API} from "aws-amplify";

export async function getPreferences(authUser) {
    const token = authUser.signInUserSession.idToken.jwtToken;
    const requestInfo = {
      headers: {
        Authorization: token
      },
      
    }
  // console.log({requestInfo})
    var url = "/get-preferences"
    
  // console.log("Debugging")
  // console.log({url})

    const data = API.post('raise-gui-api-dev', url, requestInfo)
    // .then((result) => {
    //   console.log(result)
      
    // })
  // console.log({data})
  return data;

   
}

export default getPreferences;