import React, {useState, useContext}  from 'react'
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom'


const SignInRegSuccess = (props) =>{

    return (
        <div style={{justifyContent:'center', margin:'20px',width:'100%',display:'flex'}}>
          <div style={{width:'900px'}}>
          <p>
            Your account has been registered successfully.  A RAISE Admin will still need to approve your account and provision it.  If you do not hear back from a RAISE Admin within the next few days please reach out to ContactUs@asias.info
           
            
            </p>
            <Link to='/' style={{paddingTop: '1.5rem'}}>
                    <Button>Return Home</Button>
                </Link>
                </div>
        </div>
    );
  
}
  
export default SignInRegSuccess;