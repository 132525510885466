import {API} from "aws-amplify";

export function deleteCognitoUser(authUser, user) {
    const token = authUser.signInUserSession.idToken.jwtToken;
    const requestInfo = {
      headers: {
        Authorization: token
      },
      body: {
        'email':user.email,
        'userIdentifier': user.userIdentifier
      }
    }
  // console.log({requestInfo})
    
    const data = API.post('raise-gui-api-dev', "/delete-cognito-user", requestInfo)
  // console.log({data})
      return data;

   
}

export default deleteCognitoUser;