import {API} from "aws-amplify";

export async function getCognitoUsers(user) {

    const token = user.signInUserSession.idToken.jwtToken;
    const requestInfo = {
      headers: {
        Authorization: token
      }
    }

    const data = await API.get('raise-gui-api-dev', "/listcognitousers", requestInfo)

    
    data.Users.forEach(user => {
      let jsonAttributes =  {};
      // console.log({user})
      user.Attributes.forEach(attribute => {
        // console.log({attribute})
        jsonAttributes[attribute['Name']] = attribute['Value']
        if(attribute['Name'] === 'name')
        {
          let nameSplit = attribute['Value'].split(" ")
          jsonAttributes['firstName'] = nameSplit[0]
          jsonAttributes['lastName'] = nameSplit[1]
        }
      })
      // console.log({jsonAttributes})
      user['jsonAttributes'] = jsonAttributes
      
    })
      return data;
   
}

export default getCognitoUsers;