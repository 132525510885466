import React from 'react'

const AboutContent = (props) =>  {

    return (
<div className="stretch_grid-container">
      
      <h1 style={{paddingTop: '1em'}}>About Us</h1> 
      
      <h4 style={{paddingTop: '1em'}}>What is Aviation Safety Information Analysis and Sharing (ASIAS)?</h4>
        <p className='faq-p'>
        ASIAS is a Federal Aviation Administration (FAA)-funded, joint government-industry, collaborative information sharing program to proactively analyze broad and extensive data sources towards the advancement of safety initiatives and the discovery of vulnerabilities in the National Airspace System (NAS). The primary objective of ASIAS is to provide a national resource for use in discovering common, systemic safety problems that span multiple operators, fleets, and regions of the airspace. Safety information discovered through ASIAS activities is used across the aviation industry to drive improvements and support Safety Management Systems. 
        <br /> <br />
        ASIAS is not a substitute for your own safety program. It is not an FAA sanctioned Flight Operations Quality Assurance (FOQA) or Aviation Safety Action Program (ASAP). As stated above, it is a separate, joint government-industry, collaborative information sharing program to proactively analyze data sources towards the advancement of safety. 
        </p>

        <h4 style={{paddingTop: '1em'}}>What are the benefits of participating in Rotorcraft ASIAS (R-ASIAS)? </h4>
        <p className='faq-p'>
        There are many benefits to participating in R-ASIAS. They include, but are not limited to: 
        </p>
        <ul>
          <li style={{maxWidth: "none"}}>Sharing your data with the only national data repository that is proactively analyzing rotorcraft, commercial, and general aviation data looking for systemic safety issues will provide you a broader understanding of safety issues and trends and how they can benefit your SMS program and day-to-day operations. For many operators, you may not generate enough data to accurately understand the potential risks for your operation. By participating in this program, your data becomes part of a larger industry-wide repository, and you have access to the findings derived from this large, de-identified, aggregate data source. The result is you are learning with others who are also sharing their data with this repository.</li>
          <li style={{maxWidth: "none"}}>Visibility on how your operations and safety concerns compare with the rest of the rotorcraft industry.</li>
          <li style={{maxWidth: "none"}}>Access to the R-<span style={{color:'blue'}}>ASIAS Portal</span> and dashboards presenting the R-ASIAS participants’ aggregate safety and trend information derived from the R-ASIAS database. </li>
          <li style={{maxWidth: "none"}}>Contributing to the development of Rotorcraft Issue Analysis Team (R-IAT) and United States Helicopter Safety Team (USHST) Helicopter Safety Enhancements (H-SE) resulting from R-ASIAS analyses.</li>
          <li style={{maxWidth: "none"}}>Invite to the twice-yearly <span style={{color:'blue'}}>Aviation Safety InfoShare</span> conference. </li>
          <li style={{maxWidth: "none"}}>Recommending and participating in special studies looking at specific safety issues.</li>
          <li style={{maxWidth: "none"}}>Becoming part of the ASIAS community. </li>
        </ul>
        

        <h4 style={{paddingTop: '1em'}}>How can I best leverage R-ASIAS to help understand potential issues?</h4>
        <p className='faq-p'>
        R-ASIAS hosts a wealth of information to help you build knowledge in two primary ways: Known Risk Monitoring and Vulnerability Discovery. The R-<span style={{color:'blue'}}>ASIAS Portal</span> can assist with discovering potential hazards that may affect your areas of operation and uncover issues either by specific exceedance events, fleet type, region, mission segment based on quantified data and information that an operator’s data may not provide on its own. You will also have the capability to compare your operations to industry norms relative to safety issues identified through aggregate results of de-identified FDM data, Aviation Safety Reports (ASRs), and other available operations and safety Data as directed by the ASIAS Executive Board (AEB).
        <br /> <br />
        <span style={{color:'blue'}}>Aviation Safety InfoShare</span> is another great opportunity that ASIAS operators can leverage to not only understand potential issues, but to connect with other operators and learn how they have mitigated similar issues. The R-IAT working in collaboration with USHST’s Safety Analysis Team (SAT) will be responsible for sharing ASIAS information as appropriate at these events to raise awareness of safety issues among the aviation community. The R-IAT will also review information shared at these events for potential analysis by ASIAS.
        </p>
        
        <h4 style={{paddingTop: '1em'}}>Who else participates in ASIAS today?</h4>
        <p className='faq-p'>
        In addition to the growing Rotorcraft Community in ASIAS, there are 45 Commercial Airline operators, over 150 General Aviation (GA) fixed wing operators, and several flight training institutions participating in ASIAS. Commercial airline operators in ASIAS represent nearly all of the Part 121 commercial operations in the National Airspace System (NAS), including both small and large operators. For Corporate/Business GA, the participants consist of Part 91, 91k, and 135 operators. Some operators provide digital flight data, some provide safety reports, and some provide both. Fleet sizes range from one aircraft to several hundred. About a third of the Corporate/Business operators are Fortune 500 flight departments, another third are smaller corporate flight departments, and the last third are Part 135/91k/management companies. 
        <br /> <br />
        Rotorcraft and Light GA operators may also submit data via the National General Aviation Flight Information Database (<span style={{color:'blue'}}>NGAFID</span>). The majority of data stored in the NGAFID today is collected from Part 141 flight schools, primarily university training fleets. The NGAFID also collects data from private GA operators via two methods: the General Aviation Airborne Recording Device (<span style={{color:'blue'}}>GAARD</span>) application and from operators equipped with avionics capable of collecting flight data (e.g., Garmin G1000).
        <br /> <br />
        Several industry organizations also participate in and support ASIAS as part of the <span style={{color:'blue'}}>industry/government groups</span> that guide the ASIAS program. These organizations include manufacturers, government entities, trade associations, labor groups, and safety foundations.
        </p>

        
        <h4 style={{paddingTop: '1em'}}>Can I see a list of the operators who already participate in ASIAS? </h4>
        <p className='faq-p'>
        All the commercial airline operators participating in ASIAS today are listed on the commercial/GA ASIAS home page; however, GA operator participants are not publicly disclosed without their express permission. After joining R-ASIAS, rotorcraft operators will inform their Data Transfer Organization if they want their name publicly disclosed as an R-ASIAS participant. 
        </p>

        <h4 style={{paddingTop: '1em'}}>What do I need to do to participate in R-ASIAS?</h4>
        <p className='faq-p'>
        Operators providing data to R-ASIAS have three requirements to participate:
        </p>
        <ol>
          <li style={{maxWidth: "none"}}>Voluntarily submitted data to R-ASIAS; either digital flight data (e.g., HFDM) or aviation safety reports (e.g., ASAP, aviation safety reports, or other voluntary aviation safety reports).. For R-ASIAS participation, there is no requirement for your data collection programs to be FAA-approved such as an approved FOQA program or approved ASAP program. </li>
          <li style={{maxWidth: "none"}}>Your company must have a United States (U.S.) presence.</li>
          <li style={{maxWidth: "none"}}>You must sign an R-ASIAS Cooperative Agreement with an Operational Support Entity/Data Transfer Organization, as applicable.</li>
        </ol> 
        <p className='faq-p'>
        Organizations not providing data to R-ASIAS, but participating in the program, must sign an ASIAS Statement of Intent.
        </p>

        <h4 style={{paddingTop: '1em'}}>Once I join the R-ASIAS program, what are my commitments?</h4>
        <p className='faq-p'>
        </p>
        <ul>
          <li style={{maxWidth: "none"}}>Abide by the R-ASIAS guiding principles as stated in the R-ASIAS Procedures and Operations (P&O) Plan for Rotorcraft, and the applicable Cooperative Agreement or Statement of Intent. </li>
          <li style={{maxWidth: "none"}}>Provide digital flight data and/or safety reports on a regular basis, for data providing stakeholders. </li>
          <li style={{maxWidth: "none"}}>You can leave the R-ASIAS program at any time for any reason. If you leave the program and you provided data to R-ASIAS, you can elect to leave you data in the R-ASIAS aggregate database or have your data deleted from the database. </li>
        </ul>
        

        <h4 style={{paddingTop: '1em'}}>As an R-ASIAS participant, what are the basic guiding principles of handling R-ASIAS data? </h4>
        <p className='faq-p'>
        </p>
        <ul>
          <li style={{maxWidth: "none"}}>R-ASIAS information and data is considered sensitive, confidential, and/or proprietary and shall not be shared outside of R-ASIAS without permission of the data owner. </li>
          <li style={{maxWidth: "none"}}>R-ASIAS information and data shall not be used for commercial, competitive, punitive, or litigation purposes. </li>
          <li style={{maxWidth: "none"}}>R-ASIAS information and data shall not be discussed or shared with the media or any form of social media. </li>
          <li style={{maxWidth: "none"}}>R-ASIAS information and data shall only be used to improve aviation safety. </li>
          <li style={{maxWidth: "none"}}>Knowledge gained through participation in R-ASIAS activities may be shared within your organization for the sole purpose of improving aviation safety. </li>
          <li style={{maxWidth: "none"}}>These and other R-ASIAS principles are included in the R-ASIAS Procedures and Operations Plan (P&O) for Rotorcraft, also provided to you along with the Cooperative Agreement. This document fully describes the R-ASIAS governance that all participants abide by. </li>
        </ul>
        

        <h4 style={{paddingTop: '1em'}}>What does it cost to participate in R-ASIAS? </h4>
        <p className='faq-p'>
        There is no cost to participate in R-ASIAS for domestic participants. The FAA funds the ASIAS program for domestic operators. 
        </p>

        <h4 style={{paddingTop: '1em'}}>Once I join R-ASIAS, what happens next?</h4>
        <p className='faq-p'>
        For data providing stakeholders, after you have retuned the signed operator cooperative agreement or Third party Cooperative Agreement, the Operational Support Entity/ liaison will contact you to talk about next steps. The basic next steps are as follows: 
        </p>
        <ul>
          <li style={{maxWidth: "none"}}>Notify your Operational Support Entity and/or your Data Transfer Organization to send your data to R-ASIAS.</li>
          <li style={{maxWidth: "none"}}>Provide your R-ASIAS liaison with a point of contact (POC) from your company along with other names from your company who will need access to the R-ASIAS Portal. This information should include the employee(s) name, email address, and phone number. </li>
          <li style={{maxWidth: "none"}}>Users will register their accounts on the R-ASIAS Portal and receive access as per their Rotorcraft ASIAS agreement with GDIT.</li>
          <li style={{maxWidth: "none"}}>The operator’s POC will be added to the invite list for the Aviation Safety InfoShare conferences. </li>
          <li style={{maxWidth: "none"}}>For non-data providing stakeholders, GDIT will establish an R-ASIAS Portal account for the organization’s POC, as applicable. </li>
        </ul>
        

        <h4 style={{paddingTop: '1em'}}>How do I provide digital flight data to R-ASIAS? </h4>
        <p className='faq-p'> 
        For operators flying helicopters with a digital flight data collection capability (e.g., Appareo, Garmin, Skytrac, Outerlink, etc.) R-ASIAS will work with the operator to acquire the digital flight data via a data transfer organization or directly from the operator. R-ASIAS can accept digital flight data for up to 10 years back. 
        <br /><br />
        For operators with smaller fleets or those operators flying smaller helicopters with a data collection capability (e.g., Garmin 1000, Garmin 3000, etc.) that data can be ingested into ASIAS via the National General Aviation Flight Information Database (NGAFID), another part of ASIAS.  <a href="https://ngafid.org/">https://ngafid.org/</a>
        </p>

        <h4 style={{paddingTop: '1em'}}>How do I provide safety reports and data to R-ASIAS?</h4>
        <p className='faq-p'>
        Most safety reports and data are shared to R-ASIAS through commercial entities that the operator is using as their data ingest platform or for programs such as SMS safety/hazard reports, ASAP, and FDM/FOQA. If you are a customer of one of these companies, then your data can easily be provided to R-ASIAS.  Custom solutions that may be unique to your company may also be available.   Your R-ASIAS Liaison will guide you through your options 
        </p>
        

        <h4 style={{paddingTop: '1em'}}>What is the R-ASIAS Portal? </h4>
        <p className='faq-p'>
        The R-ASIAS Portal is the R-ASIAS stakeholders’ primary access to R-ASIAS results and information. It contains multiple dashboards and visualizations presenting trending and hazard analysis results of metrics developed from R-ASIAS participant-supplied data and public information—these aggregated metrics are computed regularly (time depending upon the metric). The Portal also contains R-ASIAS-related documentation, training materials, and results of special studies. The dashboards allow you to look at de-identified aggregate safety trends and statistics, and, in some cases, these drill down to the airport and runway level for both domestic and international locations. 
        <br /><br />
        The Portal is not a public entity—only authorized users may access the Portal. The stakeholder’s POC provides a list of users who are authorized from within their organization. Each user is a member of one or more “communities” and each community has access to different information on the Portal on a need-to-know basis. Users can only see de-identified aggregated data and results; users can never see any other stakeholder’s information in a non-aggregated form. 

        </p>

        <h4 style={{paddingTop: '1em'}}>What is the R-ASIAS Database?</h4>
        <p className='faq-p'>
        The R-ASIAS database is a centralized IT architecture being developed by a “third party,” General Dynamics Information Technology (GDIT), that will support ASIAS expansion to rotorcraft community, enabling data collection and processing functions for the development of R-ASIAS’ rotorcraft safety analyses. 
        </p>

        <h4 style={{paddingTop: '1em'}}>What is an Operational Support Entity (OSE), and what is its role in R-ASIAS? </h4>
        <p className='faq-p'>
        Organizations that have been designated by the ASIAS Executive Board (AEB) to develop and support the operational infrastructure for the ASIAS program, and/or to conduct analysis supporting ASIAS. An ASIAS Operational Support Entity implements ASIAS requirements for protection of data and results and supports collaboration. R-IAT and AEB oversee the activities of Operational Support Entities. FAA provides program management of the operational support entities. A list of approved ASIAS OSEs is referenced in the cooperative agreement. 
        </p>

        {/* <h4 style={{paddingTop: '1em'}}>Who are the current Operational Support Entities for Rotorcraft ASIAS and their roles?</h4>
        <p className='faq-p'>
        
        </p>
        <ul>
          <li style={{maxWidth: "none"}}>GDIT – Database provider and Systems Administrator</li>
          <li style={{maxWidth: "none"}}>Georgia Institute of Technology – New data tools, analytics, outreach, training/data transfer organization </li>
          <li style={{maxWidth: "none"}}>MITRE – New data tools, analytics, outreach, training/data transfer organization </li>
          <li style={{maxWidth: "none"}}>TruthData – New data tools, analytics, outreach, training/data transfer organization</li>
          <li style={{maxWidth: "none"}}>ATAC – New data tools, analytics, outreach, training/data transfer organization</li>
          <li style={{maxWidth: "none"}}>Concepts Beyond – New data tools, analytics, outreach, training/data transfer organization</li>
        </ul> */}

        <h4 style={{paddingTop: '1em'}}>What are some Operational Support Entity Roles/Responsibilities?</h4>
        <p className='faq-p'>
        
        </p>
        <ul>
          <li style={{maxWidth: "none"}}>Implement R-ASIAS governance, policies, and procedures.</li>
          <li style={{maxWidth: "none"}}>Provide full transparency about processes for managing data and conducting analysis.</li>
          <li style={{maxWidth: "none"}}>Identify and act on data quality issues and coordinate with Data Transfer Organizations to resolve issues.</li>
          <li style={{maxWidth: "none"}}>Conduct analysis in alignment with AEB and R-IAT direction.</li>
          <li style={{maxWidth: "none"}}>Manage supporting Data Analysis Entity’s who assist OSE in the development of further system capacities, conducting data analysis within R-ASIAS platform for; Known risk monitoring, monitoring of established metrics, directed studies, and vulnerability discovery.</li>
        </ul>

        <h4 style={{paddingTop: '1em'}}>What is the purpose of having multiple Operational Support Entities?</h4>
        <p className='faq-p'>
        As R-ASIAS continues growing and evolving, multiple support entities enable new and different resources conducting analysis. This includes supporting many different segments of the industry with a variety of data ingest points and requiring different analysis expertise. A diverse set of support entities enables a more comprehensive set of capabilities that can be leveraged to support the identification of systemic risks in the National Airspace System (NAS).
        </p>

        {/* <h4 style={{paddingTop: '1em'}}>Is it a requirement to share my data with all Operational Support Entities?</h4>
        <p className='faq-p'>
        Yes, through the ASIAS rotorcraft IT database which provides for encryption and aggregation of data. To maximize safety insights derived from data, ASIAS must draw from a broad set of datasets. Therefore, rotorcraft analysis should leverage data from all support entities. Data aggregation will occur through the ASIAS database and will be made available to support entities, as determined appropriate by R-IAT, for use in analysis. 
        </p> */}

        <h4 style={{paddingTop: '1em'}}>Will there be different data access privileges based on user role(s) for the ASIAS database?</h4>
        <p className='faq-p'>
        Access privileges will be strictly controlled and will depend on the need to access data for those purposes as outlined by the R-IAT. 
        </p>

        <h4 style={{paddingTop: '1em'}}>What is a Data Transfer Organization (DTO), and what is its role in R-ASIAS?</h4>
        <p className='faq-p'>
        Data Transfer Organizations are organizations that have been designated by the operator to send their data to R-ASIAS. The operator typically has a contractual relationship with the DTO for other non-ASIAS services. DTOs are responsible for authorizing access to or transferring data to ASIAS. A DTO can provide data on a routine basis in accordance with the third party cooperative agreement.
        </p>

        {/* <h4 style={{paddingTop: '1em'}}>Who are the current Data Transfer Organizations for Rotorcraft ASIAS?</h4>
        <p className='faq-p'>
        
        </p>
        <ul>
        <li style={{maxWidth: "none"}}>Helicopter Association International (HAI)</li>
        <li style={{maxWidth: "none"}}>MITRE Corporation</li>
        <li style={{maxWidth: "none"}}>Skytrac </li>
        <li style={{maxWidth: "none"}}>TruthData</li>
        </ul> */}

        {/* <h4 style={{paddingTop: '1em'}}>Why don’t I just sign a Cooperative Agreement with GDIT, the developer of the ASIAS database?</h4>
        <p className='faq-p'>
        The support entities will provide data de-identification and a means for it to be transferred to the ASIAS database environment. GDIT will not be accepting data directly at the current time. This is a future goal.
        </p> */}

        <h4 style={{paddingTop: '1em'}}>What if GDIT receives a Freedom of Information Act (FOIA) request or a subpoena for R-ASIAS data?</h4>
        <p className='faq-p'>
        Since GDIT is a private company, they are not subject to FOIA. As for a subpoena, like any other company, protection from a subpoena cannot be guaranteed. While there have been no subpoenas issued for any similar data sharing programs, the GDIT team intends on raising available defenses to ensure protection of operator information. Other similar defenses have succeeded with proprietary data collection programs like ASIAS. Many operators believe that in the event of a subpoena, the subpoena would be delivered directly to the operator and not to ASIAS. 
        </p>

        <h4 style={{paddingTop: '1em'}}>How will my data be protected?</h4>
        <p className='faq-p'>
        RAISE security controls for data and applications are carefully designed for data protection using AWS Cloud services. RAISE software applications inherit several security certifications from compliance programs from AWS. Additionally, customized data protection of user data are also deployed in accordance with ASIAS Procedures and Operations Plan for Rotorcraft. For example, if OSEs need to perform safety research and studies as directed by the R-IAT, the flight recorder data is de-identified prior to any research and analysis. Finally, system test and validation are thoroughly conducted to ensure data is protected and secured. 
        </p>
        

        <h4 style={{paddingTop: '1em'}}>The company data I provide to R-ASIAS is sensitive—how is it secured? </h4>
        <p className='faq-p'>
        The data you provide to R-ASIAS is stored by <span style={{color:'blue'}}>GDIT</span> on a secure cloud environment using Amazon Web Services (AWS) infrastructure, and GDIT is responsible for securing that data. GDIT, a General Dynamics subsidiary, employs secure, encrypted data feeds; strictly limits access to data; and employs secure firewalls with traffic monitoring, intrusion detection systems, log monitoring, and user audits. 
        </p>

        <h4 style={{paddingTop: '1em'}}>How is R-ASIAS governed?</h4>
        <p className='faq-p'>
        The ASIAS Executive Board (AEB), which is composed of representatives from Government and Industry, directs and authorizes ASIAS analyses including directed studies, assessment of safety enhancements, known risk monitoring, and vulnerability discovery. In the interest of enhancing aviation safety, the results of these analyses are shared with the ASIAS participants. There are three governing bodies under the AEB: the Commercial Issue Analysis Team (IAT) overseeing the commercial airline portion of ASIAS, the GA Issue Analysis Team (GA-IAT), overseeing the GA portion of ASIAS and the Rotorcraft Issue Analysis Team (R-IAT) overseeing the rotorcraft portion for R-ASIAS. All three are chaired and composed of Government and Industry representatives. In addition to the main IAT bodies, there are working groups concentrating on specific issues or tasks working in support of the IATs. 
        </p>

        <h4 style={{paddingTop: '1em'}}>What is the FAA’s role in ASIAS? Can they see my data? </h4>
        <p className='faq-p'>
        R-ASIAS is an FAA-funded program jointly developed by the FAA and industry with the sole purpose of improving aviation safety. The FAA works with industry to oversee and direct the program as part of the <span style={{color:'blue'}}>AEB</span>. The FAA also co-chairs, with industry, several of the safety and governing bodies that steer and contribute to R-ASIAS. The FAA cannot see any individual operator’s data—they can see the same aggregate, de-identified results the R-ASIAS operators can see. The FAA cannot use any R-ASIAS data or information for punitive or enforcement purposes per the governance documented in the ASIAS Procedures and Operations Plan for Rotorcraft. 
        </p>

        <h4 style={{paddingTop: '1em'}}>What is the <span style={{color:'blue'}}>Aviation Safety InfoShare</span> conference?  </h4>
        <p className='faq-p'>
        Aviation Safety InfoShare is a confidential, twice-yearly conference sponsored by the FAA, in which government and industry representatives share aviation safety concerns and discuss aviation safety issues and mitigations. InfoShare offers the safety community a unique opportunity to come together in a private setting to learn from each other’s experiences. The success of InfoShare relies on the active participation of the community—all ASIAS participants are invited to attend InfoShare and discuss how voluntary safety programs such as SMS, FDM/FOQA, ASAP, and Line Operations Safety Assessments (LOSA) are used to manage safety within your respective organizations. InfoShare offers a singular opportunity to share experiences, advancing aviation safety for the industry. The conference is held in the spring and fall, and the location varies amongst major cities in the continental U.S. It is typically attended by 1,000+ aviation professionals from various aviation communities. There is no charge to attend the conference.
        </p>


        <h4 style={{paddingTop: '1em'}}>What is the NGAFID?  </h4>
        <p className='faq-p'>
        The NGAFID is a system to collect and process flight data for operators typically flying smaller aircraft. The NGAFID was initially developed by the University of North Dakota as part of the FAA’s General Aviation Center of Excellence activities, and is housed at the University of North Dakota, NGAFID is part of ASIAS. Depending upon your aircraft configuration, flight data is uploaded to the NGAFID via A web interface for those operators flying smaller aircraft with a data collection capability (e.g., Garmin 1000, Garmin 3000). 
        <br /><br />
        Your flight data uploaded to NGAFID is only seen by you. You can view metrics of your data (e.g., exceedances, unstable approach) and also replay your flights. In the future, ASIAS will de-identify and aggregate all NGAFID data looking for national safety trends just as it does for other data collected by the ASIAS program.

        </p>


        <h4 style={{paddingTop: '1em'}}>Who is GDIT and what is its role in ASIAS?</h4>
        <p className='faq-p'>
        GDIT is at the forefront of aviation innovation, building and maintaining the systems that keep the National Airspace System safe. For over 50 years, GDIT has provided solutions to some of the FAA’s biggest technical challenges with new and emerging technologies. GDIT builds software that the FAA relies on everyday using intelligent automation to assist in all phases of flight, develops and manages critical security operations, and secures the FAA’s gateway to the cloud with enterprise-scale infrastructure. For the Rotorcraft ASIAS program, GDIT is developing the centralized data repository and operational infrastructure to securely share rotorcraft data within the aviation community to improve safety. The secure infrastructure is a scalable, serverless, cloud environment with a rich set of tools and visualizations on a unified platform that enables collaboration and integration opportunities.
        </p>

    </div>
    )

}

export default AboutContent