import React, { useState, useContext } from "react";
import { DataContext } from "../DataContext";
// import PropTypes from 'prop-types';
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { withSize } from "react-sizeme";
import DashboardWidget from "../DashboardWidget";
import { IconButton, Tooltip } from "@mui/material";
import AddList from "../AddList";
import SaveIcon from '@mui/icons-material/Save';
import DashboardIcon from '@mui/icons-material/Dashboard';
import UimcEventsMap from "../maps/UimcEventsMap";
import LoadingSkeleton from "../utils/LoadingSkeleton";
import UimcEventsControls from "../controls/UimcEventsControls";
import DataBrowser, { buildDataBrowserColumn } from "../misc/DataBrowser";
import BigNumber from "../misc/BigNumberUIMC";
import UimcEventsByDateScatterChart from "../charts/uimcevents/UimcEventsByDateScatterChart";
import DashboardWidgets from "../DashboardWidgets";
import UimcEventsByFacilityAndDaylightBarChart from "../charts/uimcevents/UimcEventsByFacilityAndDaylightBarChart";
import UimcEventsByAircraftTypeAndDaylightBarChart from "../charts/uimcevents/UimcEventsByAircraftTypeAndDaylightBarChart";
// import UimcEventsByIcaoSubcategoryAndDaylightBarChart from "../charts/uimcevents/UimcEventsByIcaoSubcategoryAndDaylightBarChart";
// import UimcEventsAverageDurationByIcaoSubcategoryAndDaylightBarChart from "../charts/uimcevents/UimcEventsAverageDurationByIcaoSubcategoryAndDaylightBarChart";
import UimcEventsAverageDurationByAircraftTypeAndDaylightBarChart from "../charts/uimcevents/UimcEventsAverageDurationByAircraftTypeAndDaylightBarChart";
import UimcEventsAverageDurationByDateBarChart from "../charts/uimcevents/UimcEventsAverageDurationByDateBarChart";
import UimcEventsByDaylightPieChart from "../charts/uimcevents/UimcEventsByDaylightPieChart";
import UimcEventsByStateAndDaylight from "../charts/uimcevents/UimcEventsByStateAndDaylight";
import UimcEventsByRegionAndDaylight from "../charts/uimcevents/UimcEventsByRegionAndDaylight";
import UimcEventsBySeasonPieChart from "../charts/uimcevents/UimcEventsBySeasonPieChart";
import UimcEventsByFlightRulePieChart from "../charts/uimcevents/UimcEventsByFlightRulePieChart";
import UimcEventsByAirspaceClassAndFlightRuleBarChart from "../charts/uimcevents/UimcEventsByAirspaceClassAndFlightRuleBarChart";
import UimcEventsByAirspaceNameAndFlightRuleBarChart from "../charts/uimcevents/UimcEventsByAirspaceNameAndFlightRuleBarChart";
import UIMCEventsByDurationHistogram from "../charts/uimcevents/UIMCEventsByDurationHistogram";

// Need to import styles for core functionality
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const propTypes = {};
const defaultProps = {};

const styles = {
  toolbarContainer: {
    display: "flex",
    justifyContent: "flex-end",
    zIndex: 1000,
  },
  toolbarBanner: {
    marginRight: "auto",
  },
};

function UimcEventsContent({ size: { width } }) {
  const SAVED_LAYOUTS_KEY = "UimcEventsLayout";

  const dataContext = useContext(DataContext);
  const uimcAggregateMetricsDashboardConfig = dataContext.uimcAggregateMetricsDashboardConfig[dataContext.UIMC_EVENTS_TAB_KEY];

  const originalItems = Object.keys(uimcAggregateMetricsDashboardConfig);

  const initialLayouts = {
    lg: originalItems.map(item => uimcAggregateMetricsDashboardConfig[item])
  };

  const loadedLayouts = dataContext.getFromLS(SAVED_LAYOUTS_KEY) || initialLayouts;
  const [layouts, setLayouts] = useState(
    loadedLayouts
  );

  // Initial set of widgets will be based on saved layouts data (defaults to initial layouts if no saved data found)
  const [items, setItems] = useState(loadedLayouts.lg.map(item => item.i));

  const onLayoutChange = (_, allLayouts) => {
    setLayouts(allLayouts);
  };

  const onLayoutSave = (e) => {
    e.stopPropagation();
    dataContext.saveToLS(SAVED_LAYOUTS_KEY, layouts);

    // Show snackbar notification on save
    dataContext.showSnack("top", "center", "Dashboard Layout Saved for UIMC Events!", dataContext.SNACK_SEVERITY.SUCCESS, 2000);
  };

  const onLayoutReset = (e) => {
    e.stopPropagation();
    const newLoadedLayouts = dataContext.getFromLS(SAVED_LAYOUTS_KEY) || initialLayouts;
    setLayouts(newLoadedLayouts);
    setItems(newLoadedLayouts.lg.map(item => item.i));
    dataContext.showSnack("top", "center", "Dashboard Layout Reset to Last Saved State for UIMC Events!", dataContext.SNACK_SEVERITY.SUCCESS, 2000);
  }

  const onRemoveItem = (itemId) => {
    // console.log(`Removing ${itemId} from the dashboard...`);
    setItems(items.filter((i) => i !== itemId));
  };

  const onAddItem = (itemId) => {
    setItems([...items, itemId]);
  };

  const averageNumericArray = (data) => {
    return dataContext.averageNumericArray(data);
  }

  const largestNumericArray = (data) => {
    return dataContext.largestNumericArray(data);
  }

  

  /**
   * Handle DataBrowser row selection event for drilling down on data record.
   * 
   * @param {string} key Key associated with target dataset for drilldown.
   * @param {any} value Value associated with key mapping in dataset for drilldown.
   */
  const handleRowSelected = (key, value) => {
    // Build data structure that imitates control filter
    // EXAMPLE:
    // {
    //   "phaseOfFlightSelect": {
    //     "id": "phaseOfFlightSelect",
    //     "label": "Standing, Hover Ground Effect",
    //     "column": "phaseofflight_mavg10",
    //     "values": [
    //       "standing",
    //       "hover ground effect"
    //      ]
    //    }
    // }    
    // console.log("Handling drilldown for selected key-value:", [key, value]);

    let filter = {
      [dataContext.UIMC_EVENTS_CONTENT_SELECT_KEY]: {
        id: dataContext.UIMC_EVENTS_CONTENT_SELECT_KEY,
        label: value,
        column: key,
        values: [value],
        track: true,
      }
    };

    dataContext.addControlFilter(filter);
  }

  // const roundingHandlerDefault = (data) => {
  //   return dataContext.roundStr(data);
  // }

  // const roundingHandlerLong = (data) => {
  //   return dataContext.roundStr(data, 6);
  // }

  // const humanReadableHandler = (data) => {
  //   return dataContext.capitalizeWords(data);
  // }

  // Core widgets list  
  const componentList = {
    // facilityCountsDataBrowser:
    //   dataContext.processing ? <LoadingSkeleton /> :
    //     <DataBrowser
    //       primaryTargetKey="uimc_events"
    //       defaultRowsPerPage={50}
    //       enableSearch
    //       primaryCol="facility"
    //       onRowSelected={handleRowSelected}
    //       metric="count"
    //       metricPrecision={0}
    //       metricAlias="UIMC Event Count"
    //       groupByDefaultOrder="desc"
    //       decorateNulls
    //       columnsOfInterest={[
    //         buildDataBrowserColumn({
    //           col: "facility",
    //           alias: "Facility",
    //           width: 30,
    //         }),
    //       ]}
    //     />,
    uimcEventsByDaylightPieChart:
      dataContext.processing ? <LoadingSkeleton /> :
        // <DataBrowser
        //   primaryTargetKey="uimc_events"
        //   enableSearch
        //   primaryCol="daylight"
        //   onRowSelected={handleRowSelected}
        //   metric="count"
        //   metricPrecision={0}
        //   metricAlias="UIMC Event Count"
        //   groupByDefaultOrder="desc"
        //   decorateNulls
        //   columnsOfInterest={[
        //     buildDataBrowserColumn({
        //       col: "daylight",
        //       alias: "Daylight State",
        //       width: 30,
        //     }),
        //   ]}
        // />,
        <UimcEventsByDaylightPieChart />,
    uimcEventsByDateAreaChart:
      dataContext.processing ? <LoadingSkeleton /> :
        <UimcEventsByDateScatterChart />,
    // uimcEventCountByFacilityAndDaylightBarChart:
    //   dataContext.processing ? <LoadingSkeleton /> :
    //     <UimcEventsByFacilityAndDaylightBarChart />,
    // uimcEventCountByIcaoCategoryAndDaylight:
    //   dataContext.processing ? <LoadingSkeleton /> :
    //     <UimcEventsByIcaoSubcategoryAndDaylightBarChart />,
    // uimcEventAverageDurationByIcaoCategoryAndDaylight:
    //   dataContext.processing ? <LoadingSkeleton /> :
    //     <UimcEventsAverageDurationByIcaoSubcategoryAndDaylightBarChart />,
    uimcEventsClusterMap:
      dataContext.processing ? <LoadingSkeleton /> :
        <UimcEventsMap
          mapId="uimc-events-map"
        />,
    uimcEventCountByAircraftTypeAndDaylightBarChart:
      dataContext.processing ? <LoadingSkeleton /> :
        <UimcEventsByAircraftTypeAndDaylightBarChart />,
        uimcEventsByDurationHistogram:
      dataContext.processing ? <LoadingSkeleton /> :
        <UIMCEventsByDurationHistogram />,
    uimcEventsByIcaoCategoryBigNumber:
      dataContext.processing ? <LoadingSkeleton /> :
        <BigNumber
          source="uimc_events"
          column="event_id"
          aggColumn="icao_category"
          value="ROTORCRAFT"
          smallerFont={true}
          metric="count"
          subheader="Event(s)"
          subheaderSize="4"
          decimalPlaces={0}
          simplifyNumber={false}
        />,
    uimcEventDurationByIcaoCategoryBigNumber:
      dataContext.processing ? <LoadingSkeleton /> :
        <BigNumber
          source="uimc_events"
          column="duration_seconds"
          aggColumn="icao_category"
          value="ROTORCRAFT"
          smallerFont={true}
          metric="average"
          subheader="Second(s)"
          subheaderSize="4"
          decimalPlaces={2}
        />,
        uimcUniqueTrackBigNumber:
      dataContext.processing ? <LoadingSkeleton /> :
        <BigNumber
          source="uimc_events"
          column="event_id"
          aggColumn="track_id"
          metric="groupcount"
          darkerFont={true}
          subheader="Flight(s)"
          subheaderSize="4"
          decimalPlaces={0}
          simplifyNumber={false}
        />,
        uimcEventsPerTrackBigNumber:
      dataContext.processing ? <LoadingSkeleton /> :
        <BigNumber
          source="uimc_events"
          column="event_id"
          aggColumn="track_id"
          darkerFont={true}
          metric="groupaverage"
          subheader="Events per Flight"
          subheaderSize="4"
          decimalPlaces={0}
          simplifyNumber={false}
        />,
    // uimcEventCountByAircraftTypeDataBrowser:
    //   dataContext.processing ? <LoadingSkeleton /> :
    //     <DataBrowser
    //       primaryTargetKey="uimc_events"
    //       // defaultRowsPerPage={50}
    //       enableSearch
    //       primaryCol="aircraft_type"
    //       onRowSelected={handleRowSelected}
    //       metric="count"
    //       metricPrecision={0}
    //       metricAlias="UIMC Event Count"
    //       groupByDefaultOrder="desc"
    //       decorateNulls
    //       columnsOfInterest={[
    //         buildDataBrowserColumn({
    //           col: "aircraft_type",
    //           alias: "Aircraft Type",
    //           width: 30,
    //         }),
    //       ]}
    //     />,
    uimcEventsDataBrowser:
      dataContext.processing ? <LoadingSkeleton /> :
        <DataBrowser
          primaryTargetKey="uimc_events"
          decorateNulls
          onRowSelected={handleRowSelected}
          defaultRowsPerPage={50}
          columnsOfInterest={[
            buildDataBrowserColumn({ col: "event_id2", alias: "Event ID" }),
            buildDataBrowserColumn({ col: "start_timestamp", alias: "Event Date" }),
            buildDataBrowserColumn({ col: "daylight", alias: "Daylight" }),
            buildDataBrowserColumn({ col: "season", alias: "Season" }),
            buildDataBrowserColumn({ col: "duration_seconds", alias: "Duration (seconds)" }),
            // buildDataBrowserColumn({ col: "facility", alias: "Facility" }),
            buildDataBrowserColumn({ col: "aircraft_type", alias: "Aircraft Type" }),
            buildDataBrowserColumn({ col: "start_latitude", alias: "Latitude" }),
            buildDataBrowserColumn({ col: "start_longitude", alias: "Longitude" }),
            buildDataBrowserColumn({ col: "start_altitude", alias: "MSL (ft)" }),
            buildDataBrowserColumn({ col: "start_ceiling_ft", alias: "Flight Ceiling (ft)" }),
            buildDataBrowserColumn({ col: "start_cloud_top_ft", alias: "Cloud Top (ft)" }),
            buildDataBrowserColumn({ col: "start_visibility_sm", alias: "Visibility (sm)" }),
            // buildDataBrowserColumn({ col: "flight_rule", alias: "IMC" }),
            // buildDataBrowserColumn({ col: "start_speed", alias: "Speed (kts)" }),
          ]}
        />,
        wxCamerasDataBrowser:
      dataContext.processing ? <LoadingSkeleton /> :
        <DataBrowser
          primaryTargetKey="wxCameraData"
          decorateNulls
          onRowSelected={handleRowSelected}
          defaultRowsPerPage={50}
          columnsOfInterest={[
            buildDataBrowserColumn({ col: "Name", alias: "Site Name" }),
            buildDataBrowserColumn({ col: "Country", alias: "Country" }),
            buildDataBrowserColumn({ col: "State", alias: "State" }),
            buildDataBrowserColumn({ col: "Count", alias: "Number of UIMC Events", defaultOrder: 'desc' }),
            buildDataBrowserColumn({ col: "AverageDist", alias: "Average Distance from UIMC Events (mi)"}),
            buildDataBrowserColumn({ col: "LargestDist", alias: "Longest Distance from a UIMC Event (mi)" }),
          ]}
        />,
    averageDurationByAircraftTypeAndDaylight:
      dataContext.processing ? <LoadingSkeleton /> :
        // <DataBrowser
        //   primaryTargetKey="uimc_events"
        //   // defaultRowsPerPage={50}
        //   enableSearch
        //   primaryCol="aircraft_type"
        //   metricCol="duration_seconds"
        //   onRowSelected={handleRowSelected}
        //   metric="average"
        //   metricAlias="Average Duration (seconds)"
        //   groupByDefaultOrder="desc"
        //   decorateNulls
        //   columnsOfInterest={[
        //     buildDataBrowserColumn({
        //       col: "aircraft_type",
        //       alias: "Aircraft Type",
        //       width: 30,
        //     }),
        //   ]}
        // />,
        <UimcEventsAverageDurationByAircraftTypeAndDaylightBarChart />,
        uimcEventsByStateAndDaylight:
      dataContext.processing ? <LoadingSkeleton /> :
        <UimcEventsByStateAndDaylight />,
        averageDurationByDate:
      dataContext.processing ? <LoadingSkeleton /> :
        <UimcEventsAverageDurationByDateBarChart />,
        uimcEventsByRegionAndDaylight:
      dataContext.processing ? <LoadingSkeleton /> :
        <UimcEventsByRegionAndDaylight />,
        uimcEventsBySeasonPieChart:
      dataContext.processing ? <LoadingSkeleton /> :
      <UimcEventsBySeasonPieChart />,
      uimcEventsByFlightRulePieChart:
      dataContext.processing ? <LoadingSkeleton /> :
        <UimcEventsByFlightRulePieChart />,
        uimcEventsByAirspaceClassAndFlightRuleChart:
        dataContext.processing ? <LoadingSkeleton /> :
        <UimcEventsByAirspaceClassAndFlightRuleBarChart />,
        uimcEventsByAirspaceNameAndFlightRuleChart:
        dataContext.processing ? <LoadingSkeleton /> :
        <UimcEventsByAirspaceNameAndFlightRuleBarChart />,

    uimcEventInsights:
      dataContext.processing ? <LoadingSkeleton /> :
        <DashboardWidgets dashboardType='UimcInsightsDashboardID' />,
  };

  return (
    <>
      {/* Dashboard tab management functions - right aligned if display is flex and content is justified as flex-end */}
      <div style={styles.toolbarContainer}>
        <div>
          <IconButton aria-label="save" onClick={onLayoutSave}>
            <Tooltip title="Save Dashboard Tab Layout" placement="top" arrow>
              <SaveIcon />
            </Tooltip>
          </IconButton>

          <IconButton aria-label="reset" onClick={onLayoutReset}>
            <Tooltip title="Reset Dashboard Tab Layout to Last Saved State" placement="top" arrow>
              <DashboardIcon />
            </Tooltip>
          </IconButton>

          <AddList
            items={items}
            onRemoveItem={onRemoveItem}
            onAddItem={onAddItem}
            originalItems={originalItems}
            dashboardKey={dataContext.UIMC_EVENTS_TAB_KEY}
            dashboardConfig={dataContext.uimcAggregateMetricsDashboardConfig}
          />
        </div>
      </div>

      {/* Add controls here as needed */}
      <UimcEventsControls />

      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={60}
        width={width * (1 / dataContext.appScale)}
        draggableHandle=".drag-handle"
        onLayoutChange={onLayoutChange}
        transformScale={dataContext.appScale} // Required to adapt transform translation offset when dragging
      >
        {items.map((key) => (
          
          <div
            key={key}
            data-grid={{ w: 6, h: 8, x: 0, y: Infinity }}
          >
            <DashboardWidget
              id={key}
              name={uimcAggregateMetricsDashboardConfig[key].name}
              className="drag-handle"
              onRemoveItem={onRemoveItem}
              fullscreenEnabled={false}
            >
              {componentList[key]}
            </DashboardWidget>
          </div>
        ))}
      </ResponsiveGridLayout>
    </>
  );
}

UimcEventsContent.propTypes = propTypes;
UimcEventsContent.defaultProps = defaultProps;

export default withSize({ refreshMode: "debounce", refreshRate: 60 })(UimcEventsContent);
