import React from 'react'
import'./UploadStatus.css'

const PageLoadSpinner = () => {
    var color = 'black'
    


    return (
    <div className="lds-dual-ring" />
       
    )
}

export default PageLoadSpinner
