import React, { useState, useEffect, useRef, useContext } from "react";
import { DataContext } from "../DataContext";
import PropTypes from 'prop-types';
import Helicopter from "../../icons/svg/Helicopter";
import L from "leaflet";
import 'leaflet.control.layers.tree';
import 'leaflet-groupedlayercontrol';
import 'leaflet-easybutton';
import 'leaflet.fullscreen';
import 'leaflet.markercluster';
import 'leaflet-timedimension';
import 'leaflet-sidebar-v2';

// Import Styles (requirement for many leaflet plugins)
import 'font-awesome/css/font-awesome.min.css';
import 'leaflet.control.layers.tree/L.Control.Layers.Tree.css';
import 'leaflet-groupedlayercontrol/src/leaflet.groupedlayercontrol.css';
import 'leaflet.fullscreen/Control.FullScreen.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet-easybutton/src/easy-button.css';
// import 'leaflet-sidebar-v2/css/leaflet-sidebar.min.css'; // NOTE: Using overridden styles in Map.css for all styles in leaflet-sidebar.min.css
import 'leaflet-timedimension/src/leaflet.timedimension.control.css';

import './Map.css';

const propTypes = {
  mapId: PropTypes.string.isRequired,
  startLoc: PropTypes.arrayOf(PropTypes.number),
  zoom: PropTypes.number,
  zoomSensitivity: PropTypes.number,
};

const defaultProps = {
  startLoc: [39.8283, -98.5795],
  zoom: 4,
  zoomSensitivity: 1,
};

const PlaybackMap = ({
  mapId,
  startLoc,
  zoom,
  zoomSensitivity,
}) => {
  // const mapId = `map-${Math.floor(Math.random(1, 1000) * 1000)}`;

  const MAP_PAN_CONFIG = {
    animate: true,
    duration: 1.5
  }

  // LAYER CONFIG
  const TOGGLE_LAYER_GROUP_NAME = "Metric Layers";
  const OVERLAY_LAYER_GROUP_NAME = "Overlay Layers";
  const NO_LAYER_NAME = "None";
  const EXCEEDANCE_TYPE_LAYER_NAME = "Exceedance Type";
  const EXCEEDANCE_SEVERITY_LAYER_NAME = "Exceedance Severity";
  const LOC_TYPE_LAYER_NAME = "Loss of Control (LOC) Type";
  const LOC_SEVERITY_LAYER_NAME = "Loss of Control (LOC) Severity";
  const GROUND_SPEED_LAYER_NAME = "Ground Speed (kts)";
  const AGL_LAYER_NAME = "AGL (ft)";
  const PHASE_OF_FLIGHT_LAYER_NAME = "Phase of Flight";
  const OBSTACLES_LAYER_NAME = "Obstacles";
  // const PLAYBACK_LAYER_NAME = "Playback Flight Track";

  // LEGEND CONFIG
  const EXCEEDANCE_TYPE_LEGEND_NAME = "Exceedance Type Legend";
  const EXCEEDANCE_SEVERITY_LEGEND_NAME = "Exceedance Severity Legend";
  const LOC_TYPE_LEGEND_NAME = "Loss of Control (LOC) Type Legend";
  const LOC_SEVERITY_LEGEND_NAME = "Loss of Control (LOC) Severity Legend";
  const GROUND_SPEED_LEGEND_NAME = "Ground Speed (kts) Legend";
  const AGL_LEGEND_NAME = "AGL (ft) Legend";
  const PHASE_OF_FLIGHT_LEGEND_NAME = "Phase of Flight Legend";

  // SIDEBAR CONFIG
  const OPEN_SIDEBAR_ON_LOAD = true;
  const SIDEBAR_OFFSET_EXPANDED = 594;
  const SIDEBAR_OFFSET_COLLAPSED = 36;
  const SIDEBAR_CONTROL_CONFIG = {
    autopan: false,       // Whether to maintain the centered map point when opening the sidebar
    closeButton: true,    // Whether t add a close button to the panes
    container: 'sidebar', // The DOM container or #ID of a predefined sidebar container that should be used
    position: 'left',     // Left or right
  };

  // MISC
  const TOOLTIP_PANEL_NAME = "running-tooltip-panel";
  const TOOLTIP_PANEL_CONTENT_NAME = "running-tooltip-content";

  // Obtain reference to global data context consumer
  const dataContext = useContext(DataContext);

  const isMounted = useRef(false);
  const mapRef = useRef(null);
  const tileRef = useRef(null);
  const overlayTileRef = useRef(null);
  const baseControlRef = useRef(null);
  const baseLayersRef = useRef(null);
  const overlayLayersRef = useRef(null);
  const zoomControlRef = useRef(null);
  const fullscreenControlRef = useRef(null);
  const scaleControlRef = useRef(null);
  const dataControlRef = useRef(null);
  const playbackSyncControlRef = useRef(null);
  const playbackSyncButtonsRef = useRef(null);
  const sidebarControlRef = useRef(null);
  const runningTooltipContentRef = useRef(null);
  const flightLocRef = useRef(null);
  const legendsMapRef = useRef(null);
  const activeLegendRef = useRef(null);
  const exceedanceTypeMarkersRef = useRef(null);
  const exceedanceSeverityMarkersRef = useRef(null);
  const nonExceedanceTypeMarkersRef = useRef(null);
  const nonExceedanceSeverityMarkersRef = useRef(null);
  const locTypeMarkersRef = useRef(null);
  const locSeverityMarkersRef = useRef(null);
  const nonLocTypeMarkersRef = useRef(null);
  const nonLocSeverityMarkersRef = useRef(null);
  const groundSpeedMarkersRef = useRef(null);
  const aglMarkersRef = useRef(null);
  const phaseOfFlightMarkersRef = useRef(null);
  const obstacleMarkersRef = useRef(null);
  const activeTrackPointMarkersRef = useRef(null);

  // Playback/TimeDimension refs
  const timeDimensionRef = useRef(null);
  const playerRef = useRef(null);
  const timeDimensionControlRef = useRef(null);

  // Define layers
  const placeholderLayer = useRef(null);
  const exceedanceTypeLayer = useRef(null);
  const exceedanceSeverityLayer = useRef(null);
  const locTypeLayer = useRef(null);
  const locSeverityLayer = useRef(null);
  const groundSpeedLayer = useRef(null);
  const aglLayer = useRef(null);
  const phaseOfFlightLayer = useRef(null);
  const obstaclesLayer = useRef(null);
  const timeDimensionLayer = useRef(null);

  // State
  const [overlays, setOverlays] = useState([]);
  const [currentPos, setCurrentPos] = useState(null);
  const [initLoad, setInitLoad] = useState(true);
  const [playbackSyncMode, setPlaybackSyncMode] = useState(false);

  L.Map.prototype.panToOffset = function (latlng, offset, options) {
    var x = this.latLngToContainerPoint(latlng).x - offset[0]
    var y = this.latLngToContainerPoint(latlng).y - offset[1]
    var point = this.containerPointToLatLng([x, y])
    return this.setView(point, this._zoom, { pan: options })
  }

  // Create custom date formatter in playback control by extending TimeDimension control
  // See: https://github.com/socib/Leaflet.TimeDimension/issues/167#issuecomment-466705903
  L.Control.TimeDimensionCustom = L.Control.TimeDimension.extend({
    _getDisplayDateFormat: function (date) {
      return dataContext.toHumanReadableDateStr(date);
    }
  });

  // Create custom control widget for controlling the playback sync mode
  // See: http://mourner.github.io/Leaflet/reference.html#control
  L.Control.PlaybackSync = L.Control.extend({
    initialize: function (title, options) {
      this.title = title;
      this.startingState = options.startingState || false;
      L.Util.setOptions(this, options);
    },
    onAdd: function () {
      // Create the control container
      let container = L.DomUtil.create('div', 'playback-sync-control');
      // console.log("Playback control title:", this.title);

      // Build basic form control with radio buttons controlling playback sync mode
      container.innerHTML = `
        <strong style="color: black;">${this.title}</strong>
        <div>
          <input type="radio" id="playback-sync-on" name="playback-sync-state" value="true" ${this.startingState ? "checked" : ""}>
          <label for="playback-sync-on">On</label>
          <input type="radio" id="playback-sync-off" name="playback-sync-state" value="false" ${!this.startingState ? "checked" : ""}>
          <label for="playback-sync-off">Off</label>
        </div>
      `;

      return container;
    }
  });

  /**
   * Generate HTML string containing a FontAwesome icon based on
   * the specified FontAwesome icon string.
   * 
   * See: https://fontawesome.com/v4.7/icons/
   * 
   * Note: Set the CSS for "icons" class to adjust style of all icons (e.g., font size, etc.)
   * 
   * @param {string} faIconCls FontAwesome icon class string (e.g., fa fa-globe)
   * @returns HTML string representing the FontAwesome icon
   */
  // const faIconHtmlStr = (faIconCls) => {
  //   return `<span class="icons"><i class="${faIconCls}" aria-hidden="true"/></span>`;
  // }

  // Define basemap tiles from data context
  const includedBaseMaps = [
    "Light Basemap",
    "Dark Basemap",
    "Open Street Map",
    "VFR Sectional Basemap",
    "Helo Chart Basemap",
    "Esri World Imagery (Terrain)",
  ];

  tileRef.current = includedBaseMaps.reduce(
    (prev, current) => {
      return {
        ...prev, [current]: L.tileLayer(dataContext.baseMaps[current].url, {
          attribution: dataContext.baseMaps[current].attribution,
          maxZoom: dataContext.MAP_MAX_ZOOM,
        })
      }
    }, {}
  );

  // Base tile for the map:
  overlayTileRef.current = null;

  const mapStyles = {
    overflow: "hidden",
    width: "100%",
    height: "100%"
  };

  // Options for our map instance:
  // See for map parameter API reference: https://leafletjs.com/reference.html
  // const startingBasemap = props.startingBasemap in baseMaps ? props.startingBasemap;
  const startingBasemap = "Open Street Map";
  const mapParams = {
    center: startLoc,
    zoom: zoom,
    zoomSnap: zoomSensitivity != null && zoomSensitivity >= 0.2 ? zoomSensitivity : 0.25,
    zoomDelta: zoomSensitivity != null && zoomSensitivity >= 0.2 ? zoomSensitivity : 0.25,
    zoomControl: false, // Set to false for custom zoom control integration
    // maxBounds: L.latLngBounds(L.latLng(-150, -240), L.latLng(150, 240)),
    maxZoom: dataContext.MAP_MAX_ZOOM,
    closePopupOnClick: false,
    preferCanvas: true, // Improve performance/scalability for loading layers and shapes
    layers: [tileRef.current[startingBasemap]] // Start with just the base layer (defines the initial base map that will be displayed)
  };

  const buildFeaturedTooltip = (bindTarget, row, { color = null, direction = null, asPopup = false } = {}) => {
    // Build tool tip off all properties
    var tooltipText = "";

    // Start table
    tooltipText += "<table>";
    for (const [key, value] of Object.entries(row)) {
      let iconData = `<td><i class="fa fa-square tooltip-icon-fonts" style="color: ${color}; margin-right: 5px;"></i></td>`;
      tooltipText += `
        <tr>
          ${color !== null ? iconData : ""}
          <td style="color: #989898"><b>${key}&nbsp</b></td>
          <td><b>${value}</b></td>
        </tr>
      `;
    }

    // End table
    tooltipText += "</table>";

    if (asPopup) {
      bindTarget.bindPopup(tooltipText, {
        className: dataContext.darkMode ? "custom-map-popup-dark" : "custom-map-popup-light",
        direction: direction,
        // minWidth: 450,
        maxWidth: 450,
        maxHeight: 400,
        // offset: L.point(0, 180)
      });
    } else {
      bindTarget.bindTooltip(tooltipText, {
        className: dataContext.darkMode ? "custom-map-tooltip-dark" : "custom-map-tooltip-light",
        direction: direction,
        // interactive: true,
        // offset: L.point(0, 180)
      });
    }
  }

  /**
   * Build a new legend based on a set of classes/colors in a map.
   * 
   * @param {object}  cb_map Class/Color break map with keys representing legend labels and values representing the colors.
   * @param {string}  header Title for the legend to build.
   * @param {string}  position Position of the legend. Can be one of 'topleft', 'topright', 'bottomleft', 'bottomright'
   * @param {boolean} keepOriginalText Whether to convert provided legend labels to human readable form.
   * @param {boolean} withPriority Whether to expect the keys in the provided cb_map to represent priority or not. Preserve ascending order by priority if enabled.
   * @returns Leaflet control object representing the built map legend.
   */
  const buildLegend = ({ cb_map, header, position = "topright", keepOriginalText = false, withPriority = false } = {}) => {
    let legend = L.control({ position: position });
    legend.onAdd = function () {
      // Create human readable labels for class/color break map
      let humanReadableMap = {}
      Object.keys(cb_map).map((item) => {
        let key = withPriority ? cb_map[item].label : item;
        let sep = key.includes("_") ? "_" : " ";
        let formattedKey = keepOriginalText ? key : dataContext.capitalizeWords(key, sep);

        if (withPriority) {
          humanReadableMap[item] = { label: formattedKey, color: cb_map[item].color };
        } else {
          humanReadableMap[formattedKey] = cb_map[key];
        }

        return true;
      });

      let div = L.DomUtil.create('div', 'legend-container');
      let labels = [`<strong style="color: black;">${header}</strong>`];
      let categories = withPriority ? Object.keys(humanReadableMap).sort((a, b) => parseInt(a) - parseInt(b)) : Object.keys(humanReadableMap);

      for (let item of categories) {
        let category = withPriority ? humanReadableMap[item].label : item;
        let color = withPriority ? humanReadableMap[item].color : humanReadableMap[category];
        labels.push(
          `<i class="fa fa-circle legend-icon-fonts" style="color: ${color};"><span class="legend-icon-inner-text">${category ? category : "+"}</span></i>`
        );
      }

      div.innerHTML = labels.join("<br>");
      return div;
    }

    // Finally, return the built legend for implementation
    return legend;
  }

  const fitMapBounds = (map) => {
    if (map) {
      if (map.boundsTarget) {
        const { projectionOffset } = sidebarControlRef.current;
        // console.log(`Fitting to bounds target (projection offset = ${projectionOffset})...`, map.boundsTarget);
        let sidebarPos = SIDEBAR_CONTROL_CONFIG.position.toUpperCase();
        map.fitBounds(map.boundsTarget, {
          paddingTopLeft: [sidebarPos === "LEFT" ? projectionOffset : 0, 0],
          paddingBottomRight: [sidebarPos === "RIGHT" ? projectionOffset : 0, 0],
        });
      } else {
        map.flyTo(startLoc, zoom, MAP_PAN_CONFIG);
      }
    }
  }

  const onEnterFullscreen = () => {
    // TODO Handle any additional actions that should happen once the map has entered fullscreen mode
  }

  const onExitFullscreen = () => {
    // TODO Handle any additional actions that should happen once the map has exited fullscreen mode
  }

  const onPanClicked = (btn, map) => {
    fitMapBounds(map);
  }

  const onZoomClicked = () => {
    // TODO Handle zoom event by obtaining current zoom from map reference
    // console.log("Zoom Changed:", mapRef.current.getZoom());
  }

  const onMapMoveEnded = () => {
    // Invalidate size of map to refresh basemap resolution and fill in any rendering gaps
    if (mapRef.current) {
      mapRef.current.invalidateSize();

      // Save current map location in state
      let center = mapRef.current.getCenter();
      let zoom = mapRef.current.getZoom();
      // console.log("center: " + center + ", zoom: " + zoom);
      setCurrentPos({ "pos": center, "zoom": zoom });
    }
  }

  /**
   * Dynamically unbind tooltips for previously active layer and build/bind tooltips
   * for newly selected (active) layer.
   * 
   * @param {string} layerName Name of the newly selected active layer.
   */
  const handleActiveLayerChanged = (layerName) => {
    // Unbind/Delete tooltips from previously active markers if there were previously active markers
    if (activeTrackPointMarkersRef.current) {
      Array.from(activeTrackPointMarkersRef.current).forEach(markerRef => {
        markerRef.marker.unbindTooltip();
      });
    }

    // Select new active markers target to build tooltips for
    let newActiveMarkers = null;
    switch (layerName) {
      case EXCEEDANCE_TYPE_LAYER_NAME:
        newActiveMarkers = [...exceedanceTypeMarkersRef.current, ...nonExceedanceTypeMarkersRef.current];
        break;
      case EXCEEDANCE_SEVERITY_LAYER_NAME:
        newActiveMarkers = [...exceedanceSeverityMarkersRef.current, ...nonExceedanceSeverityMarkersRef.current];
        break;
      case LOC_TYPE_LAYER_NAME:
        newActiveMarkers = [...locTypeMarkersRef.current, ...nonLocTypeMarkersRef.current];
        break;
      case LOC_SEVERITY_LAYER_NAME:
        newActiveMarkers = [...locSeverityMarkersRef.current, ...nonLocSeverityMarkersRef.current];
        break;
      case GROUND_SPEED_LAYER_NAME:
        newActiveMarkers = groundSpeedMarkersRef.current;
        break;
      case AGL_LAYER_NAME:
        newActiveMarkers = aglMarkersRef.current;
        break;
      case PHASE_OF_FLIGHT_LAYER_NAME:
        newActiveMarkers = phaseOfFlightMarkersRef.current;
        break;
      default:
        break;
    }

    // console.log("New Active Markers:", newActiveMarkers);
    // Iterate target list of markers and build the tooltips
    if (newActiveMarkers) {
      Array.from(newActiveMarkers).forEach(markerRef => {
        const { marker } = markerRef;
        buildFeaturedTooltip(marker, marker.fieldsOfInterest, {
          color: marker.indicatorColor,
          asPopup: false
        });
      });
    }

    // Set reference to active markers for pre-processing tooltip teardown on next call
    activeTrackPointMarkersRef.current = newActiveMarkers;
  }

  /**
   * Handle layer change events and process newly active layer accordingly.
   * 
   * @param {object} layer New active layer object.
   */
  const onLayerChange = (layer) => {
    // console.log("Layer changed", layer);
    // One legend should always be showing
    if (layer.name in overlayLayersRef.current[TOGGLE_LAYER_GROUP_NAME]) {
      // console.log("Processing as new active layer...", layer.name);
      if (activeLegendRef.current) {
        mapRef.current.removeControl(activeLegendRef.current);
      }

      // Only set new legend if the active layer isn't a layer without a legend
      if (layer.name !== NO_LAYER_NAME) {
        activeLegendRef.current = legendsMapRef.current[layer.name];
        activeLegendRef.current.addTo(mapRef.current);
      }

      // Change active layer's marker tooltips
      handleActiveLayerChanged(layer.name);
    }
  }

  const getCurrentPlaybackSyncOffset = () => {
    const { projectionOffset } = sidebarControlRef.current;
    const offsetFactor = SIDEBAR_CONTROL_CONFIG.position.toUpperCase() === "LEFT" ? 1 : -1;
    return (projectionOffset * offsetFactor) / 2;
  }

  /**
   * Handle playback sync state changes that are controlled from radio buttons in a custom
   * Leaflet control widget.
   * 
   * @param {object} e Event object containing the playback sync state in target value.
   */
  const onPlaybackSyncModeChange = (e) => {
    const { value } = e.target;
    const valueAsBoolean = value && value.toUpperCase() === "TRUE"; // Default false
    setPlaybackSyncMode(valueAsBoolean);

    if (mapRef.current) {
      // Toggle map view based whether playback sync mode is enabled or not
      if (valueAsBoolean && mapRef.current.lastMarkerLatLng) {
        let offset = getCurrentPlaybackSyncOffset();
        mapRef.current.panToOffset(mapRef.current.lastMarkerLatLng, [offset, 0], { animate: false, duration: 0 });
        // mapRef.current.panTo(mapRef.current.lastMarkerLatLng, { animate: false, duration: 0 });
      } else {
        if (mapRef.current.boundsTarget) {
          fitMapBounds(mapRef.current);
        } else {
          mapRef.current.flyTo(startLoc, zoom, MAP_PAN_CONFIG);
        }
      }
    }

    // console.log("Playback sync mode changed to:", valueAsBoolean);
  }

  const onSidebarOpen = (e) => {
    // console.log(`Sidebar opened (new offset = ${SIDEBAR_OFFSET_EXPANDED}):`, e);
    sidebarControlRef.current.projectionOffset = SIDEBAR_OFFSET_EXPANDED;

    // Set map view based on sync mode state
    if (mapRef.current.playbackSyncMode && mapRef.current.lastMarkerLatLng) {
      let offset = getCurrentPlaybackSyncOffset();
      mapRef.current.panToOffset(mapRef.current.lastMarkerLatLng, [offset, 0], { animate: false, duration: 0 });
    } else {
      fitMapBounds(mapRef.current);
    }
  }

  const onSidebarClose = (e) => {
    // console.log(`Sidebar closed (new offset = ${SIDEBAR_OFFSET_COLLAPSED}):`, e);
    sidebarControlRef.current.projectionOffset = SIDEBAR_OFFSET_COLLAPSED;

    // Set map view based on sync mode state
    if (mapRef.current.playbackSyncMode && mapRef.current.lastMarkerLatLng) {
      let offset = getCurrentPlaybackSyncOffset();
      mapRef.current.panToOffset(mapRef.current.lastMarkerLatLng, [offset, 0], { animate: false, duration: 0 });
    } else {
      fitMapBounds(mapRef.current);
    }
  }

  const clearControls = () => {
    // mapRef.current.off();
    mapRef.current.off("moveend", onMapMoveEnded);
    mapRef.current.off('enterFullscreen', onEnterFullscreen);
    mapRef.current.off('exitFullscreen', onExitFullscreen);
    mapRef.current.off("zoomstart", onZoomClicked);
    mapRef.current.off('overlayadd', onLayerChange);

    // Remove the overlays control from the map
    if (baseControlRef.current) {
      mapRef.current.removeControl(baseControlRef.current);
      baseControlRef.current = null;
    }

    if (dataControlRef.current) {
      mapRef.current.removeControl(dataControlRef.current);
      dataControlRef.current = null;
    }

    if (activeLegendRef.current) {
      mapRef.current.removeControl(activeLegendRef.current);
      activeLegendRef.current = null;
    }

    if (zoomControlRef.current) {
      mapRef.current.removeControl(zoomControlRef.current);
      zoomControlRef.current = null;
    }

    if (fullscreenControlRef.current) {
      mapRef.current.removeControl(fullscreenControlRef.current);
      fullscreenControlRef.current = null;
    }

    if (flightLocRef.current) {
      mapRef.current.removeControl(flightLocRef.current);
      flightLocRef.current = null;
    }

    if (scaleControlRef.current) {
      mapRef.current.removeControl(scaleControlRef.current);
      scaleControlRef.current = null;
    }

    if (timeDimensionControlRef.current) {
      mapRef.current.removeControl(timeDimensionControlRef.current);
      timeDimensionControlRef.current = null;
    }

    if (playbackSyncControlRef.current) {
      mapRef.current.removeControl(playbackSyncControlRef.current);

      if (playbackSyncButtonsRef.current) {
        playbackSyncButtonsRef.current.forEach(radio => radio.removeEventListener('change', onPlaybackSyncModeChange));
        playbackSyncButtonsRef.current = null;
      }

      playbackSyncControlRef.current = null;
    }

    if (sidebarControlRef.current) {
      mapRef.current.removeControl(sidebarControlRef.current);

      sidebarControlRef.current.off('opening', onSidebarOpen);
      sidebarControlRef.current.off('closing', onSidebarClose);

      // NOTE: If any issues occur when removing the sidebar control, refer to https://github.com/noerw/leaflet-sidebar-v2#remove-sidebar
      sidebarControlRef.current.remove();
      sidebarControlRef.current._container = null
      sidebarControlRef.current = null;
    }
  }

  const buildControls = () => {
    // See https://leafletjs.com/reference.html#map-event
    // Fired when the center of the map stops changing (e.g. user stopped dragging the map or when a non-centered zoom ends).
    mapRef.current.on("moveend", onMapMoveEnded);

    if (!baseControlRef.current) {
      baseControlRef.current = L.control.layers(
        tileRef.current, overlayTileRef.current, { collapsed: true }
      ).addTo(mapRef.current);
    }

    // Add sidebar control:
    if (!sidebarControlRef.current) {
      sidebarControlRef.current = L.control.sidebar(SIDEBAR_CONTROL_CONFIG).addTo(mapRef.current);
      sidebarControlRef.current.projectionOffset = OPEN_SIDEBAR_ON_LOAD ? SIDEBAR_OFFSET_EXPANDED : SIDEBAR_OFFSET_COLLAPSED;

      // runningTooltipContentRef.current = L.DomUtil.create("div");
      // runningTooltipContentRef.current.innerHTML = dataContext.generateText();

      sidebarControlRef.current.on('opening', onSidebarOpen);
      sidebarControlRef.current.on('closing', onSidebarClose);

      let paneContent = `
        <div id="${TOOLTIP_PANEL_CONTENT_NAME}" className="running-tooltip-pane"></div>
      `;

      let panelContent = {
        id: TOOLTIP_PANEL_NAME,                                       // UID, used to access the panel
        tab: '<i class="fa fa-info-circle" aria-hidden="true"></i>',  // Content can be passed as HTML string,
        pane: paneContent,                                            // DOM elements can be passed, too
        title: 'Flight Track Info',                                   // An optional pane header
        position: 'top'                                               // Optional vertical alignment, defaults to 'top'
      };

      sidebarControlRef.current.addPanel(panelContent);
      runningTooltipContentRef.current = document.getElementById(TOOLTIP_PANEL_CONTENT_NAME);

      // Open the running tooltip panel initially as configured
      if (OPEN_SIDEBAR_ON_LOAD) {
        sidebarControlRef.current.open(TOOLTIP_PANEL_NAME);
      }
    }

    // Add zoomControl:
    if (!zoomControlRef.current) {
      zoomControlRef.current = L.control.zoom({
        position: "topleft"
      }).addTo(mapRef.current);
    }

    // Add fullscreen control:
    if (!fullscreenControlRef.current) {
      fullscreenControlRef.current = L.control.fullscreen({
        position: 'topleft', // change the position of the button can be topleft, topright, bottomright or bottomleft, default topleft
        title: 'Enter fullscreen mode', // change the title of the button, default Full Screen
        titleCancel: 'Exit fullscreen mode', // change the title of the button when fullscreen is on, default Exit Full Screen
        content: null, // change the content of the button, can be HTML, default null
        forceSeparateButton: true, // force separate button to detach from zoom buttons, default false
        forcePseudoFullscreen: false, // force use of pseudo full screen even if full screen API is available, default false (will only fit bounds of parent if true)
        fullscreenElement: false // Dom element to render in full screen, false by default, fallback to map._container
      }).addTo(mapRef.current);

      // events are fired when entering or exiting fullscreen.
      mapRef.current.on('enterFullscreen', onEnterFullscreen);
      mapRef.current.on('exitFullscreen', onExitFullscreen);
    }

    if (!flightLocRef.current) {
      flightLocRef.current = L.easyButton({
        states: [{
          stateName: 'flight-location',
          // icon: faIconHtmlStr("fa fa-plane"),
          icon: Helicopter(),
          title: 'Pan and zoom to flight',
          onClick: onPanClicked
        }]
      }).addTo(mapRef.current);

      mapRef.current.on("zoomstart", onZoomClicked);
    }

    // Add playback sync control:
    if (!playbackSyncControlRef.current) {
      // Build custom control widget to toggle playback sync mode (sync mode disabled by default)
      playbackSyncControlRef.current = new L.Control.PlaybackSync("Follow Flight", {
        position: 'topleft',
        startingState: playbackSyncMode
      });

      mapRef.current.addControl(playbackSyncControlRef.current);

      // See: https://www.techiedelight.com/bind-change-event-handler-radio-button-javascript/
      playbackSyncButtonsRef.current = document.querySelectorAll('input[type=radio][name="playback-sync-state"]');
      playbackSyncButtonsRef.current.forEach(radio => radio.addEventListener('change', onPlaybackSyncModeChange));
    }

    // Add scale control
    // See for scale options: https://leafletjs.com/reference.html#control-scale-l-control-scale
    if (!scaleControlRef.current) {
      scaleControlRef.current = L.control.scale().addTo(mapRef.current);
    }

    // Add TimeDimension (Playback) control:
    if (!timeDimensionRef.current) {
      // See: https://github.com/socib/Leaflet.TimeDimension#ltimedimension
      timeDimensionRef.current = L.timeDimension({
        period: `PT1S`, // https://en.wikipedia.org/wiki/ISO_8601#Durations - (e.g., PT1S = 1 second interval; PT5M = 5 minute interval, etc.)
      });

      mapRef.current.timeDimension = timeDimensionRef.current;
    }

    if (!playerRef.current) {
      playerRef.current = new L.TimeDimension.Player({
        transitionTime: 100,
        loop: false,
        startOver: true
      }, timeDimensionRef.current);
    }

    if (!timeDimensionControlRef.current) {
      // See: https://github.com/socib/Leaflet.TimeDimension#lcontroltimedimension
      let timeDimensionControlOptions = {
        player: playerRef.current,
        TimeDimension: timeDimensionRef.current,
        position: 'bottomleft',
        autoPlay: false,
        minSpeed: 1,
        maxSpeed: 100,
        timeSteps: 1,
        speedStep: 1, // Default 0.1
        timeSliderDragUpdate: true,
        timeZones: ["UTC"],
      };

      timeDimensionControlRef.current = new L.Control.TimeDimensionCustom(timeDimensionControlOptions);
      // timeDimensionControlRef.current.addTo(mapRef.current);
      mapRef.current.addControl(timeDimensionControlRef.current);
    }
  }

  /**
   * Iterate list of marker pointers and remove their click events. Returns null
   * for optional initialization of markers ref list passed in as an argument.
   * 
   * @param {array} markersRef List of marker pointers to clear click events.
   * @returns Null for setting markers ref list to null.
   */
  const clearMarkerClickEvents = (markersRef) => {
    if (markersRef) {
      for (let entry of markersRef) {
        const { marker, clickEvent } = entry;
        marker.off("click", clickEvent)
      }
    }

    // Return null for setting markers ref to null since setting null in function only has local scope
    return null;
  }

  // Detect when this component is unmounted
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    }
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      if (mapRef.current) {
        mapRef.current.playbackSyncMode = playbackSyncMode;
        // console.log("Playback Sync Mode changed to:", mapRef.current.playbackSyncMode);
      }
    }
  }, [playbackSyncMode]);

  useEffect(() => {
    if (isMounted.current) {
      /**
       * Build and obtain reference to a custom marker click event function
       * based on configured fields of interest on marker.
       * 
       * @param {L.CircleMarker} marker Leaflet circle marker object.
       * @returns Custom marker click event based on configured fields of interest.
       */
      const getMarkerClickEvent = (marker) => {
        const onMarkerClick = () => {
          // Only build popup if it hasn't been built already
          if (!marker.getPopup()) {
            // Build popup on the fly
            buildFeaturedTooltip(marker, marker.fieldsOfInterest, {
              color: marker.indicatorColor,
              asPopup: true
            });
          }

          // Manually open the popup
          marker.openPopup();

          // Manually close tooltip
          // NOTE: Need to add minor buffer before triggering this event to prevent it from being consumed by manual openPopup call
          setTimeout(() => {
            marker.closeTooltip();
          });

          // Destroy the popup as soon as it's closed
          // NOTE: This event currently causes a memory leak
          // marker.getPopup().on("remove", onRemovePopup);
        }

        // Return function pointer to event
        return onMarkerClick;
      }

      // Handle corner case for when basemap tile reference is cleared out when filters are applied
      if (!tileRef.current) {
        tileRef.current = includedBaseMaps.reduce(
          (prev, current) => {
            return {
              ...prev, [current]: L.tileLayer(dataContext.baseMaps[current].url, {
                attribution: dataContext.baseMaps[current].attribution,
                maxZoom: dataContext.MAP_MAX_ZOOM,
              })
            }
          }, {}
        );

        // Need to reset the initial map layers from null to starting map so the starting map is selected by default
        mapParams.layers = [tileRef.current[startingBasemap]];
      }

      // Controls:
      if (!mapRef.current) {
        mapRef.current = L.map(mapId, mapParams);
      }

      if (!placeholderLayer.current) {
        placeholderLayer.current = L.layerGroup();
      }

      if (!exceedanceTypeLayer.current) {
        exceedanceTypeLayer.current = L.layerGroup();
      }

      if (!exceedanceSeverityLayer.current) {
        exceedanceSeverityLayer.current = L.layerGroup();
      }

      if (!locTypeLayer.current) {
        locTypeLayer.current = L.layerGroup();
      }

      if (!locSeverityLayer.current) {
        locSeverityLayer.current = L.layerGroup();
      }

      if (!groundSpeedLayer.current) {
        groundSpeedLayer.current = L.layerGroup();
      }

      if (!aglLayer.current) {
        aglLayer.current = L.layerGroup();
      }

      if (!phaseOfFlightLayer.current) {
        phaseOfFlightLayer.current = L.layerGroup();
      }

      if (!obstaclesLayer.current) {
        obstaclesLayer.current = L.layerGroup();
      }

      // Add the base layer to the control:
      clearControls();
      buildControls();

      // Process data and add layers to map if they don't already exist
      if (mapRef.current) {
        let dataTarget = dataContext.filteredReducedData ? dataContext.filteredReducedData : dataContext.baseReducedData;

        if (dataTarget) {
          // Iterate overlays and remove them before adding new overlays
          for (var overlay of overlays) {
            mapRef.current.removeLayer(overlay);
          }

          // Clear overlays state
          setOverlays([]);

          // Build legend colors based on actual track point values
          let legendColors = {
            [EXCEEDANCE_TYPE_LEGEND_NAME]: {},
            [EXCEEDANCE_SEVERITY_LEGEND_NAME]: {},
            [LOC_TYPE_LEGEND_NAME]: {},
            [LOC_SEVERITY_LEGEND_NAME]: {},
            [GROUND_SPEED_LEGEND_NAME]: {},
            [AGL_LEGEND_NAME]: {},
            [PHASE_OF_FLIGHT_LEGEND_NAME]: {},
          }

          // Extract lookup table and data from track points or exceedance points if track points aren't available
          let trackDataExists = dataTarget.track_points && dataTarget.track_points.data && dataTarget.track_points.data.length > 0;
          let trackPointsTarget = trackDataExists ? dataTarget.track_points : dataTarget.exceedance_point || {};
          const { lookup = {}, data = [], geojson: geoJson = {} } = trackPointsTarget;

          // Extract lookup table and data from track points
          // const { data, lookup, geojson: geoJson } = dataTarget.track_points;

          // Set running tooltip content to show no data
          if (data.length === 0) {
            runningTooltipContentRef.current.innerHTML = "<div><b>No Data</b></div>";
          }

          // Build playback geojson layer (with running tooltip)
          let geoJsonLayer = L.geoJSON(geoJson, {
            pointToLayer: function (feature, latLng) {
              if (feature.properties.hasOwnProperty('last')) {
                // NOTE: 3rd element of geometry coordinates is the index location in dataset
                let featureIdx = feature.geometry.coordinates[2];
                let featureData = data[featureIdx];

                let exceedanceSeverity = featureData[lookup.exceedance_list_str] === "None" ? "None" : featureData[lookup.exceedance_severity_str];
                let locServerity = featureData[lookup.loc_list_str] === "None" ? "None" : featureData[lookup.exceedance_severity_str];

                let fieldsOfInterest = {
                  "Time": featureData[lookup.human_readable_datetime],
                  "Latitude": featureData[lookup.flightstate_location_latitude_str],
                  "Longitude": featureData[lookup.flightstate_location_longitude_str],
                  "AGL (ft)": featureData[lookup.final_agl_str],
                  "Roll (deg)": featureData[lookup.flightstate_position_roll_str],
                  "Pitch (deg)": featureData[lookup.flightstate_position_pitch_str],
                  "Yaw Rate (deg/s)": featureData[lookup.flightstate_rates_yawrate_str],
                  "Heading (deg)": featureData[lookup.heading_str],
                  "Exceedance Type(s)": featureData[lookup.exceedance_list_str],
                  // "Exceedance Classification": featureData[lookup.exceedance_type],
                  "Exceedance Severity": exceedanceSeverity,
                  "Loss of Control Type(s)": featureData[lookup.loc_list_str],
                  "Loss of Control Severity": locServerity,
                  "Phase of Flight": featureData[lookup.phaseofflight_mavg10_str],
                  "Ground Speed (kts)": featureData[lookup.groundspeed_final_kt_str],
                  "Vertical Speed (f/m)": featureData[lookup.verticalspeed_final_fpm_str],
                  "VRS": featureData[lookup.vrs_str],
                };

                // console.log(`Current Feature Data (i = ${featureIdx}) (heading = ${featureData[lookup.heading]} degrees):`, featureData);

                // Iterate entries in fields of interest and build running tooltip content
                let tooltipText = `<h5 style="text-align: right; color: #000;">${featureIdx + 1} of ${data.length}</h5>`;
                tooltipText += '<table><col width="230px" /><col />';
                for (const [key, value] of Object.entries(fieldsOfInterest)) {
                  tooltipText += `
                    <tr>
                      <td style="color: #989898"><b>${key}&nbsp&nbsp&nbsp</b></td>
                      <td style="color: #000"><b>${value}</b></td>
                    </tr>
                  `;
                }

                // End table and set inner html of running tooltip content panel
                tooltipText += "</table>";
                runningTooltipContentRef.current.innerHTML = tooltipText;

                const faIcon = L.divIcon({
                  html: Helicopter({
                    fill: "#ff0000",
                    width: 40,
                    height: 40,
                    animate: true,
                    rotate: featureData[lookup.heading], // Rotate the icon to match the current heading of the flight
                  }),
                  iconSize: [40, 40], // NOTE: Width and height should match with html element width and height for correct icon positioning
                  className: 'playbackFlightIcon',
                });

                let marker = new L.Marker(latLng, {
                  icon: faIcon
                });

                // Track the bounds of the last marker so the map can toggle the view based on the bounds and playback sync mode
                let lastMarkerLatLng = marker.getLatLng();

                // Handle sidebar offset
                let offset = getCurrentPlaybackSyncOffset();
                mapRef.current.lastMarkerLatLng = lastMarkerLatLng;

                // Keep up with current point in playback if syncing is enabled via custom control widget
                // console.log("Playback sync mode:", mapRef.current.playbackSyncMode);
                if (mapRef.current.playbackSyncMode) {
                  mapRef.current.panToOffset(lastMarkerLatLng, [offset, 0], { animate: false, duration: 0 });
                }

                return marker
              }

              return L.circleMarker(latLng);
            },
          });

          timeDimensionLayer.current = L.timeDimension.layer.geoJson(geoJsonLayer, {
            updateTimeDimension: true,
            updateTimeDimensionMode: 'union', // Iterate all points with union; See: https://github.com/socib/Leaflet.TimeDimension#timedimension-update-modes
            addlastPoint: true,
            waitForReady: true,
          });

          // Builing polyline for map extent (fitting map bounds to polyline)
          let polyLineCoords = [];
          let prioritiesMap = {};

          // Extract color maps
          const {
            exceedanceTypeColorMap,
            exceedanceSeverityColorMap,
            phaseOfFlightColorMap,
          } = dataContext.COLOR_MAPS.current;

          Array.from(data).forEach((point, idx) => {
            // NOTE: circle marker function requires lat/lon (y, x) coordinate format
            // See https://leafletjs.com/reference.html#circlemarker
            let coords = [point[lookup.flightstate_location_latitude], point[lookup.flightstate_location_longitude]];
            polyLineCoords.push(coords);

            // Create the core/center point that represents the track point
            // Note: color is stroke color (e.g., weight) and fillColor is within circle marker
            let markerRadius = dataContext.MAP_MARKER_RADIUS;
            let markerWeight = dataContext.MAP_MARKER_WEIGHT;

            // Only process exceedance events
            let exceedanceType = point[lookup.exceedance_type] || "NONE";
            exceedanceType = exceedanceType.toUpperCase();

            // Build exceedance type marker
            let severity = point[lookup.exceedance_severity_str];
            let exceedance = point[lookup.exceedance_subtype] || "NONE";
            exceedance = exceedance.toUpperCase().trim();

            // Get color and label based on ground speed value
            let exceedanceLabel = point[lookup.exceedance_subtype_str];
            let exceedanceTypeColor;
            let exceedanceSeverityColor;

            // Handle corner case for when type is not exceedance
            if (exceedanceType !== "EXCEEDANCE") {
              severity = "None";
              exceedance = "NONE";
              exceedanceLabel = "None";
            }

            exceedanceTypeColor = exceedanceTypeColorMap[exceedance];
            exceedanceSeverityColor = exceedanceSeverityColorMap[severity.toUpperCase().trim()];

            // Determine priority based on exceedance subtype
            let priority;
            if (exceedance in prioritiesMap) {
              priority = prioritiesMap[exceedance];
            } else {
              priority = exceedance === "NONE" ? 0 : idx + 1;
              prioritiesMap[exceedance] = priority;
            }

            legendColors[EXCEEDANCE_TYPE_LEGEND_NAME][priority] = { color: exceedanceTypeColor, label: exceedanceLabel };

            let exceedanceTypeMarker = L.circleMarker(coords, {
              radius: markerRadius,
              weight: markerWeight,
              color: exceedanceTypeColor,
              opacity: 1.0,
              fill: true,
              fillOpacity: 1.0,
              fillColor: exceedanceTypeColor
            });

            // Add non-exceedance values first so the markers appear behind the exceedance events that are added later
            if (exceedanceType !== "EXCEEDANCE") {
              exceedanceTypeMarker.addTo(exceedanceTypeLayer.current);
            }

            exceedanceTypeMarker.indicatorColor = exceedanceTypeColor;

            exceedanceTypeMarker.fieldsOfInterest = {
              // "Flight ID": point[lookup.flightid],
              "Latitude": point[lookup.flightstate_location_latitude_str],
              "Longitude": point[lookup.flightstate_location_longitude_str],
              "Exceedance": exceedanceLabel,
              "Severity": severity,
              "Time": point[lookup.human_readable_datetime],
              "Phase of Flight": point[lookup.phaseofflight_mavg10_str],
              "AGL (ft)": point[lookup.final_agl_str],
              "Ground Speed (kts)": point[lookup.groundspeed_final_kt_str],
              "Vertical Speed (f/m)": point[lookup.verticalspeed_final_fpm_str],
              "Roll (deg)": point[lookup.flightstate_position_roll_str],
              "Pitch (deg)": point[lookup.flightstate_position_pitch_str],
              "Yaw Rate (deg/s)": point[lookup.flightstate_rates_yawrate_str],
            };

            // Build exceedance severity marker
            priority = dataContext.getExceedanceSeverityPriority(severity);
            legendColors[EXCEEDANCE_SEVERITY_LEGEND_NAME][priority] = { color: exceedanceSeverityColor, label: severity };

            let exceedanceSeverityMarker = L.circleMarker(coords, {
              radius: markerRadius,
              weight: markerWeight,
              color: exceedanceSeverityColor,
              opacity: 1.0,
              fill: true,
              fillOpacity: 1.0,
              fillColor: exceedanceSeverityColor
            });

            // Add non-exceedance values first so the markers appear behind the exceedance events that are added later
            if (exceedanceType !== "EXCEEDANCE") {
              exceedanceSeverityMarker.addTo(exceedanceSeverityLayer.current);
            }

            exceedanceSeverityMarker.indicatorColor = exceedanceSeverityColor;

            exceedanceSeverityMarker.fieldsOfInterest = {
              // "Flight ID": point[lookup.flightid],
              "Latitude": point[lookup.flightstate_location_latitude_str],
              "Longitude": point[lookup.flightstate_location_longitude_str],
              "Exceedance": exceedanceLabel,
              "Severity": severity,
              "Time": point[lookup.human_readable_datetime],
              "Phase of Flight": point[lookup.phaseofflight_mavg10_str],
              "AGL (ft)": point[lookup.final_agl_str],
              "Ground Speed (kts)": point[lookup.groundspeed_final_kt_str],
              "Vertical Speed (f/m)": point[lookup.verticalspeed_final_fpm_str],
              "Roll (deg)": point[lookup.flightstate_position_roll_str],
              "Pitch (deg)": point[lookup.flightstate_position_pitch_str],
              "Yaw Rate (deg/s)": point[lookup.flightstate_rates_yawrate_str],
            };

            // Add marker click events and track them for future cleanup
            let exceedanceTypeClickEvent = getMarkerClickEvent(exceedanceTypeMarker);
            let exceedanceSeverityClickEvent = getMarkerClickEvent(exceedanceSeverityMarker);

            exceedanceTypeMarker.on("click", exceedanceTypeClickEvent);
            exceedanceSeverityMarker.on("click", exceedanceSeverityClickEvent);

            if (!exceedanceTypeMarkersRef.current) {
              exceedanceTypeMarkersRef.current = [];
            }

            if (!exceedanceSeverityMarkersRef.current) {
              exceedanceSeverityMarkersRef.current = [];
            }

            if (!nonExceedanceTypeMarkersRef.current) {
              nonExceedanceTypeMarkersRef.current = [];
            }

            if (!nonExceedanceSeverityMarkersRef.current) {
              nonExceedanceSeverityMarkersRef.current = [];
            }

            // Keep track of the marker and the click event so it can be removed during unmount
            if (exceedanceType !== "EXCEEDANCE") {
              nonExceedanceTypeMarkersRef.current.push({ marker: exceedanceTypeMarker, clickEvent: exceedanceTypeClickEvent });
              nonExceedanceSeverityMarkersRef.current.push({ marker: exceedanceSeverityMarker, clickEvent: exceedanceSeverityClickEvent });
            } else {
              exceedanceTypeMarkersRef.current.push({ marker: exceedanceTypeMarker, clickEvent: exceedanceTypeClickEvent });
              exceedanceSeverityMarkersRef.current.push({ marker: exceedanceSeverityMarker, clickEvent: exceedanceSeverityClickEvent });
            }

            // Reset exceedance, severity, and label for loss of control
            severity = point[lookup.exceedance_severity_str];
            exceedance = point[lookup.exceedance_subtype] || "NONE";
            exceedance = exceedance.toUpperCase().trim();
            exceedanceLabel = point[lookup.exceedance_subtype_str];

            // Handle corner case for when type is not loc
            if (exceedanceType !== "LOSS OF CONTROL") {
              severity = "None";
              exceedance = "NONE";
              exceedanceLabel = "None";
            }

            exceedanceTypeColor = exceedanceTypeColorMap[exceedance];

            // Determine priority based on exceedance subtype
            if (exceedance in prioritiesMap) {
              priority = prioritiesMap[exceedance];
            } else {
              priority = exceedance === "NONE" ? 0 : idx + 1;
              prioritiesMap[exceedance] = priority;
            }

            legendColors[LOC_TYPE_LEGEND_NAME][priority] = { color: exceedanceTypeColor, label: exceedanceLabel };

            let locTypeMarker = L.circleMarker(coords, {
              radius: markerRadius,
              weight: markerWeight,
              color: exceedanceTypeColor,
              opacity: 1.0,
              fill: true,
              fillOpacity: 1.0,
              fillColor: exceedanceTypeColor
            });

            // Add non-loc values first so the markers appear behind the loc events that are added later
            if (exceedanceType !== "LOSS OF CONTROL") {
              locTypeMarker.addTo(locTypeLayer.current);
            }

            locTypeMarker.indicatorColor = exceedanceTypeColor;

            locTypeMarker.fieldsOfInterest = {
              // "Flight ID": point[lookup.flightid],
              "Latitude": point[lookup.flightstate_location_latitude_str],
              "Longitude": point[lookup.flightstate_location_longitude_str],
              "Loss of Control (LOC)": exceedanceLabel,
              "Severity": severity,
              "Time": point[lookup.human_readable_datetime],
              "Phase of Flight": point[lookup.phaseofflight_mavg10_str],
              "AGL (ft)": point[lookup.final_agl_str],
              "Ground Speed (kts)": point[lookup.groundspeed_final_kt_str],
              "Vertical Speed (f/m)": point[lookup.verticalspeed_final_fpm_str],
              "Roll (deg)": point[lookup.flightstate_position_roll_str],
              "Pitch (deg)": point[lookup.flightstate_position_pitch_str],
              "Yaw Rate (deg/s)": point[lookup.flightstate_rates_yawrate_str],
            };

            // Build loc severity marker
            exceedanceSeverityColor = exceedanceSeverityColorMap[severity.toUpperCase().trim()];
            priority = dataContext.getExceedanceSeverityPriority(severity);
            legendColors[LOC_SEVERITY_LEGEND_NAME][priority] = { color: exceedanceSeverityColor, label: severity };

            let locSeverityMarker = L.circleMarker(coords, {
              radius: markerRadius,
              weight: markerWeight,
              color: exceedanceSeverityColor,
              opacity: 1.0,
              fill: true,
              fillOpacity: 1.0,
              fillColor: exceedanceSeverityColor
            });

            // Add non-loc values first so the markers appear behind the loc events that are added later
            if (exceedanceType !== "LOSS OF CONTROL") {
              locSeverityMarker.addTo(locSeverityLayer.current);
            }

            locSeverityMarker.indicatorColor = exceedanceSeverityColor;

            locSeverityMarker.fieldsOfInterest = {
              // "Flight ID": point[lookup.flightid],
              "Latitude": point[lookup.flightstate_location_latitude_str],
              "Longitude": point[lookup.flightstate_location_longitude_str],
              "Loss of Control (LOC)": exceedanceLabel,
              "Severity": severity,
              "Time": point[lookup.human_readable_datetime],
              "Phase of Flight": point[lookup.phaseofflight_mavg10_str],
              "AGL (ft)": point[lookup.final_agl_str],
              "Ground Speed (kts)": point[lookup.groundspeed_final_kt_str],
              "Vertical Speed (f/m)": point[lookup.verticalspeed_final_fpm_str],
              "Roll (deg)": point[lookup.flightstate_position_roll_str],
              "Pitch (deg)": point[lookup.flightstate_position_pitch_str],
              "Yaw Rate (deg/s)": point[lookup.flightstate_rates_yawrate_str],
            };

            // Add marker click events and track them for future cleanup
            let locTypeClickEvent = getMarkerClickEvent(locTypeMarker);
            let locSeverityClickEvent = getMarkerClickEvent(locSeverityMarker);

            locTypeMarker.on("click", locTypeClickEvent);
            locSeverityMarker.on("click", locSeverityClickEvent);

            if (!locTypeMarkersRef.current) {
              locTypeMarkersRef.current = [];
            }

            if (!locSeverityMarkersRef.current) {
              locSeverityMarkersRef.current = [];
            }

            if (!nonLocTypeMarkersRef.current) {
              nonLocTypeMarkersRef.current = [];
            }

            if (!nonLocSeverityMarkersRef.current) {
              nonLocSeverityMarkersRef.current = [];
            }

            // Keep track of the marker and the click event so it can be removed during unmount
            if (exceedanceType !== "LOSS OF CONTROL") {
              nonLocTypeMarkersRef.current.push({ marker: locTypeMarker, clickEvent: locTypeClickEvent });
              nonLocSeverityMarkersRef.current.push({ marker: locSeverityMarker, clickEvent: locSeverityClickEvent });
            } else {
              locTypeMarkersRef.current.push({ marker: locTypeMarker, clickEvent: locTypeClickEvent });
              locSeverityMarkersRef.current.push({ marker: locSeverityMarker, clickEvent: locSeverityClickEvent });
            }

            // Build ground speed marker
            let gsColorMap = dataContext.getGroundSpeedColorMap(point[lookup.groundspeed_final_kt]);
            legendColors[GROUND_SPEED_LEGEND_NAME][gsColorMap.priority] = { color: gsColorMap.color, label: gsColorMap.label };

            let gsMarker = L.circleMarker(coords, {
              radius: markerRadius,
              weight: markerWeight,
              color: gsColorMap.color,
              opacity: 1.0,
              fill: true,
              fillOpacity: 1.0,
              fillColor: gsColorMap.color,
            }).addTo(groundSpeedLayer.current);

            gsMarker.indicatorColor = gsColorMap.color;

            gsMarker.fieldsOfInterest = {
              // "Flight ID": point[lookup.flightid],
              "Latitude": point[lookup.flightstate_location_latitude_str],
              "Longitude": point[lookup.flightstate_location_longitude_str],
              "Time": point[lookup.human_readable_datetime],
              "Phase of Flight": point[lookup.phaseofflight_mavg10_str],
              "AGL (ft)": point[lookup.final_agl_str],
              "Ground Speed (kts)": point[lookup.groundspeed_final_kt_str],
              "Vertical Speed (f/m)": point[lookup.verticalspeed_final_fpm_str],
              "Roll (deg)": point[lookup.flightstate_position_roll_str],
              "Pitch (deg)": point[lookup.flightstate_position_pitch_str],
              "Yaw Rate (deg/s)": point[lookup.flightstate_rates_yawrate_str],
            };

            // Add marker click events and track them for future cleanup
            let gsClickEvent = getMarkerClickEvent(gsMarker);
            gsMarker.on("click", gsClickEvent);

            if (!groundSpeedMarkersRef.current) {
              groundSpeedMarkersRef.current = [];
            }

            // Keep track of the marker and the click event so it can be removed during unmount
            groundSpeedMarkersRef.current.push({ marker: gsMarker, clickEvent: gsClickEvent });

            // Build AGL Marker
            let aglColorMap = dataContext.getAglColorMap(point[lookup.final_agl]);
            legendColors[AGL_LEGEND_NAME][aglColorMap.priority] = { color: aglColorMap.color, label: aglColorMap.label };

            let aglMarker = L.circleMarker(coords, {
              radius: markerRadius,
              weight: markerWeight,
              color: aglColorMap.color,
              opacity: 1.0,
              fill: true,
              fillOpacity: 1.0,
              fillColor: aglColorMap.color
            }).addTo(aglLayer.current);

            aglMarker.indicatorColor = aglColorMap.color;

            aglMarker.fieldsOfInterest = {
              // "Flight ID": point[lookup.flightid],
              "Latitude": point[lookup.flightstate_location_latitude_str],
              "Longitude": point[lookup.flightstate_location_longitude_str],
              "Time": point[lookup.human_readable_datetime],
              "Phase of Flight": point[lookup.phaseofflight_mavg10_str],
              "AGL (ft)": point[lookup.final_agl_str],
              "Ground Speed (kts)": point[lookup.groundspeed_final_kt_str],
              "Vertical Speed (f/m)": point[lookup.verticalspeed_final_fpm_str],
              "Roll (deg)": point[lookup.flightstate_position_roll_str],
              "Pitch (deg)": point[lookup.flightstate_position_pitch_str],
              "Yaw Rate (deg/s)": point[lookup.flightstate_rates_yawrate_str],
            };

            // Add marker click events and track them for future cleanup
            let aglClickEvent = getMarkerClickEvent(aglMarker);

            aglMarker.on("click", aglClickEvent);

            if (!aglMarkersRef.current) {
              aglMarkersRef.current = [];
            }

            // Keep track of the marker and the click event so it can be removed during unmount
            aglMarkersRef.current.push({ marker: aglMarker, clickEvent: aglClickEvent });

            // Build phase of Flight Marker
            let posLabel = point[lookup.phaseofflight_mavg10_str];
            let posColor = phaseOfFlightColorMap[posLabel.toUpperCase().trim()];
            legendColors[PHASE_OF_FLIGHT_LEGEND_NAME][posLabel] = posColor;

            let phaseOfFlightMarker = L.circleMarker(coords, {
              radius: markerRadius,
              weight: markerWeight,
              color: posColor,
              opacity: 1.0,
              fill: true,
              fillOpacity: 1.0,
              fillColor: posColor
            }).addTo(phaseOfFlightLayer.current);

            phaseOfFlightMarker.indicatorColor = posColor;

            phaseOfFlightMarker.fieldsOfInterest = {
              // "Flight ID": point[lookup.flightid],
              "Latitude": point[lookup.flightstate_location_latitude_str],
              "Longitude": point[lookup.flightstate_location_longitude_str],
              "Time": point[lookup.human_readable_datetime],
              "Phase of Flight": point[lookup.phaseofflight_mavg10_str],
              "AGL (ft)": point[lookup.final_agl_str],
              "Ground Speed (kts)": point[lookup.groundspeed_final_kt_str],
              "Vertical Speed (f/m)": point[lookup.verticalspeed_final_fpm_str],
              "Roll (deg)": point[lookup.flightstate_position_roll_str],
              "Pitch (deg)": point[lookup.flightstate_position_pitch_str],
              "Yaw Rate (deg/s)": point[lookup.flightstate_rates_yawrate_str],
            };

            // Add marker click events and track them for future cleanup
            let posClickEvent = getMarkerClickEvent(phaseOfFlightMarker);

            phaseOfFlightMarker.on("click", posClickEvent);

            if (!phaseOfFlightMarkersRef.current) {
              phaseOfFlightMarkersRef.current = [];
            }

            // Keep track of the marker and the click event so it can be removed during unmount
            phaseOfFlightMarkersRef.current.push({ marker: phaseOfFlightMarker, clickEvent: posClickEvent });
          });

          // Iterate through exceedance type markers and add them to the exceedance type layer
          for (let ref of exceedanceTypeMarkersRef.current) {
            const { marker } = ref;
            marker.addTo(exceedanceTypeLayer.current);
          }

          // Iterate through exceedance severity markers and add them to the exceedance severity layer
          for (let ref of exceedanceSeverityMarkersRef.current) {
            const { marker } = ref;
            marker.addTo(exceedanceSeverityLayer.current);
          }

          // Iterate through loc type markers and add them to the loc type layer
          for (let ref of locTypeMarkersRef.current) {
            const { marker } = ref;
            marker.addTo(locTypeLayer.current);
          }

          // Iterate through loc severity markers and add them to the loc severity layer
          for (let ref of locSeverityMarkersRef.current) {
            const { marker } = ref;
            marker.addTo(locSeverityLayer.current);
          }

          // Process and render obstacles as a layer
          let obstaclesLookup = dataTarget.obstacles.lookup;
          let obstaclesData = dataTarget.obstacles.data;
          const { obstacleProximityColorMap } = dataContext.COLOR_MAPS.current;
          Array.from(obstaclesData).forEach(obstacle => {
            // NOTE: circle marker function requires lat/lon (y, x) coordinate format
            // See https://leafletjs.com/reference.html#circlemarker
            // console.log("obstacle", obstacle);
            let coords = [obstacle[obstaclesLookup.latitude], obstacle[obstaclesLookup.longitude]];
            // console.log(coords);

            // Note: color is stroke color (e.g., weight) and fillColor is within circle marker
            let markerRadius = 6;
            let markerWeight = 3;
            // let borderColorOff = "#003388FF";
            // let borderColorOn = "#000000";
            // let fillColorOff = "#009E0505";
            // let fillColorOn = "#272D66";

            // Color code obstacles by threat_class
            let threat_class = obstacle[obstaclesLookup.threat_class] || "None";
            let label = threat_class.toUpperCase().trim();
            let fillColorOn = obstacleProximityColorMap[label];
            let borderColorOn = fillColorOn;
            // legendColors[OBSTACLES_LEGEND_NAME][threat_class] = fillColorOn;

            //Load obstacle icon symbols based on the obstacle type and threat level (color)
            var obstacleMarker;
            let symbolSize = dataContext.OBSTACLE_ICON_SIZE;

            //Process the obstacle type string before get its icon name from the predefined dictionary
            let obstacleType = obstacle[obstaclesLookup.type].trim().replace(" ", "_");
            if (dataContext.obstacleTypeSymbols[obstacleType] !== undefined) {
              let obstacleIconColor = dataContext.obstacleTypeSymbols[obstacleType];
              let obstacleIcon = obstacleIconColor["icon"];
              //let obstacleColor = obstacleIconColor["color"];

              //Get the icon
              let obstacleIconSymbol = L.divIcon({
                html: `<i class="${obstacleIcon}" style="font-size:${symbolSize}px; color:${fillColorOn};"></i>`,
                iconSize: [symbolSize, symbolSize],
                className: 'obstacle-symbol-icon'
              });

              //Add the icon to the marker
              obstacleMarker = L.marker(coords, {
                icon: obstacleIconSymbol
              }).addTo(obstaclesLayer.current);

            }
            //If the type doesn't exist in the predefined dictionary, use a circle marker
            else {
              obstacleMarker = L.circleMarker(coords, {
                radius: markerRadius,
                weight: markerWeight,
                color: borderColorOn,
                opacity: 1.0,
                fill: true,
                fillOpacity: 1.0,
                fillColor: fillColorOn,
              }).addTo(obstaclesLayer.current);
            }

            let obstaclesFieldsOfInterest = {
              "Latitude": dataContext.roundStr(obstacle[obstaclesLookup.latitude], 6),
              "Longitude": dataContext.roundStr(obstacle[obstaclesLookup.longitude], 6),
              "Threat": dataContext.capitalizeWords(obstacle[obstaclesLookup.threat_class]),
              "Obstacle Id": obstacle[obstaclesLookup.obstacle_number],
              "Obstacle": obstacle[obstaclesLookup.type],
              "AGL Height (ft)": dataContext.roundStr(obstacle[obstaclesLookup.agl_height]),
              "MSL Height (ft)": dataContext.roundStr(obstacle[obstaclesLookup.msl_height]),
              "Lighting": obstacle[obstaclesLookup.lighting],
            };

            // Add listeners/tooltips to obstacle markers as needed
            // Build tool tip off all properties
            buildFeaturedTooltip(obstacleMarker, obstaclesFieldsOfInterest, {
              color: fillColorOn,
              asPopup: false
            });

            // const onRemovePopup = () => {
            //   console.log("Removing popup from marker...");
            //   obstacleMarker.unbindPopup();
            // }

            const onMarkerClick = () => {
              // Only build popup if it hasn't been built already
              if (!obstacleMarker.getPopup()) {
                // Build popup on the fly
                buildFeaturedTooltip(obstacleMarker, obstaclesFieldsOfInterest, {
                  color: fillColorOn,
                  asPopup: true
                });
              }

              // Manually open the popup
              obstacleMarker.openPopup();

              // Manually close tooltip
              // NOTE: Need to add minor buffer before triggering this event to prevent it from being consumed by manual openPopup call
              setTimeout(() => {
                obstacleMarker.closeTooltip();
              });

              // Destroy the popup as soon as it's closed
              // NOTE: This event currently causes a memory leak
              // obstacleMarker.getPopup().on("remove", onRemovePopup);
            }

            obstacleMarker.on("click", onMarkerClick);

            if (!obstacleMarkersRef.current) {
              obstacleMarkersRef.current = [];
            }

            // Keep track of the marker and the click event so it can be removed during unmount
            obstacleMarkersRef.current.push({ marker: obstacleMarker, clickEvent: onMarkerClick });
          });

          // Add base Layers to map here
          // NOTE: Only add one base layer initially for event listeners to work (if other layers exist)
          placeholderLayer.current.addTo(mapRef.current);
          // exceedanceTypeLayer.current.addTo(mapRef.current);

          // Add overlay layers to map here
          // NOTE: Overlay layers will be checked initially if added to map here
          timeDimensionLayer.current.addTo(mapRef.current);

          setOverlays([
            obstaclesLayer.current,
          ]);

          // Build base layer for switch control between exceedances, etc.
          baseLayersRef.current = {};

          // Build overlay map for layer control to contain all layers
          overlayLayersRef.current = {
            [TOGGLE_LAYER_GROUP_NAME]: {
              [NO_LAYER_NAME]: placeholderLayer.current,
              [GROUND_SPEED_LAYER_NAME]: groundSpeedLayer.current,
              [AGL_LAYER_NAME]: aglLayer.current,
              [PHASE_OF_FLIGHT_LAYER_NAME]: phaseOfFlightLayer.current,
              [EXCEEDANCE_TYPE_LAYER_NAME]: exceedanceTypeLayer.current,
              [EXCEEDANCE_SEVERITY_LAYER_NAME]: exceedanceSeverityLayer.current,
              [LOC_TYPE_LAYER_NAME]: locTypeLayer.current,
              [LOC_SEVERITY_LAYER_NAME]: locSeverityLayer.current,
            },
            [OVERLAY_LAYER_GROUP_NAME]: {
              [OBSTACLES_LAYER_NAME]: obstaclesLayer.current,
            },
          }

          // Build flight track polyline to calculate boundaries of flight to zoom/pan on
          let polyline = L.polyline(polyLineCoords);
          let validBounds = polyLineCoords.length > 0;

          // Pan map viewport to extent of flight based on flight track polyline bounds
          if (trackPointsTarget.data && trackPointsTarget.data.length > 0) {
            // Update bounds target for panning control so the map pans to the bounds of the polyline representing the flight track
            if (flightLocRef.current && validBounds) {
              mapRef.current.boundsTarget = polyline.getBounds();
              // console.log("Current flight loc button:", flightLocRef.current);
            } else {
              mapRef.current.boundsTarget = null;
            }

            // Set map view to position of first coordinate only if data has not been loaded before and the current position state isn't null
            try {
              if (mapRef.current && isMounted.current) {
                // Don't animate on map load
                const config = { ...MAP_PAN_CONFIG, animate: false, duration: 0 };

                if (initLoad) {
                  fitMapBounds(mapRef.current);
                  setInitLoad(false);
                } else {
                  if (currentPos) {
                    mapRef.current.flyTo(currentPos.pos, currentPos.zoom, config);
                  } else {
                    fitMapBounds(mapRef.current);
                  }
                }
              }
            } catch (error) {
              console.error(error);
            }
          }

          // Create the control layers tree now that the layers have been created
          dataControlRef.current = L.control.groupedLayers(
            baseLayersRef.current, overlayLayersRef.current, { collapsed: false, position: "topleft", exclusiveGroups: [TOGGLE_LAYER_GROUP_NAME] }
          ).addTo(mapRef.current);

          // Legends ****
          // Only include color mappings for existing data
          // Build legend for ground speed
          let exceedanceTypeLegend = buildLegend({ cb_map: legendColors[EXCEEDANCE_TYPE_LEGEND_NAME], header: EXCEEDANCE_TYPE_LEGEND_NAME, withPriority: true });
          let exceedanceSeverityLegend = buildLegend({ cb_map: legendColors[EXCEEDANCE_SEVERITY_LEGEND_NAME], header: EXCEEDANCE_SEVERITY_LEGEND_NAME, withPriority: true });
          let locTypeLegend = buildLegend({ cb_map: legendColors[LOC_TYPE_LEGEND_NAME], header: LOC_TYPE_LEGEND_NAME, withPriority: true });
          let locSeverityLegend = buildLegend({ cb_map: legendColors[LOC_SEVERITY_LEGEND_NAME], header: LOC_SEVERITY_LEGEND_NAME, withPriority: true });
          let groundSpeedLegend = buildLegend({ cb_map: legendColors[GROUND_SPEED_LEGEND_NAME], header: GROUND_SPEED_LEGEND_NAME, withPriority: true });
          let aglLegend = buildLegend({ cb_map: legendColors[AGL_LEGEND_NAME], header: AGL_LEGEND_NAME, withPriority: true });
          let posLegend = buildLegend({ cb_map: legendColors[PHASE_OF_FLIGHT_LEGEND_NAME], header: PHASE_OF_FLIGHT_LEGEND_NAME });
          // exceedanceTypeLegend.addTo(mapRef.current); // NOTE: Only add legend to map if a legend should be shown initially

          // Build legends map when different base layers have differnet legends to swap between
          legendsMapRef.current = {
            [GROUND_SPEED_LAYER_NAME]: groundSpeedLegend,
            [AGL_LAYER_NAME]: aglLegend,
            [PHASE_OF_FLIGHT_LAYER_NAME]: posLegend,
            [EXCEEDANCE_TYPE_LAYER_NAME]: exceedanceTypeLegend,
            [EXCEEDANCE_SEVERITY_LAYER_NAME]: exceedanceSeverityLegend,
            [LOC_TYPE_LAYER_NAME]: locTypeLegend,
            [LOC_SEVERITY_LAYER_NAME]: locSeverityLegend,
          }

          // Set initial active legend
          // activeLegendRef.current = exceedanceTypeLegend;

          // Add event listener to layer control so the legened is toggled accordingly
          // NOTE: To prevent bug with baselayerchange event not triggering on first base layer change, only add one of the base layers from layer control
          //       initially to the map. Include all base layers in the associated base layers object that is passed into the L.control.layers constructor.
          mapRef.current.on('overlayadd', onLayerChange);
        }
      }
    }

    // Unmount post-processing hook
    return () => {
      clearControls();
      placeholderLayer.current.clearLayers();
      exceedanceTypeLayer.current.clearLayers();
      exceedanceSeverityLayer.current.clearLayers();
      locTypeLayer.current.clearLayers();
      locSeverityLayer.current.clearLayers();
      groundSpeedLayer.current.clearLayers();
      aglLayer.current.clearLayers();
      phaseOfFlightLayer.current.clearLayers();
      obstaclesLayer.current.clearLayers();

      // Clear all click events for each marker ref list and set ref to null (return value of clearMarkerClickEvents function is always null)
      exceedanceTypeMarkersRef.current = clearMarkerClickEvents(exceedanceTypeMarkersRef.current);
      exceedanceSeverityMarkersRef.current = clearMarkerClickEvents(exceedanceSeverityMarkersRef.current);
      nonExceedanceTypeMarkersRef.current = clearMarkerClickEvents(nonExceedanceTypeMarkersRef.current);
      nonExceedanceSeverityMarkersRef.current = clearMarkerClickEvents(nonExceedanceSeverityMarkersRef.current);
      locTypeMarkersRef.current = clearMarkerClickEvents(locTypeMarkersRef.current);
      locSeverityMarkersRef.current = clearMarkerClickEvents(locSeverityMarkersRef.current);
      nonLocTypeMarkersRef.current = clearMarkerClickEvents(nonLocTypeMarkersRef.current);
      nonLocSeverityMarkersRef.current = clearMarkerClickEvents(nonLocSeverityMarkersRef.current);
      groundSpeedMarkersRef.current = clearMarkerClickEvents(groundSpeedMarkersRef.current);
      aglMarkersRef.current = clearMarkerClickEvents(aglMarkersRef.current);
      phaseOfFlightMarkersRef.current = clearMarkerClickEvents(phaseOfFlightMarkersRef.current);
      obstacleMarkersRef.current = clearMarkerClickEvents(obstacleMarkersRef.current);

      // Dynamically iterate all layers and remove them
      mapRef.current.eachLayer(function (layer) {
        mapRef.current.removeLayer(layer);
      });

      mapRef.current.timeDimension = null;
      mapRef.current._initEvents(true);
      mapRef.current._stop();
      mapRef.current.remove();

      // Delete all references and initialize state once the component is unmounted
      if (dataContext.currentMapRef.current !== null) {
        dataContext.currentMapRef.current = null;
      }

      mapRef.current = null;
      tileRef.current = null;
      overlayTileRef.current = null;
      baseControlRef.current = null;
      baseLayersRef.current = null;
      overlayLayersRef.current = null
      zoomControlRef.current = null;
      fullscreenControlRef.current = null;
      scaleControlRef.current = null;
      dataControlRef.current = null;
      flightLocRef.current = null;
      legendsMapRef.current = null;
      activeLegendRef.current = null;
      timeDimensionRef.current = null;
      timeDimensionControlRef.current = null;
      sidebarControlRef.current = null;
      runningTooltipContentRef.current = null;

      placeholderLayer.current = null;
      exceedanceTypeLayer.current = null;
      exceedanceSeverityLayer.current = null;
      locTypeLayer.current = null;
      locSeverityLayer.current = null;
      groundSpeedLayer.current = null;
      aglLayer.current = null;
      phaseOfFlightLayer.current = null;
      obstaclesLayer.current = null;
      timeDimensionLayer.current = null;

      activeTrackPointMarkersRef.current = null;

      setOverlays(null);
      setCurrentPos(null);
      setInitLoad(true);
      setPlaybackSyncMode(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.baseReducedData, dataContext.filteredReducedData]);

  return (
    <div id={mapId} style={mapStyles} />
  )
}

PlaybackMap.propTypes = propTypes;
PlaybackMap.defaultProps = defaultProps;

export default PlaybackMap;
