import React, { useEffect, useContext, useState }  from 'react'
import { Auth } from 'aws-amplify'
import { Link } from 'react-router-dom'
import UserContext from '../components/UserContext'
import SignInError from './SignInError.js'
import {
    Label,
    Button,
    TextInput,
    Fieldset
} from '@trussworks/react-uswds'
import hasRole from '../services/hasRole'
import UnauthNav from '../components/UnauthNav';
import SignInRegConfirm from './SignInRegConfirm'

function SignIn(props) {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [registerConfirmCode, setRegisterConfirmCode] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(''); 
  const [showRegConfirm, setShowRegConfirm] = useState('');
  const [regStatusMessage, setRegStatusMessage] = useState('Click the Resend button if your code is more than 24 hours old.')
  const [user, setUser] = useState('');
  const [authCode, setAuthCode] = useState('');
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [processingsignIn, setProcessingsignIn] = useState(false);
  const [readyToNavigate, setReadyToNavigate] = useState(false);
  
  const contextType = useContext(UserContext);

  

  // onChange = (key, value) => {
  //   this.setState({
  //     [key]: value
  //   })
  // }

  const handleClose = () => {
    setShowError(false)
  }

  useEffect(() => {
    
    if(contextType.isLoaded && readyToNavigate){
      navigateToHomepage();
    }
  

    // Update the user on creation, to catch refreshes and other actions.  While the user is signed in this will pull from Auth.
    // var firstPref = currentUser ? getFromLS(currentUser, 'firstLoad') : null
    return () => {
      
    }
  }, [contextType.isLoaded, readyToNavigate]);


  const navigateToHomepage = () => {
    const { history } = props
    const user = contextType

    if(hasRole(user, ["operator"])) {
        history.push('/home')
      }
      else if(hasRole(user, ["dto"])) {
        history.push('/fileStatus')
      } 
      else if(hasRole(user, ["r-iat"])) {
        history.push("/metrics")
      } 
      else {
        history.push('/home')
      }
  }

  const signIn = (e) => {
    
    e.preventDefault();
    
    Auth.signIn(username, password)
      .then(user => {
        
        if(user && user.challengeName === "SMS_MFA"){
          setUser(user)
          setShowConfirmation(true)
        }
        else 
        if (!user.signInUserSession) {
          setUser(user)
          setShowConfirmation(true)
        } else {
          setReadyToNavigate(true)
          
        }        
      })
      .catch(err => {
        if(err.message === "User is disabled."){
          setShowError(true)
          setErrorMessage("Your account is disabled.  If this is not a new account or it is a new account and has been disabled for longer than 7 days please reach out to us at ContactUs@asias.info")
        } else if(err.message === "User is not confirmed."){
          setShowRegConfirm(true)
          // setErrorMessage("Time to confirm")
        } else{
          setShowError(true)
          setErrorMessage(err.message)
        }
      })
  }
  
  const confirmSignIn = async (e) => {
    e.preventDefault();
    if(!processingsignIn){
      setProcessingsignIn(true)
    try{
    const { updateCurrentUser } = contextType
    const userSent = user;
    const authCodeSent = authCode;
    const challengeSent = user.challengeName;
    await Auth.confirmSignIn(userSent, authCodeSent, challengeSent)
        const currentUser = await Auth.currentAuthenticatedUser() 
        updateCurrentUser(currentUser).then((result) => {
          setReadyToNavigate(true)
        })
        
      // })
      // .catch(err => console.log('error confirming signing in...: ', err))
    }
    catch (error) {
      setProcessingsignIn(false)
      if(error.name === "CodeMismatchException"){
        setShowError(true)
        setErrorMessage("Your confirmation code is incorrect.")
        
      }
      else{
        setShowError(true)
        setErrorMessage(error.message)
      }
    }
  }
  }
  

    return (
      
      <div>
      <UnauthNav />
      <div style={{margin:'1rem'}}>
      <h1>Welcome</h1>

                <p>
                  Access to this system is for authorized users only. Any unauthorized entry or attempt to enter is strictly forbidden and will result in prosecution to the maximum extent allowable by applicable law.
                  If you are NOT an authorized user and have a need for an account, please register by clicking the link below.
                  For security reasons, please Log Out and Exit your web browser when you are done accessing services that require authentication!
                </p>
                  
                </div>
      <div className="TempMarker" style={{margin:'auto',width:'40%'}}>
      {
        !showConfirmation && !showRegConfirm &&(
      
        <div style={{paddingTop: '2em',}} className="stretch_grid-container">
        
          <form onSubmit={evt => {signIn(evt)}}>
            <Fieldset legend="" legendStyle="large">
              
              <Label htmlFor="email">Email address</Label>
              <TextInput
                id="email"
                autoFocus
                name="email"
                type="text"
                autoCapitalize="off"
                autoCorrect="off"
                onChange={evt => setUsername(evt.target.value)}
              />
              <Label htmlFor="password-sign-in">Password</Label>
              <TextInput
                id="password-sign-in"
                name="password-sign-in"
                type="password"
                onChange={evt => setPassword(evt.target.value)}
              />
             

              <Button type="submit" style={{marginBottom: '20px', marginTop: '20px'}}>Sign in</Button><br></br>
              
              <Link to="/forgot_password" style={{ color: '#142945', textAlign: 'center'}}>
                Forgot Password?
              </Link>
              <br></br>
             
              <p>Need an account? 
                 <Link to="/register" style={{color: '#142945', textAlign: 'center' }}> Sign up</Link>
              </p>

            </Fieldset>
          </form>


          </div>
          )
      }
      {
        showConfirmation && (
            <div>
            <form onSubmit={evt => {confirmSignIn(evt)}}>
            <Fieldset legend="" legendStyle="large">
            <Label htmlFor="confirm-code">Confirmation Code</Label>
            <TextInput
                name="confirm-code"
                autoFocus
                onChange={evt => setAuthCode(evt.target.value)}
                autoComplete="off"
                placeholder='Confirmation Code'
                
              />
              <br />
              <Button type="submit"> Confirm Sign In</Button>
              </Fieldset>
              </form>
            </div>
          )
      }
      {
        showRegConfirm && (
            <SignInRegConfirm username={username} />
          )
      }
    <SignInError show={showError} handleClose={handleClose} errorMessage={errorMessage}/>
    </div>
    </div>
    )
  

}




export default SignIn;

