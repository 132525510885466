import React from 'react'

const HelpContents = (props) =>  {


    return (
    <div className="stretch_grid-container">
      
      <h1 style={{paddingTop: '1em'}}>Help/FAQ</h1> 

      <h4 style={{paddingTop: '1em'}}>I made an account but I cannot login</h4>
        <p className='faq-p'>
          After creating an account, a confirmation email will be sent to your email address verifying you are the owner of that email.  You will need to click the confirmation link inside to verify your account.
        </p>
      
        <h4 style={{paddingTop: '1em'}}>I verified my account but cannot log in</h4>
        <p className='faq-p'>
          After signing up for an account and confirming your email, all accounts undergo administrative review for authenticity.  During this time your account will either be approved or denied, with the administration team reaching out with any additional questions they may have.  Once approved, you will receive an email from <a href="mailto:ContactUs@asias.info">ContactUs@asias.info</a>.
        </p>

      <h4 style={{paddingTop: '1em'}}>I never received my account activation email</h4>
        <p className='faq-p'>
          Some email providers have strict filtering policies on .info domains.  If you have not received any emails from <a href="mailto:ContactUs@asias.info">ContactUs@asias.info</a> check your junk mail for any that may have been fitlered.
        </p>

        <h4 style={{paddingTop: '1em'}}>I was able to login but am now unable to</h4>
        <p className='faq-p'>
          Accounts may be disabled as part of routine pruning activities.  If your account appears non-responsive please email us at <a href="mailto:ContactUs@asias.info">ContactUs@asias.info</a> for assistance reinstating your account.
        </p>

        <h4 style={{paddingTop: '1em'}}>I don't know my Organization</h4>
        <p className='faq-p'>
          The Organization information is used to identify which Operating company a user is associated with.  If you are uncertain what this would be, fill in any identifying information you can think of and an administrator will contact you during the account review process.
        </p>

        <h4 style={{paddingTop: '1em'}}>I don't know what Role I should be</h4>
        <p className='faq-p'>
          Roles are an optional field during sign up, the administrators will assign the roles necessary for the user and reach out for confirmation on any outstanding requests.
        </p>

        <h4 style={{paddingTop: '1em'}}>The registration form will not accept my phone number</h4>
        <p className='faq-p'>
          The system requires users to enter an SMS receiving capable phone number in the format of +1(your 10 digit number).  This would look like +11234567890 when filled in.
        </p>

        <h4 style={{paddingTop: '1em'}}>I do not receive/do not consistently receive Multifactor Authentication One Time Passwords on my phone</h4>
        <p className='faq-p'>
          MFA OTPs require an SMS capable device and are sent via standard phone carriers.  Occasionally carriers may be unable to deliver messages due to localized outages, poor signal or other reasons.  If you experience issues for a prolonged period of time or for multiple sign in attempts, please reach out to the administrative team at <a href="mailto:RotorcraftHelp@asias.info">RotorcraftHelp@asias.info</a> for help resolving the issue.
        </p>

        <h4 style={{paddingTop: '1em'}}>Attempting to access the site in Internet Explorer (IE) just gives a white page</h4>
        <p className='faq-p'>
          Rotorcrft ASIAS is built using Javascript libraries and does not have support for Internet Explorer.  Please use Chrome, Firefox, Safari, or Microsfot Edge to access the site.
        </p>

        <h4 style={{paddingTop: '1em'}}>While attempting to recover my account I was locked out.</h4>
        <p className='faq-p'>
          An account lockout will occur if an account attempts to recover unsuccessfully too many times.  Please contact the administrators at <a href="mailto:RotorcraftHelp@asias.info">RotorcraftHelp@asias.info</a> for additional assistance.
        </p>

        <h4>How do I register for ASIAS?</h4>
          <p className='faq-p'>
            Go to User Registration and submit your registration information. 
            An FAA Approver will review your registration request and notify you when your account is created.
            The FAA Approver may contact you with any questions to complete your registration
          </p>

          <h4>How do I add an aircraft to my fleet?</h4>
          <p className='faq-p'>
            Go to My Fleet and select Add Aircraft. Submit your aircraft information. 
            An FAA Approver will review your aircraft request and notify you when your aircraft is added. 
            The FAA Approver may contact you with any questions to complete your aircraft request.
          </p>

          <h4>How do I upload flight information?</h4>
          <p className='faq-p'>
            Go to My Fleet and find the Make/Model/Series/Recorder for the flight you wish to upload.  At the end of the table row will be an upload flight button, which when clicked will launch an upload dialog for that flight with the expected defaults filled out.  Adjust the information and either drag/drop a file onto the window, or launch the file upload browser to select a file to upload.  When selecting to Upload, you'll be prompted to confirm the upload information before submitting.<br />
            You can view your uploaded flight information on My Flights.
          </p>

          <h4>How do I view flight metrics?</h4>
          <p className='faq-p'>
            Go to My Flights and select Metrics for the flight you want to view metrics for. 
            Select a topic to view more details.
          </p>

          <h4>How do I see aggregate metrics?</h4>
          <p className='faq-p'>
            Go to My Metrics to view aggregated metrics for ASIAS users. Select a topic to view more details.
          </p>

          <h4>Where do I go for additional help?</h4>
          <p className='faq-p'>
            Contact Charles (Cliff) Johnson at 609-485-6181 or Charles.C.Johnson@faa.gov
          </p>

          <h4>I created a flight with erroneous information, how can I remove it?</h4>
            <p className='faq-p'>
              Contact the site administrators at ContactUs@asias.info
            </p>

            <h4>How do I see my operator's flight uploads?</h4>
            <p className='faq-p'>Go to your SFTP upload location to view flight uploads</p>

            <h4>How do I add an operator?</h4>
            <p className='faq-p'>Contact an FAA Approver and send them an updated DTO to Operator spreadsheet.</p>

            <h4>Where do I go for additional help?</h4>
            <p className='faq-p'>
              Contact Charles (Cliff) Johnson at 609-485-6181 or Charles.C.Johnson@faa.gov
            </p>

            <h4>I created a flight with erroneous information, how can I remove it?</h4>
            <p className='faq-p'>
              Contact the site administrators at ContactUs@asias.info
            </p>

            <h4>I am unable to see resources in Athena</h4>
          <p className='faq-p'>
            Athena resources are governed by AWS regions.  In the upper right corner of the AWS console next to the username make sure N. Virginia (us-east-1) is your chosen region.  
            <br />Not all parts of AWS acknowledge or use regions, so this value may be changed on you depending on which screens in the AWS console you visit.
          </p>

          <h4>What is QuickSight and what is it used for?</h4>
          <a href="https://docs.aws.amazon.com/quicksight/latest/user/welcome.html">Amazon QuickSight</a> is a cloud-scale business intelligence (BI) service that you can use to deliver easy-to-understand insights to the people who you work with, wherever they are. Amazon QuickSight connects to your data in the cloud and combines data from many different sources. In a single data dashboard, QuickSight can include AWS data, third-party data, big data, spreadsheet data, SaaS data, B2B data, and more. As a fully managed cloud-based service, Amazon QuickSight provides enterprise-grade security, global availability, and built-in redundancy. It also provides the user-management tools that you need to scale from 10 users to 10,000, all with no infrastructure to deploy or manage.
          <br />
          QuickSight gives decision-makers the opportunity to explore and interpret information in an interactive visual environment. They have secure access to dashboards from any device on your network and from mobile devices.
          <br />
          <a href="https://docs.aws.amazon.com/quicksight/latest/user/add-a-filter.html">Adding a Filter - Amazon QuickSight</a>
          <br />
          <a href="https://docs.aws.amazon.com/quicksight/latest/user/exporting-data.html">Exporting Data - Amazon QuickSight</a>
          <br />
    </div>

    )}

    export default HelpContents