import React, { useContext, useEffect, useState } from 'react'



import UserContext from '../components/UserContext';
import DashboardNav from './DashboardNav';
import NavbarUnauth from './NavbarUnauth';
// import { Drawer } from 'react-bootstrap-drawer';



function Navbar(props) {
  // state = {
  //   drawerOpen: false,
  // }

  // handleDrawerToggle = () => this.setState({drawerOpen: !drawerOpen});

  const userContext = useContext(UserContext)
  // const [isLoaded, setIsLoaded] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    // console.log(userContext)
    
    setIsLoggedIn(userContext &&  userContext.user && userContext.user.username && userContext.user.username !== '' ) 
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  },[userContext.isLoaded])

  // console.log(userContext)
  // console.log(userContext.user)
  // console.log(userContext.user.username)
  return(
    <>
      {!userContext.isLoaded ? <></>
        :
        <>
          {isLoggedIn ?
            <DashboardNav />
            :
            <NavbarUnauth />}
        </>
      }
    </>

  )

}

export default Navbar

