import React, { useContext } from 'react'
import Navbar from '../components/Navbar'
import UserContext from '../components/UserContext';



function NoMatch() {
    const userContext = useContext(UserContext)

  return (
    <>
    {userContext.isLoaded ? 
    <>
    <Navbar />
    <div className="stretch_grid-container">
      <h2>404</h2>
      <h1>Page not found.</h1>
      <p>We’re sorry but it appears that we can’t find the page you were looking for. 
        Usually this occurs because of a
        page that previously existed was removed or you’ve mistyped the address.</p>
      {/*<h3>No match for <code>{location.pathname}</code></h3>*/}
    </div>
    </>
    :
    <></>
    }
  </>
  )
}

export default NoMatch
