import {API} from "aws-amplify";

function getPresignedURL(user, params) {
    const token = user.signInUserSession.idToken.jwtToken;
    const requestInfo = {
      headers: {
        Authorization: token
      }
    }

    let url = "/fileupload?" +
    new URLSearchParams({
        filename: params.filename,
        file_type: params.file_type,
        tail: params.tail,
        mission_type: params.mission_type,
        operator: params.operator,
        fileNumber: params.fileNumber
    })

    const data = API.get('raise-gui-api-dev', url, requestInfo)
      return data;

}

export default getPresignedURL;