import React, { createContext, useRef, useState, useEffect } from 'react';
import { getFromLS, saveToLS, getAllFromLS } from '../helper-functions/LsHelper';
import { Auth, Hub } from 'aws-amplify'
import getUserGroups from '../services/getUserGroups';


export const UserContext = createContext();

export const UserProvider = ({ children }) => {

    // User Context Info
    const isMounted = useRef(false);
    // const [darkMode, setDarkMode] = useState(getFromLS(DARK_MODE_KEY) || false);
    const [currentUser, setCurrentUser] = useState({});
    const [userGroups, setUserGroups] = useState({});
    const [tail, setTail] = useState();
    const [groupsLoaded, setGroupsLoaded] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false)
    const [pauseTimer, setPauseTimer] = useState()
    const [resumeTimer, setResumeTimer] = useState()


    // User Preferences
    
    const DARK_MODE_KEY = "darkMode";


    const handleLogout = () => {
      Auth.signOut();
      // setIsLoaded(false);
      setCurrentUser({});
      setUserGroups({});
      setTail();
    }

    // Functions

    
    
   /**
    * Intermediate function invoked on action to help refresh stored user data to match the 
    * new idToken being generated.  This function should resolve issues where a user inactive longer
    * then token expiry receives 401 errors when navigating.  It also now has a check for the currentUser 
    * to exist before attempting anything.  There may still be an edge case where onr efresh the user doesn't exist and a token
    * is not refreshed prior to being used.
    * 
    * The original problem occurs because the saved user object does not necessarily update when the tokens expire,
    * but the user object in the context is how the rest of the app invokes services.  The next possbile solution would be
    * to replace the State user with a direct call to Auth, although there's a chance the response would be async and not render well.
    */
    const updateUserInter = async () => {
      if(currentUser){
      try {
        // console.log("Getting Users!")
        await Auth.currentSession({}).then((result) => {
          // console.log(result)
          // console.log(currentUser)
          let currentTemp = currentUser
          let updateRequired = false
          // Since result isn't in the right format, check each token and replace them in the temp object, then assign the temp object
          if(currentUser && currentUser.signInUserSession && currentUser.signInUserSession.accessToken && currentUser.signInUserSession.accessToken.jwtToken != result.accessToken.jwtToken){
            // console.log("Update the access")
            currentTemp.signInUserSession.accessToken = result.accessToken
            updateRequired = true
          }
          if(currentUser && currentUser.signInUserSession && currentUser.signInUserSession.idToken && currentUser.signInUserSession.idToken.jwtToken != result.idToken.jwtToken){
            // console.log("Update the id")
            currentTemp.signInUserSession.idToken = result.idToken
            updateRequired = true
          }
          if(currentUser && currentUser.signInUserSession && currentUser.signInUserSession.refreshToken && currentUser.signInUserSession.refreshToken.jwtToken != result.refreshToken.jwtToken){
            // console.log("Update the refresh")
            currentTemp.signInUserSession.refreshToken = result.refreshToken
            updateRequired = true
          }

          if(updateRequired){
            // console.log("Updating user")
            setCurrentUser(currentTemp);
          }
        // return (currentTemp);
          // console.log({result})
        }).catch((error) => {
            setCurrentUser(null);
            setIsLoaded(true);
            setUserGroups(null);
            setGroupsLoaded(true);
        })
      } catch (err) {
        setCurrentUser(null);
        setUserGroups(null);
        setIsLoaded(true);
      }
    }
    
  }


    const updateCurrentUser = async () => {
      // setIsLoaded(false);
        // console.log("Updating the context!");
        // if (user) {
        //   this.setState({ currentUser: user })
        //   // return
        // }
        // if (groups) {
        //   this.setState({ userGroups: groups })
        //   // return
        // }
        try {
          // console.log("Getting Users!")
          await Auth.currentAuthenticatedUser({ bypassCache: true }).then((result) => {
            if (!localStorage.getItem("raiseLastActiveTime") || localStorage.getItem("raiseLastActiveTime") == '') {
                // console.log("No Last Logged Logout");
                handleLogout();
              }
            localStorage.setItem("raiseLastActiveTime", new Date())
            setCurrentUser(result);
            return (result);
            // console.log({result})

                
          }).catch((error) => {
              setCurrentUser(null);
              setIsLoaded(true);
              setUserGroups(null);
              setGroupsLoaded(true);
          })
          //Force refresh of user attributes
          
          
          // console.log("Groups returned " + groups)
    
    
        } catch (err) {
          setCurrentUser(null);
          setUserGroups(null);
          setIsLoaded(true);
        }
        
      }


    const updateTail = (newTail) => {
        setTail(newTail);
      }


      useEffect(() => {
        updateCurrentUser()
        // Update the user on creation, to catch refreshes and other actions.  While the user is signed in this will pull from Auth.
        // var firstPref = currentUser ? getFromLS(currentUser, 'firstLoad') : null
        return () => {
          
        }
      }, []);

// Mount/Unmount used for cleanup
// useEffect(() => {
//   // console.log("Mounting useEffect")
//     isMounted.current = true;
//     // Update the user on creation, to catch refreshes and other actions.  While the user is signed in this will pull from Auth.
//     updateCurrentUser();

//     return () => {
      
//     }
//   }, [isLoaded]);

  useEffect(() => {
    
    if(currentUser  && currentUser.signInUserSession){
    getUserGroups(currentUser).then((result) => {
      // console.log(result);
      setUserGroups(result.Groups);
      setIsLoaded(true);
      setGroupsLoaded(true);
  })
}
    // Update the user on creation, to catch refreshes and other actions.  While the user is signed in this will pull from Auth.
    // var firstPref = currentUser ? getFromLS(currentUser, 'firstLoad') : null
    return () => {
      
    }
  }, [currentUser]);


  return (
    <UserContext.Provider
      value={{
        user: currentUser,
        updateCurrentUser,
        updateUserInter,
        isLoaded, 
        userGroups,
        groupsLoaded,
        tail,
        updateTail,
        handleLogout ,
        pauseTimer,setPauseTimer,
        resumeTimer,setResumeTimer
      }}
    >
      {children}
    </UserContext.Provider>
  );

}


export default UserContext;