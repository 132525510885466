import React, { useState, useRef, useEffect, useContext } from 'react';
import { DataContext } from "../../DataContext";
// import PropTypes from 'prop-types';
import CanvasJSReact from '../../../canvasjs-commercial-3.4.12/canvasjs.react';
import { useResizeDetector } from 'react-resize-detector';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Radio, RadioGroup} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import '../Chart.css';
import { ConstructionOutlined, SocialDistanceOutlined, VolunteerActivismOutlined } from '@mui/icons-material';
import { propsToClassKey } from '@mui/styles';

// const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const propTypes = {

};

const defaultProps = {

};

// X Axis types
const xAxisTypes = ["agl", "time", "msl"];

// limit prefix suffix
const TOLERANCELIST = {
  "HIGHTOL": {
    "lookupKey": "approach_tol_high_",
    "displayName": "High Tolerance Limit",
    "lineStyle": "solid",
    "color": "red"
  },
  "NORMUPPER": {
    "lookupKey": "approach_norm_high_",
    "displayName": "Normal Upper Limit",
    "lineStyle": "dash",
    "color": "red"
  },
  "NORMLOWER": {
    "lookupKey": "approach_norm_low_",
    "displayName": "Normal Lower Limit",
    "lineStyle": "dash",
    "color": "red"
  },
  "LOWTOL": {
    "lookupKey": "approach_tol_low_",
    "displayName": "Low Tolerance Limit",
    "lineStyle": "solid",
    "color": "red"
  },
  "IFRUPPER": {
    "lookupKey": "approach_norm_upper_",
    "displayName": "Normal Upper Limit",
    "lineStyle": "dash",
    "color": "red"
  },
  
}

const STATISTICALLIST = {
  "APPMEAN": {
    "lookupKey": "approach_mean_",
    "difKey": '',
    "displayName": "Approach Mean",
    "lineStyle": "solid",
    "color": "DarkKhaki"
  },
  "STDDEVHIGH": {
    "lookupKey": "approach_stddev_",
    "difKey": 'high',
    "refKey": ["approach_mean_", "approach_stddev_"],
    "operation": function(a,b){return parseFloat(a) + (2 * parseFloat(b))},
    "displayName": "+2 Standard Deviation",
    "lineStyle": "dash",
    "color": "DarkKhaki"
  },
  "STDDEVLOW": {
    "lookupKey": "approach_stddev_",
    "difKey": 'low',
    "refKey": ["approach_mean_", "approach_stddev_"],
    "operation": function(a,b){return parseFloat(a) - ( 2 * parseFloat(b))},
    "displayName": "-2 Standard Deviation",
    "lineStyle": "dash",
    "color": "DarkKhaki"
  }
}

const subCharts = {
  "AIRSPEED": "airspeed_kt",
  "VERTSPEED": "vertspeed_fpm",
  "ANGLE": "angle_deg",
  // "GRNDTRACK": "groundtrack_deg",
  "TORQUE": "torque_percent",
  "BANK": "bank_deg",
  // "LNDG": "airspeed_at_landing_kt",
  "PITCH": "pitch_deg",
  "VERTDEV": "vertdev_dots",
  "LATDEV": "latdev_deg",
  "LATDEVDOTS": "latdev_dots",

}

const subChartUnits = {
  "AIRSPEED": 'approach_agl',
  "VERTSPEED": 'approach_agl',
  "ANGLE": 'approach_agl',
  "GRNDTRACK": 'approach_agl',
  "BANK": 'approach_agl',
  "LNDG": 'approach_agl',
  "VERTDEV": 'times_timestamp',
  "LATDEV": 'times_timestamp',
  "LATDEVDOTS": 'times_timestamp',
}


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'blue' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const modalStyle = {
  bgcolor: 'background.paper',
  boxShadow: 24,
  maxWidth: "xl",
  overflowY: "scroll",
  display: 'flex',
  flexDirection: 'row',
  height: '98%',
}

// Control/Configure the size of the markers and lines in the chart
const DATA_POINT_SIZE = 8;
const LINE_THICKNESS = 2;

function ApproachSubchart(props) {
  // Obtain reference to global data context consumer
  const dataContext = useContext(DataContext);

  const { width, height, ref: resizeDetectorRef } = useResizeDetector();

  // Keep reference of chart for event listeners/actions
  const chartRef = useRef(null);
  const isMounted = useRef(false);
  const [displayToggle, setDisplayToggle] = useState('agl');
  
  // Approach series lists
  const [approachSubchart, setApproachSubchart] = useState({})

  // Determine if data exists
  const d = dataContext.filteredReducedData ? dataContext.filteredReducedData : dataContext.baseReducedData;
  const trackPointDataExists = d && d.track_points && d.track_points.data && d.track_points.data.length > 0;
  const exceedancePointDataExists = d && d.exceedance_point && d.exceedance_point.data && d.exceedance_point.data.length > 0;
  const dataExists = trackPointDataExists || exceedancePointDataExists;

  const [dataOfInterestExists, setDataOfInterestExists] = useState(true);

  const { approachColorMap, angleColorMap } = dataContext.COLOR_MAPS.current;
  const [approachSet, setApproachSet] = useState(new Set())

  // Approach details popover controls
  const [menuOpen, setMenuOpen] = useState(null)
  const open = Boolean(menuOpen)



  /**
   * Handler for parsing date object to a formatted string version.
   * 
   * @param {object} e CanvasJS label event object containing the current date value.
   * @returns The formatted date string.
   */
  const dateLabelFormatter = (e) => {
    return dataContext.toHumanReadableDateStr(e.value, true);
  }

  function addApproachSet(e) {
    setApproachSet(prevSet => new Set(prevSet.add(e)))
  }

  /**
   * Build and retrieve formatted HTML string representing a featured
   * tooltip given a set of values for a particular row of interest.
   * 
   * row = {
   *   "key_1": "value_1",
   *   "key_2": "value_2",
   *   ...
   *   "key_n": "value_n"
   * }
   * 
   * @param {object} row Fields of interest for current row in dataset.
   * @param {string} color Hexidecimal or RGB(A) color string.
   * @returns Formatted HTML string representing a featured tooltip.
   */
  const getFeaturedTooltip = (row, color = null) => {
    // Build tool tip off all properties
    var tooltipText = "";

    // Start table
    tooltipText += "<table>";
    for (const [key, value] of Object.entries(row)) {
      let iconData = `<td><i class="fa fa-square tooltip-icon-fonts" style="color: ${color}; margin-right: 5px;"></i></td>`;
      tooltipText += `
        <tr>
          ${color !== null && iconData}
          <td style="color: #989898"><b>${key}&nbsp</b></td>
          <td><b>${value}</b></td>
        </tr>
      `;
    }

    // End table
    tooltipText += "</table>";
    return tooltipText;
  }

  // See CanvasJS configuration options at https://canvasjs.com/docs/charts/basics-of-creating-html5-chart/
  // Value format string: https://canvasjs.com/docs/charts/chart-options/axisx/valueformatstring/
  const [options, setOptions] = useState({
    zoomEnabled: true,
    animationEnabled: true,
    title: {
      text: ""
    },
    width: 400,
    height: 400,
    theme: dataContext.darkMode ? "dark1" : "light1",
    toolTip: {
      enabled: true,          //disable here
      animationEnabled: true, //disable here
      contentFormatter: e => {
        var content = "";
        for (var i = 0; i < e.entries.length; i++) {
          let dataPoint = e.entries[i].dataPoint;
          content = `${content}${dataPoint.tooltipText}`;
        }

        return content;
      }
    },
    axisX: {
      labelFontFamily: dataContext.CHART_FONTS.fontFamily,
      labelFontWeight: dataContext.CHART_FONTS.fontWeight,
      labelAutoFit: true,
      reversed: true,
      labelAngle: 0,
      title: "",
      gridThickness: 0.5,
      // See: https://canvasjs.com/docs/charts/basics-of-creating-html5-chart/formatting-date-time/
      labelFormatter: dateLabelFormatter,
      labelFontSize: 9,
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
        labelFormatter: dateLabelFormatter,
      },
      // interval: 2,
      // intervalType: "minute",
    },
    axisY: {
      gridThickness: 0.5,
      labelFontFamily: dataContext.CHART_FONTS.fontFamily,
      labelFontSize: dataContext.CHART_FONTS.fontSize,
      labelFontWeight: dataContext.CHART_FONTS.fontWeight,
      title: ""
    },
    legend: {
      fontFamily: dataContext.CHART_FONTS.fontFamily,
      fontSize: dataContext.CHART_FONTS.fontSize,
      fontWeight: dataContext.CHART_FONTS.fontWeight,
    },
    data: [
      { type: "spline", dataPoints: [] }
    ]
  });

  const [subchartOptions, setSubchartOptions] = useState({
    zoomEnabled: true,
    animationEnabled: true,
    title: {
      text: ""
    },
    theme: dataContext.darkMode ? "dark1" : "light1",
    toolTip: {
      enabled: true,          //disable here
      animationEnabled: true, //disable here
      contentFormatter: e => {
        var content = "";
        for (var i = 0; i < e.entries.length; i++) {
          let dataPoint = e.entries[i].dataPoint;
          content = `${content}${dataPoint.tooltipText}`;
        }

        return content;
      }
    },
    axisX: {
      labelFontFamily: dataContext.CHART_FONTS.fontFamily,
      labelFontWeight: dataContext.CHART_FONTS.fontWeight,
      labelAutoFit: true,
      labelAngle: 0,
      reversed: true,
      title: "",
      gridThickness: 0.5,
      // See: https://canvasjs.com/docs/charts/basics-of-creating-html5-chart/formatting-date-time/
      labelFormatter: dateLabelFormatter,
      labelFontSize: 13,
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
        labelFormatter: dateLabelFormatter,
      },
      // interval: 2,
      // intervalType: "minute",
    },
    axisY: {
      gridThickness: 0.5,
      labelFontFamily: dataContext.CHART_FONTS.fontFamily,
      labelFontSize: dataContext.CHART_FONTS.fontSize,
      labelFontWeight: dataContext.CHART_FONTS.fontWeight,
      title: ""
    },
    legend: {
      fontFamily: dataContext.CHART_FONTS.fontFamily,
      fontSize: dataContext.CHART_FONTS.fontSize,
      fontWeight: dataContext.CHART_FONTS.fontWeight,
    },
    data: [
      { type: "spline", dataPoints: [] }
    ]
  });



  const handleClose = () => {
    props.setMenuOpen(null);
  };

  // Component mount/unmount
  useEffect(() => {
    isMounted.current = true;

    // NOTE: identify document element by id on component mount to ensure canvas js chart wraps to parent container
    if (resizeDetectorRef.current) {
      let chartContainer = resizeDetectorRef.current.children[0]; // e.g., canvasjs-react-chart-container-2
      if (chartContainer) {
        chartContainer.style.height = "100%";
        // chartContainer.style.width = "100%";
      }
    }

    return () => {
      isMounted.current = false;

      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }

      setOptions({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Detect changes to incomming/ingested data
  useEffect(() => {
    if (isMounted.current) {
      // Obtain reference to active data from context
      let dataTarget = dataContext.filteredReducedData ? dataContext.filteredReducedData : dataContext.baseReducedData;

      if (dataTarget) {
        // Extract lookup table and data from track points or exceedance points if track points aren't available
        let trackDataExists = dataTarget.track_points && dataTarget.track_points.data && dataTarget.track_points.data.length > 0;
        let trackPointsTarget = trackDataExists ? dataTarget.track_points : dataTarget.exceedance_point;
        // console.log(dataTarget)
        const { data, lookup } = trackPointsTarget; // Extract lookup table from track points data

        let xAxisCol = lookup.approach_agl
        let xAxisColLabel = "Approach AGL (ft)"
        

        // Extract only required data key/values for rendering data points and tooltips from track points data
        let dataPoints = [];
        let approachDataPoints = {}
        let colorLegendSeries = [{}];
        // console.log(data)
        // console.log(lookup)
        // find min/max date for scale of x axis
        let dateMin = undefined;
        let dateMax = undefined;
        let colorLegend = new Set();

        //  console.log(data)
        xAxisTypes.forEach((axis) => {
          // lookups for types of axis
          if (axis === 'msl') {
            xAxisCol = lookup.final_msl
            xAxisColLabel = "MSL (ft)"
          } else if (axis === 'time') {
            xAxisCol = lookup.times_timestamp
            xAxisColLabel = "Time"
          }
        let builtTolerances = false
        Array.from(data).forEach((point) => {
          let timestampStr = point[lookup.times_timestamp];
          let agl = point[lookup.final_agl];

          if (timestampStr !== null && agl !== null) {

            // Change color of points here based off approach type
            let color = "#9FDEF1";
            // Add colors for angle and approach.  Angle has priority over approach, and VFR should never appear unless there's a data issue
            if (point[lookup.approach_angle_classification] && point[lookup.approach_angle_classification] !== "") {
              //   console.log(point[lookup.approach_angle_classification])
              color = angleColorMap[point[lookup.approach_angle_classification]];
              colorLegend.add(point[lookup.approach_angle_classification])
            }
            else if (point[lookup.approach_type] && point[lookup.approach_type] !== "") {
              color = approachColorMap[point[lookup.approach_type]];
              colorLegend.add(point[lookup.approach_type])
            }
            // console.log(color)  

            let pointDate = new Date(timestampStr)
            // let fieldsOfInterest = {
            //   "Time": point[lookup.human_readable_datetime],
            //   "Phase of Flight": point[lookup.phaseofflight_mavg10_str],
            //   "AGL (ft)": point[lookup.final_agl_str],
            //   "Ground Speed (kts)": point[lookup.groundspeed_final_kt_str],
            //   "Vertical Speed (f/m)": point[lookup.verticalspeed_final_fpm_str],
            //   "Roll (deg)": point[lookup.flightstate_position_roll_str],
            //   "Pitch (deg)": point[lookup.flightstate_position_pitch_str],
            //   "Yaw Rate (deg/s)": point[lookup.flightstate_rates_yawrate_str],
            // };

            /** Target Structures:
             * approachSet: [approach1, approach2]
             * approachDataPoints: [approach1: {
             *                        chartMeta: {
             *                          minX,
             *                          minY,
             *                          zoomEnabled,
             *                          etc},
             *                        dataSeries: {
             *                            actual:[{points1, points2}],
             *                            nominal:[{points1, points2}]
             *                          }
             *                        },
             *                      approach2...] 
             */


            if (point[lookup.approach_id] && point[lookup.approach_id] != "") {
              // add to master list of approach ids
              addApproachSet(point[lookup.approach_id])


              // Createapproach data schema
              if (!approachDataPoints[point[lookup.approach_id]]) {
                approachDataPoints[point[lookup.approach_id]] = {}
              }
              if (!approachDataPoints[point[lookup.approach_id]][axis]) {
                approachDataPoints[point[lookup.approach_id]][axis] = {
                  "trueAlt": {
                    "showChart": true,
                    "title": "True Altitude (ft)",
                    "actualLegend": {},
                    "nominalLegend": {},
                    "yaxis": "Altitude (ft)",
                    "xaxis": xAxisColLabel,
                    "dataSeries": {
                      "actual": [],
                      "nominal": []
                    },
                    "tolerances": {},
                    "options": {},
                    "unstableCriteriaFlag": false,

                  },
                  "airspeed_kt": {
                    "showChart": true,
                    "title": "Airspeed",
                    "legend": {},
                    "yaxis": "Airspeed |kts|",
                    "xaxis": 'Approach AGL (ft)',
                    "dataSeries": {
                      "actual": [],
                      "nominal": []
                    },
                    "tolerances": {},
                    "options": {},
                    "unstableCriteriaFlag": false,
                  },
                  
                  "vertspeed_fpm": {
                    "showChart": true,
                    "title": "Rate of Descent |fpm|",
                    "legend": {
                      showInLegend: true,
                      legendText: "Rate of Descent",
                      color: "black",
                    },
                    "yaxis": "Rate of Descent |fpm|",
                    "xaxis": xAxisColLabel,
                    "dataSeries": [],
                    "tolerances": {},
                    "options": {},
                    "unstableCriteriaFlag": false,
                  },
                  "torque_percent": {
                    "showChart": true,
                    "title": "Torque (%)",
                    "legend": {
                      showInLegend: true,
                      legendText: "Torque",
                      color: "black",
                    },
                    "yaxis": "Torque (%)",
                    "xaxis": xAxisColLabel,
                    "dataSeries": [],
                    "tolerances": {},
                    "options": {},
                    "unstableCriteriaFlag": false,
                  },
                  "angle_deg": {
                    "showChart": true,
                    "title": "Approach Angle |Deg|",
                    "legend": {
                      showInLegend: true,
                      legendText: "Approach Angle",
                      color: "black",
                    },
                    "yaxis": "Approach Angle |Deg|",
                    "xaxis": xAxisColLabel,
                    "dataSeries": [],
                    "tolerances": {},
                    "options": {},
                    "unstableCriteriaFlag": false,

                  },
                  "pitch_deg": {
                    "showChart": true,
                    "title": "Pitch (Deg)",
                    "legend": {
                      showInLegend: true,
                      legendText: "Pitch",
                      color: "black",
                    },
                    "yaxis": "Pitch (Deg)",
                    "xaxis": xAxisColLabel,
                    "dataSeries": [],
                    "tolerances": {},
                    "options": {},
                    "unstableCriteriaFlag": false,
                  },
                  "bank_deg": {
                    "showChart": true,
                    "title": "Bank (Deg)",
                    "legend": {
                      showInLegend: true,
                      legendText: "Bank",
                      color: "black",
                    },
                    "yaxis": "Bank (Deg)",
                    "xaxis": xAxisColLabel,
                    "dataSeries": [],
                    "tolerances": {},
                    "options": {},
                    "unstableCriteriaFlag": false,
                  },
                  "latdev_deg": {
                    "showChart": true,
                    "title": "IFR Lateral Deviation",
                    "legend": {
                      showInLegend: true,
                      legendText: "Flight vs Navaid Bearing Difference",
                      color: "black",
                    },
                    "yaxis": "Lateral Deviation (Degrees)",
                    "xaxis": xAxisColLabel,
                    "dataSeries": [],
                    "tolerances": {},
                    "options": {},
                    "unstableCriteriaFlag": false,
                  },
                  "latdev_dots": {
                    "showChart": true,
                    "title": "IFR Lateral Deviation",
                    "legend": {
                      showInLegend: true,
                      legendText: "Flight vs Navaid Bearing Difference",
                      color: "black",
                    },
                    "yaxis": "Lateral Deviation (Dots)",
                    "xaxis": xAxisColLabel,
                    "dataSeries": [],
                    "tolerances": {},
                    "options": {},
                    "unstableCriteriaFlag": false,
                  },
                  "vertdev_dots": {
                    "showChart": true,
                    "title": "IFR Vertical Deviation",
                    "legend": {
                      showInLegend: true,
                      legendText: "Glide Slope",
                      color: "black",
                    },
                    "yaxis": "Vertical Deviation (Dots)",
                    "xaxis": xAxisColLabel,
                    "dataSeries": [],
                    "tolerances": {},
                    "options": {},
                    "unstableCriteriaFlag": false,
                  },
                  
                  // "airspeed_at_landing_kt": {
                  //   "showChart": true,
                  //   "title": "Hover/Touchdown Airspeed (kts)",
                  //   "legend": {
                  //     showInLegend: true,
                  //     legendText: "Airspeed (kts)",
                  //     color: "black",
                  //   },
                  //   "yaxis": "Hover/Touchdown Airspeed (kts)",
                  //   "xaxis": xAxisColLabel,
                  //   "dataSeries": [],
                  //   "tolerances": {},
                  //   "options": {},
                  //   "unstableCriteriaFlag": false,
                  // },
                  
                }
              }

              // Add approach ID field to Approach set
              if (!approachDataPoints[point[lookup.approach_id]][axis].approach_id) {
                approachDataPoints[point[lookup.approach_id]][axis].approach_id = point[lookup.approach_id]
              }
              // Add legend options

              approachDataPoints[point[lookup.approach_id]][axis]['trueAlt']["actualLegend"] = {
                legendText: point[lookup.approach_type],
                showInLegend: true,
                legendMarkerColor: approachColorMap[point[lookup.approach_type]],
                legendMarkerType: "square",
              }


              approachDataPoints[point[lookup.approach_id]][axis]['trueAlt']['nominalLegend'] = {
                legendText: "Nominal Approach Path",
                showInLegend: true,
                legendMarkerColor: 'blue',
                // legendMarkerType: "circle",
              }

              // if (point[lookup.approach_id] && approachDataPoints.indexOf(point[lookup.approach_id]) === -1) {

              //   approachDataPoints.push(point[lookup.approach_id])
              // }
              // // add to series lists for nominal and actual 
              // if (point[lookup.approach_id] && approachDataPoints.indexOf(point[lookup.approach_id]) > -1) {
              //   console.log(approachDataPoints)
              //   if (approachDataPoints[point[lookup.approach_id]][axis].indexOf("actual") === -1) {
              //     approachDataPoints[point[lookup.approach_id]][axis].push("actual")
              //   }
              // }

              // populate charts
              // Actual vs Nominal  
              // if (approachDataPoints[point[lookup.approach_id]][axis]['trueAlt']['dataSeries']['actual']) {
              //     approachDataPoints[point[lookup.approach_id]][axis]['trueAlt']['dataSeries']['actual'].push({
              //       flightid: point[lookup.flightid],
              //       approach_id: point[lookup.approach_id],
              //       x: pointDate,
              //       y: point[lookup.approach_agl],
              //       // color: approachColorMap[point[lookup.approach_type]],
              //       // lineColor: approachColorMap[point[lookup.approach_type]],
              //       tooltipText: getFeaturedTooltip({"Time": point[lookup.human_readable_datetime],
              //       "Phase of Flight": point[lookup.phaseofflight_mavg10_str],
              //       "Approach AGL (ft)": point[lookup.approach_agl],
              //       "Ground Speed (kts)": point[lookup.groundspeed_final_kt_str],
              //       "Vertical Speed (f/m)": point[lookup.verticalspeed_final_fpm_str],
              //       "Roll (deg)": point[lookup.flightstate_position_roll_str],
              //       "Pitch (deg)": point[lookup.flightstate_position_pitch_str],
              //       "Yaw Rate (deg/s)": point[lookup.flightstate_rates_yawrate_str],}, approachColorMap[point[lookup.approach_type]]),
              //     })
              //   }
              //   if (approachDataPoints[point[lookup.approach_id]][axis]['trueAlt']['dataSeries']['nominal']) {
              //     approachDataPoints[point[lookup.approach_id]][axis]['trueAlt']['dataSeries']['nominal'].push({
              //       flightid: point[lookup.flightid],
              //       approach_id: point[lookup.approach_id],
              //       x: pointDate,
              //       y: point[lookup.nominal_approach_agl],
              //       color: "blue",
              //       lineColor: "blue",
              //       tooltipText: getFeaturedTooltip({"Time": point[lookup.human_readable_datetime],
              //                     "Nominal Approach AGL (ft)": point[lookup.nominal_approach_agl],}, 'blue'),
              //     })
              //   }
              // if(!builtTolerances){
              // Tolerance and STDEV are done in loop because approach names don't exist as a list outside of approach points.
              // Tolerances.  Loop tolerances, and for each:
              // Check if the tolerance object exsits, if not create it (can't manually set, might take that into a loop later).  
              // Check if the High, Nomr High, Norm Low and Low data series exit, if not create those.
              //AIRSPEED is not time based, needs separate call out
              // Add to relevant series.
              // console.log(approachDataPoints)
              // Filter for IFR > 300 agl and VFR < 300 AGL, can skip all the data processing if the point lies outside those areas.

              if((point[lookup.approach_type].trim().substring(0,3) === 'IFR' && point[lookup['approach_agl']] >= 300) ||
              (point[lookup.approach_type].trim().substring(0,3) === 'VFR' && point[lookup['approach_agl']] <= 300)){
              Object.keys(subCharts).map((item) => {
                Object.keys(TOLERANCELIST).map((key) => {
                  // console.log(item,key)
                  // console.log(subCharts[item])
                  // console.log(approachDataPoints[point[lookup.approach_id]][axis])
                  if (!approachDataPoints[point[lookup.approach_id]][axis][subCharts[item]]['tolerances'][TOLERANCELIST[key]["lookupKey"] + subCharts[item]]) {
                    approachDataPoints[point[lookup.approach_id]][axis][subCharts[item]]['tolerances'][TOLERANCELIST[key]["lookupKey"] + subCharts[item]] = {
                      "showInLegend": false,
                      type: "line",
                      markerType: "none",
                      lineDashType: TOLERANCELIST[key]["lineStyle"],
                      color: TOLERANCELIST[key]["color"],
                      "dataPoints": []
                    }
                  }
                  // console.log(point[lookup[TOLERANCELIST[key]["lookupKey"]+subCharts[item]]])
                  let tempY = point[lookup[TOLERANCELIST[key]["lookupKey"] + subCharts[item]]]
                  if(subCharts[item] === "vertdev_dots" && key === 'HIGHTOL'){
                    tempY = 1
                  }
                  // Ugly, checks if the value exists or was assigned because we're hardcoding to avoid a reprocess fee
                  // if (tempY) {
                    // console.log(item, point[lookup.approach_agl])
                    
                    // Intercept the value for BANK charts and set to 30 for VFR, 20 for IFR, to avoid a reprocessing
                    
                    if(subCharts[item] === "bank_deg"){
                      if( key === "NORMUPPER"){
                        point[lookup["approach_type"]].substring(0,3) === "VFR" ? tempY = 30 : tempY = 20
                      }
                      else {
                        tempY = null
                      }
                    }
                    if(subCharts[item] === "pitch_deg"){
                      if( key === "NORMUPPER"){
                        tempY = 20
                      }
                      else {
                        tempY = null
                      }
                      
                    }
                    // Intercept the values for approach angle and make them absolute, to avoid a reprocess
                    if(subCharts[item] === "angle_deg"){
                      tempY = Math.abs(tempY)  // Not sure if still needed.
                    }
                    if(subCharts[item] === "vertspeed_fpm"){
                      if(key === 'NORMUPPER')
                      {
                        tempY = Math.abs(tempY)
                      }
                      else {
                        tempY = null
                      }
                    }
                    if(subCharts[item] === "airspeed_kt"){
                      if(key === "LOWTOL"){
                        tempY = 50
                      } else if(key === "HIGHTOL"){
                        tempY = 130
                      }
                    }
                    if(subCharts[item] === "angle_deg"){
                      if(point[lookup["approach_type"]].substring(0,3) === "IFR"){
                      if(key === "LOWTOL"){
                        tempY = 1
                      } else if(key === "HIGHTOL"){
                        tempY = 7
                      } else if(key === "NORMLOWER"){
                        tempY = 2
                      } else if(key === "NORMUPPER"){
                        tempY = 6
                      }
                    }
                      else {
                        if(key === "LOWTOL"){
                          tempY = 2
                        } else if(key === "HIGHTOL"){
                          tempY = 13
                        } else if(key === "NORMLOWER"){
                          tempY = 5
                        } else if(key === "NORMUPPER"){
                          tempY = 10
                        }
                      }
                    }
                    if(subCharts[item] === "vertdev_dots"){
                     if(key === "HIGHTOL"){
                        tempY = 2
                      }
                    }
                    if(subCharts[item] === "latdev_dots"){
                      if(key === "HIGHTOL"){
                        tempY = 2
                      }
                    }

                    approachDataPoints[point[lookup.approach_id]][axis][subCharts[item]]['tolerances'][TOLERANCELIST[key]["lookupKey"] + subCharts[item]]['dataPoints'].push({
                      x: item !== 'AIRSPEED' ? axis === 'time' ? new Date(point[xAxisCol]) : point[xAxisCol] : point[lookup.approach_agl],
                      y: tempY,
                      // color: approachColorMap[point[lookup.approach_type]],
                      // lineColor: approachColorMap[point[lookup.approach_type]],
                      tooltipText: getFeaturedTooltip({
                        "Tolerance": TOLERANCELIST[key]["displayName"],

                        [String(subCharts[item]).charAt(0).toUpperCase() + String(subCharts[item]).slice(1)]: Math.abs(point[lookup[TOLERANCELIST[key]["lookupKey"] + subCharts[item]]]),


                      }, "red")


                    })
                  
                })

                // Statistical mean and deviations, similar to Tolerance but statistically derived.
                // Set the line reference to use from the dataTarget
                // console.log({dataTarget})

                if(dataTarget && dataTarget.aggregate_approach_data ){

                /** Mappings for oddball names:
                 * airspeed_at_landing_kt -> landing_kt */ 

                let subchartConvert = subCharts[item]
                // if(item === "LNDG"){
                //   subchartConvert = "airspeed_at_landing_kt"
                // }
                if(item === "GRNDTRACK"){
                  subchartConvert = "groundtrack_deg"
                }

                // Pull aggregate approach data that matches the approach, so we can figure out what a proper approach looks like
                let statHandle = dataTarget.aggregate_approach_data.data.filter((appData) =>  {
                  // console.log(appData[dataTarget.aggregate_approach_data.lookup['approach_type']].trim())
                  // console.log(point[lookup.approach_type].substring(0,3).trim())
                  return appData[dataTarget.aggregate_approach_data.lookup['approach_type']].trim() ===  point[lookup.approach_type].substring(0,3).trim()} )
                  if (statHandle && statHandle.length > 0){
                  // console.log(statHandle)
                  }
                  // console.log(point[lookup.approach_type].substring(0,3))
                  // console.log(dataTarget.aggregate_approach_data)
                Object.keys(STATISTICALLIST).map((key) => {
                  
                  // DOn't use the converted bit for the overall mapping, only for getting values
                  if (!approachDataPoints[point[lookup.approach_id]][axis][subCharts[item]]['tolerances'][STATISTICALLIST[key]["lookupKey"] + STATISTICALLIST[key]["difKey"] + subCharts[item]]) {
                    approachDataPoints[point[lookup.approach_id]][axis][subCharts[item]]['tolerances'][STATISTICALLIST[key]["lookupKey"] + STATISTICALLIST[key]["difKey"] + subCharts[item]] = {
                      "showInLegend": false,
                      type: "line",
                      markerType: "none",
                      lineDashType: STATISTICALLIST[key]["lineStyle"],
                      color: STATISTICALLIST[key]["color"],
                      "dataPoints": []
                    }
                  }
                  // console.log(approachDataPoints[point[lookup.approach_id]][axis][subCharts[item]]['tolerances'][STATISTICALLIST[key]["lookupKey"] + STATISTICALLIST[key]["difKey"] + subCharts[item]])
                  // Calc Y before hand
                  // console.log(statHandle)
                  if (statHandle && statHandle.length > 0){
                    // console.log(statHandle)
                    // console.log(STATISTICALLIST[key]["lookupKey"] + subchartConvert)
                    // console.log(statHandle[0][dataTarget.aggregate_approach_data.lookup[STATISTICALLIST[key]["lookupKey"] + subchartConvert]])
                  }
                  let yVal = (statHandle && statHandle.length > 0) ? parseFloat(statHandle[0][dataTarget.aggregate_approach_data.lookup[STATISTICALLIST[key]["lookupKey"] + subchartConvert]]) : 0
                  if(STATISTICALLIST[key].operation){
                    // if(subCharts[item] === 'landing_kt'){
                    // console.log("Suffix", subchartConvert)
                    // console.log("Lookup", dataTarget.aggregate_approach_data.lookup)
                    // console.log("statHnadle", statHandle[0])
                    // console.log("refKey 1", STATISTICALLIST[key]["refKey"][0] + subCharts[item])
                    // console.log("refKey 2", STATISTICALLIST[key]["refKey"][1] + subCharts[item])
                    // }
                    let abase = (statHandle && statHandle.length > 0) ? statHandle[0][dataTarget.aggregate_approach_data.lookup[STATISTICALLIST[key]["refKey"][0] + subchartConvert]] : 0
                    let amod = (statHandle && statHandle.length > 0) ? statHandle[0][dataTarget.aggregate_approach_data.lookup[STATISTICALLIST[key]["refKey"][1] + subchartConvert]] : 0
                    
                    yVal = STATISTICALLIST[key].operation(abase, amod)
                  }
                  // if(subCharts[item] === 'landing_kt'){
                  // console.log(yVal)
                  // console.log(statHandle[0][dataTarget.aggregate_approach_data.lookup[STATISTICALLIST[key]["lookupKey"] + subCharts[item]]] ? 'True' : 'False')
                  // }
                  
                  if (statHandle && statHandle.length > 0 && statHandle[0][dataTarget.aggregate_approach_data.lookup[STATISTICALLIST[key]["lookupKey"] + subchartConvert]]) {
                    // console.log(item, point[lookup.approach_agl])
                    approachDataPoints[point[lookup.approach_id]][axis][subCharts[item]]['tolerances'][STATISTICALLIST[key]["lookupKey"] + STATISTICALLIST[key]['difKey'] + subCharts[item]]['dataPoints'].push({
                      x: item !== 'AIRSPEED' ? axis === 'time' ? new Date(point[xAxisCol]) : point[xAxisCol] : point[lookup.approach_agl],
                      y: yVal,
                      // color: approachColorMap[point[lookup.approach_type]],
                      // lineColor: approachColorMap[point[lookup.approach_type]],
                      tooltipText: getFeaturedTooltip({
                        "Statistic": STATISTICALLIST[key]["displayName"],

                        [String(subCharts[item]).charAt(0).toUpperCase() + String(subCharts[item]).slice(1)]: yVal,


                      },  STATISTICALLIST[key]["color"])
                      

                    })
                    // console.log(point[lookup.approach_id], axis, item, key)
                    // console.log(approachDataPoints)
                  }
                })
              }

              })
              // builtTolerances = true;
            // } // End build Tolerances

              
              // Approach Angle  
              // console.log(approachDataPoints)
              
              if (approachDataPoints[point[lookup.approach_id]][axis][subCharts['ANGLE']]['dataSeries']) {

                approachDataPoints[point[lookup.approach_id]][axis][subCharts['ANGLE']]['dataSeries'].push({
                  flightid: point[lookup.flightid],
                  approach_id: point[lookup.approach_id],
                  x: axis === 'time' ? new Date(point[xAxisCol]) : point[xAxisCol],
                  y: Math.abs(point[lookup.approach_angle]),
                  color: "black",
                  lineColor: "black",
                  tooltipText: getFeaturedTooltip({
                    [xAxisColLabel]: axis === 'time' ? new Date(point[lookup.times_timestamp]) : point[xAxisCol],
                    "Approach Angle": Math.abs(point[lookup.approach_angle]),

                  }, point[lookup.approach_unstable_angle] ? "orange" : "black")
                })
              }
              // Flag for unstable.
              let tempY = approachDataPoints[point[lookup.approach_id]][axis][subCharts['ANGLE']]['tolerances']['approach_stddev_highangle_deg']['dataPoints'][0]
              if(tempY && (point[lookup.approach_angle] > tempY['y'] ||
                point[lookup.approach_angle] < tempY['y'])
                ){approachDataPoints[point[lookup.approach_id]][axis][subCharts['ANGLE']]['unstableCriteriaFlag'] = true;

              }
            
            
              

              // Vertical Speed
              if (approachDataPoints[point[lookup.approach_id]][axis][subCharts['VERTSPEED']]['dataSeries']) {

                approachDataPoints[point[lookup.approach_id]][axis][subCharts['VERTSPEED']]['dataSeries'].push({
                  flightid: point[lookup.flightid],
                  approach_id: point[lookup.approach_id],
                  x: axis === 'time' ? new Date(point[xAxisCol]) : point[xAxisCol],
                  y: Math.abs(point[lookup.verticalspeed_final_fpm]),
                  color: "black",
                  lineColor: "black",
                  // color: approachColorMap[point[lookup.approach_type]],
                  // lineColor: approachColorMap[point[lookup.approach_type]],
                  tooltipText: getFeaturedTooltip({
                    [xAxisColLabel]: axis === 'time' ? new Date(point[lookup.times_timestamp]) : point[xAxisCol],
                    "Vertical Speed": Math.abs(point[lookup.verticalspeed_final_fpm]),


                  }, Math.abs(point[lookup.approach_unstable_vertspeed]) ? "orange" : "black")
                })
                 // Flag for unstable. 
                let tempY = approachDataPoints[point[lookup.approach_id]][axis][subCharts['VERTSPEED']]['tolerances']['approach_stddev_high'+subCharts['VERTSPEED']]['dataPoints'][0]
              if(tempY && (point[lookup.verticalspeed_final_fpm] > tempY['y'] ||
                point[lookup.verticalspeed_final_fpm] < tempY['y'])
                ){approachDataPoints[point[lookup.approach_id]][axis][subCharts['VERTSPEED']]['unstableCriteriaFlag'] = true;

                }
              }

              // // Ground track is plotted as actual vs nominal bearing, with the tolerances associated

              // // Add legend options
              // approachDataPoints[point[lookup.approach_id]][axis][subCharts['GRNDTRACK']]["actualLegend"] = {
              //   legendText: 'Actual Ground Track',
              //   showInLegend: true,
              //   legendMarkerColor: "black",
              //   legendMarkerType: "square",
              // }


              // approachDataPoints[point[lookup.approach_id]][axis][subCharts['GRNDTRACK']]['nominalLegend'] = {
              //   legendText: "Nominal Ground Track",
              //   showInLegend: true,
              //   color: 'blue',
              //   legendMarkerType: "triangle",
              //   legendMarkerColor: "white",
              // }

              // approachDataPoints[point[lookup.approach_id]][axis][subCharts['VERTDEV']]['nominalLegend'] = {
              //   legendText: "2 to 4",
              //   showInLegend: true,
              //   color: 'red',
              // }

              // approachDataPoints[point[lookup.approach_id]][axis][subCharts['LATDEV']]['nominalLegend'] = {
              //   legendText: "2 to 4 dots",
              //   showInLegend: true,
              //   color: 'red',
              // }

              // if (approachDataPoints[point[lookup.approach_id]][axis][subCharts['GRNDTRACK']]['dataSeries']['actual']) {
              //   approachDataPoints[point[lookup.approach_id]][axis][subCharts['GRNDTRACK']]['dataSeries']['actual'].push({
              //     flightid: point[lookup.flightid],
              //     approach_id: point[lookup.approach_id],
              //     x: axis === 'time' ? new Date(point[xAxisCol]) : point[xAxisCol],
              //     y: point[lookup.approach_bearing_deg],
              //     color: "black",
              //     lineColor: "black",
              //     tooltipText: getFeaturedTooltip({
              //       [xAxisColLabel]: axis === 'time' ? new Date(point[lookup.times_timestamp]) : point[xAxisCol],
              //       "Ground Track (degrees)": point[lookup.approach_bearing_deg],
              //     }, point[lookup.approach_unstable_groundtrack] ? "orange" : "blue"),
              //   })
              // }

              // if (approachDataPoints[point[lookup.approach_id]][axis][subCharts['GRNDTRACK']]['dataSeries']['nominal']) {
              //   approachDataPoints[point[lookup.approach_id]][axis][subCharts['GRNDTRACK']]['dataSeries']['nominal'].push({
              //     flightid: point[lookup.flightid],
              //     approach_id: point[lookup.approach_id],
              //     x: axis === 'time' ? new Date(point[xAxisCol]) : point[xAxisCol],
              //     y: point[lookup.approach_bearing_ideal_deg],
              //     color: "blue",
              //     lineColor: "blue",
              //     tooltipText: getFeaturedTooltip({
              //       [xAxisColLabel]: axis === 'time' ? new Date(point[lookup.times_timestamp]) : point[xAxisCol],
              //       "Nominal Ground Track (degrees)": point[lookup.approach_bearing_ideal_deg],
              //     }, 'blue'),
              //   })
              // }


              // Airspeed - AIrspeed vs Approach AGL
              // console.log(approachDataPoints)
             
              if (approachDataPoints[point[lookup.approach_id]][axis][subCharts['AIRSPEED']]['dataSeries']) {

                approachDataPoints[point[lookup.approach_id]][axis][subCharts['AIRSPEED']]['dataSeries']['actual'].push({
                  flightid: point[lookup.flightid],
                  approach_id: point[lookup.approach_id],
                  x: point[lookup['approach_agl']], // airspeed can't go against anything other than AGL right now
                  y: point[lookup.flightstate_speeds_indicatedairspeed],
                  color: "black",
                  lineColor: "black",
                  // color: approachColorMap[point[lookup.approach_type]],
                  // lineColor: approachColorMap[point[lookup.approach_type]],
                  tooltipText: getFeaturedTooltip({
                    [xAxisColLabel]: point[xAxisCol],
                    "Air Speed": point[lookup.flightstate_speeds_indicatedairspeed],


                  }, point[lookup.approach_unstable_airspeed] ? "orange" : "black")
                })
                let tempY = approachDataPoints[point[lookup.approach_id]][axis][subCharts['AIRSPEED']]['tolerances']['approach_stddev_high'+subCharts['AIRSPEED']]['dataPoints'][0]
                if(tempY && (point[lookup.flightstate_speeds_indicatedairspeed] > tempY['y'] ||
                  point[lookup.flightstate_speeds_indicatedairspeed] < tempY['y'])
                  ){approachDataPoints[point[lookup.approach_id]][axis][subCharts['AIRSPEED']]['unstableCriteriaFlag'] = true;
                    
                  }
                // Build gates lines
                
                if (approachDataPoints[point[lookup.approach_id]][axis][subCharts['AIRSPEED']]['dataSeries']) {

                  // band normal upper/lower are identical, and used to store the nominal information.  Tol low and High are based off that, and the 3 will form a series of steps.
                  approachDataPoints[point[lookup.approach_id]][axis][subCharts['AIRSPEED']]['dataSeries']['nominal'].push({
                    flightid: point[lookup.flightid],
                    approach_id: point[lookup.approach_id],
                    x: point[lookup['approach_agl']], // airspeed can't go against anything other than AGL right now
                    y: point[lookup.approach_type].indexOf("IFR") > -1 ? 90 : point[lookup.approach_norm_high_airspeed_kt],
                    color: "blue",
                    lineColor: "blue",
                    // color: approachColorMap[point[lookup.approach_type]],
                    // lineColor: approachColorMap[point[lookup.approach_type]],
                    tooltipText: getFeaturedTooltip({
                      [xAxisColLabel]: point[xAxisCol],
                      "Air Speed": point[lookup.approach_type].indexOf("IFR") > -1 ? 90 : point[lookup.approach_norm_high_airspeed_kt],


                    }, "blue")
                  })
                }
              }
            
              // Bank Angle
              if (approachDataPoints[point[lookup.approach_id]][axis][subCharts['BANK']]['dataSeries']) {

                approachDataPoints[point[lookup.approach_id]][axis][subCharts['BANK']]['dataSeries'].push({
                  flightid: point[lookup.flightid],
                  approach_id: point[lookup.approach_id],
                  x: axis === 'time' ? new Date(point[xAxisCol]) : point[xAxisCol],
                  y: point[lookup.flightstate_position_roll],
                  color: "black",
                  lineColor: "black",
                  // color: approachColorMap[point[lookup.approach_type]],
                  // lineColor: approachColorMap[point[lookup.approach_type]],
                  tooltipText: getFeaturedTooltip({
                    [xAxisColLabel]: axis === 'time' ? new Date(point[lookup.times_timestamp]) : point[xAxisCol],
                    "Bank Angle": point[lookup.flightstate_position_roll],


                  }, point[lookup.approach_unstable_bank] ? "orange" : "black")
                })
                let tempY = approachDataPoints[point[lookup.approach_id]][axis][subCharts['BANK']]['tolerances']['approach_stddev_high'+subCharts['BANK']]['dataPoints'][0]
                if(tempY && (point[lookup.flightstate_position_roll] > tempY['y'] ||
                  point[lookup.flightstate_position_roll] < tempY['y'])
                  ){approachDataPoints[point[lookup.approach_id]][axis][subCharts['BANK']]['unstableCriteriaFlag'] = true;
  
                  }
              }

              // Torque, which is ((engines_computations_e1torque + coalesce(engines_computations_e2torque,engines_computations_e1torque))/2)
              if (approachDataPoints[point[lookup.approach_id]][axis][subCharts['TORQUE']]['dataSeries']) {
                // console.log(point[lookup.engines_computations_e1torque], point[lookup.engines_computations_e2torque],((point[lookup.engines_computations_e1torque] + point[lookup.engines_computations_e2torque] ? point[lookup.engines_computations_e2torque]:point[lookup.engines_computations_e1torque])/2))
                let torqueCalc = ((point[lookup.engines_computations_e1torque] + (point[lookup.engines_computations_e2torque] ? point[lookup.engines_computations_e2torque]:point[lookup.engines_computations_e1torque]))/2)
                approachDataPoints[point[lookup.approach_id]][axis][subCharts['TORQUE']]['dataSeries'].push({
                  flightid: point[lookup.flightid],
                  approach_id: point[lookup.approach_id],
                  x: axis === 'time' ? new Date(point[xAxisCol]) : point[xAxisCol],
                  y:  torqueCalc,
                  color: "black",
                  lineColor: "black",
                  // color: approachColorMap[point[lookup.approach_type]],
                  // lineColor: approachColorMap[point[lookup.approach_type]],
                  tooltipText: getFeaturedTooltip({
                    [xAxisColLabel]: axis === 'time' ? new Date(point[lookup.times_timestamp]) : point[xAxisCol],
                    "Torque": torqueCalc,


                  }, "black")
                })
                // console.log(approachDataPoints)
                let tempY = approachDataPoints[point[lookup.approach_id]][axis][subCharts['TORQUE']]['tolerances']['approach_stddev_high'+subCharts['TORQUE']]['dataPoints'][0]
                if(tempY && (point[lookup.flightstate_position_roll] > tempY['y'] ||
                  point[lookup.flightstate_position_roll] < tempY['y'])
                  ){approachDataPoints[point[lookup.approach_id]][axis][subCharts['TORQUE']]['unstableCriteriaFlag'] = true;
  
                  }
              }

              // Pitch
              // console.log(approachDataPoints[point[lookup.approach_id]][axis][subCharts['PITCH']])
              if (approachDataPoints[point[lookup.approach_id]][axis][subCharts['PITCH']]['dataSeries']) {
                
                approachDataPoints[point[lookup.approach_id]][axis][subCharts['PITCH']]['dataSeries'].push({
                  flightid: point[lookup.flightid],
                  approach_id: point[lookup.approach_id],
                  x: axis === 'time' ? new Date(point[xAxisCol]) : point[xAxisCol],
                  y:  point[lookup.flightstate_position_pitch],
                  color: "black",
                  lineColor: "black",
                  // color: approachColorMap[point[lookup.approach_type]],
                  // lineColor: approachColorMap[point[lookup.approach_type]],
                  tooltipText: getFeaturedTooltip({
                    [xAxisColLabel]: axis === 'time' ? new Date(point[lookup.times_timestamp]) : point[xAxisCol],
                    "Pitch": point[lookup.flightstate_position_pitch],


                  }, "black")
                })
                // console.log(approachDataPoints)
                let tempY = approachDataPoints[point[lookup.approach_id]][axis][subCharts['PITCH']]['tolerances']['approach_stddev_high'+subCharts['PITCH']]['dataPoints'][0]
                if(tempY && (point[lookup.flightstate_position_roll] > tempY['y'] ||
                  point[lookup.flightstate_position_roll] < tempY['y'])
                  ){approachDataPoints[point[lookup.approach_id]][axis][subCharts['PITCH']]['unstableCriteriaFlag'] = true;
  
                  }
              }

              // Hover
              // if (point[lookup.approach_type] !== "VFR") {
              //   approachDataPoints[point[lookup.approach_id]][axis][subCharts['LNDG']]['showChart'] = false
              // }
              // else {
              //   if (approachDataPoints[point[lookup.approach_id]][axis][subCharts['LNDG']]['dataSeries']) {

              //     approachDataPoints[point[lookup.approach_id]][axis][subCharts['LNDG']]['dataSeries'].push({
              //       flightid: point[lookup.flightid],
              //       approach_id: point[lookup.approach_id],
              //       x: axis === 'time' ? new Date(point[xAxisCol]) : point[xAxisCol],
              //       y: point[lookup.approach_airspeed_smooth],
              //       color: "black",
              //       lineColor: "black",
              //       // color: approachColorMap[point[lookup.approach_type]],
              //       // lineColor: approachColorMap[point[lookup.approach_type]],
              //       tooltipText: getFeaturedTooltip({
              //         [xAxisColLabel]: axis === 'time' ? new Date(point[lookup.times_timestamp]) : point[xAxisCol],
              //         "Hover Landing": point[lookup.approach_airspeed_smooth],


              //       }, point[lookup.approach_unstable_landing] ? "orange" : "blue")
              //     })
              //     let tempY = approachDataPoints[point[lookup.approach_id]][axis][subCharts['LNDG']]['tolerances']['approach_stddev_high'+subCharts['LNDG']]['dataPoints'][0]
              //     if(tempY && (point[lookup.approach_airspeed_smooth] > tempY['y'] ||
              //       point[lookup.approach_airspeed_smooth] < tempY['y'])
              //       ){approachDataPoints[point[lookup.approach_id]][axis][subCharts['LNDG']]['unstableCriteriaFlag'] = true;
    
              //       }
              //   }
              // }

              // Lateral Deviation
              // console.log(point[lookup.approach_navaid_type])
              if (point[lookup.approach_type] !== "IFR" || point[lookup.approach_navaid_type] === 'LOC') {
                approachDataPoints[point[lookup.approach_id]][axis][subCharts['LATDEV']]['showChart'] = false
              }
              else {
                if (approachDataPoints[point[lookup.approach_id]][axis][subCharts['LATDEV']]['dataSeries']) {
                  // console.log(point, point[lookup.approach_latdev_diff_deg])
                  approachDataPoints[point[lookup.approach_id]][axis][subCharts['LATDEV']]['dataSeries'].push({
                    flightid: point[lookup.flightid],
                    approach_id: point[lookup.approach_id],
                    x: axis === 'time' ? new Date(point[xAxisCol]) : point[xAxisCol],
                    y: point[lookup.approach_latdev_diff_deg],
                    color: "black",
                    lineColor: "black",
                    // color: approachColorMap[point[lookup.approach_type]],
                    // lineColor: approachColorMap[point[lookup.approach_type]],
                    tooltipText: getFeaturedTooltip({
                      [xAxisColLabel]: axis === 'time' ? new Date(point[lookup.times_timestamp]) : point[xAxisCol],
                      "Bearing to Navaid": point[lookup.approach_latdev_diff_deg],


                    }, point[lookup.approach_unstable_landing] ? "orange" : "black")
                  })
                  let tempY = approachDataPoints[point[lookup.approach_id]][axis][subCharts['LATDEV']]['tolerances']['approach_stddev_high'+subCharts['LATDEV']]['dataPoints'][0]
                  if(tempY && (point[lookup.approach_latdev_diff_deg] > tempY['y'] ||
                    point[lookup.approach_latdev_diff_deg] < tempY['y'])
                    ){approachDataPoints[point[lookup.approach_id]][axis][subCharts['LATDEV']]['unstableCriteriaFlag'] = true;
    
                    }
                }

              }

              // Lateral Deviation Dots
              // console.log(point[lookup.approach_type], point[lookup.approach_navaid_type], (point[lookup.approach_type] === "IFR" && point[lookup.approach_navaid_type] !== 'LOC'))
              if (point[lookup.approach_type] !== "IFR" || point[lookup.approach_navaid_type] !== 'LOC') {
                approachDataPoints[point[lookup.approach_id]][axis][subCharts['LATDEVDOTS']]['showChart'] = false
              }
              else {
                if (approachDataPoints[point[lookup.approach_id]][axis][subCharts['LATDEVDOTS']]['dataSeries']) {
                  // console.log(point, point[lookup.approach_latdev_diff_deg])
                  approachDataPoints[point[lookup.approach_id]][axis][subCharts['LATDEVDOTS']]['dataSeries'].push({
                    flightid: point[lookup.flightid],
                    approach_id: point[lookup.approach_id],
                    x: axis === 'time' ? new Date(point[xAxisCol]) : point[xAxisCol],
                    y: (point[lookup.approach_latdev_diff_deg]/0.625),
                    color: "black",
                    lineColor: "black",
                    // color: approachColorMap[point[lookup.approach_type]],
                    // lineColor: approachColorMap[point[lookup.approach_type]],
                    tooltipText: getFeaturedTooltip({
                      [xAxisColLabel]: axis === 'time' ? new Date(point[lookup.times_timestamp]) : point[xAxisCol],
                      "Bearing to Navaid": (point[lookup.approach_latdev_diff_deg]/0.625),


                    }, point[lookup.approach_unstable_landing] ? "orange" : "black")
                  })
                  let tempY = approachDataPoints[point[lookup.approach_id]][axis][subCharts['LATDEVDOTS']]['tolerances']['approach_stddev_high'+subCharts['LATDEVDOTS']]['dataPoints'][0]
                  if(tempY && (point[lookup.approach_latdev_diff_deg] > tempY['y'] ||
                    point[lookup.approach_latdev_diff_deg] < tempY['y'])
                    ){approachDataPoints[point[lookup.approach_id]][axis][subCharts['LATDEVDOTS']]['unstableCriteriaFlag'] = true;
    
                    }
                }

              }

              // Vertical Deviation
              if (point[lookup.approach_type] !== "IFR") {
                approachDataPoints[point[lookup.approach_id]][axis][subCharts['VERTDEV']]['showChart'] = false
              }
              else {
                // console.log(point[lookup.approach_vertdev_diff_deg], point[lookup.approach_collocated_angle_deg])
                if (approachDataPoints[point[lookup.approach_id]][axis][subCharts['VERTDEV']]['dataSeries']) {
                  // console.log(point, point[lookup.approach_vertdev_diff_deg])
                  approachDataPoints[point[lookup.approach_id]][axis][subCharts['VERTDEV']]['dataSeries'].push({
                    flightid: point[lookup.flightid],
                    approach_id: point[lookup.approach_id],
                    x: axis === 'time' ? new Date(point[xAxisCol]) : point[xAxisCol],
                    y: (point[lookup.approach_vertdev_diff_deg]/0.125) ,
                    color: "black",
                    lineColor: "black",
                    // color: approachColorMap[point[lookup.approach_type]],
                    // lineColor: approachColorMap[point[lookup.approach_type]],
                    tooltipText: getFeaturedTooltip({
                      [xAxisColLabel]: axis === 'time' ? new Date(point[lookup.times_timestamp]) : point[xAxisCol],
                      "Glideslope": point[lookup.approach_vertdev_diff_deg],


                    }, point[lookup.approach_unstable_landing] ? "orange" : "black")
                  })
                }let tempY = approachDataPoints[point[lookup.approach_id]][axis][subCharts['VERTDEV']]['tolerances']['approach_stddev_high'+subCharts['VERTDEV']]['dataPoints'][0]
                if(tempY && (point[lookup.approach_vertdev_diff_deg] > tempY['y'] ||
                  point[lookup.approach_vertdev_diff_deg] < tempY['y'])
                  ){approachDataPoints[point[lookup.approach_id]][axis][subCharts['VERTDEV']]['unstableCriteriaFlag'] = true;
  
                  }
              }

            }

            }




            // let tooltipText = getFeaturedTooltip(fieldsOfInterest, color);

            // dataPoints.push({
            //   flightid: point[lookup.flightid],
            //   approach_id : point[lookup.approach_id],
            //   x: pointDate,
            //   y: dataContext.round(agl),
            //   color: color,
            //   tooltipText: tooltipText,
            // });

            if (dateMin === undefined && dateMax === undefined) {
              dateMin = pointDate;
              dateMax = pointDate;
            }

            // Update date min
            if (pointDate < dateMin) {
              dateMin = pointDate;
            }

            // Update date max
            if (pointDate > dateMax) {
              dateMax = pointDate;
            }
          }
        });
      });

        // console.log(approachSet)

        // NOTE: Data points list with empty object (e.g., [{}]) will cause render in legend but no data points needed on chart
        // build a list of legends from colorLegend

        Array.from(colorLegend).sort().forEach((element) => {
          colorLegendSeries.push({
            legendText: element,
            showInLegend: true,
            legendMarkerColor: angleColorMap[element] ? angleColorMap[element] : approachColorMap[element],
            legendMarkerType: "square",
            dataPoints: [{}]
          })
        })


        // Iterate through data points and set the line colors to the color of the next point (for variable color shading support)
        // Array.from(dataPoints).forEach((currentPoint, idx) => {
        //   let nextPointExists = (idx + 1) <= dataPoints.length - 1;
        //   let nextPoint = nextPointExists ? dataPoints[idx + 1] : null;
        //   currentPoint.lineColor = nextPoint !== null ? nextPoint.color : currentPoint.color;
        // });

        // Data points of interest exist when at least one data point exists in the list
        // setDataOfInterestExists(dataPoints.length > 0);

        // console.log(approachDataPoints)
        // Get the minimum and maximum possible y values to set appropriate scale for y-axis (and doesn't change when zooming)
        const { min, max } = dataContext.getMinMaxValues(data, lookup.final_agl);
        let padding = (dataContext.getMagnitude(max) / 10) * 5;
        // Adjust padding if it is 0, which indicates all data points have the same y-axis value (e.g., min and max are equal)
        padding = padding === 0 ? 0.1 : padding;
        Object.keys(approachDataPoints).map(key => {
          Object.keys(approachDataPoints[key]).map(axis => {
          Object.keys(approachDataPoints[key][axis]).map(item => {
            if (approachDataPoints[key][axis][item]['dataSeries']) {
              if (item === 'trueAlt') {
                // Nominal vs Actual, but cull the Nominal values out because it wasn't a straight enough line in the data
                approachDataPoints[key][axis][item].options = {
                  ...options,
                  // height:200,
                  axisX: {
                    ...options.axisX,
                    reversed: axis !== 'time' ? options.axisX.reversed : false,
                    title: approachDataPoints[key][axis][item]["xaxis"]

                    // minimum: dataPoints ? dateMin : undefined,
                    // maximum: dataPoints ? dateMax : undefined,
                  },
                  axisY: {
                    ...options.axisY,
                    title: approachDataPoints[key][axis][item]["yaxis"]

                    // minimum: min - padding,
                    // maximum: max + padding,
                  },
                  data: [

                    {
                      legendText: approachDataPoints[key][axis][item]['actualLegend'].legendText,
                      showInLegend: approachDataPoints[key][axis][item]['actualLegend'].showInLegend,
                      color: approachDataPoints[key][axis][item]['actualLegend'].legendMarkerColor,

                      type: "line",
                      lineThickness: LINE_THICKNESS,
                      dataPoints: approachDataPoints[key][axis][item]['dataSeries'].actual
                    },
                    {
                      legendText: approachDataPoints[key][axis][item]['nominalLegend'].legendText,
                      showInLegend: approachDataPoints[key][axis][item]['nominalLegend'].showInLegend,
                      color: approachDataPoints[key][axis][item]['nominalLegend'].legendMarkerColor,
                      markerType: 'none',
                      type: "line",
                      lineDashType: "dash",
                      // can't dash legend lines, so we work around
                      legendMarkerType: "triangle",
                      legendMarkerColor: "white",
                      lineThickness: LINE_THICKNESS,
                      dataPoints: [approachDataPoints[key][axis][item]['dataSeries'].nominal[0],
                      approachDataPoints[key][axis][item]['dataSeries'].nominal[approachDataPoints[key][axis][item]['dataSeries'].nominal.length - 1]]
                    }
                  ]
                }
              }
              else if (item === subCharts['GRNDTRACK']) {

                // Nominal vs actual branch, but also don't use the 2 middle of the road thresholds.  Fancy logic for excluding that would be nice but expediency is requried right now
                let tolArray = []
                // console.log(approachDataPoints[key][axis][item])
                // Loop tolerance array, add all but the excluded items.
                Object.keys(approachDataPoints[key][axis][item]['tolerances']).map((it1) => {
                  if(!(it1 === TOLERANCELIST['NORMUPPER']['lookupKey'] + item || it1 === TOLERANCELIST['NORMLOWER']['lookupKey'] + item || it1 === TOLERANCELIST['IFRUPPER']['lookupKey'] + item)){
                    tolArray.push(approachDataPoints[key][axis][item]['tolerances'][it1])
                  }
                })

                // tolArray.push(approachDataPoints[key][axis][item]['tolerances'][TOLERANCELIST['HIGHTOL']['lookupKey'] + item])
                // tolArray.push(approachDataPoints[key][axis][item]['tolerances'][TOLERANCELIST['LOWTOL']['lookupKey'] + item])
                

                approachDataPoints[key][axis][item].options = {
                  ...options,
                  // height:200,
                  axisX: {
                    ...options.axisX,
                    reversed: axis !== 'time' ? options.axisX.reversed : false,
                    title: approachDataPoints[key][axis][item]["xaxis"]

                    // minimum: dataPoints ? dateMin : undefined,
                    // maximum: dataPoints ? dateMax : undefined,
                  },
                  axisY: {
                    ...options.axisY,
                    title: approachDataPoints[key][axis][item]["yaxis"]

                    // minimum: min - padding,
                    // maximum: max + padding,
                  },
                  data: [

                    {
                      legendText: approachDataPoints[key][axis][item]['actualLegend'].legendText,
                      showInLegend: approachDataPoints[key][axis][item]['actualLegend'].showInLegend,
                      color: approachDataPoints[key][axis][item]['actualLegend'].legendMarkerColor,

                      type: "line",
                      lineThickness: LINE_THICKNESS,
                      dataPoints: approachDataPoints[key][axis][item]['dataSeries'].actual
                    },
                    {
                      legendText: approachDataPoints[key][axis][item]['nominalLegend'].legendText,
                      showInLegend: "true",
                      color: "blue",
                      markerType: 'none',
                      type: "line",
                      lineDashType: "dash",
                      // can't dash legend lines, so we work around
                      legendMarkerType: "triangle",
                      legendMarkerColor: "white",
                      lineThickness: LINE_THICKNESS,
                      dataPoints: approachDataPoints[key][axis][item]['dataSeries'].nominal
                    },
                    ...tolArray
                  ]
                }
              }
              else if (item === subCharts['AIRSPEED']) {
                // console.log('airspeed')
                let tolArray = []
                  // Only add gate tolerances for non IFR

                  Object.keys(approachDataPoints[key][axis][item]['tolerances']).map((it1) => {
                    if(!(it1 === TOLERANCELIST['NORMUPPER']['lookupKey'] + item || it1 === TOLERANCELIST['NORMLOWER']['lookupKey'] + item || it1 === TOLERANCELIST['IFRUPPER']['lookupKey'] + item)){
                      tolArray.push(approachDataPoints[key][axis][item]['tolerances'][it1])
                    }
                  })
                  // tolArray.push(approachDataPoints[key][axis][item]['tolerances'][TOLERANCELIST['HIGHTOL']['lookupKey'] + item])
                  // tolArray.push(approachDataPoints[key][axis][item]['tolerances'][TOLERANCELIST['LOWTOL']['lookupKey'] + item])  
                
                // console.log(approachDataPoints[key][axis][item], key, item)
               

                
                //   console.log(approachDataPoints[key][axis][item])
                //   console.log(approachDataPoints[key][axis][item]['options'])
                //   console.log(approachDataPoints[key][axis][item]['tolerances'])
                // create Tolerance object


                approachDataPoints[key][axis][item].options = {
                  ...options,
                  // height:200,
                  axisX: {
                    ...options.axisX,
                    reversed: true,
                    title: approachDataPoints[key][axis][item]["xaxis"]

                    // minimum: dataPoints ? dateMin : undefined,
                    // maximum: dataPoints ? dateMax : undefined,
                  },
                  axisY: {
                    ...options.axisY,
                    title: approachDataPoints[key][axis][item]["yaxis"]

                    // minimum: min - padding,
                    // maximum: max + padding,
                  },
                  data: [

                    {
                      legendText: "Airspeed",
                      showInLegend: "true",
                      color: "black",

                      type: "line",
                      lineThickness: LINE_THICKNESS,
                      dataPoints: approachDataPoints[key][axis][item]['dataSeries'].actual
                    },
                    {
                      legendText: key.indexOf("IFR") > -1 ? "Vref (kts)" : "Air Speed Gates",
                      showInLegend: 'true',
                      color: 'blue',
                      markerType: 'none',

                      type: "line",
                      lineThickness: LINE_THICKNESS+4,
                      dataPoints: approachDataPoints[key][axis][item]['dataSeries'].nominal
                    },
                    ...tolArray

                  ]
                }
              }
              else {
                //   console.log(approachDataPoints[key][axis][item])
                //   console.log(approachDataPoints[key][axis][item]['options'])
                //   console.log(approachDataPoints[key][axis][item]['tolerances'])
                // create Tolerance object
                let tolArray = []
                let vertLatNomFlag = false;
                Object.keys(approachDataPoints[key][axis][item]['tolerances']).map((subset) => {
                  // console.log(approachDataPoints[key][axis][item]['tolerances'])
                  // console.log(approachDataPoints[key][axis][item]['tolerances'][subset])
                  let tempObj = approachDataPoints[key][axis][item]['tolerances'][subset]
                  if ((item === subCharts['LATDEV'] || item === subCharts['VERTDEV'] || item === subCharts['LATDEVDOTS'] )  && !vertLatNomFlag) {
                    tempObj = {...approachDataPoints[key][axis][item]['tolerances'][subset], ...approachDataPoints[key][axis][item].nominalLegend}
                    vertLatNomFlag = true
                    }
                  tolArray.push(tempObj)
                })
                // console.log(item, tolArray)
                
                if (item === subCharts['LATDEV']) {
                // console.log(tolArray)
                // console.log(approachDataPoints[key][axis][item].nominalLegend)
                }
                approachDataPoints[key][axis][item].options = {
                  ...options,
                  // height:200,
                  axisX: {
                    ...options.axisX,
                    reversed: axis !== 'time' ? options.axisX.reversed : false,
                    title: approachDataPoints[key][axis][item]["xaxis"]

                    // minimum: dataPoints ? dateMin : undefined,
                    // maximum: dataPoints ? dateMax : undefined,
                  },
                  axisY: {
                    ...options.axisY,
                    title: approachDataPoints[key][axis][item]["yaxis"]

                    // minimum: min - padding,
                    // maximum: max + padding,
                  },
                  data: [

                    {
                      // legendText: approachDataPoints[key][axis][item].legend.legendText ? approachDataPoints[key][axis][item].legend.legendText : undefined,
                      // showInLegend: approachDataPoints[key][axis][item].legend.showInLegend ? approachDataPoints[key][axis][item].legend.showInLegend : false,
                      //   color: approachDataPoints[key][axis][item].legend.legendMarkerColor ? approachDataPoints[key][axis][item].legend.legendMarkerColor : undefined,
                      ...approachDataPoints[key][axis][item].legend,
                      // ...approachDataPoints[key][axis][item].nominalLegend,
                      type: "line",
                      lineThickness: LINE_THICKNESS,
                      dataPoints: approachDataPoints[key][axis][item]['dataSeries']
                    },
                    ...tolArray

                  ]
                }

                if (item === subCharts['LNDG']) {
                  approachDataPoints[key][axis][item].options.axisX = {
                    ...approachDataPoints[key][axis][item].options.axisX,

                    reversed: axis !== 'time' ? options.axisX.reversed : false,

                  }
                }

                if (item === subCharts['VERTDEV']) {
                  approachDataPoints[key][axis][item].options.axisX = {
                    ...approachDataPoints[key][axis][item].options.axisX,

                    reversed: axis !== 'time' ? options.axisX.reversed : false,

                  }
                }

                if (item === subCharts['LATDEV']) {
                  approachDataPoints[key][axis][item].options.axisX = {
                    ...approachDataPoints[key][axis][item].options.axisX,

                    reversed: axis !== 'time' ? options.axisX.reversed : false,

                  }
                }
              }
            }
          })
        })
      })
        setApproachSubchart(approachDataPoints)

        // Create formatted data structure for canvasjs chart
        // See data series attributes at https://canvasjs.com/docs/charts/chart-options/data/
        // See performance improvement considerations at https://canvasjs.com/forums/topic/performance-improvement-in-scatter-chart/
        // setOptions({
        //   ...options,
        //   axisX: {
        //     ...options.axisX,
        //     minimum: dataPoints ? dateMin : undefined,
        //     maximum: dataPoints ? dateMax : undefined,
        //   },
        //   axisY: {
        //     ...options.axisY,
        //     minimum: min - padding,
        //     maximum: max + padding,
        //   },
        //   data: [
        //     ...colorLegendSeries,
        //     {
        //       type: "spline",
        //       markerType: "square",
        //       lineThickness: LINE_THICKNESS,
        //       markerSize: DATA_POINT_SIZE,
        //       dataPoints: dataPoints,
        //       click : onClick
        //     },
        //   ]
        // });
      }
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.baseReducedData, dataContext.filteredReducedData, dataContext.approachUnits]);

  useEffect(() => {
    if (isMounted) {
      // Re-render chart if there's a change in theme
      if (chartRef.current) {
        // NOTE: must do it this way since options are copied to chart reference (e.g., not bound by state/props)
        chartRef.current.options.theme = dataContext.darkMode ? "dark1" : "light1";
        chartRef.current.render();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.darkMode]);

  // Dynamically update width and height of chart to fill parent container on dimension change
  useEffect(() => {
    if (isMounted) {
      if (chartRef.current) {
        let yOffset = 20;
        let dh = height - yOffset;
        if (width > 0 && dh > 0) {
          chartRef.current.options.width = width;
          chartRef.current.options.height = dh;
          chartRef.current.render();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height]);

  return (
    <>
      {Object.keys(approachSubchart).map((key, index1) => (
        //Construct popovers for each approach.
        <Modal
          id={props.id + approachSubchart[key].approach_id}
          key={props.id + "modal" + index1 + key + approachSubchart[key].approach_id}
          open={props.open && props.menuOpen === key}
          style={{ display: 'block', top: '5%', left: '10%', alignItems: 'center', justifyContent: 'center' }}
          disableRestoreFocus={true}
          //   anchorEl={props.anchorEl}
          //   PaperProps={{ style:{ height: 420, width: 600,}}}
          //   container={document.fullscreenElement ? document.fullscreenElement : document.body}
          //   anchorOrigin={{
          //     vertical: 'top',
          //     horizontal: 'left',
          //   }}
          onClose={(e) => handleClose()}
        >
          <Box sx={{ ...modalStyle, flexGrow: 8 }}
            key={props.id + "box" + index1 + approachSubchart[key].approach_id}
            alignItems="left">
              <div
              key={props.id + "rdiv" + key + index1 + approachSubchart[key].approach_id}
              style ={{paddingLeft:'10px'}}>
                <RadioGroup
                name='display-toggle'
                value={displayToggle}
                onChange={event => {setDisplayToggle(event.target.value)}}
                id={props.id + "rgroup" + key + index1 + approachSubchart[key].approach_id}
                key={props.id + "rgroup" + key + index1 + approachSubchart[key].approach_id}
                >
                {xAxisTypes && Object.keys(xAxisTypes).map((type, index3) => (
                  
                  <FormControlLabel
                  id={props.id + "flabel" + key + index1 + type + index3}
                  key={props.id + "flabel" + key + index1 + type + index3}
                  value={xAxisTypes[type]}
                  control={<Radio key={props.id + "fradio" + key + index1 + type + index3} id={props.id + "fradio" + key + index1 + type + index3}/>}
                  label={xAxisTypes[type].toUpperCase()}
                />
                ))} 
                </RadioGroup>
                <div key={props.id + "tgdiv" + key + index1 + approachSubchart[key].approach_id}>
                  <br />
                  <br />
                  <p><font color='red'>--</font> Red dashed line: <i>Normal Range</i></p>
                  <p><font color='red'>&mdash;</font> Red solid line: <i>Tolerance Limits</i></p>
                  <p><font color='blue'>&mdash;</font> Blue solid line: <i>Nominal path</i></p>
                  <p><font color='DarkKhaki'>&mdash;</font> Tan solid line: <i>Community mean</i></p>
                  <p><font color='DarkKhaki'>--</font> Tan dashed line: <i>Community deviation</i></p>
                  <p><font color='black'>&mdash;</font> Black solid line: <i>Flight data</i></p>
                </div>
              </div>
              <div key={props.id + "agdiv" + key + index1 + approachSubchart[key].approach_id}>
              {Object.keys(approachSubchart[key]).map((axis) => (
                
            <Grid container spacing={2}
            sx={{display: displayToggle !== axis && 'none' }}
              // visibility={displayToggle === 'HAHAH'}
              key={props.id + "grid" + index1 + key + axis}
              direction="row"
              alignItems="center"
              justifyContent="center" >
                
              {/* <div style={{display:'inlineFlex', color:'blue', width:'600px'}}> */}
              
                
              
                {Object.keys(approachSubchart[key][axis]).map((item, index2) => (
                
                approachSubchart[key][axis][item]['showChart'] && approachSubchart[key][axis][item]['options'] && approachSubchart[key][axis][item]['options']['data'] && approachSubchart[key][axis][item]['options']['data'][0]['dataPoints'].length > 0 &&


                <Grid item key={props.id + "item" + index2 + approachSubchart[key][axis][item]} >
                  <Item key={props.id + "itemitem" + index2 + approachSubchart[key][axis][item]}>
                    <CanvasJSChart key={props.id + "chart" + index2 + approachSubchart[key][axis][item]} containerProps={{ position: 'relative', width: '400px' }} options={approachSubchart[key][axis][item].options} onRef={ref => chartRef.current = ref} />
                    {/* {approachSubchart[key][axis][item]['unstableCriteriaFlag'] ? <div style={{color:"Red"}}>Unstable Criteria</div> : <div><br /></div>} */}
                  </Item>
                </Grid>
              ))}
              
              

            </Grid>
            ))}
            </div>
          </Box>

        </Modal>


      ))}

    </>
  );
}

ApproachSubchart.propTypes = propTypes;
ApproachSubchart.defaultProps = defaultProps;

export default ApproachSubchart;
