import React, { useState, useRef, useEffect, useContext } from 'react';
import { DataContext } from "../../DataContext";
// import PropTypes from 'prop-types';
import CanvasJSReact from '../../../canvasjs-commercial-3.4.12/canvasjs.react';
import { useResizeDetector } from 'react-resize-detector';
import Menu from '@mui/material/Menu';
import '../Chart.css';
import { ConstructionOutlined } from '@mui/icons-material';

// const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const propTypes = {

};

const defaultProps = {

};

// Control/Configure the size of the markers and lines in the chart
const DATA_POINT_SIZE = 8;
const LINE_THICKNESS = 2;

function AglScatterChart() {
  // Obtain reference to global data context consumer
  const dataContext = useContext(DataContext);

  const { width, height, ref: resizeDetectorRef } = useResizeDetector();

  // Keep reference of chart for event listeners/actions
  const chartRef = useRef(null);
  const subChartRefs = useRef([]);
  const isMounted = useRef(false);

  // Approach series lists
  const [approachSubchart, setApproachSubchart] = useState({})

  // Determine if data exists
  const d = dataContext.filteredReducedData ? dataContext.filteredReducedData : dataContext.baseReducedData;
  const trackPointDataExists = d && d.track_points && d.track_points.data && d.track_points.data.length > 0;
  const exceedancePointDataExists = d && d.exceedance_point && d.exceedance_point.data && d.exceedance_point.data.length > 0;
  const dataExists = trackPointDataExists || exceedancePointDataExists;

  const [dataOfInterestExists, setDataOfInterestExists] = useState(true);

  const { approachColorMap } = dataContext.COLOR_MAPS.current;
  const [approachSet, setApproachSet] = useState(new Set())

  // Approach details popover controls
  const [menuOpen, setMenuOpen] = useState(null)
  const open = Boolean(menuOpen)
  


  /**
   * Handler for parsing date object to a formatted string version.
   * 
   * @param {object} e CanvasJS label event object containing the current date value.
   * @returns The formatted date string.
   */
  const dateLabelFormatter = (e) => {
    return dataContext.toHumanReadableDateStr(e.value, true);
  }

  function addApproachSet(e){
    setApproachSet(prevSet => new Set(prevSet.add(e)))
      }

  // const setRef = (ref) => {
  //   console.log(ref)
  //   subChartRefs.push(ref)
  // }    
  /**
   * Build and retrieve formatted HTML string representing a featured
   * tooltip given a set of values for a particular row of interest.
   * 
   * row = {
   *   "key_1": "value_1",
   *   "key_2": "value_2",
   *   ...
   *   "key_n": "value_n"
   * }
   * 
   * @param {object} row Fields of interest for current row in dataset.
   * @param {string} color Hexidecimal or RGB(A) color string.
   * @returns Formatted HTML string representing a featured tooltip.
   */
  const getFeaturedTooltip = (row, color = null) => {
    // Build tool tip off all properties
    var tooltipText = "";

    // Start table
    tooltipText += "<table>";
    for (const [key, value] of Object.entries(row)) {
      let iconData = `<td><i class="fa fa-square tooltip-icon-fonts" style="color: ${color}; margin-right: 5px;"></i></td>`;
      tooltipText += `
        <tr>
          ${color !== null && iconData}
          <td style="color: #989898"><b>${key}&nbsp</b></td>
          <td><b>${value}</b></td>
        </tr>
      `;
    }

    // End table
    tooltipText += "</table>";
    return tooltipText;
  }

  // See CanvasJS configuration options at https://canvasjs.com/docs/charts/basics-of-creating-html5-chart/
  // Value format string: https://canvasjs.com/docs/charts/chart-options/axisx/valueformatstring/
  const [options, setOptions] = useState({
    zoomEnabled: true,
    animationEnabled: false,
    title: {
      text: ""
    },
    theme: dataContext.darkMode ? "dark1" : "light1",
    toolTip: {
      enabled: true,          //disable here
      animationEnabled: true, //disable here
      contentFormatter: e => {
        var content = "";
        for (var i = 0; i < e.entries.length; i++) {
          let dataPoint = e.entries[i].dataPoint;
          content = `${content}${dataPoint.tooltipText}`;
        }

        return content;
      }
    },
    axisX: {
      labelFontFamily: dataContext.CHART_FONTS.fontFamily,
      labelFontWeight: dataContext.CHART_FONTS.fontWeight,
      labelAutoFit: true,
      labelAngle: 0,
      title: "",
      gridThickness: 0.5,
      // See: https://canvasjs.com/docs/charts/basics-of-creating-html5-chart/formatting-date-time/
      labelFormatter: dateLabelFormatter,
      labelFontSize: 13,
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
        labelFormatter: dateLabelFormatter,
      },
      // interval: 2,
      // intervalType: "minute",
    },
    axisY: {
      gridThickness: 0.5,
      labelFontFamily: dataContext.CHART_FONTS.fontFamily,
      labelFontSize: dataContext.CHART_FONTS.fontSize,
      labelFontWeight: dataContext.CHART_FONTS.fontWeight,
      title: ""
    },
    legend: {
      fontFamily: dataContext.CHART_FONTS.fontFamily,
      fontSize: dataContext.CHART_FONTS.fontSize,
      fontWeight: dataContext.CHART_FONTS.fontWeight,
      cursor: "pointer",
      itemclick: function (e) {
        

        if (typeof(e.dataSeries.visible) === "undefined" || e.dataSeries.visible){
          e.dataSeries.visible = false;
        } else {
          e.dataSeries.visible = true;
        }
        console.log("Be invis", e.chart.options.data)
        e.chart.render()
        
      },
    },
    data: [
      { type: "spline", dataPoints: [] }
    ]
  });

  // const [subchartOptions, setSubchartOptions] = useState({
  //   zoomEnabled: true,
  //   animationEnabled: true,
  //   title: {
  //     text: ""
  //   },
  //   theme: dataContext.darkMode ? "dark1" : "light1",
  //   toolTip: {
  //     enabled: true,          //disable here
  //     animationEnabled: true, //disable here
  //     contentFormatter: e => {
  //       var content = "";
  //       for (var i = 0; i < e.entries.length; i++) {
  //         let dataPoint = e.entries[i].dataPoint;
  //         content = `${content}${dataPoint.tooltipText}`;
  //       }

  //       return content;
  //     }
  //   },
  //   axisX: {
  //     labelFontFamily: dataContext.CHART_FONTS.fontFamily,
  //     labelFontWeight: dataContext.CHART_FONTS.fontWeight,
  //     labelAutoFit: true,
  //     labelAngle: 0,
  //     title: "",
  //     gridThickness: 0.5,
  //     // See: https://canvasjs.com/docs/charts/basics-of-creating-html5-chart/formatting-date-time/
  //     labelFormatter: dateLabelFormatter,
  //     labelFontSize: 13,
  //     crosshair: {
  //       enabled: true,
  //       snapToDataPoint: true,
  //       labelFormatter: dateLabelFormatter,
  //     },
  //     // interval: 2,
  //     // intervalType: "minute",
  //   },
  //   axisY: {
  //     gridThickness: 0.5,
  //     labelFontFamily: dataContext.CHART_FONTS.fontFamily,
  //     labelFontSize: dataContext.CHART_FONTS.fontSize,
  //     labelFontWeight: dataContext.CHART_FONTS.fontWeight,
  //     title: ""
  //   },
  //   legend: {
  //     fontFamily: dataContext.CHART_FONTS.fontFamily,
  //     fontSize: dataContext.CHART_FONTS.fontSize,
  //     fontWeight: dataContext.CHART_FONTS.fontWeight,
  //   },
  //   data: [
  //     { type: "spline", dataPoints: [] }
  //   ]
  // });

  function onClick(e) {
    // console.log(e)
    if(e.dataPoint.approach_id){
    // console.log(e.dataPoint.approach_id)
    // console.log(approachSet)
    setMenuOpen(e.dataPoint.approach_id)
    // to be added when working on nameplates again
    // addIndexLabels(subChartRefs.current[0]);
    }
    // console.log("Back up alert")
    // console.log(e)
    // alert(e.dataPoint.approach_id + " was clicked")
  }

  const handleClose = () => {
    setMenuOpen(null);
  };

  // Component mount/unmount
  useEffect(() => {
    isMounted.current = true;

    // NOTE: identify document element by id on component mount to ensure canvas js chart wraps to parent container
    if (resizeDetectorRef.current) {
      let chartContainer = resizeDetectorRef.current.children[0]; // e.g., canvasjs-react-chart-container-2
      if (chartContainer) {
        chartContainer.style.height = "100%";
        chartContainer.style.width = "100%";
      }
    }

    return () => {
      isMounted.current = false;

      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }

      setOptions({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Detect changes to incomming/ingested data
  useEffect(() => {
    if (isMounted.current) {
      // Obtain reference to active data from context
      let dataTarget = dataContext.filteredReducedData ? dataContext.filteredReducedData : dataContext.baseReducedData;
      
      if (dataTarget) {
        // Extract lookup table and data from track points or exceedance points if track points aren't available
        let trackDataExists = dataTarget.track_points && dataTarget.track_points.data && dataTarget.track_points.data.length > 0;
        let trackPointsTarget = trackDataExists ? dataTarget.track_points : dataTarget.exceedance_point;

        const { data, lookup } = trackPointsTarget; // Extract lookup table from track points data

        let waypointsExist = dataTarget.ifr_waypoints && dataTarget.ifr_waypoints.data && dataTarget.ifr_waypoints.data.length > 0;
        let waypointsData = waypointsExist ? dataTarget.ifr_waypoints : {"data" : [], "lookup" : []}

        

        // Extract only required data key/values for rendering data points and tooltips from track points data
        let dataPoints = [];
        let approachDataPoints = {};
        // console.log(data)
        // console.log(lookup)
        // find min/max date for scale of x axis
        let dateMin = undefined;
        let dateMax = undefined;
        let vfrFlag = false
        let vfrMissedFlag = false
        let ifrFlag = false
        let ifrMissedFlag = false
        let stripLineBuilder = {};
        
        // console.log(trackPointsTarget)

        Array.from(data).forEach((point) => {
          let timestampStr = point[lookup.times_timestamp];
          let agl = point[lookup.final_agl];

          if (timestampStr !== null && agl !== null) {

            // Change color of points here based off approach type
            let color = "#9FDEF1";
            // Add colors for apporaches
            
            if(point[lookup.approach_type] === "VFR-Missed"){
              color = approachColorMap["VFR-Missed"];
              vfrMissedFlag = true
            }
            else if(point[lookup.approach_type] === "IFR-Missed"){
              color = approachColorMap["IFR-Missed"];
              ifrMissedFlag = true
            }
            else if(point[lookup.approach_type] === "VFR"){
              color = approachColorMap["VFR"];
              vfrFlag = true
            }
            else if(point[lookup.approach_type] === "IFR"){
              color = approachColorMap["IFR"];
              ifrFlag = true
            }
          
            let pointDate = new Date(timestampStr)
            let fieldsOfInterest = {
              "Time": point[lookup.human_readable_datetime],
              "Phase of Flight": point[lookup.phaseofflight_mavg10_str],
              "AGL (ft)": point[lookup.final_agl_str],
              "Ground Speed (kts)": point[lookup.groundspeed_final_kt_str],
              "Vertical Speed (f/m)": point[lookup.verticalspeed_final_fpm_str],
              "Roll (deg)": point[lookup.flightstate_position_roll_str],
              "Pitch (deg)": point[lookup.flightstate_position_pitch_str],
              "Yaw Rate (deg/s)": point[lookup.flightstate_rates_yawrate_str],
            };

            /** Target Structures:
             * approachSet: [approach1, approach2]
             * approachDataPoints: [approach1: {
             *                        chartMeta: {
             *                          minX,
             *                          minY,
             *                          zoomEnabled,
             *                          etc},
             *                        dataSeries: {
             *                            actual:[{points1, points2}],
             *                            nominal:[{points1, points2}]
             *                          }
             *                        },
             *                      approach2...] 
             */
          

            if (point[lookup.approach_id] && point[lookup.approach_id] != "") {
              // add to master list of approach ids
              // console.log(point[lookup.approach_id])
              // add approach to point list
              addApproachSet(point[lookup.approach_id])

              // Adjust striplines
              if(!stripLineBuilder[point[lookup.approach_id]]){
                              
                stripLineBuilder[point[lookup.approach_id]] = {'startPoint': pointDate}
                
              }
              // if(!stripLineBuilder[point[lookup.approach_id]]['startPoint']){
              //   stripLineBuilder[point[lookup.approach_id]].push({'startPoint': pointDate})
              // }
              if(stripLineBuilder[point[lookup.approach_id]]){
                stripLineBuilder[point[lookup.approach_id]]['endPoint'] = pointDate
              }

              // Create Actual vs Nominal lists
              if(!approachDataPoints[point[lookup.approach_id]]){
                approachDataPoints[point[lookup.approach_id]] = {'actual' : [],
                                                                'nominal': []}
              }
              // Add approach ID field to Approach set
              if(!approachDataPoints[point[lookup.approach_id]].approach_id){
                approachDataPoints[point[lookup.approach_id]].approach_id = point[lookup.approach_id]
              }
              if(!approachDataPoints[point[lookup.approach_id]].approach_type){
                approachDataPoints[point[lookup.approach_id]].approach_type = point[lookup.approach_type]
              }
              if(!approachDataPoints[point[lookup.approach_id]].approach_procedure){
                approachDataPoints[point[lookup.approach_id]].approach_procedure = point[lookup.approach_procedure]
              }
              // Add legend options
              if(!approachDataPoints[point[lookup.approach_id]].actual.legend){
                approachDataPoints[point[lookup.approach_id]].actual.legend = {
                  legendText: point[lookup.approach_type] === "VFR-Missed" ? "Go-Around" : point[lookup.approach_type],
                  showInLegend: true,
                  legendMarkerColor: approachColorMap[point[lookup.approach_type]],
                  legendMarkerType: "square",
                }
              }
              if(!approachDataPoints[point[lookup.approach_id]].nominal.legend){
                approachDataPoints[point[lookup.approach_id]].nominal.legend = {
                  legendText: `${point[lookup.approach_type]}`.startsWith("IFR") ? "Fixes" : "Nominal Approach Path",
                  showInLegend: true,
                  legendMarkerColor: 'blue',
                  // legendMarkerType: "circle",
                }
              }
              // if (point[lookup.approach_id] && approachDataPoints.indexOf(point[lookup.approach_id]) === -1) {

              //   approachDataPoints.push(point[lookup.approach_id])
              // }
              // // add to series lists for nominal and actual 
              // if (point[lookup.approach_id] && approachDataPoints.indexOf(point[lookup.approach_id]) > -1) {
              //   console.log(approachDataPoints)
              //   if (approachDataPoints[point[lookup.approach_id]].indexOf("actual") === -1) {
              //     approachDataPoints[point[lookup.approach_id]].push("actual")
              //   }
              // }
              
              if (`${point[lookup.approach_type]}`.startsWith("IFR")) {
                if (approachDataPoints[point[lookup.approach_id]]['actual']) {
                  approachDataPoints[point[lookup.approach_id]]['actual'].push({
                    flightid: point[lookup.flightid],
                    approach_id: point[lookup.approach_id],
                    x: point[lookup.flightstate_location_longitude],
                    y: point[lookup.flightstate_location_latitude],
                    color: approachColorMap[point[lookup.approach_type]],
                    lineColor: approachColorMap[point[lookup.approach_type]],
                    tooltipText: getFeaturedTooltip({"Time": point[lookup.human_readable_datetime],
                    "Phase of Flight": point[lookup.phaseofflight_mavg10_str],
                    "Longitude": point[lookup.flightstate_location_longitude],
                    "Latitude": point[lookup.flightstate_location_latitude],
                    "Ground Speed (kts)": point[lookup.groundspeed_final_kt_str],
                    "Vertical Speed (f/m)": point[lookup.verticalspeed_final_fpm_str],
                    "Roll (deg)": point[lookup.flightstate_position_roll_str],
                    "Pitch (deg)": point[lookup.flightstate_position_pitch_str],
                    }, approachColorMap[point[lookup.approach_type]]),
                  })
                }

                
                
            
          
                // approachDataPoints[point[lookup.approach_id]]['nominal'].push({
                //   flightid: point[lookup.flightid],
                //   approach_id: point[lookup.approach_id],
                //   x: pointDate,
                //   y: point[lookup.approach_nominal_agl],
                //   color: "blue",
                //   lineColor: "blue",
                //   tooltipText: getFeaturedTooltip({"Time": point[lookup.human_readable_datetime],
                //                 "Nominal Approach AGL (ft)": point[lookup.approach_nominal_agl],}, 'blue'),
              }
              else {
                if (approachDataPoints[point[lookup.approach_id]]['actual']) {
                  approachDataPoints[point[lookup.approach_id]]['actual'].push({
                    flightid: point[lookup.flightid],
                    approach_id: point[lookup.approach_id],
                    x: pointDate,
                    y: point[lookup.approach_agl],
                    // color: approachColorMap[point[lookup.approach_type]],
                    // lineColor: approachColorMap[point[lookup.approach_type]],
                    tooltipText: getFeaturedTooltip({"Time": point[lookup.human_readable_datetime],
                    "Phase of Flight": point[lookup.phaseofflight_mavg10_str],
                    "Approach AGL (ft)": point[lookup.approach_agl],
                    "AGL (ft)": point[lookup.final_agl],
                    "Ground Speed (kts)": point[lookup.groundspeed_final_kt_str],
                    "Airspeed (kts)": point[lookup.flightstate_speeds_indicatedairspeed],
                    "Vertical Speed (f/m)": point[lookup.verticalspeed_final_fpm_str],
                    "Roll (deg)": point[lookup.flightstate_position_roll_str],
                    "Pitch (deg)": point[lookup.flightstate_position_pitch_str],
                  }, approachColorMap[point[lookup.approach_type]]),
                  })
                }
                if (approachDataPoints[point[lookup.approach_id]]['nominal']) {
                approachDataPoints[point[lookup.approach_id]]['nominal'].push({
                  flightid: point[lookup.flightid],
                  approach_id: point[lookup.approach_id],
                  x: pointDate,
                  y: point[lookup.approach_nominal_agl] ? point[lookup.approach_nominal_agl] : point[lookup.approach_agl],
                  color: "blue",
                  lineColor: "blue",
                  tooltipText: getFeaturedTooltip({"Time": point[lookup.human_readable_datetime],
                                "Nominal Approach AGL (ft)": point[lookup.approach_nominal_agl],}, 'blue'),
                })
              }
            }
              // console.log(lookup)
              // approachDataPoints[point[lookup.approach_id]]
            }
            
  
            let tooltipText = getFeaturedTooltip(fieldsOfInterest, color);
            
            dataPoints.push({
              flightid: point[lookup.flightid],
              approach_id : point[lookup.approach_id],
              x: pointDate,
              y: dataContext.round(agl),
              color: color,
              cursor: point[lookup.approach_id] ? 'pointer' : undefined,
              tooltipText: tooltipText,
            });

            if (dateMin === undefined && dateMax === undefined) {
              dateMin = pointDate;
              dateMax = pointDate;
            }

             // Update date min
             if (pointDate < dateMin) {
              dateMin = pointDate;
            }

            // Update date max
            if (pointDate > dateMax) {
              dateMax = pointDate;
            }
          }
        });

        // console.log(approachDataPoints)
        // console.log(waypointsData)
        Object.keys(approachDataPoints).map((element) =>{
          // console.log(approachDataPoints[element]['approach_procedure'])
          Array.from(waypointsData['data']).forEach((wp) => {
            
            // console.log(`WP: ${wp[waypointsData['lookup']['approach_procedure']]}  VS  Elem: ${approachDataPoints[element]['approach_procedure']}`)
            if(approachDataPoints[element]['approach_procedure'] !== null && wp[waypointsData['lookup']['approach_procedure']] === approachDataPoints[element]['approach_procedure']) {
            //   console.log(wp)
            // console.log(wp[waypointsData['lookup']['waypointlongitude']])
            // console.log(wp[waypointsData['lookup']['waypointlatitude']])
            // console.log(wp[waypointsData['lookup']['approach_procedure']])
            approachDataPoints[element]['nominal'].push({
            // flightid: point[lookup.flightid],
            approach_id: element,
            x: wp[waypointsData['lookup']['waypointlongitude']],
            y: wp[waypointsData['lookup']['waypointlatitude']],
            color: "blue",
            lineColor: "blue",
            tooltipText: getFeaturedTooltip({
                                            // "Time": wp[waypointsData['lookup']['human_readable_datetime']],
                                            "Facility": wp[waypointsData['lookup']['icao_identifier']],
                                            "Fix Identifier": wp[waypointsData['lookup']['fixident']],
                                            "Procedure": wp[waypointsData['lookup']['ap_id_name']],
                                            // "NAVAID": wp[waypointsData['lookup']['approach_navaidClass']],
                                            "Latitude": wp[waypointsData['lookup']['waypointlatitude']],
                                            "Longitude": wp[waypointsData['lookup']['waypointlongitude']],}, 'blue'),
          })
        }
        })
        })
        // console.log(approachSet)

         // NOTE: Data points list with empty object (e.g., [{}]) will cause render in legend but no data points needed on chart
         let vfrMissedPlaceholder = {
          legendText: "Go-Around",
          showInLegend: vfrMissedFlag,
          legendMarkerColor: approachColorMap["VFR-Missed"],
          legendMarkerType: "square",
          dataPoints: [{}]
        }

        let vfrPlaceholder = {
          legendText: "Visual",
          showInLegend: vfrFlag,
          legendMarkerColor: approachColorMap["VFR"],
          legendMarkerType: "square",
          dataPoints: [{}]
        }

        let ifrMissedPlaceholder = {
          legendText: "IFR-Missed",
          showInLegend: ifrMissedFlag,
          legendMarkerColor: approachColorMap["IFR-Missed"],
          legendMarkerType: "square",
          dataPoints: [{}]
        }

        let ifrPlaceholder = {
          legendText: "Instrument",
          showInLegend: ifrFlag,
          legendMarkerColor: approachColorMap["IFR"],
          legendMarkerType: "square",
          dataPoints: [{}]
        }

        // Iterate through data points and set the line colors to the color of the next point (for variable color shading support)
        Array.from(dataPoints).forEach((currentPoint, idx) => {
          let nextPointExists = (idx + 1) <= dataPoints.length - 1;
          let nextPoint = nextPointExists ? dataPoints[idx + 1] : null;
          currentPoint.lineColor = nextPoint !== null ? nextPoint.color : currentPoint.color;
        });

        // Data points of interest exist when at least one data point exists in the list
        setDataOfInterestExists(dataPoints.length > 0);

        

        
        // Get the minimum and maximum possible y values to set appropriate scale for y-axis (and doesn't change when zooming)
        const { min, max } = dataContext.getMinMaxValues(data, lookup.final_agl);
        let padding = (dataContext.getMagnitude(max) / 10) * 5;
        // Adjust padding if it is 0, which indicates all data points have the same y-axis value (e.g., min and max are equal)
        padding = padding === 0 ? 0.1 : padding;
        Object.keys(approachDataPoints).map(key => {
          let xAxisLabel = "Time";
          let yAxisLabel = "Altitude (AGL)";
          let markerType = "none"
          let legendMarkerColor = "white";
          let legendMarkerType = "triangle";
          let legendType = "line";
          let legendLineType = "dash";
          if(approachDataPoints[key]['approach_type'].startsWith("IFR")){
            xAxisLabel = "Longitude";
            yAxisLabel = "Latitude";
            markerType = "square"
            legendMarkerType = "square";
            legendMarkerColor = "blue";
            legendType = 'scatter'
            legendLineType = 'line'
          }
          approachDataPoints[key].options = {...options,
            // height:200,
          axisX: {
            ...options.axisX,
            title: xAxisLabel
            
            // minimum: dataPoints ? dateMin : undefined,
            // maximum: dataPoints ? dateMax : undefined,
          },
          axisY: {
            ...options.axisY,
            title:  yAxisLabel
            
            // minimum: min - padding,
            // maximum: max + padding,
          },
          data: [
              
            
            {
              legendText: approachDataPoints[key].nominal.legend.legendText,
              showInLegend: approachDataPoints[key].nominal.legend.showInLegend,
              color: approachDataPoints[key].nominal.legend.legendMarkerColor,
              markerType: markerType,
              type: legendType,
              lineDashType: legendLineType,
              // can't dash legend lines, so we work around
              legendMarkerType: legendMarkerType,
              legendMarkerColor: legendMarkerColor,
              lineThickness: LINE_THICKNESS,
              dataPoints: approachDataPoints[key].nominal.length > 0 ?
                          approachDataPoints[key].approach_type === "VFR" || approachDataPoints[key].approach_type === "VFR-Missed" ?
                          [approachDataPoints[key].nominal[0],
                           approachDataPoints[key].nominal[approachDataPoints[key].nominal.length - 1]] :
                            approachDataPoints[key].nominal
                           :
                           null
            },
            {
              legendText: approachDataPoints[key].actual.legend.legendText,
              showInLegend: approachDataPoints[key].actual.legend.showInLegend,
              color: approachDataPoints[key].actual.legend.legendMarkerColor,
              
              type: "line",
              lineThickness: LINE_THICKNESS,
              dataPoints: approachDataPoints[key].actual
            },
          ]}
        })
        // console.log(approachDataPoints)
       
          setApproachSubchart(approachDataPoints)
        

        // create strip line block
        // console.log(stripLineBuilder)
        var stripLineBlock = []
        Object.keys(stripLineBuilder).map(key => {
          stripLineBlock.push({
            startValue:stripLineBuilder[key] ? stripLineBuilder[key]['startPoint'] : '',
              endValue:stripLineBuilder[key] ? stripLineBuilder[key]['endPoint'] : '',
              color:"#A6DCF2"
          })
        })
      
        
        // Create formatted data structure for canvasjs chart
        // See data series attributes at https://canvasjs.com/docs/charts/chart-options/data/
        // See performance improvement considerations at https://canvasjs.com/forums/topic/performance-improvement-in-scatter-chart/
        setOptions({
          ...options,
          axisX: {
            ...options.axisX,
            minimum: dataPoints ? dateMin : undefined,
            maximum: dataPoints ? dateMax : undefined,
            stripLines:stripLineBlock
          },
          axisY: {
            ...options.axisY,
            minimum: min - padding,
            maximum: max + padding,
          },
          legend :{
            ...options.legend,
              reversed:true,
          },
          data: [
              vfrPlaceholder,
              vfrMissedPlaceholder,
              ifrPlaceholder,
              ifrMissedPlaceholder,
            {
              type: "spline",
              markerType: "square",
              lineThickness: LINE_THICKNESS,
              markerSize: DATA_POINT_SIZE,
              dataPoints: dataPoints,
              click : onClick
            },
          ]
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.baseReducedData, dataContext.filteredReducedData]);

  // to be added when working on name plates again
  // var indexLabels = [];
  // addIndexLabels(subChartRefs.current[0]);
  
  // function addIndexLabels(chart){
  //   if(chart){
  //   for(var i = 0; i < chart.data[0].dataPoints.length; i++){
  //       var _span = document.createElement("span");
  //       var _link = document.createElement("a");
  //       var _linkText = document.createTextNode("Test Label")
  //       _link.appendChild(_linkText);
  //       _link.href = "https://airnav.com/airport/KEVW"
  //       _link.title = "KEVW"
  //       _span.appendChild(_link)
  //       indexLabels.push(_span);
  //       indexLabels[i].setAttribute("id", "indexlabel");
  //       // indexLabels[i].innerHTML = 'Test Label';
  //       console.log(indexLabels[i])
  //       // console.log(subChartRefs.current, document.getElementById("canvasjs-react-chart-container-2"))
  //       document.getElementById(subChartRefs.current[0].container.id).appendChild(indexLabels[i]);
  //   }
  //   positionIndexLabels(chart);
  // }
  // }
  
  // function positionIndexLabels(chart){
  //   if(chart){
  //     for(var i = 0; i < indexLabels.length; i++){
  //         indexLabels[i].style.left = chart.axisX[0].convertValueToPixel(chart.data[0].dataPoints[i].x) - (100) + "px";
  //         let offset = 100
  //         chart.axisX[0].convertValueToPixel(chart.data[0].dataPoints[i].x) < 100 ? offset = -15 : offset = 100
  //         console.log(chart.axisX[0].convertValueToPixel(chart.data[0].dataPoints[i].x))
  //         indexLabels[i].style.top = chart.axisY[0].convertValueToPixel(chart.data[0].dataPoints[i].y) - (indexLabels[i].clientHeight / 2)  + "px";
  //         indexLabels[i].style.left =  chart.axisX[0].convertValueToPixel(chart.data[0].dataPoints[i].x) - (offset) + "px";
  //         // indexLabels[i].styles.backgroundColor = "#555"
  //     }
  //     chart.render()
  //   }
  // }
  
  // window.onresize = function(event) {
  //     positionIndexLabels(subChartRefs.current[0])
  // }
  
  useEffect(() => {
    if (isMounted) {
      // Re-render chart if there's a change in theme
      if (chartRef.current) {
        // NOTE: must do it this way since options are copied to chart reference (e.g., not bound by state/props)
        chartRef.current.options.theme = dataContext.darkMode ? "dark1" : "light1";
        chartRef.current.render();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.darkMode]);

  // Dynamically update width and height of chart to fill parent container on dimension change
  useEffect(() => {
    if (isMounted) {
      if (chartRef.current) {
        let yOffset = 20;
        let dh = height - yOffset;
        if (width > 0 && dh > 0) {
          chartRef.current.options.width = width;
          chartRef.current.options.height = dh;
          chartRef.current.render();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height]);

  return (
    <div ref={resizeDetectorRef} style={{ width: "100%", height: "100%" }}>
        {Object.keys(approachSubchart).map((key, index) => (
          //Construct popovers for each approach.
          <Menu
              id={approachSubchart[key].approach_id}
              key={"menu"+approachSubchart[key].approach_id}
              open={open && menuOpen === approachSubchart[key].approach_id}
              anchorEl={resizeDetectorRef.current}
              PaperProps={{ style:{ height: 420, width: 400,}}}
              container={document.fullscreenElement ? document.fullscreenElement : document.body}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={(e) => handleClose()}
            >
              <CanvasJSChart id="aglChart" options={approachSubchart[key].options} onRef={ref => subChartRefs.current[index] = ref} />
            
      </Menu>
      
      
    ))}
    
    
      {(dataExists && dataOfInterestExists) && (
        <CanvasJSChart options={options} onRef={ref => chartRef.current = ref} />
      )}
      {(!dataExists) && (
        <div>
          <b>No Data</b>
        </div>
      )}
      {(dataExists && !dataOfInterestExists) && (
        <div>
          {/* Customize message as needed */}
          <b>No Data</b>
        </div>
      )}
    </div>
  );
}

AglScatterChart.propTypes = propTypes;
AglScatterChart.defaultProps = defaultProps;

export default AglScatterChart;
