import React  from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


class SignInError extends React.Component {
    // const [show, setShow] = useState(false);
  
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
  render() {
    const {show, handleClose, errorMessage} = this.props;
      
    return (
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>There was a problem signing in.</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {errorMessage}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
    );
  }
}
  
export default SignInError;