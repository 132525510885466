import React, { useState, useContext, useEffect } from 'react'
import {
    FormGroup,
    Label,
    TextInput,
    Checkbox,
    Dropdown,
    Textarea,
    ButtonGroup,
    Button,
    Form
} from '@trussworks/react-uswds'
import { Link, useHistory } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import './UserRegistration.css'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { Container, Row, Col } from 'react-bootstrap'
import getUserProfile from '../services/getUserProfile'
import DashboardNav from '../components/DashboardNav'
import UserContext from '../components/UserContext'
import updateUserProfile from '../services/updateUserProfile'


const UserProfile = () => {

    const [email, setEmail] = useState("");
    //    const [userIdentifier, setUserIdentifier] = useState();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [existPassword, setExistPassword] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumber2, setPhoneNumber2] = useState("");
    const [isMobile, setIsMobile] = useState(false);
    const [emailNotifications, setEmailNotifications] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const animatedComponents = makeAnimated();
    const userContext = useContext(UserContext);
    const history = useHistory();

    useEffect(() => {
      // console.log(userContext)
        getUserProfile(userContext.user)
            .then((results) => {
              // console.log({ results })
                setFirstName(results.jsonAttributes.firstName)
                setLastName(results.jsonAttributes.lastName)
                setEmail(results.jsonAttributes.email)
                setPhoneNumber(results.jsonAttributes.phone_number)
                // setUserIdentifier(results.jsonAttributes["sub"])
                setPhoneNumber2(results.jsonAttributes["custom:secondaryPhone"] || "")
                setEmailNotifications(results.jsonAttributes['custom:notificationPref'] === "true" ? true : false)
                setIsMobile(results.jsonAttributes['custom:phoneIsMobile']  === "true" ? true : false)
            });
    }, [])



    // const onChange = (key, value) => {
    //     this.setState({
    //       [key]: value
    //     })
    //   }

    const onBlurTrim = (evt) => {
        return evt.target.value.trim()
    }

    const updateErrorMessage = (msg) => {
        alert(msg)
    }

    const handleCancel = () => {
        history.push("/")
    }

    const updateProfile = (e) => {

        e.preventDefault()

        let name = firstName + ' ' + lastName
        try {
            //Update password if needed.
            if (existPassword != null || password != null || password2 != null) {
                if (existPassword == null) {
                    alert("You must enter your existing password to change it.")
                }
                else if (password != null && password != "") {
                    if (password === password2) {
                        Auth.currentAuthenticatedUser()
                            .then(user => {
                                return Auth.changePassword(user, existPassword, password);
                            })
                        // Auth.signUp({
                        //     username,
                        //     password,
                        //     attributes: {
                        //         email,
                        //         phone_number,
                        //         name,
                        //         'custom:organization': organization,
                        //         'custom:secondaryPhone': phone_number2,
                        //         'custom:additionalInfo': stringRoles,
                        //         'custom:phoneIsMobile': isMobile.toString(),
                        //         'custom:notificationPref': emailNotifications.toString(),
                        //         'custom:approverComments': commentsToApprover

                        //     }
                        // })
                        // .then(() => this.setState({ showConfirmation: true }))
                        // .catch(err => {
                        // // console.log('error signing up: ', err)
                        //   this.updateErrorMessage(err.message)
                        // })
                    } else {
                        alert("Passwords do not match")
                    }
                }


            }
        

        //Kick off attribute update service
        const userInfo = {
            'email': email,
            'name': firstName + ' ' + lastName,
            'phoneNumber': phoneNumber,
            'secondaryPhone': phoneNumber2,
            'phoneIsMobile': isMobile,
            'emailNotifications': emailNotifications,
            // 'userIdentifier': userIdentifier
        }

        updateUserProfile(userContext.user, userInfo).then(() => {
            userContext.updateCurrentUser();
            alert("User Information has been updated")
            
        })
    }
        catch (error)  {
            alert("An error occured during your update process.  Please contact the administrator to resolve.")
        }
        // console.log(commentsToApprover)

    }

    

    return (
        <>
            <DashboardNav />
            <div className="stretch_grid-container">

                {
                    !showConfirmation && (
                        <form onSubmit={updateProfile}>
                            <h1 style={{ paddingTop: '2em' }}>User Profile</h1>


                            <h3 className="registration-h3"> User Profile</h3>
                            <p className="registration-p-text">You may update the below information for your user profile</p>
                            <p className="registration-p">Fields marked with an asterisk (<abbr className="req" title="required">*</abbr>) are required.</p>


                            <FormGroup>
                                <h5 className="registration-h5">User Information</h5>
                                <Row className="userManagementRow">
                                    <Col>
                                        <Label htmlFor="first-name-input">First name <abbr className="req" title="required">*</abbr>:</Label>
                                        <TextInput
                                            id="first-name-input"
                                            name="first-name-input"
                                            value={firstName}
                                            type="text"
                                            onChange={evt => setFirstName(evt.target.value)}
                                            onBlur={evt => setFirstName(onBlurTrim(evt))}
                                            required
                                        />


                                    </Col>
                                    <Col>
                                        <Label htmlFor="last-name-input">Last name <abbr className="req" title="required">*</abbr>:</Label>
                                        <TextInput
                                            id="last-name-input"
                                            name="last-name-input"
                                            value={lastName}
                                            type="text"
                                            onChange={evt => setLastName(evt.target.value)}
                                            onBlur={evt => setLastName(onBlurTrim(evt))}
                                            required
                                        />
                                    </Col>
                                    <Col>
                                        {/* <Label htmlFor="organization-input">Organization <abbr className="req" title="required">*</abbr>:</Label>
                            <TextInput 
                                id="organization-input" 
                                name="organization-input" 
                                type="text" 
                                onChange={evt => this.onChange('organization', evt.target.value)}
                                onBlur={evt => this.onBlurTrim('organization', evt)}
                                required 
                            /> */}
                                    </Col>
                                </Row>
                                <br />
                                <h5 className="registration-h5">Contact Information</h5>
                                <Row className="userManagementRow">
                                    <Col>
                                        <Label htmlFor="email-input">Email <abbr className="req" title="required">*</abbr>:</Label>
                                        <TextInput
                                            id="email-input"
                                            name="email-input"
                                            value={email}
                                            type="email"
                                            onChange={evt => setEmail(evt.target.value)}
                                            onBlur={evt => setEmail(onBlurTrim(evt))}
                                            required
                                        />
                                        <Checkbox className="checkbox" id="email-notifications" name="email-notifications" label="Receive Notifications" checked={emailNotifications} onChange={evt => setEmailNotifications(evt.target.checked)} />
                                        RAISE will only use your email adress to confirm your account registration and send email notifications if enabled.
                                    </Col>
                                    <Col>
                                        <Label htmlFor="phone-input">Primary Phone Number <abbr className="req" title="required">*</abbr>:</Label>
                                        <TextInput
                                            id="phone-input"
                                            name="phone-input"
                                            type="text"
                                            value={phoneNumber}
                                            pattern="\+[0-9]{11,15}"
                                            placeholder="+11234567890"
                                            onChange={evt => setPhoneNumber(evt.target.value)}
                                            onBlur={evt => setPhoneNumber(onBlurTrim(evt))}
                                            required
                                        />
                                        <br />RAISE requires a primary phone able to receive SMS multifactor authentication codes to sign in.  US Phone numbers should be prefixed with +1, such as +11234567890.
                                    </Col>
                                    <Col>
                                        <Label htmlFor="secondary-phone-input">Secondary Phone Number:</Label>
                                        <TextInput
                                            id="secondary-phone-input"
                                            name="secondary-phone-input"
                                            type="tel"
                                            value={phoneNumber2}
                                            // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                            pattern="+[0-9]{11,15}"
                                            placeholder="+11234567890"
                                            onChange={evt => setPhoneNumber2(evt.target.value)}
                                            onBlur={evt => setPhoneNumber2(onBlurTrim(evt))}
                                        />
                                        <Checkbox className="checkbox" id="secondary-phone-input-checkbox" name="secondary-phone-input-checkbox" label="Mobile" checked={isMobile} onChange={evt => setIsMobile(evt.target.checked)} />

                                    </Col>
                                </Row>
                                <br />
                                <h5 className="registration-h5">Password</h5>
                                To change your password, enter your existing password, the new password you want, and then your new passowrd again in the confirmation field.<br />
                                Your RAISE Password must be 8 alpha numeric characters and include:<br />
                                1 lowercase letter<br />
                                1 uppercase letter<br />
                                1 special symbol from these: @$!%*?&<br />
                                1 number<br />
                                Spaces, tabs and new lines are not valid password characters<br />
                                <Row className="userManagementRow">
                                    <Col>
                                        <Label htmlFor="exist-password-input">Existing Password:</Label>
                                        <TextInput
                                            autoComplete="new-password"
                                            id="exist-password-input"
                                            name="exist-password-input"
                                            // pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
                                            type="password"
                                            onChange={evt => setExistPassword(evt.target.value)}

                                        />
                                    </Col>
                                    <Col>
                                        <Label htmlFor="password-input">Password:</Label>
                                        <TextInput
                                            id="password-input"
                                            autoComplete="new-password"
                                            name="password-input"
                                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
                                            type="password"
                                            onChange={evt => setPassword(evt.target.value)}

                                        />
                                    </Col>
                                    <Col>
                                        <Label htmlFor="confirm-password-input">Confirm Password:</Label>
                                        <TextInput
                                            autoComplete="new-password"
                                            id="confirm-password-input"
                                            name="confirm-password-input"
                                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
                                            type="password"
                                            onChange={evt => setPassword2(evt.target.value)}

                                        />
                                    </Col>

                                </Row>
                            </FormGroup>

                            <Button type="button" outline onClick={() => handleCancel()}>Cancel</Button>

                            <Button style={{ marginBottom: '200px' }} type="submit">Submit</Button>





                        </form>
                    )
                }
                {
                    showConfirmation && (
                        <div>
                            {/* <input
                    onChange={evt => this.onChange('authCode', evt.target.value)}
                    placeholder='Confirmation Code'
                /> */}
                            We've sent an e-mail to the address provided.  Please click on the link in that e-mail to finish your account registration process.  Once registered, your account will need to be approved and provisioned by an administrator.
                            <p />
                            <Link to='/' style={{ padding: '0.5rem' }}>
                                <Button>Return Home</Button>
                            </Link>
                        </div>
                    )
                }
            </div>
        </>
    )

}

export default UserProfile;
