import React, { useState, useContext, useEffect } from 'react'
import {  DataProvider } from '../components/DataContext';
import DashboardNav from '../components/DashboardNav'
import { Link } from 'react-router-dom'
import UserContext from '../components/UserContext'
import PageLoadSpinner from '../components/PageLoadSpinner'
import getMyFlights from '../services/getMyFlights'
import DQMetrics from './DQMetrics'
import FlightMetricsReact from './FlightMetricsReact'
// import MyFlightsTable from '../components/MyFlightsTable'
import GeoSearchByHelipadsComponentWrapper from './GeoSearchByHelipadsComponentWrapper'
import './MyFlights.css'
import CustomThemeProvider from '../components/styles/CustomThemeProvider';

import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const DEBUG_MODE = false;

const useStyles = makeStyles((theme) => ({

  lightTabs: {
    color: '#000 !important',
    background: '#fff !important',
    borderBottom: '1px solid #dee2e6',
    fontSize: 14
  },
  darkTabs: {
    color: '#fff !important',
    background: '#121212 !important',
    borderBottom: '1px solid #dee2e6',
  }
}));


const MyFlights = () => {

  const classes = useStyles();
  
  // Handle API call
  const [state, setState] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState([]);
  const [showEmbedDiv, setShowEmbedDiv] = useState(0);

  // Handle window autoscrolling
  const [scrollPosition, setScrollPostion] = useState(0);

  // Selected row vars
  const [selectedFlight, setSelectedFlight] = useState();

  // Handle user context
  const userContext = useContext(UserContext)
  var operator = userContext.user.attributes['custom:operator']

  // Handle column visiblity
  const [showColumnVisibility, setShowColumnVisibility] = useState(false);
  // const [durationVisibility, setDurationVisibility] = useState(true);
  // const [missionVisibility, setMissionVisibility] = useState(true);
  // const [makeVisibility, setMakeVisibility] = useState(true);
  // const [modelVisibility, setModelVisibility] = useState(true);
  // const [seriesVisibility, setSeriesVisibility] = useState(true);
  // const [exceedanceVisibility, setExceedanceVisibility] = useState(true);
  // const [obstacleVisibility, setObstacleVisibility] = useState(true);
  // const [weatherVisibility, setWeatherVisibility] = useState(true);
  // const [locVisibility, setLocVisibility] = useState(true);
  // const [dataQualityVisibility, setDataQualityVisibility] = useState(false);

  const [tabValue, setTabValue] = React.useState("1");
  // const handleTabChange = (event, newValue) => {
  //   setTabValue(newValue);
  // };

  useEffect(() => {
    // let execStart = Date.now();
    let intervDate = new Date();
    intervDate.setMonth(intervDate.getMonth() - 12);
    let searchObject = {
      startDate: intervDate,
      endDate: new Date(),
    };
    // console.log({ searchObject });
    getMyFlights(userContext.user, operator, searchObject)
      .then((result) => {
        // let execFinish = Date.now()
        // let timeTaken = execFinish - execStart
        // console.log("Service took " + timeTaken)
        setIsLoaded(true)
        if (result != null) {
          // console.log("Results gathered")
          setState(result)

        }

      })
      .catch((error) => {
        // console.log(error)
        setIsLoaded(true)
        setError(error)
      });
  }, [])

  // useEffect(() => {
  //   if (showEmbedDiv === 1) {
  //     // console.log("I've been triggered!")
  //     window.scrollTo(0, scrollPosition)
  //   }
  // }, [showEmbedDiv])

  // const toggleColumnVisibilityMenu = () => {
  //   setShowColumnVisibility(!showColumnVisibility)
  // }

  const enableShowEmbedDiv = (rows, divCode = 2) => {
    //mkae service call and mount dashboard
    // window.scrollTo(0, 0)
    setSelectedFlight(rows.row.original);
    setScrollPostion(window.pageYOffset);
    setShowEmbedDiv(divCode);
  }

  const searchShowEmbedDiv = (e) => {
    if (e) {
      e.stopPropagation();
    }

    setShowEmbedDiv(1);
  }

  const backToSearch = () => {
    //mkae service call and mount dashboard
    // reset scroll position so returning to table doesn't autoscroll
    setScrollPostion(0);
    setShowEmbedDiv(0);

  }

  // function convertToHHmmSS(t) {
  //   // Convert a string to int if needed.
  //   let time = 0;
  //   // console.log({t})
  //   // t is an object, parse it and round the decimals out.
  //   time = parseInt(t, 10).toFixed(0);

  //   // console.log(time)
  //   let hours = Math.floor(time / 3600);
  //   let minutes = Math.floor((time - (hours * 3600)) / 60);
  //   let seconds = time - (hours * 3600) - (minutes * 60);

  //   //Prepend 0 for non double digits.
  //   if (hours < 10) { hours = '0' + hours };
  //   if (minutes < 10) { minutes = '0' + minutes };
  //   if (seconds < 10) { seconds = '0' + seconds };

  //   return hours + ':' + minutes + ':' + seconds;
  // }

  // const submitSearchRequest = (searchObject) => {
  //   // console.log("Searching")
  //   // console.log({ searchObject })
  //   setState([]);
  //   setIsLoaded(false);
  //   setError([]);
  //   let execStart = Date.now()
  //   // console.log({searchObject})
  //   getMyFlights(userContext.user, operator, searchObject)
  //     .then((result) => {
  //       let execFinish = Date.now()
  //       let timeTaken = execFinish - execStart
  //       // console.log("Service took " + timeTaken)
  //       setIsLoaded(true)
  //       if (result != null) {
  //         // console.log("Results gathered")
  //         setState(result)

  //       }

  //     })
  //     .catch((error) => {
  //       // console.log(error)
  //       setIsLoaded(true)
  //       setError(error)
  //     })
  // }




  return (
    <div>
      <DashboardNav />
      <div hidden={showEmbedDiv !== 0} style={{paddingLeft: 10, paddingRight: 10}}>

        <Typography variant="button" display="block" sx={{pl: 1.5, pb: 1}} gutterBottom>
          {/*<Link to="#" style={{ marginLeft: '16px' }} onClick={searchShowEmbedDiv}>Back to Results</Link>*/}
          {/* <MButton variant="outlined" size="small" onClick={searchShowEmbedDiv}>Back to Results</MButton> */}
        </Typography>
        {/*<SearchMyFlights submitSearchRequest={submitSearchRequest} showEmbedDiv={searchShowEmbedDiv} />*/}
        <Box sx={{ width: '100%', typography: 'body1' }}>
          {/*<TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label="Flight search tabs">
                <Tab label="Geospatial Search" value="1" />
                <Tab label="Filter Search" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{paddingLeft: 0, paddingRight: 0}}>
              <GeoSearchByHelipadsComponentWrapper />
            </TabPanel>
            <TabPanel value="2" sx={{paddingLeft: 0, paddingRight: 0}}>
              <SearchMyFlights submitSearchRequest={submitSearchRequest} showEmbedDiv={searchShowEmbedDiv} />
            </TabPanel>
          </TabContext>*/}
          {/* <Tabs
            id="search_tabs"
            unmountOnExit={true} // Unmount tabs (remove it from the DOM) when it is no longer visible (if true)
            transition={Fade}
            variant="tabs"
          >
            <Tab eventKey="geo" 
                 title="Geospatial Search"
                 tabClassName={classes.lightTabs}> */}
              <GeoSearchByHelipadsComponentWrapper />
            {/* </Tab> */}
          
            {/* <Tab eventKey="filter" 
                 title="Filter Search"
                 tabClassName={classes.lightTabs}>
              <SearchMyFlights submitSearchRequest={submitSearchRequest} showEmbedDiv={searchShowEmbedDiv} />
            </Tab> */}
          {/* </Tabs> */}
        </Box>
      </div>
      <div id="myFlightsDiv" hidden={showEmbedDiv !== 1}>
        <div className="myFlights_header">
          <h1>My Flights</h1>
        </div>
        <div className="stretch_grid-container_myflights">
          <div className="grid-flight-bar">
            <div>
              <Link to="#" onClick={(e) => backToSearch()}>Search</Link> <br />
              {DEBUG_MODE && (
                <Link
                  to="#"
                  onClick={
                    () => enableShowEmbedDiv(
                      { row: { original: { flightmetadata__id: "S76D1_20210804T193025_20210804T195524" } } }, 3
                    )
                  }
                >
                  S76D1_20210804T193025_20210804T195524
                </Link>
              )}
              <br />
            </div>
          </div>
          {/*<Search 
                    style={{ width: '30em', float: 'left', marginRight: '10px'}} 
                    placeholder="Search Flights"
                    size="small"
                />
                <p style={{float: 'left', padding: '10px 10px 10px 10px'}}>Sort by</p>
                <Dropdown style={{ width: '20em', float: 'left', paddingTop: '0px', marginTop: '0px', marginRight: '10px' }} id="sort-dropdown" name="sort-dropdown">
                    <option>- Select - </option>
                    <option value="value1">Option A</option>
                    <option value="value2">Option B</option>
                    <option value="value3">Option C</option>
                </Dropdown>*/}
          {!isLoaded ? (
            <div><PageLoadSpinner />
              Please wait while the data loads.
            </div>
          ) : isLoaded && state.length > 0 ? (
            <div>
            {/* <MyFlightsTable state={state} setSelectedFlight={setSelectedFlight} isLoaded={isLoaded} setShowEmbedDiv={setShowEmbedDiv} setScrollPostion={setScrollPostion}/> */}
             
            </div>
          ) : (
            <div>No data was returned.  If this is unexpected please contact the administrator at <a href="mailto:RotorcraftHelp@asias.info">RotorcraftHelp@asias.info</a>
            </div>

          )}
        </div>
      </div> {/* End MyFlights Div */}
      {/* {showEmbedDiv === 2 && (
        <div id="dashDivEmbed">
          <Link to="#" style={{ marginLeft: '16px' }} onClick={searchShowEmbedDiv}>Back to Flights</Link>
          {selectedFlight && (
            <div>
              <FlightMetrics flight={selectedFlight} />
            </div>
          )}
        </div>
      )} */}

      {showEmbedDiv === 3 && (
        <div id="dashDivEmbedReact">
          {/* <Link to="#" style={{ marginLeft: '16px' }} onClick={searchShowEmbedDiv}>Back to Flights</Link> */}
          {selectedFlight && (
            <div>
              {/* NEW REACT-BASED DASHBOARD */}
              <DataProvider>
                <CustomThemeProvider>
                  <FlightMetricsReact
                    user={userContext.user}
                    operator={operator}
                    flight={selectedFlight}
                    onBackToFlights={searchShowEmbedDiv}
                  />
                </CustomThemeProvider>
              </DataProvider>
            </div>
          )}
        </div>
      )}


      {showEmbedDiv === 4 && (
        <div id="dqDivEmbed">
          <Link to="#" style={{ marginLeft: '16px' }} onClick={searchShowEmbedDiv}>Back to Flights</Link>
          {selectedFlight && (
            <div>
              <DQMetrics flight={selectedFlight} />
            </div>
          )}
        </div>
      )}

    </div>
  )
}

export default MyFlights
