import React, { useState, useRef, useEffect, useContext } from 'react';
import { DataContext } from "../../DataContext";
// import PropTypes from 'prop-types';
import CanvasJSReact from '../../../canvasjs-commercial-3.4.12/canvasjs.react';
import { useResizeDetector } from 'react-resize-detector';
import PropTypes from 'prop-types';

import '../Chart.css';

// const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const propTypes = {
  fieldOfInterestName:PropTypes.string.isRequired,
  fieldOfInterest:PropTypes.string.isRequired,
};

const defaultProps = {
  fieldOfInterestName:"AGL (ft)",
  fieldOfInterest:'final_agl_str',
};

// Control/Configure the size of the markers and lines in the chart
const DATA_POINT_SIZE = 8;
const LINE_THICKNESS = 2;

const appUnsTranslation = {
  "0": "Stable",
  "1": "Unstable LOW",
  "2": "Unstable MEDIUM",
  "3": "Unstable HIGH"
}

const humanReadableApproach = {
  'VFR':'Visual',
  'IFR':'Instrument',
  'VFR-Missed':'Go-Around',
  'IFR-Missed':'IFR-Missed',
  'Visual':'VFR',
  'Instrument':'IFR',
  'Go-Around':'VFR-Missed',
  'IFR-Missed':'IFR-Missed',
  'Stable':'Stable',
  "Unstable HIGH":"Unstable HIGH",
  "Unstable MEDIUM":"Unstable MEDIUM",
  "Unstable LOW":"Unstable LOW",

}

function UimcPOIScatterChart(props) {
  // const {fieldOfInterest, fieldOfInterestName} = props
  // Obtain reference to global data context consumer
  const dataContext = useContext(DataContext);

  const { width, height, ref: resizeDetectorRef } = useResizeDetector();

  // Keep reference of chart for event listeners/actions
  const chartRef = useRef(null);
  const isMounted = useRef(false);

  

  // Determine if data exists
  const d = dataContext.filteredReducedData ? dataContext.filteredReducedData : dataContext.baseReducedData;
  const trackPointDataExists = d && d.track_points && d.track_points.data && d.track_points.data.length > 0;
  const exceedancePointDataExists = d && d.exceedance_point && d.exceedance_point.data && d.exceedance_point.data.length > 0;
  const dataExists = trackPointDataExists || exceedancePointDataExists;

  const [dataOfInterestExists, setDataOfInterestExists] = useState(true);
  const [dataCalculated, setDataCalculated] = useState(true)


  
  


  /**
   * Handler for parsing date object to a formatted string version.
   * 
   * @param {object} e CanvasJS label event object containing the current date value.
   * @returns The formatted date string.
   */
  const dateLabelFormatter = (e) => {
    return dataContext.toHumanReadableDateStr(e.value, true);
  }

 
  /**
   * Build and retrieve formatted HTML string representing a featured
   * tooltip given a set of values for a particular row of interest.
   * 
   * row = {
   *   "key_1": "value_1",
   *   "key_2": "value_2",
   *   ...
   *   "key_n": "value_n"
   * }
   * 
   * @param {object} row Fields of interest for current row in dataset.
   * @param {string} color Hexidecimal or RGB(A) color string.
   * @returns Formatted HTML string representing a featured tooltip.
   */
  const getFeaturedTooltip = (row, color = null) => {
    // Build tool tip off all properties
    var tooltipText = "";

    // Start table
    tooltipText += "<table>";
    for (const [key, value] of Object.entries(row)) {
      let iconData = `<td><i class="fa fa-square tooltip-icon-fonts" style="color: ${color}; margin-right: 5px;"></i></td>`;
      tooltipText += `
        <tr>
          ${color !== null && iconData}
          <td style="color: #989898"><b>${key}&nbsp</b></td>
          <td><b>${value}</b></td>
        </tr>
      `;
    }

    // End table
    tooltipText += "</table>";
    return tooltipText;
  }

  // See CanvasJS configuration options at https://canvasjs.com/docs/charts/basics-of-creating-html5-chart/
  // Value format string: https://canvasjs.com/docs/charts/chart-options/axisx/valueformatstring/
  const [options, setOptions] = useState({
    zoomEnabled: true,
    animationEnabled: true,
    title: {
      text: ""
    },
    theme: dataContext.darkMode ? "dark1" : "light1",
    toolTip: {
      enabled: true,          //disable here
      animationEnabled: true, //disable here
      contentFormatter: e => {
        var content = "";
        for (var i = 0; i < e.entries.length; i++) {
          let dataPoint = e.entries[i].dataPoint;
          content = `${content}${dataPoint.tooltipText}`;
        }

        return content;
      }
    },
    axisX: {
      labelFontFamily: dataContext.CHART_FONTS.fontFamily,
      labelFontWeight: dataContext.CHART_FONTS.fontWeight,
      labelAutoFit: true,
      labelAngle: 0,
      title: "",
      gridThickness: 0.5,
      // See: https://canvasjs.com/docs/charts/basics-of-creating-html5-chart/formatting-date-time/
      labelFormatter: dateLabelFormatter,
      labelFontSize: 13,
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
        labelFormatter: dateLabelFormatter,
      },
      // interval: 2,
      // intervalType: "minute",
    },
    axisY: {
      gridThickness: 0.5,
      labelFontFamily: dataContext.CHART_FONTS.fontFamily,
      labelFontSize: dataContext.CHART_FONTS.fontSize,
      labelFontWeight: dataContext.CHART_FONTS.fontWeight,
      title: ""
    },
    legend: {
      fontFamily: dataContext.CHART_FONTS.fontFamily,
      fontSize: dataContext.CHART_FONTS.fontSize,
      fontWeight: dataContext.CHART_FONTS.fontWeight,
    },
    data: [
      { type: "spline", dataPoints: [] }
    ]
  });

  
  

  // Component mount/unmount
  useEffect(() => {
    isMounted.current = true;

    // NOTE: identify document element by id on component mount to ensure canvas js chart wraps to parent container
    if (resizeDetectorRef.current) {
      let chartContainer = resizeDetectorRef.current.children[0]; // e.g., canvasjs-react-chart-container-2
      if (chartContainer) {
        chartContainer.style.height = "100%";
        chartContainer.style.width = "100%";
      }
    }

    return () => {
      isMounted.current = false;

      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }

      setOptions({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Detect changes to incomming/ingested data
  useEffect(() => {
    if (isMounted.current) {
      // Obtain reference to active data from context
      let dataTarget = dataContext.filteredReducedData ? dataContext.filteredReducedData : dataContext.baseReducedData;

      if (dataTarget) {
        // Extract lookup table and data from track points or exceedance points if track points aren't available
        let trackDataExists = dataTarget.track_points && dataTarget.track_points.data && dataTarget.track_points.data.length > 0;
        let trackPointsTarget = trackDataExists ? dataTarget.track_points : dataTarget.exceedance_point;

        const { data, lookup } = trackPointsTarget; // Extract lookup table from track points data

       const {fieldOfInterest, fieldOfInterestName} = props

        // Extract only required data key/values for rendering data points and tooltips from track points data
        let dataPoints = [];
        let vrsCalculated = dataTarget && dataTarget.flight_meta ? dataTarget.flight_meta.data[0][dataTarget.flight_meta.lookup["vrs_calculated"]] : true;
        let lteCalculated = dataTarget && dataTarget.flight_meta ? dataTarget.flight_meta.data[0][dataTarget.flight_meta.lookup["lte_calculated"]] : true;
        
        let colorLegendSeries = [{}];
        // console.log(data)
        // console.log(lookup)
        // find min/max date for scale of x axis
        let dateMin = undefined;
        let dateMax = undefined;
        let colorLegend = new Set();
        let vfrFlag = false
        let vfrMissedFlag = false
        let ifrFlag = false
        let ifrMissedFlag = false
        let stripLineBuilder = {};
        

        Array.from(data).forEach((point) => {
          let timestampStr = point[lookup.times_timestamp];
          let value = point[lookup[fieldOfInterest]];
          let fieldName = fieldOfInterestName;

          vrsCalculated = point[lookup.vrs_calculated] === true || point[lookup.vrs_calculated] === false ? vrsCalculated && point[lookup.vrs_calculated] : vrsCalculated
          lteCalculated = point[lookup.lte_calculated] === true || point[lookup.lte_calculated] === false ? lteCalculated && point[lookup.lte_calculated] : lteCalculated


          if (timestampStr !== null && value !== null) {

            
            let color = "#9FDEF1";
            
            let pointDate = new Date(timestampStr)
            let fieldsOfInterest = {
              "Time": point[lookup.human_readable_datetime],
              "Phase of Flight": point[lookup.phaseofflight_mavg10_str],
              [fieldName]: value,
              "Vertical Speed (f/m)": point[lookup.verticalspeed_final_fpm_str],
              "Roll (deg)": point[lookup.flightstate_position_roll_str],
              "Pitch (deg)": point[lookup.flightstate_position_pitch_str],
              "Yaw Rate (deg/s)": point[lookup.flightstate_rates_yawrate_str],
            };

            if (point[lookup.uimc_id] && point[lookup.uimc_id] != "") {

              //Add legend
             // Change color of points here based off uimc_id type
              color = 'purple'
              //Add legend
              colorLegend.add('UIMC Event') 
              // Adjust striplines
              if(!stripLineBuilder[point[lookup.uimc_id]]){
                
                stripLineBuilder[point[lookup.uimc_id]] = {'startPoint': pointDate}
                
              }
              if(stripLineBuilder[point[lookup.uimc_id]]){
                stripLineBuilder[point[lookup.uimc_id]]['endPoint'] = pointDate
              }

              
            }
            
  
            let tooltipText = getFeaturedTooltip(fieldsOfInterest, color);
            
            dataPoints.push({
              flightid: point[lookup.flightid],
              uimc_id : point[lookup.uimc_id],
              x: pointDate,
              y: dataContext.round(value),
              color: color,
              tooltipText: tooltipText,
            });

            if (dateMin === undefined && dateMax === undefined) {
              dateMin = pointDate;
              dateMax = pointDate;
            }

             // Update date min
             if (pointDate < dateMin) {
              dateMin = pointDate;
            }

            // Update date max
            if (pointDate > dateMax) {
              dateMax = pointDate;
            }
          }
        });

        // Set builder to main list

         // NOTE: Data points list with empty object (e.g., [{}]) will cause render in legend but no data points needed on chart
         // build a list of legends from colorLegend
        //  console.log(colorLegend)
        Array.from(colorLegend).sort().forEach((element) => {
          colorLegendSeries.push({
            legendText: element,
          showInLegend: true,
          legendMarkerColor: element === 'UIMC Event' ? 'purple' : 'white',
          legendMarkerType: "square",
          dataPoints: [{}]
          })
        })


        // Iterate through data points and set the line colors to the color of the next point (for variable color shading support)
        Array.from(dataPoints).forEach((currentPoint, idx) => {
          let nextPointExists = (idx + 1) <= dataPoints.length - 1;
          let nextPoint = nextPointExists ? dataPoints[idx + 1] : null;
          currentPoint.lineColor = nextPoint !== null ? nextPoint.color : currentPoint.color;
        });

        // Data points of interest exist when at least one data point exists in the list
        setDataOfInterestExists(dataPoints.length > 0);
        setDataCalculated(vrsCalculated && lteCalculated)

        
        // Get the minimum and maximum possible y values to set appropriate scale for y-axis (and doesn't change when zooming)
        const { min, max } = dataContext.getMinMaxValues(data, lookup[fieldOfInterest]);
        let padding = (dataContext.getMagnitude(max) / 10) * 5;
        // Adjust padding if it is 0, which indicates all data points have the same y-axis value (e.g., min and max are equal)
        padding = padding === 0 ? 0.1 : padding;

        

        // create strip line block
        // console.log(stripLineBuilder)
        var stripLineBlock = []
        Object.keys(stripLineBuilder).map(key => {
          stripLineBlock.push({
            startValue:stripLineBuilder[key] ? stripLineBuilder[key]['startPoint'] : '',
              endValue:stripLineBuilder[key] ? stripLineBuilder[key]['endPoint'] : '',
              color:"#A6DCF2"
          })
        })
        
        // console.log(colorLegendSeries)
        // Create formatted data structure for canvasjs chart
        // See data series attributes at https://canvasjs.com/docs/charts/chart-options/data/
        // See performance improvement considerations at https://canvasjs.com/forums/topic/performance-improvement-in-scatter-chart/
        setOptions({
          ...options,
          axisX: {
            ...options.axisX,
            minimum: dataPoints ? dateMin : undefined,
            maximum: dataPoints ? dateMax : undefined,
            stripLines:stripLineBlock
          },
          axisY: {
            ...options.axisY,
            minimum: min - padding,
            maximum: max + padding,
          },
          data: [
            ...colorLegendSeries,
            {
              type: "spline",
              markerType: "square",
              lineThickness: LINE_THICKNESS,
              markerSize: DATA_POINT_SIZE,
              dataPoints: dataPoints,
            },
          ]
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.baseReducedData, dataContext.filteredReducedData]);

  useEffect(() => {
    if (isMounted) {
      // Re-render chart if there's a change in theme
      if (chartRef.current) {
        // NOTE: must do it this way since options are copied to chart reference (e.g., not bound by state/props)
        chartRef.current.options.theme = dataContext.darkMode ? "dark1" : "light1";
        chartRef.current.render();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.darkMode]);

  // Dynamically update width and height of chart to fill parent container on dimension change
  useEffect(() => {
    if (isMounted) {
      if (chartRef.current) {
        let yOffset = 20;
        let dh = height - yOffset;
        if (width > 0 && dh > 0) {
          chartRef.current.options.width = width;
          chartRef.current.options.height = dh;
          chartRef.current.render();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height]);

  return (
    <div ref={resizeDetectorRef} style={{ width: "100%", height: "100%" }}>
    
      {(dataExists && dataOfInterestExists && dataCalculated) && (
        
        <CanvasJSChart options={options} onRef={ref => chartRef.current = ref} />
      )}
      {(!dataExists) && (
        <div>
          <b>No Data</b>
        </div>
      )}
      {(dataExists && !dataCalculated) && (
        <div>
          {/* Customize message as needed */}
          <b>Insufficient Data</b>
        </div>
      )}
      {(dataExists && dataCalculated && !dataOfInterestExists) && (
        <div>
          {/* Customize message as needed */}
          <b>No Findings</b>
        </div>
      )}
    </div>
  );
}

UimcPOIScatterChart.propTypes = propTypes;
UimcPOIScatterChart.defaultProps = defaultProps;

export default UimcPOIScatterChart;
