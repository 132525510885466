import getPresignedURL from '../services/getPresignedURL'

async function upload(tail, mission, operator, userContext) {

    let uploadSuccess = "failure"
    const files = document.querySelector('[type=file]').files
    const formData = new FormData()
    userContext.pauseTimer()
    // console.log({userContext})

        if(files.length === 0) {
            alert("Please select a file first")
        }
        
        for (let i = 0; i < files.length; i++) {
            let file = files[i]
            var fileName = file.name
            formData.append('files[]', file)

            // TEMPROARY PARAMETERS FOR TESTING
            let params = {
                filename: fileName,
                tail: tail,
                operator: operator,
                file_type: "csv",   
                mission_type: mission,
                fileNumber: ('0000'+i).slice(-4)
            }

            if(typeof fileName != 'undefined') {
                await getPresignedURL(userContext.user, params)
                    .then(
                        async (response) => {
                        //   console.log(response)
                        //   console.log(files[i]['name'])
                            await fetch(response.url, {
                                method: 'PUT',
                                body: files[i],
                                headers: new Headers({
                                    'x-amz-tagging': 'original_filename='+response.filename
                                })
                            }).then((response) => {
                                
                                //   console.log(response)
                                    // alert("File(s) successfully uploaded")
                                    if(response.status === 200){
                                        uploadSuccess = "success";
                                    }
                                    
                                
                            }).catch((error) => {
                                alert('There was an error uploading this file' + error);
                                
                            })
    
                    }).catch((error) => {
                        alert('Error uploading file' + error);
                    })

            } else {
                // handle user has not yet selected any files 
                alert('Please Select a file to upload')
            }
        }
        userContext.resumeTimer()
        return uploadSuccess
}

export default upload;

