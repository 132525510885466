import React from 'react'
// import Navbar from './Navbar'
import UnauthNav from '../components/UnauthNav';

const Contact = () => {
  return ( 
    <>
    <UnauthNav />
    <div className="stretch_grid-container">
      
      <h1 style={{paddingTop: '1em'}}> Contact </h1> 
      <p>
        For more information please email us via the following email address: <a href="mailto:ContactUs@asias.info">ContactUs@asias.info</a> 
      </p>
    </div>
    </>
  )
}

export default Contact