import React, {useEffect, useState, useContext } from 'react'
import { 
    FormGroup,
    Dropdown,
    Label,
    FileInput,
    Button
} from '@trussworks/react-uswds'
import DashboardNav from '../components/DashboardNav'
import UserContext from '../components/UserContext'
import upload from '../services/upload'

import UploadStatusTag from '../components/UploadStatusTag'
import Modal from 'react-bootstrap/Modal'

function UploadPage(props) {
    const [uploadState, setUploadState] = useState("none")
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [flights, setFlights] = useState([]);
    // const [tail, setTail] = useState(null);
    // const [missionTypes, setMissionTypes] = useState(null);
    const [mission, setMission] = useState(null);
    const [defaultMission, setDefaultMission] = useState(null);
    const [operator, setOperator] = useState([]);
    const userContext = useContext(UserContext);
    const [selected, setSelected] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const handleClose = () => setShowConfirmation(false);

    
    
    // console.log({props})
    // setOperator(userContext.user.attributes['custom:operator'])
    // setTail(props.tail)
    // setMission(props.mission)
    // setMissionTypes(props.missionList)

    useEffect(() => {      
        setMission(props.mission)
    //     // getMissionsAndMapping(userContext.user)
    //     //   .then(
    //     //     (result) => {
    //     //   // console.log(result)
    //     //       if (result) {
    //     //         setMissionTypes(result[0])
    //     //         setFlights(result[1]);
    //     //         setIsLoaded(true);
    //     //       }
    //     //     // console.log(result)
    //     //     },
    //     //     (error) => {
    //     //         setIsLoaded(true);
    //     //         setError(error);
    //     //     })
        }, [])
    
    // function handleTailSelect(event) {
        // console.log(event.target.value)
        // setTail(event.target.value)
    // }
    
    function handleMissionSelect(event) {
      // console.log(event.target.value)
        setMission(event.target.value)
    }

    function handleChange(event) {
        if(event.target.files.length){
            let listFiles = [];
            Array.from(event.target.files).map((targetFile) => {
              listFiles.push(targetFile.name)
            })
            // console.log({target})
            setSelected(listFiles)
        }
    }

    function onConfirmUpload(e) {
        e.preventDefault();
        setUploadState("inprogress")
        setShowConfirmation(false);
        // console.log(userContext)
        upload(props.tail, mission, operator, userContext)
        .then((result) => {
            setUploadState(result)
        })
        
    }

    function onClick(e) {
        e.preventDefault();
      // console.log({selected})
        setShowConfirmation(true);
        // setUploadState("inprogress")
        // console.log(userContext)
        // upload(tail, mission, operator, userContext)
        // .then((result) => {
            // setUploadState(result)
        // })
        
    }

    function handleCancel() {
        props.handleClose()
      // console.log("Cancelled")
    }

    return (
        <div>
        {/* <DashboardNav /> */}
        <div className="stretch_grid-container">
            
            {/* <h1>Upload Flight Information</h1> */}
            <p><strong>Operator: {operator} </strong></p>
                <form onSubmit={onClick}>
                    <p>Upload for Alias: {props.tail}</p>
                        {/* <Label htmlFor="tail-dropdown">Aircraft MMS - Recorder MMV</Label>
                            <Dropdown id="tail-dropdown" name="tail-dropdown" 
                                value={tail || ''}
                                onChange={handleTailSelect}
                                required
                            >
                                
                            <option value="">- Select MMS - MMV - </option>
                                {
                                    isLoaded && (
                                        flights.map((data, index) => (
                                            <option key={index} >
                                                {console.log(data)}
                                                {data.icao_make}/{data.icao_model}/{data.icao_series} 
                                                {"  -  "}    
                                                {data.recorder_make}/{data.recorder_model}/{data.recorder_version}

                                            </option>
                                        ))
                                        
                                    )
                                
                                }
                            </Dropdown> */}
                        <Label htmlFor="mission-dropdown">Mission Type</Label>
                            <Dropdown id="mission-dropdown" name="mission-dropdown" 
                                value={mission || ""}
                                onChange={handleMissionSelect}
                                required
                            >
                            <option value="">- Select Mission Type - </option>
                            {
                                    
                                        
                                        props.missionList.map((data, index) => (
                                            <option key={index} value={data.mission_short}>
                                                {data.mission }
                                            </option>
                                        )
                                        
                                    )
                                
                            }
                        </Dropdown>


                        <Label htmlFor="file-input-multiple">Upload Flight Information</Label>
                        <span className="usa-hint" id="file-input-specific-hint">Supported file types: csv</span>
                        <FileInput 
                            id="file-input-multiple" 
                            name="file-input-multiple" 
                            onChange={handleChange} 
                            accept=".csv"
                            multiple
                            required
                        />
                        <div style={{paddingTop: '20px', display: 'flex'}}>
                            <Button type="button" onClick={handleCancel} outline style={{display: uploadState && uploadState !="none" ? "" : "none"}}>Close</Button>
                            <Button type="button" onClick={handleCancel} outline style={{display: uploadState && uploadState =="none" ? "" : "none"}}>Cancel</Button>
                            <Button type="submit" style={{display: uploadState && uploadState =="none" ? "" : "none"}}>Submit</Button>
                            <UploadStatusTag message={uploadState} />
                        </div>

                </form>

            <p style={{paddingTop: '20px'}}>Operator understands that this data is only for use within the ASIAS Governance model and individual flight data
                will always be masked to other users and is being shared with IAT analysts via the ASIAS Governance process 
                and only made available in aggregate form to the broader ASIAS community... Every effort is taken to protect 
                the identity of all operators. The user agrees that this is a voluntary tranmission of data to be used for ASIAS 
                purposes... 
            </p>
        </div>
        <Modal
            size="small" 
            show={showConfirmation}
            backdrop="static"
            centered
            scrollable={true} 
            onHide={handleClose}
            >
            <Modal.Header>
                        <Modal.Title>Confirm Upload Information</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                      {props.tail}<br />
                      {mission} <br />
                      {selected ? selected.map((fileName, index) => ( <div key={index}> {fileName} <br /> </div>)) : <div></div>} 
                      <Button type="button" onClick={handleCancel} outline>Cancel</Button>
                      <Button type="submit" onClick={onConfirmUpload}>Confirm</Button>
                      </Modal.Body>
                      </Modal>
        </div>
    )
}

export default UploadPage;