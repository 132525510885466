// import { useContext } from "react";
// import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
// import { DataContext } from "../DataContext";
import './BasemapSwitchControlStyles.css';

const DEFAULT_TILE_SIZE = 256;

class BasemapMapboxSwitchControl {
  constructor(controlName) {
    // this.styles = styles || BasemapSwitchControl.DEFAULT_STYLES;
    this.controlName = controlName || BasemapMapboxSwitchControl.DEFAULT_NAME;

    this.styles = [];
    // const dataContext = useContext(DataContext);
    for (const [key, value] of Object.entries(BasemapMapboxSwitchControl.BaseMaps)) {
      let baseMap = {};
      baseMap["title"] = key;
      baseMap["styleId"] = value["styleId"];
      
      // baseMap["url"] = value["url"];
      // baseMap["attribution"] = value["attribution"];
      // baseMap["type"] = value["type"];
      // baseMap["tileSize"] = value["tileSize"];

      this.styles.push(baseMap);
    }

    // const defaultStyle = typeof(options) === "string" ? options : options ? options.defaultStyle : undefined;
    // this.defaultStyle = defaultStyle || BasemapMapboxSwitchcontrol.DEFAULT_STYLE;
    this.defaultStyle = BasemapMapboxSwitchControl.DEFAULT_STYLE;
    this.onDocumentClick = this.onDocumentClick.bind(this);
    // this.events = typeof(options) !== "string" && options ? options.eventListeners : undefined;
  }
  getDefaultPosition() {
    const defaultPosition = "top-right";
    return defaultPosition;
  }
  onAdd(map) {
    this.map = map;
    this.controlContainer = document.createElement("div");
    this.controlContainer.classList.add("mapboxgl-ctrl");
    this.controlContainer.classList.add("mapboxgl-ctrl-group");
    this.mapStyleContainer = document.createElement("div");
    this.styleButton = document.createElement("button");
    this.styleButton.type = "button";
    this.mapStyleContainer.classList.add("mapboxgl-style-list");
    for (const style of this.styles) {
      const styleElement = document.createElement("input");
      styleElement.type = "radio";
      // styleElement.setAttribute("name", "basemap-checkbox"); //Because of the Radio buttons, the name must be same!
      styleElement.setAttribute("name", this.controlName);
      styleElement.classList.add("list-checkcontrol-block");
      // styleElement.dataset.url = style.url;
      // styleElement.dataset.attribution = style.attribution;
      // styleElement.dataset.type = style.type;
      // styleElement.dataset.tileSize = style.tileSize;
       styleElement.dataset.styleId = style.styleId;

      styleElement.addEventListener("click", event => {
        const srcElement = event.srcElement;

        if (srcElement.hasAttribute("checked")) {
          return;
        }

        if (this.events && this.events.onOpen && this.events.onOpen(event)) {
          return;
        }

        // const styleJson = BasemapSwitchControl.DEFAULT_STYLEJSON;
        // styleJson['sources']['raster-tiles']['type'] = srcElement.dataset.type;

        // // NOTE: Need to parse to int because dataset is a DOMStringMap type (all values are converted to string)
        // styleJson['sources']['raster-tiles']['tileSize'] = parseInt(srcElement.dataset.tileSize);

        // // styleJson['sources']['raster-tiles']['url'] = srcElement.dataset.url;
        // styleJson['sources']['raster-tiles']['tiles'][0] = srcElement.dataset.url;
        // styleJson['sources']['raster-tiles']['attribution'] = srcElement.dataset.attribution;

        // this.map.setStyle(styleJson);
        // console.log(event);
        // console.log(srcElement);
        // console.log(srcElement.dataset)
        
        let styleId = srcElement.dataset.styleId;
        let mapStyle = 'mapbox://styles/mapbox/' + styleId;
        // console.log(mapStyle);
        
        this.map.setStyle(mapStyle);

        let nodeList = this.mapStyleContainer.getElementsByTagName("input");

        for (let i = 0; i < nodeList.length; i++) {
          let node = nodeList[i];
          if (node.hasAttribute("checked"))
            node.checked = false;
        }
        srcElement.checked = true;

        if (this.events && this.events.onChange && this.events.onChange(event, style)) {
          return;
        }
      });

      // console.log(style.title);
      
      if (style.title ==(this.defaultStyle)) {
        styleElement.checked = true;
      }
      this.mapStyleContainer.appendChild(styleElement);

      const styleElementLabel = document.createElement("span");
      styleElementLabel.innerText = style.title;

      this.mapStyleContainer.appendChild(styleElementLabel);

      const styleElementBr = document.createElement("br");
      this.mapStyleContainer.appendChild(styleElementBr);
    }
    this.styleButton.classList.add("mapboxgl-ctrl-icon");
    this.styleButton.classList.add("mapboxgl-style-switcher");
    this.styleButton.addEventListener("mouseover", event => {
      this.openModal();
    });
    this.mapStyleContainer.addEventListener("mouseleave", event => {
      this.closeModal();
    });
    this.styleButton.addEventListener("click", event => {
      if (this.events && this.events.onSelect && this.events.onSelect(event)) {
        return;
      }
      // this.openModal();
    });
    document.addEventListener("click", this.onDocumentClick);
    this.controlContainer.appendChild(this.styleButton);
    this.controlContainer.appendChild(this.mapStyleContainer);
    return this.controlContainer;
  }
  onRemove() {
    if (!this.controlContainer || !this.controlContainer.parentNode || !this.map || !this.styleButton) {
      return;
    }
    this.styleButton.removeEventListener("click", this.onDocumentClick);
    this.controlContainer.parentNode.removeChild(this.controlContainer);
    document.removeEventListener("click", this.onDocumentClick);
    this.map = undefined;
  }
  closeModal() {
    if (this.mapStyleContainer && this.styleButton) {
      this.mapStyleContainer.style.display = "none";
      this.styleButton.style.display = "block";
    }
  }
  openModal() {
    if (this.mapStyleContainer && this.styleButton) {
      this.mapStyleContainer.style.display = "block";
      this.mapStyleContainer.style.marginTop = "5px";
      this.mapStyleContainer.style.marginLeft = "3px";
      this.mapStyleContainer.style.marginBottom = "5px";
      this.mapStyleContainer.style.marginRight = "6px";
      this.styleButton.style.display = "none";
    }
  }
  onDocumentClick(event) {
    if (this.controlContainer && !this.controlContainer.contains(event.target)) {
      this.closeModal();
    }
  }
}
BasemapMapboxSwitchControl.DEFAULT_STYLE = "Satellite";
// BasemapSwitchControl.DEFAULT_STYLES = [
//     { title: "Light Basemap", uri: "mapbox://styles/mapbox/light-v10" },
//     { title: "Dark Basemap", uri: "mapbox://styles/mapbox/dark-v10" },
//     { title: "Open Street Map", uri: "mapbox://styles/mapbox/outdoors-v11" },
//     { title: "VFR Sectional Basemap", uri: "mapbox://styles/mapbox/satellite-streets-v11" },
//     { title: "Helo Chart Basemap", uri: "mapbox://styles/mapbox/satellite-streets-v11" },
//     { title: "Esri World Imagery (Terrain)", uri: "mapbox://styles/mapbox/outdoors-v11" },
// ];
BasemapMapboxSwitchControl.BaseMaps = {
  "Satellite": {
    styleId: 'satellite-v9'
  },
   "Satellite Streets": {
    styleId: 'satellite-streets-v12'
  },
   "Streets": {
    styleId: 'streets-v12'
  },
   "Outdoor": {
    styleId: 'outdoors-v12'
  },
   "Dark": {
    styleId: 'dark-v11'
  }
  // "3D Terrain": {
  //   url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
  //   attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
  //   type: 'raster-dem',
  //   tileSize: 512,
  // },
};

BasemapMapboxSwitchControl.DEFAULT_NAME = "basemap-checkbox";
BasemapMapboxSwitchControl.DEFAULT_STYLEJSON = {
  'version': 8,
  'glyphs': 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
  'sources': {
    'raster-tiles': {
      'type': 'raster',
      'tiles': [
        'https://basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png'
      ],
      'tileSize': DEFAULT_TILE_SIZE,
      'attribution': '&copy <a href="http://cartodb.com/attributions" target="_blank">CartoDB</a> contributors'
    }
  },
  'layers': [
    {
      'id': 'basemap-tiles',    // Can be whatever you want to name it
      'type': 'raster',         // Must be raster
      'source': 'raster-tiles', // Must be 'raster-tiles'
      'minzoom': 0,
      'maxzoom': 22
    }
  ]
};

export default BasemapMapboxSwitchControl;
