import React, { useState, useEffect, useRef, useContext } from "react";
import UserContext from '../UserContext';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { DataContext } from "../DataContext";
import getDocument from "../../services/getDocument";

import { useResizeDetector } from 'react-resize-detector';
import BasemapMapboxSwitchControl from "../controls/BasemapMapboxSwitchControl";
import MapboxGLButtonControl from '../controls/MapboxGLButtonControl';
import getAirportApproachFlights from '../../services/getAirportApproachFlights';
import getAirportOperators from '../../services/getAirportOperators';

import getMyFlights from '../../services/getMyFlights';
import PropTypes from 'prop-types';
import DataBrowser, { buildDataBrowserColumn } from "../misc/DataBrowser";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

// Import Styles
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import 'font-awesome/css/font-awesome.min.css';
import '../styles/css/fontello.css';
import './Map.css';
import './GeoSearchByHelipadsMap.css';
import ApproachHelipadsBar from '../utils/ApproachHelipadsBar'
import SearchMyFlights from "../../pages/SearchMyFlights";
import helipadPng from '../styles/helipad.png';
import geohelipadPng from '../styles/helipad_orangered.png';
import { makeStyles } from '@mui/styles';

import { Threebox } from "threebox-plugin";
import "threebox-plugin/dist/threebox.css";

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { ComponentPropsToStylePropsMapKeys } from "@aws-amplify/ui-react";

mapboxgl.accessToken = 'pk.eyJ1IjoiemhqbWFwYm94IiwiYSI6ImNpdDY2NjZtczAzYXgyc2xqbGp0MTI0cmUifQ.ZwiVYpuYfxANYXqBBYTzgQ';

const propTypes = {
  mapId: PropTypes.string.isRequired,
  startLoc: PropTypes.arrayOf(PropTypes.number),
  zoom: PropTypes.number,
};

const defaultProps = {
  // startLoc: [-76.72425, 39.1057],
  // zoom: 10,
  startLoc: [-98.57, 39.82],
  zoom: 6,
};

const approachQueryNoDataStr = 'No data queried'

const mapResourceNameRunways = 'Runways.geojson';
// const mapLayerNameRunways = 'Runways';
const mapLayerIdRunways = 'Runways-layer';

// const mapResourceNameHelipads = 'helipads.geojson';
// const mapLayerIdHelipads = 'helipads-layer';

// const mapResourceNameCircle = "resource-circle";
// const mapLayerIdCircle = "map-layer-circle";
// const mapLayerIdCircleOutline = "map-layer-circle-outline";

const mapResourceNameHelipadPoint = 'resource-helipad-point';
const mapLayerIdHelipadPoint = 'map-layer-helipad-point';
const mapImageIdHelipadPoint = 'map-image-helipad-point';

const mapResourceNameHelipadPointAni = 'resource-helipad-point-ani';
const mapLayerIdHelipadPointAni = 'map-layer-helipad-point-ani';

const mapImageIdHelipadPointAni = 'pulsing-dot';

// const uasSightingsColor = "#666";

const mapResourceNameDEM = 'mapbox-dem';
const mapLayerIdCustom = 'custom_layer';

const airhelipadJsonFileName = "aphelipads.json";


const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: "0.5rem"
  },
  spacer: {
    flexGrow: 1
  },
  body: {
    padding: "0.5rem",
    flexGrow: 1
  }
});

// const buildFeaturedTooltip = (row, { color = null }) => {
//   // Build tool tip off all properties
//   var tooltipText = "";

//   // Start table
//   tooltipText += "<table>";
//   for (const [key, value] of Object.entries(row)) {
//     let iconData = `<td><i class="fa fa-square tooltip-icon-fonts" style="color: ${color}; margin-right: 5px;"></i></td>`;
//     tooltipText += `
//       <tr>
//         ${color !== null ? iconData : ""}
//         <td style="color: #989898"><b>${key}&nbsp</b></td>
//         <td><b>${value}</b></td>
//       </tr>
//     `;
//   }

//   // End table
//   tooltipText += "</table>";
//   return tooltipText;
// }


//Build the legend based on the approach types
const buildLegend = () => {
  let header = "Approach Types";

  // let position = "topright";
  let categories = [{ "label": "VFR", "color": "#db11fa" }, { "label": "VFR-Missed", "color": "#fc7521" }, { "label": "IFR", "color": "#21a1fc" }, { "label": "IFR-Missed", "color": "#f8fc21" }];

  //Create the legend container and return
  let legend = <div className="map-overlay " id="legend" style={{ height: 150 }}>
    {/* Title */}
    <strong style={{ color: "black" }}>{header}</strong>
    <br />

    {/* Items/Icons */}
    {categories.map((cate, i) => {
      let category = cate.label;
      const iconColor = { color: cate.color };

      return (
        <div key={`legend-icon-${i}`}>
          <i
            className="fa fa-window-minimize vector-legend-icon-fonts"
            style={iconColor}
          >
            <span className="legend-icon-inner-text">{category}</span>
          </i>
        </div>
      );
    })}

  </div>

  return legend;
}

class PitchToggle {

  constructor({ bearing = -20, pitch = 70, minpitchzoom = null }) {
    this._bearing = bearing;
    this._pitch = pitch;
    this._minpitchzoom = minpitchzoom;
  }

  onAdd(map) {
    this._map = map;
    let _this = this;

    this._btn = document.createElement('button');
    this._btn.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d';
    this._btn.type = 'button';
    this._btn['aria-label'] = 'Toggle Pitch';
    this._btn.onclick = function () {


      if (map.getPitch() === 0) {
        let options = { pitch: _this._pitch, bearing: _this._bearing };
        if (_this._minpitchzoom && map.getZoom() > _this._minpitchzoom) {
          options.zoom = _this._minpitchzoom;
        }
        map.easeTo(options);
        _this._btn.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-2d';
      }
      else {
        map.easeTo({ pitch: 0, bearing: 0 });
        _this._btn.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d';
      }
    };


    this._container = document.createElement('div');
    this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
    this._container.appendChild(this._btn);

    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }

}

class HomeReset {

  constructor({ helipadLong = -95.71, helipadLat = 37.09 }) {
    this._helipadLong = helipadLong;
    this._helipadLat = helipadLat;
  }

  onAdd(map) {
    this._map = map;
    let _this = this;

    this._btn = document.createElement('button');
    this._btn.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-home-reset';
    this._btn.type = 'button';
    this._btn['aria-label'] = 'Toggle Pitch';
    this._btn.onclick = function () {

      let markerCoord = [-95.71, 37.09];
      // console.log(markerCoord);

      map.flyTo({
        center: markerCoord,
        zoom: 3.5,
        essential: true // this animation is considered essential with respect to prefers-reduced-motion
      });
    };


    this._container = document.createElement('div');
    this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
    this._container.appendChild(this._btn);

    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }

}


const ApproachFlight3DMap = ({
  mapId,
  startLoc,
  zoom,
}) => {
  // Obtain reference to global data context consumer
  const dataContext = useContext(DataContext);
  const userContext = useContext(UserContext);

  const classes = useStyles();

  

  // const defaultBasemap = dataContext.darkMode ? "dark-v10" : "light-v10";
  const defaultBasemap = dataContext.baseMaps[dataContext.darkMode ? "Dark Basemap" : "Light Basemap"];
  // const basemap = BASEMAPS[BASEMAPS.findIndex(item => item.id === defaultBasemap)];
  const defaultBasemapStyle = dataContext.defaultBasemapStyleJson;
  defaultBasemapStyle['sources']['raster-tiles']['tiles'][0] = defaultBasemap.url;
  defaultBasemapStyle['sources']['raster-tiles']['attribution'] = defaultBasemap.attribution;

  // const satelliteImgStyle = 'mapbox://styles/mapbox-map-design/ckhqrf2tz0dt119ny6azh975y';
  // const satelliteImgStyle = 'mapbox://styles/mapbox/satellite-streets-v12';
  const satelliteImgStyle = 'mapbox://styles/mapbox/satellite-v9';
  // const satelliteImgStyle = 'mapbox://styles/mapbox/streets-v12';

  const {
    width: resizeDetectorWidth,
    height: resizeDetectorHeight,
    ref: resizeDetectorRef
  } = useResizeDetector();


  const mapContainer = useRef(null);
  const map = useRef(null);
  // const draw = useRef(null);
  const airportIDRef = useRef(null);

  const [lng, setLng] = useState(startLoc[0]);
  const [lat, setLat] = useState(startLoc[1]);
  const [zoomState, setZoomState] = useState(zoom);
  const [legend, setLegend] = useState(null);
  const [checkControl, setCheckControl] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [stylesLoaded, setStylesLoaded] = useState(false);
  const [helipadsLoaded, setHelipadsLoaded] = useState(false);
  const [runwayJsonUrl, setRunwayJsonUrl] = useState(null);
  const [helipadJsonUrl, setHelipadJsonUrl] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const isMounted = useRef(false);


  const mapStyles = {
    width: "100%",
    height: "100%",
  };

  const mapParentStyle = {
    position: "relative",
    width: "100%",
    height: "100%"
  };

  // const getRandomColor = () => {
  //   var letters = '0123456789ABCDEF';
  //   var color = '#';
  //   for (var i = 0; i < 6; i++) {
  //     color += letters[Math.floor(Math.random() * 16)];
  //   }
  //   return color;
  // }

  // Detect when this component is unmounted
  useEffect(() => {
    (async () => {
      try {
        const runTemp = await getDocument(userContext.user, "RUNWAYS")
        setRunwayJsonUrl(runTemp.preSignedUrl)
        console.log(runTemp)
        const helTemp = await getDocument(userContext.user, "APHELIPADS")
        setHelipadJsonUrl(helTemp.preSignedUrl)
        setIsLoaded(true)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    })();
    // fetchData();
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    }
  }, []);

  // const fetchData = async () => {
  //   try {
      
  // }


  const findThreshold = (array, target, low = 0, high = array.length - 1) => {
    if (low > high) {
      return array[high]
    }
    const mid = Math.floor((low + high) / 2)

    if (target < array[mid]) {
      return findThreshold(array, target, low, mid - 1)
    }
    else if (target > array[mid]) {
      return findThreshold(array, target, mid + 1, high)
    }
    else {
      return array[mid]
    }
  }

  const getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  ///Group function with multiple properties. See https://stackoverflow.com/questions/46794232/group-objects-by-multiple-properties-in-array-then-sum-up-their-values
  const groupBy = (array, f) => {
    let groups = {};
    array.forEach(function (o) {
      var group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    })
  }

  const addApproach3DLayer = () => {


    if (!airportIDRef.current)
      airportIDRef.current = dataContext.helipadInfo;

    // let airportId = "87NJ";
    // let airportId = dataContext.helipadInfo[2];
    let airportId = airportIDRef.current[2]
    // console.log(airportId);

    // let airportId = "DC52";
    const operator = userContext.user.attributes['custom:operator'];

    getAirportApproachFlights(userContext.user, operator, airportId).then((results) => {

      // console.log(userContext.user + "," + operator + "," + airportId);

      // console.log(results);
      if (results == null) {
        dataContext.setApproachQueryStatus(approachQueryNoDataStr);
        dataContext.setApproachData(null);
        return;
      }
      else {
        dataContext.setApproachQueryStatus("");
      }

      dataContext.setApproachQueryLoading(false);


      // const uniqueFlightIds = [...new Set(results.map((item) => item.flightid))];
      // console.log(uniqueFlightIds);
      // console.log(dataContext.approachSelectInfo);
      let startDate = new Date(dataContext.approachSelectInfo['startDate']);
      let endDate = new Date(dataContext.approachSelectInfo['endDate']);


      let approachDateInDateRange = results.filter(item => {
        let curTimeStamp = new Date(item.times_timestamp);
        return curTimeStamp >= startDate && curTimeStamp <= endDate;
      });
      // console.log(approachDateInDateRange);

      if (dataContext.approachSelectInfo['approachType'] != 'All') {
        let approachDataByType = approachDateInDateRange.filter(item => item.approach_type == (dataContext.approachSelectInfo['approachType']));
        approachDateInDateRange = approachDataByType;
      }
      // console.log(approachDateInDateRange);

      // console.log(dataContext.approachSelectInfo['approachSubtype']);

      if (dataContext.approachSelectInfo['approachSubtype'].length != 0) {

        let subtypes = dataContext.approachSelectInfo['approachSubtype'];

        let approachStableUnstable = approachDateInDateRange.filter(item => {

          return Object.keys(item).some(key => subtypes.includes(key) && item[key] === '1');
        });

        approachDateInDateRange = approachStableUnstable;

      }
      console.log(approachDateInDateRange);

      dataContext.setApproachData(approachDateInDateRange);

      let groupResultsByflightid_approachid = groupBy(approachDateInDateRange, function (item) {
        return [item.flightid, item.approach_id];
      });
      console.log(groupResultsByflightid_approachid);

      // let groupedApproachData = [];
      // groupResultsByflightid_approachid.forEach(gR => {
      //   gR.forEach(r => groupedApproachData.push(r) )
      // })

      // console.log(groupedApproachData);

      if (approachDateInDateRange.length > 0) {
        dataContext.setApproachQueryStatus("");
      }
      else {
        dataContext.setApproachQueryStatus(approachQueryNoDataStr);
        dataContext.setApproachData(null);

        //   console.log("AAAA");
        //   window.tb.clear(mapLayerIdCustom, true);
        //   if (map.current.getLayer(mapLayerIdCustom)) map.current.removeLayer(mapLayerIdCustom);
        //   return;
      }


      //////////////approachFlights is for 3d drawing////////////////////
      var approachFlights = approachDateInDateRange.reduce(function (a, b) {
        if (a[b['flightid']]) {
          a[b['flightid']].push({ long: b['flightstate_location_longitude'], lat: b['flightstate_location_latitude'], agl: b['final_agl'], msl: b['final_msl'], approach_type: b['approach_type'] })
        }
        else {
          a[b['flightid']] = [{ long: b['flightstate_location_longitude'], lat: b['flightstate_location_latitude'], agl: b['final_agl'], msl: b['final_msl'], approach_type: b['approach_type'] }]
        }
        return a
      }, {});

      console.log(approachFlights);
      // const flightsArray = Object.keys(approachFlights);

      let approachStr = "approaches";
      if (groupResultsByflightid_approachid.length == 1)
        approachStr = "approach";

      let statusStr = `Loaded ${groupResultsByflightid_approachid.length} ${approachStr}`
      dataContext.setApproachQueryStatus(statusStr)

      window.tb.clear(mapLayerIdCustom, true);

      if (map.current.getLayer(mapLayerIdCustom)) map.current.removeLayer(mapLayerIdCustom);


      var minLat = 100.0;
      var maxLat = -9999.0;
      var minLong = 1000.0;
      var maxLong = -9999.0;


      let mapcurrent = map.current; //Because onAdd function does not allow dot map.current
      map.current.addLayer({
        id: mapLayerIdCustom,
        type: 'custom',
        renderingMode: '3d',
        onAdd: function (mapcurrent, mbxContext) {
          // onAdd: function(map, gl) {

          // // Init Threebox inside the onAdd function
          window.tb = new Threebox(
            map.current,
            mbxContext, {
            defaultLights: true,
            enableSelectingObjects: true,
            enableTooltips: true,
            multiLayer: true, // this will create a default custom layer that will manage a single tb.update
          }
          );


          ///////////////Add Flights////////////////////
          var flight_data;
          var flight_id;
          var order = 0;

          for (const [key, value] of Object.entries(approachFlights)) {
            // console.log(key, value);
            flight_id = key;
            flight_data = value;

            var flight_xyz = flight_data.map(elem => {
              let xyz = [];
              xyz.push(parseFloat(elem.long));
              xyz.push(parseFloat(elem.lat));
              let z = parseFloat(elem.msl) * 0.3048; //Convert feet to meter
              // let z = parseFloat(elem.msl); //Convert feet to meter
              // let z = parseFloat(elem.agl); //Convert feet to meter
              xyz.push(z);

              // let returnThreshod = findThreshold(elevationThresholds, z);
              // let threshodIdx = elevationThresholds.indexOf(returnThreshod);

              // let colorValue = colorSchema[threshodIdx];
              //let colorValue = colorSchema[order];
              let colorValue;
              if (order == 0) colorValue = "#F00";
              else if (order == 1) colorValue = "#0F0";

              switch (elem.approach_type) {
                case "VFR":
                  colorValue = 0xdb11fa;
                  break;
                case "VFR-Missed":
                  colorValue = 0xfc7521;
                  break;
                case "IFR":
                  colorValue = 0x21a1fc;
                  break;
                case "IFR-Missed":
                  colorValue = 0xf8fc21;
                  break;
                default:
                  colorValue = getRandomColor();
              }

              // let colorValue = "#FF0";
              xyz.push(colorValue);

              return xyz;
            });
            order++;



            for (let i = 0; i < flight_xyz.length - 1; i++) {

              let firstPoint = flight_xyz[i];
              let secondPoint = flight_xyz[i + 1];
              var lineOptions = {
                geometry: [firstPoint, secondPoint],
                color: flight_xyz[i][3],
                width: 2 // random width between 1 and 2,
              }
              let lineMesh = window.tb.line(lineOptions);

              window.tb.add(lineMesh, flight_id);

            }


            let firstPoint = flight_xyz[0];
            let lastPoint = flight_xyz[flight_xyz.length - 1];

            let minFLong = Math.min(firstPoint[0], lastPoint[0]);
            let maxFLong = Math.max(firstPoint[0], lastPoint[0]);
            let minFLat = Math.min(firstPoint[1], lastPoint[1]);
            let maxFLat = Math.max(firstPoint[1], lastPoint[1]);

            // console.log(minFLong + "," + maxFLong + "," + minFLat + "," + maxFLat);

            if (minFLong < minLong) minLong = minFLong;
            if (maxFLong > maxLong) maxLong = maxFLong;
            if (minFLat < minLat) minLat = minFLat;
            if (maxFLat > maxLat) maxLat = maxFLat;


          } // For loop flightdata


        },

        render: function (gl, matrix) {
          window.tb.update();
        }
      });

      // let flyToX = flight_xyz[0][0];
      // let flyToY = flight_xyz[0][1];
      // // console.log(flyToX + ", " + flyToY);

      // map.current.flyTo({ center: [flyToX, flyToY], zoom: 14 });

      // console.log(minLong + ", " + minLat + ", " + maxLong + ", " + maxLat);

      if (airportId != "AAAAA") {

        if (maxLat > -90 && maxLat < 90) {
          map.current.fitBounds([
            [minLong, minLat], // southwestern corner of the bounds
            [maxLong, maxLat] // northeastern corner of the bounds
          ], { padding: 120 });
        }
      }

    }); //For get data service   
  }


  const addHelipadSite0 = () => {

    // if (airportIDRef.current) {
    //   airportIDRef.current = dataContext.approachSelectInfo['helipad'];
    // }

    if (!airportIDRef.current)
      airportIDRef.current = dataContext.helipadInfo;

    // let helipadLong = dataContext.approachSelectInfo['helipad'][0];
    // let helipadLat = dataContext.approachSelectInfo['helipad'][1];

    let helipadLong = airportIDRef.current[0];
    let helipadLat = airportIDRef.current[1];

    let markerCoord = [helipadLong, helipadLat];
    // console.log(markerCoord);

    ////////////////////////Load helipad as a image point layer//////////////////////////
    const addHelipadPointLayer = (lon, lat) => {
      if (map.current.getLayer(mapLayerIdHelipadPoint)) map.current.removeLayer(mapLayerIdHelipadPoint);
      if (map.current.getSource(mapResourceNameHelipadPoint)) map.current.removeSource(mapResourceNameHelipadPoint);

      // Add a data source containing one point feature.
      map.current.addSource(mapResourceNameHelipadPoint, {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': [{
            'type': 'Feature',
            'geometry': {
              'type': 'Point',
              'coordinates': [lon, lat]
            }
          }]
        }
      });

      // Add a layer to use the image to represent the data.
      map.current.addLayer({
        'id': mapLayerIdHelipadPoint,
        'type': 'symbol',
        'source': mapResourceNameHelipadPoint, // reference the data source
        'layout': {
          'icon-image': mapImageIdHelipadPoint, // reference the image
          'icon-size': [
            'interpolate', ['linear'],
            ['zoom'],
            5, 0.05,
            8, 0.07,
            12, 0.09,
            13, 0.1,
            14, 0.13
          ]
        }
      });
    }

    if (map.current.hasImage(mapImageIdHelipadPoint)) {
      addHelipadPointLayer(helipadLong, helipadLat);
    }
    else {
      map.current.loadImage(helipadPng, (error, image) => {
        if (error) throw error;

        if (!map.current.hasImage(mapImageIdHelipadPoint))
          map.current.addImage(mapImageIdHelipadPoint, image);

        addHelipadPointLayer(helipadLong, helipadLat);
      });
    }

    return markerCoord;

  }

  const convertLatLongStrToDouble = (valueStr) => {

    let valueNs = valueStr.split("-");
    let nw = valueNs[2].slice(-1);
    let latlonV = parseInt(valueNs[0]) + parseFloat(valueNs[1]) / 60 + parseFloat(valueNs[2].slice(0, -1)) / 3600;
    let latlon = Math.round(latlonV * 1000000) / 1000000;

    if (nw === "W" || nw === "S")
      latlon = (-1) * latlon


    return latlon;
  }

  const buildFeaturedTooltip = (row, { color = null }) => {
    // Build tool tip off all properties
    var tooltipText = "";

    // Start table
    tooltipText += "<table>";
    for (const [key, value] of Object.entries(row)) {
      let iconData = `<td><i class="fa fa-square tooltip-icon-fonts" style="color: ${color}; margin-right: 5px;"></i></td>`;
      tooltipText += `
      <tr>
        ${color !== null ? iconData : ""}
        <td style="color: #989898"><b>${key}&nbsp</b></td>
        <td><b>${value}</b></td>
      </tr>
    `;
    }

    // End table
    tooltipText += "</table>";
    return tooltipText;
  }

  const size = 100;

  // This implements `StyleImageInterface`
  // to draw a pulsing dot icon on the map.
  const pulsingDot = {
    width: size,
    height: size,
    data: new Uint8Array(size * size * 4),

    // When the layer is added to the map,
    // get the rendering context for the map canvas.
    onAdd: function () {
      const canvas = document.createElement('canvas');
      canvas.width = this.width;
      canvas.height = this.height;
      this.context = canvas.getContext('2d');
    },

    // Call once before every frame where the icon will be used.
    render: function () {
      const duration = 1000;
      const t = (performance.now() % duration) / duration;

      const radius = (size / 2) * 0.3;
      const outerRadius = (size / 2) * 0.7 * t + radius;
      const context = this.context;

      // Draw the outer circle.
      context.clearRect(0, 0, this.width, this.height);
      context.beginPath();
      context.arc(
        this.width / 2,
        this.height / 2,
        outerRadius,
        0,
        Math.PI * 2
      );
      context.fillStyle = `rgba(255, 200, 200, ${1 - t})`;
      context.fill();

      // Draw the inner circle.
      context.beginPath();
      context.arc(
        this.width / 2,
        this.height / 2,
        radius,
        0,
        Math.PI * 2
      );
      context.fillStyle = 'rgba(255, 100, 100, 1)';
      context.strokeStyle = 'white';
      context.lineWidth = 2 + 4 * (1 - t);
      context.fill();
      context.stroke();

      // Update this image's data with data from the canvas.
      this.data = context.getImageData(
        0,
        0,
        this.width,
        this.height
      ).data;

      // Continuously repaint the map, resulting
      // in the smooth animation of the dot.
      map.current.triggerRepaint();

      // Return `true` to let the map know that the image was updated.
      return true;
    }
  };


  const addHelipadSite = () => {

    // if (airportIDRef.current) {
    //   airportIDRef.current = dataContext.approachSelectInfo['helipad'];
    // }

    // if (!airportIDRef.current)
    //   airportIDRef.current = dataContext.helipadInfo;

    // let helipadLong = dataContext.approachSelectInfo['helipad'][0];
    // let helipadLat = dataContext.approachSelectInfo['helipad'][1];

    // let helipadLong = airportIDRef.current[0];
    // let helipadLat = airportIDRef.current[1];

    // let markerCoord = [helipadLong, helipadLat];
    // console.log(markerCoord);

    ////////////////////////Load helipad as a image point layer//////////////////////////
    const addHelipadPointLayer = () => {
      if (map.current.getLayer(mapLayerIdHelipadPoint)) map.current.removeLayer(mapLayerIdHelipadPoint);
      if (map.current.getSource(mapResourceNameHelipadPoint)) map.current.removeSource(mapResourceNameHelipadPoint);

      // dataContext.setApproachQueryLoading(true);
      // dataContext.setApproachQueryStatus("Loaing helipads on the map");
      setHelipadsLoaded(false);

      const operator = userContext.user.attributes['custom:operator'];
      getAirportOperators(userContext.user, operator).then((existingHelipads) => {

        // console.log(existingHelipads);
        
        (async () => {
          console.log(helipadJsonUrl)
          await fetch(helipadJsonUrl).then((result) => {console.log(result)})
          
          await fetch(helipadJsonUrl).then((result) => result.json()).then((data) => {
            console.log(data);

            data["rowdata"] = [{ label: '', id: '', name: '', lat: '', lon: '', ele: '', tc: '' }, ...data["rowdata"]]


            let selectedHelipads = [...data["rowdata"].filter(row => {
              return (row["label"].trim() !== '' && existingHelipads.some(e => e.approach_icao_id == row["label"]))
            }).sort((a, b) => a['label'] > b['label'] ? 1 : -1)];

            // console.log(selectedHelipads);

            dataContext.setHelipadsOperator(selectedHelipads);

            let helipadGeoJson = {};
            helipadGeoJson["type"] = "FeatureCollection";

            let features = [];
            selectedHelipads.forEach(helipad => {
              let latS = helipad["lat"];
              let latV = convertLatLongStrToDouble(latS);

              let lonS = helipad["lon"];
              let lonV = convertLatLongStrToDouble(lonS);

              let feature = {};
              feature["type"] = 'Feature';

              let featureProp = {};
              featureProp['IDENT'] = helipad["label"];
              featureProp["Name"] = helipad["name"];
              featureProp["Elevation"] = helipad["ele"];
              featureProp["Type code"] = helipad["tc"];
              feature["properties"] = featureProp;

              let featureGeo = {};
              featureGeo["type"] = 'Point';
              featureGeo["coordinates"] = [lonV, latV];
              feature["geometry"] = featureGeo;

              features.push(feature);
            });

            helipadGeoJson["features"] = features;

            // Add a data source containing one point feature.
            map.current.addSource(mapResourceNameHelipadPoint, {
              'type': 'geojson',
              'data': helipadGeoJson
            });

            // Add a layer to use the image to represent the data.
            map.current.addLayer({
              'id': mapLayerIdHelipadPoint,
              'type': 'symbol',
              'source': mapResourceNameHelipadPoint, // reference the data source
              'layout': {
                'icon-image': mapImageIdHelipadPoint, // reference the image
                'icon-size': [
                  'interpolate', ['linear'],
                  ['zoom'],
                  5, 0.04,
                  8, 0.06,
                  12, 0.07,
                  13, 0.07,
                  14, 0.08
                ]
              }
            });

            // dataContext.setApproachQueryLoading(false);
            // dataContext.setApproachQueryStatus("");

            setHelipadsLoaded(true);

          });

        })();
      });

    }

    if (map.current.hasImage(mapImageIdHelipadPoint)) {
      addHelipadPointLayer();
    }
    else {
      map.current.loadImage(helipadPng, (error, image) => {
        if (error) throw error;

        if (!map.current.hasImage(mapImageIdHelipadPoint))
          map.current.addImage(mapImageIdHelipadPoint, image);

        addHelipadPointLayer();
      });
    }

  }


  useEffect(() => {
    if(runwayJsonUrl && helipadJsonUrl) {
    // if (isMounted.current) {
    // if (map.current) return; // Initialize map only once

    // Create a popup, but don't add it to the map yet.
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      maxWidth: "350px",
      anchor: 'bottom'
      // className: dataContext.darkMode ? "custom-map-tooltip-dark" : "custom-map-tooltip-light",
    });

    //Define the function to add source and add layer. This function will be called in map.on("load") and map.on("styledata") event
    const addDataLayer = () => {

      let sourceStatusMT = map.current.getSource(mapResourceNameDEM);
      if (sourceStatusMT === undefined) {
        map.current.addSource(mapResourceNameDEM, {
          'type': 'raster-dem',
          'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
          'tileSize': 512,
          'maxzoom': 14
        });
      }
      map.current.setTerrain({ 'source': mapResourceNameDEM, 'exaggeration': 1.0 });
      map.current.setFog({
        'range': [3, 16],
        'horizon-blend': 0.5,
        "color": "#b5d1ff",
      });

      // const userContext = useContext(UserContext);
      const operator = userContext.user.attributes['custom:operator'];

      // console.log(userContext.user + ", " + operator);
      
      let sourceStatusRunways = map.current.getSource(mapResourceNameRunways);
      if (sourceStatusRunways === undefined) {
        map.current.addSource(mapResourceNameRunways, {
          'type': 'geojson',
          'data': runwayJsonUrl
        });
      }

      addApproach3DLayer();

    } // For addLayer()

    if (!map.current) {
      //Basemap with predefined style
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: satelliteImgStyle,
        center: [lng, lat],
        zoom: zoomState,
        // pitch: 45,
        // bearing: 10,
        antialias: true,
        transformRequest: (url, resourceType) => {
          if (resourceType === 'Source' && url.startsWith('https://maptiles.asias.info')) {
            // console.log(url, userContext.user.signInUserSession.idToken.jwtToken)
            return {

              url: url,
              headers: { Authorization: userContext.user.signInUserSession.idToken.jwtToken }
            }
          }
        }
      });


      //Add controls
      map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');
      map.current.addControl(new mapboxgl.FullscreenControl(), 'top-right');
      map.current.addControl(new BasemapMapboxSwitchControl("basemap-checkControl-riskmap"));
      map.current.addControl(new PitchToggle({ pitch: 80, minpitchzoom: 12 }), "top-right");

      let helipadLong = dataContext.approachSelectInfo['helipad'][0];
      let helipadLat = dataContext.approachSelectInfo['helipad'][1];
      // console.log(helipadLong + "," + helipadLat);
      map.current.addControl(new HomeReset({ helipadLong, helipadLat }), "top-right");

    }

    if (map.current) {

      window.tb = new Threebox(
        map.current,
        map.current.getCanvas().getContext('webgl'), {
        defaultLights: true,
        enableSelectingObjects: true,
        enableTooltips: true,
        multiLayer: true, // this will create a default custom layer that will manage a single tb.update
      }
      );


      // Map event when the basemap style loads or changes.
      map.current.on('style.load', () => {

        // var mapLayer = map.current.getLayer(mapLayerId);
        var mapLayer = map.current.getLayer(mapLayerIdCustom);
        // console.log(mapLayer);
        if (typeof mapLayer === 'undefined') {

          addHelipadSite();

          addDataLayer();

          // let airportId = dataContext.helipadInfo[2];
          // console.log(airportId);


        }

        setStylesLoaded(true);

      });

      //Map event when the basemap was loaded
      map.current.on('load', () => {

        // /////////////////////////3D Building////////////////////////////
        // // Add 3D buildings and remove label layers to enhance the map
        // const layers = map.current.getStyle().layers;
        // for (const layer of layers) {
        //     if (layer.type === 'symbol' && layer.layout['text-field']) {
        //         // remove text labels
        //         map.current.removeLayer(layer.id);
        //     }
        // }

        // map.current.addLayer({
        //     'id': '3d-buildings',
        //     'source': 'composite',
        //     'source-layer': 'building',
        //     'filter': ['==', 'extrude', 'true'],
        //     'type': 'fill-extrusion',
        //     'minzoom': 15,
        //     'paint': {
        //         'fill-extrusion-color': '#aaa',

        //         // use an 'interpolate' expression to add a smooth transition effect to the
        //         // buildings as the user zooms in
        //         'fill-extrusion-height': [
        //             'interpolate',
        //             ['linear'],
        //             ['zoom'],
        //             15,
        //             0,
        //             15.05,
        //             ['get', 'height']
        //         ],
        //         'fill-extrusion-base': [
        //             'interpolate',
        //             ['linear'],
        //             ['zoom'],
        //             15,
        //             0,
        //             15.05,
        //             ['get', 'min_height']
        //         ],
        //         'fill-extrusion-opacity': 1
        //     }
        // });
        // //////////////////////////////////////////////////////////////////////

        //             let sourceStatusMT = map.current.getSource(mapResourceNameDEM);
        // if (sourceStatusMT === undefined) {
        //   map.current.addSource(mapResourceNameDEM, {
        //     'type': 'raster-dem',
        //     'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
        //     'tileSize': 512,
        //     'maxzoom': 14
        //   });
        // }
        // map.current.setTerrain({ 'source': mapResourceNameDEM, 'exaggeration': 1.0 });
        // map.current.setFog({
        //   'range': [3, 16],
        //   'horizon-blend': 0.5,
        //   "color": "#b5d1ff",
        // });

        addDataLayer();

        // setMap(map);
        // map.resize();

        // Create legend
        const legend = buildLegend();
        setLegend(legend);


        map.current.on('mousemove', (e) => {
          // 	console.log(e.point.x + ", " + e.point.y);

          let feat = window.tb.queryRenderedFeatures(e.point);
          if (feat.length != 0) {

            // console.log(feat);
            map.current.getCanvas().style.cursor = 'pointer';


            let layerInfo = feat[0].object.layer;

            // console.log(layerInfo);

            // let result = flightInfo.find(item => item.flightId === layerInfo);
            // let center = result["center"];

            let pointLonlat = e.lngLat;
            // console.log(pointLonlat);
            let lng = pointLonlat["lng"];
            let lat = pointLonlat["lat"];
            // console.log(lng + ", " + lat);

            // console.log(feat[0]);

            // console.log(map.getCanvas().style);
            let desc = "Flight id: " + layerInfo;
            popup.setLngLat([lng, lat]).setHTML(desc).addTo(map.current);

          }
          else {
            map.current.getCanvas().style.cursor = '';
            if (popup) popup.remove();
          }


        });

        map.current.on('click', [mapLayerIdHelipadPoint, mapLayerIdHelipadPointAni], (e) => {

          popup.remove();

          // Change the cursor style as a UI indicator.
          map.current.getCanvas().style.cursor = 'pointer';

          // Extract features
          const { properties, geometry } = e.features[0];

          // Copy coordinates array.
          const coordinates = geometry.coordinates.slice();

          let fieldsOfInterest = {
            "IDENT": properties["IDENT"],
            "Name": properties["Name"],
            "Elevation": properties["Elevation"],
            "Type code": properties["Type code"]
          };

          let tooltipHTML = buildFeaturedTooltip(fieldsOfInterest, { color: "#666" });

          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          // Populate the popup and set its coordinates
          // based on the feature found.
          popup.setLngLat(coordinates).setHTML(tooltipHTML).addTo(map.current);
        });

        // map.current.on('mouseleave', mapLayerIdHelipadPoint, () => {
        //   map.current.getCanvas().style.cursor = '';
        //   popup.remove();
        // });


        map.current.on('move', () => {
          setLng(map.current.getCenter().lng.toFixed(4));
          setLat(map.current.getCenter().lat.toFixed(4));
          setZoomState(map.current.getZoom().toFixed(2));
        });

        setMapLoaded(true);
      });

      map.current.on('click', mapLayerIdCustom, (e) => {
        console.log(e);
      });

      // Condition is only satisfied when data is loaded and styles are applied
      // This means that layers should only be removed/added here if a filter is applied or removed after the initial map creation
      // if (mapLoaded && stylesLoaded) {
      //   addDataLayer();
      // }

    }
    // }//for if mounted

    // Unmount post-processing hook
    return () => {
      // TODO Cleanup objects as needed
      window.tb.dispose();

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [dataContext.baseData, dataContext.filteredData]);
    }
  }, [runwayJsonUrl, helipadJsonUrl]);

  // useEffect(() => {
  //   // Resize map to fix viewport scaling when parent container is resized
  //   if (map.current) {
  //     map.current.resize();
  //   }

  // }, [resizeDetectorWidth, resizeDetectorHeight]);


  useEffect(() => {
    // Resize map to fix viewport scaling when parent container is resized
    if (map.current) {
      map.current.resize();
    }

  }, [resizeDetectorWidth, resizeDetectorHeight]);


  // function toggleSidebar(id) {
  //   const elem = document.getElementById(id);
  //   // Add or remove the 'collapsed' CSS class from the sidebar element.
  //   // Returns boolean "true" or "false" whether 'collapsed' is in the class list.
  //   const collapsed = elem.classList.toggle('collapsed');
  //   const padding = {};
  //   // 'id' is 'right' or 'left'. When run at start, this object looks like: '{left: 300}';
  //   padding[id] = collapsed ? 0 : 300; // 0 if collapsed, 300 px if not. This matches the width of the sidebars in the .sidebar CSS class.
  //   // Use `map.easeTo()` with a padding option to adjust the map's center accounting for the position of sidebars.
  //   map.easeTo({
  //   padding: padding,
  //   duration: 1000 // In ms. This matches the CSS transition duration property.
  //   });
  //   }

  //For fly to helipad
  useEffect(() => {
    // if (dataContext.DEBUG_MODE)
    // console.log(dataContext.approachSelectInfo);

    // Store airportID into the airportIDRef ref when approachSelectInfo updates
    // if (airportIDRef.current) {
    //   airportIDRef.current = dataContext.approachSelectInfo['helipad'];
    // }

    airportIDRef.current = dataContext.approachSelectInfo['helipad'];

    if (map.current && dataContext.approachSelectInfo['helipad'].length > 0) {
      // if (map.current > 0) {

      let helipadLong = dataContext.approachSelectInfo['helipad'][0];
      let helipadLat = dataContext.approachSelectInfo['helipad'][1];
      let helipadIdent = dataContext.approachSelectInfo['helipad'][2];
      let helipadName = dataContext.approachSelectInfo['helipad'][3];
      let helipadElevator = dataContext.approachSelectInfo['helipad'][4];
      let helipadTypeCode = dataContext.approachSelectInfo['helipad'][5];

      // if (airportId == "AAAAA")
      //   return;

      let markerCoord = [helipadLong, helipadLat];
      // console.log(markerCoord);


      ///////////////////////Load helipad as a image point layer//////////////////////////
      const addHelipadPointLayer = (lon, lat) => {
        if (map.current.getLayer(mapLayerIdHelipadPointAni)) map.current.removeLayer(mapLayerIdHelipadPointAni);
        if (map.current.getSource(mapResourceNameHelipadPointAni)) map.current.removeSource(mapResourceNameHelipadPointAni);

        // Add a data source containing one point feature.
        map.current.addSource(mapResourceNameHelipadPointAni, {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': [{
              'type': 'Feature',
              'geometry': {
                'type': 'Point',
                'coordinates': [lon, lat]
              },
              'properties': {
                'IDENT': helipadIdent,
                "Name": helipadName,
                "Elevation": helipadElevator,
                "Type code": helipadTypeCode
              },
            }]
          }
        });

        // Add a layer to use the image to represent the data.
        map.current.addLayer({
          'id': mapLayerIdHelipadPointAni,
          'type': 'symbol',
          'source': mapResourceNameHelipadPointAni, // reference the data source
          'layout': {
            'icon-image': mapImageIdHelipadPointAni, // reference the image
            'icon-allow-overlap': true,
            'icon-size': [
              'interpolate', ['linear'],
              ['zoom'],
              5, 0.001,
              6, 0.09,
              7, 0.1,
              8, 0.12,
              12, 0.14,
              13, 0.15,
              14, 0.16
            ]
          }
        });
      }

      if (map.current.hasImage(mapImageIdHelipadPointAni)) {
        addHelipadPointLayer(helipadLong, helipadLat);
      }
      else {
        map.current.loadImage(geohelipadPng, (error, image) => {
          if (error) throw error;
          map.current.addImage(mapImageIdHelipadPointAni, image, { pixelRatio: 2 });
          addHelipadPointLayer(helipadLong, helipadLat);
        });
      }

      // map.current.addImage(mapImageIdHelipadPointAni, pulsingDot, { pixelRatio: 2 });

      // let markerCoord = addHelipadSite();
      // console.log(markerCoord);

      ////////////////////////End of loading helipad as image point layer
      let airportId = dataContext.approachSelectInfo['helipad'][2];
      // console.log(airportId);

      if (airportId != "AAAAA") {
        map.current.flyTo({
          center: markerCoord,
          zoom: 8,
          essential: true // this animation is considered essential with respect to prefers-reduced-motion
        });

        ////////////////////////Add 3D flight approach type line segments////////////////////
        addApproach3DLayer();
      }
    }

  }, [dataContext.approachSelectInfo]);

  /* //For fly to helipad
 useEffect(() => {
    if (dataContext.DEBUG_MODE)
      console.log(dataContext.helipadInfo);

    if (map.current && dataContext.helipadInfo.length > 0) {

      let markerCoord = [dataContext.helipadInfo[0], dataContext.helipadInfo[1]];

      ////////////////////////Load helipad as a image point layer//////////////////////////
      const addHelipadPointLayer = (lon, lat) => {
        if (map.current.getLayer(mapLayerIdHelipadPoint)) map.current.removeLayer(mapLayerIdHelipadPoint);
        if (map.current.getSource(mapResourceNameHelipadPoint)) map.current.removeSource(mapResourceNameHelipadPoint);

        // Add a data source containing one point feature.
        map.current.addSource(mapResourceNameHelipadPoint, {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': [{
              'type': 'Feature',
              'geometry': {
                'type': 'Point',
                'coordinates': [lon, lat]
              }
            }]
          }
        });

        // Add a layer to use the image to represent the data.
        map.current.addLayer({
          'id': mapLayerIdHelipadPoint,
          'type': 'symbol',
          'source': mapResourceNameHelipadPoint, // reference the data source
          'layout': {
            'icon-image': mapImageIdHelipadPoint, // reference the image
            'icon-size': [
              'interpolate', ['linear'],
              ['zoom'],
              5, 0.05,
              8, 0.07,
              12, 0.09,
              13, 0.1,
              14, 0.13
            ]
          }
        });
      }

      if (map.current.hasImage(mapImageIdHelipadPoint)) {
        addHelipadPointLayer(dataContext.helipadInfo[0], dataContext.helipadInfo[1]);
      }
      else {
        map.current.loadImage(helipadPng, (error, image) => {
          if (error) throw error;
          map.current.addImage(mapImageIdHelipadPoint, image);
          addHelipadPointLayer(dataContext.helipadInfo[0], dataContext.helipadInfo[1]);
        });
      }

      ////////////////////////End of loading helipad as image point layer//////////////////

      map.current.flyTo({
        center: markerCoord,
        zoom: 8,
        essential: true // this animation is considered essential with respect to prefers-reduced-motion
      });

      ////////////////////////Add 3D flight approach type line segments////////////////////
      addApproach3DLayer();
    }

  }, [dataContext.helipadInfo]);
*/

  return (
    <div ref={resizeDetectorRef} style={mapParentStyle}>
      {/*<Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>*/}

      {dataContext.DEBUG_MODE && (
        <div className="mapbox-custom-sidebar">
          <div>
            Longitude: {lng} | Latitude: {lat} | Zoom: {zoomState}
          </div>
        </div>
      )}

      <div id={mapId} ref={mapContainer} style={mapStyles}>

        {/*<ApproachHelipadsBar />*/}
        {/*
        <div id="left" className={leftBarActive ? bottomBarActive ? "sidebar with-bottom flex-sidebar-contents left" : "sidebar full-size flex-sidebar-contents left": bottomBarActive ?  "sidebar with-bottom flex-sidebar-contents left shrunk collapsed" : "sidebar full-size flex-sidebar-contents left collapsed"}>
          <div className="sidebar-over rounded-rect flex-center">
            <div className="sidebar-content">

              <h5 className="registration-h5">Search Criteria</h5>
              <GeoSearchHelipadsRadiusBar />
              <h5 className="registration-h5">Additional Filters</h5>
              <SearchMyFlights setSearchObj={setSearchObj}/>
            </div>
            <div className="sidebar-toggle rounded-rect left" onClick={() => setLeftBarActive(!leftBarActive)}>
            {leftBarActive ? <>&#9666;</> : <>&#9656;</> }
            </div>
          </div>
        </div>*/}
        {/*
        <div id="bottom" className={bottomBarActive ? "bottombar flex-bottombar-contents bottom" : "bottombar flex-bottombar-contents bottom collapsed" }>
          <div className="bottombar-over rounded-rect flex-center">
            <div className="bottombar-content rounded-rect flex-bottombar-contents">

              <h5 className="registration-h5">Results</h5>
              
              
              <DataBrowser
          primaryTargetKey="searched_exist_flights"
          primaryCol = "flight_id"
          defaultRowsPerPage = {5}
          decorateNulls
          isDivContainer={true}
          onRowSelected={handleRowSelected}
          onRowHovered={handleRowHovered}
          columnsOfInterest={[
            buildDataBrowserColumn({ col: "flightmetadata_rotorcraft_aircraftregistration", alias: "Tail", width: 10, enableHeaderOptions:true }),
            buildDataBrowserColumn({ col: "flightmetadata_startisodate", alias: "Start Time", width: 50,  enableHeaderOptions:true }),
            // buildDataBrowserColumn({ col: "flightmetadata_endisodate", alias: "End Time", width: 50,modifier: dateTimeHandler  }),
            buildDataBrowserColumn({ col: "exceedance_total_max_text", alias: "Exceedances", width: 12, colorFn: exceedanceColorFn, textStyle: cellTextStyle, enableHeaderOptions:true }),
            buildDataBrowserColumn({ col: "flightmetadata_risks_maxobstaclelevelencountered_text", alias: "Obstacles", width: 12, colorFn: obstacleColorFn, textStyle: cellTextStyle, enableHeaderOptions:true }),
            buildDataBrowserColumn({ col: "loss_of_control_total_max_text", alias: "Loss of Control", width: 12, colorFn: locColorFn, textStyle: cellTextStyle, enableHeaderOptions:true  }),
            buildDataBrowserColumn({ col: "dq_errors", alias: "Data Quality", width: 6, colorFn: dqColorFn, textStyle: cellTextStyle, enableHeaderOptions:true, onCellClick: handleDQSelected}),
            // buildDataBrowserColumn({ col: "flight_id", alias: "Flight Id", width: 40 })
          ]}
        />
        
            </div>
            <div className="bottombar-toggle rounded-rect bottom" onClick={() => setBottomBarActive(!bottomBarActive)}>
            {bottomBarActive? <>&#9662;</> : <>&#9652;</>}
            </div>
          </div>
        </div> */}

        {/*checkControl*/}
        {/*gridLabelControl*/}
        {legend}
      </div>
      <div style={{
        position: 'absolute',
        top: -120,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        pointerEvents: 'none'
      }}>
        {helipadsLoaded && isLoaded ? null : <div><CircularProgress sx={{ color: "white" }} size={50} /> <Typography variant="body2" sx={{ color: "white" }} gutterBottom> Loading helipads...</Typography></div>}
      </div>

    </div>

  )
}

ApproachFlight3DMap.propTypes = propTypes;
ApproachFlight3DMap.defaultProps = defaultProps;

export default ApproachFlight3DMap;
