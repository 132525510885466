import React, { useState, useEffect, useContext, useRef, useCallback } from 'react'
import DashboardNav from '../components/DashboardNav'
import {
  Search,
  Button,
  Dropdown,
  ErrorMessage
} from '@trussworks/react-uswds'
import './FleetManagement.css'
import { Link } from 'react-router-dom'
import { useTable, useFilters, useSortBy } from 'react-table'
import UserContext from '../components/UserContext'
import getTails from '../services/getTails'
import { matchSorter } from 'match-sorter'
import UploadPage from './UploadPage'
import Modal from 'react-bootstrap/Modal'
import getMissionsAndMapping from '../services/getMissionsAndMapping'
import hasRole from '../services/hasRole'
import getOperatorsList from '../services/getOperatorsList'
import FleetTable from '../components/FleetTable'

const FleetManagement = (props) => {


  const isMounted = useRef(false);
  // Handle API call
  const [fleet, setFleet] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState([])
  const [show, setShow] = useState(false);
  const [selectedTail, setSelectedTail] = useState();
  const [defaultMission, setDefaultMission] = useState();
  const [missionList, setMissionList] = useState();
  const [operatorsList, setOperatorsList] = useState();
  const [operator, setOperator] = useState();
  const [lastSortedOption, setLastSortedOption] = useState();

  const handleClose = () => setShow(false);


  // Handle user context
  const userContext = useContext(UserContext)

  var isDTO = hasRole(userContext, ['dto'])


  //Sorting requried
  const fleetListRef = useRef();
  fleetListRef.current = fleet;

  useEffect(() => {
    isMounted.current = true;

    // Operator may come in as a prop, or maybe assigned to the user as an operator, or be the one that a DTO impersonates when 
    // first signing on.  Populte from prop/attribute, then override if it's null later

    let inboundOperator = props.location && props.location.state && props.location.state.operator ? props.location.state.operator : userContext.user.attributes['custom:operator']

    if (isDTO) {
      //Get the mappings for the 
      // console.log("In DTO portion")
      getOperatorsList(userContext.user)
        .then((result) => {
          if (isMounted.current) {
            // setOperatorsList(result[0])
            // console.log(result[0])
            let filterList = result[0].filter(q => q.DTO == userContext.user.attributes['custom:dto']).map(q => q.Operator)
            // console.log(filterList)
            setOperatorsList(filterList)
            setOperator(inboundOperator ? inboundOperator : filterList[0])
          }
          // setDtoList(tempList)
        })
        .catch((error) => {
          setError(error)
        })
    } else {
      //User is not DTO, set their operator to their user operator
      setOperator(userContext.user.attributes['custom:operator']);
    }
    return () => {
      isMounted.current = false;
    }

  }, [])

  useEffect(() => {
    if (operator && isMounted.current) {
      // console.log("triggered use effect for " + operator)
      populatePage()
    }
  }, [operator])

  const populatePage = () => {
    //  console.log(operator)
    getTails(userContext.user, operator)
      .then((result) => {
        setFleet(result.Items)
        if (lastSortedOption) {
          handleSort(lastSortedOption)
        }
        //  console.log(result.Items)
        setIsLoaded(true)
      })
      .catch((error) => {
        // console.log(error)
        setError(error)
      })
    // Get the mission list here, we can call it asynchronously and have it populated before the user launches the upload button
    getMissionsAndMapping(userContext.user)
      .then(
        (result) => {
          // console.log(result)
          if (result) {
            setMissionList(result[0])
            // setFlights(result[1]);
            // setIsLoaded(true);
          }
          // console.log(result)
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        })


  }

  const uploadFlight = (rows) => {
    setSelectedTail(rows.tail)
    setDefaultMission(rows.default_mission_short)
    setShow(true)
    // console.log({rows})
    // userContext.updateTail(rows)

    // console.log({userContext})
    // console.log({rows})
    // console.log(rows.tail)

    // console.log(findValue(rows, 'tail'))

  }

  const handleSort = useCallback(sortBy => {
    // Doing multisort
    // console.log("Sort mate!")
    let sorted = fleetListRef.current.slice();
    sorted.sort((a, b) => {
      for (let i = 0; i < sortBy.length; ++i) {
        let sortBySplit = sortBy[0].id.split(".")
        return ('' + a[sortBySplit[1]]).localeCompare(b[sortBySplit[1]])
      }
      return 0;
    });
    setLastSortedOption(sortBy);
    setFleet(sorted);
  }, []);

  function onDtoOperatorChange(evt) {
    setOperator(evt.target.value);
  }

  // Returns the actual attribute
  const findValue = (rows, value) => {
    // console.log({rows})
    var attribute = null
    rows.value.forEach((row) => {
      // Set string values of Boolean type to actual Boolean for use in page logic
      if (row.Name === value) {
        attribute = row.Value === 'true' ? true : (row.Value === 'false' ? false : row.Value)
      }
    })
    return attribute
  }

  const columns = React.useMemo(
    () => [
      {
        accessor: '',
        id: '1',
        Cell: (rows) => {

          var operator = rows.row.original.operator
          var tail = rows.row.original.alias
          var recorder = rows.row.original.recorder_version
          var mission = rows.row.original.default_mission

          return (
            <div className="table-entry">
              <p><strong>Operator</strong> {operator} </p>
              <p><strong>Alias</strong> {tail} </p>
              <p><strong>Recorder Number</strong> {recorder} </p>
              <p><strong>Default Mission Type</strong> {mission} </p>

            </div>
          );
        }
      },
      {
        accessor: '',
        id: '2',
        Cell: (rows) => {

          var make = rows.row.original.icao_make
          var model = rows.row.original.icao_model
          var series = rows.row.original.icao_series

          return (
            <div className="table-entry">
              <p><strong>Aircraft</strong></p>
              <p><strong>Make</strong> {make} </p>
              <p><strong>Model</strong> {model} </p>
              <p><strong>Series</strong> {series} </p>
            </div>
          );
        }
      },
      {
        accessor: '',
        id: '3',
        Cell: (rows) => {

          var make = rows.row.original.recorder_make
          var model = rows.row.original.recorder_model
          var version = rows.row.original.recorder_version

          return (
            <div className="table-entry">
              <p><strong>Recorder</strong></p>
              <p><strong>Make</strong> {make} </p>
              <p><strong>Model</strong> {model} </p>
              <p><strong>Version</strong> {version} </p>
            </div>
          );
        }
      },
      {
        id: '4',
        show: isDTO ? false : true,
        Cell: (rows) => {
          return (

            // <Link to="/upload">
            <Button type="button" onClick={(e) => uploadFlight(rows.row.original)} outline>Upload Flight</Button>
            // </Link>                

          );
        }
      },
    ],
    []
  )













  return (
    <div>
      <DashboardNav />
      <div className="stretch_grid-container">

        <h1>Fleet Management</h1>
        {isDTO ?
          <div>
            <p style={{ display: '-webkit-inline-box' }}>Please select Operator for Fleet Management. <Dropdown style={{ width: '5em', height: '24px', marginLeft: '5px', paddingTop: '0px', marginTop: '0px', marginRight: '5px', paddingBottom: '0px' }}
              id="dtoOpDropDown" name="dtoOpDropDown"
              value={operator}
              onChange={evt => onDtoOperatorChange(evt, () => { })}>
              {
                operatorsList && (

                  operatorsList.map((data, index) => (
                    <option key={index} value={data}>
                      {data}
                    </option>
                  ))

                )

              }
            </Dropdown>.
            </p> </div>
          :
          <p>Manage your fleet</p>
        }
        <Link to={{ pathname: '/new-aircraft', state: { operator: operator } }}>
          <Button type="button" outline>Add aircraft</Button>
        </Link>


        <FleetTable columns={columns} data={fleet} onSort={handleSort} operator={operator} />

      </div>
      <Modal
        size="lg"
        show={show}
        scrollable={true}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title>Upload Flight Information</Modal.Title>
        </Modal.Header>
        <Modal.Body><UploadPage
          handleClose={() => handleClose()}
          tail={selectedTail}
          mission={defaultMission}
          missionList={missionList} />
        </Modal.Body>
      </Modal>
    </div>



  )
}



export default FleetManagement