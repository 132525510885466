import React, { useState, useEffect, useRef, useContext } from "react";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { DataContext } from "../DataContext";
import { useResizeDetector } from 'react-resize-detector';
import BasemapSwitchControl from "../controls/BasemapSwitchControl";
import PropTypes from 'prop-types';
import UserContext from "../UserContext";
import getDocument from "../../services/getDocument";

// Import Styles
import 'mapbox-gl/dist/mapbox-gl.css';
import 'font-awesome/css/font-awesome.min.css';
// import '../styles/css/fontello.css';
import './Map.css';



mapboxgl.accessToken = 'pk.eyJ1IjoiemhqbWFwYm94IiwiYSI6ImNpdDY2NjZtczAzYXgyc2xqbGp0MTI0cmUifQ.ZwiVYpuYfxANYXqBBYTzgQ';

const propTypes = {
  mapId: PropTypes.string.isRequired,
  startLoc: PropTypes.arrayOf(PropTypes.number),
  zoom: PropTypes.number,
};

const defaultProps = {
  startLoc: [-98.5795, 39.8283],
  zoom: 3,
};

const mapResourceName = 'raise-maps';
const mapLayerId = 'uasfm-current-layer';
const mapLayerName = 'uasfm-current-tiles';
const mapSoureLayerName = 'uasfm-current-tiles';
const minZoom = 8;
const maxZoom = 16;
const mapLayerFillOpacity = 0.67;
const mapLayerFillOutlineColor = 'hsl(0, 0%, 39%)';

const mapResourceNameM = 'raise-maps-merged';
const mapLayerNameM = 'uas-facility-merged';
const mapLayerIdM = 'uasfm-current-merged-layer';
// const mapSoureLayerNameM = 'uas-facility-merged';

const mapResourceNameMT = 'uasfm_current_merged_points.geojson';
// const mapLayerNameMT = 'uas-facility-merged-text';
const mapLayerIdMT = 'uasfm-current-merged-text-layer';
// const mapLayerMTField = 'AptCode';

const mapResourceNameGridT = 'uasfm_current_centroids';
const mapLayerIdGridT = 'uasfm_current_centroids-layer';
const mapLayerNameGridT = 'uasfm_current_centroids';
const mapSoureLayerNameGridT = 'uasfm_current_centroids';
// const mapLayerGridTextField = 'Ceiling';

const mapResourceNameHotspot = 'Class_Airspace_Hotspots.geojson';
// const mapLayerNameHotspot = 'Hotspot_Class_Airspace';
const mapLayerIdHotspot = 'Hotspot_Class_Airspace-layer';

const mapResourceNameHotspotT = 'Class_Airspace_Hotspot_Centroids.geojson';
// const mapLayerNameHotspotT = 'Hotspot_Class_Airspace_Centroids';
const mapLayerIdHotspotT = 'Hotspot_Class_Airspace_Centroids-layer';
// const mapLayerHotspotTextField = "SECTOR";

const mapResourceNameRunways = 'Runways.geojson';
// const mapLayerNameRunways = 'Runways';
const mapLayerIdRunways = 'Runways-layer';

const mapResourceNameAggre = 'faa-uas-aggregate-metrics.geojson';
// const mapLayerNameAggre = 'uas-aggregate';
const mapLayerIdAggre = 'uas-aggregate-layer';

const gridLabelZoomThreshold = 10;
const gridLabelOptions = [
  { name: "GRID_ID", label: "Grid ID", color: "#000000", checked: false },
  { name: "CEILING", label: "Ceiling", color: "#4A0078", checked: true },
  { name: "NMAC_RC", label: "Model Altitude Ceiling", color: "#000099", checked: false },
]

const uasSightingsColor = "#666";

const buildFeaturedTooltip = (row, { color = null }) => {
  // Build tool tip off all properties
  var tooltipText = "";

  // Start table
  tooltipText += "<table>";
  for (const [key, value] of Object.entries(row)) {
    let iconData = `<td><i class="fa fa-square tooltip-icon-fonts" style="color: ${color}; margin-right: 5px;"></i></td>`;
    tooltipText += `
      <tr>
        ${color !== null ? iconData : ""}
        <td style="color: #989898"><b>${key}&nbsp</b></td>
        <td><b>${value}</b></td>
      </tr>
    `;
  }

  // End table
  tooltipText += "</table>";
  return tooltipText;
}


//Build the legend based on the threat levels
const buildLegend = () => {
  let header = "Threat Levels";
  let headerOverlays = "Overlays";
  let categorySightings = "sUAS Sightings";

  // let position = "topright";
  let categories = [{ "label": "Low", "color": "#00ff00" }, { "label": "Medium", "color": "#ffff00" }, { "label": "High", "color": "#ff0000" }];

  //Create the legend container and return
  let legend = <div className="map-overlay " id="legend" style={{ height: 190 }}>
    {/* Title */}
    <strong style={{ color: "black" }}>{header}</strong>
    <br />

    {/* Items/Icons */}
    {categories.map((cate, i) => {
      let category = cate.label;
      const iconColor = { color: cate.color };

      return (
        <div key={`legend-icon-${i}`}>
          <i
            className="fa fa-circle vector-legend-icon-fonts"
            style={iconColor}
          >
            <span className="legend-icon-inner-text">{category}</span>
          </i>
        </div>
      );
    })}
    <br />
    <strong style={{ color: "black" }}>{headerOverlays}</strong>
    <br />
    <div>
      <i
        key={`legend-icon-sightings`}
        className="fa fa-circle vector-legend-icon-fonts"
        style={{ color: uasSightingsColor }}
      >
        <span className="legend-icon-inner-text">{categorySightings}</span>
      </i>
    </div>
  </div>

  return legend;
}

const UasRiskVectorTilesMap = ({
  mapId,
  startLoc,
  zoom,
}) => {
  // Obtain reference to global data context consumer
  const dataContext = useContext(DataContext);
  const contextUser = useContext(UserContext);
  // const defaultBasemap = dataContext.darkMode ? "dark-v10" : "light-v10";
  const defaultBasemap = dataContext.baseMaps[dataContext.darkMode ? "Dark Basemap" : "Light Basemap"];
  // const basemap = BASEMAPS[BASEMAPS.findIndex(item => item.id === defaultBasemap)];
  const defaultBasemapStyle = dataContext.defaultBasemapStyleJson;
  defaultBasemapStyle['sources']['raster-tiles']['tiles'][0] = defaultBasemap.url;
  defaultBasemapStyle['sources']['raster-tiles']['attribution'] = defaultBasemap.attribution;
  
  const {
    width: resizeDetectorWidth,
    height: resizeDetectorHeight,
    ref: resizeDetectorRef
  } = useResizeDetector();

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(startLoc[0]);
  const [lat, setLat] = useState(startLoc[1]);
  const [zoomState, setZoomState] = useState(zoom);
  const [legend, setLegend] = useState(null);
  const [checkControl, setCheckControl] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [stylesLoaded, setStylesLoaded] = useState(false);
  const [checkCtrlAirspace, setCheckCtrlAirspace] = useState(false);
  const [checkCtrlSightings, setCheckCtrlSightings] = useState(true);
  const [checkCtrlFacility, setCheckCtrlFacility] = useState(true);
  const [runwayJsonUrl, setRunwayJsonUrl] = useState(null)
  const [aggmetJsonUrl, setAggmetJsonUrl] = useState(null)
  const [uascurJsonUrl, setUascurJsonUrl] = useState(null)
  const [classairhotJsonUrl, setClassairhotJsonUrl] = useState(null)
  const [classaircenJsonUrl, setClassaircenJsonUrl] = useState(null)

  const [gridLabelControl, setGridLabelControl] = useState(null);
  const [checkedItems, setCheckedItems] = useState(gridLabelOptions);

  const isMounted = useRef(false);
  // const mapLoaded = useRef(false);
  // const stylesLoaded = useRef(false);

  const mapStyles = {
    width: "100%",
    height: "100%",
  };



  //Change event handle function for turn-on/turn off Hotspot airspace layer
  const handleCheckAirspaceChange = () => {

    //Change and set the state of the checkcontrol
    setCheckCtrlAirspace((state) => !state);
  }

  const handleCheckSightingsChange = () => {
    setCheckCtrlSightings((state) => !state);
  }

  const handleCheckFacilityChange = () => {
    setCheckCtrlFacility((state) => !state);
  }

  //Build check control JSX, set the default check status 
  const buildCheckControl = () => {
    let checkControl = (
      <div
        id="checkcontrol"
        className="checkcontrol-overlay"
        style={{ background: dataContext.darkMode ? "#121212" : "#fff", height: 80 }}
      >
        <div style={{ display: "block" }}>
          <form>
            <input
              key="airspace-checkbox"
              type="checkbox"
              className="checkcontrol-block"
              defaultChecked={checkCtrlAirspace}
              onChange={handleCheckAirspaceChange}
            />
            <span>Class Airspaces</span>
          </form>
        </div>
        <div style={{ display: "block" }}>
          <form>
            <input
              key="sightings-checkbox"
              type="checkbox"
              className="checkcontrol-block"
              defaultChecked={checkCtrlSightings}
              onChange={handleCheckSightingsChange}
            />
            <span>sUAS Sightings</span>
          </form>
        </div>
        <div style={{ display: "block" }}>
          <form>
            <input
              key="uasfacility-checkbox"
              type="checkbox"
              className="checkcontrol-block"
              defaultChecked={checkCtrlFacility}
              onChange={handleCheckFacilityChange}
            />
            <span>Grids</span>
          </form>
        </div>
      </div>
    );
    return checkControl;
  }

  const handleGridLabelChange = (index) => {

    //Copy the check status items to the local variable
    const newCheckboxes = [...checkedItems];

    //Make changes based on the index of the clicked item
    newCheckboxes[index]["checked"] = !newCheckboxes[index]["checked"];

    //Create the text format
    let textFieldFormat = [];
    newCheckboxes.forEach(item => {
      if (item.checked) {
        textFieldFormat.push(['get', item.name]);
        textFieldFormat.push({ 'text-color': item.color });
        textFieldFormat.push('\n\n');
      }
    });

    //Update the layer render property
    if (textFieldFormat.length > 0) {
      textFieldFormat.unshift("format");
      map.current.setLayoutProperty(mapLayerIdGridT, 'text-field', textFieldFormat);
      map.current.setLayoutProperty(mapLayerIdGridT, 'visibility', 'visible');
    }
    else {
      map.current.setLayoutProperty(mapLayerIdGridT, 'visibility', 'none');
    }

    //Updated the state of check status
    setCheckedItems(newCheckboxes);
  };

  const buildGridTextCheckControl = () => {
    let checkControl = <div className="grid-label-overlay " id="grid-label-checkcontrol">
      {
        gridLabelOptions.map((option, index) => (
          <div key={`grid-label-block-${index}`} style={{ display: "block" }}>
            <input
              key={`grid-lable-${index}`}
              type="checkbox"
              className="checkcontrol-block"
              defaultChecked={option.checked}
              onChange={() => handleGridLabelChange(index)}
            />
            <span className="check-inner-text" style={{ color: option.color }}>{option.label}</span>
          </div>
        ))
      }
    </div>;
    return checkControl;
  }


  useEffect(() => {

    //Update the visiblity of Hotspot airspace layers based on the status of the check control and basemap style changes 
    if (map.current) {
      if (checkCtrlAirspace) {
        map.current.setLayoutProperty(mapLayerIdHotspot, 'visibility', 'visible');
        map.current.setLayoutProperty(mapLayerIdHotspotT, 'visibility', 'visible');
      }
      else {
        map.current.setLayoutProperty(mapLayerIdHotspot, 'visibility', 'none');
        map.current.setLayoutProperty(mapLayerIdHotspotT, 'visibility', 'none');
      }
    }

  }, [checkCtrlAirspace]);

  useEffect(() => {

    if (map.current) {
      if (checkCtrlSightings) {
        map.current.setLayoutProperty(mapLayerIdAggre, 'visibility', 'visible');
      }
      else {
        map.current.setLayoutProperty(mapLayerIdAggre, 'visibility', 'none');
      }
    }

  }, [checkCtrlSightings]);

  useEffect(() => {

    if (map.current) {
      if (checkCtrlFacility) {
        map.current.setLayoutProperty(mapLayerId, 'visibility', 'visible');
      }
      else {
        map.current.setLayoutProperty(mapLayerId, 'visibility', 'none');
      }
    }

  }, [checkCtrlFacility]);
  // Detect when this component is unmounted
  useEffect(() => {
    fetchData();
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    }
  }, []);

  const fetchData = async () => {
    try {
      await getDocument(contextUser.user, "RUNWAYS").then((result) => {
        setRunwayJsonUrl(result.preSignedUrl)
      })
      await getDocument(contextUser.user, "UASAGGMETRIC").then((result) => {
        setAggmetJsonUrl(result.preSignedUrl)
      })
      await getDocument(contextUser.user, "UASCURRENTMERGED").then((result) => {
        setUascurJsonUrl(result.preSignedUrl)
      })
      await getDocument(contextUser.user, "CLASSAIRSPACEHOTSPOT").then((result) => {
        setClassairhotJsonUrl(result.preSignedUrl)
      })
      await getDocument(contextUser.user, "CLASSAIRSPACECENTROIDS").then((result) => {
        setClassaircenJsonUrl(result.preSignedUrl)
      })
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    if (isMounted.current) {
      // if (map.current) return; // Initialize map only once

      //Define the function to add source and add layer. This function will be called in map.on("load") and map.on("styledata") event

      const addDataLayer = () => {
        // console.log("Adding data layer for UAS risk vector tiles map...");
        let dataTarget = dataContext.filteredData ? dataContext.filteredData : dataContext.baseData;

        if (dataTarget) {
          // Extract data and lookup table from gridsumm data in data target
          const { gridsumm: { data: gridsummData, lookup: gridsummLookup } } = dataTarget;
          let targetGrids = dataContext.groupBy(gridsummData, gridsummLookup.gridid);

          // Extract data and lookup table from gridsumm data in data target
          const { uas_sightings: { data: sightingsData, lookup: sightingsLookup } } = dataTarget;
          let targetSightings = dataContext.groupBy(sightingsData, sightingsLookup.id);

          // console.log("Target grids to filter by in map:", targetGrids);

          let sourceStatusRunways = map.current.getSource(mapResourceNameRunways);
          
          if (sourceStatusRunways === undefined) {
            
            map.current.addSource(mapResourceNameRunways, {
              'type': 'geojson',
              'data': runwayJsonUrl
            });
          }

          let sourceStatusAggre = map.current.getSource(mapResourceNameAggre);
          if (sourceStatusAggre === undefined) {
            map.current.addSource(mapResourceNameAggre, {
              'type': 'geojson',
              'data': aggmetJsonUrl
            });
          }

          //Check the map resource status
          let sourceStatus = map.current.getSource(mapResourceName);
          //Only add the resource when the defined resource doesn't exist
          if (sourceStatus === undefined) {
            map.current.addSource(mapResourceName, {
              'type': 'vector',
              'tiles': [
                `https://maptiles.asias.info/${process.env.REACT_APP_USER_BRANCH}/${mapLayerName}%2F{z}%2F{x}%2F{y}.pbf`
              ],
              'minzoom': minZoom,
              'maxzoom': maxZoom
            });
          }

          let sourceStatusGridT = map.current.getSource(mapResourceNameGridT);
          if (sourceStatusGridT === undefined) {
            map.current.addSource(mapResourceNameGridT, {
              'type': 'vector',
              'tiles': [
                `https://maptiles.asias.info/${process.env.REACT_APP_USER_BRANCH}/${mapLayerNameGridT}%2F{z}%2F{x}%2F{y}.pbf`
              ]
            });
          }

          let sourceStatusM = map.current.getSource(mapResourceNameM);
          if (sourceStatusM === undefined) {
            map.current.addSource(mapResourceNameM, {
              'type': 'vector',
              'tiles': [
                `https://maptiles.asias.info/${process.env.REACT_APP_USER_BRANCH}/${mapLayerNameM}%2F{z}%2F{x}%2F{y}.pbf`
              ]
            });
          }

          let sourceStatusMT = map.current.getSource(mapResourceNameMT);
          
          if (sourceStatusMT === undefined) {
            map.current.addSource(mapResourceNameMT, {
              'type': 'geojson',
              'data': uascurJsonUrl
            });
          }

          let sourceStatusHotspot = map.current.getSource(mapResourceNameHotspot);
          if (sourceStatusHotspot === undefined) {
            map.current.addSource(mapResourceNameHotspot, {
              'type': 'geojson',
              'data': classairhotJsonUrl
            });
          }

          let sourceStatusHotspotT = map.current.getSource(mapResourceNameHotspotT);
          if (sourceStatusHotspotT === undefined) {
            map.current.addSource(mapResourceNameHotspotT, {
              'type': 'geojson',
              'data': classaircenJsonUrl
            });
          }

          // Remove layers before adding new layers
          if (map.current.getLayer(mapLayerId)) map.current.removeLayer(mapLayerId);
          if (map.current.getLayer(mapLayerIdGridT)) map.current.removeLayer(mapLayerIdGridT);
          if (map.current.getLayer(mapLayerIdM)) map.current.removeLayer(mapLayerIdM);
          if (map.current.getLayer(mapLayerIdMT)) map.current.removeLayer(mapLayerIdMT);
          if (map.current.getLayer(mapLayerIdHotspot)) map.current.removeLayer(mapLayerIdHotspot);
          if (map.current.getLayer(mapLayerIdHotspotT)) map.current.removeLayer(mapLayerIdHotspotT);
          if (map.current.getLayer(mapLayerIdRunways)) map.current.removeLayer(mapLayerIdRunways);
          if (map.current.getLayer(mapLayerIdAggre)) map.current.removeLayer(mapLayerIdAggre);


          map.current.addLayer({
            'id': mapLayerIdRunways, // Layer ID
            'type': 'fill',
            'source': mapResourceNameRunways, // ID of the tile source created above
            'minzoom': 11,
            "layout": { 'visibility': 'visible' },
            "paint": {
              "fill-color": '#2493dd',
              "fill-opacity": 1.0,
              "fill-outline-color": '#eee'
            }
          });

          map.current.addLayer({
            'id': mapLayerId, // Layer ID
            'type': 'fill',
            'source': mapResourceName, // ID of the tile source created above
            "source-layer": mapSoureLayerName, //Source layer name was defined when generating vector tiles
            "layout": { 'visibility': checkCtrlFacility ? 'visible' : 'none' },
            "paint": {
              "fill-color": ['get', 'COLOR'],
              "fill-opacity": mapLayerFillOpacity,
              "fill-outline-color": mapLayerFillOutlineColor
            },
            filter: ["in", "GRID_ID", ...targetGrids]
          });

          map.current.addLayer({
            'id': mapLayerIdGridT, // Layer ID
            'type': 'symbol',
            'source': mapResourceNameGridT, // ID of the tile source created above
            "source-layer": mapSoureLayerNameGridT, //Source layer name was defined when generating vector tiles
            'minzoom': 10,
            "layout": {
              "text-field": ['get', 'CEILING'],
              "text-anchor": "center",
              'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
              "text-size": [
                'interpolate', ['linear'],
                ['zoom'],
                10, 7,
                11, 10,
                12, 13,
                12.5, 20,
                13, 24,
                14, 30
              ]
            },
            filter: ["in", "GRID_ID", ...targetGrids]
          });

          map.current.addLayer({
            'id': mapLayerIdHotspot, // Layer ID
            'type': 'fill',
            'source': mapResourceNameHotspot, // ID of the tile source created above
            "layout": { 'visibility': checkCtrlAirspace ? 'visible' : 'none' },
            "paint": {
              "fill-color": '#c9c9c9',
              "fill-opacity": mapLayerFillOpacity,
              "fill-outline-color": '#404040'
            }
          });

          map.current.addLayer({
            'id': mapLayerIdAggre,
            'type': 'circle',
            'source': mapResourceNameAggre,
            "layout": { 'visibility': checkCtrlSightings ? 'visible' : 'none' },
            'paint': {
              'circle-radius': 5,
              'circle-color': '#666',
              'circle-stroke-color': '#fff',
              'circle-stroke-width': 1
            },
            filter: ["in", "id", ...targetSightings]
          });

          map.current.addLayer({
            'id': mapLayerIdHotspotT, // Layer ID
            'type': 'symbol',
            'source': mapResourceNameHotspotT, // ID of the tile source created above
            "layout": {
              "text-field": [
                'format',
                ['upcase', ['get', 'IDENT']],
                // { 'font-scale': 0.6},
                {},
                ' (CLASS ',
                // { 'font-scale': 0.6 },
                {},
                ['upcase', ['get', 'CLASS']],
                // { 'font-scale': 0.6 },
                {},
                ') ',
                // { 'font-scale': 0.6 },
                {},
                ['upcase', ['get', 'SECTOR']],
                // { 'font-scale': 0.6 },
                {},
              ],
              "text-anchor": "center",
              'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
              "text-size": {
                "stops": [
                  [3, 8],
                  [5, 9],
                  [6, 11],
                  [7, 12],
                  [8, 13],
                  [9, 16],
                  [10, 19]
                ]
              },
              'visibility': checkCtrlAirspace ? 'visible' : 'none',
            }
          });

        }
      }

      // const changeBasemapStyle = (event, style) => {
      //   console.log(style);
      // }

      if (!map.current) {
        // console.log("Initializing UAS Risk map...");
        map.current = new mapboxgl.Map({
          container: mapContainer.current,
          // style: `mapbox://styles/mapbox/${basemap.id}`,
          // See: https://docs.mapbox.com/mapbox-gl-js/example/map-tiles/
          // style: {
          //   'version': 8,
          //   "glyphs": "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
          //   'sources': {
          //     'raster-tiles': {
          //       'type': 'raster',
          //       'tiles': [
          //         defaultBasemap.url
          //       ],
          //       'tileSize': 256,
          //       'attribution': defaultBasemap.attribution
          //     }
          //   },
          //   'layers': [
          //     {
          //       'id': 'basemap-tiles',    // Can be whatever you want to name it
          //       'type': 'raster',         // Must be raster
          //       'source': 'raster-tiles', // Must be 'raster-tiles'
          //       'minzoom': 0,
          //       'maxzoom': 22
          //     }
          //   ]
          // },
          style: defaultBasemapStyle,
          center: [lng, lat],
          zoom: zoomState,
          transformRequest: (url, resourceType) => {
            if (resourceType === 'Source' && url.startsWith('https://maptiles.asias.info')) {
            console.log(url, contextUser.user.signInUserSession.idToken.jwtToken)
            return{

            url: url, 
            headers: {Authorization: contextUser.user.signInUserSession.idToken.jwtToken}
            }
          }
          }
        });


        //Add controls
        map.current.addControl(new mapboxgl.NavigationControl(), 'top-left');
        map.current.addControl(new mapboxgl.FullscreenControl(), 'top-left');
        map.current.addControl(new BasemapSwitchControl("basemap-checkControl-riskmap"));
      }

      if (map.current) {

        // Map event when the basemap style loads or changes.
        map.current.on('styledata', () => {

          var mapLayer = map.current.getLayer(mapLayerId);
          if (typeof mapLayer === 'undefined') {
            // Run asynchronously to prevent race codition error for cleaning resources while in use
            setTimeout(() => {
              // console.log("Invoking addDataLayer because map layer is undefined but map has already been loaded...");
              addDataLayer();
            });
          }

          setStylesLoaded(true);

        });

        //Map event when the basemap was loaded
        map.current.on('load', () => {
          addDataLayer();

          // setMap(map);
          // map.resize();

          // Create legend
          const legend = buildLegend();
          setLegend(legend);

          const checkControl = buildCheckControl();
          setCheckControl(checkControl);


          map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoomState(map.current.getZoom().toFixed(2));
          });

          setMapLoaded(true);
        });

        map.current.on('click', (e) => {
          // Set `bbox` as 2px reactangle area around clicked point.
          const bbox = [
            [e.point.x - 1, e.point.y - 1],
            [e.point.x + 1, e.point.y + 1]
          ];
          // Find features intersecting the bounding box.
          const selectedFeatures = map.current.queryRenderedFeatures(bbox, {
            layers: [mapLayerId]
          });


          if (selectedFeatures !== undefined && selectedFeatures.length > 0) {

            // Extract properties
            const { properties } = selectedFeatures[0];

            let fieldsOfInterest = {
              "Grid ID": properties["GridID"],
              "Airport Code": properties["AptCode"],
              "ATM Assigned Ceiling": properties["CEILING"],
              "Model Altitude Ceiling": properties["NMAC_RC"],
              "NMAC Risk": properties["NMAC_risk"],
            };

            let tooltipHTML = buildFeaturedTooltip(fieldsOfInterest, { color: properties["COLOR"] });

            let gridPopup = new mapboxgl.Popup({
              closeButton: true,
              maxWidth: "350px",
              className: dataContext.darkMode ? "custom-map-tooltip-dark" : "custom-map-tooltip-light",
            });

            gridPopup.setLngLat(e.lngLat);
            gridPopup.setHTML(tooltipHTML);
            gridPopup.addTo(map.current);
          }
        });

        map.current.on('zoom', () => {
          if (map.current.getZoom() >= gridLabelZoomThreshold) {
            let gridTextCtrl = buildGridTextCheckControl();
            setGridLabelControl(gridTextCtrl);
          }
          else {
            setGridLabelControl(null);
          }
        });

        // Create a popup, but don't add it to the map yet.
        const popup = new mapboxgl.Popup({
          closeButton: true,
          // closeOnClick: false,
          maxWidth: "350px",
          className: dataContext.darkMode ? "custom-map-tooltip-dark" : "custom-map-tooltip-light",
        });

        map.current.on("click", mapLayerIdAggre, (e) => {
          // Remove tooltip version of popup if it is showing
          if (popup) {
            popup.remove();
          }

          // Extract features
          const { properties } = e.features[0];

          let fieldsOfInterest = {
            "Date": dataContext.toHumanReadableDateStr(new Date(properties["date_s"]), false, true),
            "City": properties["city"],
            "State": properties["state"],
            "Latitude": dataContext.roundStr(properties["final_lat"], 6),
            "Longitude": dataContext.roundStr(properties["final_lon"], 6),
            "Summary": properties["summary"],
          };

          let color = uasSightingsColor;
          let tooltipHTML = buildFeaturedTooltip(fieldsOfInterest, { color: color });

          let clickablePopup = new mapboxgl.Popup({
            closeButton: true,
            // anchor: "top",
            maxWidth: "350px",
            className: dataContext.darkMode ? "custom-map-tooltip-dark" : "custom-map-tooltip-light",
          });

          clickablePopup.setLngLat(e.lngLat);
          clickablePopup.setHTML(tooltipHTML);
          clickablePopup.addTo(map.current);
        });

        map.current.on('mouseenter', mapLayerIdAggre, (e) => {

          // Change the cursor style as a UI indicator.
          map.current.getCanvas().style.cursor = 'pointer';

          // Extract features
          const { properties, geometry } = e.features[0];

          // Copy coordinates array.
          const coordinates = geometry.coordinates.slice();

          let fieldsOfInterest = {
            "Date": dataContext.toHumanReadableDateStr(new Date(properties["date_s"]), false, true),
            "City": properties["city"],
            "State": properties["state"],
            "Latitude": dataContext.roundStr(properties["final_lat"], 6),
            "Longitude": dataContext.roundStr(properties["final_lon"], 6),
            "Summary": dataContext.ellipsify(properties["summary"], 20),
          };

          let color = uasSightingsColor;
          let tooltipHTML = buildFeaturedTooltip(fieldsOfInterest, { color: color });

          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          // Populate the popup and set its coordinates
          // based on the feature found.
          popup.setLngLat(coordinates).setHTML(tooltipHTML).addTo(map.current);
        });

        map.current.on('mouseleave', mapLayerIdAggre, () => {
          map.current.getCanvas().style.cursor = '';
          popup.remove();
        });

        // Condition is only satisfied when data is loaded and styles are applied
        // This means that layers should only be removed/added here if a filter is applied or removed after the initial map creation
        if (mapLoaded && stylesLoaded) {
          addDataLayer();
        }
      }
    }

    // Unmount post-processing hook
    return () => {
      // TODO Cleanup objects as needed

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.baseData, dataContext.filteredData]);

  useEffect(() => {
    // Resize map to fix viewport scaling when parent container is resized
    if (map.current) {
      map.current.resize();
    }

  }, [resizeDetectorWidth, resizeDetectorHeight]);

  return (
    <div ref={resizeDetectorRef} style={{ width: "100%", height: "100%" }}>
      {dataContext.DEBUG_MODE && (
        <div className="mapbox-custom-sidebar">
          <div>
            Longitude: {lng} | Latitude: {lat} | Zoom: {zoomState}
          </div>
        </div>
      )}

      <div id={mapId} ref={mapContainer} style={mapStyles}>
        {checkControl}
        {gridLabelControl}
        {legend}
      </div>
    </div>

  )
}

UasRiskVectorTilesMap.propTypes = propTypes;
UasRiskVectorTilesMap.defaultProps = defaultProps;

export default UasRiskVectorTilesMap;
