import React, { useState, useRef, useEffect, useContext } from 'react';
import { DataContext } from "../../DataContext";
// import PropTypes from 'prop-types';
import CanvasJSReact from '../../../canvasjs-commercial-3.4.12/canvasjs.react';
import { useResizeDetector } from 'react-resize-detector';
import Stack from '@mui/material/Stack';
import StyledSlider, { StyledThumbComponent } from '../../utils/StyledSlider';
import '../Chart.css';

// const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const propTypes = {};
const defaultProps = {};

// Control number of points (bars) that fit within viewport
const VISIBLE_POINTS_THRESHOLD = 10; // n points (bars) will be visibile within default viewport bounds
const VISIBLE_POINTS_PADDING = -0.5; // 2.5 // Magic number to offset begining viewport minimum value and display full point (bar)

function UimcEventsByStateAndDaylight() {
  // Obtain reference to global data context consumer
  const dataContext = useContext(DataContext);

  const { width, height, ref: resizeDetectorRef } = useResizeDetector();

  // Keep reference of chart for event listeners/actions
  const chartRef = useRef(null);
  const chartContainerRef = useRef(null);
  const isMounted = useRef(false);

  // Determine if data exists
  const d = dataContext.filteredData ? dataContext.filteredData : dataContext.baseData;
  const dataExists = d && d.uimc_events && d.uimc_events.data && d.uimc_events.data.length > 0;

  const [dataOfInterestExists, setDataOfInterestExists] = useState(true);
  const [minScrollValue, setMinScrollValue] = useState(0);
  const [maxScrollValue, setMaxScrollValue] = useState(0);
  const [currentScrollValue, setCurrentScrollValue] = useState(0);
  const [viewportMin, setViewportMin] = useState(0);
  const [viewportMax, setViewportMax] = useState(0);

  /**
   * Build and retrieve formatted HTML string representing a featured
   * tooltip given a set of values for a particular row of interest.
   * 
   * row = {
   *   "key_1": "value_1",
   *   "key_2": "value_2",
   *   ...
   *   "key_n": "value_n"
   * }
   * 
   * @param {object} row Fields of interest for current row in dataset.
   * @param {string} color Hexidecimal or RGB(A) color string.
   * @returns Formatted HTML string representing a featured tooltip.
   */
  const getFeaturedTooltip = (row, color = null) => {
    // Build tool tip off all properties
    let tooltipText = "";

    // Start table
    tooltipText += "<table>";
    for (const [key, value] of Object.entries(row)) {
      let iconData = `<td><i class="fa fa-square tooltip-icon-fonts" style="color: ${color}; margin-right: 5px;"></i></td>`;
      tooltipText += `
        <tr>
          ${color !== null && iconData}
          <td style="color: #989898"><b>${key}&nbsp</b></td>
          <td><b>${value}</b></td>
        </tr>
      `;
    }

    // End table
    tooltipText += "</table>";
    return tooltipText;
  }

  // See CanvasJS configuration options at https://canvasjs.com/docs/charts/basics-of-creating-html5-chart/
  // Value format string: https://canvasjs.com/docs/charts/chart-options/axisx/valueformatstring/
  const [options, setOptions] = useState({
    zoomEnabled: false,
    animationEnabled: true,
    title: {
      text: ""
    },
    theme: dataContext.darkMode ? "dark1" : "light1",
    toolTip: {
      enabled: true,
      animationEnabled: true,
      // shared: true,
      contentFormatter: e => {
        let content = "";
        for (let i = 0; i < e.entries.length; i++) {
          let dataPoint = e.entries[i].dataPoint;
          content = `${content}${dataPoint.tooltipText}`;
        }

        return content;
      }
    },
    legend: {
      fontFamily: dataContext.CHART_FONTS.fontFamily,
      fontSize: dataContext.CHART_FONTS.legendFontSize,
      fontWeight: dataContext.CHART_FONTS.fontWeight,
    },
    axisX: {
      labelFontFamily: dataContext.CHART_FONTS.fontFamily,
      labelFontWeight: dataContext.CHART_FONTS.fontWeight,
      labelAutoFit: true,
      labelAngle: 0,
      title: "",
      gridThickness: 0,
      labelFontSize: 13,
      interval: 1, // Show all labels
    },
    axisY: {
      gridThickness: 0.5,
      labelFontFamily: dataContext.CHART_FONTS.fontFamily,
      labelFontSize: dataContext.CHART_FONTS.fontSize,
      labelFontWeight: dataContext.CHART_FONTS.fontWeight,
      title: "",
    },
    data: [
      { type: "stackedBar", dataPoints: [] }
    ]
  });

  /**
   * Convert string payload to uppercase if possible.
   * 
   * @param {string} payload String data to convert to uppercase.
   * @returns Uppercased string or original payload if not string type.
   */
  const toUpper = (payload) => {
    return payload && typeof (payload) === "string" ? payload.trim().toUpperCase() : payload;
  }

  /**
   * Get grouped counts for each unique data point and produce a consolidated
   * object containing the rolled up counts, grouped by the desired field.
   * 
   * @param {object} dataTarget Base dataset or filtered dataset to extract desired group counts from.
   * @returns Object containing desired group counts.
   */
  const getGroupedData = (dataTarget) => {
    // Extract lookup table and data from data target
    const { uimc_events: { data, lookup } } = dataTarget;
    let groupedData = {};

    // Get initial grouping of all possible daylight values
    let daylightValues = dataContext.groupBy(data, lookup.daylight);

    // Create case insensitive variation
    let daylightUpperValues = daylightValues.map(item => toUpper(item));

    // console.log("Possible daylight values:", daylightValues);
    // console.log("|-- Case insensitive variation:", daylightUpperValues);

    // Group by day and daylight state (e.g., Day, Night, Twilight)
    for (let record of data) {
      let state = record[lookup.state];
      let stateUpper = toUpper(state);

      let daylight = record[lookup.daylight];
      let daylightUpper = toUpper(daylight);

      // let timestampStr = `${d.getFullYear()}${d.getMonth()}${d.getDate()}`;
      let key = `${stateUpper}${daylightUpper}`;

      if (!(key in groupedData)) {
        // NOTE: Value will always be the first date occurrence
        groupedData[key] = { daylight: daylight, state: state, count: 0 };

        // Check other variations of daylight states and initialize where needed
        // This will ensure each list of counts will be equal in length, setting counts to 0 where needed on dates that didn't have a respective daylight state
        for (let i = 0; i < daylightValues.length; i++) {
          let daylightValue = daylightValues[i];
          let daylightUpperValue = daylightUpperValues[i];

          // Skip same daylight value
          if (daylightUpperValue !== daylightUpper) {
            let newKey = `${stateUpper}${daylightUpperValue}`;
            if (!(newKey in groupedData)) {
              groupedData[newKey] = { daylight: daylightValue, state: state, count: 0 };
            }
          }
        }
      }

      groupedData[key].count++;
    }

    return groupedData;
  }

  /**
   * Handle new minimum and maxium count range and process accordingly.
   * 
   * @param {object} e Event object containing target value representing minimum and maximum count range pair.
   */
  const handleCountRangeChanged = (e) => {
    const { target: { value: newRange = 0, asDelta = false } } = e;
    let targetRange = asDelta ? currentScrollValue + newRange : newRange;
    let lowerBound = (targetRange - VISIBLE_POINTS_THRESHOLD) + VISIBLE_POINTS_PADDING;
    let upperBound = targetRange + VISIBLE_POINTS_PADDING;
    // console.log(`lowerBound = ${lowerBound}, upperBound = ${upperBound}, targetRange = ${targetRange}, asDelta = ${asDelta}`);

    setCurrentScrollValue(targetRange);
    setViewportMin(lowerBound);
    setViewportMax(upperBound);
  }

  /**
   * Drilldown on data on click event. Parse selection and pass forward to
   * data context to handle drilldown filter.
   * 
   * @param {object} e Event object passed from click listener on chart.
   */
  const handleDrilldown = (e) => {
    // Build data structure that imitates control filter
    // EXAMPLE:
    // {
    //   "phaseOfFlightSelect": {
    //     "id": "phaseOfFlightSelect",
    //     "label": "Standing, Hover Ground Effect",
    //     "column": "phaseofflight_mavg10",
    //     "values": [
    //       "standing",
    //       "hover ground effect"
    //      ]
    //    }
    // }
    const { label: state, daylight } = e.dataPoint;

    let filter = {
      [dataContext.UIMC_EVENTS_STATE_SELECT_KEY]: {
        id: dataContext.UIMC_EVENTS_STATE_SELECT_KEY,
        label: state,
        column: "state",
        values: [state],
        track: true,
      },
      [dataContext.UIMC_EVENTS_DAYLIGHT_SELECT_KEY]: {
        id: dataContext.UIMC_EVENTS_DAYLIGHT_SELECT_KEY,
        label: daylight,
        column: "daylight",
        values: [daylight],
        track: true,
      }
    };

    // console.log(`Drilldown on datapoint: state = ${state}, daylight = ${daylight}`);
    // console.log("|-- Filter:", filter);

    chartRef.current.toolTip.hide(); // Hide tooltip to prevent errors
    dataContext.addControlFilter(filter);
  }

  // Component mount/unmount
  useEffect(() => {
    isMounted.current = true;

    // NOTE: identify document element by id on component mount to ensure canvas js chart wraps to parent container
    if (resizeDetectorRef.current) {
      let chartContainer = resizeDetectorRef.current.children[0]; // e.g., canvasjs-react-chart-container-2
      if (chartContainer) {
        // console.log("Setting width and height of parent canvasjs container...", chartContainer);
        chartContainer.style.height = "100%";
        chartContainer.style.width = "100%";

        // Because of the mui stack container, the chart container is nested one more layer deep.
        if (chartContainer.children && chartContainer.children.length > 0) {
          chartContainerRef.current = chartContainer.children[0];
          if (chartContainerRef.current.id.includes("canvasjs-react-chart-container")) {
            // console.log("Nested chart container:", chartContainerRef.current);
            chartContainerRef.current.style.height = "100%";
            chartContainerRef.current.style.width = "100%";

            // Programmatically click pan button to enable pan mode by default
            // NOTE: Prevent race condition by putting programmatic trigger on separate async thread
            // setTimeout(() => {
            //   let toolbarElement = (chartContainerRef.current.getElementsByClassName("canvasjs-chart-toolbar") || [null])[0];
            //   // console.log("Toolbar element:", toolbarElement);
            //   if (toolbarElement) {
            //     if (toolbarElement.children[0].getAttribute("state") === "pan") {
            //       // console.log("Setting chart to pan mode...");
            //       toolbarElement.children[0].click();
            //     }

            //     // Hide the toolbar
            //     toolbarElement.style.visibility = "hidden";
            //   }
            // });
          }
        }
      }
    }

    return () => {
      isMounted.current = false;

      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }

      setOptions({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Detect changes to incomming/ingested data
  useEffect(() => {
    if (isMounted.current) {
      // Obtain reference to active data from context
      let dataTarget = dataContext.filteredData ? dataContext.filteredData : dataContext.baseData;
      let totalDataPoints = 0;
      let totalFacilities = 0;
      let countMin = 0;
      let countMax = 0;

      if (dataTarget) {
        // Get aggregated count on timestamp field
        let counts = getGroupedData(dataTarget);
        let keys = Object.keys(counts);

        const { uimcDaylightTypeColorMap } = dataContext.COLOR_MAPS.current;
        const dataSeriesTemplate = {
          type: "stackedBar",
          color: undefined,
          legendText: undefined,
          showInLegend: true,
          legendMarkerColor: undefined,
          legendMarkerType: "square",
          dataPoints: []
        };

        // Track unique data series
        let dataSeriesSet = {};

        // console.log("UIMC Event Counts:", counts);
        if (keys.length > 0) {
          // Change with color palette value
          let usedNewColor = false;

          // Create separate data series for each daylight group
          let stateSet = new Set();
          Array.from(keys).forEach((key) => {
            const { count, state, daylight } = counts[key];
            let daylightUpper = toUpper(daylight);
            let color = uimcDaylightTypeColorMap[daylightUpper] || "#C9C9C9"; // Default gray

            // Update count min
            if (count < countMin) {
              countMin = count;
            }

            // Update count max
            if (count > countMax) {
              countMax = count;
            }

            // Create new data series entry if the daylight state hasn't been encountered yet
            if (!(daylightUpper in dataSeriesSet)) {
              dataSeriesSet[daylightUpper] = {
                ...dataSeriesTemplate,
                color: color,
                legendText: daylight,
                legendMarkerColor: color,
                dataPoints: [],
              };

              // console.log(`Initialized entry for ${daylightUpper}`);
              usedNewColor = true;
            }

            // Use existing color for current daylight if not using a new color
            if (!usedNewColor) {
              color = dataSeriesSet[daylightUpper].color;
            }

            // Filter out null x or y values to prevent crash
            if (state !== null && count !== null) {
              let fieldsOfInterest = {
                "State": state,
                "Daylight": daylight,
                "Count": `${count.toLocaleString('en-US')}`,
              };

              let tooltipText = getFeaturedTooltip(fieldsOfInterest, color);

              if (!stateSet.has(state)) {
                stateSet.add(state);
              }

              // Add point to data points for current data series
              dataSeriesSet[daylightUpper].dataPoints.push({
                label: state,
                daylight: daylight,
                y: count,
                color: color,
                tooltipText: tooltipText,
                click: handleDrilldown,
              });
            }

            // Initialize new color flag
            usedNewColor = false;
          });

          // Track total facilities
          totalFacilities = stateSet.size;

          // Create final dataset
          let dataSeriesList = Object.keys(dataSeriesSet).map(item => dataSeriesSet[item]);
          totalDataPoints = dataSeriesList.reduce((prev, next) => prev + next.dataPoints.length, 0);

          // Sort by count in descending order for each daylight type
          // NOTE: Need to sort each list in parallel to prevent state labels from getting jumbled
          let sumList = [];
          let maxSum = 0;
          for (let i = 0; i < dataSeriesList[0].dataPoints.length; i++) {
            const { label, y } = dataSeriesList[0].dataPoints[i];
            let sum = y;

            // Sync other data points lists in parallel
            for (let j = 1; j < dataSeriesList.length; j++) {
              sum += dataSeriesList[j].dataPoints[i].y;
            }

            if (sum > maxSum) {
              maxSum = sum;
            }

            // console.log(`Sum count for ${label} = ${sum}`);
            sumList.push({ label: label, sum: sum, idx: i });
          }

          // Sort sum list in descending order
          sumList.sort((a, b) => a.sum - b.sum);
          // console.log("Max sum:", maxSum);
          // console.log("Sorted sum list:", sumList);

          // Build new lists for each daylight type based on sorted sum list
          for (let i = 0; i < dataSeriesList.length; i++) {
            let dataSeries = dataSeriesList[i];

            // Map backup copy of original data points list
            let oldDataPoints = [...dataSeries.dataPoints];
            dataSeriesList[i].dataPoints = [];

            // Iterate sorted sum list and build new data points for current data series
            for (let item of sumList) {
              dataSeriesList[i].dataPoints.push(oldDataPoints[item.idx]);
            }
          }

          // console.log("Data series set:", dataSeriesSet);
          // console.log("Data series list:", dataSeriesList);

          // Get the minimum and maximum possible y values to set appropriate scale for y-axis (and doesn't change when zooming)
          let padding = (dataContext.getMagnitude(countMax) / 10) * 5;

          // Adjust padding if it is 0, which indicates all data points have the same y-axis value (e.g., min and max are equal)
          padding = padding === 0 ? 0.1 : padding;

          // Compute lower bound and upper bound for viewport
          let lowerBound = (totalFacilities - VISIBLE_POINTS_THRESHOLD) + VISIBLE_POINTS_PADDING;
          let upperBound = totalFacilities + VISIBLE_POINTS_PADDING;

          // Create formatted data structure for canvasjs chart
          // See data series attributes at https://canvasjs.com/docs/charts/chart-options/data/
          // See performance improvement considerations at https://canvasjs.com/forums/topic/performance-improvement-in-scatter-chart/
          setOptions({
            ...options,
            axisX: {
              ...options.axisX,
              // NOTE: Set viewport minimum relative to maximum count to ensure the first datapoint is visible within viewport bounds 
              // For example, if countMax is 100 and VISIBLE_POINTS_THRESHOLD is 10, then viewportMinimum will be 90
              viewportMinimum: totalFacilities > VISIBLE_POINTS_THRESHOLD && lowerBound > 0 ? lowerBound : undefined,
              viewportMaximum: totalFacilities > VISIBLE_POINTS_THRESHOLD ? upperBound : undefined,
              interval: totalFacilities > 1 ? 1 : undefined,
            },
            axisY: {
              ...options.axisY,
              minimum: 0,
              maximum: maxSum,
            },
            data: dataSeriesList
          });
        }
      }

      // let scrollValue = totalFacilities > 1 ? totalFacilities + VISIBLE_POINTS_PADDING : totalFacilities;

      // Track min and max counts for slider widget control
      // console.log(`Setting min and max counts: (${countMin}, ${countMax})`);
      // console.log(`Total facilities: ${totalFacilities}`);
      // console.log(`Scroll value: ${scrollValue}`);
      setMinScrollValue(0);
      setMaxScrollValue(totalFacilities);
      setCurrentScrollValue(totalFacilities);

      // Data points of interest exist when at least one data point exists in the list
      setDataOfInterestExists(totalDataPoints > 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.baseData, dataContext.filteredData]);

  // Detect changes to viewport min/max state
  useEffect(() => {
    if (viewportMax === 0) return;
    // console.log(`Viewport bounds changed: [${viewportMin}, ${viewportMax}]`);
    setOptions({
      ...options,
      axisX: {
        ...options.axisX,
        // NOTE: Set viewport minimum relative to maximum count to ensure the first datapoint is visible within viewport bounds 
        // For example, if countMax is 100 and VISIBLE_POINTS_THRESHOLD is 10, then viewportMinimum will be 90
        viewportMinimum: viewportMin,
        viewportMaximum: viewportMax,
      }
    });
  }, [viewportMin, viewportMax]);

  useEffect(() => {
    if (isMounted) {
      // Re-render chart if there's a change in theme
      if (chartRef.current) {
        // NOTE: must do it this way since options are copied to chart reference (e.g., not bound by state/props)
        chartRef.current.options.theme = dataContext.darkMode ? "dark1" : "light1";
        chartRef.current.render();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.darkMode]);

  // Dynamically update width and height of chart to fill parent container on dimension change
  useEffect(() => {
    if (isMounted) {
      if (chartRef.current) {
        let yOffset = 20;
        let dh = height - yOffset;
        if (width > 0 && dh > 0) {
          chartRef.current.options.width = width;
          chartRef.current.options.height = dh;
          chartRef.current.render();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height]);

  useEffect(() => {
    if (!chartRef.current) return;
    if (!chartContainerRef.current) return;

    // Don't allow scrolling if the total number of visible points (bars) is less than or equal to the configured threshold
    if (maxScrollValue <= VISIBLE_POINTS_THRESHOLD) return; 

    const handleMouseScroll = (e) => {
      e.preventDefault();
      const chart = chartRef.current;
      if (!chart) return;

      let axisX = chart.axisX[0];
      let viewportMin = axisX.get("viewportMinimum");
      let viewportMax = axisX.get("viewportMaximum");
      let interval = e.deltaY < 0 ? 1 : e.deltaY > 0 ? -1 : 0; // axisX.get("minimum")
      let newViewportMin, newViewportMax;

      // console.log(`Scroll changed: interval = ${interval}, currentScrollValue = ${currentScrollValue}`);

      // NOTE: Inverted behavior. Scrolling down results in positive delta y values
      if (interval !== 0) {
        newViewportMax = viewportMax + interval;
        newViewportMin = viewportMin + interval;
        setCurrentScrollValue(currentScrollValue + interval);
      }

      if (newViewportMin >= chart.axisX[0].get("minimum") && newViewportMax <= chart.axisX[0].get("maximum") && (newViewportMax - newViewportMin) > (2 * interval)) {
        chart.axisX[0].set("viewportMinimum", newViewportMin, false);
        chart.axisX[0].set("viewportMaximum", newViewportMax);
      }
    }

    chartContainerRef.current.addEventListener('wheel', handleMouseScroll);
    return () => chartContainerRef.current.removeEventListener("wheel", handleMouseScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentScrollValue, maxScrollValue]);

  return (
    <div ref={resizeDetectorRef} style={{ width: "100%", height: "100%" }}>
      {(dataExists && dataOfInterestExists) && (
        <Stack sx={{ width: "100%", height: "100%" }} spacing={1} direction="row">
          <CanvasJSChart options={options} onRef={ref => chartRef.current = ref} />
          <StyledSlider
            // NOTE: Create dynamic key to make this a controlled slider (prevents error on uncontrolled slider)
            key={`daylight-count-slider-${minScrollValue}-${maxScrollValue}`}
            components={{ Thumb: StyledThumbComponent }}
            sx={{ height: "75%", paddingLeft: 0 }}
            getAriaLabel={() => 'Daylight Count'}
            orientation="vertical"
            // getAriaValueText={valuetext}
            defaultValue={maxScrollValue}
            value={currentScrollValue}
            min={minScrollValue + VISIBLE_POINTS_THRESHOLD}
            max={maxScrollValue}
            valueLabelDisplay="off"
            track={false}
            disabled={maxScrollValue <= VISIBLE_POINTS_THRESHOLD}
            onChange={handleCountRangeChanged}
          // onChangeCommitted={handleCountRangeChanged} // More efficient for rendering performance (if needed)
          />
        </Stack>
      )}
      {(!dataExists) && (
        <div>
          <b>No Data</b>
        </div>
      )}
      {(dataExists && !dataOfInterestExists) && (
        <div>
          {/* Customize message as needed */}
          <b>No Data</b>
        </div>
      )}
    </div>
  );
}

UimcEventsByStateAndDaylight.propTypes = propTypes;
UimcEventsByStateAndDaylight.defaultProps = defaultProps;

export default UimcEventsByStateAndDaylight;
