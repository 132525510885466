import {API} from "aws-amplify";

export function getTails(user, operator) {

    const token = user.signInUserSession.idToken.jwtToken;
    const requestInfo = {
      headers: {
        Authorization: token
      },
      body: {
        operator:operator
      }
    }
  // console.log({token})

    let url = "/read-tails"
  

    const data = API.post('raise-gui-api-dev', url, requestInfo)
    return data;
}

export default getTails;