import { API } from "aws-amplify";

export function getMyFlightsV2(user, operator, flightIds) {
  const token = user.signInUserSession.idToken.jwtToken;
  const mode = "flight-metrics";
  const requestInfo = {
    headers: {
      Authorization: token
    },
    body: {
      operator: operator,
      flights: flightIds,
      mode: mode,
    }
  }

  let url = "/get-viz-presigned-url"

  const data = API.post('raise-gui-api-dev', url, requestInfo)
  return data;
}

export default getMyFlightsV2;