import React from 'react'
import { FaBars } from 'react-icons/fa';
import { NavLink as Link, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import UserContext from './UserContext';
// import { Drawer } from 'react-bootstrap-drawer';

export const Nav = styled.nav`
  background: #142945;
  height: 80px;
  display: flex;
  padding: 0.5rem calc((100vw - 1000px) / 2);
  z-index: 10;

  justify-content: flex-start;
`;

export const NavLink = styled(Link)`
  color: #ffffff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  
  &:hover {
    color: #8994a2;
  }
`;

export const Bars = styled(FaBars)`
  display: none;
  color: #fff;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;
  width: 100vw;
  white-space: nowrap;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 12px;
  justify-content: flex-end;
  width: 105px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtnLink = styled(Link)`
  border: 2px solid white;
  border-color: #ffffff;
  border-radius: 4px;
  padding: 10px 22px;
  width: 10vw;
  color: #ffffff;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #142945;
  }
`;

export const SignIn = styled(Link)`
  text-decoration: none;
  color: #ffffff;
  margin-right: 0px;
  width: 5vw;

  &:hover {
    transition: all 0.2s ease-in-out;
    text-decoration: underline
  }
`;

export const NavTitle = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #ffffff;
  width: 25vw;
  white-space: nowrap;
  font-weight: 700;
`;

class NavbarUnauth extends React.Component {
  // state = {
  //   drawerOpen: false,
  // }

  // handleDrawerToggle = () => this.setState({drawerOpen: !drawerOpen});

  static contextType = UserContext
  render() {
    const isAuthenticated = this.context.user && this.context.user.username ? true : false
    const isLoaded = this.context.isLoaded
    return (
      <>
         <Nav>
          <Bars />
          <NavTitle to="/">
            <h3>ASIAS (Rotorcraft)</h3>
          </NavTitle>
          <NavMenu>
            <NavLink to="/">
              Home
            </NavLink>
            <NavLink to="/about">
              About
            </NavLink>
            <NavLink to="/contact">
             Contact  
            </NavLink>
            {
              isLoaded ? isAuthenticated ? (
                <>

                </>
                
                /*<NavLink to="/dashboard">
                  Dashboard
                </NavLink>*/
              ) : (
                <div></div>
              ) : null
            }
          </NavMenu>
          <NavBtn>
            <SignIn to="/login">
              Sign In 
            </SignIn>
          </NavBtn>
          <NavBtn>
            <NavBtnLink to="/register">
              Sign up
            </NavBtnLink>
          </NavBtn>
        
          </Nav>
      </>
    )
  }
}

export default NavbarUnauth

