import React, { useState, useEffect, useRef, useContext } from "react";
import { DataContext } from "../../DataContext";
// import PropTypes from "prop-types";
import CanvasJSReact from '../../../canvasjs-commercial-3.4.12/canvasjs.react';
import { useResizeDetector } from 'react-resize-detector';
import Stack from '@mui/material/Stack';
import StyledSlider, { StyledThumbComponent } from '../../utils/StyledSlider';
import '../Chart.css';

// const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const propTypes = {};
const defaultProps = {};

// Control number of points (bars) that fit within viewport
const VISIBLE_POINTS_THRESHOLD = 5; // n points (bars) will be visibile within default viewport bounds
const VISIBLE_POINTS_PADDING = -0.5; // Magic number to offset begining viewport minimum value and display full point (bar)

function LocRiskDurationBarChart() {
  // Obtain reference to global data context consumer
  const dataContext = useContext(DataContext);
  const { width, height, ref: resizeDetectorRef } = useResizeDetector();

  // Keep reference of chart for event listeners/actions
  const chartRef = useRef(null);
  const chartContainerRef = useRef(null);
  const isMounted = useRef(false);

  // Determine if data exists
  const d = dataContext.filteredData ? dataContext.filteredData : dataContext.baseData;
  const dataExists = d && d.track_points && d.track_points.data && d.track_points.data.length > 0;

  const [dataOfInterestExists, setDataOfInterestExists] = useState(true);
  const [dataCalculated, setDataCalculated] = useState(true)

  const [minScrollValue, setMinScrollValue] = useState(0);
  const [maxScrollValue, setMaxScrollValue] = useState(0);
  const [currentScrollValue, setCurrentScrollValue] = useState(0);
  const [viewportMin, setViewportMin] = useState(0);
  const [viewportMax, setViewportMax] = useState(0);

  /**
   * Build and retrieve formatted HTML string representing a featured
   * tooltip given a set of values for a particular row of interest.
   * 
   * row = {
   *   "key_1": "value_1",
   *   "key_2": "value_2",
   *   ...
   *   "key_n": "value_n"
   * }
   * 
   * @param {object} row Fields of interest for current row in dataset.
   * @param {string} color Hexidecimal or RGB(A) color string.
   * @returns Formatted HTML string representing a featured tooltip.
   */
  const getFeaturedTooltip = (row, color = null) => {
    // Build tool tip off all properties
    var tooltipText = "";

    // Start table
    tooltipText += "<table>";
    for (const [key, value] of Object.entries(row)) {
      let iconData = `<td><i class="fa fa-square tooltip-icon-fonts" style="color: ${color}; margin-right: 5px;"></i></td>`;
      tooltipText += `
        <tr>
          ${color !== null && iconData}
          <td style="color: #989898"><b>${key}&nbsp</b></td>
          <td><b>${value}</b></td>
        </tr>
      `;
    }

    // End table
    tooltipText += "</table>";
    return tooltipText;
  }

  // See CanvasJS configuration options at https://canvasjs.com/docs/charts/basics-of-creating-html5-chart/
  // NOTE: axes are inverted for bar charts by default (e.g., horizontal bar chart)
  const [options, setOptions] = useState({
    zoomEnabled: false,
    animationEnabled: true,
    title: {
      text: ""
    },
    theme: dataContext.darkMode ? "dark1" : "light1",
    toolTip: {
      enabled: true,          //disable here
      animationEnabled: true, //disable here
      contentFormatter: e => {
        var content = "";
        for (var i = 0; i < e.entries.length; i++) {
          let dataPoint = e.entries[i].dataPoint;
          content = `${content}${dataPoint.tooltipText}`;
        }

        return content;
      }
    },
    axisX: {
      labelFontFamily: dataContext.CHART_FONTS.labelFontFamily,
      labelFontWeight: dataContext.CHART_FONTS.labelFontWeight,
      interval: 1,         // Enforce showing all labels
    },
    axisY: {
      gridThickness: 0.5,
      labelFontFamily: dataContext.CHART_FONTS.fontFamily,
      labelFontSize: dataContext.CHART_FONTS.fontSize,
      labelFontWeight: dataContext.CHART_FONTS.fontWeight,
      title: "",
    },
    dataPointMaxWidth: dataContext.MAX_BARCHART_DATAPOINT_WIDTH,
    // dataPointWidth: 100,
    // exportEnabled: true,
    data: [
      { type: "bar", dataPoints: [] }
    ]
  });

  /**
   * Get exceedance event durations for each unique exceedance type and produced a 
   * consolidated object containing the rolled up durations, grouped by severity.
   * 
   * @param {object} dataTarget Base dataset or filtered dataset to extract exceedance event duraions from.
   * @returns Object containing exceedance event durations grouped by severity.
   */
  const getEventDurationsBySeverity = (dataTarget) => {
    let grouping = {};
    let relevantCounts = {};

    let eventData = dataTarget.exceedance_event.data;
    let eventLookup = dataTarget.exceedance_event.lookup;

    // Get unique global counts for exceedances (using as a method for providing an entry for each unique exceedance type)
    let exceedance_counts = dataContext.groupByCount(eventData, eventLookup.exceedance_subtype);
    // console.log("Exceedance Counts:", exceedance_counts);

    // Iterate exceedance event records and link with severity data
    for (let row of eventData) {
      // Only process loss of control (LOC) events
      let type = row[eventLookup.exceedance_type];
      if (type !== null && type.toUpperCase() === "LOSS OF CONTROL") {
        let exceedance = row[eventLookup.exceedance_subtype];
        let severity = row[eventLookup.exceedance_severity];
        let duration = row[eventLookup.duration];

        if (severity && severity !== "None") {
          if (!(severity in grouping)) {
            grouping[severity] = { value: severity, duration: 0 };

            // Build default values set for severity occurrance (charting requirement)
            grouping[severity]["exceedance_events"] = Object.keys(exceedance_counts).reduce(
              (prev, current) => {
                return current === "null" ? { ...prev } : {
                  ...prev,
                  [current]: { value: exceedance_counts[current].value, duration: 0 }
                }
              }, {}
            );
          }

          // Make an entry for the current exceedance event in the severity slot if it doesn't exist yet
          if (exceedance) {
            // Increment total severity count
            grouping[severity].duration += duration;

            if (!(exceedance in grouping[severity]["exceedance_events"])) {
              grouping[severity]["exceedance_events"][exceedance] = { value: exceedance, duration: 0 };
            }

            // Increment total exceedance count for the severity
            grouping[severity]["exceedance_events"][exceedance].duration += duration;
            relevantCounts[exceedance] = true;
          }
        }
      }
    }

    // Prune out exceedances where there are no counts for any severity level to save space on rendered chart
    for (let severity of Object.keys(grouping)) {
      let reduced = Object.keys(grouping[severity].exceedance_events).reduce((prev, current) => {
        return current in relevantCounts ? { ...prev, [current]: { ...grouping[severity].exceedance_events[current] } } : { ...prev };
      }, {});

      grouping[severity].exceedance_events = reduced;
    }

    // console.log("Exceedance Event Duration Grouping:", grouping);

    return grouping;
  }

  /**
   * Handle new minimum and maxium count range and process accordingly.
   * 
   * @param {object} e Event object containing target value representing minimum and maximum count range pair.
   */
   const handleCountRangeChanged = (e) => {
    const { target: { value: newRange = 0, asDelta = false } } = e;
    let targetRange = asDelta ? currentScrollValue + newRange : newRange;
    let lowerBound = (targetRange - VISIBLE_POINTS_THRESHOLD) + VISIBLE_POINTS_PADDING;
    let upperBound = targetRange + VISIBLE_POINTS_PADDING;
    // console.log(`lowerBound = ${lowerBound}, upperBound = ${upperBound}, targetRange = ${targetRange}, asDelta = ${asDelta}`);

    setCurrentScrollValue(targetRange);
    setViewportMin(lowerBound);
    setViewportMax(upperBound);
  }

  /**
   * Drilldown on data on click event. Parse selection and pass forward to
   * data context to handle drilldown filter.
   * 
   * @param {object} e Event object passed from click listener on chart.
   */
  const handleDrilldown = (e) => {
    // Build data structure that imitates control filter
    // EXAMPLE:
    // {
    //   "phaseOfFlightSelect": {
    //     "id": "phaseOfFlightSelect",
    //     "label": "Standing, Hover Ground Effect",
    //     "column": "phaseofflight_mavg10",
    //     "values": [
    //       "standing",
    //       "hover ground effect"
    //      ]
    //    }
    // }
    const { label, exceedance, severity } = e.dataPoint;

    let filter = {
      [dataContext.LOC_TYPE_SELECT_KEY]: {
        id: dataContext.LOC_TYPE_SELECT_KEY,
        label: label,
        column: "exceedance_subtype",
        values: [exceedance],
        track: true,
      },
      [dataContext.LOC_SEVERITY_SELECT_KEY]: {
        id: dataContext.LOC_SEVERITY_SELECT_KEY,
        label: dataContext.capitalizeWords(severity),
        column: "exceedance_severity",
        values: [severity],
        track: true,
      }
    }

    chartRef.current.toolTip.hide(); // Hide tooltip to prevent errors
    dataContext.addControlFilter(filter);
  }

  // Component mount/unmount
  useEffect(() => {
    isMounted.current = true;

    // NOTE: identify document element by id on component mount to ensure canvas js chart wraps to parent container
    if (resizeDetectorRef.current) {
      let chartContainer = resizeDetectorRef.current.children[0]; // e.g., canvasjs-react-chart-container-2
      if (chartContainer) {
        // console.log("Setting width and height of parent canvasjs container...", chartContainer);
        chartContainer.style.height = "100%";
        chartContainer.style.width = "100%";

        // Because of the mui stack container, the chart container is nested one more layer deep.
        if (chartContainer.children && chartContainer.children.length > 0) {
          chartContainerRef.current = chartContainer.children[0];
          if (chartContainerRef.current.id.includes("canvasjs-react-chart-container")) {
            // console.log("Nested chart container:", chartContainerRef.current);
            chartContainerRef.current.style.height = "100%";
            chartContainerRef.current.style.width = "100%";
          }
        }
      }
    }

    return () => {
      isMounted.current = false;

      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }

      setOptions({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Detect changes to incomming/ingested data
  useEffect(() => {
    if (isMounted.current) {
      // Obtain reference to active data from context
      let dataTarget = dataContext.filteredData ? dataContext.filteredData : dataContext.baseData;
      let dataPointsList = [];
      let exceedanceCount = 0;
      let exceedanceCountPadded = 0;

      let vrsCalculated = true;
      let lteCalculated = true;

      if (dataTarget && dataTarget.track_points) {

        // Get exceedance event durations by severity
        let durations = getEventDurationsBySeverity(dataTarget);
        // console.log("Event Durations by Severity:", durations);

        // Dynamically build exceedance events data points by severity
        let yMax = 0;

        vrsCalculated = dataTarget && dataTarget.flight_meta ? dataTarget.flight_meta.data[0][dataTarget.flight_meta.lookup["vrs_calculated"]] : dataTarget.track_points.data[0][dataTarget.track_points.lookup["vrs_calculated"]]
        lteCalculated = dataTarget && dataTarget.flight_meta ? dataTarget.flight_meta.data[0][dataTarget.flight_meta.lookup["lte_calculated"]] : dataTarget.track_points.data[0][dataTarget.track_points.lookup["lte_calculated"]]

        const { exceedanceSeverityColorMap } = dataContext.COLOR_MAPS.current;
        for (let severity of Object.keys(durations)) {
          let severityIdx = severity.toUpperCase().trim();
          let exceedance_events = durations[severity]["exceedance_events"];
          let color = severityIdx in exceedanceSeverityColorMap ? exceedanceSeverityColorMap[severityIdx] : "";

          let dataPoints = [];
          
          for (let item of Object.keys(exceedance_events)) {
            let label = dataContext.capitalizeWords(item);
            let duration = exceedance_events[item].duration;
            let fieldsOfInterest = {
              "Loss of Control (LOC)": label,
              "Severity": severity,
              "Duration (s)": dataContext.roundStr(duration)
            };

            let tooltipText = getFeaturedTooltip(fieldsOfInterest, color);

            // Track maximum y value for viewport scaling purposes
            if (duration > yMax) {
              yMax = duration;
            }

            dataPoints.push({
              y: duration,
              label: label,
              exceedance: item,
              severity: severity,
              click: handleDrilldown,
              tooltipText: tooltipText,
            });
          }

          exceedanceCount += dataPoints.length;

          dataPointsList.push({
            type: "bar",
            showInLegend: true,
            legendText: `${severityIdx === "NONE" ? "No" : severity} Severity`,
            color: color,
            // indexLabelFontSize: 25,
            dataPoints: dataPoints,
          });
        }

        // console.log("Exceedance Event Durations Data Points:", dataPointsList);
        // console.log("|-- Total Exceedances:", exceedanceCount);

        // Divide exceedance count by total number of keys in data points list to account for space provided for each severity
        exceedanceCountPadded = dataPointsList.length > 0 ? parseInt(exceedanceCount / dataPointsList.length) : exceedanceCount;
        // console.log(`exceedanceCountPadded = ${exceedanceCountPadded}`);

        // Compute lower bound and upper bound for viewport
        let lowerBound = (exceedanceCountPadded - VISIBLE_POINTS_THRESHOLD) + VISIBLE_POINTS_PADDING;
        let upperBound = exceedanceCountPadded + VISIBLE_POINTS_PADDING;

        // Create formatted data structure for canvasjs chart
        // See data series attributes at https://canvasjs.com/docs/charts/chart-options/data/
        // TODO duplicated as example for final format (will need to make dynamic based on values)
        setOptions({
          ...options,
          axisX: {
            ...options.axisX,
            // NOTE: Set viewport minimum relative to maximum count to ensure the first datapoint is visible within viewport bounds 
            // For example, if countMax is 100 and VISIBLE_POINTS_THRESHOLD is 10, then viewportMinimum will be 90
            viewportMinimum: exceedanceCountPadded > VISIBLE_POINTS_THRESHOLD && lowerBound > 0 ? lowerBound : undefined,
            viewportMaximum: exceedanceCountPadded > VISIBLE_POINTS_THRESHOLD ? upperBound : undefined,
            interval: exceedanceCount > 4 ? 1 : undefined, // Remove numbers when potentially one exceedance type is showing
          },
          axisY: {
            ...options.axisY,
            minimum: 0,
            maximum: yMax,
          },
          data: dataPointsList,
        });
      }

      // Track min and max counts for slider widget control
      setMinScrollValue(0);
      setMaxScrollValue(exceedanceCountPadded);
      setCurrentScrollValue(exceedanceCountPadded);

      // Data points of interest exist when at least one data point exists in the list
      setDataOfInterestExists(dataPointsList.length > 0);
      setDataCalculated(vrsCalculated && lteCalculated)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.baseData, dataContext.filteredData]);

  // Detect changes to viewport min/max state
  useEffect(() => {
    if (viewportMax === 0) return;
    // console.log(`Viewport bounds changed: [${viewportMin}, ${viewportMax}]`);
    setOptions({
      ...options,
      axisX: {
        ...options.axisX,
        // NOTE: Set viewport minimum relative to maximum count to ensure the first datapoint is visible within viewport bounds 
        // For example, if countMax is 100 and VISIBLE_POINTS_THRESHOLD is 10, then viewportMinimum will be 90
        viewportMinimum: viewportMin,
        viewportMaximum: viewportMax,
      }
    });
  }, [viewportMin, viewportMax]);

  useEffect(() => {
    if (isMounted.current) {
      // Re-render chart if there's a change in theme
      if (chartRef.current) {
        // NOTE: must do it this way since options are copied to chart reference (e.g., not bound by state/props)
        chartRef.current.options.theme = dataContext.darkMode ? "dark1" : "light1";
        chartRef.current.render();
      }
    }
  }, [dataContext.darkMode]);

  // Dynamically update width and height of chart to fill parent container on dimension change
  useEffect(() => {
    if (isMounted.current) {
      if (chartRef.current) {
        let yOffset = 20;
        let dh = height - yOffset;
        if (width > 0 && dh > 0) {
          chartRef.current.options.width = width;
          chartRef.current.options.height = dh;
          // chartRef.current.options.dataPointWidth = dh * 0.30;
          chartRef.current.render();
        }
      }
    }
  }, [width, height]);

  useEffect(() => {
    if (!chartRef.current) return;
    if (!chartContainerRef.current) return;

    // Don't allow scrolling if the total number of visible points (bars) is less than or equal to the configured threshold
    if (maxScrollValue <= VISIBLE_POINTS_THRESHOLD) return; 

    const handleMouseScroll = (e) => {
      e.preventDefault();
      const chart = chartRef.current;
      if (!chart) return;

      let axisX = chart.axisX[0];
      let viewportMin = axisX.get("viewportMinimum");
      let viewportMax = axisX.get("viewportMaximum");
      let interval = e.deltaY < 0 ? 1 : e.deltaY > 0 ? -1 : 0; // axisX.get("minimum")
      let newViewportMin, newViewportMax;

      // console.log(`Scroll changed: interval = ${interval}, currentScrollValue = ${currentScrollValue}`);

      // NOTE: Inverted behavior. Scrolling down results in positive delta y values
      if (interval !== 0) {
        newViewportMax = viewportMax + interval;
        newViewportMin = viewportMin + interval;
        setCurrentScrollValue(currentScrollValue + interval);
      }

      if (newViewportMin >= chart.axisX[0].get("minimum") && newViewportMax <= chart.axisX[0].get("maximum") && (newViewportMax - newViewportMin) > (2 * interval)) {
        chart.axisX[0].set("viewportMinimum", newViewportMin, false);
        chart.axisX[0].set("viewportMaximum", newViewportMax);
      }
    }

    chartContainerRef.current.addEventListener('wheel', handleMouseScroll);
    return () => chartContainerRef.current.removeEventListener("wheel", handleMouseScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentScrollValue, maxScrollValue]);

  return (
    <div ref={resizeDetectorRef} style={{ width: "100%", height: "100%" }}>
      {(dataExists && dataOfInterestExists && dataCalculated) && (
        <Stack sx={{ width: "100%", height: "100%" }} spacing={1} direction="row">
          <CanvasJSChart options={options} onRef={ref => chartRef.current = ref} />
          <StyledSlider
            // NOTE: Create dynamic key to make this a controlled slider (prevents error on uncontrolled slider)
            key={`daylight-count-slider-${minScrollValue}-${maxScrollValue}`}
            components={{ Thumb: StyledThumbComponent }}
            sx={{ height: "75%", paddingLeft: 0 }}
            getAriaLabel={() => 'Daylight Count'}
            orientation="vertical"
            // getAriaValueText={valuetext}
            defaultValue={maxScrollValue}
            value={currentScrollValue}
            min={minScrollValue + VISIBLE_POINTS_THRESHOLD}
            max={maxScrollValue}
            valueLabelDisplay="off"
            track={false}
            disabled={maxScrollValue <= VISIBLE_POINTS_THRESHOLD}
            onChange={handleCountRangeChanged}
          // onChangeCommitted={handleCountRangeChanged} // More efficient for rendering performance (if needed)
          />
        </Stack>
      )}
      {(!dataExists) && (
        <div>
          <b>No Data</b>
        </div>
      )}
      {(dataExists && !dataCalculated) && (
        <div>
          {/* Customize message as needed */}
          <b>Insufficient Data</b>
        </div>
      )}
      {(dataExists && dataCalculated && !dataOfInterestExists) && (
        <div>
          {/* Customize message as needed */}
          <b>No Findings</b>
        </div>
      )}
    </div>
  );
}

LocRiskDurationBarChart.propTypes = propTypes;
LocRiskDurationBarChart.defaultProps = defaultProps;

export default LocRiskDurationBarChart;
