import {API} from "aws-amplify";

export function getMyFlights(user, operator, searchObject) {
    const token = user.signInUserSession.idToken.jwtToken;
    const requestInfo = {
      headers: {
        Authorization: token
      },
      body: {
        operator: operator,
        queryParams: searchObject
      }
    }

    // console.log({requestInfo})

    let url = "/raise-dev-get-my-flights"

    const data = API.post('raise-gui-api-dev', url, requestInfo)
      return data;
}

export default getMyFlights;