import React, { useContext } from 'react';
import { DataContext } from '../DataContext';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const propTypes = {
  dataTargetKey: PropTypes.string,
  columnsOfInterest: PropTypes.arrayOf(PropTypes.object),
};

const defaultProps = {
  dataTargetKey: "track_points",
  columnsOfInterest: [],
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function StaticMetadataViewer({
  dataTargetKey,
  columnsOfInterest,
}) {
  const dataContext = useContext(DataContext);
  const targetData = dataContext.filteredData ? dataContext.filteredData : dataContext.baseData;
  const valid = targetData && targetData[dataTargetKey] && targetData[dataTargetKey].data && targetData[dataTargetKey].data.length > 0;
  const lookup = valid && dataTargetKey in targetData ? targetData[dataTargetKey].lookup : null;
  const data = valid && dataTargetKey in targetData ? targetData[dataTargetKey].data : null;

  return (
    <Paper elevation={0} sx={{ width: '100%', height: '100%' }}>
      {valid && (
        <TableContainer sx={{ maxHeight: 440, overflow: "auto" }}>
          <Table size="small" aria-label="static metadata table">
            <TableBody>
              {columnsOfInterest.map((item) => (
                <StyledTableRow key={item.col}>
                  <StyledTableCell align="right">
                    <b>{item.alias ? item.alias : item.col}</b>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {item.col in lookup && (
                      item.modifier ? item.modifier(data[0][lookup[item.col]]) : data[0][lookup[item.col]]
                    )}

                    {/* Display empty string if invalid mapping */}
                    {(!(item.col in lookup)) && ""}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!valid && (
        <div>
          <b>No Data</b>
        </div>
      )}
    </Paper>
  );
}

StaticMetadataViewer.propTypes = propTypes;
StaticMetadataViewer.defaultProps = defaultProps;

export default StaticMetadataViewer;
