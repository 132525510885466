import {API} from "aws-amplify";

export function getAirportApproachFlights(user, operator, airportId) {
    const token = user.signInUserSession.idToken.jwtToken;
    const requestInfo = {
      headers: {
        Authorization: token
      },
      body: {
        airportId: airportId,
        operator: operator
      }
    }

    // console.log({requestInfo})

    let url = "/raise-get-approach-flights"

    const data = API.post('raise-gui-api-dev', url, requestInfo)
      return data;
}

export default getAirportApproachFlights;