import React, { useState, useRef, useContext } from "react";
import { DataContext } from "../DataContext";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from "@mui/material/Tooltip";
import FilterControlWidget from "../FilterControlWidget";

// Styles
import "./Controls.css";

const propTypes = {};
const defaultProps = {};

const PHASE_OF_FLIGHT_TITLE = "Phase of Flight";

function PhaseOfFlightControls() {
  const dataContext = useContext(DataContext);

  // Track selected filters from each FilterSelectWidget instance
  const selectedFiltersRef = useRef({
    [dataContext.PHASE_OF_FLIGHT_SELECT_KEY]: { title: PHASE_OF_FLIGHT_TITLE, label: "Loading Options...", values: [], track: false },
  });

  const filterApplierTimeoutRef = useRef(null);

  const [selectedFilters, setSelectedFilters] = useState({ ...selectedFiltersRef.current });
  const [expanded, setExpanded] = useState(dataContext.controlsExpandedRef.current);

  const currentTheme = dataContext.darkMode ? dataContext.styles.darkTheme : dataContext.styles.lightTheme;

  /**
   * Apply filters with selection data.
   * 
   * @param {object} selectionData Set of selection data to apply as filters in data context.
   * @param {boolean} useTimer Whether to use the filter applier timer before processing selection data.
   */
   const handleApplyFilters = (selectionData, { useTimer=false } = {}) => {
    // Build data structure that imitates control filter
    // EXAMPLE:
    // {
    //   "phaseOfFlightSelect": {
    //     "id": "phaseOfFlightSelect",
    //     "label": "Standing, Hover Ground Effect",
    //     "column": "phaseofflight_mavg10",
    //     "values": [
    //       "standing",
    //       "hover ground effect"
    //      ]
    //    }
    // }
    // Build filters
    let filters = {};
    for (let key of Object.keys(selectionData)) {
      let selection = selectionData[key];
      const { id, label, values, column, track } = selection;
      filters[selection.id] = {
        id: id,
        label: label,
        column: column,
        values: values,
        track: track,
      };
    }

    // Use timer as desired to prevent spaming filters
    if (useTimer) {
      // Reset filter applier timer if it's already active
      if (filterApplierTimeoutRef.current) {
        clearTimeout(filterApplierTimeoutRef.current);
      }

      // Apply filters after configured amount of time
      filterApplierTimeoutRef.current = setTimeout(() => {
        dataContext.preventUIBlocking();

        dataContext.showSnack(
          "top",
          "center",
          "Applying phase of flight filters...",
          dataContext.SNACK_SEVERITY.INFO,
          2000
        );

        // console.log("Applying filters:", filters);
        dataContext.addControlFilter(filters);
      }, dataContext.FILTER_TIMEOUT_MILLIS);
    } else {
      // Otherwise, just handle the control filter without tracking
      dataContext.addControlFilter(filters);
    }
  }

  /**
   * Update the respective list of filters for a designated filter select component instance.
   * 
   * @param {object} selectionData Dictionary containing selected values and other metadata corresponding to the 
   *                               filter select component instance.
   */
   const handleFilterControlChange = (selectionData) => {
    selectedFiltersRef.current = {
      ...selectedFiltersRef.current,
      [selectionData.id]: selectionData
    };

    setSelectedFilters({ ...selectedFiltersRef.current });

    // Only apply filters if propagation is desired
    if (selectionData.propagate) {
      // console.log("Applying new selected filters in phase of flight controls:", selectedFiltersRef.current);
      handleApplyFilters(selectedFiltersRef.current, { useTimer: selectionData.track });  
    }
  }

  const handleExpandToggle = () => {
    setExpanded(!expanded);
    dataContext.controlsExpandedRef.current = !expanded;
  }

  return (
    <Accordion
      sx={currentTheme}
      expanded={expanded}
    >
      <AccordionSummary
        className={dataContext.classes.current.summary}
        expandIcon={
          <Tooltip
            title={expanded ? "Collapse Controls" : "Expand Controls"}
            placement="top"
            arrow
            aria-label={expanded ? "Collapse Controls" : "Expand Controls"}
          >
            <ExpandMoreIcon
              className={dataContext.classes.current.icon}
              sx={currentTheme}
            />
          </Tooltip>
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
        onClick={handleExpandToggle}
      >
        {/* Controls header with summary */}
        <div style={{ marginTop: "auto", marginBottom: "auto" }}>
          <span style={{ fontSize: "1.2em", marginRight: "30px" }}>Controls</span>
          {/* Collapsed controls filter selection summary */}
          {!expanded &&
            // Filter summary from filter control
            // EXAMPLE
            // <span>
            //   <b>VRS</b> {"CURRENT LABEL HERE"}
            // </span>
            Object.keys(selectedFilters).map(key => (
              <Tooltip
                key={selectedFilters[key].title}
                title={selectedFilters[key].label}
                enterDelay={1000}
              >
                <span className="collapsed-container">
                  <p className="collapsed-content">
                    <b>{selectedFilters[key].title}</b> {selectedFilters[key].label}
                  </p>
                </span>
              </Tooltip>
            ))
          }
        </div>
      </AccordionSummary>
      <AccordionDetails className="control-details">
        <FilterControlWidget 
          id={dataContext.PHASE_OF_FLIGHT_SELECT_KEY}
          title={PHASE_OF_FLIGHT_TITLE}
          dataKey="unique_track_points"
          groupByColumnName="phaseofflight_mavg10"
          tooltipPlacement="right"
          onSelectionChange={
            (selectionData) => handleFilterControlChange(selectionData)
          }
        />
      </AccordionDetails>
    </Accordion>
  );
}

PhaseOfFlightControls.propTypes = propTypes;
PhaseOfFlightControls.defaultProps = defaultProps;

export default PhaseOfFlightControls;

