import {API} from "aws-amplify";

export function getExceedanceTypes(user) {

    const token = user.signInUserSession.idToken.jwtToken;
    const requestInfo = {
      headers: {
        Authorization: token
      }
    }

    const data = API.get('raise-gui-api-dev', "/get-exceedance-types", requestInfo)
  // console.log({data})
      return data;
 
}

export default getExceedanceTypes;