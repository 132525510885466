import {API} from "aws-amplify";
import {getAllFromLS} from "../helper-functions/LsHelper"

function savePreferences(authUser) {
    const token = authUser.signInUserSession.idToken.jwtToken;
    const requestInfo = {
      headers: {
        Authorization: token
      },
      body: {
        'preferences': getAllFromLS(),
      }
    }
  // console.log({requestInfo})
    var url = "/save-preferences"
    
  // console.log("Debugging")
  // console.log({url})

    const data = API.post('raise-gui-api-dev', url, requestInfo)
  // console.log({data})
      return data;

   
}

export default savePreferences;