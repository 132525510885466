import React, {useState} from 'react'

import './UnauthNav.css'


const UnauthBanner = () => {
    //Amplify can't have a null for the variable, so check against empty quotes so we can null the message as well.
    const [display, setDisplay] =useState(process.env.REACT_APP_ADMIN_LOGIN_MESSAGE && process.env.REACT_APP_ADMIN_LOGIN_MESSAGE != '' && process.env.REACT_APP_ADMIN_LOGIN_MESSAGE != '""')
    
    const [bannerMessage, setBannerMessage] =useState(process.env.REACT_APP_ADMIN_LOGIN_MESSAGE)
  
    const bannerBg = () => {
      let keyWord = bannerMessage.split(":")
      switch (keyWord[0]) {
      case 'Offline':
        return '#E9967A'; //DarkSalmon
      case 'Maintenance':
        return '#FFD700'; //Gold
      case 'Feature':
        return '#8FBC8F0'; //DarkSeaGreen
      default:
        return '#6495ED'; //CornflowerBlue      
      }
    }



    return (
        <>
        {display && (
            <div style={{backgroundColor:bannerBg(),textAlign:'center'}}>
              {bannerMessage}  
            </div>
            )}
            
        </>
    )
}

export default UnauthBanner
