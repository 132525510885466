import { API } from 'aws-amplify'


export function getCognitoGroups(user)  {
    const token = user.signInUserSession.idToken.jwtToken;
    const requestInfo = {
      headers: {
        Authorization: token
      }
    }
  // console.log({token})
    const data = API.get('raise-gui-api-dev', "/get-cognito-groups", requestInfo)
  // console.log(data)
    return data;

}

export default getCognitoGroups;
