import React, { useState, useEffect, useContext, useCallback, useRef } from 'react'
import DashboardNav from '../components/DashboardNav'
import {
  Search,
  Dropdown,
  Button,
  TextInput,
  Checkbox,
  FormGroup,
  Label,
  ButtonGroup,
  Textarea
} from '@trussworks/react-uswds'
import getCognitoUsers from '../services/getCognitoUsers'
import getOperatorsList from '../services/getOperatorsList'
import { useTable, useFilters, useSortBy } from 'react-table'
import UserStatusTag from '../components/UserStatusTag'
import Modal from 'react-bootstrap/Modal'
import updateCognitoUser from '../services/updateCognitoUser'
import sendDenyEmail from '../services/sendDenyEmail'
import { Container, Row, Col } from 'react-bootstrap'
import UserContext from '../components/UserContext';
import getCognitoGroups from '../services/getCognitoGroups'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { matchSorter } from 'match-sorter'
import deleteCognitoUser from "../services/deleteCognitoUser"
import sendEmail from "../services/sendEmail"
import PageLoadSpinner from "../components/PageLoadSpinner"
import { ConstructionOutlined } from '@mui/icons-material'

const UserManagement = () => {

  const [usersList, setUsersList] = useState([])
  const [operatorsList, setOperatorsList] = useState("")
  const [displayOperatorsList, setDisplayOperatorsList] = useState("")
  const [dtoList, setDtoList] = useState("")
  const [dtoManagedList, setDtoManagedList] = useState("")
  const [isLoaded, setIsLoaded] = useState(false)
  const [dtoSelected, setDtoSelected] = useState(false)
  const [operatorSelected, setOperatorSelected] = useState(false)
  const [userIsDeniedInBackend, setUserIsDeniedInBackend] = useState(false)
  

  // To handle modal
  const [show, setShow] = useState(false);
  const [showDenyModal, setShowDenyModal] = useState(false);
  const [showLoading, setShowLoading] = useState()
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseLoading = () => setShowLoading(false);


  const handleDenyClose = () => {
    if(!userIsDeniedInBackend){
      setShow(true);
    }
    setShowDenyModal(false);
  }

  const handleShowDeny = () => {
    setShow(false);
    setShowDenyModal(true);
  }

  const onBlurDenyReasonTrim = (evt) => {
    setDenyReason(evt.target.value.trim())
      evt.target.value = evt.target.value.trim()
    }

  const [userStatus, setUserStatus] = useState([])

  // For user attributes on modal
  const [userEmail, setUserEmail] = useState("")
  const [userFirstName, setUserFirstName] = useState("")
  const [userLastName, setUserLastName] = useState("")
  const [userOrg, setUserOrg] = useState("")
  const [userPhone, setUserPhone] = useState("")
  const [userGroups, setUserGroups] = useState([])
  const [userOperator, setUserOperator] = useState("")
  const [userDto, setUserDto] = useState("")
  const [adminEnabled, setAdminEnabled] = useState()
  const [userAccountStatusOriginal, setUserAccountStatusOriginal] = useState()
  const [userIdentifier, setUserIdentifier] = useState()
  const [secondaryPhone, setSecondaryPhone] = useState()
  const [approverComments, setApproverComments] = useState('')
  const [phoneIsMobile, setPhoneIsMobile] = useState()
  const [denyReason, setDenyReason] = useState()

  const [rolesDropdownList, setRolesDropdownList] = useState()
  const [rolesSelected, setRolesSelected] = useState()
  const [nameFilter, setNameFilter] = useState()

  const contextUser = useContext(UserContext)

  const animatedComponents = makeAnimated();

  // The callback function later will initialize when rendered and hold the state list.  This useRef will maintain a handle to the latest
  // data so the sort function can get the data to resort on.
  const usersListRef = useRef();
  const rolesListRef = useRef();
  
  usersListRef.current = usersList;


  const [error, setError] = useState([])
  useEffect(() => {
    getCognitoUsers(contextUser.user)
      .then((result) => {

        let userOut = result.Users
        // userOut.forEach(user => {
        //   let jsonList = JSON.stringify(user.Attributes)
        // })
        setUsersList(result.Users)
        setIsLoaded(true)
      })
      .catch((error) => {
        setError(error)
      })
    getOperatorsList(contextUser.user)
      .then((result) => {
        setOperatorsList(result[0])
        setDisplayOperatorsList(result[0])
        let tempList = [...new Set(result[0].filter(q => q.DTO !== "FAA").map(q => q.DTO))]
        setDtoList(tempList)

      })
      .catch((error) => {
        setError(error)
      })
    getCognitoGroups(contextUser.user)
      .then((result) => {
        setRolesDropdownList(buildOptionsList(result.Groups, "GroupName", "Description"))
      })
      .catch((error) => {
        setError(error)
      })

  }, [])

    useEffect(() => {
      
      // Filter the operator list shown on screen based off the chosen dto
      if(operatorsList){
        let tempDisplayList = operatorsList.filter(q => q.DTO == userDto)
        let tempList = operatorsList.filter(q => q.DTO == userDto).map(q => q.Operator + ", ")
        if(tempList && tempList.length > 0){
          let lastElem = tempList.pop()
          tempList.push(lastElem.substring(0, lastElem.length - 2))
        }
        setDtoManagedList(tempList)
        // Update the operator list
        if(tempDisplayList && tempDisplayList.length > 0){
          setDisplayOperatorsList(tempDisplayList)
          if(!tempDisplayList.some(v => (v.Operator === userOperator))){
            setUserOperator("")
          }
        } else {
          setDisplayOperatorsList(operatorsList)
        }
      }
    }, [userDto])

  

  // const onUserDTOSelection = ((newValue) => {
  //   if(newValue && newValue !== ''){
  //     if(userOperator)
  //   }

  // })



  const listGroups = ((rows) => {
    var groups = null
    rows.value.forEach((row) => {
      if (row.Name === 'groups') {
        groups = row.Value
      }
    })
    setRolesSelectedHandler(buildOptionsList(groups, "GroupName", "Description"))
    return groups
  })

  const buildOptionsList = ((response, identifier, displayIdentifier) => {

    var multiSelectGroup = [];
    response.forEach((groupObj) => {
      multiSelectGroup.push({ label: groupObj[displayIdentifier], value: groupObj[identifier] })
    })
    return multiSelectGroup
  })

  // Sets the infomation for and displays the user edit modal
  const userEdit = (rows) => {
    setUserEmail(rows.row.original.jsonAttributes.email)

    var username = rows.row.original.jsonAttributes.name
    if (username != null) {
      //User name does not support 2 word first names currently, could be changed to comma separated?
      // username = username.split(' ')
      var first_name = rows.row.original.jsonAttributes.firstName
      var last_name = rows.row.original.jsonAttributes.lastName
      setUserFirstName(first_name)
      setUserLastName(last_name)
    }

    
    setDenyReason(rows.row.original.jsonAttributes.DenialReason)
    setUserIsDeniedInBackend(rows.row.original.jsonAttributes.DenialReason ? true : false)
    
    //Set actual values for the user in the popup
    setUserOrg(rows.row.original.jsonAttributes["custom:organization"])
    if(rows.row.original.jsonAttributes && rows.row.original.jsonAttributes.groups && rows.row.original.jsonAttributes.groups.some(v => v.GroupName === "operator")){
      setOperatorSelected(true)
      setDtoSelected(false)
      setUserOperator(rows.row.original.jsonAttributes["custom:operator"])
    }
      
    if(rows.row.original.jsonAttributes && rows.row.original.jsonAttributes.groups && rows.row.original.jsonAttributes.groups.some(v => v.GroupName === "dto")){
      setDtoSelected(true)
      setOperatorSelected(false)
      setUserDto(rows.row.original.jsonAttributes["custom:dto"])
    }
    setUserPhone(rows.row.original.jsonAttributes.phone_number)
    setUserIdentifier(rows.row.original.jsonAttributes["sub"])
    setAdminEnabled(rows.row.original.Enabled)
    setUserAccountStatusOriginal(rows.row.original.Enabled)
    setSecondaryPhone(rows.row.original.jsonAttributes["custom:secondaryPhone"])
    setPhoneIsMobile(rows.row.original.jsonAttributes["custom:phoneIsMobile"] === 'True')
    setApproverComments(rows.row.original.jsonAttributes["custom:approverComments"])

    setUserGroups(listGroups(rows))



    // Show the modal.  If the user has been denied, show the denial modal instead
    if(rows.row.original.jsonAttributes.DenialReason) {
      setShowDenyModal(true)
    } else{
      setShow(true)
    } 
    
  }

  const setRolesSelectedHandler = (newVal) => {
    // Flags
    let dtoFlag = false;
    let operatorFlag=false;
    //Handler to check if the new values contain a specific item
    if(newVal.some(v => v.value === "dto") && rolesSelected && !rolesSelected.some(v => v.value === 'dto' )){
      dtoFlag = true
      let filteredList = newVal.filter(v => {return v.value !== 'operator'})
      setUserOperator("")
      setRolesSelected(filteredList)
    }
    else if(newVal.some(v => v.value === "operator") && rolesSelected && !rolesSelected.some(v => v.value === 'operator' )){
      operatorFlag = true
      setUserDto("")
      let filteredList = newVal.filter(v => {return v.value !== 'dto'})
      setRolesSelected(filteredList)
    } else {
      //set the value as normal
      setRolesSelected(newVal)
    }
    if (rolesSelected) { // Only run after first load when stuff has initialized
    
    setDtoSelected(dtoFlag)
    setOperatorSelected(operatorFlag)
  }
  }

  // Handles when save button is clicked
  const handleEdit = () => {
    handleClose();
    setShowLoading(true);
    const user = {
      'email': userEmail,
      'name': userFirstName + ' ' + userLastName,
      'operator': operatorSelected ? userOperator : '',
      'organization': userOrg,
      'adminEnabled': denyReason ? false: adminEnabled || false,
      'phoneNumber': userPhone,
      'groups': rolesSelected,
      'userIdentifier': userIdentifier,
      'userDto': dtoSelected ? userDto : '',
      'secondaryPhone': secondaryPhone,
      'phoneIsMobile': phoneIsMobile,
      'denialReason': ''
    }

    updateCognitoUser(contextUser.user, user)
      .then((result) => {
        if(adminEnabled && !userAccountStatusOriginal){
          // User was not enabled, but now is
          let email = {
            emailSubject: "Your RAISE Account has been reviewed and approved.",
            emailBodyText: "Your raise account has been reviewed and approved by the administrators.  You may access the application via " + process.env.REACT_APP_WEB_URL,
            emailBodyHTML: "Your raise account has been reviewed and approved by the administrators.  You may access the application via " + process.env.REACT_APP_WEB_URL,
            emailRecipients: userEmail
          }
          sendEmail(contextUser.user, email).then((results) => {
            contextUser.updateCurrentUser()
            handleCloseLoading();
            alert('You have successfully activated the user')
            window.location.reload()
          })
        } else {
          setShowLoading(false, () => {
          });
          alert('You have successfully updated the user')
          window.location.reload()
        }
      })
      .catch((error) => {
        setError(error)
      })
        
    // Close the modal
    handleClose()
  }

  const saveDenyUser = () => {
    handleClose();
    setShowLoading(true);
    const user = {
      'email': userEmail,
      'name': userFirstName + ' ' + userLastName,
      'operator': operatorSelected ? userOperator : '',
      'organization': userOrg,
      'adminEnabled': false,
      'phoneNumber': userPhone,
      'groups': rolesSelected,
      'userIdentifier': userIdentifier,
      'userDto': dtoSelected ? userDto : '',
      'secondaryPhone': secondaryPhone,
      'phoneIsMobile': phoneIsMobile,
      'denialReason': denyReason
    } 
    // Update user to remove any lingering permissions
    updateCognitoUser(contextUser.user, user)
      .then((result) => {
        if(denyReason){ //User has a deny reason and is being denied.
        // Send the deny email.
        sendDenyEmail(contextUser.user, userEmail)
        // Delete the user.
        deleteCognitoUser(contextUser.user, user).then((result) => {
          contextUser.updateCurrentUser()
          handleCloseLoading();
          alert('User has been deleted and informed of their denial.')
          window.location.reload()
        })
        }
        else {
          // User had deny reason removed.
          handleCloseLoading();
          alert('User denial removed, user may be actioned normally.')
        window.location.reload()
        }
        
      })
      .catch((error) => {
        setError(error)
      })
    

    // Close the modal
    setShow(false);
    setShowDenyModal(false);

    // handleEdit()
  }

  const sendDenialEmail = () => {
      
      
  }

  const handleSort = useCallback(sortBy => {
    // Doing multisort
    let sorted = usersListRef.current.slice();//usersList.slice();
    sorted.sort((a, b) => {
      for (let i = 0; i < sortBy.length; ++i) {
        let sortBySplit = sortBy[0].id.split(".")
        if (a[sortBySplit[0]][sortBySplit[1]] > b[sortBySplit[0]][sortBySplit[1]]) return sortBy[i].desc ? -1 : 1;
        if (a[sortBySplit[0]][sortBySplit[1]] < b[sortBySplit[0]][sortBySplit[1]]) return sortBy[i].desc ? 1 : -1;
      }
      return 0;
    });
    setUsersList(sorted);
  }, []);



  const columns = React.useMemo(
    () => [
      {
        Header: 'Username',
        accessor: 'jsonAttributes.name',
        filter: 'fuzzyText',
        filterValue: nameFilter,
        id: '1',
        Cell: (rows) => {
          return (
            <div>
              {rows.row.original.jsonAttributes.name}
              <UserStatusTag message={rows.row.original.Enabled} denied={rows.row.original.jsonAttributes.DenialReason} />
            </div>
          );
        }
      },
      {
        Header: 'Contact Info',
        accessor: 'jsonAttributes.firstName',
        Cell: (rows) => {

          return (
            <div>
              <div>
                {rows.row.original.jsonAttributes.email}
              </div>
              <div>
                {rows.row.original.jsonAttributes.phone_number}
              </div>
            </div>
          );
        }
      },
      {
        Header: 'Role',
        accessor: 'jsonAttributes.lastName',
        canFilter: false,
        Cell: (rows) => {


          return (
            <div>
              {rows.row.original.jsonAttributes.groups.map((data, index) =>
                <div key={index}>
                  {data.Description}
                </div>

              )}
            </div>
          );
        }
      },
      {
        Header: 'Operator',
        accessor: 'jsonAttributes.operator',
        canFilter: false,
        Cell: (rows) => {
          return (
            <div>
              {rows.row.original.jsonAttributes['custom:operator']}
            </div>
          );
        }
      },
      {
        Header: '',
        accessor: 'Attributes',
        canFilter: false,
        id: '5',
        Cell: (rows) => {
          return (

            <Button type="button" onClick={(e) => userEdit(rows)} outline>Edit</Button>

          );
        }
      },
    ],
    []
  )


  // const {
  //   getTableProps,
  //   getTableBodyProps,
  //   headerGroups,
  //   rows,
  //   prepareRow,
  // } = useTable({ columns, data: state })

  return (
    <div>
      <DashboardNav />
      <div className="stretch_grid-container">
        <h1>User Management</h1>



        <Table columns={columns} data={usersList} filterName={nameFilter} onSort={handleSort} />


        <Modal size="sm" show={showLoading} scrollable={false} onHide={handleCloseLoading}>
          <Modal.Header>
            <Modal.Title>Submitting</Modal.Title>
          </Modal.Header>
          <Modal.Body><div style={{display:'flex'}}>
          <PageLoadSpinner />
          <div style={{paddingLeft:'10px'}}>
          Please wait while your request is processed.
          </div>
          </div>
          </Modal.Body>
          </Modal>

        <Modal size="lg" show={show} scrollable={true} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>Edit User</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div>


              <h3 className="registration-h3"> Account Information</h3>
              <p className="registration-p">Edit users information below</p>
            </div>
            <div className="userManagementBody">
              <Container className="userManagementContainer">
                <Row className="userManagementRow">
                  <Col><Label htmlFor="email-input">Email</Label>
                    <TextInput
                      id="email-input"
                      name="email-input"
                      type="email"
                      defaultValue={userEmail}
                      onChange={evt => setUserEmail(evt.target.value, () => { })}
                    ></TextInput></Col>
                  <Col>
                    <Label htmlFor="organization-input">Organization</Label>
                    <TextInput
                      id="organization-input"
                      name="organization-input"
                      type="text"
                      disabled={true}
                      defaultValue={userOrg}
                      onChange={evt => setUserOrg(evt.target.value, () => { })}
                    />
                  </Col>
                </Row>
                <Row className="userManagementRow">
                  <Col>
                    <Label htmlFor="first-name-input">First name</Label>
                    <TextInput
                      id="first-name-input"
                      name="first-name-input"
                      type="text"
                      defaultValue={userFirstName}
                      onChange={evt => setUserFirstName(evt.target.value, () => { })}
                    />
                  </Col>
                  <Col>
                    <Label htmlFor="last-name-input">Last name</Label>
                    <TextInput
                      id="last-name-input"
                      name="last-name-input"
                      type="text"
                      defaultValue={userLastName}
                      onChange={evt => setUserLastName(evt.target.value, () => { })}
                    />
                  </Col>
                </Row>
                <Row className="userManagementRow">
                  <Col>
                    <Label htmlFor="phone-input">Primary Phone Number</Label>
                    <TextInput
                      id="phone-input"
                      name="phone-input"
                      type="tel"
                      onChange={evt => setUserPhone(evt.target.value, () => { })}
                      defaultValue={userPhone}

                    />
                    {/* <Checkbox className="checkbox" id="phone-input-checkbox" name="phone-input-checkbox" label="Mobile" /> */}

                  </Col>
                  <Col>
                    <Label htmlFor="secondary-phone-input">Secondary Phone Number</Label>
                    <Container style={{ display: 'inline-flex', paddingLeft: '0px', paddingRight: '0px' }}>
                      <TextInput
                        id="secondary-phone-input"
                        name="secondary-phone-input"
                        type="tel"
                        pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                        defaultValue={secondaryPhone}
                        onChange={evt => setSecondaryPhone(evt.target.value, () => { })}
                        style={{ marginRight: '10px', marginTop: '8px' }}
                      />
                      <Checkbox style={{ paddingTop: '0px' }} id="secondary-phone-input-checkbox" name="secondary-phone-input-checkbox" label="Mobile" checked={phoneIsMobile} onChange={evt => setPhoneIsMobile(evt.target.checked)} />
                    </Container>
                  </Col>
                </Row>

                <Row className="userManagementRow">
                  <Col>
                    <Label htmlFor="application-role-select">Application Roles</Label>
                    <Select
                      id="application-role-select"
                      
                      style={{ borderColor: "black" }}
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      value={rolesSelected}
                      isMulti
                      onChange={(event) => setRolesSelectedHandler(event)}
                      options={rolesDropdownList}
                    />
                  </Col>
                  {/* <Col>
                            <Label htmlFor="role-list">Role(s)</Label>
                            <p>(can be managed in cognito console)</p>
                            <ul>
                              {
                                userGroups.map((data, index) => (
                                    <li key={index}>
                                      {data.GroupName}
                                    </li>
                                ))
                              }
                            </ul>

                            </Col> */}
                </Row>
                <Row className="userManagementRow">
                  <Col>
                    <Label htmlFor="operator-select">Operator</Label>
                    <Dropdown id="operator-select" name="operator-select"

                      value={userOperator}
                      disabled={!operatorSelected}
                      onChange={evt => setUserOperator(evt.target.value)}
                    // required
                    >
                      <option value="">None</option>

                      {
                        isLoaded && displayOperatorsList && (

                          displayOperatorsList.map((data, index) => (
                            <option key={index} value={data.Operator}>
                              {data.Operator}
                            </option>
                          ))

                        )

                      }
                    </Dropdown>
                  </Col>
                  <Col>
                    <Label htmlFor="dto-select">Data Transfer Organization</Label>
                    <Dropdown id="dto-select" name="dto-select"
                      value={userDto}
                      onChange={evt => setUserDto(evt.target.value)}
                      disabled={!dtoSelected}
                    >
                      <option value="">None</option>

                      {
                        isLoaded && dtoList && (

                          dtoList.map((data, index) => (
                            <option key={index} value={data}>
                              {data}
                            </option>
                          ))

                        )

                      }
                    </Dropdown>
                    {dtoSelected ? 
                    <div>This DTO manage the following Operators: {dtoManagedList}</div>
                    :
                    <div></div>
                    }
                  </Col>
                </Row>
                <Row className="userManagementRow">
                  <Col>
                    Account Status
                    <Checkbox
                      label="Enabled"
                      id="enable-user-checkbox"
                      name="enable-user-checkbox"
                      className="checkbox"
                      checked={adminEnabled}
                      onChange={evt => setAdminEnabled(evt.target.checked)}
                    />
                  </Col>
                </Row>
                <Row className="userManagementRow">
                  <Col>
                    <Textarea
                      style={{ maxWidth: "50rem" }}
                      id="comments-to-approver-text"
                      name="comments-to-approver-text"
                      defaultValue={approverComments}
                      maxLength="2000"
                      disabled={true}
                    />
                  </Col>
                </Row>


              </Container>


            </div>
          </Modal.Body>
          
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary"  hidden={adminEnabled} onClick={handleShowDeny}>
              Deny and Delete Account 
            </Button>
            <Button variant="primary" onClick={handleEdit}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>



        <Modal size="lg" show={showDenyModal} scrollable={true} onHide={handleDenyClose}>
          <Modal.Header>
            <Modal.Title>Deny User Registration</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <h3 className="registration-h3">Deny User Account Request</h3>
              <p className="registration-p">Enter a note or reason below to deny the user.  The user will not see this information, it is for internal reference only.  A denied user will be deleted and emailed a notice.  A previous denial reason must be cleared before the account can be actioned normally.</p>
              <p className="registration-p">Saving this page with a deny reason present will delete the user's account and send them a denial email.</p>
            </div>
            <div className="userManagementBody">
              <Container className="userManagementContainer">
                <Row className="userManagementRow">
                  <Col><Label htmlFor="deny-reason">Deny Reason</Label>
                    <Textarea
                      style={{ maxWidth: "50rem" }}
                      id="deny-reason"
                      name="deny-reason"
                      defaultValue={denyReason}
                      onBlur={evt => onBlurDenyReasonTrim(evt)}
                      onChange={evt => setDenyReason(evt.target.value, () => { })}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          </Modal.Body>
          <Modal.Footer>
          
            
            
            <Button variant="secondary" onClick={handleDenyClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={saveDenyUser}>
              Save
            </Button>
            
          </Modal.Footer>
        </Modal>

      </div>
    </div>
  )
}













// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  // const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder="Search username"
    />
  )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  // rows.forEach(row => {
  //   let values = row.values[1].forEach((token, index) => {
  //     token.Name === 'name' ? console.log(index) : console.log("Not found yet")
  //   })
  // })
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

// Our table component
function Table({ columns, data, onSort }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn
    }),
    []
  )


  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // state,
    visibleColumns,
    setFilter,
    setSortBy,
    state: { sortBy }
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      manualSortBy: true
    },
    useFilters,
    useSortBy
  )

  useEffect(() => {
    onSort(sortBy);
  }, [onSort, sortBy]);

  const [sortByValue, setSortByValue] = useState()

  // Function to link the text box to the hook for filtering
  const onFilterChange = e => {
    setFilter('1', e.target.value || undefined)
    // setNameFilter(e.target.value)
  }
    ;
  // Function to link the text box to the hook for filtering
  const onFilterSubmit = e => {
    e.preventDefault()
    // let debug = e.target
    // setFilter('1', e.target.value || undefined)
    // setNameFilter(e.target.value)
  }
    ;

  const onSortByChange = e => {
    setSortByValue(e.target.value)
    setSortBy([{ id: 'jsonAttributes.' + e.target.value, desc: false }])
  }

  return (
    <>
      <Search
        style={{ width: '30em', float: 'left', marginRight: '10px' }}
        placeholder="Search Users"
        onChange={event => { onFilterChange(event) }}
        size="small"
        onSubmit={event => onFilterSubmit(event)}
      />
      <p style={{ float: 'left', padding: '10px 10px 10px 10px' }}>Sort by</p>
      <Dropdown style={{ width: '20em', height: '32px', float: 'left', paddingTop: '0px', marginTop: '0px', marginRight: '10px', paddingBottom: '0px' }}
        id="sort-dropdown" name="sort-dropdown"
        onChange={evt => onSortByChange(evt, () => { })}>
        <option >Select an Option</option>
        <option value="lastName">Last Name</option>
        <option value="firstName">First Name</option>
        <option value="custom:operator">Operator</option>
      </Dropdown>
      <table {...getTableProps()} style={{ marginTop: '8em', width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}
              style={{ borderBottom: '1px solid grey' }}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    color: 'black',

                    fontWeight: 'bold',
                  }}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: '10px',
                        borderBottom: 'solid 2px grey',
                      }}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}

export default UserManagement
