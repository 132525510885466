import React, { useContext } from 'react'
import UnauthNav from '../components/UnauthNav';
import DashboardNav from '../components/DashboardNav'
import HelpContents from '../components/HelpContents';
import UserContext from '../components/UserContext'

const Help = () => {

  const userContext = useContext(UserContext);
  const isLoggedOn = userContext.user && userContext.user.username ? true : false

  return (
    <>
    {isLoggedOn ? 
    <DashboardNav />
    : <UnauthNav />}
    <HelpContents />
    
    </>
  )
}

export default Help
