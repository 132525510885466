import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { DataContext } from "../DataContext";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Grid from '@mui/material/Grid'
import { Box, createTheme, ThemeProvider } from '@mui/material';
import TableRow from '@mui/material/TableRow';




const styles = {
  container: {
    position: "fixed",
    top: 80,
    bottom: 0,
    left: 5,
    right: 0,
    overflow: "auto",
    width: "99%",
    whiteSpace: "nowrap",
    marginTop: 50,
    marginBottom: 10,
  }
};


const propTypes = {
  dataTargetKey: PropTypes.string,
  columnsOfInterest: PropTypes.arrayOf(PropTypes.object),
};

const defaultProps = {
  dataTargetKey: "track_points",
  columnsOfInterest: [],
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));



const theme = createTheme({
  breakpoints:{
    xs:0,
    sm:250,
    md:500,
    lg:750,
    xl:1000,
  }
})

const columns = [
  {
    field:'id',
    headerName:'',
  },
  {
    field:'min',
    headerName:'Min',
  },
  {
    field:'max',
    headerName:'Max',
  },
  {
    field:'mean',
    headerName:'Mean',
  },
]

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function StaticFieldsViewer({
  key,
  metaDataToDisplay,
  tableDataToDisplay,
}) {
  const dataContext = useContext(DataContext);
  const [dbData, setDbData] = useState(null)
  const valid = metaDataToDisplay

  useEffect(() => {
    let rowData = []
    if(tableDataToDisplay){
      Object.keys(tableDataToDisplay).map((item) =>{
      rowData.push({id:item, min:tableDataToDisplay[item].min, max:tableDataToDisplay[item].max, mean:tableDataToDisplay[item].mean})
    })
  }
    
    // Reformat for DataBrowser use for resize help.
    let data = []
    let lookup = {}
    
    rowData.forEach((datum) =>{
      let addRow = []
      let index = 0;
      Object.keys(datum).map((datumAtt) =>{
        lookup[`${datumAtt}`] = index
        addRow.push(datum[datumAtt])
      })
      data.push(addRow)
    })
    setDbData({'formattedUimc': {data, lookup}})
  }, [])
  
  
  

  return (
    <>
      {valid && (
        <div style={styles.container}>
        <Grid container rowSpacing={0} columnSpacing={1} columns={12}>
              {Object.keys(metaDataToDisplay).map((item) => (
                <Grid item key={metaDataToDisplay[item]} xs={12} sm={6}>
                 
                  
                    <b>{item}</b>: {metaDataToDisplay[item]}
                    {/* Display empty string if invalid mapping */}
                    {(!(metaDataToDisplay[item])) && ""}
                </Grid>
              ))}
            </Grid>
            
            


<TableContainer sx={{overflowX:'visible'}}>
<Table key={key} size="small" aria-label="static metadata table">
  <TableBody>
  <StyledTableRow key={key+'headerRow'}>
  <StyledTableCell key={key+'header1'} align="right">
    
  </StyledTableCell>
  
  <StyledTableCell key={key+'header2'} align="center">
    <b>Min</b>
  </StyledTableCell>
  <StyledTableCell key={key+'header3'} align="center">
    <b>Max</b>
  </StyledTableCell>
  <StyledTableCell key={key+'header4'} align="center">
    <b>Mean</b>
  </StyledTableCell>
  
  
    </StyledTableRow>
  {Object.keys(tableDataToDisplay).map((item) => (
                <StyledTableRow key={key + item + 'row'}>
             <StyledTableCell key={key + item + 'data'} align="center">
                    <b>{item}</b>
                  </StyledTableCell>
                  <StyledTableCell key={key + item + 'min'} align="center">
                    {tableDataToDisplay[item].min}
                  </StyledTableCell>
                  <StyledTableCell key={key + item + 'max'} align="center">
                    {tableDataToDisplay[item].max}
                  </StyledTableCell>
                  <StyledTableCell key={key + item + 'mean'} align="center">
                    {tableDataToDisplay[item].mean}
                  </StyledTableCell>
                </StyledTableRow>
              ))}

      </TableBody>
          </Table>
        </TableContainer>
        </div>
      )}
      {!valid && (
        <div>
          <b>No Data</b>
        </div>
      )}
      
    {/* </Paper> */}
    {/* </ThemeProvider> */}
    </>
  );
}

StaticFieldsViewer.propTypes = propTypes;
StaticFieldsViewer.defaultProps = defaultProps;

export default StaticFieldsViewer;
