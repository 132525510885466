import React, { useContext, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { DataContext } from "../DataContext";

import { Box } from "@mui/material";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import StaticFieldsViewer from "./StaticFieldsViewer";

import Fade from 'react-bootstrap/Fade';
// import { ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import {ToggleButton, ToggleButtonGroup}  from "@mui/material";
// import ToggleButtonGroup from "@mui/material";

const propTypes = {
  source: PropTypes.string,
  darkerFont: PropTypes.bool,
  smallerFont: PropTypes.bool,
  setSelectedUimcId:PropTypes.func,
  setUimcIdTranslation:PropTypes.func,
};

const defaultProps = {
  source: "flight_uimc",
  metric: "count",
  decimalPlaces: 2,
  simplifyNumber: true,
  darkerFont: false,
  smallerFont: false,
};

const styles = {
  container: {
    width: "100%",
    height: "100%",
    textAlign: "center",
  },
  content: {
    fontWeight: "bold",
    height: "50%",
    lineHeight: 1,
    verticalAlign: "bottom",
  },
  subcontent: {
    textAlign: "center",
    height: "50%",
    lineHeight: 1,
    verticalAlign: "top",
  },
};

const SHOW_DEBUG_LINES = false;

const UimcDataTable = (props) => {
  const { source, darkerFont, smallerFont, setSelectedUimcId, setUimcIdTranslation } = props;
  const dataContext = useContext(DataContext);
  const targetData = dataContext.filteredData ? dataContext.filteredData : dataContext.baseData;
  const valid = targetData && targetData[source] && Object.keys(targetData[source]).length > 0;
  const listOfItemsSource = targetData ? targetData[source].data : {}
  const lookup = targetData[source].lookup
  const [uimcEventList, setUimcEventList] = useState({metaData:{}, tableData:{}})

  
  
  useEffect(() => {
   // Build data structure for display
  let tempEventList = {}
  let tempEventIdList = {}
  let eventCounter = 1;
  listOfItemsSource.forEach((item) => {
    if(lookup) {
      tempEventList[item[lookup['start_timestamp']]] = {metaData:{}, tableData:{}}
    Object.keys(lookup).map((iRef) => {
      // console.log(item, `${iRef}`)
      if(`${iRef}`.startsWith('min_') || `${iRef}`.startsWith('max_') || `${iRef}`.startsWith('mean_')) {
        let cat = `${iRef}`.substring(`${iRef}`.indexOf('_')+1)
        let stat = `${iRef}`.substring(0,`${iRef}`.indexOf('_'))
        if(!tempEventList[item[lookup['start_timestamp']]].tableData[cat]){
          tempEventList[item[lookup['start_timestamp']]].tableData[cat] = {}
        }
        tempEventList[item[lookup['start_timestamp']]].tableData[cat][stat] = item[lookup[iRef]]
      } else {
        tempEventList[item[lookup['start_timestamp']]].metaData[iRef] = item[lookup[iRef]]
      }
    })
    tempEventIdList[item[lookup['event_id2']]] = "Event "+ eventCounter++
    tempEventList[item[lookup['start_timestamp']]].eventName = tempEventIdList[item[lookup['event_id2']]]
  } 
  
  })
  setUimcEventList(tempEventList)
  setUimcIdTranslation(tempEventIdList)
  }, [])

  
  
  

  return (
    <Box key='uimcDataTableKey'  sx={{}}>
      <Tabs id="uimc_events_tab"
            ley='uimcDataTableTabsKey'
            unmountOnExit={true} // Unmount tabs (remove it from the DOM) when it is no longer visible (if true)
            transition={Fade}
            mountOnEnter={true}
            variant="tabs"
            tabindicatorprops={{style:{backgroundColor:'#ff0000'}}}  
            onSelect={(event) => {setSelectedUimcId(event)}}>
      {uimcEventList && Object.keys(uimcEventList).map((item) => (
        <Tab key={'uimcDataTab'+item}
        eventKey={uimcEventList && uimcEventList[item] && uimcEventList[item].metaData ? uimcEventList[item].metaData.event_id2 : ''}
        title={uimcEventList[item].eventName}
        
        
      >
        <Box  sx={{}}>
        <StaticFieldsViewer key={item} metaDataToDisplay={uimcEventList[item].metaData} tableDataToDisplay={uimcEventList[item].tableData} />
        </Box>
      </Tab>
      ))}
      </Tabs>
    </Box>
  );
};

UimcDataTable.propTypes = propTypes;
UimcDataTable.defaultProps = defaultProps;

export default UimcDataTable;
