import {API} from "aws-amplify";

async function getSystemAggs(user, operator) {

const token = user ? user.signInUserSession.idToken.jwtToken : null;

      const requestInfo = {
        headers: {
          Authorization: token
        },
        body: {
          operator : user.attributes['custom:operator']
        }
      }
      // console.log({token})
      const data = await API.post('raise-gui-api-dev', "/get-system-aggregates", requestInfo)
      // console.log({data})
      // Convert long numbers to , delimited.
      
      Object.keys(data).forEach(value => {
        // console.log("Hello")
        data[value]['total_flights'] = Number(data[value]['total_flights']).toLocaleString(("en-US"));
        data[value]['total_flight_hours'] = Number(data[value]['total_flight_hours']).toLocaleString(("en-US"));
          // console.log(data[value])
      })

    // console.log({data})
      return data;
}

export default getSystemAggs;