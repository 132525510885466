import {API} from "aws-amplify";

export function sendEmail(authUser, email) {
    const token = authUser.signInUserSession.idToken.jwtToken;
    const requestInfo = {
      headers: {
        Authorization: token
      },
      body: {
        emailSubject: email.emailSubject,
        emailBodyText: email.emailBodyText,
		    emailBodyHTML: email.emailBodyHTML,
		    emailRecipients: email.emailRecipients
      }
    }
  // console.log({requestInfo})
    
    const data = API.post('raise-gui-api-dev', "/send-email", requestInfo)
  // console.log({data})
      return data;

   
}

export default sendEmail;