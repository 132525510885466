import React from 'react'
import UnauthNav from '../components/UnauthNav';
import AboutContent from '../components/AboutContent'

const About = () => {
  return (
    <>
    <UnauthNav />
    <AboutContent />
        </>
  )
}

export default About
