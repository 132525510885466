
const hasRole = (contextUser, roles) => {
    // console.log(contextUser)
    var group_list = []
    if(contextUser.groupsLoaded && contextUser.userGroups != null) {
        var groups = contextUser.userGroups
    // console.log("checking groups")
        // Add each group to a new list of group names
        groups.forEach((group) => {
            group_list.push(group.GroupName)
        });
      
        // User object that maintains a list of groups that the user
        // belongs to
        const user = {
            roles: group_list
        }

        return roles.some(role => user.roles.includes(role));
    } else {
        return false
    }
    
}

export default hasRole
