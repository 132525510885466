import React, { useEffect, useContext, useRef } from 'react';
import { DataContext } from '../components/DataContext';
import PropTypes from 'prop-types';
import DashboardNav from '../components/DashboardNav';
import Footer from '../components/PageFooter';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { makeStyles } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Fade from 'react-bootstrap/Fade';
import Toolbar from '../components/utils/Toolbar';
import UasRiskAnalysisContent from '../components/content/UasRiskAnalysisContent';
import SUasSightingsContent from '../components/content/SUasSightingsContent';
import getUasAggregateMetrics from '../services/getUasAggregateMetrics';

import './AggregateUserMetrics.css';

const drawerWidth = 450; // 240

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  filterToolbar: {
    display: "flex",
  },
  filterButton: {
    margin: 10,
  },
  rightIcons: {
    marginLeft: theme.spacing(0.5)
  },
  spacer: {
    flexGrow: 1
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  formControl: {
    margin: theme.spacing(1),
    width: 150
  },
  selectBodyDarkTheme: {
    borderBottom: '1px solid #fff',
    '& .MuiSvgIcon-root': {
      color: '#fff',
    },
  },
  selectBodyLightTheme: {
    borderBottom: '1px solid #424242',
    '& .MuiSvgIcon-root': {
      color: '#424242',
    },
  },
  parentFlexSplit: {
    display: "flex",
    justifyContent: "space-between"
  },
  leftAlignItem: {
    marginRight: "auto"
  },
  rightAlignItem: {
    marginLeft: "auto"
  },
  centerAlignItem: {
    marginTop: "auto",
    marginBottom: "auto"
  },
  button: {
    margin: "10px"
  },
  indeterminateColor: {
    color: "#f50057"
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  },
  bottomControls: {
    margin: 10,
    bottom: 0,
    left: 0,
    right: 0,
    position: "absolute",
  },
  controlButton: {
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    // background: "#000000",
    border: 0,
    borderRadius: 3,
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    height: 32,
    padding: '0 30px',
  },
  lightTabs: {
    color: '#000 !important',
    background: '#fff !important',
    borderBottom: '1px solid #dee2e6',
  },
  darkTabs: {
    color: '#fff !important',
    background: '#121212 !important',
    borderBottom: '1px solid #dee2e6',
  },
  // summary: {
  //   // pointerEvents: 'none',
  //   pointerEvents: 'auto',
  // },
  // icon: {
  //   pointerEvents: 'auto',
  // },
}));

const propTypes = {
  user: PropTypes.object.isRequired,
  operator: PropTypes.string,
};

const defaultProps = {
  operator: "FAA",
};

const AggregateUserMetrics = (props) => {
  const { user, operator } = props;
  const dataContext = useContext(DataContext);
  const classes = useStyles();
  dataContext.classes.current = classes;

  const styles = {
    customScale: {
      display: "flex",
      transform: `scale(${dataContext.appScale})`,
      transformOrigin: "0 0",
      width: `${100 * (1 / dataContext.appScale)}%`,
      height: `${dataContext.appScale * 100}vh`,
    },
    antiScale: {
      transform: `scale(${1 + (1 - dataContext.appScale)})`,
      transformOrigin: "0 0",
      width: `${100 * (1 / (1 + (1 - dataContext.appScale)))}%`,
    },
  };

  const toolbarEl = useRef(null);
  const toolbarOffsetTop = useRef(0);

  let runtime = 0;
  let startTime = 0;

  const handleSnackClose = () => {
    dataContext.setOpenSnack(false);
  }

  const getRuntime = (msg = "Performance") => {
    let endTime = performance.now();
    // runtime = Math.round((endTime - startTime) * 1000) / 1000;
    runtime = endTime - startTime;
    if (dataContext.DEBUG_MODE) {
      console.log(`${msg}: ${runtime} ms`);
    }
  }

  const onLoadData = (user, operator) => {
    if (!dataContext.baseData) {
      // Notify processing start to render loading modal overlay on map
      dataContext.setLoadingData(true);
      dataContext.setProcessing(true);

      // setRunning(true);
      runtime = 0;
      startTime = performance.now();

      getUasAggregateMetrics(user, operator).then((result) => {
        if (dataContext.DEBUG_MODE) {
          console.log("UAS Aggregate Metrics Service Results:", result);
        }

        getRuntime("Lambda Performance");

        // Extract pre-signed url from result
        const { preSignedUrl, filesize } = result;

        // Assuming we'll create 4 simul downloads, find the quotient of the filesize.
        let simulDowns = 4
        let fileChunk = Math.floor(filesize / simulDowns)

        // Construct a series of ranges using fileChunk
        let rangeArray = [];
        for (let i = 0; i < simulDowns; i++) {
          rangeArray.push((i === 0 ? 0 : (fileChunk * i) + 1) + "-" + (i === simulDowns - 1 ? '' : fileChunk * (i + 1)))
        }

        if (preSignedUrl) {
          if (dataContext.DEBUG_MODE) {
            console.log(`Pre-signed URL: ${preSignedUrl}`);
          }

          // Get 0 bytes to act as a head request, insect returned header for content-length
          let chunkPromises = rangeArray.map(range => {
            return fetch(preSignedUrl, {
              headers: {
                Range: "bytes=" + range
              }
            }).then(result => {
              return result.arrayBuffer();
            }).catch(error => {
              console.error(error);
            });
          });

          Promise.all(chunkPromises).then(chunks => {
            if (dataContext.DEBUG_MODE) {
              console.log({ chunks })
            }

            let blob = new Blob(chunks, {
              type: "application/json"
            })
            let jsonValue = blob.text().then(x => { return JSON.parse(x) });
            return jsonValue;
          }).then((data) => {
            if (dataContext.DEBUG_MODE) {
              console.log("Base Data", data);
            }

            // Modify original dataset before registering it in data context
            dataContext.addFormattedUasSightingsFields(data);

            // Set main data as selected/active flight (default to first flight in data if active flight doesn't exist)
            dataContext.setBaseData(data);
            // dataContext.buildReducedDataset(dataTarget);

            // applySavedFilters();
            dataContext.setProcessing(false);
            dataContext.setLoadingData(false);

          }).catch((err) => {
            dataContext.showSnack(
              "top",
              "center",
              `Failed to retrieve ${operator} UAS aggregate metrics data (Network Error)`,
              dataContext.SNACK_SEVERITY.ERROR
            );
            console.error(err);
            dataContext.setProcessing(false);
            dataContext.setLoadingData(false);
          }).finally(() => {
            getRuntime("Overall Performance");
            // setRunning(false);
            dataContext.setProcessing(false);
            dataContext.setLoadingData(false);
          });
        } else {
          // setRunning(false);
          dataContext.setProcessing(false);
          dataContext.setLoadingData(false);

          dataContext.showSnack(
            "top",
            "center",
            `${operator} UAS aggregate metrics data not available`,
            dataContext.SNACK_SEVERITY.ERROR
          );
        }
      }).catch((error) => {
        // setRunning(false);
        dataContext.setProcessing(false);
        dataContext.setLoadingData(false);

        dataContext.showSnack(
          "top",
          "center",
          `Failed to retrieve ${operator} UAS aggregate metrics data (Network Error)`,
          dataContext.SNACK_SEVERITY.ERROR
        );
        console.error(error);
      });

      // console.log(`Simulating aggregated user metrics load for ${user.attributes.name} (${operator})...`);
      // setTimeout(() => {
      //   dataContext.showSnack(
      //     "top",
      //     "center",
      //     `Simulating aggregated user metrics load for ${user.attributes.name} (${operator})...`,
      //     dataContext.SNACK_SEVERITY.INFO
      //   );
      // });

      // setTimeout(() => {
      //   console.log(`Processing Complete!`);
      //   dataContext.showSnack(
      //     "top",
      //     "center",
      //     `Processing Complete!`,
      //     dataContext.SNACK_SEVERITY.SUCCESS
      //   );

      //   getRuntime("Overall Performance");

      //   dataContext.setLoadingData(false);
      //   dataContext.setProcessing(false);
      // }, 2000);
    }
  }

  useEffect(() => {
    if (dataContext.processing) {
      dataContext.setOpenSnack(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.processing]);

  useEffect(() => {
    if (toolbarEl.current) {
      const onScroll = () => {
        // Additional ref check in case component is unmounted
        if (toolbarEl.current) {
          // console.log(`Scroll ( window-scroll-y = ${window.scrollY}; toolbar-top-offset = ${toolbarOffsetTop.current})`);
          if (window.scrollY > toolbarOffsetTop.current) {
            toolbarEl.current.classList.add("sticky");
          } else {
            toolbarEl.current.classList.remove("sticky");
          }
        }
      }

      const onResize = () => {
        // Additional ref check in case component is unmounted
        if (toolbarEl.current) {
          // Track updated toolbar offset
          toolbarOffsetTop.current = toolbarEl.current.offsetTop;
        }
      }

      // Custom window scroll and resize events for smooth/dynamic position of the toolbar component
      toolbarOffsetTop.current = toolbarEl.current.offsetTop;
      window.onscroll = onScroll;
      window.onresize = onResize;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolbarEl.current]);

  useEffect(() => {
    // EXPERIMENTAL (Produces odd behavior with map tooltips - usage is not recommended)
    // document.body.style.zoom = 0.80;
    onLoadData(user, operator);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div style={{ height: '100vh' }}>
        <DashboardNav />
        <div className="stretch_grid-container">
          <h1>UAS Sightings</h1>
          Note: 
          <br />
          The <a href="https://www.faa.gov/uas/resources/public_records/uas_sightings_report#:~:text=Reports%20of%20unmanned%20aircraft%20%28UAS%29%20sightings%20from%20pilots%2C,airplanes%2C%20helicopters%20and%20airports%20is%20dangerous%20and%20illegal." target="_blank">UAS Sightings data</a> was parsed by Machine Learning techniques to extract key info such as ICAO Designator and Location of reported sighting from the FAA’s website.
          <br />
          The team is continually looking to improve the accuracy of results, please contact us if you have any questions at <a href="mailto:RotorcraftHelp@asias.info">RotorcraftHelp@asias.info</a>
          {/* DASHBOARD CONTENT HERE */}
          {/* NOTE: Snackbar must be outside of root div due to scaling transform breaking fixed position of snackbar */}
          <Snackbar
            anchorOrigin={{ vertical: dataContext.snackVertical, horizontal: dataContext.snackHorizontal }}
            open={dataContext.openSnack}
            onClose={handleSnackClose}
            key={dataContext.snackVertical + dataContext.snackHorizontal}
            autoHideDuration={6000}
          >
            <Alert
              onClose={handleSnackClose}
              severity={dataContext.snackSeverity}
              style={dataContext.snackColor}
              sx={{ width: '100%' }}
            >
              {dataContext.snackMessage}
            </Alert>
          </Snackbar>

          <Toolbar
            onRef={ref => toolbarEl.current = ref}
            excludeBackToFlightsLink // Don't want back to flights link in aggregate user metrics dashboard
            style={{
              transform: styles.customScale.transform,
              transformOrigin: styles.customScale.transformOrigin,
              width: styles.customScale.width,
              background: dataContext.darkMode ? '#121212' : '#fff',
              zIndex: 1000, // Float over all widgets in app
            }}
          />

          <div
            className='content'
            style={styles.customScale}
          >
            <CssBaseline />
            <main
              style={{ width: "100%", marginLeft: 10, marginRight: 10 }}
            >
              <Tabs
                defaultActiveKey= {process.env.REACT_APP_USER_BRANCH === 'dev' ? "uasRiskAnalysis" : "sUasSightings"}
                id="aggregate-user-metrics-dashboard-tabs"
                unmountOnExit={true} // Unmount tabs (remove it from the DOM) when it is no longer visible (if true)
                transition={Fade}
                variant="tabs"
                // onChange={e => console.log(`Changing tab to ${e.target.value}`)}
                onSelect={dataContext.preventUIBlocking}
              >
              {process.env.REACT_APP_USER_BRANCH == 'dev' && <Tab
                  eventKey="uasRiskAnalysis"
                  title="UAS Risk Analysis"
                  tabClassName={dataContext.darkMode ? classes.darkTabs : classes.lightTabs}
                >
                  <UasRiskAnalysisContent />
                </Tab>
              }
                <Tab
                  eventKey="sUasSightings"
                  title="sUAS Sightings"
                  tabClassName={dataContext.darkMode ? classes.darkTabs : classes.lightTabs}
                >
                  <SUasSightingsContent />
                </Tab>
              </Tabs>

              {/* TODO Determine how to keep footer at bottom when tabs are changed */}
              {/* TODO Change color of footer based on current theme */}
              <Footer
                style={styles.antiScale} 
              />
            </main>
          </div>
        </div>
      </div>
    </>
  );
}

AggregateUserMetrics.propTypes = propTypes;
AggregateUserMetrics.defaultProps = defaultProps;

export default AggregateUserMetrics;
