import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

const propTypes = {
  color: PropTypes.string,
};

const defaultProps = {};

const styles = {
  container: {
    display: "inline-block",
    alignItems: 'center',
    width: 0,
    border: (theme) => `1px solid ${theme.palette.divider}`,
    borderRadius: 1,
    bgcolor: 'background.paper',
    color: 'text.secondary',
    '& svg': {
      m: 1.5,
    },
  },
  divider: {
    display: "inline-block",
    background: "transparent",
    color: "transparent",
    borderColor: "transparent",
  }
};

const VerticalDivider = (props) => {
  const { color } = props;

  return (
    <Box
      sx={color ? { ...styles.container, border: `1px solid ${color}` } : styles.container }
    >
      <Divider
        sx={styles.divider}
        orientation="vertical"
        flexItem
      />
    </Box>
  );
}

VerticalDivider.propTypes = propTypes;
VerticalDivider.defaultProps = defaultProps;

export default VerticalDivider;
