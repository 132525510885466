import React, { useState, useEffect, useContext, useRef } from 'react';
import { DataContext } from '../components/DataContext';
import PropTypes from 'prop-types';
import DashboardNav from '../components/DashboardNav';
import Footer from '../components/PageFooter';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CssBaseline from '@mui/material/CssBaseline';
import { makeStyles } from '@mui/styles';
import FlightMetrics from './FlightMetrics';
import { DataProvider } from '../components/DataContext';
import CustomThemeProvider from '../components/styles/CustomThemeProvider';
import FlightMetricsReact from './FlightMetricsReact'
import DQMetrics from './DQMetrics'

import GeoSearchByHelipadsContent from '../components/content/GeoSearchByHelipadsContent';

import HelipadsRadiusBar from '../components/utils/GeoSearchHelipadsRadiusBar';

import './GeoSearchByHelipads.css';


const drawerWidth = 450; // 240

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  filterToolbar: {
    display: "flex",
  },
  filterButton: {
    margin: 10,
  },
  rightIcons: {
    marginLeft: theme.spacing(0.5)
  },
  spacer: {
    flexGrow: 1
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  formControl: {
    margin: theme.spacing(1),
    width: 150
  },
  selectBodyDarkTheme: {
    borderBottom: '1px solid #fff',
    '& .MuiSvgIcon-root': {
      color: '#fff',
    },
  },
  selectBodyLightTheme: {
    borderBottom: '1px solid #424242',
    '& .MuiSvgIcon-root': {
      color: '#424242',
    },
  },
  parentFlexSplit: {
    display: "flex",
    justifyContent: "space-between"
  },
  leftAlignItem: {
    marginRight: "auto"
  },
  rightAlignItem: {
    marginLeft: "auto"
  },
  centerAlignItem: {
    marginTop: "auto",
    marginBottom: "auto"
  },
  button: {
    margin: "10px"
  },
  indeterminateColor: {
    color: "#f50057"
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  },
  bottomControls: {
    margin: 10,
    bottom: 0,
    left: 0,
    right: 0,
    position: "absolute",
  },
  controlButton: {
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    // background: "#000000",
    border: 0,
    borderRadius: 3,
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    height: 32,
    padding: '0 30px',
  },
  lightTabs: {
    color: '#000 !important',
    background: '#fff !important',
    borderBottom: '1px solid #dee2e6',
  },
  darkTabs: {
    color: '#fff !important',
    background: '#121212 !important',
    borderBottom: '1px solid #dee2e6',
  },
  // summary: {
  //   // pointerEvents: 'none',
  //   pointerEvents: 'auto',
  // },
  // icon: {
  //   pointerEvents: 'auto',
  // },
}));

const propTypes = {
  user: PropTypes.object.isRequired,
  operator: PropTypes.string,
};

const defaultProps = {
  operator: "FAA",
};

const GeoSearchByHelipads = (props) => {
  const { user, operator } = props;
  
  const dataContext = useContext(DataContext);
  
  let selectedFlight0 = {};
  selectedFlight0["flightmetadata__id"] = 'N38_20181005T133122_20181005T150138';
  
  const [selectedFlight, setSelectedFlight] = useState(selectedFlight0);
  const [geoSearchPageFlag, setGeoSearchPageFlag] = useState(0);
  
  // console.log(user);
  // console.log(operator);
  // console.log(selectedFlight);
  
  const classes = useStyles();
  dataContext.classes.current = classes;

  const styles = {
    customScale: {
      display: "flex",
      transform: `scale(${dataContext.appScale})`,
      transformOrigin: "0 0",
      width: `${100 * (1 / dataContext.appScale)}%`,
      height: `${dataContext.appScale * 80}vh`,
    },
    antiScale: {
      transform: `scale(${1 + (1 - dataContext.appScale)})`,
      transformOrigin: "0 0",
      width: `${100 * (1 / (1 + (1 - dataContext.appScale)))}%`,
    },
  };

  const toolbarEl = useRef(null);
  const toolbarOffsetTop = useRef(0);

  let runtime = 0;
  let startTime = 0;

  const handleSnackClose = () => {
    dataContext.setOpenSnack(false);
  }

 
  useEffect(() => {
    if (dataContext.processing) {
      dataContext.setOpenSnack(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.processing]);

  useEffect(() => {
    if (toolbarEl.current) {
      const onScroll = () => {
        // Additional ref check in case component is unmounted
        if (toolbarEl.current) {
          // console.log(`Scroll ( window-scroll-y = ${window.scrollY}; toolbar-top-offset = ${toolbarOffsetTop.current})`);
          if (window.scrollY > toolbarOffsetTop.current) {
            toolbarEl.current.classList.add("sticky");
          } else {
            toolbarEl.current.classList.remove("sticky");
          }
        }
      }

      const onResize = () => {
        // Additional ref check in case component is unmounted
        if (toolbarEl.current) {
          // Track updated toolbar offset
          toolbarOffsetTop.current = toolbarEl.current.offsetTop;
        }
      }

      // Custom window scroll and resize events for smooth/dynamic position of the toolbar component
      toolbarOffsetTop.current = toolbarEl.current.offsetTop;
      window.onscroll = onScroll;
      window.onresize = onResize;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolbarEl.current]);


  //Action for clicking the flight item in DataBrowser (GeoSearchByHelipadContent.js)
  useEffect(() => {
    if(dataContext.connectFlightId){
      let selectedFlight0 = {};
      selectedFlight0["flightmetadata__id"] = dataContext.connectFlightId;
      
      setSelectedFlight(selectedFlight0);
      setGeoSearchPageFlag(1);
    }

  }, [dataContext.connectFlightId]);

  //Action for clicking the flight item in DataBrowser (GeoSearchByHelipadContent.js)
  useEffect(() => {
    if(dataContext.dqFlightId){
      let selectedFlight0 = {};
      selectedFlight0["flightmetadata__id"] = dataContext.dqFlightId;
      
      setSelectedFlight(selectedFlight0);
      setGeoSearchPageFlag(2);
    }

  }, [dataContext.dqFlightId]);

  //Action in "Back to search" button in Flight matrix component below (FlightMetricsReact)
  const handleBackToGeoSearch = () => {
    dataContext.setDqFlightId('')
    dataContext.setConnectFlightId('')
    setGeoSearchPageFlag(0);
    dataContext.setBackToGeoSearchMode(true);
  }
  
  return (
    <>
      {/*<div style={{ height: '100vh' }}>*/}
        {/*<DashboardNav />*/}
        <div className="stretch_grid-container" style={{marginLeft: '0.5em', marginRight: '0.5em'}} >
          
          
          <div style={geoSearchPageFlag === 0 ? {display:"unset"} : {display:"none"}}>
            <div>
             {/* <h1>Search Flights by Geospatial</h1>*/}
              {/* <HelipadsRadiusBar /> */}
            </div>
           
          <div
            className='content'
            style={styles.customScale}
          >
            <CssBaseline />
            <main
              style={{ width: "100%"}}
            >
              <GeoSearchByHelipadsContent />
            </main>
          </div>
          </div>
          
          { geoSearchPageFlag === 1 && (
             <div>
              <DataProvider>
                <CustomThemeProvider>
                  <FlightMetricsReact
                    user={user}
                    operator={operator}
                    flight={selectedFlight}
                    onBackToFlights={handleBackToGeoSearch}
                  />
                </CustomThemeProvider>
              </DataProvider>
            </div>)} 

            { geoSearchPageFlag === 2 && (
             <div>
             <DQMetrics flight={selectedFlight} handleBackToGeoSearch={handleBackToGeoSearch}/>
            </div>)}        
            
        </div>
      {/*</div>*/}
    </>
  );
}

GeoSearchByHelipads.propTypes = propTypes;
GeoSearchByHelipads.defaultProps = defaultProps;

export default GeoSearchByHelipads;
