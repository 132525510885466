import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { DataContext } from '../../DataContext';
import {
  Stage,
  Layer,
  Shape,
  Rect,
  Text,
  Path
} from 'react-konva';
import { Html } from 'react-konva-utils';
import tinycolor from 'tinycolor2';
import helicopterSvgData from '../../../icons/svg/HelicopterSvgData';

// Import styles
import './LteCompassRoseChart.css';
import 'font-awesome/css/font-awesome.css';

const propTypes = {
  /**
   * Total number of sections to subdivide compass rose chart by.
   * Represented by dashed lines.
   * 
   * 36 sections = 10 degrees , 18 sections = 20 degrees, 8 sections = 45 degrees, 4 sections = 90 degrees
   */
  sections: PropTypes.oneOf([36, 18, 8, 4]),
};

const defaultProps = {
  sections: 18,
};

const styles = {
  container: {
    margin: 10,
  },
  theme: {
    light: {
      margin: 10,
      background: "#FFFFFF",
    },
    dark: {
      margin: 10,
      background: "#2A2A2A",
    },
  }
};

const RISK_LABEL_LOOKUP = {
  // "LOW": "Low Severity",
  "MEDIUM": "Risk of Event",
  "HIGH": "Event",
};

const EVENT_LABEL_CONFIG = {
  width: 30,
  height: 20,
  fontSize: 16,
};

const CENTER_IMAGE_CONFIG = {
  width: 150,
  height: 150,
};

const BAND_SPACING_RATIO = 0.2; // Control the spacing between bands in chart

const COLORS = {
  BANDS: {
    // "LOW": "#808080", // #FFD69D, #FDF8CE, #E6E6E6
    "MEDIUM": "#808080", // #FFD69D, #FFC380, #E6E6E6
    "HIGH": "#808080", // #FFD69D, #FF4D5B, #E6E6E6
  },
  BANDS_LIGHT: {
    // "LOW": "#E6E6E6",
    "MEDIUM": "#E6E6E6",
    "HIGH": "#E6E6E6",
  },
  BANDS_DARK: {
    // "LOW": "#808080",
    "MEDIUM": "#808080",
    "HIGH": "#808080",
  },
  EVENTS: {
    // "LOW": "#F7E65A",
    "MEDIUM": "#FF8700",
    "HIGH": "#CF0011",
  },
  DEFAULT_LIGHT: "#FFFFFF",
  DEFAULT_DARK: "#2A2A2A",
};

const DARKEN_FACTOR = 10;
const BACKGROUND_LAYER_ID = "tailwind-compass-rose-background-layer";

const baseShapeConfigs = [
  // {
  //   color: COLORS.BANDS.LOW,
  //   colorLight: COLORS.BANDS_LIGHT.LOW,
  //   colorDark: COLORS.BANDS_DARK.LOW,
  //   hoverColor: tinycolor(COLORS.BANDS.LOW).darken(DARKEN_FACTOR).toString(),
  //   eventColor: COLORS.EVENTS.LOW,
  //   hoverEventColor: tinycolor(COLORS.EVENTS.LOW).darken(DARKEN_FACTOR).toString(),
  //   priority: 0,
  //   eventSeverity: 1,
  //   risk: RISK_LABEL_LOOKUP.LOW,
  //   suppressEvents: true,
  //   renderLabels: true,
  // },
  {
    color: COLORS.BANDS.MEDIUM,
    colorLight: COLORS.BANDS_LIGHT.MEDIUM,
    colorDark: COLORS.BANDS_DARK.MEDIUM,
    hoverColor: tinycolor(COLORS.BANDS.MEDIUM).darken(DARKEN_FACTOR).toString(),
    eventColor: COLORS.EVENTS.MEDIUM,
    hoverEventColor: tinycolor(COLORS.EVENTS.MEDIUM).darken(DARKEN_FACTOR).toString(),
    priority: 1,
    eventSeverity: 2,
    risk: RISK_LABEL_LOOKUP.MEDIUM,
    suppressEvents: true,
    renderLabels: true,
  },
  {
    color: COLORS.BANDS.HIGH,
    colorLight: COLORS.BANDS_LIGHT.HIGH,
    colorDark: COLORS.BANDS_DARK.HIGH,
    hoverColor: tinycolor(COLORS.BANDS.HIGH).darken(DARKEN_FACTOR).toString(),
    eventColor: COLORS.EVENTS.HIGH,
    hoverEventColor: tinycolor(COLORS.EVENTS.HIGH).darken(DARKEN_FACTOR).toString(),
    priority: 2,
    eventSeverity: 3,
    risk: RISK_LABEL_LOOKUP.HIGH,
    suppressEvents: true,
    renderLabels: true,
  },
  {
    color: COLORS.DEFAULT_LIGHT,
    colorLight: COLORS.DEFAULT_LIGHT,
    colorDark: COLORS.DEFAULT_DARK,
    hoverColor: null,
    eventColor: null,
    hoverEventColor: null,
    priority: null,
    eventSeverity: 0,
    risk: null,
    suppressEvents: true,
    renderLabels: false,
  },
];

const TailwindCompassRoseChart = (props) => {
  const dataContext = useContext(DataContext);
  const { parentWidth, parentHeight, sections } = props;

  const stepAngleDegrees = 360 / sections;
  const stepAngleRadians = dataContext.toRadians(stepAngleDegrees);

  const [canvasWidth, setCanvasWidth] = useState(0);
  const [canvasHeight, setCanvasHeight] = useState(0);
  const [shapeSchematics, setShapeSchematics] = useState(null); // Will be list of objects [{...}, {...}, ...]
  const [eventCountLabels, setEventCountLabels] = useState(null);
  const [shapeConfigs, setShapeConfigs] = useState([]);
  const [tickMarks, setTickMarks] = useState(null);
  const [tickMarkLabels, setTickMarkLabels] = useState(null);
  const [defferedDataLoad, setDefferedDataLoad] = useState(false);

  const stageRef = useRef(null);
  const maxRadiusRef = useRef(null);
  const bandSchematicIdSet = useRef(new Set());
  const bandSchematicLookup = useRef({});
  const shapeSchematicsRef = useRef(null);
  const eventCountLabelsRef = useRef(null);
  const isMounted = useRef(false);

  /**
   * Handle when the mouse pointer enters a Konva object. Rebuilds the shape schematics list
   * with updated configurations as needed.
   * 
   * @param {string | object} e Event data representing either the id string of a schematic or an object containing the id.
   */
  const handleMouseMove = (e) => {
    let id = typeof (e) === "string" ? e : e.target.id();
    if (shapeSchematics && shapeSchematics.length > 0) {
      setShapeSchematics(shapeSchematics.map(schematic => {
        let hoverColor = schematic.eventCount > 0 ? schematic.hoverEventColor : schematic.hoverColor;
        let newSchematic = {
          ...schematic,
          fill: !schematic.suppressEvents && schematic.id === id ? hoverColor : schematic.fill,
          hovered: schematic.id === id,
        };

        // Only turn off hover if hovered out of the compass rose and on to the background layer
        if (id === BACKGROUND_LAYER_ID) {
          newSchematic.hovered = false;
        }

        return newSchematic;
      }));
    }
  }

  /**
   * Handle when the mouse pointer exists an associated Konva object. Rebuilds the shape schematics list
   * with updated configurations as needed.
   * 
   * @param {string | object} e Event data representing either the id string of a schematic or an object containing the id.
   */
  const handleMouseOut = (e) => {
    let id = typeof (e) === "string" ? e : e.target.id();
    if (shapeSchematics && shapeSchematics.length > 0) {
      setShapeSchematics(shapeSchematics.map(schematic => {
        let baseColor = !schematic.suppressEvents && schematic.eventCount > 0 ? schematic.eventColor : schematic.baseColor;

        // NOTE: No need to set hovered to false here as it's being set to false for non-hovered cells in the mousemove event handler
        let newSchematic = {
          ...schematic,
          fill: schematic.id === id ? baseColor : schematic.fill,
        }

        return newSchematic;
      }));
    }
  }

  /**
   * Drilldown on data on click event. Parse selection and pass forward to
   * data context to handle drilldown filter.
   * 
   * @param {object} e Event object passed from click listener on chart.
   */
  const handleDrilldown = (e) => {
    // Build data structure that imitates control filter
    // EXAMPLE:
    // {
    //   "phaseOfFlightSelect": {
    //     "id": "phaseOfFlightSelect",
    //     "label": "Standing, Hover Ground Effect",
    //     "column": "phaseofflight_mavg10",
    //     "values": [
    //       "standing",
    //       "hover ground effect"
    //      ]
    //    }
    // }
    const { eventSeverity, beginAngleDeg, endAngleDeg } = e;

    let filter = {
      [dataContext.TAILWIND_VALUE_SELECT_KEY]: {
        id: dataContext.TAILWIND_VALUE_SELECT_KEY,
        label: "eventSeverity-risk",
        column: "exceedance_value",
        values: [eventSeverity],
        track: true,
      },
      [dataContext.TAILWIND_DRIFT_ANGLE_SELECT_KEY]: {
        id: dataContext.TAILWIND_DRIFT_ANGLE_SELECT_KEY,
        label: "drift-angle",
        column: "fms_driftangle_normalized",
        values: [beginAngleDeg, endAngleDeg],
        track: true,
        how: "between",
      }
    };

    // console.log("LTE Compass Rose Filter:", filter);
    dataContext.addControlFilter(filter);
  }

  /**
   * Calculate the position of a tooltip give an original x and y coordinate position of the tooltip. Use
   * the current mouse pointer x and y coordinates if the Konva stage has been initialized and a reference
   * is obtained.
   * 
   * @param {object} tooltip Tooltip configuration object containing the x and y coordinate location of the tooltip.
   * @returns Calculated styling configuration object containing the tooltip width, left, and right data points.
   */
  const calculateTooltipPosition = (tooltip = {}) => {
    const padding = 15;
    const tooltipWidth = 300;

    const { x = 0, y = 0 } = tooltip;
    let pos = {
      width: tooltipWidth,
      left: x + padding,
      right: y,
    };

    if (stageRef.current) {
      let pointerPos = stageRef.current.getPointerPosition();
      // console.log(`w: ${canvasWidth}, h: ${canvasHeight}, x: ${pointerPos.x}, y: ${pointerPos.y}`);

      pos.left = pointerPos.x + padding; // Follow mouse x position
      pos.top = pointerPos.y;            // Follow mouse y position

      // Position tooltip to the left of the mouse pointer if too close to the right edge of the canvas
      // TODO Handle other cases when offsetting left goes outside of canvas bounds too (e.g., render tooltip above mouse pointer)
      if ((pointerPos.x + tooltipWidth) >= canvasWidth) {
        pos.left -= (tooltipWidth + (padding * 2));
      }
    }

    return pos;
  }

  // Component mount/unmount
  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;

      // TODO Cleanup resources to prevent memory leaks as needed

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Dynamically update width and height of chart to fill parent container on dimension change
  useEffect(() => {
    if (isMounted.current) {
      let yOffset = 20;
      let dh = parentHeight - yOffset;
      if (parentWidth > 0 && dh > 0) {
        setCanvasWidth(parentWidth);
        setCanvasHeight(dh);
        // console.log(`Set compass rose canvas dimensions = ${parentWidth}x${dh}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentWidth, parentHeight]);

  // Detect changes in parent width/height and initialize shape schematics for rebuilding
  useEffect(() => {
    // console.log(`Canvas size change: ${canvasWidth}x${canvasHeight}`);
    setShapeSchematics([]);
    setTickMarks([]);
    setTickMarkLabels([]);
    bandSchematicLookup.current = {};
  }, [canvasWidth, canvasHeight]);

  // Build main band schematics
  useEffect(() => {
    // Don't continue if component isn't mounted
    if (!isMounted.current) return;

    // Don't continue if shape schematics have already been initialized
    if (shapeSchematics && shapeSchematics.length > 0) return;

    // Temporary variables, to store each arc angles
    let beginAngleDeg = 90;
    let endAngleDeg = beginAngleDeg;
    let beginAngleRad = dataContext.toRadians(beginAngleDeg); // Offset begin angle to point North
    let endAngleRad = beginAngleRad;

    let schematics = [];
    let eventLabels = [];

    // Prepare max radius calculation to pefectly fit chart within bounding box of parent
    const centerX = canvasWidth / 2;
    const centerY = canvasHeight / 2;

    let centerPoint = { x: centerX, y: centerY };
    let topPoint = { x: centerX, y: 0 };
    let rightPoint = { x: canvasWidth, y: centerY };

    // Determine which center edge point to use based on which is least between width and height
    let targetEdgePoint = Math.min(canvasWidth, canvasHeight) === canvasWidth ? rightPoint : topPoint;

    // Use Pythagorean Theorem to compute distance between center point and target edge point (representing max radius)
    let a = centerPoint.x - targetEdgePoint.x;
    let b = centerPoint.y - targetEdgePoint.y;
    maxRadiusRef.current = Math.sqrt(a * a + b * b);

    // Only continue building shape schematics if the max radius is greater than 0
    if (maxRadiusRef.current > 0) {
      // Add padding relative to band spacing ratio configuration
      const outerPaddingRatio = BAND_SPACING_RATIO + 0.15; // NOTE: Increase/Decrease ratio added to band spacing ratio here as needed
      if ((maxRadiusRef.current - (outerPaddingRatio * 100)) > 0) {
        maxRadiusRef.current = maxRadiusRef.current - (outerPaddingRatio * 100);
      }

      // Build updated shape configurations with computed radius for each entry
      let newShapeConfigs = baseShapeConfigs.map((config, idx) => {
        return {
          ...config,
          color: dataContext.darkMode ? config.colorDark : config.colorLight,
          radius: maxRadiusRef.current - ((maxRadiusRef.current * BAND_SPACING_RATIO) * idx)
        }
      });

      // Add previous and next radius fields
      let i = 0;
      for (let config of newShapeConfigs) {
        config.prevRadius = i === 0 ? config.radius : newShapeConfigs[i - 1].radius;
        config.nextRadius = (i + 1) >= newShapeConfigs.length ? config.radius : newShapeConfigs[i + 1].radius;
        i++;
      }

      // Track new shape configs in state
      setShapeConfigs(newShapeConfigs);

      if (dataContext.DEBUG_MODE) {
        console.log("Dynamic max radius calculation:");
        console.log("|-- Center point:", centerPoint);
        console.log("|-- Target edge point:", targetEdgePoint);
        console.log("|-- Computed max radius (with padding):", maxRadiusRef.current);
        console.log("|-- New shape configurations with computed radius:", newShapeConfigs);
      }

      let shapeIdx = 0;
      let x = 0;
      let y = 0;
      let centerAngleRad = 0;
      let centerRadius = 0;

      for (i = 0; i < sections; i++) {
        beginAngleRad = endAngleRad;    // Begin where we left off
        endAngleRad += stepAngleRadians; // End Angle

        // Calculate begin and end angles in degrees with offset to account for 90 degrees to point North
        beginAngleDeg = Math.round(dataContext.toDegrees(beginAngleRad)) - 270;
        endAngleDeg = Math.round(dataContext.toDegrees(endAngleRad)) - 270;

        // Normalize begin and end angles in degrees to set to coterminal angle if angle in degrees is less than 0
        beginAngleDeg = beginAngleDeg < 0 ? beginAngleDeg + 360 : beginAngleDeg;
        endAngleDeg = endAngleDeg <= 0 ? endAngleDeg + 360 : endAngleDeg;

        for (let config of newShapeConfigs) {
          centerAngleRad = (beginAngleRad + endAngleRad) / 2;

          // Use next radius to calculate the center point between radius to center the label in the section
          centerRadius = (config.nextRadius + config.radius) / 2;
          x = (centerX + (centerRadius * Math.cos(centerAngleRad))) - (EVENT_LABEL_CONFIG.width / 2);
          y = (centerY + (centerRadius * Math.sin(centerAngleRad))) - (EVENT_LABEL_CONFIG.height / 2);

          // Obtain reference to existing schematic and event label if possible
          let existingSchematic = null;

          if (shapeSchematicsRef.current && shapeSchematicsRef.current.length > 0) {
            existingSchematic = shapeSchematicsRef.current[shapeIdx];
          }

          // console.log(beginAngleRad, endAngleRad);
          // console.log("Creting new Konva triangle shape...");

          // Build entry in schematics lookup if it doesn't exist yet
          if (!(config.eventSeverity in bandSchematicLookup.current)) {
            bandSchematicLookup.current[config.eventSeverity] = [];
          }

          let schematicId = `konva-shape-id-${shapeIdx}`;

          // Add the lookup entry if not done so already
          if (!bandSchematicIdSet.current.has(schematicId)) {
            bandSchematicIdSet.current.add(schematicId);
          }

          let schematic = {
            ...config,
            id: schematicId,
            x: centerX,
            y: centerY,
            fill: config.color,
            stroke: dataContext.darkMode ? '#2A2A2A' : '#000',
            strokeWidth: 1,
            beginAngleRad: beginAngleRad,
            endAngleRad: endAngleRad,
            beginAngleDeg: beginAngleDeg,
            endAngleDeg: endAngleDeg,
            baseColor: config.color,
            hovered: false,
            eventCount: 0,
            eventLabelIdx: -1,
            tooltip: {
              text: {},
              x: x,
              y: y,
            }
          };

          if (existingSchematic) {
            schematic = {
              ...schematic,
              fill: existingSchematic.fill,
              eventCount: existingSchematic.eventCount,
              suppressEvents: existingSchematic.suppressEvents,
              tooltip: {
                ...schematic.tooltip,
                text: existingSchematic.tooltip.text,
              }
            };
          }

          schematics.push(schematic);

          // Generate labels as configured
          if (config.renderLabels) {
            // NOTE: Will be within center of rect container
            let eventLabel = {
              id: schematicId, // Share same id as associated schematic for event propagation
              x: x,
              y: y,
              w: EVENT_LABEL_CONFIG.width,
              h: EVENT_LABEL_CONFIG.height,
              schematicIdx: shapeIdx, // Track schematic index for efficient reverse lookup from labels
              text: "",
            };

            // Add the event label and track the index for efficient lookups
            let eventLabelIdx = eventLabels.push(eventLabel);
            schematic.eventLabelIdx = eventLabelIdx - 1;

            // Set text value if there's an existing event count label
            if (eventCountLabelsRef.current && eventCountLabelsRef.current.length > 0) {
              eventLabels[eventLabelIdx - 1].text = eventCountLabelsRef.current[eventLabelIdx - 1].text;
            }
          }

          bandSchematicLookup.current[config.eventSeverity].push(shapeIdx);
          shapeIdx++;
        }
      }

      // console.log("Created schematics for Konva triangles:", schematics);
      // console.log(`|-- Configured canvas dimensions: ${canvasWidth} x ${canvasHeight}`);
      setShapeSchematics(schematics);
      setEventCountLabels(eventLabels);

      if (!defferedDataLoad) {
        setDefferedDataLoad(true);
      }
    }
  }, [shapeSchematics, canvasWidth, canvasHeight, sections, stepAngleRadians]);

  // Build tick marks with number labels schematics
  useEffect(() => {
    // Don't continue if component isn't mounted
    if (!isMounted.current) return;

    if (maxRadiusRef.current === null) return;
    if (tickMarks && tickMarks.length > 0) return;

    const step = 2; // Degrees
    const tickCount = Math.round(360 / step);
    const centerX = canvasWidth / 2;
    const centerY = canvasHeight / 2;
    const keyAngles = new Set([0, 90, 180, 270]);
    let ticks = [];
    let labels = [];

    let angleDeg = 0;
    let angleRad = 0;
    let angleDegWithOffset = 0;
    let text = "";
    let x = 0;
    let y = 0;

    for (let i = 0; i < tickCount; i++) {
      angleDeg = i * step;
      angleDegWithOffset = angleDeg + 90;
      angleRad = dataContext.toRadians(angleDeg);
      x = centerX + (maxRadiusRef.current * Math.cos(angleRad));
      y = centerY + (maxRadiusRef.current * Math.sin(angleRad));

      let tick = {
        x: x,
        y: y,
        w: 3,
        h: -10, // Negative height to make tick marks go outside of circle
        angleDeg: angleDegWithOffset, // Add 90 degrees to ensure tick marks point outward
        angleRad: angleRad,
        stroke: "#A0211E",
        fill: "#A0211E",
      };

      // Initialize label each iteration
      text = "";

      // Only set label every 20 degrees
      if ((angleDegWithOffset % 20) === 0) {
        // Ensure degrees labels are fixed width
        text = (angleDegWithOffset % 360).toString().padStart(3, " ");
      } else if (angleDegWithOffset !== 0 && (angleDegWithOffset % 2) === 0) {
        // Inner tick marks are every 2 degrees and are not a core tick mark that occurs every 10 degrees
        if ((angleDegWithOffset % 10) !== 0) {
          tick = {
            ...tick,
            w: 2,
            h: -5,
            stroke: "#007800",
            fill: "#007800",
          };
        }
      }

      let label = {
        x: x,
        y: y,
        text: text,
        angleDeg: angleDegWithOffset,
      }

      ticks.push(tick);
      labels.push(label);

      // Add additional line from centerpoint if the current angle in degrees is one of the key angles
      if (keyAngles.has(angleDeg)) {
        ticks.push({ ...tick, h: maxRadiusRef.current, label: "", stroke: "#A0211E90", fill: "#A0211E90" });
      }
    }

    setTickMarks(ticks);
    setTickMarkLabels(labels);

  }, [tickMarks, tickMarkLabels, canvasWidth, canvasHeight]);

  // Process data when possible
  useEffect(() => {
    // Don't continue if component isn't mounted
    if (!isMounted.current) return;

    // Only process if the shape schematics have been initialized and populated
    if (shapeSchematics === null || shapeSchematics.length === 0) return;

    // Obtain reference to active data from context
    let dataTarget = dataContext.filteredData ? dataContext.filteredData : dataContext.baseData;

    if (dataTarget) {
      // console.log("Processing data for compass rose chart...");

      shapeSchematicsRef.current = [...shapeSchematics];
      eventCountLabelsRef.current = [...eventCountLabels];

      // Identify schematics with events for building tooltips
      let schematicsWithEvents = {};

      // Initialize shape schematic and event count label references to ensure there's no persisting event labels after data updates
      for (let i = 0; i < shapeSchematicsRef.current.length; i++) {
        shapeSchematicsRef.current[i].eventCount = 0;
        shapeSchematicsRef.current[i].fill = shapeSchematicsRef.current[i].baseColor;
        shapeSchematicsRef.current[i].suppressEvents = true;
      }

      for (let i = 0; i < eventCountLabelsRef.current.length; i++) {
        eventCountLabelsRef.current[i].text = "";
        eventCountLabelsRef.current[i].schematicIdx = -1;
      }

      // Extract lookup table and data from track points or exceedance points if track points aren't available
      let dataExists = dataTarget.exceedance_event && dataTarget.exceedance_event.data && dataTarget.exceedance_event.data.length > 0;
      if (dataExists) {
        const { data, lookup } = dataTarget.exceedance_event; // Extract lookup table from track points data
        // console.log("events data:", data);
        // console.log("events lookup:", lookup);

        Array.from(data).forEach(point => {
          // Given an angle (a) in degrees such that -90 <= a <= 90, and risk value (v) such
          // that 0 <= v <= 3, identify the correct band section that corresponds to the provided
          // a and v variables.
          let a = point[lookup.fms_driftangle_normalized];
          let v = point[lookup.exceedance_subtype] === "Tailwind" ? point[lookup.exceedance_value] : null ;
          // console.log(`drift angle = ${a}, exceedanceVal = ${v}`);

          // Normalize angle to be between 0 and 360 degrees
          if (a < 0) {
            a += 360;
          }

          // Handle low risk and up
          // console.log(bandSchematicLookup.current)
          if (v > 0 && v in bandSchematicLookup.current) {
            // Search for the shape schematic corresponding to the current angle and risk
            for (let idx of bandSchematicLookup.current[v]) {
              let schematic = shapeSchematicsRef.current[idx];

              // Determine if the angle and risk value belongs to the schematic
              if (a !== null && a >= schematic.beginAngleDeg && a <= schematic.endAngleDeg) {
                shapeSchematicsRef.current[idx].eventCount++;
                shapeSchematicsRef.current[idx].fill = schematic.eventColor;
                shapeSchematicsRef.current[idx].suppressEvents = false;

                // Update the associated label
                if (schematic.eventLabelIdx >= 0) {
                  // Format number to ensure it fits within bounds of cell
                  let eventCount = shapeSchematicsRef.current[idx].eventCount;
                  eventCountLabelsRef.current[schematic.eventLabelIdx].text = eventCount >= 1000 ? dataContext.bigNumberFormatter(eventCount, 0) : eventCount.toString();
                  eventCountLabelsRef.current[schematic.eventLabelIdx].schematicIdx = idx;
                }

                // Add the schematic index if not already done
                if (!(schematic.id in schematicsWithEvents)) {
                  schematicsWithEvents[schematic.id] = idx;
                }

                break;
              }
            }
          }
        });

        // console.log("Setting shape schematics after processing data...", shapeSchematicsRef.current);
        // console.log("Setting event count labels after processing data...", eventCountLabelsRef.current);
        // console.log("Used band schematic lookup:", bandSchematicLookup.current);

        // console.log("Schematics with events:", schematicsWithEvents);

        // Build tooltips
        for (let key of Object.keys(schematicsWithEvents)) {
          let idx = schematicsWithEvents[key];
          let schematic = shapeSchematicsRef.current[idx];

          // Build fields of interest and set tooltip text accordingly
          shapeSchematicsRef.current[idx].tooltip.text = {
            "Wind Azimuth Range": `${schematic.beginAngleDeg}° to ${schematic.endAngleDeg}°`,
            "Severity": schematic.risk,
            "Count": schematic.eventCount,
          };
        }
      }

      // Set new shape schematics
      setShapeSchematics(shapeSchematicsRef.current);
      setEventCountLabels(eventCountLabelsRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.baseData, dataContext.filteredData, defferedDataLoad]);

  return (
    <>
      {(canvasWidth > 0 && canvasHeight > 0 && shapeSchematics && shapeSchematics.length > 0) ? (
        <Stage
          ref={stageRef}
          width={canvasWidth}
          height={canvasHeight}
          style={dataContext.darkMode ? styles.theme.dark : styles.theme.light}
        >
          {/* Background Layer */}
          <Layer>
            <Rect
              x={0}
              y={0}
              width={canvasWidth}
              height={canvasHeight}
              fill={dataContext.darkMode ? COLORS.DEFAULT_DARK : COLORS.DEFAULT_LIGHT}
              onMouseEnter={() => handleMouseMove(BACKGROUND_LAYER_ID)}
            />
          </Layer>

          {/* Shapes Layer */}
          <Layer>
            {/* Main Triangles/Bands */}
            {shapeSchematics.map((schematic, idx) => (
              <Shape
                id={schematic.id}
                key={`main-band-${idx}`}
                sceneFunc={(context, shape) => {
                  const {
                    x,
                    y,
                    beginAngleRad: start,
                    endAngleRad: end,
                    radius,
                  } = schematic;

                  // console.log("Calling scene function from CompassRose component!");
                  context.beginPath();
                  context.setLineDash([5, 10]);
                  context.moveTo(x, y);
                  context.arc(x, y, radius, start, end);
                  // context.lineTo(x, y);
                  context.closePath();

                  // (!) Konva specific method, it is very important
                  context.fillStrokeShape(shape);
                }}
                fill={schematic.fill}
                stroke={schematic.stroke}
                strokeWidth={schematic.strokeWidth}
                onMouseMove={() => {
                  // Change cursor to pointer only for cells that have events
                  if (schematic.eventCount > 0) {
                    document.body.style.cursor = "pointer";
                  }

                  handleMouseMove(schematic.id);
                }}
                onMouseOut={() => {
                  document.body.style.cursor = "default"; // Change cursor back to default
                  handleMouseOut(schematic.id);
                }}
                onClick={() => handleDrilldown(schematic)}
              />
            ))}

            {/* Draw solid boarders around bands */}
            {shapeConfigs.map((config, idx) => (
              <Shape
                key={`band-outline-${idx}`}
                sceneFunc={(context, shape) => {
                  const { radius } = config;

                  context.beginPath();
                  context.arc(canvasWidth / 2, canvasHeight / 2, radius, 0, 2 * Math.PI);
                  context.closePath();

                  // (!) Konva specific method, it is very important
                  context.fillStrokeShape(shape);
                }}
                fillEnabled={false} // Don't fill so that main band events still work
                stroke={dataContext.darkMode ? "#fff" : "#000"}
                strokeWidth={1}
              />
            ))}

            {/* Tick Marks */}
            {tickMarks.map((tick, idx) => (
              <Rect
                key={`outer-tick-mark-${idx}`}
                x={tick.x}
                y={tick.y}
                width={tick.w}
                height={tick.h}
                rotation={tick.angleDeg} // Rotation in degrees
                fill={tick.fill}
                stroke={tick.stroke}
                strokeWidth={1}
              />
            ))}

            {/* Tick Mark Number Labels */}
            {tickMarkLabels.map((label, idx) => (
              <Text
                key={`outer-tick-mark-label-${idx}`}
                x={label.x}
                y={label.y}
                offsetX={10}  //  5
                offsetY={30}  // 20
                fontFamily="Calibri"
                fontStyle="bold"
                fontSize={16} // 10
                text={label.text}
                align="center"
                fill={dataContext.darkMode ? "#fff" : "#000"}
                rotation={label.angleDeg}
              />
            ))}

            {/* HTML Legend */}
            {/* See: https://konvajs.org/docs/react/DOM_Portal.html */}
            <Html>
              <div style={{ margin: 10 }}>
                <table>
                  <tbody>
                    {shapeConfigs.filter(config => config.risk !== null).map((config, i) => (
                      <tr key={`tailwind-legend-label-${i}`}>
                        <td>
                          <i
                            className="fa fa-square legend-icon-fonts"
                            style={{
                              color: config.eventColor,
                              marginRight: 5,                                                           
                              // textShadow: "-2px 0 #000, 0 2px #000, 2px 0 #000, 0 -2px #000"
                            }}
                          >
                          </i>
                        </td>
                        <td 
                          style={{ 
                            color: dataContext.darkMode ? "#fff" : "#000",
                            fontFamily: "Roboto",
                            fontWeight: "bold",
                            fontSize: 12, 
                          }}
                        >
                          {config.risk}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Html>

            {/* Helicopter Center Image */}
            {helicopterSvgData.map((data, idx) => (
              <Path
                key={`helicopter-svg-data-part-${idx}`}
                x={(canvasWidth / 2) - (maxRadiusRef.current / 4)}
                y={(canvasHeight / 2) - (maxRadiusRef.current / 4)}
                width={CENTER_IMAGE_CONFIG.width}
                height={CENTER_IMAGE_CONFIG.height}
                data={data}
                fill={dataContext.darkMode ? "#fff" : "#000"}
                scale={{
                  x: maxRadiusRef.current / 1000,
                  y: maxRadiusRef.current / 1000,
                }}
              />
            ))}

            {/* Tailwind Severity/Event Count Labels */}
            {eventCountLabels.map((label, idx) => (
              <Text
                key={`tailwind-event-label-${idx}`}
                x={label.x}
                y={label.y}
                width={label.w}
                height={label.h}
                text={label.text}
                fontFamily="Calibri"
                // fontStyle="bold"
                fontSize={EVENT_LABEL_CONFIG.fontSize}
                align="center"
                fill="white"
                stroke="white"
                onMouseMove={() => {
                  // Change cursor to pointer only if the cell associated with the event label has events
                  if (label.schematicIdx >= 0 && shapeSchematics[label.schematicIdx].eventCount > 0) {
                    document.body.style.cursor = "pointer";
                  }

                  handleMouseMove(label.id);
                }}
                onMouseOut={() => {
                  document.body.style.cursor = "default"; // Change cursor back to default
                  handleMouseOut(label.id);
                }}
                onClick={() => {
                  // Propagate drilldown event through label associated with schematic
                  let schematic = label.schematicIdx >= 0 ? shapeSchematics[label.schematicIdx] : null;
                  if (schematic) {
                    handleDrilldown(schematic);
                  }
                }}
              />
            ))}
          </Layer>

          {/* Tooltips Layer */}
          <Layer>
            {/* NOTE: Only render tooltips for shapes that are currently being hovered */}
            {shapeSchematics.filter(schematic => schematic.hovered && schematic.eventCount > 0).map((schematic, idx) => (
              schematic.tooltip.text ?
                <Html
                  key={`tooltip-${idx}`}
                >
                  <div
                    style={{
                      padding: 5,
                      position: "absolute",
                      fontSize: 14,
                      // lineHeight: 1,
                      border: `${schematic.eventColor} solid 2px`,
                      background: dataContext.darkMode ? "rgba(23, 27, 34, 0.9)" : "rgba(255, 255, 255, 0.9)",
                      zIndex: 2000,
                      ...calculateTooltipPosition(schematic.tooltip),
                    }}
                  >
                    <table>
                      <tbody>
                        {Object.entries(schematic.tooltip.text).map((entry, entryIdx) => (
                          <tr key={`${schematic.id}-tooltip-text-row-${entryIdx}`}>
                            <td>
                              <i
                                className="fa fa-square tooltip-icon-fonts"
                                style={{
                                  color: schematic.eventColor,
                                  marginRight: 5,
                                }}
                              >
                              </i>
                            </td>
                            <td style={{ color: "#989898" }}><b>{entry[0]}&nbsp;</b></td>
                            <td style={{ color: dataContext.darkMode ? "#fff" : "#000" }}><b>{entry[1]}</b></td>
                          </tr>
                        ))}

                      </tbody>
                    </table>
                  </div>
                </Html> : null
            ))}
          </Layer>
        </Stage>
      ) : (
        <div></div>
      )}
    </>
  )
}

TailwindCompassRoseChart.propTypes = propTypes;
TailwindCompassRoseChart.defaultProps = defaultProps;

export default TailwindCompassRoseChart;

