import React, { useContext } from 'react';
import { DataContext } from '../DataContext';

const styles = {
  container: {
    margin: 10,
    color: "#969696"
  },
  declaimer: {
    fontSize:18,
    fontStyle: "italic",
    paddingBottom: 5
  }
};

function FlightIdBanner({ style , disclaimer }) {
  const dataContext = useContext(DataContext);

  return (
    <div style={{ ...styles.container, ...style}}>
      <h6>
        <div style={{ ...styles.declaimer}}>
        { disclaimer ? <div>{disclaimer}</div> : <></>
        }
        </div>
        <>Flight ID: { dataContext.activeFlight ? dataContext.activeFlight : "No Data" } <br/>
        </>
        
      </h6>
    </div>
  );
}

export default FlightIdBanner;
