import React, { useState, useEffect, useContext } from 'react'
import {
    Fieldset,
    Label,
    TextInput,
    Dropdown,
    Button,
    Checkbox,
    ButtonGroup
} from '@trussworks/react-uswds'
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import Navbar from '../components/DashboardNav'
import Footer from '../components/PageFooter'
import './AddAircraft.css'
import getMissionsAndMapping from '../services/getMissionsAndMapping'
import UserContext from '../components/UserContext';
import addAircraft from "../services/updateRegistryAddAircraft"
import sendApproverEmail from "../services/sendApproverEmail"

function AddAircraft(props) {
    const contextType = useContext(UserContext);
    const [tail, setTail] = useState('')
    const [alias, setAlias] = useState('')
    const [MMSMMV, setMMSMMV] = useState(1)
    const [missionType, setMissionType] = useState('')
    const [newModel, setNewModel] = useState('')
    const [newMake, setNewMake] = useState('')
    const [newSeries, setNewSeries] = useState('')
    const [newRecorderModel, setNewRecorderModel] = useState('')
    const [newRecorderMake, setNewRecorderMake] = useState('')
    const [newRecorderVersion, setNewRecorderVersion] = useState('')
    const [existModel, setExistModel] = useState('')
    const [existMake, setExistMake] = useState('')
    const [existSeries, setExistSeries] = useState('')
    const [existRecorderModel, setExistRecorderModel] = useState('')
    const [existRecorderMake, setExistRecorderMake] = useState('')
    const [existRecorderVersion, setExistRecorderVersion] = useState('')
    const [heliMakeList, setHeliMakeList] = useState([])
    const [heliModelList, setHeliModelList] = useState([])
    const [heliSeriesList, setHeliSeriesList] = useState([])
    const [recMakeList, setRecMakeList] = useState([])
    const [recModelList, setRecModelList] = useState([])
    const [recVersionList, setRecVersionList] = useState([])
    const [existMission, setExistMission] = useState('')
    const [existMissionShort, setExistMissionShort] = useState('')
    // const [results, setResults] = useState({})
    const [isLoaded, setIsLoaded] = useState(false)
    // const [error, setError] = useState({})
    const [missions, setMissions] = useState({})
    const [mappings, setMappings] = useState([])
    const [filteredMappings, setFilteredMappings] = useState([])
    const [isExcludeFromAggregates, setIsExcludeFromAggregates] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [operator] = useState(props.location && props.location.state && props.location.state.operator ? props.location.state.operator : contextType.user.attributes['custom:operator'])


    useEffect(() => {
        getMissionsAndMapping(contextType.user)
            .then((results) => {
                // setResults(results)
                setMissions(results[0])
                setMappings(results[1])
                setFilteredMappings(results[1])
                setIsLoaded(true)
                // setIsexcludefromaggregates(false)
            })
            .catch((error) => {
                // setError(error)
            })
    }, [])
    

    useEffect(() => {

        if (filteredMappings) {
            // Init Lists
            let uniqueHeliMakeList = [];
            let uniqueHeliModelList = [];
            let uniqueHeliSeriesList = [];
            let uniqueRecMakeList = [];
            let uniqueRecModelList = [];
            let uniqueRecVersionList = [];


            // Parse filtered mappings for each list it builds
            filteredMappings.map((row) => {
                if (uniqueHeliMakeList.indexOf(row['icao_make']) === -1) {
                    uniqueHeliMakeList.push(row['icao_make'])
                }
                if (uniqueHeliModelList.indexOf(row['icao_model']) === -1) {
                    uniqueHeliModelList.push(row['icao_model'])
                }
                if (uniqueHeliSeriesList.indexOf(row['icao_series']) === -1) {
                    uniqueHeliSeriesList.push(row['icao_series'])
                }
                if (uniqueRecMakeList.indexOf(row['recorder_make']) === -1) {
                    uniqueRecMakeList.push(row['recorder_make'])
                }
                if (uniqueRecModelList.indexOf(row['recorder_model']) === -1) {
                    uniqueRecModelList.push(row['recorder_model'])
                }
                if (uniqueRecVersionList.indexOf(row['recorder_version']) === -1) {
                    uniqueRecVersionList.push(row['recorder_version'])
                }
            })
            setHeliMakeList(uniqueHeliMakeList)
            setHeliModelList(uniqueHeliModelList)
            setHeliSeriesList(uniqueHeliSeriesList)
            setRecMakeList(uniqueRecMakeList)
            setRecModelList(uniqueRecModelList)
            setRecVersionList(uniqueRecVersionList)
        }
    }, [filteredMappings])


    useEffect(() => {
        //Filter data
        let uniqueItems = [];
        if (existMake === '') {
            setHeliModelList([])
        } else
            if (mappings) {
                mappings.filter(row => {
                    return (row["icao_make"].indexOf(existMake) > -1)
                }).map((row) => {
                    if (uniqueItems.indexOf(row['icao_model']) === -1) {
                        uniqueItems.push(row['icao_model'])
                    }
                })

                setHeliModelList(uniqueItems)
            }


        // Reset lower values
        uniqueItems.length === 1 ? setExistModel(uniqueItems[0]) : setExistModel('')


    }, [existMake])

    useEffect(() => {
        //Filter data
        let uniqueItems = [];
        if (existModel === '') {
            setHeliSeriesList([])
        } else
            if (mappings) {
                mappings.filter(row => {
                    return (row["icao_make"].indexOf(existMake) > -1 &&
                        row["icao_model"].indexOf(existModel) > -1)
                }).map((row) => {
                    if (uniqueItems.indexOf(row['icao_series']) === -1) {
                        uniqueItems.push(row['icao_series'])
                    }
                })
                setHeliSeriesList(uniqueItems)
            }

        // Reset lower values
        uniqueItems.length === 1 ? setExistSeries(uniqueItems[0]) : setExistSeries('')

    }, [existModel])

    useEffect(() => {
        //Filter data
        let uniqueItems = [];
        if (existSeries === '') {
            setRecMakeList([])
        } else
            if (mappings) {
                mappings.filter(row => {
                    return (row["icao_make"].indexOf(existMake) > -1 &&
                        row["icao_model"].indexOf(existModel) > -1 &&
                        row["icao_series"].indexOf(existSeries) > -1)
                }).map((row) => {
                    if (uniqueItems.indexOf(row['recorder_make']) === -1) {
                        uniqueItems.push(row['recorder_make'])
                    }
                })

                setRecMakeList(uniqueItems)
            }
        // Reset lower values
        // uniqueItems.length > 0 ? console.log(uniqueItems[0]) : console.log("rMake List length 0")
        uniqueItems.length === 1 ? setExistRecorderMake(uniqueItems[0]) : setExistRecorderMake('')

    }, [existSeries])

    useEffect(() => {
        //Filter data
        let uniqueItems = [];
        if (existRecorderMake === '') {
            setRecModelList([])
        } else
            if (mappings) {
                mappings.filter(row => {
                    return (row["icao_make"].indexOf(existMake) > -1 &&
                        row["icao_model"].indexOf(existModel) > -1 &&
                        row["icao_series"].indexOf(existSeries) > -1 &&
                        row["recorder_make"].indexOf(existRecorderMake) > -1)
                }).map((row) => {
                    if (uniqueItems.indexOf(row['recorder_model']) === -1) {
                        uniqueItems.push(row['recorder_model'])
                    }
                })
                setRecModelList(uniqueItems)
            }
        // uniqueItems.length > 0 ? console.log(uniqueItems[0]) : console.log("rModel List length 0")
        // Reset lower values
        uniqueItems.length === 1 ? setExistRecorderModel(uniqueItems[0]) : setExistRecorderModel('')

    }, [existRecorderMake])

    useEffect(() => {
        //Filter data
        let uniqueItems = [];
        if (existRecorderModel === '') {
            setRecVersionList([])
        } else
            if (mappings) {
                mappings.filter(row => {
                    return (row["icao_make"].indexOf(existMake) > -1 &&
                        row["icao_model"].indexOf(existModel) > -1 &&
                        row["icao_series"].indexOf(existSeries) > -1 &&
                        row["recorder_make"].indexOf(existRecorderMake) > -1 &&
                        row["recorder_model"].indexOf(existRecorderModel) > -1)
                }).map((row) => {
                    if (uniqueItems.indexOf(row['recorder_version']) === -1) {
                        uniqueItems.push(row['recorder_version'])
                    }
                })
                setRecVersionList(uniqueItems)
            }
        // uniqueItems.length > 0 ? console.log(uniqueItems[0]) : console.log("rVersion List length 0")

        // Reset lower values
        uniqueItems.length === 1 ? setExistRecorderVersion(uniqueItems[0]) : setExistRecorderVersion('')
    }, [existRecorderModel])



    const MMSMMVCompList = [{
        "id": "heliNameDropdown",
        "label": "Helicopter Make",
        "value": existMake,
        "disableFlag": false,
        "stateData": heliMakeList,
        "dataKey": "icao_make",
        "onChange": (val) => setExistMake(val)
    },
    {
        "id": "heliModelDropdown",
        "label": "Helicopter Model",
        "value": existModel,
        "disableFlag": existMake ? existMake === '' : true,
        "stateData": heliModelList,
        "dataKey": "icao_model",
        "onChange": (val) => setExistModel(val)
    },
    {
        "id": "heliSeriesDropdown",
        "label": "Helicopter Series",
        "value": existSeries,
        "disableFlag": existModel ? existModel === '' : true,
        "stateData": heliSeriesList,
        "dataKey": "icao_series",
        "onChange": (val) => setExistSeries(val)
    },
    {
        "id": "recMakeDropdown",
        "label": "Recorder Make",
        "value": existRecorderMake,
        "disableFlag": existSeries ? existSeries === '' : true,
        "stateData": recMakeList,
        "dataKey": "recorder_make",
        "onChange": (val) => setExistRecorderMake(val)
    },
    {
        "id": "recModelDropdown",
        "label": "Recorder Model",
        "value": existRecorderModel,
        "disableFlag": existRecorderMake ? existRecorderMake === '' : true,
        "stateData": recModelList,
        "dataKey": "recorder_model",
        "onChange": (val) => setExistRecorderModel(val)
    },
    {
        "id": "recVersionDropdown",
        "label": "Recorder Version",
        "value": existRecorderVersion,
        "disableFlag": existRecorderModel ? existRecorderModel === '' : true,
        "stateData": recVersionList,
        "dataKey": "recorder_version",
        "onChange": (val) => setExistRecorderVersion(val)
    }]

    const handleCloseConfirm = () => setShowConfirm(false)
    const handleShowConfirm = (e) => {
        e.preventDefault()
        setShowConfirm(true)
    }


    const onChangeMMSMMV = (value) => {
        setMMSMMV(Number(value))

        if (value >= 0) {
            setExistMake(mappings[value]['icao_make'])
            setExistModel(mappings[value]['icao_model'])
            setExistSeries(mappings[value]['icao_series'])
            setExistRecorderMake(mappings[value]['recorder_make'])
            setExistRecorderModel(mappings[value]['recorder_model'])
            setExistRecorderVersion(mappings[value]['recorder_version'])
        }


    }

    const onChangeMission = (value) => {
        // console.log(missions[value])
        setMissionType(value)
        if (value > 0) {
            setExistMission(missions[value]['mission'])
            setExistMissionShort(missions[value]['mission_short'])
        }
    }

    const submitAircraftRequest = (e) => {
        e.preventDefault()
        //   console.log(MMSMMV)
        //   console.log(missionType)
        if (!MMSMMV || (MMSMMV !== -1 && !missionType)) {
            alert("You must select an MMS - MMV and a Mapping type for the aircraft you wish to add.")
        } else {
            // First check if the user found their MMSMMV and is making an aircraft request, or couldn't and is submitting an MMSMMV request.
            if (MMSMMV === -1) {
                // Branch for sending approvers emaila sking for MMSMMV.
                // console.log("In send approver email branch")
                let emailSubject = "Request for new MMS - MMV mapping to be added"
                let emailMessage = "<pre>A user would like to add the following MMS - MMV record: \n" +
                    "Operator " + operator + " \n" +
                    "Tail Number " + tail + " \n" +
                    "Alias" + alias + "\n" +
                    "Aircraft Make " + newMake + " \n" +
                    "Aircraft Model " + newModel + " \n" +
                    "Aircraft Series " + newSeries + " \n" +
                    "Recorder Make " + newRecorderMake + " \n" +
                    "Recorder Model " + newRecorderModel + " \n" +
                    "Recorder Version " + newRecorderVersion + " \n </pre>";

                let email = {
                    emailSubject: emailSubject,
                    emailBodyText: emailMessage,
                }
                sendApproverEmail(contextType.user, email).then((result) => {
                    alert('An email requesting the MMS - MMV be added has been sent to the application administrators.')
                    props.history.push({
                        pathname: "/fleet",
                        state: { operator: operator }
                    })
                })

            } else {
                
                // console.log(mappings)

                let mmsMMVFull = mappings.filter(row => {
                    return (row["icao_make"].indexOf(existMake) > -1 &&
                            row["icao_model"].indexOf(existModel) > -1 &&
                            row["icao_series"].indexOf(existSeries) > -1 &&
                            row["recorder_make"].indexOf(existRecorderMake) > -1 &&
                            row["recorder_model"].indexOf(existRecorderModel) > -1 &&
                            row["recorder_version"].indexOf(existRecorderVersion) > -1 )})
                
                let selectedMMSMMV = mmsMMVFull[0]
                // let selectedMission = missions[missionType]
                let body = {
                    operator: operator,
                    tail: tail,
                    alias: alias,
                    

                    icao_mms_id: selectedMMSMMV.icao_mms_id,
                    icao_make: selectedMMSMMV.icao_make,
                    icao_make_short: selectedMMSMMV.icao_make_short,
                    icao_model: selectedMMSMMV.icao_model,
                    icao_model_short: selectedMMSMMV.icao_model_short,
                    icao_series: selectedMMSMMV.icao_series,
                    icao_series_short: selectedMMSMMV.icao_series_short,
                    recorder_make: selectedMMSMMV.recorder_make,
                    recorder_make_short: selectedMMSMMV.recorder_make_short,
                    recorder_model: selectedMMSMMV.recorder_model,
                    recorder_model_short: selectedMMSMMV.recorder_model_short,
                    recorder_version: selectedMMSMMV.recorder_version,
                    default_mission: existMission,
                    default_mission_short: existMissionShort,
                    exclude_aggregates: isExcludeFromAggregates ? "1" : "0"
                }
                // Invoke service passing user context for security and body for messageBody
                addAircraft(contextType.user, body).then((result) => {
                    alert('Tail Number Added')
                    props.history.push({
                        pathname: "/fleet",
                        state: { operator: operator }
                    })

                })
                // add catch for failure
            }
        }
    }


    return (
        <>
            <Navbar />
            <div className="stretch_grid-container">
                <h1>Add Aircraft</h1>
                {
                    operator ?
                        <p>You are adding an aircraft on behalf of operator {operator}</p> :

                        <p>Please provide the information below to add an aircraft to your fleet</p>

                }
                <p>Note: Aircrafts can only be <b>added</b> for an Opeartor’s fleet. Please contact <a href="mailto:RotorcraftHelp@asias.info">RotorcraftHelp@asias.info</a> if you want to make any deletions or updates to the fleet.</p>


                <form onSubmit={handleShowConfirm}>
                    <Fieldset legend="" legendStyle="large">
                        <br />
                        <Button type="button" onClick={() => setMMSMMV(MMSMMV * -1)}>{MMSMMV > 0 ? "Request New MMS - MMV" : "Choose existing MMS - MMV"}</Button>
                        <br />
                       
                            
                                <Label htmlFor="tail-number">Tail Number</Label>
                                <TextInput
                                    id="tail-number"
                                    name="tail-number"
                                    type="text"
                                    onChange={evt => setTail(evt.target.value)}
                                    required
                                />

                                <Label htmlFor="alias">Alias (For display in RAISE Systems)</Label>
                                <TextInput
                                    id="alias"
                                    name="alias"
                                    type="text"
                                    onChange={evt => setAlias(evt.target.value)}
                                    required
                                />


                            {MMSMMV > 0 ?
                                <>
                                {
                                    isLoaded && (
                                        MMSMMVCompList.map((data, index) => (
                                            <>
                                                <Label htmlFor={data["id"]}>{data["label"]}</Label>
                                                <Dropdown id={data["id"]} name={data["id"]}
                                                    onChange={evt => data["onChange"](evt.target.value)}
                                                    disabled={data['disableFlag']}
                                                    value={data['value']}>
                                                    {data['stateData'].length !== 1 ?
                                                        <option value="">- Select {data["label"]} - </option>
                                                        : <></>}

                                                        {data['stateData'].sort((a, b) => a > b ? 1 : -1).map((mapper, index) => (
                                                        <option key={index} value={mapper}>
                                                            {mapper}
                                                        </option>
                                                    )

                                                    )
                                                    }
                                                </Dropdown>
                                            </>
                                        ))
                                    )
                                }


                                {/* <Label htmlFor="mms-mmv">Aircraft Make/Model/Series - Recorder Make/Model/Version</Label>
                    <Dropdown id="mms-mmv" name="mms-mmv"
                    onChange={evt => onChangeMMSMMV(evt.target.value)}>
                        <option>- Select MMS - MMV - </option>
                        <option value="-1" > MMS - MMV Combination is not listed. </option>
                        {
                            isLoaded && (
                            
                                mappings.map((data, index) => (
                                    <option key={index} value={index}>
                                        {data.icao_make}/{data.icao_model}/{data.icao_series} 
                                        {"  -  "}    
                                        {data.recorder_make}/{data.recorder_model}/{data.recorder_version}
                                    </option>
                                ))
                                
                            )
                            
                        }
                    </Dropdown> */}




                                <div>
                                    <Label htmlFor="mission-dropdown">Default Mission Type</Label>
                                    <Dropdown id="mission-dropdown" name="mission-dropdown"
                                        onChange={evt => onChangeMission(evt.target.value)}
                                        required={true}>
                                        <option>- Select Default Mission Type -</option>
                                        {
                                            isLoaded && (
                                                missions.map((data, index) => (
                                                    <option key={index} value={index}>
                                                        {data.mission}
                                                    </option>
                                                ))
                                            )

                                        }


                                    </Dropdown>

                                    <Checkbox style={{ paddingTop: '0px' }} id="secondary-phone-input-checkbox" name="exclude-from-aggregate-checkbox" label="Exclude from Aggregate data" checked={isExcludeFromAggregates} onChange={evt => setIsExcludeFromAggregates(evt.target.checked)} />
                                    Selecting this checkbox will exclude the flight's information from general data trends.  This is typically used for simulator information which is outside of normal operational boundaries.
                                </div>
                            </>
                            :
                            <div>
                                <br />
                                Please fill out the below fields to request a new MMS - MMV to be created.
                                <Label htmlFor="newMake">Make</Label>
                                <TextInput
                                    id="newMake"
                                    name="newMake"
                                    type="text"
                                    onChange={evt => setNewMake(evt.target.value)}
                                    required={MMSMMV === -1}
                                />
                                <Label htmlFor="newModel">Model</Label>
                                <TextInput
                                    id="newModel"
                                    name="newModel"
                                    type="text"
                                    onChange={evt => setNewModel(evt.target.value)}
                                    required={MMSMMV === -1}
                                />
                                <Label htmlFor="newSeries">Series</Label>
                                <TextInput
                                    id="newSeries"
                                    name="newSeries"
                                    type="text"
                                    onChange={evt => setNewSeries(evt.target.value)}
                                    required={MMSMMV === -1}
                                />
                                <Label htmlFor="newRecorderMake">Recorder Make</Label>
                                <TextInput
                                    id="newRecorderMake"
                                    name="newRecorderMake"
                                    type="text"
                                    onChange={evt => setNewRecorderMake(evt.target.value)}
                                    required={MMSMMV === -1}
                                />
                                <Label htmlFor="newRecorderModel">Recorder Model</Label>
                                <TextInput
                                    id="newRecorderModel"
                                    name="newRecorderModel"
                                    type="text"
                                    onChange={evt => setNewRecorderModel(evt.target.value)}
                                    required={MMSMMV === -1}
                                />
                                <Label htmlFor="newRecorderVersion">Recorder Version</Label>
                                <TextInput
                                    id="newRecorderVersion"
                                    name="newRecorderVersion"
                                    type="text"
                                    onChange={evt => setNewRecorderVersion(evt.target.value)}
                                    required={MMSMMV === -1}
                                />



                            </div>
                        }
                        <ButtonGroup style={{ paddingTop: '3em', paddingBottom: '5em' }}>
                            <Link to={{ pathname: '/fleet', state: { operator: operator } }} ><Button type="button" outline>Cancel</Button></Link>
                            <Button type="submit">Submit</Button>
                        </ButtonGroup>


                    </Fieldset>
                </form>


                <Footer />

                <Modal
                    size="lg"
                    show={showConfirm}
                    scrollable={true}
                    onHide={handleCloseConfirm}
                >
                    <Modal.Header>
                        <Modal.Title>Add Aircraft Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Warning: Once created this flight can only be removed with the help of Administrators.  Please confirm the information before proceeding.
                        <br />
                        Tail Number: {tail} <br />
                        Alias: {alias} <br />

                        Make: {(MMSMMV > -1) ? (existMake) : (newMake)} <br />
                        Model: {(MMSMMV > -1) ? (existModel) : (newModel)} <br />
                        Series: {(MMSMMV > -1) ? (existSeries) : (newSeries)} <br />
                        Recorder Make: {(MMSMMV > -1) ? (existRecorderMake) : (newRecorderMake)} <br />
                        Recorder Model: {(MMSMMV > -1) ? (existRecorderModel) : (newRecorderModel)} <br />
                        Recorder Version: {(MMSMMV > -1) ? (existRecorderVersion) : (newRecorderVersion)} <br />
                        {MMSMMV > -1 ? <><div>Default Mission: {existMission} <br /></div> 
                        Aggregate Exclusion: {isExcludeFromAggregates ? 'True' : 'False'} <br /></>: <></>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseConfirm}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={submitAircraftRequest}>
                            Submit
                        </Button>

                    </Modal.Footer>
                </Modal>

            </div>
        </>
    )

}

export default AddAircraft
