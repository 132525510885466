import React, { useContext, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DataContext } from '../DataContext';
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import ReplayIcon from '@mui/icons-material/Replay';
import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import SettingsIcon from '@mui/icons-material/Settings';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import DeleteIcon from '@mui/icons-material/Delete';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import Slide from '@mui/material/Slide';
import SaveIcon from '@mui/icons-material/Save';
import VerticalDivider from './VerticalDivider';
import AddList from '../AddList';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Placeholder for defining add list prop types
const AddListPropsType = () => null;

AddListPropsType.propTypes = {
  items: PropTypes.array,
  onRemoveItem: PropTypes.func,
  onAddItem: PropTypes.func,
  originalItems: PropTypes.array,
  dashboardKey: PropTypes.string,
}

const propTypes = {
  AddListProps: AddListPropsType,
  onLayoutSave: PropTypes.func,
  onRef: PropTypes.func,
  excludeBackToFlightsLink: PropTypes.bool,
  style: PropTypes.object,
};

const defaultProps = {
  style: {},
  onRef: (ref) => ref.current,
  excludeBackToFlightsLink: false,
};

const styles = {
  container: {
    display: "flex",
  },
  content: {
    flex: 1,
  }
}

const Toolbar = (props) => {
  const {
    AddListProps,
    onLayoutSave,
    onRef,
    excludeBackToFlightsLink,
    style,
  } = props;

  const dataContext = useContext(DataContext);

  const toolbarRef = useRef(null);

  const [resetConfirmOpen, setResetConfirmOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleSettingsOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setAnchorEl(null);
  };

  const onClearDataCache = () => {
    dataContext.clearLS();
    dataContext.showSnack("top", "center", "Cleared App Cache!", dataContext.SNACK_SEVERITY.SUCCESS, 2000);
  }

  const toggleDarkMode = () => {
    let toggleState = !dataContext.darkMode;
    dataContext.preventUIBlocking();

    // Add slight delay for user experience optimization
    setTimeout(() => {
      dataContext.setDarkMode(toggleState);

      // Save dark mode state to local storage
      dataContext.saveToLS(dataContext.DARK_MODE_KEY, toggleState);
    }, 100);
  };

  const handleOpenResetConfirmationDialog = () => {
    setResetConfirmOpen(true);
  }

  const handleCloseResetConfirmationDialog = () => {
    setResetConfirmOpen(false);
  }

  /**
   * Undo the latest applied data filter.
   */
  const handleUndoFilter = () => {
    // alert("Undo filter!");
    dataContext.preventUIBlocking();
    dataContext.undoLastAppliedFilter();
  }

  /**
   * Redo the latest data filter that was formerly undone or manually removed.
   */
  const handleRedoFilter = () => {
    // alert("Redo filter!");
    dataContext.preventUIBlocking();
    dataContext.redoLastUndoneFilter();
  }

  /**
   * Reset all applied filters to return to the original base dataset state.
   */
  const handleResetFilters = () => {
    setResetConfirmOpen(false);
    dataContext.preventUIBlocking();
    dataContext.resetAllFilters();
  }

  useEffect(() => {
    if (toolbarRef.current) {
      onRef(toolbarRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolbarRef.current]);

  return (
    <div ref={toolbarRef} style={{ ...styles.container, ...style }}>
      <div style={styles.content}>
        {/* Back to Flights */}
        {!excludeBackToFlightsLink && (
          <>
            {/* Icon buttion version of back to flights link if desired */}
            {/* <IconButton
              onClick={dataContext.backToFlights}
              // color="inherit"
              size="small"
              sx={{ m: 1 }}
              aria-controls={'back-to-flights-button'}
              aria-haspopup="false"
            >
              <Tooltip title="Back to Flights" placement="bottom" arrow>
                <ArrowBackIcon />
              </Tooltip>
            </IconButton> */}
            <Link
              to="#"
              style={{ marginLeft: '16px', marginRight: '16px' }}
              onClick={dataContext.backToFlights}
            >
              {dataContext.getBackToFlightsFn() ? "Back to Flights" : "Go Back"}
            </Link>
            <VerticalDivider />
          </>
        )}

        <IconButton
          onClick={handleUndoFilter}
          disabled={dataContext.processing || dataContext.filterList.length === 0}
          // color="inherit"
          size="small"
          sx={{ m: 1 }}
          aria-controls={'undo-filter'}
          aria-haspopup="false"
        >
          <Tooltip title="Undo Previous Filter" placement="bottom" arrow>
            <UndoIcon />
          </Tooltip>
        </IconButton>
        <IconButton
          onClick={handleRedoFilter}
          disabled={dataContext.processing || dataContext.redoFilterList.length === 0}
          // color="inherit"
          size="small"
          sx={{ m: 1 }}
          aria-controls={'redo-filter'}
          aria-haspopup="false"
        >
          <Tooltip title="Redo Previous Filter" placement="bottom" arrow>
            <RedoIcon />
          </Tooltip>
        </IconButton>
        <IconButton
          onClick={handleOpenResetConfirmationDialog}
          disabled={
            dataContext.processing ||
            (dataContext.filterList.length === 0 && dataContext.redoFilterList.length === 0)
          }
          // color="inherit"
          size="small"
          sx={{ m: 1 }}
          aria-controls={'reset-filters'}
          aria-haspopup="false"
        >
          <Tooltip title="Reset all Filters" placement="bottom" arrow>
            <ReplayIcon />
          </Tooltip>
        </IconButton>
        <VerticalDivider />

        {/* Header Components Start */}
        {/* Reference: https://mui.com/components/menus/#account-menu */}
        <IconButton
          onClick={handleSettingsOpen}
          // color="inherit"
          size="small"
          sx={{ m: 1 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Tooltip title="Settings" placement="bottom" arrow>
            <SettingsIcon
            // sx={{ fill: dataContext.darkMode ? "#fff" : "#000" }} 
            />
          </Tooltip>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleSettingsClose}
          onClick={handleSettingsClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={onClearDataCache}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            Clear App Cache
          </MenuItem>
        </Menu>

        <IconButton
          // color="inherit"
          aria-label="toggle dark mode"
          onClick={toggleDarkMode}
          size="small"
          sx={{ m: 1 }}
          disabled={dataContext.processing}
        >
          <Tooltip title={dataContext.darkMode ? "Turn Dark Mode Off" : "Turn Dark Mode On"} placement="bottom" arrow>
            {dataContext.darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
          </Tooltip>
        </IconButton>
        {/* Header Components End */}

        {/* Optional components to support different placements of the toolbar in application */}
        {(onLayoutSave || AddListProps) && <VerticalDivider />}

        {/* Save Dashboard Tab */}
        {onLayoutSave && (
          <IconButton
            aria-label="save"
            onClick={onLayoutSave}
            sx={{ m: 1 }}
          >
            <Tooltip title="Save Dashboard Tab Layout" placement="top" arrow>
              <SaveIcon />
            </Tooltip>
          </IconButton>
        )}

        {/* Add/Remove Dashboard Widgets */}
        {AddListProps && (
          <AddList
            {...AddListProps}
          />
        )}
      </div>

      <Dialog
        open={resetConfirmOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseResetConfirmationDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Reset all applied filters?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            All applied filters for each dashboard tab will be lost.
            Are you sure you would like to reset filters?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={dataContext.styles.buttonPrimary} onClick={handleResetFilters}>Yes</Button>
          <Button sx={dataContext.styles.buttonSecondary} onClick={handleCloseResetConfirmationDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

Toolbar.propTypes = propTypes;
Toolbar.defaultProps = defaultProps;

export default Toolbar;
