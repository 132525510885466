import React, { useContext } from 'react';
import UserContext from '../components/UserContext';
import { DataProvider } from '../components/DataContext';
import UimcAggregateMetricsThemeProvider from '../components/styles/UimcAggregateMetricsThemeProvider';
import UimcAggregateMetrics from './UimcAggregateMetrics';

const propTypes = {};
const defaultProps = {};

const UimcAggregateMetricsWrapper = () => {
  const userContext = useContext(UserContext);

  return (
    <DataProvider>
      <UimcAggregateMetricsThemeProvider>
        <UimcAggregateMetrics 
          user={userContext.user}
        />
      </UimcAggregateMetricsThemeProvider>
    </DataProvider>
  );
}

UimcAggregateMetricsWrapper.propTypes = propTypes;
UimcAggregateMetricsWrapper.defaultProps = defaultProps;

export default UimcAggregateMetricsWrapper;
