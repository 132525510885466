import React, { useState, useRef, useEffect, useContext } from 'react';
import { DataContext } from "../../DataContext";
// import PropTypes from 'prop-types';
import CanvasJSReact from '../../../canvasjs-commercial-3.4.12/canvasjs.react';
import { useResizeDetector } from 'react-resize-detector';
import '../Chart.css';

// const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const propTypes = {};
const defaultProps = {};

// Control/Configure the size of the markers and lines in the chart
// NOTE: Setting these parameters equal to each other will hide the markers until hovered
const DATA_POINT_SIZE = 0; // 8
const LINE_THICKNESS = 2;
const DEFAULT_X_INTERVAL_THRESHOLD = 10; // days

function UimcEventsByDateScatterChart() {
  // Obtain reference to global data context consumer
  const dataContext = useContext(DataContext);

  const { width, height, ref: resizeDetectorRef } = useResizeDetector();

  // Keep reference of chart for event listeners/actions
  const chartRef = useRef(null);
  const isMounted = useRef(false);

  // Determine if data exists
  const d = dataContext.filteredData ? dataContext.filteredData : dataContext.baseData;
  const dataExists = d && d.uimc_events && d.uimc_events.data && d.uimc_events.data.length > 0;

  const [dataOfInterestExists, setDataOfInterestExists] = useState(true);

  /**
   * Handler for parsing date object to a formatted string version.
   * 
   * @param {object} e CanvasJS label event object containing the current date value.
   * @returns The formatted date string.
   */
  const dateLabelFormatter = (e) => {
    return dataContext.toHumanReadableDateStr(e.value, false, true);
  }

  /**
   * Compute the interval configuration for the x-axis based on total point count (representing days).
   * 
   * @param {number} pointCount 
   * @returns 
   */
  const getXAxisIntervalByPointCount = (pointCount) => {
    let newInterval = pointCount > DEFAULT_X_INTERVAL_THRESHOLD ? Math.round((2 + (pointCount / DEFAULT_X_INTERVAL_THRESHOLD)) - 1) : 1;
    return newInterval;
  }

  /**
   * Dynamically adjust chart interval configuration based on current zoom level.
   * 
   * @param {object} e CanvasJS event object with chart metadata.
   */
  const handleRangeChangingInterval = (e) => {
    // See: https://canvasjs.com/forums/topic/x-axis-interval/
    let viewport = e.axisX[0].viewportMaximum - e.axisX[0].viewportMinimum;
    
    // Determine how many points are visibile within the current viewport
    let dpsCount = 0; 
    for (let i = 0; i < e.chart.data[0].dataPoints.length; i++) {
      if (e.chart.data[0].dataPoints[i].x.getTime() <= e.axisX[0].viewportMaximum && e.chart.data[0].dataPoints[i].x.getTime() >= e.axisX[0].viewportMinimum) {
        dpsCount++;
      }
    }

    // Set interval based on how many days worth of points there are with respect to n days threshold
    let newInterval = Math.max(1, Math.round((viewport / dpsCount) / (1000 * 3600 * 24)));
    
    if (!e.chart.options.axisX)
      e.chart.options.axisX = {};

    // e.chart.options.axisX.interval = newInterval;
    if (e.trigger === "reset") {
      // console.log("current viewport after reset:", viewport, "new interval after reset:", newInterval);
      e.chart.options.axisX.interval = getXAxisIntervalByPointCount(e.chart.data[0].dataPoints.length);
    }
    else if (e.trigger === "zoom") {
      // console.log("current viewport after zoom:", viewport, "new interval after zoom:", newInterval);
      e.chart.options.axisX.interval = newInterval;
    }
  }

  /**
   * Add days to a given date. Does not modify the provided date by reference. Returns
   * new date object instead.
   * 
   * @param {Date} date Date object to add days to.
   * @param {number} days Number of days to add.
   * @returns New date object representing the original date plus the desired number of days.
   */
  // const addDays = (date, days) => {
  //   let result = new Date(date);
  //   result.setDate(result.getDate() + days);
  //   return result;
  // }

  /**
   * Build and retrieve formatted HTML string representing a featured
   * tooltip given a set of values for a particular row of interest.
   * 
   * row = {
   *   "key_1": "value_1",
   *   "key_2": "value_2",
   *   ...
   *   "key_n": "value_n"
   * }
   * 
   * @param {object} row Fields of interest for current row in dataset.
   * @param {string} color Hexidecimal or RGB(A) color string.
   * @returns Formatted HTML string representing a featured tooltip.
   */
  const getFeaturedTooltip = (row, color = null) => {
    // Build tool tip off all properties
    let tooltipText = "";

    // Start table
    tooltipText += "<table>";
    for (const [key, value] of Object.entries(row)) {
      let iconData = `<td><i class="fa fa-square tooltip-icon-fonts" style="color: ${color}; margin-right: 5px;"></i></td>`;
      tooltipText += `
        <tr>
          ${color !== null && iconData}
          <td style="color: #989898"><b>${key}&nbsp</b></td>
          <td><b>${value}</b></td>
        </tr>
      `;
    }

    // End table
    tooltipText += "</table>";
    return tooltipText;
  }

  // See CanvasJS configuration options at https://canvasjs.com/docs/charts/basics-of-creating-html5-chart/
  // Value format string: https://canvasjs.com/docs/charts/chart-options/axisx/valueformatstring/
  const [options, setOptions] = useState({
    zoomEnabled: true,
    // zoomType: "xy",
    animationEnabled: true,
    rangeChanging: handleRangeChangingInterval,
    title: {
      text: ""
    },
    theme: dataContext.darkMode ? "dark1" : "light1",
    toolTip: {
      enabled: true,
      animationEnabled: true,
      // shared: true,
      contentFormatter: e => {
        let content = "";
        for (let i = 0; i < e.entries.length; i++) {
          let dataPoint = e.entries[i].dataPoint;
          content = `${content}${dataPoint.tooltipText}`;
        }

        return content;
      }
    },
    legend: {
      fontFamily: dataContext.CHART_FONTS.fontFamily,
      fontSize: dataContext.CHART_FONTS.legendFontSize,
      fontWeight: dataContext.CHART_FONTS.fontWeight,
    },
    axisX: {
      labelFontFamily: dataContext.CHART_FONTS.fontFamily,
      labelFontWeight: dataContext.CHART_FONTS.fontWeight,
      labelAutoFit: true,
      labelAngle: 0,
      title: "",
      gridThickness: 0,
      // See: https://canvasjs.com/docs/charts/basics-of-creating-html5-chart/formatting-date-time/
      labelFormatter: dateLabelFormatter,
      labelFontSize: 13,
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
        labelFormatter: dateLabelFormatter,
      },
      // interval: 1,
      intervalType: "day",
    },
    axisY: {
      gridThickness: 0.5,
      labelFontFamily: dataContext.CHART_FONTS.fontFamily,
      labelFontSize: dataContext.CHART_FONTS.fontSize,
      labelFontWeight: dataContext.CHART_FONTS.fontWeight,
      title: "",
      // scaleBreaks: {
      //   autoCalculate: true
      // }
    },
    data: [
      { type: "stackedArea", dataPoints: [] }
    ]
  });

  /**
   * Get grouped counts for each unique data point and produce a consolidated
   * object containing the rolled up counts, grouped by the desired field.
   * 
   * @param {object} dataTarget Base dataset or filtered dataset to extract desired group counts from.
   * @returns Object containing desired group counts.
   */
  const getGroupedData = (dataTarget) => {
    // Extract lookup table and data from data target
    const { uimc_events: { data, lookup } } = dataTarget;
    let groupedData = {};

    // Get initial grouping of all possible daylight values
    let daylightValues = dataContext.groupBy(data, lookup.daylight);

    // Create case insensitive variation
    let daylightUpperValues = daylightValues.map(item => item && typeof (item) === "string" ? item.trim().toUpperCase() : item);

    // console.log("Possible daylight values:", daylightValues);
    // console.log("|-- Case insensitive variation:", daylightUpperValues);

    // Group by day and daylight state (e.g., Day, Night, Twilight)
    for (let record of data) {
      let d = new Date(record[lookup.start_timestamp]);

      let daylight = record[lookup.daylight];
      let daylightUpper = daylight;
      if (daylight && typeof (daylight) === "string") {
        daylightUpper = daylight.trim().toUpperCase();
      }

      let timestampStr = `${d.getFullYear()}${d.getMonth()}${d.getDate()}`;
      let key = `${timestampStr}${daylightUpper}`;

      if (!(key in groupedData)) {
        // NOTE: Value will always be the first date occurrence
        groupedData[key] = { date: d, daylight: daylight, count: 0 };

        // Check other variations of daylight states and initialize where needed
        // This will ensure each list of counts will be equal in length, setting counts to 0 where needed on dates that didn't have a respective daylight state
        for (let i = 0; i < daylightValues.length; i++) {
          let daylightValue = daylightValues[i];
          let daylightUpperValue = daylightUpperValues[i];

          // Skip same daylight value
          if (daylightUpperValue !== daylightUpper) {
            let newKey = `${timestampStr}${daylightUpperValue}`;
            if (!(newKey in groupedData)) {
              groupedData[newKey] = { date: d, daylight: daylightValue, count: 0 };
            }
          }
        }
      }

      groupedData[key].count++;
    }

    return groupedData;
  }

  // Component mount/unmount
  useEffect(() => {
    isMounted.current = true;

    // NOTE: identify document element by id on component mount to ensure canvas js chart wraps to parent container
    if (resizeDetectorRef.current) {
      let chartContainer = resizeDetectorRef.current.children[0]; // e.g., canvasjs-react-chart-container-2
      if (chartContainer) {
        chartContainer.style.height = "100%";
        chartContainer.style.width = "100%";
      }
    }

    return () => {
      isMounted.current = false;

      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }

      setOptions({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Detect changes to incomming/ingested data
  useEffect(() => {
    if (isMounted.current) {
      // Obtain reference to active data from context
      let dataTarget = dataContext.filteredData ? dataContext.filteredData : dataContext.baseData;
      let totalDataPoints = 0;

      if (dataTarget) {
        // Get aggregated count on timestamp field
        let counts = getGroupedData(dataTarget);
        let keys = Object.keys(counts);

        const { uimcDaylightTypeColorMap } = dataContext.COLOR_MAPS.current;
        const dataSeriesTemplate = {
          type: "stackedArea",
          markerType: "square",
          color: undefined,
          lineThickness: LINE_THICKNESS,
          markerSize: DATA_POINT_SIZE,
          legendText: undefined,
          showInLegend: true,
          legendMarkerColor: undefined,
          legendMarkerType: "square",
          fillOpacity: 1.0,
          dataPoints: []
        };

        // Track unique data series
        let dataSeriesSet = {};

        // console.log("UIMC Event Counts:", counts);
        if (keys.length > 0) {
          let dateMin = undefined;
          let dateMax = undefined;
          let countMin = 0;
          let countMax = 0;

          // Change with color palette value
          let usedNewColor = false;

          // Create separate data series for each daylight group
          for (let key of keys) {
            const { count, date, daylight } = counts[key];
            let daylightUpper = daylight && typeof (daylight) === "string" ? daylight.trim().toUpperCase() : daylight;
            let color = uimcDaylightTypeColorMap[daylightUpper] || "#C9C9C9"; // Default gray

            // Initialize date min and max
            if (dateMin === undefined && dateMax === undefined) {
              dateMin = date;
              dateMax = date;
            }

            // Update date min
            if (date < dateMin) {
              dateMin = date;
            }

            // Update date max
            if (date > dateMax) {
              dateMax = date;
            }

            // Update count min
            if (count < countMin) {
              countMin = count;
            }

            // Update count max
            if (count > countMax) {
              countMax = count;
            }

            // console.log("Current Color:", color);

            // Create new data series entry if the daylight state hasn't been encountered yet
            if (!(daylightUpper in dataSeriesSet)) {
              dataSeriesSet[daylightUpper] = {
                ...dataSeriesTemplate,
                color: color,
                legendText: daylight,
                legendMarkerColor: color,
                dataPoints: [],
              };

              // console.log(`Initialized entry for ${daylightUpper}`);
              usedNewColor = true;
            }

            // Use existing color for current daylight if not using a new color
            if (!usedNewColor) {
              color = dataSeriesSet[daylightUpper].color;
            }

            // Filter out null x or y values to prevent crash
            if (date !== null && count !== null) {
              let fieldsOfInterest = {
                "Event Date": dateLabelFormatter({ value: date }),
                "Daylight": daylight,
                "Count": `${count.toLocaleString('en-US')}`,
              };

              let tooltipText = getFeaturedTooltip(fieldsOfInterest, color);

              // Add point to data points for current data series
              dataSeriesSet[daylightUpper].dataPoints.push({
                label: key,
                // value: value,
                x: date,
                y: count,
                color: color,
                tooltipText: tooltipText,
              });
            }

            // Initialize new color flag
            usedNewColor = false;
          }

          // Create final dataset
          let dataSeriesList = Object.keys(dataSeriesSet).map(item => dataSeriesSet[item]);
          totalDataPoints = dataSeriesList.reduce((prev, next) => prev + next.dataPoints.length, 0);

          // Add buffer in days to maximum so the label doesn't get cut off
          // if (dateMax) {
          //   dateMax = addDays(dateMax, 1);
          // }

          // console.log("Data series set:", dataSeriesSet);
          // console.log("Data series list:", dataSeriesList);
          // console.log("Min Date:", dateMin, "Max Date:", dateMax);
          // console.log("Min Count:", countMin, "Max Count:", countMax);

          // Get the minimum and maximum possible y values to set appropriate scale for y-axis (and doesn't change when zooming)
          let padding = (dataContext.getMagnitude(countMax) / 10) * 5;

          // Adjust padding if it is 0, which indicates all data points have the same y-axis value (e.g., min and max are equal)
          padding = padding === 0 ? 0.1 : padding;

          // Create formatted data structure for canvasjs chart
          // See data series attributes at https://canvasjs.com/docs/charts/chart-options/data/
          // See performance improvement considerations at https://canvasjs.com/forums/topic/performance-improvement-in-scatter-chart/
          setOptions({
            ...options,
            axisX: {
              ...options.axisX,
              interval: getXAxisIntervalByPointCount(totalDataPoints),
              minimum: totalDataPoints > 1 ? dateMin : undefined,
              maximum: totalDataPoints > 1 ? dateMax : undefined,
            },
            axisY: {
              ...options.axisY,
              minimum: totalDataPoints > 1 && countMin >= padding ? countMin - padding : countMin,
              maximum: totalDataPoints > 1 ? countMax + padding : countMax,
            },
            data: dataSeriesList
          });
        }
      }

      // Data points of interest exist when at least one data point exists in the list
      setDataOfInterestExists(totalDataPoints > 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.baseData, dataContext.filteredData]);

  useEffect(() => {
    if (isMounted) {
      // Re-render chart if there's a change in theme
      if (chartRef.current) {
        // NOTE: must do it this way since options are copied to chart reference (e.g., not bound by state/props)
        chartRef.current.options.theme = dataContext.darkMode ? "dark1" : "light1";
        chartRef.current.render();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.darkMode]);

  // Dynamically update width and height of chart to fill parent container on dimension change
  useEffect(() => {
    if (isMounted) {
      if (chartRef.current) {
        let yOffset = 20;
        let dh = height - yOffset;
        if (width > 0 && dh > 0) {
          chartRef.current.options.width = width;
          chartRef.current.options.height = dh;
          chartRef.current.render();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height]);

  return (
    <div ref={resizeDetectorRef} style={{ width: "100%", height: "100%" }}>
      {(dataExists && dataOfInterestExists) && (
        <CanvasJSChart options={options} onRef={ref => chartRef.current = ref} />
      )}
      {(!dataExists) && (
        <div>
          <b>No Data</b>
        </div>
      )}
      {(dataExists && !dataOfInterestExists) && (
        <div>
          {/* Customize message as needed */}
          <b>No Data</b>
        </div>
      )}
    </div>
  );
}

UimcEventsByDateScatterChart.propTypes = propTypes;
UimcEventsByDateScatterChart.defaultProps = defaultProps;

export default UimcEventsByDateScatterChart;
