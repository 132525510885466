import React, { useState, useEffect, useRef, useContext } from "react";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { DataContext } from "../DataContext";
import { useResizeDetector } from 'react-resize-detector';
import PropTypes from 'prop-types';
import BasemapSwitchControl from "../controls/BasemapSwitchControl";
import getDocument from "../../services/getDocument";

// Import Styles
import 'mapbox-gl/dist/mapbox-gl.css';

import './Map.css';
import 'font-awesome/css/font-awesome.min.css';
import UserContext from "../UserContext";
// import '../styles/css/fontello.css';

mapboxgl.accessToken = 'pk.eyJ1IjoiemhqbWFwYm94IiwiYSI6ImNpdDY2NjZtczAzYXgyc2xqbGp0MTI0cmUifQ.ZwiVYpuYfxANYXqBBYTzgQ';

const propTypes = {
  mapId: PropTypes.string.isRequired,
  startLoc: PropTypes.arrayOf(PropTypes.number),
  zoom: PropTypes.number
};

const defaultProps = {
  startLoc: [-98.5795, 39.8283],
  zoom: 3
};

// const BASEMAPS = [
//   { id: "satellite-v9", name: "Satellite" },
//   { id: "light-v10", name: "Light" },
//   { id: "dark-v10", name: "Dark" },
//   { id: "streets-v11", name: "Streets" },
//   { id: "outdoors-v11", name: "Outdoors" },
// ];

const mapResourceName = 'state-shapefile';
const mapLayerId = 'states_shapefile-layer';
const mapJsonName = 'UAS_Sighting_Counts.geojson';
const mapResourceNameRunways = 'Runways.geojson';
// const mapLayerNameRunways = 'Runways';
const mapLayerIdRunways = 'Runways-layer';
// const minZoom = 3;
// const maxZoom = 16;

const themeColors = { "0-20": "#c5c4f1", "21-40": "#aaade2", "41-60": "#8d97d4", "61-80": "#6e81c5", "80+": "#155aa9" };

const getSightingsCountThemeColor = (count) => {
  let color = themeColors["0-20"];
  if (count >= 21 && count <= 40) {
    color = themeColors["21-40"];
  }
  else if (count >= 41 && count <= 60) {
    color = themeColors["41-60"];
  }
  else if (count >= 61 && count <= 80) {
    color = themeColors["61-80"];
  }
  else if (count >= 81) {
    color = themeColors["80+"];
  }

  return color;
}

const buildFeaturedTooltip = (row, { color = null }) => {
  // Build tool tip off all properties
  var tooltipText = "";

  // Start table
  tooltipText += "<table>";
  for (const [key, value] of Object.entries(row)) {
    let iconData = `<td><i class="fa fa-square tooltip-icon-fonts" style="color: ${color}; margin-right: 5px;"></i></td>`;
    tooltipText += `
      <tr>
        ${color !== null ? iconData : ""}
        <td style="color: #989898"><b>${key}&nbsp</b></td>
        <td><b>${value}</b></td>
      </tr>
    `;
  }

  // End table
  tooltipText += "</table>";
  return tooltipText;
}

//Build the legend based on the threat levels
const buildLegend = () => {
  let header = "Summary"
  let categories = [];

  for (const [key, value] of Object.entries(themeColors)) {
    // console.log(key, value);
    let theme = {};
    theme["label"] = key;
    theme["color"] = value;
    categories.push(theme);
  }

  //Create the legend container and return
  let legend = <div className="map-overlay " id="legend" style={{ height: 180 }}>
    {/* Title */}
    <strong style={{ color: "black" }}>{header}</strong><br />

    {/* Items/Icons */}
    {categories.map((cate, i) => {
      let category = cate.label;
      const iconColor = { color: cate.color };

      return (
        <div key={`legend-icon-${i}`}>
          <i
            className="fa fa-circle vector-legend-icon-fonts"
            style={iconColor}
          >
            <span className="legend-icon-inner-text">{category}</span>
          </i>
        </div>
      );
    })}
  </div>

  return legend;
}

const UasSightingsHeatMap = ({
  mapId,
  startLoc,
  zoom
}) => {
  // Obtain reference to global data context consumer
  const dataContext = useContext(DataContext);
  const contextUser = useContext(UserContext);
  // const defaultBasemap = dataContext.darkMode ? "dark-v10" : "light-v10";
  // const basemap = BASEMAPS[BASEMAPS.findIndex(item => item.id === defaultBasemap)];

  const defaultBasemap = dataContext.baseMaps[dataContext.darkMode ? "Dark Basemap" : "Light Basemap"];
  const defaultBasemapStyle = dataContext.defaultBasemapStyleJson;
  defaultBasemapStyle['sources']['raster-tiles']['tiles'][0] = defaultBasemap.url;
  defaultBasemapStyle['sources']['raster-tiles']['attribution'] = defaultBasemap.attribution;

  const {
    width: resizeDetectorWidth,
    height: resizeDetectorHeight,
    ref: resizeDetectorRef
  } = useResizeDetector();

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(startLoc[0]);
  const [lat, setLat] = useState(startLoc[1]);
  const [zoomState, setZoomState] = useState(zoom);
  const [legend, setLegend] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [stylesLoaded, setStylesLoaded] = useState(false);
  const [runwayJsonUrl, setRunwayJsonUrl] = useState(null)
  const [uassightJsonUrl, setUassightJsonUrl] = useState(null)

  const isMounted = useRef(false);

  const mapStyles = {
    width: "100%",
    height: "100%",
  };

  const mapParentStyle = {
    position: "relative",
    width: "100%",
    height: "100%"
  };

  // Detect when this component is unmounted
  useEffect(() => {
    fetchData();
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    }
  }, []);

  const fetchData = async () => {
    try {
      await getDocument(contextUser.user, "RUNWAYS").then((result) => {
        setRunwayJsonUrl(result.preSignedUrl)
      })
      await getDocument(contextUser.user, "UASSIGHTINGS").then((result) => {
        setUassightJsonUrl(result.preSignedUrl)
      })
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  useEffect(() => {
    if (isMounted.current) {
      // if (map.current) return; // Initialize map only once

      //Define the function to add source and add layer. This function will be called in map.on("load") and map.on("styledata") event

      const addDataLayer = () => {
        // console.log("Adding data layer for UAS risk vector tiles map...");
        let dataTarget = dataContext.filteredData ? dataContext.filteredData : dataContext.baseData;
        if (dataTarget) {
          // Extract data and lookup table from gridsumm data in data target
          const { uas_sightings: { data, lookup } } = dataTarget;

          let targetGrids = dataContext.groupBy(data, lookup.state);

          // console.log("Target grids to filter by in map:", targetGrids);

          //Check the map resource status
          //Only add the resource when the defined resource doesn't exist
          let sourceStatusRunways = map.current.getSource(mapResourceNameRunways);
          if (sourceStatusRunways === undefined) {
            map.current.addSource(mapResourceNameRunways, {
              'type': 'geojson',
              'data': runwayJsonUrl
            });
          }

          let sourceStatus = map.current.getSource(mapResourceName);
          if (sourceStatus === undefined) {
            map.current.addSource(mapResourceName, {
              'type': 'geojson',
              'data': uassightJsonUrl
            });
          }

          // Remove layers before adding new layers
          if (map.current.getLayer(mapLayerId)) map.current.removeLayer(mapLayerId);
          if (map.current.getLayer(mapLayerIdRunways)) map.current.removeLayer(mapLayerIdRunways);

          map.current.addLayer({
            'id': mapLayerIdRunways, // Layer ID
            'type': 'fill',
            'source': mapResourceNameRunways, // ID of the tile source created above
            'minzoom': 11,
            "layout": { 'visibility': 'visible' },
            "paint": {
              "fill-color": '#2493dd',
              "fill-opacity": 1.0,
              "fill-outline-color": '#eee'
            }
          });

          map.current.addLayer({
            'id': mapLayerId,
            'type': 'fill',
            'source': mapResourceName,
            'paint': {
              'fill-color': [
                'interpolate',
                ['linear'],
                ['get', 'uas_sightings_count'],
                0,
                '#c5c4f1',
                20,
                '#aaade2',
                40,
                '#8d97d4',
                60,
                '#6e81c5',
                80,
                '#4b6db7',
                100,
                '#155aa9'
              ],
              'fill-opacity': 0.75,
              "fill-outline-color": '#fff'
            },
            filter: ["in", "State_Name", ...targetGrids]
          });

        }
      }

      if (!map.current) {
        // console.log("Initializing UAS Risk map...");
        map.current = new mapboxgl.Map({
          container: mapContainer.current,
          style: defaultBasemapStyle,
          center: [lng, lat],
          zoom: zoomState,
          transformRequest: (url, resourceType) => {
            if (resourceType === 'Source' && url.startsWith('https://maptiles.asias.info')) {
            // console.log(url, contextUser.user.signInUserSession.idToken.jwtToken)
            return{

            url: url, 
            headers: {Authorization: contextUser.user.signInUserSession.idToken.jwtToken}
            }
          }
          }
        });


        //Add controls
        map.current.addControl(new mapboxgl.NavigationControl(), 'top-left');
        map.current.addControl(new mapboxgl.FullscreenControl(), 'top-left');
        map.current.addControl(new BasemapSwitchControl("basemap-checkControl-heatmap"));

        // Create a popup, but don't add it to the map yet.
        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
          maxWidth: "350px",
          className: dataContext.darkMode ? "custom-map-tooltip-dark" : "custom-map-tooltip-light",
        });

        map.current.on('mousemove', mapLayerId, (e) => {
          // Change the cursor style as a UI indicator.
          map.current.getCanvas().style.cursor = 'pointer';

          // Extract features
          const { properties } = e.features[0];

          let fieldsOfInterest = {
            "State": dataContext.capitalizeWords(properties["State_Name"]),
            "Sightings Count": properties["uas_sightings_count"],
          };

          let color = getSightingsCountThemeColor(properties["uas_sightings_count"]);
          let tooltipHTML = buildFeaturedTooltip(fieldsOfInterest, { color: color });

          // Populate the popup and set its coordinates
          // based on the feature found.
          popup.setLngLat(e.lngLat).setHTML(tooltipHTML).addTo(map.current);
        });

        map.current.on('mouseleave', mapLayerId, () => {
          map.current.getCanvas().style.cursor = '';
          popup.remove();
        });

      }

      if (map.current) {

        // Map event when the basemap style loads or changes.
        map.current.on('styledata', () => {

          var mapLayer = map.current.getLayer(mapLayerId);
          if (typeof mapLayer === 'undefined') {
            // Run asynchronously to prevent race codition error for cleaning resources while in use
            setTimeout(() => {
              // console.log("Invoking addDataLayer because map layer is undefined but map has already been loaded...");
              addDataLayer();
            });
          }

          setStylesLoaded(true);
        });

        //Map event when the basemap was loaded
        map.current.on('load', () => {
          addDataLayer();

          // setMap(map);
          // map.resize();

          // Create legend
          const legend = buildLegend();
          setLegend(legend);

          map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoomState(map.current.getZoom().toFixed(2));
          });

          setMapLoaded(true);
        });

        // Create a popup, but don't add it to the map yet.
        // const popup = new mapboxgl.Popup({
        //   closeButton: false,
        //   closeOnClick: false
        // });

        // map.current.on('mouseenter', mapLayerId, (e) => {

        //   // Change the cursor style as a UI indicator.
        //   map.current.getCanvas().style.cursor = 'pointer';

        //   // Copy coordinates array.
        //   const coordinates = e.features[0].geometry.coordinates.slice();

        //   let infoHTML = `<div class="info-style">\
        //     <div><strong>State:</strong>&nbsp;${e.features[0].properties["State_Name"]}</div>\
        //     <div><strong>Sightings count:</strong>&nbsp;${e.features[0].properties["uas_sightings_count"]}</div>\
        //   </div>`;

        //   // Ensure that if the map is zoomed out such that multiple
        //   // copies of the feature are visible, the popup appears
        //   // over the copy being pointed to.
        //   while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        //     coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        //   }

        //   // Populate the popup and set its coordinates
        //   // based on the feature found.
        //   popup.setLngLat(e.lngLat).setHTML(infoHTML).addTo(map.current);
        // });

        // map.current.on('mouseleave', mapLayerId, () => {
        //   map.current.getCanvas().style.cursor = '';
        //   popup.remove();
        // });

        // Condition is only satisfied when data is loaded and styles are applied
        // This means that layers should only be removed/added here if a filter is applied or removed after the initial map creation
        if (mapLoaded && stylesLoaded) {
          addDataLayer();
        }
      }
    }

    // Unmount post-processing hook
    return () => {
      // TODO Cleanup objects as needed

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.baseData, dataContext.filteredData]);

  useEffect(() => {
    // Resize map to fix viewport scaling when parent container is resized
    if (map.current) {
      map.current.resize();
    }

  }, [resizeDetectorWidth, resizeDetectorHeight]);

  return (
    <div ref={resizeDetectorRef} style={mapParentStyle}>
      {dataContext.DEBUG_MODE && (
        <div className="mapbox-custom-sidebar">
          <div>
            Longitude: {lng} | Latitude: {lat} | Zoom: {zoomState}
          </div>
        </div>
      )}

      <div id={mapId} ref={mapContainer} style={mapStyles}>
        {legend}
      </div>
    </div>

  )
}

UasSightingsHeatMap.propTypes = propTypes;
UasSightingsHeatMap.defaultProps = defaultProps;

export default UasSightingsHeatMap;
