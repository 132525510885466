import React, { useState, useContext } from "react";
import { DataContext } from "../DataContext";
// import PropTypes from 'prop-types';
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { withSize } from "react-sizeme";
import DashboardWidget from "../DashboardWidget";
import LoadingSkeleton from "../utils/LoadingSkeleton";
import { IconButton, Tooltip } from "@mui/material";
import AddList from "../AddList";
import SaveIcon from '@mui/icons-material/Save';
import DashboardIcon from '@mui/icons-material/Dashboard';
import UasSightingsVectorTilesMap from "../maps/UasSightingsVectorTilesMap";
import UasSightingsHeatMap from "../maps/UasSightingsHeatMap";
// import TopIcaoDesignatorSightingsBarChart from "../charts/suassightings/TopIcaoDesignatorSightingsBarChart";
// import TopAirportSightingsBarChart from "../charts/suassightings/TopAirportSightingsBarChart";
import SUasSightingsScatterChart from "../charts/suassightings/SUasSightingsScatterChart";
import DataBrowser, { buildDataBrowserColumn } from "../misc/DataBrowser";
import SuasSightingsControls from "../controls/SuasSightingsControls";

// Need to import styles for core functionality
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const propTypes = {};
const defaultProps = {};

const styles = {
  toolbarContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  toolbarBanner: {
    marginRight: "auto",
  },
};

function SUasSightingsContent({ size: { width } }) {
  const SAVED_LAYOUTS_KEY = "SUasSightingsLayout";

  const dataContext = useContext(DataContext);
  const uasAggregateMetricsDashboardConfig = dataContext.uasAggregateMetricsDashboardConfig[dataContext.SUAS_SIGHTINGS_TAB_KEY];

  const originalItems = Object.keys(uasAggregateMetricsDashboardConfig);

  const initialLayouts = {
    lg: originalItems.map(item => uasAggregateMetricsDashboardConfig[item])
  };

  const loadedLayouts = dataContext.getFromLS(SAVED_LAYOUTS_KEY) || initialLayouts;
  const [layouts, setLayouts] = useState(
    loadedLayouts
  );

  // Initial set of widgets will be based on saved layouts data (defaults to initial layouts if no saved data found)
  const [items, setItems] = useState(loadedLayouts.lg.map(item => item.i));

  const onLayoutChange = (_, allLayouts) => {
    setLayouts(allLayouts);
  };

  const onLayoutSave = (e) => {
    e.stopPropagation();
    dataContext.saveToLS(SAVED_LAYOUTS_KEY, layouts);

    // Show snackbar notification on save
    dataContext.showSnack("top", "center", "Dashboard Layout Saved for sUAS Sightings!", dataContext.SNACK_SEVERITY.SUCCESS, 2000);
  };

  const onLayoutReset = (e) => {
    e.stopPropagation();
    const newLoadedLayouts = dataContext.getFromLS(SAVED_LAYOUTS_KEY) || initialLayouts;
    setLayouts(newLoadedLayouts);
    setItems(newLoadedLayouts.lg.map(item => item.i));
    dataContext.showSnack("top", "center", "Dashboard Layout Reset to Last Saved State for sUAS Sightings!", dataContext.SNACK_SEVERITY.SUCCESS, 2000);
  }

  const onRemoveItem = (itemId) => {
    // console.log(`Removing ${itemId} from the dashboard...`);
    setItems(items.filter((i) => i !== itemId));
  };

  const onAddItem = (itemId) => {
    setItems([...items, itemId]);
  };

  /**
   * Handle DataBrowser row selection event for drilling down on data record.
   * 
   * @param {string} key Key associated with target dataset for drilldown.
   * @param {any} value Value associated with key mapping in dataset for drilldown.
   */
  const handleRowSelected = (key, value) => {
    // Build data structure that imitates control filter
    // EXAMPLE:
    // {
    //   "phaseOfFlightSelect": {
    //     "id": "phaseOfFlightSelect",
    //     "label": "Standing, Hover Ground Effect",
    //     "column": "phaseofflight_mavg10",
    //     "values": [
    //       "standing",
    //       "hover ground effect"
    //      ]
    //    }
    // }    
    // console.log("Handling drilldown for selected key-value:", [key, value]);

    let filter = {
      [dataContext.SUAS_SIGHTINGS_CONTENT_SELECT_KEY]: {
        id: dataContext.SUAS_SIGHTINGS_CONTENT_SELECT_KEY,
        label: value,
        column: key,
        values: [value],
        track: true,
      }
    };

    dataContext.addControlFilter(filter);
  }

  const dateHandler = (data) => {
    let retVal = data;
    if (data && data !== "") {
      retVal = dataContext.toHumanReadableDateStr(data, false, true);
    }

    return retVal;
  }

  const timeHandler = (data) => {
    let retVal = data;
    if (data && data !== "") {
      retVal = dataContext.toHumanReadableDateStr(data, true, false);
    }

    return retVal;
  }

  const roundingHandlerLong = (data) => {
    return dataContext.roundStr(data, 6);
  }

  const replaceModelUnsure = (data) => {
    var rString ;
    data === 'model output maybe wrong' ? rString = 'Uncertain/Unknown' : rString = data ;
    return rString;
  }

  // Core widgets list
  const componentList = {
    uasSightingsVectorTilesMap:
      dataContext.processing ? <LoadingSkeleton /> :
        <UasSightingsVectorTilesMap
          mapId="uasSightingsVectorTilesMap"
          startLoc={[-96, 37.3]}
          zoom={3}
        />,
    uasSightingsHeatMap:
      dataContext.processing ? <LoadingSkeleton /> :
        <UasSightingsHeatMap
          mapId="uasSightingsHeatMap"
          startLoc={[-96, 37.3]}
          zoom={3}
        />,
    // sUasSightingsByStateDataBrowser:
    //   dataContext.processing ? <LoadingSkeleton /> :
    //     <DataBrowser
    //       primaryTargetKey="uas_sightings"
    //       primaryCol="state_id"
    //       defaultRowsPerPage={25}
    //       groupByCount
    //       groupByAlias="Count"
    //       groupByAsMetric
    //       groupByDefaultOrder="desc"
    //       enableSearch
    //       onRowSelected={handleRowSelected}
    //       // decorateNulls
    //       removeGroupByNulls
    //       columnsOfInterest={[
    //         buildDataBrowserColumn({
    //           col: "state_id",
    //           alias: "State",
    //           width: 30,
    //           // modifier: (data) => data === null || data === "" ? "NULL" : data, // Replace null/empty strings with 'NULL'
    //         }),
    //       ]}
    //     />,
    sUasSightingsByCityDataBrowser:
      dataContext.processing ? <LoadingSkeleton /> :
        <DataBrowser
          primaryTargetKey="uas_sightings"
          primaryCol="city"
          defaultRowsPerPage={25}
          groupByCount
          groupByAlias="Count"
          groupByAsMetric
          groupByDefaultOrder="desc"
          enableSearch
          onRowSelected={handleRowSelected}
          // decorateNulls
          removeGroupByNulls
          columnsOfInterest={[
            buildDataBrowserColumn({
              col: "city",
              alias: "City",
              width: 40,
              modifier: (data) => dataContext.capitalizeWords(data),
            }),
          ]}
        />,
    // topIcaoDesignatorSightingsBarChart:
    //   dataContext.processing ? <LoadingSkeleton /> :
    //     <TopIcaoDesignatorSightingsBarChart />,
    // topAirportSightingsBarChart:
    //   dataContext.processing ? <LoadingSkeleton /> :
    //     <TopAirportSightingsBarChart />,
    topIcaoDesignatorSightingsBarChart:
      dataContext.processing ? <LoadingSkeleton /> :
        <DataBrowser
          primaryTargetKey="uas_sightings"
          primaryCol="icao_designator"
          defaultRowsPerPage={25}
          groupByCount
          groupByAlias="Count"
          groupByAsMetric
          groupByDefaultOrder="desc"
          enableSearch
          onRowSelected={handleRowSelected}
          // decorateNulls
          removeGroupByNulls
          columnsOfInterest={[
            buildDataBrowserColumn({
              col: "icao_designator",
              alias: "ICAO Designator",
              width: 30,
              // modifier: (data) => data === null || data === "" ? "NULL" : data, // Replace null/empty strings with 'NULL'
            }),
          ]}
        />,
    topAirportSightingsBarChart:
      dataContext.processing ? <LoadingSkeleton /> :
        <DataBrowser
          primaryTargetKey="uas_sightings"
          primaryCol="arpt_code_1"
          defaultRowsPerPage={25}
          groupByCount
          groupByAlias="Count"
          groupByAsMetric
          groupByDefaultOrder="desc"
          enableSearch
          onRowSelected={handleRowSelected}
          // decorateNulls
          removeGroupByNulls
          columnsOfInterest={[
            buildDataBrowserColumn({
              col: "arpt_code_1",
              alias: "Airport Code",
              width: 30,
              // modifier: (data) => data === null || data === "" ? "NULL" : data, // Replace null/empty strings with 'NULL'
            }),
          ]}
        />,
    sUasSightingsScatterChart:
      dataContext.processing ? <LoadingSkeleton /> :
        <SUasSightingsScatterChart />,
    countOfSummaryByDist2LocDataBrowser:
      dataContext.processing ? <LoadingSkeleton /> :
        <DataBrowser
          primaryTargetKey="uas_sightings"
          defaultRowsPerPage={50}
          // enableSearch
          primaryCol="dist2loc"
          groupByCount
          groupByAlias="Summary"
          groupByAsMetric
          groupByDefaultOrder="desc"
          onRowSelected={handleRowSelected}
          // decorateNulls
          removeGroupByNulls
          columnsOfInterest={[
            buildDataBrowserColumn({
              col: "dist2loc",
              alias: "Dist (NM)",
              width: 30,
              // defaultOrder: "asc",
              // modifier: (data) => data === null || data === "" ? "NULL" : data, // Replace null/empty strings with 'NULL'
              customSortingComparator: (val1, val2, order) => {
                let a = val1;
                let b = val2;
                let result = 0;

                // Parse to int and calculate diff for comparison since dist2loc is string
                if (val1 !== null && val2 !== null) {
                  a = parseInt(val1);
                  b = parseInt(val2);
                }

                // Keep null/empty string values at the top when sorting in ascending order
                if (val1 === null || val1 === "") {
                  a = 0;
                }

                if (val2 === null || val2 === "") {
                  b = 10e6;
                }

                result = a - b;
                return order === "asc" ? result : -result;
              }
            }),
          ]}
        />,
        sUasSightingsByHelicopterType:
        dataContext.processing ? <LoadingSkeleton /> :
          <DataBrowser
            primaryTargetKey="uas_sightings"
            defaultRowsPerPage={50}
            // enableSearch
            primaryCol="acft_sub_category"
            groupByCount
            groupByAlias="Summary"
            groupByAsMetric
            groupByDefaultOrder="desc"
            onRowSelected={handleRowSelected}
            // decorateNulls
            removeGroupByNulls
            columnsOfInterest={[
              buildDataBrowserColumn({
                col: "acft_sub_category",
                alias: "Aircraft Type",
                width: 30,
                // defaultOrder: "asc",
                modifier: replaceModelUnsure,
                customSortingComparator: (val1, val2, order) => {
                  let a = val1;
                  let b = val2;
                  let result = 0;
  
                  // Parse to int and calculate diff for comparison since dist2loc is string
                  if (val1 !== null && val2 !== null) {
                    a = parseInt(val1);
                    b = parseInt(val2);
                  }
  
                  // Keep null/empty string values at the top when sorting in ascending order
                  if (val1 === null || val1 === "") {
                    a = 0;
                  }
  
                  if (val2 === null || val2 === "") {
                    b = 10e6;
                  }
  
                  result = a - b;
                  return order === "asc" ? result : -result;
                }
              }),
            ]}
          />,
          sUasSightingsByNavaid:
          dataContext.processing ? <LoadingSkeleton /> :
            <DataBrowser
              primaryTargetKey="uas_sightings"
              defaultRowsPerPage={50}
              // enableSearch
              primaryCol="navaid_name"
              groupByCount
              groupByAlias="Summary"
              groupByAsMetric
              groupByDefaultOrder="desc"
              onRowSelected={handleRowSelected}
              // decorateNulls
              removeGroupByNulls
              columnsOfInterest={[
                buildDataBrowserColumn({
                  col: "navaid_name",
                  alias: "NAVAID Name",
                  width: 30,
                  // defaultOrder: "asc",
                  // modifier: (data) => data === null || data === "" ? "NULL" : data, // Replace null/empty strings with 'NULL'
                  customSortingComparator: (val1, val2, order) => {
                    let a = val1;
                    let b = val2;
                    let result = 0;
    
                    // Parse to int and calculate diff for comparison since dist2loc is string
                    if (val1 !== null && val2 !== null) {
                      a = parseInt(val1);
                      b = parseInt(val2);
                    }
    
                    // Keep null/empty string values at the top when sorting in ascending order
                    if (val1 === null || val1 === "") {
                      a = 0;
                    }
    
                    if (val2 === null || val2 === "") {
                      b = 10e6;
                    }
    
                    result = a - b;
                    return order === "asc" ? result : -result;
                  }
                }),
              ]}
            />,
    sUasSightingsDataBrowser:
      dataContext.processing ? <LoadingSkeleton /> :
        <DataBrowser
          primaryTargetKey="uas_sightings"
          primaryCol="id"
          defaultRowsPerPage={50}
          onRowSelected={handleRowSelected}
          decorateNulls
          columnsOfInterest={[
            buildDataBrowserColumn({ col: "date_s", alias: "Sighting Date", modifier: dateHandler }),
            buildDataBrowserColumn({ col: "incident_time_zulu", alias: "Sighting Time", modifier: timeHandler }),
            buildDataBrowserColumn({ col: "state", alias: "State" }),
            buildDataBrowserColumn({ col: "city", alias: "City" }),
            buildDataBrowserColumn({ col: "make", alias: "Make" }),
            buildDataBrowserColumn({ col: "icao_designator", alias: "ICAO Designator" }),
            buildDataBrowserColumn({ col: "incident_time", alias: "Incident Time" }),
            buildDataBrowserColumn({ col: "location_1", alias: "Location" }),
            // buildDataBrowserColumn({ col: "location_2", alias: "Location (2)" }), 
            buildDataBrowserColumn({ col: "arpt_code_1", alias: "Airport Code" }),
            buildDataBrowserColumn({ col: "navaid_name", alias: "NAVAID" }),
            buildDataBrowserColumn({ col: "acft_sub_category", alias: "Aircraft Type", modifier: replaceModelUnsure }),
            buildDataBrowserColumn({ col: "final_lat", alias: "Sighting Latitude", modifier: roundingHandlerLong }),
            buildDataBrowserColumn({ col: "final_lon", alias: "Sighting Longitude", modifier: roundingHandlerLong }),
            buildDataBrowserColumn({ col: "latlon_uncertainty", alias: "Lat/Lon Uncertainty" }),
            buildDataBrowserColumn({ col: "summary", alias: "Summary" }),
          ]}
        />,
  };

  return (
    <>
      {/* Dashboard tab management functions - right aligned if display is flex and content is justified as flex-end */}
      <div style={styles.toolbarContainer}>
        <div>
          <IconButton aria-label="save" onClick={onLayoutSave}>
            <Tooltip title="Save Dashboard Tab Layout" placement="top" arrow>
              <SaveIcon />
            </Tooltip>
          </IconButton>

          <IconButton aria-label="reset" onClick={onLayoutReset}>
            <Tooltip title="Reset Dashboard Tab Layout to Last Saved State" placement="top" arrow>
              <DashboardIcon />
            </Tooltip>
          </IconButton>

          <AddList
            items={items}
            onRemoveItem={onRemoveItem}
            onAddItem={onAddItem}
            originalItems={originalItems}
            dashboardKey={dataContext.SUAS_SIGHTINGS_TAB_KEY}
            dashboardConfig={dataContext.uasAggregateMetricsDashboardConfig}
          />
        </div>
      </div>

      {/* Add controls here as needed */}
      <SuasSightingsControls />

      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={60}
        width={width * (1 / dataContext.appScale)}
        draggableHandle=".drag-handle"
        onLayoutChange={onLayoutChange}
        transformScale={dataContext.appScale} // Required to adapt transform translation offset when dragging
      >
        {items.map((key) => (

          <div
            key={key}
            data-grid={{ w: 6, h: 8, x: 0, y: Infinity }}
          >
            <DashboardWidget
              id={key}
              name={uasAggregateMetricsDashboardConfig[key].name}
              className="drag-handle"
              onRemoveItem={onRemoveItem}
              fullscreenEnabled={false}
            >
              {componentList[key]}
            </DashboardWidget>
          </div>
        ))}
      </ResponsiveGridLayout>
    </>
  );
}

SUasSightingsContent.propTypes = propTypes;
SUasSightingsContent.defaultProps = defaultProps;

export default withSize({ refreshMode: "debounce", refreshRate: 60 })(SUasSightingsContent);
