import React, { useState, useContext } from "react";
import { DataContext } from "../DataContext";
// import PropTypes from 'prop-types';
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { withSize } from "react-sizeme";
import DashboardWidget from "../DashboardWidget";
import LoadingSkeleton from "../utils/LoadingSkeleton";
import FlightIdBanner from "./FlightIdBanner";
import Playback3DMap from "../maps/Playback3DMap";
// import Toolbar from "../utils/Toolbar";
import { IconButton, Tooltip } from "@mui/material";
import AddList from "../AddList";
import SaveIcon from '@mui/icons-material/Save';
import DashboardIcon from '@mui/icons-material/Dashboard';

// Need to import styles for core functionality
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const propTypes = {};
const defaultProps = {};

const disclaimer = "Disclaimer: 3D Playback utilizes WebGL hardware graphics acceleration, performance will vary based on the client's hardware, free memory, and choice of Browser. Firefox is the recommended browser for 3D viewer.  If you experience issues with playback, try refreshing the browser."

const styles = {
  toolbarContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  toolbarBanner: {
    marginRight: "auto",
  },
};

function Playback3DContent({ size: { width } }) {
  const SAVED_LAYOUTS_KEY = "Flight3DLayout";

  const dataContext = useContext(DataContext);
  const flightMetricsDashboardConfig = dataContext.flightMetricsDashboardConfig[dataContext.PLAYBACK3D_TAB_KEY];

  const originalItems = Object.keys(flightMetricsDashboardConfig);

  const initialLayouts = {
    lg: originalItems.map(item => flightMetricsDashboardConfig[item])
  };

  const loadedLayouts = dataContext.getFromLS(SAVED_LAYOUTS_KEY) || initialLayouts;
  const [layouts, setLayouts] = useState(
    loadedLayouts
  );

  // Initial set of widgets will be based on saved layouts data (defaults to initial layouts if no saved data found)
  const [items, setItems] = useState(loadedLayouts.lg.map(item => item.i));

  const onLayoutChange = (_, allLayouts) => {
    setLayouts(allLayouts);
  };

  const onLayoutSave = (e) => {
    e.stopPropagation();
    dataContext.saveToLS(SAVED_LAYOUTS_KEY, layouts);

    // Show snackbar notification on save
    dataContext.showSnack("top", "center", "Dashboard Layout Saved for Flight 3D!", dataContext.SNACK_SEVERITY.SUCCESS, 2000);
  };

  const onLayoutReset = (e) => {
    e.stopPropagation();
    const newLoadedLayouts = dataContext.getFromLS(SAVED_LAYOUTS_KEY) || initialLayouts;
    setLayouts(newLoadedLayouts);
    setItems(newLoadedLayouts.lg.map(item => item.i));
    dataContext.showSnack("top", "center", "Dashboard Layout Reset to Last Saved State for Flight 3D!", dataContext.SNACK_SEVERITY.SUCCESS, 2000);
  }

  const onRemoveItem = (itemId) => {
    // console.log(`Removing ${itemId} from the dashboard...`);
    setItems(items.filter((i) => i !== itemId));
  };

  const onAddItem = (itemId) => {
    setItems([...items, itemId]);
  };

  // Core widgets list
  const componentList = {
    playback3DMap:
      dataContext.processing ? <LoadingSkeleton /> :
        <Playback3DMap
          mapId="playback3D-map"
          zoom={10}
        />,
  };

  return (
    <>
      {/* Dashboard tab management functions - right aligned if display is flex and content is justified as flex-end */}
      <div style={styles.toolbarContainer}>
        <FlightIdBanner style={styles.toolbarBanner} disclaimer={disclaimer} />
        <div>
          <IconButton aria-label="save" onClick={onLayoutSave}>
            <Tooltip title="Save Dashboard Tab Layout" placement="top" arrow>
              <SaveIcon />
            </Tooltip>
          </IconButton>

          <IconButton aria-label="reset" onClick={onLayoutReset}>
            <Tooltip title="Reset Dashboard Tab Layout to Last Saved State" placement="top" arrow>
              <DashboardIcon />
            </Tooltip>
          </IconButton>

          <AddList
            items={items}
            onRemoveItem={onRemoveItem}
            onAddItem={onAddItem}
            originalItems={originalItems}
            dashboardKey={dataContext.PLAYBACK3D_TAB_KEY}
            dashboardConfig={dataContext.flightMetricsDashboardConfig}
          />
        </div>
      </div>
      {/* <Toolbar
        onLayoutSave={onLayoutSave}
        AddListProps={{
          items: items,
          onRemoveItem: onRemoveItem,
          onAddItem: onAddItem,
          originalItems: originalItems,
          dashboardKey: dataContext.PLAYBACK3D_TAB_KEY
        }}
      /> */}

      {/* Add controls here as needed */}


      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={60}
        width={width * (1 / dataContext.appScale)}
        draggableHandle=".drag-handle"
        onLayoutChange={onLayoutChange}
        transformScale={dataContext.appScale} // Required to adapt transform translation offset when dragging
      > 
        {items.map((key) => (

          <div
            key={key}
            data-grid={{ w: 6, h: 8, x: 0, y: Infinity }}
          >
            <DashboardWidget
              id={key}
              name={flightMetricsDashboardConfig[key].name}
              className="drag-handle"
              onRemoveItem={onRemoveItem}
              fullscreenEnabled={false}
            >
              {componentList[key]}
            </DashboardWidget>
          </div>
        ))}
      </ResponsiveGridLayout>
    </>
  );
}

Playback3DContent.propTypes = propTypes;
Playback3DContent.defaultProps = defaultProps;

export default withSize({ refreshMode: "debounce", refreshRate: 60 })(Playback3DContent);
