import React from 'react'
import {
    FormGroup,
    Label,
    TextInput,
    Checkbox,
    Dropdown,
    Textarea,
    ButtonGroup,
    Button,
    Form
} from '@trussworks/react-uswds'
import { Link } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import './UserRegistration.css'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { Container, Row, Col } from 'react-bootstrap'
import UnauthNav from '../components/UnauthNav';
import SignInRegConfirm from './SignInRegConfirm'


class UserRegistration extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            email: '',
            first_name: '',
            last_name: '',
            password: '',
            phone_number: '',
            authCode: '',
            phone_number2: '',
            showConfirmation: false,
            roles: [
                // {GroupName: "administrator", value: "administrator"}, 
                {label: "Operator", value: "operator"},
                {label:  "Data Transfer Organization", value: "dto"}, 
                {label: "R-IAT Member", value: "r-iat"},
                {label: "R-IAT Safety Data Analyst", value: "r-iat-analyst"},
                {label: "R-IAT Safety Software Developer", value: "r-iat-dev"},
                {label: "Enclave Analyst", value: "enclave-analyst"},
            ],
            rolesSelected: null,
            isMobile: false,
            emailNotifications: false,
            commentsToApprover: ''
        }
      // console.log(this.state.roles)
        const animatedComponents = makeAnimated();
        

        
    }
      

    setRolesSelected(e) {
        this.setState({rolesSelected: e})
    }

      onChange = (key, value) => {
        this.setState({
          [key]: value
        })
      }

      onBlurTrim = (key, evt) => {
          const trimValue = evt.target.value.trim()
        this.setState({
          [key]: trimValue.trim()
        })
        evt.target.value = trimValue
      }
    
      updateErrorMessage = (msg) => {
        alert(msg)
      }
    
      handleCancel = () => {
          this.props.history.push("/")
      }
    
      signUp = (e) => {

        e.preventDefault()
        
        const { 
          username, 
          password, 
          password2,
          email, 
          phone_number,
          phone_number2,
          first_name,
          last_name,
          organization,
          rolesSelected,
          isMobile,
          emailNotifications,
          commentsToApprover
         } = this.state
        
        //  let stringRoles = rolesSelected.reduce((result, item) => {
        //      return `${result}${item.value},`
        //  }, "")
        
        let stringRoles = rolesSelected ? rolesSelected.map(({value}) => `${value}`).join(',') : '';

        let name = first_name + ' ' + last_name
      // console.log(commentsToApprover)
      if (password === password2) { 
        Auth.signUp({
            username,
            password,
            attributes: {
                email,
                phone_number,
                name,
                'custom:organization': organization,
                'custom:secondaryPhone': phone_number2,
                'custom:additionalInfo': stringRoles,
                'custom:phoneIsMobile': isMobile.toString(),
                'custom:notificationPref': emailNotifications.toString(),
                'custom:approverComments': commentsToApprover
                
            }
        })
        .then(() => this.setState({ showConfirmation: true }))
        .catch(err => {
        // console.log('error signing up: ', err)
          this.updateErrorMessage(err.message)
        })
        } else {
            alert("Passwords do not match")
        }
      }
      confirmSignUp = () => {
        const { history } = this.props
        Auth.confirmSignUp(this.state.username, this.state.authCode)
        .then(res => {
        // console.log(res)
          alert("You have succesfully confirmed your account")
          history.push('/')
        })
        .catch(err => console.log('error confirming signing up: ', err))
      }

    render() {

        const { showConfirmation } = this.state

        return (
            <div>
                <UnauthNav />
           
        <div className="stretch_grid-container">
        
        {
        !showConfirmation && (
            <form onSubmit={this.signUp}>
                <h1 style={{paddingTop: '1em'}}>User Registration</h1>
                <span >
                    Already have an account? <Link to='/login'>Sign in.</Link>
                </span>
                
                <h3 className="registration-h3"> Account Information</h3>
                <p className="registration-p-text">Please provide your information below to identify you in the system and assist in approving your role.</p>
                <p className="registration-p">Fields marked with an asterisk (<abbr className="req" title="required">*</abbr>) are required.</p>
                
                
                    <FormGroup>
                    <h5 className="registration-h5">User Information</h5>
                        <Row className="userManagementRow">
                            <Col>
                            <Label htmlFor="first-name-input">First name <abbr className="req" title="required">*</abbr>:</Label>
                            <TextInput 
                                id="first-name-input" 
                                name="first-name-input" 
                                type="text" 
                                onChange={evt => this.onChange('first_name', evt.target.value)}
                                onBlur={evt => this.onBlurTrim('first_name', evt)}
                                required
                            />
                            
                            
                            </Col>
                            <Col>
                            <Label htmlFor="last-name-input">Last name <abbr className="req" title="required">*</abbr>:</Label>
                            <TextInput 
                                id="last-name-input" 
                                name="last-name-input" 
                                type="text" 
                                onChange={evt => this.onChange('last_name', evt.target.value)}
                                onBlur={evt => this.onBlurTrim('last_name', evt)}
                                required 
                            />
                            </Col>
                            <Col>
                            <Label htmlFor="organization-input">Organization <abbr className="req" title="required">*</abbr>:</Label>
                            <TextInput 
                                id="organization-input" 
                                name="organization-input" 
                                type="text" 
                                onChange={evt => this.onChange('organization', evt.target.value)}
                                onBlur={evt => this.onBlurTrim('organization', evt)}
                                required 
                            />
                            </Col>
                        </Row>
                        <br />
                        <h5 className="registration-h5">Contact Information</h5>
                        <Row className="userManagementRow">
                            <Col>
                            <Label htmlFor="email-input">Email <abbr className="req" title="required">*</abbr>:</Label>
                            <TextInput 
                                id="email-input" 
                                name="email-input" 
                                type="email" 
                                onChange={evt => this.onChange('username', evt.target.value)}
                                onBlur={evt => this.onBlurTrim('username', evt)}
                                required
                            />
                            <Checkbox className="checkbox" id="email-notifications" name="email-notifications" label="Receive Notifications" onChange={evt => this.onChange('emailNotifications', evt.target.checked)} />
                            RAISE will only use your email adress to confirm your account registration and send email notifications if enabled.
                            </Col>
                            <Col>
                            <Label htmlFor="phone-input">Primary Phone Number <abbr className="req" title="required">*</abbr>:</Label>
                            <TextInput 
                                id="phone-input" 
                                name="phone-input" 
                                type="text" 
                                value={this.phone_number}
                                pattern="\+[0-9]{11,15}"
                                placeholder="+11234567890"
                                onChange={evt => this.onChange('phone_number', evt.target.value)}
                                onBlur={evt => this.onBlurTrim('phone_number', evt)}
                                required 
                            />
                            <br />RAISE requires a primary phone able to receive SMS multifactor authentication codes to sign in.  US Phone numbers should be prefixed with +1, such as +11234567890.
                            </Col>
                            <Col>
                            <Label htmlFor="secondary-phone-input">Secondary Phone Number:</Label>
                            <TextInput 
                                id="secondary-phone-input"
                                name="secondary-phone-input"
                                type="tel"
                                // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                pattern="+[0-9]{11,15}"
                                placeholder="+11234567890"
                                onChange={evt => this.onChange('phone_number2', evt.target.value)}
                                onBlur={evt => this.onBlurTrim('phone_number2', evt)}
                            />
                            <Checkbox className="checkbox" id="secondary-phone-input-checkbox" name="secondary-phone-input-checkbox" label="Mobile" onChange={evt => this.onChange('isMobile', evt.target.checked)} />
                           
                            </Col>
                        </Row>
                        <br />
                        <h5 className="registration-h5">Password</h5>
                            Your RAISE Password must be 8 alpha numeric characters and include:<br />
                            1 lowercase letter<br />
                            1 uppercase letter<br />
                            1 special symbol from these: @$!%*?&<br />
                            1 number<br />
                            Spaces, tabs and new lines are not valid password characters<br />
                        <Row className="userManagementRow">
                        
                            <Col>
                            <Label htmlFor="password-input">Password <abbr className="req" title="required">*</abbr>:</Label>
                            <TextInput 
                                id="password-input" 
                                name="password-input" 
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
                                type="password" 
                                autoComplete="new-password"
                                onChange={evt => this.onChange('password', evt.target.value)}
                                required 
                            />
                            </Col>
                            <Col>
                            <Label htmlFor="confirm-password-input">Confirm Password <abbr className="req" title="required">*</abbr>:</Label>
                            <TextInput 
                                id="confirm-password-input" 
                                name="confirm-password-input" 
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
                                type="password" 
                                autoComplete="new-password"
                                onChange={evt => this.onChange('password2', evt.target.value)}
                                required 
                            />  
                            </Col>
                            <Col>

                            </Col>
                        </Row>
                    </FormGroup>
               
                    <FormGroup>
                        <h5 className="registration-h5">Role</h5>
                        <p className="registration-p">Please request your role(s) for your access.</p>
                        <Row className="userManagementRow">
                            <Col>
                            <div style={{paddingBottom: '10px'}}>
                            <Select
                            closeMenuOnSelect={false}
                            components={this.animatedComponents}
                            isMulti
                            onChange={(event) => this.setRolesSelected(event)}
                            options={this.state.roles}
                          />
                          </div>
                            </Col>
                            <Col>

                            </Col>
                        </Row>
                        
                            {/* <Dropdown id="input-dropdown" name="input-dropdown">
                                <option value=''>- Select - </option>
                               { this.state.roles && (this.state.roles.map((data, index) => (
                                            <option key={index} value={data.value}>
                                                {data.GroupName }
                                            </option>
                                        ))
                               )
                               }
                            </Dropdown>
                            <Button className="link-button">Select another Role</Button> */}
                           
                    </FormGroup>

                    

                    {/*<FormGroup>
                        <h2 className="registration-h2" style={{paddingBottom: '0.5em'}}>Preferences</h2>
                        <Checkbox className="checkbox" id="email-preferences-checkbox" name="email-preferences-checkbox" label="Receive Email Updates" />
                    </FormGroup>*/}
              
                    <FormGroup>
                    <h5 className="registration-h5">Comments to Approver</h5>
                    <p className="registration-p">Enter comments (optional)</p>
                    <Row className="userManagementRow">
                    <Col>
                    <Textarea
                        style={{maxWidth: "50rem"}}
                        id="comments-to-approver-text"
                        name="comments-to-approver-text"
                        placeholder="Comments..."
                        maxLength="2000"
                        onChange={evt => this.onChange('commentsToApprover', evt.target.value)}
                        onBlur={evt => this.onBlurTrim('commentsToApprover', evt)}
                    />
                    </Col>
                    </Row>
                    <p className="registration-p">2,000 characters allowed</p>
                    <br />
                    <p className="registration-p">Note: Email providers tend to heavily filter .info domains.  If you do not receive email correspondence from <a href="mailto:ContactUs@asias.info">ContactUs</a>, it may be getting filtered to your junk/spam folder.</p>
                    
                </FormGroup>
                
                        <Button type="button" outline onClick={() => this.handleCancel()}>Cancel</Button>
                   
                <Button style={{marginBottom: '200px'}}type="submit">Submit</Button>
               
                
                
                    
                 
            </form>  
        )
        }
        {
            showConfirmation && (
                <div>
                {/* <input
                    onChange={evt => this.onChange('authCode', evt.target.value)}
                    placeholder='Confirmation Code'
                /> */}
                <SignInRegConfirm username={this.state.username} />
                </div>
            )
        }
        </div>
        </div>
       
       )
    }
}

export default UserRegistration;
