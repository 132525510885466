import {API} from "aws-amplify";

export function getAggregateDash(user) {
  const token = user.signInUserSession.idToken.jwtToken;
  const requestInfo = {
    headers: {
      Authorization: token
    }
  }

  let url = "/get-aggregate-quicksight-dashboard?" +
  new URLSearchParams({
      username: user.username,
      accessToken: user.signInUserSession.accessToken.jwtToken,
      idToken: user.signInUserSession.idToken.jwtToken,
  })
// console.log({url})
    const data = API.get('raise-gui-api-dev', url, requestInfo)
      return data;
}

export default getAggregateDash;