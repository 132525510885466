import React, { useContext, useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
// import { API } from "aws-amplify";
import PropTypes from 'prop-types';
import { DataContext } from '../DataContext';
import UserContext from '../UserContext'
import getMyFlights from '../../services/getMyFlights';

import SearchFacet from '../SearchFacet';
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Checkbox from "@mui/material/Checkbox";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";

// import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
// import Stack from '@mui/material/Stack';
// import Typography from '@mui/material/Typography';

import CircularProgress from '@mui/material/CircularProgress';
import { Popper } from '@mui/material';
// import circle from '@turf/circle';
import DatePicker from 'react-datepicker'

import getAirportHelipadsFromQuery from '../../services/getAirportHelipadsFromQuery';
import getAirportApproachFlights from '../../services/getAirportApproachFlights';
import getAirportOperators from '../../services/getAirportOperators';

import { makeStyles } from '@mui/styles';

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

//For multiple select unstable subtypes

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300
  },
  indeterminateColor: {
    color: "#f50057"
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
}));

const ITEM_HEIGHT = 35;
const ITEM_PADDING_TOP = 5;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  // getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  },
  variant: "menu"
};

const subtypes = [
  {"name": "Approach Angle", "value": 'approach_angle_isoutrange'},
  {"name": "Approach Bank", "value": 'approach_bank_isoutrange'},
  {"name": "Approach Pitch", "value": 'approach_pitch_isoutrange'},
  {"name": "Approach Ground Track Difference", "value": 'approach_groundtrack_difference_isoutrange'},
  {"name": "Approach Vertspeed", "value": 'approach_vertspeed_isoutrange'},
  {"name": "Approach Airspeed", "value": 'approach_airspeed_isoutrange'},
  {"name": "Approach Torque Total", "value": 'approach_torque_isoutrange'}
];


// Placeholder for defining add list prop types
const AddListPropsType = () => null;

AddListPropsType.propTypes = {
  items: PropTypes.array,
  onRemoveItem: PropTypes.func,
  onAddItem: PropTypes.func,
  originalItems: PropTypes.array,
  dashboardKey: PropTypes.string,
}

const propTypes = {
  AddListProps: AddListPropsType,
  onLayoutSave: PropTypes.func,
  onRef: PropTypes.func,
  excludeBackToFlightsLink: PropTypes.bool,
  style: PropTypes.object,
};

const defaultProps = {
  style: {},
  onRef: (ref) => ref.current,
  excludeBackToFlightsLink: false,
};

const styles = {
  container: {
    display: "flex",
  },
  content: {
    flex: 1,
  }
}


const ApproachHelipadsBar = forwardRef((props, ref) => {

  // ForwardRef method
  useImperativeHandle(ref, () => ({
    resetSearch() {
      // console.log("Resetting geo stuff")
      // setRadius(0)
      // dataContext.setSearchRadius(0);
      setSelHelipad({ label: '', id: '', name: '', lat: '', lon: '' })
      dataContext.setHelipadInfo([-98.5795, 39.8283, '', ''])
    }
  }));
  const {
    style,
  } = props;

  const dataContext = useContext(DataContext);
  const userContext = useContext(UserContext);


  // const toolbarRef = useRef(null);

  // const [resetConfirmOpen, setResetConfirmOpen] = useState(false);
  // const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);

  const radiusRef = useRef();

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [selHelipad, setSelHelipad] = React.useState({ label: '', id: '', name: '', lat: '', lon: '' })
  const [selApproachType, setSelApproachType] = React.useState('All');
  // const [selApproachSubtype, setSelApproachSubtype] = React.useState('All');
  const [selApproachSubtype, setSelApproachSubtype] = React.useState([]);
  // const [selStableUnstable, setSelStableUnstable] = React.useState('All');
  // const [stableUnstableDisable, setStableUnstableDisable] = React.useState(true);
  
   const isAllSelected =
    subtypes.length > 0 && selApproachSubtype.length === subtypes.length;
   const classes = useStyles();
   
  // console.log(isAllSelected);

  const loading = open && options.length === 0;
  const queryLoading = dataContext.approachQueryLoading === false;


  // let currentDate = new Date();
  // let monthInterval = new Date();
  // monthInterval.setMonth(currentDate.getMonth() - 72);

  // const [startDate, setStartDate] = useState(monthInterval);
  // const [endDate, setEndDate] = useState(currentDate);

  let initStartDate = new Date(dataContext.approachSelectInfo['startDate']);
  let initEndDate = new Date(dataContext.approachSelectInfo['endDate']);

  const [startDate, setStartDate] = useState(initStartDate);
  const [endDate, setEndDate] = useState(initEndDate);

  // const [radius, setRadius] = useState(0);
  // const [statusMsg, setStatusMsg] = useState(null);
  // const [searchedNumber, setSearchedNumber] = useState(0);

  const airhelipadJsonFileName = "aphelipads.json";
  // const defaultHelipad = { label: 'NJ48', id: "10133", name: "Golden Nugget Atlantic City", lat: "39-22-46.4200N", lon: "074-25-39.5360W" };

  // const helipadsList = [
  //   { label: 'NJ48', name: "Golden Nugget Atlantic City", lat: "39-22-46.4200N", lon: "074-25-39.5360W" },
  //   { label: '89NJ', name: "Strawberry Fields", lat: "39-29-21.0000N", lon: "074-43-25.0000W" },
  //   { label: 'MMU', name: "Morristown Muni", lat: "40-47-57.6180N", lon: "074-24-53.5990W" }
  // ];

  const customPopover = function(props) {
    return <Popper {...props} placement="bottom-start" container={document.fullscreenElement ? document.fullscreenElement : document.body} />;
  };

  useEffect(() => {

    if (dataContext.mapLoadSearch)
      defaultSearchExec()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.mapLoadSearch]);


  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    // if (options.length <= 0) {

    //   const operator = userContext.user.attributes['custom:operator'];

    //   getAirportOperators(userContext.user, operator).then((existingHelipads) => {

    //     // console.log(existingHelipads);
    //     const airhelipadJsonUrl = `https://raisemaptiles.asias.info/mapdata/${airhelipadJsonFileName}`;
    //     (async () => {

    //       if (active) {
    //         await fetch(airhelipadJsonUrl).then((result) => result.json()).then((data) => {
    //           data["rowdata"] = [{ label: '', id: '', name: '', lat: '', lon: '' }, ...data["rowdata"]]
    //           // console.log(data["rowdata"])

    //           // setOptions([...data["rowdata"].filter(row => {
    //           //   return (row["label"].trim() !== '' && existingHelipads.some(e => e.approach_icao_id == row["label"]))
    //           // }).sort((a, b) => a['label'] > b['label'] ? 1 : -1)]);
              
    //           let selectedHelipads = [...data["rowdata"].filter(row => {
    //             return (row["label"].trim() !== '' && existingHelipads.some(e => e.approach_icao_id == row["label"]))
    //           }).sort((a, b) => a['label'] > b['label'] ? 1 : -1)];
              
    //           // console.log(selectedHelipads);
              
    //           setOptions(selectedHelipads);
              
    //           dataContext.setHelipadsOperator(selectedHelipads);

    //         });
    //       }
    //     })();
    //   });


    // }
    
    setOptions(dataContext.helipadsOperator);


    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  // useEffect(() => {
  //   if (!open) {
  //     // setOptions([]);  
  //   }
  // }, [open]);


  const convertLatLongStrToDouble = (valueStr) => {

    let valueNs = valueStr.split("-");
    let nw = valueNs[2].slice(-1);
    let latlonV = parseInt(valueNs[0]) + parseFloat(valueNs[1]) / 60 + parseFloat(valueNs[2].slice(0, -1)) / 3600;
    let latlon = Math.round(latlonV * 1000000) / 1000000;

    if (nw === "W" || nw === "S")
      latlon = (-1) * latlon


    return latlon;
  }

  const onHelipadListChange = (event, value) => {

    if (value) {
      // if(dataContext.DEBUG_MODE)
      // console.log(value);

      //console.log(value);
      let latS = value["lat"];
      let latV = convertLatLongStrToDouble(latS);

      let lonS = value["lon"];
      let lonV = convertLatLongStrToDouble(lonS);

      if (dataContext.DEBUG_MODE)
        // console.log([lonV, latV]);
        // props.setLng(lonV)
        // props.setLat(latV)
        setSelHelipad(value)
      dataContext.setHelipadInfo([lonV, latV, value["label"], value["name"], value["ele"], value["tc"]]);

      // dataContext.setSearchRadius(0);
      dataContext.setShowNoOperatorFlights(false);
      // setSearchedNumber(0);
      // setStatusMsg(null);

      // let airportId = value["label"];
      // getAirportApproachFlights(userContext.user, airportId).then((results) => {

      //   const maxDate = new Date(
      //     Math.max(
      //       ...results.map(element => {
      //         return new Date(element.times_timestamp);
      //       }),
      //     ),
      //   );

      //   const minDate = new Date(
      //     Math.min(
      //       ...results.map(element => {
      //         return new Date(element.times_timestamp);
      //       }),
      //     ),
      //   );

      //   console.log(maxDate);
      //   console.log(minDate);

      // });
    }

  }

  const onSelectApproachType = (event) => {
    setSelApproachType(event.target.value);
    // console.log(event.target.value);

  }

  const onSelectApproachSubtype0 = (event) => {
    setSelApproachSubtype(event.target.value);
    
    // console.log(event.target.value);

    // if (event.target.value == "All")
    //   setStableUnstableDisable(true);
    // else
    //   setStableUnstableDisable(false)


  }
  
  const onSelectApproachSubtype = (event) => {
    const value = event.target.value;
    // console.log(value);
    if (value[value.length - 1] === "all") {
      
      let subtypesValues = [];
      subtypes.forEach(s => subtypesValues.push(s.value));
      
      // console.log(selApproachSubtype.length ==+ subtypes.lengt);
      
      let setValues = selApproachSubtype.length === subtypes.length ? [] : subtypesValues;
      // console.log(setValues);
      
      setSelApproachSubtype(setValues);
      
      return;
    }
    setSelApproachSubtype(value);
  };

  // const onSelectStableUnstable = (event) => {
  //   setSelStableUnstable(event.target.value);
  //   // console.log(event.target.value);

  // }

  const onFetchInputs = (event) => {

    // console.log(selHelipad);
    // console.log(startDate.toISOString());
    // console.log(endDate.toISOString());
    dataContext.setApproachSelectInfo({
      "helipad": dataContext.helipadInfo,
      "startDate": startDate.toISOString(),
      "endDate": endDate.toISOString(),
      "approachType": selApproachType,
      "approachSubtype": selApproachSubtype
    })
    
    dataContext.setApproachQueryLoading(true);
    dataContext.setApproachQueryStatus("Loading approach data...");

  }

  // const onRangeChange = (e) => {
  //   if (dataContext.DEBUG_MODE)
  //     // console.log(e.target.value);

  //     setRadius(e.target.value);

  //   dataContext.setDrawMode(dataContext.DRAW_MODE.INPUT); //Set the drawing mode to be text input instead of drawing graphics 
  //   //dataContext.setRenderFlightIds([]); //Clear rendered flights in memory
  //   dataContext.setSearchRadius(e.target.value);

  //   // console.log(dataContext.helipadInfo);

  //   // let center = [];
  //   // center[0] = dataContext.helipadInfo[0];
  //   // center[1] = dataContext.helipadInfo[1];
  //   // let radius = parseFloat(e.target.value) * 1.60934; //miles to kms
  //   // let options = {steps: 60, units: 'kilometers', properties: {"ident": dataContext.helipadInfo[2]}};
  //   // console.log(options);
  //   // let circle1 = circle(center, radius, options);

  //   // console.log(circle1);

  //   dataContext.setShowNoOperatorFlights(false);
  //   //  setStatusMsg(null);

  // }

  const geoSearchFlightsByPolygonRetired = () => {

    dataContext.setSearchStatus(true);

    props.searchObj["polygon"] = dataContext.circleGeoCoords

    // console.log(props.searchObj)
    getMyFlights(userContext.user, "", props.searchObj).then((resultsMyF) => {
      if (dataContext.DEBUG_MODE)
        // console.log(resultsMyF)

        if (resultsMyF) {
          //Create mimic of original geosearch strucutre for useEffect chain
          let tempResultList = []
          // Scrub nan out of dq_errors
          resultsMyF.forEach((item) => {
            if (item['dq_errors'] === 'nan')
              item['dq_errors'] = '0'

            tempResultList.push(item)

          })
          dataContext.setSearchedFlights(tempResultList);
          dataContext.searchedFlightsMeta.current = resultsMyF

        }
      else {
        onNotFound();
      }

    })



  }

  // const onSearchButtonClick = () => {

  //   //Remove the red status message
  //   let curRadius = radiusRef.current.value;

  //   if(dataContext.DEBUG_MODE)
  //     console.log(curRadius);

  //   dataContext.setShowNoOperatorFlights(false);
  //   setStatusMsg(null);

  //   if (curRadius > 0) {

  //     dataContext.setSearchRadius(curRadius);
  //     dataContext.setSearchStatus(true);
  //     dataContext.setSearchedFlights([]);
  //     dataContext.setBaseData({});
  //     dataContext.setBackToGeoSearchMode(false);


  //   }
  //   geoSearchFlightsByPolygon();
  // }


  // const defaultSearchExec = () => {

  //   //Remove the red status message
  //   let curRadius = 2000;

  //   if(dataContext.DEBUG_MODE)
  //     console.log(curRadius);

  //   dataContext.setShowNoOperatorFlights(false);
  //   setStatusMsg(null);
  //   console.log("Status 1")
  //   if (curRadius > 0) {
  //     console.log("Status 2")
  //     dataContext.setSearchRadius(curRadius);
  //     dataContext.setSearchStatus(true);
  //     dataContext.setSearchedFlights([]);
  //     dataContext.setBaseData({});
  //     dataContext.setBackToGeoSearchMode(false);
  //     const waitForCoords = setTimeout(() => {
  //       console.log("Status 3")
  //       // Hack to wait for polygon coords to be done
  //       defaultSearchExec2();
  //     }, 5000);

  //   }
  //   else
  //     onNotFound();
  // }

  const defaultSearchExec = () => {


    // let authUser = userContext.user;

    // let testCoords = [[-74.112, 40.934], [-73.714, 41.107],[-73.416,40.972],[-73.532,40.708],[-73.935,40.699],[-74.074,40.833]];
    // console.log(dataContext.circleGeoCoords);
    // Use a default search package, defaultSearch should only ever be used when making a default search, and left blank otherwise
    // const token = authUser.signInUserSession.idToken.jwtToken;
    const requestInfo = {
      defaultQuery: true
    }
    // console.log(requestInfo)
    dataContext.setSearchStatus(true);
    getMyFlights(userContext.user, "", requestInfo).then((resultsMyF) => {
      if (dataContext.DEBUG_MODE)
        // console.log(resultsMyF)

        if (resultsMyF) {
          //Create mimic of original geosearch strucutre for useEffect chain
          let tempResultList = []
          // Scrub nan out of dq_errors
          resultsMyF.forEach((item) => {
            if (item['dq_errors'] === 'nan')
              item['dq_errors'] = '0'

            tempResultList.push(item)

          })
          dataContext.setSearchedFlights(tempResultList);
          dataContext.searchedFlightsMeta.current = resultsMyF

        }
      else {
        onNotFound();
      }

    });

  }

  const onNotFound = () => {
    // let statusStr = `No flight found`;
    // let statusJSX = <p style={{color: 'red', paddingTop: 7}}>{statusStr}</p>
    // setStatusMsg(statusJSX);
    // setSearchedNumber(0);

    dataContext.setSearchStatus(false);
    dataContext.setFoundFlightCount(0);
    dataContext.setSearchedFlights([]);
    dataContext.setBaseData({});
  }

  return (
    <div style={{ ...styles.container, ...style }}>
      <div style={styles.content} >
         
                <Stack direction="row" spacing={1}>
                  <div className='inputLabel' style={{display: 'block', paddingLeft: '10px', width: '200px' }}>Helipad

                    <Autocomplete
                    PopperComponent={customPopover}
                      value={selHelipad || null}
                      id="helipadsList"
                      style={{ width: "100%", marginTop: '8px'}}
                      size="small"
                      open={open}
                      onOpen={() => {
                        setOpen(true);
                      }}
                      onClose={() => {
                        setOpen(false);
                      }}
                      onChange={onHelipadListChange}
                      // defaultValue = {defaultHelipad}
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                      getOptionLabel={(option) => option.label}
                      freeSolo
                      options={options}
                      loading={loading}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id} style={{ fontSize: "13px"}}>
                            {option.label}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          id="searchtext"
                          {...params}

                          sx={{
                            "& .MuiInputBase-input": {
                              outline: "none !important",
                            },
                          }}
                          // label="Choose a helipad"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </div>
                  
                  <div className='inputLabel' style={{ display: 'block', paddingLeft: '10px' }}>Date Range
                   <div style={{display:'flex', paddingTop:'5px'}}>
                    <div className={dataContext.darkMode ? 'darkDate' : 'lightDate'} style={{ fontSize: "large", width:'50%' }}>
                      <DatePicker
              
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)} />
                    </div>
              
                    {/* <Label style={{ marginTop: '0px' }} htmlFor="endDateSelector">End Date:</Label> */}
                    <div className={dataContext.darkMode ? 'darkDate' : 'lightDate'} style={{ fontSize: "large", width:'50%' }}>
                      <DatePicker
              
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        selected={endDate}
                        onChange={(date) => setEndDate(date)} />
                    </div>
                    </div>
                  </div>
                  
                  <div className='inputLabel' style={{ display: 'block', paddingLeft: '10px', width: '200px' }}>Approach Type
                    <div style={{display:'flex', paddingTop:'5px'}}>
                      <Select
                        labelId="select-approach-type-label"
                        id="select-approach-type"
                        style={{ width: "100%", marginTop: '8px', height: '40px' }}
                        value={selApproachType}
                        label="Approach Type"
                        onChange={onSelectApproachType}
                      >
                        <MenuItem value={'All'}>All</MenuItem>
                        <MenuItem value={'VFR'}>VFR</MenuItem>
                        <MenuItem value={'VFR-Missed'}>VFR-Missed</MenuItem>
                        <MenuItem value={'IFR'}>IFR</MenuItem>
                        <MenuItem value={'IFR-Missed'}>IFR-Missed</MenuItem>
                      </Select>
                    </div>
                  </div>

                  {/*<div className='inputLabel' style={{ display: 'block', paddingLeft: '10px', width: '200px' }}>Unstable Approach Type
                    <div style={{display:'flex', paddingTop:'5px'}}>
                      <Select
                        labelId="select-approach-type-label"
                        id="select-approach-type"
                        style={{ width: "100%", marginTop: '8px', height: '40px' }}
                        value={selApproachSubtype}
                        label="Approach Subtype"
                        onChange={onSelectApproachSubtype}
                      >
                        <MenuItem value={'approach_mean_angle_deg'}>Approach Angle</MenuItem>
                        <MenuItem value={'approach_mean_bank_deg'}>Approach Bank</MenuItem>
                        <MenuItem value={'approach_mean_pitch_deg'}>Approach Pitch</MenuItem>
                        <MenuItem value={'approach_mean_groundtrack_difference_deg'}>Approach Ground Track Difference</MenuItem>
                        <MenuItem value={'approach_mean_vertspeed_fpm'}>Approach Vertspeed</MenuItem>
                        <MenuItem value={'approach_mean_airspeed_kts'}>Approach Airspeed</MenuItem>
                        <MenuItem value={'approach_mean_torque_total_pct'}>Approach Torque Total</MenuItem>
                      </Select>
                    </div>
                  </div>*/}
                  
                  <div className='inputLabel' style={{ display: 'block', paddingLeft: '10px', width: '580px' }}>Unstable Criteria
                    <div style={{display:'flex', paddingTop:'5px'}}>
                    
                    {/*<FormControl sx={{ m: 0.1, height: 40, width: 500 }}>*/}
                            <Select
                              labelId="select-approach-type-label"
                              multiple
                              value={selApproachSubtype}
                              style={{ width: "100%", marginTop: '8px', height: '40px'}}
                              onChange={onSelectApproachSubtype}
                              
                              renderValue={(selApproachSubtype) =>(selApproachSubtype.map(value => subtypes.find(e => e.value === value).name.slice(9))).join(', ')}
                             
                            >
                              <MenuItem
                                value="all"
                                classes={{
                                  root: selApproachSubtype.length === subtypes.length ? classes.selectedAll : ""
                                }}
                                sx={{height: 30, fontSize: '0.8rem'}}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    classes={{ indeterminate: classes.indeterminateColor }}
                                    checked={selApproachSubtype.length === subtypes.length}
                                    indeterminate={
                                      selApproachSubtype.length > 0 && selApproachSubtype.length < subtypes.length
                                    }
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  classes={{ primary: classes.selectAllText }}
                                  primaryTypographyProps={{fontSize: '15px'}}
                                  primary="Select All"
                                />
                              </MenuItem>
                              {subtypes.map((option) => (
                                <MenuItem key={option.name} value={option.value} sx={{height: 30, fontSize: '0.8rem'}}>
                                  <ListItemIcon>
                                    <Checkbox checked={selApproachSubtype.indexOf(option.value) > -1} />
                                  </ListItemIcon>
                                  <ListItemText primaryTypographyProps={{fontSize: '15px'}} primary={option.name} />
                                </MenuItem>
                              ))}
                            </Select>
                    
                   {/* </FormControl>*/}

                    </div>
                  </div>  

                  {/*<div className='inputLabel' style={{ display: 'block', paddingLeft: '10px', width: '200px' }}>Stable or Unstable
                    <div style={{display:'flex', paddingTop:'5px'}}>
                      <Select
                        labelId="select-approach-type-label"
                        id="select-approach-type"
                        style={{ width: "100%", marginTop: '8px', height: '40px' }}
                        value={selStableUnstable}
                        label="Stable or Unstable"
                        onChange={onSelectStableUnstable}
                      >
                        <MenuItem value={'All'} disabled={stableUnstableDisable}>All</MenuItem>
                        <MenuItem value={'0'} disabled={stableUnstableDisable}>Stable</MenuItem>
                        <MenuItem value={'1'} disabled={stableUnstableDisable}>Unstable</MenuItem>
                      </Select>
                    </div>
                  </div>*/}   
                  
                  <div style={{ paddingLeft: '10px', alignSelf: 'center' }}>
                  <div style={{display:'flex', paddingTop:'25px'}}>
                  <Button variant="contained" 
                          size="large" 
                          color="primary" 
                          style={{textTransform: 'none'}}
                          onClick={onFetchInputs}
                          >Search</Button>
                  </div>
                  
                  </div>
                  <div style={{ paddingLeft: '10px', alignSelf: 'center' }}>
                  <div style={{display:'flex', paddingTop:'25px', width: '250px'}}>
                  {queryLoading ? null : <CircularProgress color="inherit" size={20} /> }&nbsp;&nbsp;
                  <Typography variant="body1" color="black" display="block" gutterBottom>
                    {dataContext.approachQueryStatus}
                  </Typography>
                  </div>
                  </div>
                  </Stack>


      {/*<Typography variant="caption" display="block" sx={{color: '#eee'}} gutterBottom>
        Select a helipad indent, input radius or draw a circle on the map to search flights
      </Typography>*/}
      
      {/*<SearchFacet title="Geospatial"
                      groupName="geospatial"
                      groupText="Search flights by geography"
                      >*/}
       {/* <Stack spacing={2} direction="column" sx={{pt: 2, pb: 1}}>
        */}
       {/* {dataContext.searchStatus?(
            <div><CircularProgress size={30}/>
              <Typography variant="caption" color="secondary" sx={{pt: -1, pl: 1}} gutterBottom>
                Searching Flights...
              </Typography>
            </div>
          ):<div></div>}
             {dataContext.showNoOperatorFlights? (<p style={{color: 'red', paddingTop: 7}}>{'No flight found'}</p>):statusMsg} */}

        {/*Select A Helipad and Radius:*/}
        {/*  <div style={{ display: 'block' }}>

            <div className='inputLabel' style={{ width: '100%', display: 'block', border: 'gray', borderStyle: 'dashed', borderWidth: 'thin' }}>
            <div style={{marginTop:'5px'}}>Point/Radius Search <Tooltip title="Ctrl-Click the map to select a Lat/Lon"><HelpIcon sx={{ fontSize: 17 }} /></Tooltip></div>
              <div className='inputLabel' style={{ width: '100%', display: 'flex' }}>
                <div className='inputLabel' style={{ width: '100%', display: 'block', paddingLeft: '10px' }}>Point
                  <div className='inputLabel' style={{ width: '100%', display: 'flex', paddingLeft: '10px' }}>
                    <div className='inputLabel' style={{ width: '100%' }}>Lat
                      <TextField
                        style={{ width: "100%", marginTop: '8px' }}
                        id="Latitude"
                        
                        sx={{
                          "& .MuiInputBase-input": {
                            outline: "none !important",
                          },
                        }}
                        size="small"
                        variant="outlined"
                        onChange={(evt) => {
                          console.log(evt.target.value)
                          props.setLat(evt.target.value)}}
                        value={props.lat}
                      />
                    </div>
                    <div className='inputLabel' style={{ width: '100%' }}>Lon
                      <TextField
                        style={{ width: "100%", marginTop: '8px' }}
                        id="Longitude"
                        sx={{
                          "& .MuiInputBase-input": {
                            outline: "none !important",
                          },
                        }}
                        size="small"
                        variant="outlined"
                        onChange={(evt) => {
                          console.log(evt.target.value)
                          props.setLng(evt.target.value)}}
                        value={props.lng}
                      />
                    </div>
                  </div>
                </div>
                <div className='inputLabel' style={{ width: '100%', display: 'block', paddingLeft: '10px' }}>Preset Points
                  <div className='inputLabel' style={{ width: '100%', display: 'block', paddingLeft: '10px' }}>Helipad

                    <Autocomplete
                    PopperComponent={customPopover}
                      value={selHelipad || null}
                      id="helipadsList"
                      style={{ width: "100%", marginTop: '8px' }}
                      size="small"
                      open={open}
                      onOpen={() => {
                        setOpen(true);
                      }}
                      onClose={() => {
                        setOpen(false);
                      }}
                      onChange={onHelipadListChange}
                      // defaultValue = {defaultHelipad}
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                      getOptionLabel={(option) => option.label}
                      options={options}
                      loading={loading}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.label}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          id="searchtext"
                          {...params}

                          sx={{
                            "& .MuiInputBase-input": {
                              outline: "none !important",
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            

            
            <div style={{ display: 'flex' }}>

              <div className='inputLabel'>Radius (miles)</div>
              <TextField
                style={{ width: "100%", marginTop: '8px' }}
                id="searchRadius"
                inputRef={radiusRef}
                sx={{
                  "& .MuiInputBase-input": {
                    outline: "none !important",
                  },
                }}
                size="small"
                variant="outlined"
                onChange={onRangeChange}
                value={radius}
              />

 

            </div>
            </div>
          </div>*/}
        {/*</SearchFacet>*/}
        {/* Header Components Start */}
        {/* Reference: https://mui.com/components/menus/#account-menu */}
        {/* Header Components End */}

      </div>


    </div>
  );
})

ApproachHelipadsBar.propTypes = propTypes;
ApproachHelipadsBar.defaultProps = defaultProps;

export default ApproachHelipadsBar;
