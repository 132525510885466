import React, { useState, useContext } from "react";
import { DataContext } from "../DataContext";
// import PropTypes from 'prop-types';
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { withSize } from "react-sizeme";
import DashboardWidget from "../DashboardWidget";
import LoadingSkeleton from "../utils/LoadingSkeleton";
import { IconButton, Tooltip } from "@mui/material";
import AddList from "../AddList";
import SaveIcon from '@mui/icons-material/Save';
import GeoSearchByHelipadsMap from "../maps/GeoSearchByHelipadsMap";
import DataBrowser, { buildDataBrowserColumn } from "../misc/DataBrowser";
import BigNumber from "../misc/BigNumber";
// import UasRiskMap from "../maps/UasRiskMap";

// Need to import styles for core functionality
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const propTypes = {};
const defaultProps = {};

const styles = {
  toolbarContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  toolbarBanner: {
    marginRight: "auto",
  },
};


function GeoSearchByHelipadsContent({ size: { width } }) {
  const SAVED_LAYOUTS_KEY = "GeoSearchLayout";

  const dataContext = useContext(DataContext);
  const geoSearchByHelipadsPageConfig = dataContext.geoSearchByHelipadsPageConfig;

  const originalItems = Object.keys(geoSearchByHelipadsPageConfig);

  const initialLayouts = {
    lg: originalItems.map(item => geoSearchByHelipadsPageConfig[item])
  };

  const loadedLayouts = dataContext.getFromLS(SAVED_LAYOUTS_KEY) || initialLayouts;
  const [layouts, setLayouts] = useState(
    loadedLayouts
  );

  // Initial set of widgets will be based on saved layouts data (defaults to initial layouts if no saved data found)
  const [items, setItems] = useState(loadedLayouts.lg.map(item => item.i));

  const onLayoutChange = (_, allLayouts) => {
    setLayouts(allLayouts);
  };

  const onLayoutSave = (e) => {
    e.stopPropagation();
    dataContext.saveToLS(SAVED_LAYOUTS_KEY, layouts);

    // Show snackbar notification on save
    dataContext.showSnack("top", "center", "Dashboard Layout Saved for UAS Risk Analysis!", dataContext.SNACK_SEVERITY.SUCCESS, 2000);
  };

  const onRemoveItem = (itemId) => {
    // console.log(`Removing ${itemId} from the dashboard...`);
    setItems(items.filter((i) => i !== itemId));
  };

  const onAddItem = (itemId) => {
    setItems([...items, itemId]);
  };

  /**
   * Handle DataBrowser row selection event for drilling down on data record.
   * 
   * @param {string} key Key associated with target dataset for drilldown.
   * @param {any} value Value associated with key mapping in dataset for drilldown.
   */
  const handleRowSelected = (key, value) => {

    //value is the selected flight id
    dataContext.setConnectFlightId(value);
    
    if(dataContext.DEBUG_MODE)
      console.log(value);

  }

  /**
   * Handle DataBrowser row hovered event for drilling down on data record.
   * 
   * @param {string} key Key associated with target dataset for drilldown.
   * @param {any} value Value associated with key mapping in dataset for drilldown.
   */
  const handleRowHovered = (key, value) => {
    // TODO Handle row hovered event
    // console.log(`Row hovered with key = ${key} and value = ${value}`);

    dataContext.setHoverFlightId(value);

    //Reset the drawing mode, don't draw graphics on the map
    dataContext.setDrawMode(dataContext.DRAW_MODE.NODRAWING);
  }

  const dateTimeHandler = (data) => {
    // return dataContext.toHumanReadableDateStr(new Date(data));
    return dataContext.toHumanReadableDateStr(data);
  }

  const exceedanceModifer = (value) => {
    let msg;
    switch (value) {
      case "1":
        msg = 'Low';
        break;
      case "2":
        msg = 'Medium';
        break;
      case "3":
        msg = 'High';
        break;
      default:
        msg = 'None';
        break;

    }
    return msg;
  }

  const exceedanceColorFn = (value) => {
    let color;
    switch (value) {
      case "Low":
        color = '#FAF28B';
        break;
      case "Medium":
        color = 'orange';
        break;
      case "High":
        color = '#FF0000';
        break;
      default:
        color = '#94FA8B';
        break;

    }
    return color;
  }

  const obstacleModifer = (value) => {
    let msg;
    switch (value) {
      case '1':
        msg = 'None';
        break;
      case '2':
        msg = 'Low';
        break;
      case '3':
        msg = 'Medium';
        break;
      case '4':
        msg = 'High';
        break;
      default:
        msg = 'None';
        break;

    }
    return msg;
  }

  const obstacleColorFn = (value) => {
    let color;
    switch (value) {
      case "Low":
        color = '#FAF28B';
        break;
      case "Medium":
        color = 'orange';
        break;
      case "High":
        color = '#FF0000';
        break;
      default:
        color = '#94FA8B';
        break;

    }
    return color;
  }

  const locModifer = (value) => {
    let msg;
    if (!value || value === 'nan') {
      msg = "False";
    }
    else {
      msg = 'True';
    }
    return msg;
  }

  const locColorFn = (value) => {
    let color;
    switch (value) {
      case "Low":
        color = '#FAF28B';
        break;
      case "Medium":
        color = 'orange';
        break;
      case "High":
        color = '#FF0000';
        break;
      default:
        color = '#94FA8B';
        break;
    }
    return color;
  }

  const dqColorFn = (value) => {
    let color;
    if (value === '0') {
      color = '#94FA8B';
    }
    else {
      color = 'orange';
    }
    return color;
  }

  const cellTextStyle = { textAlign: "center", left: 0 };

  // Core widgets list
  const componentList = {
    geoSearchByHelipadsMap: <GeoSearchByHelipadsMap
          mapId="geoSearchByHelipadsMap"
          // startLoc={[-75.146, 39.946]} // BOS airport
          // zoom={4}
        />,
    searchedFlightsTable: <DataBrowser
          primaryTargetKey="searched_exist_flights"
          primaryCol = "flight_id"
          decorateNulls
          onRowSelected={handleRowSelected}
          onRowHovered={handleRowHovered}
          columnsOfInterest={[
            buildDataBrowserColumn({ col: "alias", alias: "Alias", width: 10 }),
            buildDataBrowserColumn({ col: "flightmetadata_startisodate", alias: "Start Time", width: 50, modifier: dateTimeHandler }),
            // buildDataBrowserColumn({ col: "flightmetadata_endisodate", alias: "End Time", width: 50,modifier: dateTimeHandler  }),
            buildDataBrowserColumn({ col: "exceedance_total_max_text", alias: "Exceedances", width: 12, colorFn: exceedanceColorFn, textStyle: cellTextStyle }),
            buildDataBrowserColumn({ col: "flightmetadata_risks_maxobstaclelevelencountered_text", alias: "Obstacles", width: 12, colorFn: obstacleColorFn, textStyle: cellTextStyle }),
            buildDataBrowserColumn({ col: "loss_of_control_total_max_text", alias: "Loss of Control", width: 12, colorFn: locColorFn, textStyle: cellTextStyle  }),
            buildDataBrowserColumn({ col: "dq_errors", alias: "Data Quality", width: 12, colorFn: dqColorFn, textStyle: cellTextStyle  }),
            // buildDataBrowserColumn({ col: "flight_id", alias: "Flight Id", width: 40 })
          ]}
        />
    //         flightmetadata_rotorcraft_aircraftregistration, 
    // flightmetadata_startisodate,
    // flightmetadata_endisodate,
    // exceedance_total_max,
    // flightmetadata_risks_maxobstaclelevelencountered,
    // loss_of_control_total_max
  };

  return (
    <>
      {/* Dashboard tab management functions - right aligned if display is flex and content is justified as flex-end */}

      {/* Add controls here as needed */}


      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={60}
        width={width * (1 / dataContext.appScale)}
        draggableHandle=".drag-handle"
        transformScale={dataContext.appScale} // Required to adapt transform translation offset when dragging
      >
        {items.map((key) => (

          <div
            key={key}
            data-grid={{ w: 6, h: 8, x: 0, y: Infinity }}
          >
            <DashboardWidget
              id={key}
              name={geoSearchByHelipadsPageConfig[key].name}
              className="drag-handle"
              onRemoveItem={onRemoveItem}
              fullscreenEnabled={false}
              removeEnabled={false}
              moveEnabled={false}
            >
              {componentList[key]}
            </DashboardWidget> 
            {/*componentList[key]*/}
          </div>
        ))}
      </ResponsiveGridLayout>
    </>
  );
}

GeoSearchByHelipadsContent.propTypes = propTypes;
GeoSearchByHelipadsContent.defaultProps = defaultProps;

export default withSize({ refreshMode: "debounce", refreshRate: 60 })(GeoSearchByHelipadsContent);
