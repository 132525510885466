import React, { useState, useContext } from "react";
import { DataContext } from "../DataContext";
// import PropTypes from 'prop-types';
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { withSize } from "react-sizeme";
import DashboardWidget from "../DashboardWidget";
import LoadingSkeleton from "../utils/LoadingSkeleton";
import FlightIdBanner from "./FlightIdBanner";
import StaticMetadataViewer from "../misc/StaticMetadataViewer";
import GroundSpeedScatterChart from "../charts/flightprofile/GroundSpeedScatterChart";
import GroundSpeedMap from "../maps/GroundSpeedMap";
import DataBrowser, { buildDataBrowserColumn } from "../misc/DataBrowser";
import AglScatterChart from "../charts/flightprofile/AglScatterChart";
import AglMap from "../maps/AglMap";
// import Toolbar from "../utils/Toolbar";
import { IconButton, Tooltip } from "@mui/material";
import AddList from "../AddList";
import SaveIcon from '@mui/icons-material/Save';
import DashboardIcon from '@mui/icons-material/Dashboard';

// Need to import styles for core functionality
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const propTypes = {};
const defaultProps = {};

const styles = {
  toolbarContainer: {
    display: "flex", 
    justifyContent: "flex-end",
  },
  toolbarBanner: {
    marginRight: "auto",
  },
};

function FlightProfileContent({ size: { width } }) {
  const SAVED_LAYOUTS_KEY = "FlightProfileLayout";

  const dataContext = useContext(DataContext);
  const flightMetricsDashboardConfig = dataContext.flightMetricsDashboardConfig[dataContext.FLIGHT_PROFILE_TAB_KEY];

  const originalItems = Object.keys(flightMetricsDashboardConfig);
  const targetData = dataContext.filteredData ? dataContext.filteredData : dataContext.baseData;
  const metaKey = targetData && targetData.flight_meta ? "flight_meta" : "track_points"
  const initialLayouts = {
    lg: originalItems.map(item => flightMetricsDashboardConfig[item])
  };

  const loadedLayouts = dataContext.getFromLS(SAVED_LAYOUTS_KEY) || initialLayouts;
  const [layouts, setLayouts] = useState(
    loadedLayouts
  );

  // Initial set of widgets will be based on saved layouts data (defaults to initial layouts if no saved data found)
  const [items, setItems] = useState(loadedLayouts.lg.map(item => item.i));

  const onLayoutChange = (_, allLayouts) => {
    setLayouts(allLayouts);
  };

  const onLayoutSave = (e) => {
    e.stopPropagation();
    dataContext.saveToLS(SAVED_LAYOUTS_KEY, layouts);

    // Show snackbar notification on save
    dataContext.showSnack("top", "center", "Dashboard Layout Saved for Flight Profile!", dataContext.SNACK_SEVERITY.SUCCESS, 2000);
  };

  const onLayoutReset = (e) => {
    e.stopPropagation();
    const newLoadedLayouts = dataContext.getFromLS(SAVED_LAYOUTS_KEY) || initialLayouts;
    setLayouts(newLoadedLayouts);
    setItems(newLoadedLayouts.lg.map(item => item.i));
    dataContext.showSnack("top", "center", "Dashboard Layout Reset to Last Saved State for Flight Profile!", dataContext.SNACK_SEVERITY.SUCCESS, 2000);
  }

  const onRemoveItem = (itemId) => {
    // console.log(`Removing ${itemId} from the dashboard...`);
    setItems(items.filter((i) => i !== itemId));
  };

  const onAddItem = (itemId) => {
    setItems([...items, itemId]);
  };

  // Handler Functions for Data Viewers
  const dateTimeHandler = (data) => {
    // return dataContext.toHumanReadableDateStr(new Date(data));
    return dataContext.toHumanReadableDateStr(data);
  }

  const roundingHandlerDefault = (data) => {
    return dataContext.roundStr(data);
  }

  const roundingHandlerLong = (data) => {
    return dataContext.roundStr(data, 6);
  }

  const humanReadableHandler = (data) => {
    return dataContext.capitalizeWords(data);
  }

  // Core widgets list
  const componentList = {
    flightMetadata:
      dataContext.processing ? <LoadingSkeleton /> :
        <StaticMetadataViewer
          dataTargetKey={metaKey}
          columnsOfInterest={[
            { col: "alias", alias: "Alias", modifier: null },
            { col: "flightmetadata_missionprofilenameshort", alias: "Mission", modifier: null },
            { col: "flightmetadata_starttime", alias: "Start Date (UTC)", modifier: dateTimeHandler },
            { col: "flightmetadata_endtime", alias: "End Date (UTC)", modifier: dateTimeHandler },
            { col: "flightmetadata_durationsec", alias: "Duration (min)", modifier: data => roundingHandlerDefault(data / 60) },            
          ]}
        />,
    aircraftMetadata:
      dataContext.processing ? <LoadingSkeleton /> :
        <StaticMetadataViewer
          dataTargetKey={metaKey}
          columnsOfInterest={[
            { col: "flightmetadata_rotorcraft_rotorcraftmake", alias: "Make", modifier: null },
            { col: "flightmetadata_rotorcraft_rotorcraftmodel", alias: "Model", modifier: null },
            { col: "flightmetadata_rotorcraft_rotorcraftseries", alias: "Series", modifier: null },
          ]}
        />,
    recorderMetadata:
      dataContext.processing ? <LoadingSkeleton /> :
        <StaticMetadataViewer
          dataTargetKey={metaKey}
          columnsOfInterest={[
            { col: "recorder_make", alias: "Make", modifier: null },
            { col: "recorder_model", alias: "Model", modifier: null },
            { col: "recorder_version", alias: "Version", modifier: null },
            { col: "recorder_hz", alias: "Data Rate (hz)", modifier: null },
          ]}
        />,
    recordDetails:
      dataContext.processing ? <LoadingSkeleton /> :
        <StaticMetadataViewer
          dataTargetKey={metaKey}
          columnsOfInterest={[
            { col: metaKey === 'flight_meta' ? "flightmetadata__id": "flightid", alias: "Flight ID", modifier: null },
            // ... (process.env.REACT_APP_USER_BRANCH !== 'prod' ? [{ col: "translationscript_inputfile", alias: "Source File", modifier: null }] : []),
            { col: "issimulation", alias: "Simulator", modifier: null },
            { col: "translationscript_translationdatetime", alias: "Processed Date", modifier: dateTimeHandler },
          ]}
        />,
    groundSpeedScatterChart:
      dataContext.processing ? <LoadingSkeleton /> :
        <GroundSpeedScatterChart />,
    groundSpeedMap:
      dataContext.processing ? <LoadingSkeleton /> :
        <GroundSpeedMap
          mapId="ground-speed-map"
          zoomSensitivity={0.2}
        />,
    aglScatterChart:
      dataContext.processing ? <LoadingSkeleton /> :
        <AglScatterChart />,
    aglMap:
      dataContext.processing ? <LoadingSkeleton /> :
        <AglMap
          mapId="agl-map"
          zoomSensitivity={0.2}
        />,
    flightProfileDataBrowser:
      dataContext.processing ? <LoadingSkeleton /> :
        <DataBrowser
          primaryTargetKey="unique_track_points"
          secondaryTargetKey="exceedance_point" // Fallback on exceedance point dataset if unique track data doesn't exist
          decorateNulls
          columnsOfInterest={[
            buildDataBrowserColumn({ col: "_id", alias: "Record Id" }),
            buildDataBrowserColumn({ col: "times_timestamp", alias: "Time", modifier: dateTimeHandler }),
            buildDataBrowserColumn({ col: "flightstate_location_latitude", alias: "Latitude", modifier: roundingHandlerLong }),
            buildDataBrowserColumn({ col: "flightstate_location_longitude", alias: "Longitude", modifier: roundingHandlerLong }),
            buildDataBrowserColumn({ col: "phaseofflight_mavg10", alias: "Phase of Flight", modifier: humanReadableHandler }),
            buildDataBrowserColumn({ col: "final_agl", alias: "AGL (ft)", modifier: roundingHandlerDefault }),
            buildDataBrowserColumn({ col: "groundspeed_final_kt", alias: "Ground Speed (kts)", modifier: roundingHandlerDefault }),
            buildDataBrowserColumn({ col: "verticalspeed_final_fpm", alias: "Vertical Speed (f/m)", modifier: roundingHandlerDefault }),
            buildDataBrowserColumn({ col: "flightstate_position_roll", alias: "Roll (deg)", modifier: roundingHandlerDefault }),
            buildDataBrowserColumn({ col: "flightstate_position_pitch", alias: "Pitch (deg)", modifier: roundingHandlerDefault }),
            buildDataBrowserColumn({ col: "flightstate_rates_yawrate", alias: "Yaw Rate (deg/s)", modifier: roundingHandlerDefault }),
            buildDataBrowserColumn({ col: "rotors_mainrotortorque", alias: "Main Rotor Torque (%)", modifier: roundingHandlerDefault }),
            buildDataBrowserColumn({ col: "engines_computations_e1torque", alias: "Engine 1 Torque", modifier: roundingHandlerDefault }),
            buildDataBrowserColumn({ col: "engines_computations_e2torque", alias: "Engine 2 Torque", modifier: roundingHandlerDefault }),
          ]}
        />,
  };

  return (
    <>
      {/* Dashboard tab management functions - right aligned if display is flex and content is justified as flex-end */}
      <div style={styles.toolbarContainer}>
        <FlightIdBanner style={styles.toolbarBanner} />
        <div>
          <IconButton aria-label="save" onClick={onLayoutSave}>
            <Tooltip title="Save Dashboard Tab Layout" placement="top" arrow>
              <SaveIcon />
            </Tooltip>
          </IconButton>

          <IconButton aria-label="reset" onClick={onLayoutReset}>
            <Tooltip title="Reset Dashboard Tab Layout to Last Saved State" placement="top" arrow>
              <DashboardIcon />
            </Tooltip>
          </IconButton>

          <AddList
            items={items}
            onRemoveItem={onRemoveItem}
            onAddItem={onAddItem}
            originalItems={originalItems}
            dashboardKey={dataContext.FLIGHT_PROFILE_TAB_KEY}
            dashboardConfig={dataContext.flightMetricsDashboardConfig}
          />
        </div>
      </div>

      {/* <Toolbar
        onLayoutSave={onLayoutSave}
        AddListProps={{
          items: items,
          onRemoveItem: onRemoveItem,
          onAddItem: onAddItem,
          originalItems: originalItems,
          dashboardKey: dataContext.FLIGHT_PROFILE_TAB_KEY
        }}
      /> */}

      {/* Add controls here as needed */}

      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={60}
        width={width * (1 / dataContext.appScale)}
        draggableHandle=".drag-handle"
        onLayoutChange={onLayoutChange}
        transformScale={dataContext.appScale} // Required to adapt transform translation offset when dragging
      >
        {items.map((key) => (

          <div
            key={key}
            data-grid={{ w: 6, h: 8, x: 0, y: Infinity }}
          >
            <DashboardWidget
              id={key}
              name={flightMetricsDashboardConfig[key].name}
              className="drag-handle"
              onRemoveItem={onRemoveItem}
              fullscreenEnabled={false}
            >
              {componentList[key]}
            </DashboardWidget>
          </div>
        ))}
      </ResponsiveGridLayout>
    </>
  );
}

FlightProfileContent.propTypes = propTypes;
FlightProfileContent.defaultProps = defaultProps;

export default withSize({ refreshMode: "debounce", refreshRate: 60 })(FlightProfileContent);
