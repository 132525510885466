import React, { useContext } from "react";
import PropTypes from 'prop-types';
import { DataContext } from "./DataContext";
import { makeStyles } from '@mui/styles';
import Popover from "@mui/material/Popover";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Tooltip from "@mui/material/Tooltip";

const useStyles = makeStyles((theme) => ({
  popup: {
    padding: theme.spacing(2)
  }
}));

const propTypes = {
  items: PropTypes.array.isRequired,
  onRemoveItem: PropTypes.func,
  onAddItem: PropTypes.func,
  originalItems: PropTypes.array.isRequired,
  dashboardKey: PropTypes.string.isRequired,
  dashboardConfig: PropTypes.object.isRequired,
};

const defaultProps = {
  onRemoveItem: () => {},
  onAddItem: () => {},
};

function AddList({
  items,
  onRemoveItem,
  onAddItem,
  originalItems,
  dashboardKey,
  dashboardConfig,
}) {
  const classes = useStyles();
  const dataContext = useContext(DataContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChange = (e) => {
    e.stopPropagation();
    dataContext.preventUIBlocking();
    if (e.target.checked) {
      onAddItem(e.target.name);
    } else {
      onRemoveItem(e.target.name);
    }
  };

  return (
    <>
      <IconButton 
        aria-label="Manage Dashboard Tab Widgets" 
        onClick={handleClick} 
        aria-describedby={id}
      >
        <Tooltip title="Manage Dashboard Tab Widgets" placement="top" arrow>
          <AddCircleOutlineIcon />
        </Tooltip>
      </IconButton>
      {/* Only display popup selection if dashboard widgets exist for active tab */}
      {originalItems.length > 0 && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <div className={classes.popup}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Select Widgets</FormLabel>
              <FormGroup>
                {originalItems.map((i) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={items.includes(i)}
                        onChange={handleChange}
                        onClick={e => e.stopPropagation()}
                        name={i}
                      />
                    }
                    label={dashboardConfig[dashboardKey][i].name}
                    key={i}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </div>
        </Popover>
      )}
    </>
  );
}

AddList.propTypes = propTypes;
AddList.defaultProps = defaultProps;

export default AddList;
