import React, { useState, useContext } from "react";
import { DataContext } from "../DataContext";
// import PropTypes from 'prop-types';
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { withSize } from "react-sizeme";
import DashboardWidget from "../DashboardWidget";
import LoadingSkeleton from "../utils/LoadingSkeleton";
import { IconButton, Tooltip } from "@mui/material";
import AddList from "../AddList";
import SaveIcon from '@mui/icons-material/Save';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ApproachFlight3DMap from "../maps/ApproachFlight3DMap";
import UasSightingsVectorTilesMap from "../maps/UasSightingsVectorTilesMap";
// import ApproachAngleBarChart from "../charts/approach/ApproachAngleBarChart";
import ApproachAnalysisBarChart from "../charts/approach/ApproachAnalysisBarChart";
import DataBrowser, { buildDataBrowserColumn } from "../misc/DataBrowser";
import BigNumber from "../misc/BigNumber";
// import UasRiskMap from "../maps/UasRiskMap";
import ApproachControls from "../controls/ApproachControls";


// Need to import styles for core functionality
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const propTypes = {};
const defaultProps = {};

const styles = {
  toolbarContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  toolbarBanner: {
    marginRight: "auto",
  },
};

// NOTE: Don't use global mode on a regex object if it is meant to be reused
// See: https://medium.com/codesnips/js-careful-when-reusing-regex-636b92c6bf07
const gridRegex = /(?<apt>[a-zA-Z]+)(?<grid>[0-9]+)/m; // NOTE: named group syntax is ?<group-name>

function ApproachAggregateContent({ size: { width } }) {
  const SAVED_LAYOUTS_KEY = "UasRiskAnalysisLayout";

  const dataContext = useContext(DataContext);
  
  const approachFlightsMetricsDashboardConfig = dataContext.approachFlightsMetricsDashboardConfig[dataContext.APPROACH_FLIGTHS_TAB_KEY];
  
  // console.log(approachFlightsMetricsDashboardConfig)

  const originalItems = Object.keys(approachFlightsMetricsDashboardConfig);

  const initialLayouts = {
    lg: originalItems.map(item => approachFlightsMetricsDashboardConfig[item])
  };

  const loadedLayouts = dataContext.getFromLS(SAVED_LAYOUTS_KEY) || initialLayouts;
  const [layouts, setLayouts] = useState(
    loadedLayouts
  );

  // Initial set of widgets will be based on saved layouts data (defaults to initial layouts if no saved data found)
  const [items, setItems] = useState(loadedLayouts.lg.map(item => item.i));

  const onLayoutChange = (_, allLayouts) => {
    setLayouts(allLayouts);
  };

  const onLayoutSave = (e) => {
    e.stopPropagation();
    dataContext.saveToLS(SAVED_LAYOUTS_KEY, layouts);

    // Show snackbar notification on save
    dataContext.showSnack("top", "center", "Dashboard Layout Saved for UAS Risk Analysis!", dataContext.SNACK_SEVERITY.SUCCESS, 2000);
  };

  const onLayoutReset = (e) => {
    e.stopPropagation();
    const newLoadedLayouts = dataContext.getFromLS(SAVED_LAYOUTS_KEY) || initialLayouts;
    setLayouts(newLoadedLayouts);
    setItems(newLoadedLayouts.lg.map(item => item.i));
    dataContext.showSnack("top", "center", "Dashboard Layout Reset to Last Saved State for UAS Risk Analysis!", dataContext.SNACK_SEVERITY.SUCCESS, 2000);
  }

  const onRemoveItem = (itemId) => {
    // console.log(`Removing ${itemId} from the dashboard...`);
    setItems(items.filter((i) => i !== itemId));
  };

  const onAddItem = (itemId) => {
    setItems([...items, itemId]);
  };

  /**
   * Handle DataBrowser row selection event for drilling down on data record.
   * 
   * @param {string} key Key associated with target dataset for drilldown.
   * @param {any} value Value associated with key mapping in dataset for drilldown.
   */
  const handleRowSelected = (key, value) => {
    // Build data structure that imitates control filter
    // EXAMPLE:
    // {
    //   "phaseOfFlightSelect": {
    //     "id": "phaseOfFlightSelect",
    //     "label": "Standing, Hover Ground Effect",
    //     "column": "phaseofflight_mavg10",
    //     "values": [
    //       "standing",
    //       "hover ground effect"
    //      ]
    //    }
    // }    
    // console.log("Handling drilldown for selected key-value:", [key, value]);

    let filter = {
      [dataContext.UAS_RISK_ANALYSIS_CONTENT_SELECT_KEY]: {
        id: dataContext.UAS_RISK_ANALYSIS_CONTENT_SELECT_KEY,
        label: value,
        column: key,
        values: [value],
        track: true,
      }
    };

    dataContext.handleControlFilter(filter);
  }

  const roundingHandlerDefault = (data) => {
    return dataContext.roundStr(data);
  }

  const roundingHandlerLong = (data) => {
    return dataContext.roundStr(data, 6);
  }

  const humanReadableHandler = (data) => {
    return dataContext.capitalizeWords(data);
  }

  // Core widgets list
  const componentList = {
    approachFlight3DMap:
      dataContext.processing ? <LoadingSkeleton /> :
        <ApproachFlight3DMap
          mapId="approachFlight3DMap"
          startLoc={[-95.71, 37.09]} // BOS airport
          zoom={3.6}
        />,
    approachAngleBarChart:
      dataContext.processing ? <LoadingSkeleton /> :
         <ApproachAnalysisBarChart
           analysisIndicator = "approach_mean_angle_deg"
           analysisIndicatorUnit = "deg"
        />,
    approachBankBarChart:
      dataContext.processing ? <LoadingSkeleton /> :
        <ApproachAnalysisBarChart
           analysisIndicator = "approach_mean_bank_deg"
           analysisIndicatorUnit = "deg"
        />,
    approachPitchBarChart:
      dataContext.processing ? <LoadingSkeleton /> :
         <ApproachAnalysisBarChart
           analysisIndicator = "approach_mean_pitch_deg"
           analysisIndicatorUnit = "deg"
        />,
    approachGroundTrackDifferenceBarChart:
      dataContext.processing ? <LoadingSkeleton /> :
         <ApproachAnalysisBarChart
           analysisIndicator = "approach_mean_groundtrack_difference_deg"
           analysisIndicatorUnit = "deg"
        />,
    approachVertspeedBarChart:
      dataContext.processing ? <LoadingSkeleton /> :
         <ApproachAnalysisBarChart
           analysisIndicator = "approach_mean_vertspeed_fpm"
           analysisIndicatorUnit = "ft/m"
        />,
    approachAirspeedBarChart:
      dataContext.processing ? <LoadingSkeleton /> :
         <ApproachAnalysisBarChart
           analysisIndicator = "approach_mean_airspeed_kts"
           analysisIndicatorUnit = "kts"
        />,
    approachTorquetotalBarChart:
      dataContext.processing ? <LoadingSkeleton /> :
         <ApproachAnalysisBarChart
           analysisIndicator = "approach_mean_torque_total_pct"
           analysisIndicatorUnit = "%"
        />
  };

  return (
    <>
      {/* Dashboard tab management functions - right aligned if display is flex and content is justified as flex-end */}
      <div style={styles.toolbarContainer}>
        <div>
          <IconButton aria-label="save" onClick={onLayoutSave}>
            <Tooltip title="Save Dashboard Tab Layout" placement="top" arrow>
              <SaveIcon />
            </Tooltip>
          </IconButton>

          <IconButton aria-label="reset" onClick={onLayoutReset}>
            <Tooltip title="Reset Dashboard Tab Layout to Last Saved State" placement="top" arrow>
              <DashboardIcon />
            </Tooltip>
          </IconButton>

          <AddList
            items={items}
            onRemoveItem={onRemoveItem}
            onAddItem={onAddItem}
            originalItems={originalItems}
            dashboardKey={dataContext.APPROACH_FLIGTHS_TAB_KEY}
            dashboardConfig={dataContext.approachFlightsMetricsDashboardConfig}
          />
        </div>
      </div>

      {/* Add controls here as needed */}
      <ApproachControls />

      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={60}
        width={width * (1 / dataContext.appScale)}
        draggableHandle=".drag-handle"
        onLayoutChange={onLayoutChange}
        transformScale={dataContext.appScale} // Required to adapt transform translation offset when dragging
      >
        {items.map((key) => (

          <div
            key={key}
            data-grid={{ w: 6, h: 8, x: 0, y: Infinity }}
          >
            <DashboardWidget
              id={key}
              name={approachFlightsMetricsDashboardConfig[key].name}
              className="drag-handle"
              onRemoveItem={onRemoveItem}
              fullscreenEnabled={false}
            >
              {componentList[key]}
            </DashboardWidget>
          </div>
        ))}
      </ResponsiveGridLayout>
    </>
  );
}

ApproachAggregateContent.propTypes = propTypes;
ApproachAggregateContent.defaultProps = defaultProps;

export default withSize({ refreshMode: "debounce", refreshRate: 60 })(ApproachAggregateContent);
