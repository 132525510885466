import React, { useContext, useEffect, useState } from 'react'

import getDashboardById from '../services/getDashboardById'
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk'
import UserContext from './UserContext';
import { Col } from 'react-bootstrap';

function DashboardWidgets(props) {

    const [url, setUrl] = useState({})
    const [isLoaded, setIsLoaded] = useState(false)
    // const [error, setError] = useState( {})
    const [loadedAggregate, setLoadedAggregate] = useState(false)
    const dashName = props.dashboardType + "dashboardContainer"

    const contextType = useContext(UserContext)

    useEffect(() => {
        let isMounted = true;
        if(isMounted){
        getDashboardById(contextType.user, props.dashboardType, true, true)
        .then((results) => {
            if ('EmbedUrl' in results && isMounted) {
                setUrl(results.EmbedUrl);
                setIsLoaded(true);
                
            }
        })
        .catch((error) => {
            // setError(error)
            console.log(error)
        })
    }
    return () => {
        isMounted = false;
    }
    }
    , [])
    
    useEffect(() => {
        if(isLoaded && !loadedAggregate) {
            getQuicksightDashboardEmbedURL(url)
            setLoadedAggregate(true);
        }
    }, [isLoaded, loadedAggregate])

    

    const getQuicksightDashboardEmbedURL = (embedUrl) => {
        // console.log("Rendering Dashboard")
        const containerDiv = document.getElementById(dashName);

         //clear any existing embeds so we can embed a new one and not duplicate
         while (containerDiv.lastChild){
            // console.log("Remove a child comp")
            containerDiv.removeChild(containerDiv.lastChild)
        }

        const options = {
            url: embedUrl,
            container: containerDiv,
            // parameters: {
            //     flightid : "FODR::1538745771.0:1538751766.0"
            // },
            scrolling: "no",
            iframeResizeOnSheetChange : true,
            // width: "960px",
            height: "1000px",
            undoRedoDisabled: true,
            resetDisabled: true,
            footerPaddingEnabled: true,
        };
    
      // console.log({options})
        const dashboard = QuickSightEmbedding.embedDashboard(options);
    }

   
        


        return (
            <>
            {props.dashboardTitle ?
            <Col md="auto">
                <h4 style={{minWidth:"200px",alignSelf:"center"}}>{props.dashboardTitle}</h4>
                </Col>
            : <></>}
                <Col>
                <div id={dashName}></div>
                </Col>
            </>
        )
    
}
export default DashboardWidgets
