import React, {useState, useEffect, useContext} from 'react'
import UserContext from '../components/UserContext'

import getDashboardById from '../services/getDashboardById'
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk'

import { Link } from 'react-router-dom';

const DQMetrics = (props) => {

    const [state, setState] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    // const [error, setError] = useState([])
    // const [flightId, setFlightId] = useState([])
    const [lastLoadedId, setLastLoadedId] = useState();
    // const flight = useParams()
    const propFlight = props.flight
    // console.log({props})

    const userContext = useContext(UserContext) 

    useEffect(() => {
        // console.log({flight})
        // setFlightId(flight.id)
        
        // console.log(props.flight.flightmetadata__id)
        
        //dashboardName = ?

        
        

        getDashboardById(userContext.user, "DQDashboardID", true, true)
        .then((result) => {
            if ('EmbedUrl' in result) {
                setState(result.EmbedUrl)
                setIsLoaded(true)
            }
        })
        .catch((error) => {
          // console.log(error)
            // setError(error)
        })
    }, [])

    // useEffect(() => {
        
    // }, [propFlight])

    const getQuicksightDashboardEmbedURL = (embedUrl) => {
        // console.log("In embed method")
        const containerDiv = document.getElementById("dashboardContainer");
        //clear any existing embeds so we can embed a new one and not duplicate
        while (containerDiv.lastChild){
            // console.log("Remove a child comp")
            containerDiv.removeChild(containerDiv.lastChild)
        }

        const options = {
            url: embedUrl,
            container: containerDiv,
            parameters: {
                flightid : props.flight? props.flight.flightmetadata__id : ''
            },
            scrolling: "no",
            // height: "700px",
            height: "AutoFit",
            width: "100%",
            footerPaddingEnabled: true,
        };
    
        const dashboard = QuickSightEmbedding.embedDashboard(options);
        
      // console.log(dashboard)
    }

    if(isLoaded && propFlight && propFlight.flightmetadata__id !== lastLoadedId) {
        getQuicksightDashboardEmbedURL(state)
        setLastLoadedId(propFlight.flightmetadata__id)
    }



    return (
        <>
            {/* <DashboardNav /> */}
            <Link to="#" style={{ marginLeft: '16px', marginRight: '16px', fontSize:"1rem" }} onClick={props.handleBackToGeoSearch}>Back to Flights</Link>
            <div className="stretch_grid-container">
            {props.flight ?
            <div>
            {/* <div style={{display:'inline-flex'}}>
                <div style={{paddingRight:'60px'}}>
                    <h6>Viewing Tail#: {props.flight.flightmetadata_rotorcraft_aircraftregistration} 
                        <br /> Date: {props.flight.flightmetadata_startisodate.split(" ")[0]}
                    </h6>
                </div>
                <div style={{paddingRight:'60px'}}>
                    <h6> Start Time: {props.flight.flightmetadata_startisodate.split(" ")[1].split(".")[0]}
                        <br /> End Time:{props.flight.flightmetadata_endisodate.split(" ")[1].split(".")[0]}
                    </h6>
                </div>
                <div>
                    <h6>  Duration (seconds):{props.flight.flightmetadata_durationsec} 
                    </h6>
                </div>
                
            </div> */}
                <div id="dashboardContainer" className="embedContainerHeight"></div>
            </div>
            : 
            <div>
            </div> }
            </div>
        </>
    )
}

export default DQMetrics
