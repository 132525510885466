import {API} from "aws-amplify";

export async function getUserProfile(user) {
// console.log({user})
    const token = user.signInUserSession.idToken.jwtToken;
    const requestInfo = {
      headers: {
        Authorization: token
      },
      body: {
        'username': user.attributes.email,
      }
    }
    // console.log({token})


    const data = await API.post('raise-gui-api-dev', "/get-user-profile", requestInfo)
    let nameSplit = data.jsonAttributes.name.split(" ")
          data.jsonAttributes['firstName'] = nameSplit[0]
          data.jsonAttributes['lastName'] = nameSplit[1]
  // console.log({data})
    return data;
}

export default getUserProfile;