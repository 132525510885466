import React from 'react'
import { css } from 'glamor'
import { Auth } from 'aws-amplify'
import UserContext from '../components/UserContext'
import UnauthNav from '../components/UnauthNav';

class ForgotPassword extends React.Component {
  state = {
    username: '',
    confirmationCode: '',
    password: '',
    showConfirmation: false
  }
  static contextType = UserContext
  onChange = (key, value) => {
    this.setState({
      [key]: value
    })
  }
  forgotPassword = () => {
  // console.log(console.log(this.state.username))
    Auth.forgotPassword(this.state.username)
      .then((results) => {
      // console.log(results)
        this.setState({ showConfirmation: true })
      })
      .catch(err => console.log('error: ', err))
  }
  forgotPasswordSubmit = () => {
    const { username, password, confirmationCode } = this.state
    Auth.forgotPasswordSubmit(username, confirmationCode, password)
      .then(() => {
        alert('successfully changed password!')
        this.props.history.push('/login')
      })
      .catch(err => console.log('error resetting password:', err))
  }
  render() {
    return (
      <div {...css(styles.container)}>
      <UnauthNav />
      <br />
        {
          !this.state.showConfirmation && (
            <div {...css(styles.formContainer)}>
              <h2 {...css(styles.signInHeader)}>Forgot Password?</h2>
              <p>Enter your username below.  We'll send a code to your email that will allow you to reset your password.</p>
              <input
                onChange={evt => this.onChange('username', evt.target.value)}
                {...css(styles.input)}
                placeholder='username'
                
              />
              <div {...css(styles.button)} onClick={this.forgotPassword}>
                <p {...css(styles.buttonText)}>Get Authentication Code</p>
              </div>
            </div>
          )
        }
        {
          this.state.showConfirmation && (
            <div {...css(styles.formContainer)}>
            <p>We've sent a confirmation code to your email address, enter that below along with your new password.  If you cannot find the code, it may be caught in spam or junk mail filters.  If you continue having problems, please contact the admins at <a href="mailto:ContactUs@asias.info">ContactUs@asias.info</a></p>
              <input
                onChange={evt => this.onChange('confirmationCode', evt.target.value)}
                {...css(styles.input)}
                autoComplete="new-password"
                placeholder='Confirmation Code'
              />
              <input
                onChange={evt => this.onChange('password', evt.target.value)}
                {...css(styles.input)}
                type='password'
                autoComplete="new-password"
                placeholder='New Password'
              />
              <div {...css(styles.button)} onClick={this.forgotPasswordSubmit}>
                <p {...css(styles.buttonText)}>Reset Password</p>
              </div>
            </div>
          )
        }
      </div>
    )
  }
}

const styles = {
  signInHeader: {
    textAlign: 'left',
    margin: '0px 0px 20px'
  },
  button: {
    padding: '10px 60px',
    backgroundColor: '#005EA2',
    marginTop: 10,
    marginBottom: 10,
    cursor: 'pointer',
    borderRadius: '4px',
    ':hover': {
      backgroundColor: '#092942'
    }
  },
  buttonText: {
    margin: 0,
    textAlign: 'center',
    color: 'white'
  },
  input: {
    height: 40,
    marginBottom: '10px',
    border: 'none',
    outline: 'none',
    borderBottom: '1px solid #005EA2',
    paddingBottom: '10px',
    fontSize: '16px',
    '::placeholder': {
      color: 'rgba(0, 0, 0, .3)'
    }
  },
  container: {
    flex: 1,
    paddingTop: '15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  formContainer: {
    padding: 20,
    width: 400,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: "0px 0px 2px rgba(0, 0, 0, .2)",
    borderRadius: 20
  }
}

export default ForgotPassword
