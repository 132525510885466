import React, { useState, useContext, useRef, useEffect } from 'react';
import { DataContext } from '../DataContext';
import PropTypes from 'prop-types';
import { styled, useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import LabeledLinearProgress from '../utils/LabeledLinearProgress';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Badge from '@mui/material/Badge';
import SearchBar from '../utils/SearchBar';
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Menu from '@mui/material/Menu';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { Typography } from '@mui/material';

const ROWS_PER_PAGE = [5, 10, 25, 50, 100];

const propTypes = {
  /** 
   * Primary data source key.
   * 
   * Default: "track_points"
   * */
  primaryTargetKey: PropTypes.string,

  /** 
   * Secondary data source key to fall back on if primary data source doesn't exist. 
   * */
  secondaryTargetKey: PropTypes.string,

  /** 
   * Primary column to target for searching and drilldowns. Defaults to first column in columns of interest list. 
   * */
  primaryCol: PropTypes.string,

  /**
   * Metric column to target for aggregations. Defaults to primary column if not specified when calculating aggregations.
   */
  metricCol: PropTypes.string,

  /** 
   * List of column configurations following the format specified in the buildDataBrowserColumn component. 
   * 
   * Default: []
   * */
  columnsOfInterest: PropTypes.arrayOf(PropTypes.object),

  /** 
   * Default number of rows to display per page. 
   * 
   * Default: 10
   * */
  defaultRowsPerPage: PropTypes.number,

  /** 
   * Whether to provide a search bar to search on primary column. 
   * 
   * Default: false
   * */
  enableSearch: PropTypes.bool,

  /** 
   * Callback function to be implemented for listening to row selection events. 
   * Implemented function should follow the format of:
   * 
   * (key, value) => { ... }
   * 
   * key   - target dataset for drilldown.
   * 
   * value - the selected row primary col/cell value.
   * */
  onRowSelected: PropTypes.func,

  /**
   * Callback function to be implemented for listening to row hover events.
   * Implemented function should follow the format of:
   * 
   * (key, value) => { ... }
   * 
   * key   - target dataset for drilldown.
   * 
   * value - the selected row primary col/cell value.
   */
  onRowHovered: PropTypes.func,

  /**
   * Whether to build the data browser component as a grouped dataset, performing
   * a grouped count on the primary column and creating a new column to contain the resulting counts.
   * 
   * Default: false
   */
  groupByCount: PropTypes.bool,

  /**
   * Alias associated with the logical grouping.
   */
  groupByAlias: PropTypes.string,

  /**
   * Alias associated with the logical grouping. Same functionality as groupByAlias.
   */
  metricAlias: PropTypes.string,

  /**
   * Whether to show a ranked bar for group counts to indicate order.
   * 
   * Default: false
   */
  groupByAsMetric: PropTypes.bool,

  /**
   * Apply aggregation to primary column and show a ranked bar to indicate order. 
   * This prop is a shorthanded approach for the groupByCount and groupByAsMetric
   * props and adds more ability to select from different aggregations.
   */
  metric: PropTypes.oneOf(['count', 'sum', 'average']),

  /**
   * Rounding precision for displaying aggregations. Defaults to 2 decimal places.
   */
  metricPrecision: PropTypes.number,

  /**
   * Default sorting order for the count column associated with the grouping results.
   */
  groupByDefaultOrder: PropTypes.string,

  /**
   * Whether to remove null values in grouped count results.
   * 
   * Default: false
   */
  removeGroupByNulls: PropTypes.bool,

  /**
   * Value to put in place of null values or empty strings.
   * 
   * Default: ""
   */
  nullPlaceholder: PropTypes.string,

  /**
   * Whether to italicize null values to make them stand out.
   * 
   * Default: false
   */
  decorateNulls: PropTypes.bool,

  /**
   * Optional filter function used to filter out records when return value is false.
   * 
   * Example:
   * ```
   * // Only show records where exceedance type is Loss of Control (LOC)
   * filter={(row, lookup) => { 
   *   let val = row[lookup.exceedance_type] || "NONE";
   *   val = val.toUpperCase();
   *   return val === "LOSS OF CONTROL" || val === "NONE";
   * }}
   * ```
   */
  filter: PropTypes.func,

  /** 
   * Wether to display the options menu on column hover
   */
  enableHeaderOptions: PropTypes.bool,

  /**
   * Whether to use styling for embed into a widget or embed into a div
   */

  isDivContainer: PropTypes.bool,

  /**
   * Custom function for handling specific cell click from row click
   */

  onCellClick: PropTypes.func,

  /** 
  * Whether to display the column by default
  */
  hideCol: PropTypes.bool,

  /** 
  * Whether to persist row selection or not
  */
  persistRowSelection: PropTypes.bool,

  /**
   * Message to display when there's no records to display in data browser.
   * Defaults to "No Data"
   */
  noDataMessage: PropTypes.string,
};

const defaultProps = {
  primaryTargetKey: "track_points",
  columnsOfInterest: [],
  defaultRowsPerPage: ROWS_PER_PAGE[1],
  enableSearch: false,
  primaryCol: null,
  metricCol: null,
  onRowSelected: () => { },
  onRowHovered: () => { },
  groupByCount: false,
  groupByAlias: null,
  metricAlias: null,
  groupByAsMetric: false,
  metric: null,
  metricPrecision: 2,
  groupByDefaultOrder: null,
  removeGroupByNulls: false,
  nullPlaceholder: "NULL",
  decorateNulls: false,
  filter: () => true,
  enableHeaderOptions: false,
  isDivContainer: false,
  onCellClick: undefined,
  persistRowSelection: true,
  noDataMessage: "No Data",
};

// Helper function used to ensure the correct format for data browser columns
export function buildDataBrowserColumn({
  col = null,
  alias = null,
  sortingEnabled = true,
  modifier = null,
  isMetric = false,
  width = null,
  height = null,
  defaultOrder = null,
  customSortingComparator = null,
  colorFn = null,
  textStyle = {},
  filterValue = '',
  enableHeaderOptions,
  onCellClick,
  persistRowSelection,
  hideCol,
}) {
  return {
    col,
    alias,
    sortingEnabled,
    modifier,
    isMetric,
    width,
    height,
    defaultOrder,
    customSortingComparator,
    colorFn,
    textStyle,
    filterValue,
    enableHeaderOptions,
    onCellClick,
    persistRowSelection,
    hideCol,
  };
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&:hover': {
    backgroundColor: "#c9c9c9 !important",
    cursor: "pointer",
  },
}));

function EnhancedTableSortIcon(props) {
  const { sortDirection, tally, showBadge, hovered, active } = props;

  const theme = createTheme({
    palette: {
      neutral: {
        main: '#64748B',
        contrastText: '#fff',
      },
      transparent: {
        main: 'transparent',
        contrastText: 'transparent'
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <Badge
        badgeContent={showBadge ? tally : 0}
        color="neutral"
      >
        {(sortDirection === "asc" || sortDirection === false) ? (
          <ArrowUpwardIcon color={active || hovered ? "default" : "transparent"} />
        ) : (
          <ArrowDownwardIcon color={active || hovered ? "default" : "transparent"} />
        )}
      </Badge>
    </ThemeProvider>
  );
}

EnhancedTableSortIcon.propTypes = {
  direction: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  tally: PropTypes.number,
  showBadge: PropTypes.bool,
  hovered: PropTypes.bool,
  active: PropTypes.bool,
};

EnhancedTableSortIcon.defaultProps = {
  direction: 'asc',
  tally: 1,
  showBadge: true,
  hovered: false,
  active: false,
};

function EnhancedTableHead(props) {
  const {
    columnsOfInterest,
    orderLookup,
    orderByList,
    onRequestSort,
    handleFilterRequest,
    style,
    columnVisibility,
    handleVisToggle
  } = props;

  const [hover, setHover] = useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuOpen, setMenuOpen] = useState(null)

  const open = Boolean(menuOpen)
  const handleMenuClick = (event, colName) => {
    setMenuOpen(colName)
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setMenuOpen(null);
    setAnchorEl(null);
  };

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columnsOfInterest.map((item, index) => (
          <StyledTableCell
            key={item.col}
            style={(columnVisibility.find(element => element.col === item.col) || { 'visible': true })['visible'] ? {
              fontWeight: 'bold',
              ...style,
              width: item.width,
              height: item.height || 45,
            } : { display: 'none' }}
            sortDirection={item.col in orderLookup ? orderLookup[item.col].direction : false}
          ><div style={{ minWidth: "200" }}
            onMouseOver={(event) => {
              // console.log(item.col)
              // console.log(event)
              // console.log(`Hovered on ${item.col}`);
              setHover(item.col);
            }}
            onMouseLeave={() => {
              // console.log(`Hovered off ${item.col}`);
              setHover("");
            }}>
              <TableSortLabel

                active={item.col in orderLookup}
                direction={item.col in orderLookup ? orderLookup[item.col].direction : 'asc'}
                onClick={createSortHandler(item.col)}
                IconComponent={
                  () => <EnhancedTableSortIcon
                    tally={
                      // Use calculated ordering for multi-sorting capability (when tally = 0, no badge is displayed)
                      item.col in orderLookup ? orderByList.findIndex(entry => entry.id === orderLookup[item.col].id) + 1 : 0
                    }
                    showBadge={Object.keys(orderLookup).length > 1}
                    hovered={hover === item.col}
                    active={item.col in orderLookup}
                    sortDirection={item.col in orderLookup ? orderLookup[item.col].direction : false}
                  />
                }
              >
                {item.alias ? item.alias : item.col}
                {item.col in orderLookup ? (
                  <Box component="span" sx={visuallyHidden}>
                    {orderLookup[item.col].direction === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}

              </TableSortLabel>
              {((hover === item.col || menuOpen === item.col) && item.enableHeaderOptions) &&
                <IconButton
                  style={{ position: "absolute" }}
                  onClick={(e) => handleMenuClick(e, item.col)}
                  aria-label="open column menu"
                  size='small'
                >
                  <MoreVertIcon fontSize='small' />
                </IconButton>
              }
            </div>
            <Menu
              id={"colOptions" + item.col}
              open={open && menuOpen === item.col}
              anchorEl={anchorEl}
              container={document.fullscreenElement ? document.fullscreenElement : document.body}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              onClose={(e) => handleClose()}
            >
              <SearchBar
                style={{
                  // position: "fixed", 
                  // top: 0,
                  margin: 2,
                }}
                value={item.filterValue}
                placeholder={"Filter"}
                onChange={(filterVal) => {
                  item.filterValue = filterVal
                  handleFilterRequest(item.col, filterVal)
                }}
                onCancelSearch={(filterVal) => {
                  item.filterValue = ''
                  handleFilterRequest(item.col, '')
                }}
              />
              {/* <MenuItem onClick={handleClose}>Sort ascending</MenuItem>
              <MenuItem onClick={handleClose}>Sort descending</MenuItem>
              <MenuItem onClick={() => handleFilterRequest(item.col,"s76d1")}>Filter {item.col}</MenuItem> */}
              <FormControlLabel
                key={'headCheckboxFL' + item.col}
                control={
                  <Checkbox
                    key={'headCheckbox' + item.col}
                    checked={!item['hideCol']}
                    onClick={(evt) => {
                      handleClose()
                      handleVisToggle(evt.target.checked, item.col)
                    }}
                  />}
                label={'Remove ' + (item.alias ? item.alias : item.col)}
              />
            </Menu>

          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  columnsOfInterest: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRequestSort: PropTypes.func.isRequired,
  orderLookup: PropTypes.object.isRequired,
  orderByList: PropTypes.array.isRequired,
  handleFilterRequest: PropTypes.func.isRequired,
  style: PropTypes.object,
};

EnhancedTableHead.defaultProps = {
  style: {},
}

const styles = {
  container: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: "auto",
    width: "100%",
    whiteSpace: "nowrap",
    marginTop: 50,
    marginBottom: 20,
  },
  divBased: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: "auto",
    width: "100%",
    whiteSpace: "nowrap",
    marginBottom: 10,
  }
};

const DEFAULT_AGG_BAR_COLOR = "#938FC3"; // #938FC3 = purple, #9FDEF1 = blue

function DataBrowser(props) {
  const {
    primaryTargetKey,
    secondaryTargetKey,
    defaultRowsPerPage,
    columnsOfInterest,
    enableSearch,
    primaryCol,
    metricCol,
    onRowSelected,
    onRowHovered,
    groupByCount,
    groupByAlias,
    metricAlias,
    groupByAsMetric,
    metric,
    metricPrecision,
    groupByDefaultOrder,
    removeGroupByNulls,
    nullPlaceholder,
    decorateNulls,
    filter,
    isDivContainer,
    persistRowSelection,
    noDataMessage,
  } = props;

  const dataContext = useContext(DataContext);
  const theme = createTheme({
    palette: {
      mode: dataContext.darkMode ? "dark" : "light"
    },
    components: {
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: "#c9c9c9 !important"
            },
          }
        }
      }
    },
  });

  const targetData = dataContext.filteredData ? dataContext.filteredData : dataContext.baseData;
  const primaryDataExists = targetData && targetData[primaryTargetKey] && targetData[primaryTargetKey].data && targetData[primaryTargetKey].data.length > 0;
  const secondaryDataExists = targetData && targetData[secondaryTargetKey] && targetData[secondaryTargetKey].data && targetData[secondaryTargetKey].data.length > 0;
  const valid = primaryDataExists || secondaryDataExists;
  const useableTargetKey = primaryDataExists ? primaryTargetKey : secondaryTargetKey;
  const useableTarget = targetData && targetData[useableTargetKey];
  const lookup = valid && useableTarget ? useableTarget.lookup : null;
  const data = valid && useableTarget ? useableTarget.data : null;

  const [primaryColIdx, setPrimaryColIdx] = useState(0);
  const [evaluatedPrimaryCol, setEvaluatedPrimaryCol] = useState(primaryCol);
  const colComparatorLookup = useRef({});
  const [columnVisibility, setColumnVisibility] = useState({});

  const minMaxColsRef = useRef({});
  const columnsOfInterestRef = useRef([...columnsOfInterest]);
  const customData = useRef(null);
  const groupedData = useRef(null);
  const targetLookup = useRef({ ...lookup });
  const activeFilters = useRef([]);

  const [orderLookup, setOrderLookup] = useState({});
  const [orderByList, setOrderByList] = useState([]);
  const [rows, setRows] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [searched, setSearched] = useState("");
  const [selectedRow, setSelectedRow] = useState("");
  const [openTableOptions, setOpenTableOptions] = useState(false);
  const [anchorEl, setAnchorEl] = useState()

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = valid && page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleRequestSearch = (searchedVal) => {
    const targetData = groupedData.current || customData.current;
    const filteredData = targetData.filter(row => {
      let keep = false;

      // Extract item via lookup and parse to string if not already a string
      let item = row[targetLookup.current[evaluatedPrimaryCol]];

      if (item !== null) {
        if (typeof (item) !== "string") {
          item = item.toString();
        }

        keep = item.toLowerCase().includes(searchedVal.toLowerCase());
      }

      return keep;
    });

    setRows(filteredData);
  }

  function upsertArray(array, element) {
    const i = array.findIndex(_elem => _elem.ident === element.ident)
    i > -1 ? array[i] = element : array.push(element)
  }

  const handleFilterRequest = (ident, searchVal) => {
    const targetData = groupedData.current || customData.current;
    // setActiveFilters(priorFilters => new Set([...priorFilters, {ident : searchVal}]))
    // activeFilters.current.map(element => {
    //   if(element.ident === ident){
    //     element = {ident, searchVal}
    //   }
    //   else {

    //   }
    // })
    upsertArray(activeFilters.current, { "ident": ident, "searchVal": searchVal })

    // activeFilters.current = activeFilters.current.map(el => (el.ident === ident ? [...el, {"ident": ident, "searchVal" : searchVal}] : el))
    // activeFilters.current = [...activeFilters.current, {"ident": "exceedance_total_max", "searchVal" : "3"}]
    // console.log(activeFilters.current)
    const filteredData = targetData.filter(row => {
      let keep = true;
      // console.log({row})
      // Extract item via lookup and parse to string if not already a string
      Array.from(activeFilters.current).map(filter => {
        let item = row[targetLookup.current[filter['ident']]];
        // console.log({item})
        if (item !== null) {
          if (typeof (item) !== "string") {
            item = item.toString();
          }

          keep = keep && item.toLowerCase().includes(filter['searchVal'].toLowerCase());


        }
      })
      return keep;
    });

    setRows(filteredData);
  }

  const handleVisToggle = (evt, column) => {
    console.log(evt)
    const newVisMap = columnVisibility.map(item => {
      if (item.col === column) {
        return { ...item, visible: evt }
      }
      return item;
    })

    setColumnVisibility(newVisMap)

    // evt ? setColumnVisibility(...columnVisibility, column) : setColumnVisibility(columnVisibility.filter(entry => entry.col != column.col))
  }

  const handleCancelSearch = () => {
    setSearched("");
    handleRequestSearch("");
  }

  const handleRequestSort = (event, property) => {
    event.stopPropagation();

    // Handle order toggle with null ref guard
    let newOrder = "asc";
    let newOrderLookup = { ...orderLookup };
    if (property in newOrderLookup) {
      if (newOrderLookup[property].direction === "asc") {
        newOrder = "desc";
      }
    }

    const lookupVal = targetLookup.current[property];
    const orderByEntry = { id: lookupVal, direction: newOrder, customSortingComparator: colComparatorLookup.current[property] };
    let newOrderByList;

    // Handle multisort
    if (event.shiftKey) {
      // console.log("Handling multisort request for", property);
      // If the select field is already in the multisort list, remove it before adding this one
      newOrderByList = orderByList.filter(entry => entry.id !== lookupVal);
      newOrderByList = [...newOrderByList, orderByEntry];
      newOrderLookup[property] = orderByEntry;
    } else {
      // Handle singlesort (reset multisort config)
      // console.log("Handling singlesort request for", property);
      newOrderByList = [orderByEntry];
      newOrderLookup = { [property]: orderByEntry };
    }

    // console.log(`Sorting by ${lookupVal}, ${newOrder}`);
    // console.log("New orderby list:", newOrderByList);
    // console.log("|-- Order lookup:", newOrderLookup);

    setOrderLookup(newOrderLookup);
    setOrderByList(newOrderByList);
  };

  // Args: event, newPage
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderCellContent = (row, item) => {
    const targetData = groupedData.current || customData.current;

    let text = "";
    let cellVal = "";

    if (item.col in targetLookup.current) {
      if (item.modifier) {
        text = item.modifier(row[targetLookup.current[item.col]]);
      } else {
        text = row[targetLookup.current[item.col]];

        if (text && text !== null && typeof (text) !== "string") {
          text = text.toString();
        }
      }
    }

    if (item.isMetric && typeof (row[targetLookup.current[item.col]]) === "number") {
      // Get min-max for column of interest
      if (!(item.col in minMaxColsRef.current)) {
        // console.log(`Getting min-max values for column, '${item.col}'`);
        const minMaxValues = dataContext.getMinMaxValues(targetData, targetLookup.current[item.col]);

        if (minMaxValues.min !== null && minMaxValues.max !== null) {
          minMaxColsRef.current[item.col] = minMaxValues;
          // console.log("Min-max values:", minMaxColsRef.current);
        }
      }

      if (item.col in minMaxColsRef.current) {
        // Set bar value for each column value relative to the max (computing rank)
        const { max } = minMaxColsRef.current[item.col];
        let rank = Math.floor((row[targetLookup.current[item.col]] / max) * 100);
        // console.log(text, rank);

        cellVal = <LabeledLinearProgress
          variant="determinate"
          value={rank}
          text={text}
          textStyle={{ color: dataContext.darkMode ? "#fff" : "#000", ...item.textStyle }}
          sx={{
            [`& .${linearProgressClasses.bar}`]: {
              // borderRadius: 5,
              backgroundColor: item.colorFn ? item.colorFn(text) : DEFAULT_AGG_BAR_COLOR,
            },
          }}
        />;
      }
    } else if (item.colorFn) {
      cellVal = <LabeledLinearProgress
        variant="determinate"
        value={100}
        text={text}
        textStyle={{ color: dataContext.darkMode ? "#fff" : "#000", ...item.textStyle }}
        sx={{
          [`& .${linearProgressClasses.bar}`]: {
            // borderRadius: 5,
            backgroundColor: item.colorFn(text),
          },
        }}
      />;
    } else {
      cellVal = text;
    }

    return cellVal;
  }

  const renderCellContentStyled = (row, item) => {
    let cellVal = renderCellContent(row, item);
    let retVal = cellVal;

    // If the cell value is null or an empty string and decorating nulls is desired then emphasize the null value
    if (cellVal === null || cellVal === "") {
      if (decorateNulls) {
        retVal = <em>{nullPlaceholder}</em>;
      }
    }

    return retVal;
  }

  const handleMenuClick = (event) => {
    setOpenTableOptions(true)
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (data) {
      customData.current = data.filter(row => filter(row, lookup));

      // Initialize target lookup to prevent persistent initialization between state changes
      targetLookup.current = { ...lookup };

      // Identify search column index. Default to the first column if not provided as a prop
      const targetCol = primaryCol || columnsOfInterestRef.current[0].col;
      const targetMetricCol = metricCol || targetCol;
      // console.log(`Data has changed for data browser with primary col = ${targetCol}`);

      let targetColIdx = columnsOfInterestRef.current.findIndex(entry => entry.col === targetCol);
      let targetMetricColIdx = columnsOfInterestRef.current.findIndex(entry => entry.col === targetMetricCol);
      let evaluatedTargetCol = targetColIdx !== -1 ? columnsOfInterestRef.current[targetColIdx].col : targetCol;
      let evaluatedTargetMetricCol = targetMetricColIdx !== -1 ? columnsOfInterestRef.current[targetMetricColIdx].col : targetMetricCol;

      setPrimaryColIdx(targetColIdx);
      setEvaluatedPrimaryCol(evaluatedTargetCol);

      let columnList = []
      columnsOfInterestRef.current.map((item) => {
        columnList.push({
          "alias": item.alias,
          "col": item.col,
          "visible": !item.hideCol
        })
      })
      // console.log(visibleColumns)
      setColumnVisibility(columnList)

      // New metric prop takes precedence if provided
      if (metric && typeof (metric) === "string") {
        let aggResult;

        switch (metric.toUpperCase()) {
          case "COUNT":
            aggResult = dataContext.groupByCount(customData.current, lookup[evaluatedTargetCol]);

            targetLookup.current = {
              [evaluatedTargetCol]: 0,
              "count": 1,
            };

            // console.log("Group By Count Result:", aggResult);
            groupedData.current = Object.entries(aggResult).reduce((previousValue, currentValue) => {
              const { value, count } = currentValue[1];

              // Determine whether to remove null values or not
              let allow = true;
              if (removeGroupByNulls) {
                allow = value !== null && value !== "";
              }

              if (allow) {
                previousValue.push([value, count]);
              }

              return previousValue;
            }, []).filter(row => filter(row, targetLookup.current));

            // console.log(`Grouped Data (by ${evaluatedTargetCol}):`, groupedData.current);

            // Add new count column of interest to ref by spread to prevent appending same grouped column
            columnsOfInterestRef.current = [
              ...columnsOfInterest,
              buildDataBrowserColumn({
                col: "count",
                alias: groupByAlias || metricAlias || "Count",
                modifier: (data) => dataContext.bigNumberFormatter(data, metricPrecision),
                isMetric: groupByAsMetric || typeof (metric) === "string",
                defaultOrder: groupByDefaultOrder,
              }),
            ];

            break;
          case "SUM":
            // TODO Implement handle for sum
            break;
          case "AVERAGE":
            aggResult = dataContext.groupByAgg(
              customData.current, {
              primaryIdx: lookup[evaluatedTargetCol],
              aggColIdx: lookup[evaluatedTargetMetricCol]
            }
            );

            targetLookup.current = {
              [evaluatedTargetCol]: 0,
              "average": 1,
            };

            // console.log("Group By Aggregation Result:", aggResult);
            groupedData.current = Object.entries(aggResult).reduce((previousValue, currentValue) => {
              const { value, average } = currentValue[1];

              // Determine whether to remove null values or not
              let allow = true;
              if (removeGroupByNulls) {
                allow = value !== null && value !== "";
              }

              if (allow) {
                previousValue.push([value, average]);
              }

              return previousValue;
            }, []).filter(row => filter(row, targetLookup.current));

            // console.log(`Grouped Data:`, groupedData.current);

            // Add new average column of interest to ref by spread to prevent appending same grouped column
            columnsOfInterestRef.current = [
              ...columnsOfInterest,
              buildDataBrowserColumn({
                col: "average",
                alias: groupByAlias || metricAlias || "Average",
                modifier: (data) => dataContext.bigNumberFormatter(data, metricPrecision),
                isMetric: groupByAsMetric || typeof (metric) === "string",
                defaultOrder: groupByDefaultOrder,
              }),
            ];

            break;
          default:
            break;
        }
      } else if (groupByCount) {
        let groupByCountResult = dataContext.groupByCount(customData.current, lookup[evaluatedTargetCol]);

        targetLookup.current = {
          [evaluatedTargetCol]: 0,
          "count": 1,
        };

        // console.log("Group By Count Result:", groupByCountResult);
        groupedData.current = Object.entries(groupByCountResult).reduce((previousValue, currentValue) => {
          const { value, count } = currentValue[1];

          // Determine whether to remove null values or not
          let allow = true;
          if (removeGroupByNulls) {
            allow = value !== null && value !== "";
          }

          if (allow) {
            previousValue.push([value, count]);
          }

          return previousValue;
        }, []).filter(row => filter(row, targetLookup.current));

        // console.log(`Grouped Data (by ${evaluatedTargetCol}):`, groupedData.current);

        // Add new count column of interest to ref by spread to prevent appending same grouped column
        columnsOfInterestRef.current = [
          ...columnsOfInterest,
          buildDataBrowserColumn({
            col: "count",
            alias: groupByAlias || metricAlias || "Count",
            modifier: (data) => dataContext.bigNumberFormatter(data, metricPrecision),
            isMetric: groupByAsMetric || typeof (metric) === "string",
            defaultOrder: groupByDefaultOrder,
          }),
        ];
      }

      // Build lookup for custom sorting comparator functions so they don't lose scope when sorting
      colComparatorLookup.current = columnsOfInterestRef.current.reduce((previousValue, currentValue) => {
        return { ...previousValue, [currentValue.col]: currentValue.customSortingComparator }
      }, {});

      setOrderLookup(
        columnsOfInterestRef.current.reduce((previousValue, currentValue) => {
          let retVal = previousValue;
          if (currentValue.defaultOrder !== null) {
            let lookupVal = targetLookup.current && currentValue.col && currentValue.col in targetLookup.current ? targetLookup.current[currentValue.col] : null;
            if (lookupVal !== null) {
              retVal = {
                ...previousValue,
                [currentValue.col]: {
                  id: lookupVal,
                  direction: currentValue.defaultOrder,
                  customSortingComparator: colComparatorLookup.current[currentValue.col]
                }
              };
            }
          }

          return retVal
        }, {})
      );

      // Contains objects with the following format:
      // { id: "col-name || col-idx", direction: "asc || desc" }
      // EX:
      setOrderByList(
        columnsOfInterestRef.current.reduce((previousValue, currentValue) => {
          let retVal = previousValue;

          if (currentValue.defaultOrder !== null) {
            let lookupVal = targetLookup.current && currentValue.col && currentValue.col in targetLookup.current ? targetLookup.current[currentValue.col] : null;
            if (lookupVal !== null) {
              retVal = [
                ...previousValue,
                {
                  id: lookupVal,
                  direction: currentValue.defaultOrder,
                  customSortingComparator: colComparatorLookup.current[currentValue.col]
                }
              ];
            }
          }

          return retVal;
        }, [])
      );

      // console.log("Data changed in DataBrowser:");
      // console.log("|-- Original data:", data);
      // console.log("|-- Custom data:", customData.current);
      // console.log("|-- Grouped data:", groupedData.current);
      // console.log("|-- Lookup:", lookup);
      // console.log("|-- Target Lookup:", targetLookup.current);

      // Set target dataset as grouped dataset if desired
      setRows(groupedData.current || customData.current);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <ThemeProvider theme={theme}>
      <Box>
        {(valid && rows !== null && rows.length >= 0) && (
          <TableContainer
            style={isDivContainer ? styles.divBased : styles.container}
          >
            {/* TODO Adjust styles of SearchBar to remain fixed while scrolling (without getting overlapped by other elements) */}
            {enableSearch && (
              <SearchBar
                style={{
                  // position: "fixed", 
                  // top: 0,
                  margin: 10,
                }}
                placeholder={primaryColIdx !== -1 ? `Search by ${columnsOfInterestRef.current[primaryColIdx].alias}` : "Search"}
                value={searched}
                onChange={(searchVal) => handleRequestSearch(searchVal)}
                onCancelSearch={() => handleCancelSearch()}
              />
            )}

            <div style={{ textAlignLast: "right" }} >
              <IconButton
                // ref={visibilityContainer}
                // style={{position:"absolute"}}
                onClick={(e) => handleMenuClick(e)}
                aria-label="open column menu"
                size='small'
              >
                <MoreVertIcon fontSize='small' />
              </IconButton>
              <Menu
                id={'colVisibilityToggles'}
                open={openTableOptions}
                container={document.fullscreenElement ? document.fullscreenElement : document.body}
                anchorReference='anchorEl'
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                onClose={(e) => {
                  setOpenTableOptions(false)
                  setAnchorEl(null)
                }}
              >
                {/* <SearchBar
                style={{
                  // position: "fixed", 
                  // top: 0,
                  margin: 2,
                }}
                value={item.filterValue}
                placeholder={"Filter"}
                onChange={(filterVal) => {
                  item.filterValue = filterVal
                  handleFilterRequest(item.col,filterVal)}}
                onCancelSearch={(filterVal) => {
                  item.filterValue = ''
                  handleFilterRequest(item.col,'')}}
              /> */}
                <FormGroup row={true}>
                  <FormLabel component="legend">Toggle Column Visibility</FormLabel>
                  {columnVisibility.map((item) => (
                    <FormControlLabel
                      key={'checkboxFL' + item.col}
                      control={
                        <Checkbox
                          key={'checkbox' + item.col}
                          checked={item['visible']}
                          onClick={(evt) => handleVisToggle(evt.target.checked, item.col)}
                        />}
                      label={item.alias ? item.alias : item.col}
                    />

                  ))}
                </FormGroup>
              </Menu>
            </div>
            <Table
              stickyHeader
              size="small"
              aria-label="data browser table"
            >
              <EnhancedTableHead
                columnsOfInterest={columnsOfInterestRef.current}
                // orderLookup={orderLookupRef.current}
                orderLookup={orderLookup}
                orderByList={orderByList}
                onRequestSort={handleRequestSort}
                handleFilterRequest={handleFilterRequest}
                handleVisToggle={handleVisToggle}
                style={{
                  background: dataContext.darkMode ? "#1E1E1E" : "#fff",
                  color: dataContext.darkMode ? "#fff" : "#000"
                }}
                columnVisibility={columnVisibility}
              />
              <TableBody>
                {rows.length === 0 && (
                    <StyledTableRow>
                      <StyledTableCell
                        style={{
                          width: "100%"
                        }}
                      >
                        {noDataMessage}
                      </StyledTableCell>
                    </StyledTableRow>
                )}
                {dataContext.orderBy(rows, false, ...orderByList)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, idx) => (
                    <StyledTableRow
                      key={`row-${idx + (page * rowsPerPage)}`}
                      style={{
                        height: '35px',
                      }}
                      hover
                      selected={selectedRow === `${row[targetLookup.current[evaluatedPrimaryCol]]}-${idx + (page * rowsPerPage)}`}
                      onClick={() => {
                        let rawId = row[targetLookup.current[evaluatedPrimaryCol]];
                        let selectedId = `${rawId}-${idx + (page * rowsPerPage)}`; // Flatten index from 2D to 1D (remains unique between pages)
                        // console.log("Processed Row ID:", selectedId);

                        selectedId = selectedRow === selectedId ? "" : selectedId; // Toggle selection
                        if (persistRowSelection) // Only persist row if user hasn't opted out
                          setSelectedRow(selectedId);

                        // Handle drilldown by selection (may change to using dataContext call rather than using prop - e.g., less work)
                        if (selectedId !== "") {
                          // console.log("Selected Row ID:", rawId);
                          onRowSelected(evaluatedPrimaryCol, rawId);
                        }
                      }}
                      onMouseOver={() => {
                        let rawId = row[targetLookup.current[evaluatedPrimaryCol]];
                        let hoveredId = `${rawId}-${idx + (page * rowsPerPage)}`; // Flatten index from 2D to 1D (remains unique between pages)
                        // console.log("Hovered Row ID:", hoveredId);

                        // Propagate hover event via prop callback function
                        if (hoveredId !== "") {
                          // console.log("Selected Row ID:", rawId);
                          onRowHovered(evaluatedPrimaryCol, rawId);
                        }
                      }}
                    >
                      {columnsOfInterestRef.current.map((item) => (
                        <StyledTableCell
                          onClick={item.onCellClick ? (event) => {
                            console.log("cell click listener")
                            event.stopPropagation();
                            let rawId = row[targetLookup.current[evaluatedPrimaryCol]];
                            let selectedId = `${rawId}-${idx + (page * rowsPerPage)}`; // Flatten index from 2D to 1D (remains unique between pages)
                            // console.log("Processed Row ID:", selectedId);
                            selectedId = selectedRow === selectedId ? "" : selectedId; // Toggle selection
                            if (persistRowSelection) // persist selection only if user hasn't opted out
                              setSelectedRow(selectedId);
                            item.onCellClick(event, rawId)
                          } : undefined}
                          key={item.col}
                          align="left"
                          style={(columnVisibility.find(element => element.col === item.col) || { 'visible': true })['visible'] ? {
                            width: item.width,
                            height: item.height,
                          } : { display: "none" }}
                        >
                          {renderCellContentStyled(row, item)}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  ))}

                {emptyRows > 0 && (
                  // Change height to (53 * emptyRows) if using regular size table
                  <StyledTableRow style={{ height: 33 * emptyRows }}>
                    <StyledTableCell colSpan={6} />
                  </StyledTableRow>
                )}
              </TableBody>
              <TableFooter
                style={{
                  width: '100%',
                  bottom: 0,
                  position: 'sticky',
                  background: dataContext.darkMode ? "#1E1E1E" : "#fff",
                  color: dataContext.darkMode ? "#fff" : "#000"
                }}
              >
                <TableRow style={{ width: '100%' }}>
                  <TablePagination
                    sx={{
                      '& .MuiTablePagination-spacer': {
                        flex: 'none !important',
                      },
                      '& .MuiTablePagination-selectLabel': {
                        margin: 0,
                        fontWeight: 'bold',
                      },
                      '& .MuiTablePagination-displayedRows': {
                        margin: 0,
                        fontWeight: 'bold',
                      },
                      '& .MuiTablePagination-select': {
                        fontWeight: 'bold',
                      },
                      overflow: 'hidden',
                    }}
                    rowsPerPageOptions={ROWS_PER_PAGE}
                    colSpan={columnsOfInterestRef.current.length}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
        {/* {(!valid || (rows !== null && rows.length == 0)) && (
          <div>
            <b>{noDataMessage}</b>
          </div>
        )} */}
      </Box>
    </ThemeProvider>
  );
}

DataBrowser.propTypes = propTypes;
DataBrowser.defaultProps = defaultProps;

export default DataBrowser;
