import React, { useState, useContext, useEffect, forwardRef, useImperativeHandle } from 'react'

import UserContext from '../components/UserContext'
import getTails from '../services/getTails'
import getExceedanceTypes from '../services/getExceedanceTypes'
import DatePicker from 'react-datepicker'
import './SearchMyFlights.css'
import 'react-datepicker/dist/react-datepicker.css'
import makeAnimated from 'react-select/animated';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CircularProgress from '@mui/material/CircularProgress';
import { DataContext } from '../components/DataContext'
import SearchFacet from '../components/SearchFacet'
import { Popper } from '@mui/material'

// import {Item} from '@mui/material'



const SearchMyFlights = forwardRef((props, ref) => {

  // ForwardRef method
  useImperativeHandle(ref, () => ({
    resetSearch()  {
      // console.log("Resetting search stuff")
      setTailNumber([])
      setMission([])
      setMake(null)
      setModel(null)
      setSeries(null)
      setFlightIds('')
      setEndDate(currentDate)
      setStartDate(monthInterval)
      setExceedance([])
      setExceedanceType([])
      setLOCType([])
      setObstacle([])
      setLoc([])
  }
  }));

  // Handle API call
  const [state, setState] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState([]);
  // const [showEmbedDiv, setShowEmbedDiv] = useState(false);
  // const [scrollPosition, setScrollPostion] = useState(0);

  // Search vars
  const [tailNumber, setTailNumber] = useState([]);
  const [make, setMake] = useState();
  const [model, setModel] = useState();
  const [series, setSeries] = useState();
  const [flightIds, setFlightIds] = useState('');

  let currentDate = new Date()
  let monthInterval = new Date()
  monthInterval.setMonth(currentDate.getMonth() - 12)

  const [startDate, setStartDate] = useState(monthInterval);
  const [endDate, setEndDate] = useState(currentDate);
  const [mission, setMission] = useState([]);

  const [exceedance, setExceedance] = useState([]);
  const [exceedanceType, setExceedanceType] = useState([]);
  const [locType, setLOCType] = useState([]);
  const [obstacle, setObstacle] = useState([]);
  const [loc, setLoc] = useState([]);


  // Lists
  const [operatorTailsList, setOperatorTailsList] = useState([]);
  const [operatorMakeList, setOperatorMakeList] = useState([]);
  const [operatorModelList, setOperatorModelList] = useState([]);
  const [operatorSeriesList, setOperatorSeriesList] = useState([]);
  const [operatorSeriesListMain, setOperatorSeriesListMain] = useState([]);
  const [operatorMissionList, setOperatorMissionList] = useState([]);
  const [severityList, setSeverityList] = useState([]);
  const [obstacleSeverityList, setObstacleSeverityList] = useState([]);
  const [trueFalseList, setTrueFalseList] = useState([]);
  const [exceedanceSubtypeList, setExceedanceSubtypeList] = useState([])
  const [locSubtypeList, setLOCSubtypeList] = useState([])
  // Handle user context
  const userContext = useContext(UserContext)
  var operator = userContext.user.attributes['custom:operator']

  // Data Context
  const dataContext = useContext(DataContext);

  // Multi Select component support
  const [openTails, setOpenTails] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [openSeries, setOpenSeries] = useState(false);
  const [openExceedances, setOpenExceedances] = useState(false);
  const [openLOC, setOpenLOC] = useState(false);
  const [openExceedancesSeverity, setOpenExceedancesSeverity] = useState(false)
  const [openMissionList, setOpenMissionList] = useState(false)
  const [openObstSev, setOpenObstSev] = useState(false)
  const animatedComponents = makeAnimated();
  const [tailListMain, setTailListMain] = useState();

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted grey',
      background: dataContext.darkMode ? 'black' : 'white',
      color: dataContext.darkMode ? 'white' : 'black',
      // padding: 20,
    }),
    control: (base) => ({
      ...base,
      // none of react-select's styles are passed to <Control />
      backgroundColor: dataContext.darkMode ? 'black' : 'white',
      // width: 200,
      color: dataContext.darkMode ? 'white' : 'black',
      display: 'flex',
      borderColor: dataContext.darkMode ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '4px',
      "&:hover": {
        borderColor: dataContext.darkMode ? 'white' : 'black',
      }

    }),
    placeholder: () => ({
      color: dataContext.darkMode ? 'white' : 'black',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
  }

  const customPopover = function(props){
    return <Popper {...props} placement="bottom-start" container={document.fullscreenElement ? document.fullscreenElement : document.body} />;
  };

  

  useEffect(() => {
    // getMyFlights(userContext.user, operator)
    //   .then((result) => {
    //     if (result != null) {
    //     // console.log({result})
    //       setState(result)
    //       setIsLoaded(true)
    //     }

    //   })
    //   .catch((error) => {
    //   // console.log(error)
    //     setIsLoaded(true)
    //     setError(error)
    //   })

    const setTailNumberv2 = (value) => {
      // console.log("Hello!")
      setTailNumber(value)
    }

    getExceedanceTypes(userContext.user).then((result) => {
      // console.log(result)
      let subtypeTemp = []
      result.forEach(item => {
        // console.log(item)
        subtypeTemp.push({
          type: item['type'],
          desc: item['subtype'],
          value: item['subtype'],
          key: item['subtype'],
        })
      })
      // build subtype lists
      setExceedanceSubtypeList(buildOptionsList(Array.from(new Set(subtypeTemp.filter(row => {
        return (row["type"] === "Exceedance")
      }).map(JSON.stringify))).map(JSON.parse), "value", "desc"))

      setLOCSubtypeList(buildOptionsList(Array.from(new Set(subtypeTemp.filter(row => {
        return (row["type"] === "Loss of Control")
      }).map(JSON.stringify))).map(JSON.parse), "value", "desc"))
    })

    getTails(userContext.user, operator)
      .then((result) => {
        // console.log({result})
        setTailListMain(result)
        let makeListTemp = [];
        let modelListTemp = [];
        let seriesListTemp = [];
        let missionListTemp = [];
        let tailsListTemp = [];
        result.Items.forEach(item => {
          if(item.icao_make_short.trim() != '')
          makeListTemp.push({
            desc: item.icao_make,
            value: item.icao_make_short
          })

          if(item.icao_model_short.trim() != '')
          modelListTemp.push({
            desc: item.icao_model,
            value: item.icao_model_short
          })

          if(item.icao_series_short.trim() != '')
          seriesListTemp.push({
            desc: item.icao_series,
            value: item.icao_series_short
          })

          if(item.default_mission.trim() != '')
          missionListTemp.push({
            desc: item.default_mission,
            value: item.default_mission
          })

          if(item.alias.trim() != '')
          tailsListTemp.push({
            desc: item.alias,
            value: item.alias
          })
        })
        /* Remove duplicates by creating a map of the stringified JSON, 
          turning it into a set to remove duplicate strings, 
          then parsing the strings back to json 
        */
        //  console.log("Only once")
        setOperatorTailsList(buildOptionsList(Array.from(new Set(tailsListTemp.map(JSON.stringify))).sort((a, b) => a > b ? 1 : -1).map(JSON.parse), "value", "desc"))
        setOperatorMakeList(buildOptionsList(Array.from(new Set(makeListTemp.map(JSON.stringify))).sort((a, b) => a > b ? 1 : -1).map(JSON.parse), "value", "desc"))
        setOperatorModelList(buildOptionsList(Array.from(new Set(modelListTemp.map(JSON.stringify))).sort((a, b) => a > b ? 1 : -1).map(JSON.parse), "value", "desc"))
        setOperatorSeriesList(buildOptionsList(Array.from(new Set(seriesListTemp.map(JSON.stringify))).sort((a, b) => a > b ? 1 : -1).map(JSON.parse), "value", "desc"))
        setOperatorSeriesListMain(buildOptionsList(Array.from(new Set(seriesListTemp.map(JSON.stringify))).sort((a, b) => a > b ? 1 : -1).map(JSON.parse), "value", "desc"))
        setOperatorMissionList(buildOptionsList(Array.from(new Set(missionListTemp.map(JSON.stringify))).sort((a, b) => a > b ? 1 : -1).map(JSON.parse), "value", "desc"))
        setSeverityList(buildOptionsList(severityTypes, "value", "desc"));
        setObstacleSeverityList(buildOptionsList(obstacleSeverityTypes, "value", "desc"));
        setTrueFalseList(buildOptionsList(trueFalseTypes, "value", "desc"));
        // setIsLoaded(true)
      })
      .catch((error) => {
        // console.log(error)
        setError(error)
      })

  }, [])

  useEffect(() => {
    if(!tailListMain || !model || !model[0] || !model[0].value){
      setOperatorSeriesList(operatorSeriesListMain)
    } else {
    // When model changes, update the series list
    let tempTailsList = tailListMain
    console.log(tempTailsList)
    let seriesListTemp = []
    let tempFiltered = tempTailsList.Items.filter(item => {
      return (item["icao_model_short"].indexOf(model[0].value) > -1)})
      tempFiltered.forEach(item => {
        if (item.icao_series_short.trim() != '') {
      seriesListTemp.push({
        desc: item.icao_series,
        value: item.icao_series_short
      })
    }
    })
      // update the list
      setOperatorSeriesList(buildOptionsList(Array.from(new Set(seriesListTemp.map(JSON.stringify))).sort((a, b) => a > b ? 1 : -1).map(JSON.parse), "value", "desc"))
  }
  }, [model])


  useEffect(() => {

    let tempSearch = {
      // tailNumber: buildArrayFromOptions(tailNumber).join(","),
      alias: buildArrayFromOptions(tailNumber).join(","),
      make: buildArrayFromOptions(make).join(","),
      model: buildArrayFromOptions(model).join(","),
      series: buildArrayFromOptions(series).join(","),
      flightIds: flightIds && flightIds.length ? flightIds.split(',') : null,

      startDate: startDate,
      endDate: endDate,
      mission: buildArrayFromOptions(mission).join(","),

      exceedance: buildNumberArrayFromOptions(exceedance).join(","),
      exceedanceType: buildArrayFromOptionsNoQuote(exceedanceType).join(","),
      locType: buildArrayFromOptionsNoQuote(locType).join(","),
      obstacle: buildNumberArrayFromOptions(obstacle).join(","),
      loc: buildNumberArrayFromOptions(loc).join(","),

    }
    // console.log(tempSearch)
    props.setSearchObj(tempSearch)

  }, [tailNumber, make, model, series, flightIds, exceedance, exceedanceType, locType, loc, obstacle, startDate, endDate, mission])

  const onTailNumListChange = (event, value) => {
    // console.log(value)
    setTailNumber(value)
  }


  const onModelListChange = (event, value) => {
    // console.log(value)
    setModel(value)
  }

  const onSeriesListChange = (event, value) => {
    // console.log(value)
    setSeries(value)
  }
  const buildOptionsList = ((response, identifier, displayIdentifier) => {
    // console.log({response})
    var multiSelectGroup = [];
    response.forEach((groupObj, index) => {
      multiSelectGroup.push({ label: groupObj[displayIdentifier], value: groupObj[identifier], id: index })
    })
    // console.log({multiSelectGroup})
    return multiSelectGroup
  })

  const buildArrayFromOptions = ((optionsJson) => {
    let tempArray = [];
    if (optionsJson) {
      optionsJson.forEach((optionElement) => {
        tempArray.push("'" + optionElement['value'] + "'")
      })
      // console.log({tempArray})
    }
    return tempArray
  })

  const buildArrayFromOptionsNoQuote = ((optionsJson) => {
    let tempArray = [];
    if (optionsJson) {
      optionsJson.forEach((optionElement) => {
        tempArray.push(optionElement['value'])
      })
      // console.log({tempArray})
    }
    return tempArray
  })

  const buildNumberArrayFromOptions = ((optionsJson) => {
    let tempArray = [];
    if (optionsJson) {
      optionsJson.forEach((optionElement) => {
        tempArray.push(optionElement['value'])
      })
      // console.log({tempArray})
    }
    return tempArray
  })

  // const placeholder = props => ({ children, ...rest }) => (
  //   <Components.Placeholder {...rest}>
  //     👍
  //   </Components.Placeholder>
  // );

  const severityTypes = [

    // {
    //   desc: 'All',
    //   value: ''
    // },
    {
      desc: 'None',
      value: '0'
    }, {
      desc: 'Low',
      value: '1'
    }, {
      desc: 'Medium',
      value: '2'
    }, {
      desc: 'High',
      value: '3'
    }
  ];

  const obstacleSeverityTypes = [

    // {
    //   desc: 'All',
    //   value: ''
    // },
    {
      desc: 'None',
      value: '1'
    }, {
      desc: 'Low',
      value: '2'
    }, {
      desc: 'Medium',
      value: '3'
    }, {
      desc: 'High',
      value: '4'
    }
  ];

  const trueFalseTypes = [
    {
      desc: 'True',
      value: '1'
    }, {
      desc: 'False',
      value: '0'
    }
  ];



  // const setSearchCriteria = (e) => {
  //   e.preventDefault();
  //   showEmbedDiv();
  //   // console.log("Search stuff")
  //   // console.log(buildArrayFromOptions(tailNumber))
  //   // console.log({tailNumber})
  //   let tempSearch = {
  //     tailNumber: buildArrayFromOptions(tailNumber).join(","),
  //     make: buildArrayFromOptions(make).join(","),
  //     model: buildArrayFromOptions(model).join(","),
  //     series: buildArrayFromOptions(series).join(","),

  //     startDate: startDate,
  //     endDate: endDate,
  //     mission: buildArrayFromOptions(mission).join(","),

  //     exceedance: buildNumberArrayFromOptions(exceedance).join(","),
  //     exceedanceType: buildArrayFromOptionsNoQuote(exceedanceType).join(","),
  //     obstacle: buildNumberArrayFromOptions(obstacle).join(","),
  //     loc: buildNumberArrayFromOptions(loc).join(","),

  //   }
  //   submitSearchRequest(tempSearch);
  //   // console.log({tempSearch});
  // }

  // useEffect(() => {
  //   // console.log("I've been triggered!")
  //   window.scrollTo(0, scrollPosition)
  //   })






  return (
    <div>

      {/* <form onSubmit={(event) => setSearchCriteria(event)}> */}

      {/* <FormGroup>
            <Stack spacing={2} direction="column" sx={{pt: 2, pb: 1}}> */}

      {/* <Select
          id="tailSelector"
          style={{ borderColor: "black" }}
          closeMenuOnSelect={false}
          placeholder="All"

          components={animatedComponents}
          // defaultValue={rolesSelected}
          isMulti
          onChange={evt => setTailNumber(evt)}
          options={operatorTailsList}
          /> */}
          <SearchFacet title="General"
                      groupName="general"
                      groupText="Search general flight info"
                      >
<div style={{display:'flex'}}>

<div className='inputLabel'>Flight IDs (comma separated)</div>

<TextField
          onChange={(evt) => {setFlightIds(evt.target.value)}}
          style={{ width: "100%", marginTop: '8px' }}
          size="small"
          value={flightIds}
          InputLabelProps={{shrink:false}}
            id="searchFID"
            placeholder={(flightIds && flightIds.length !== 0) ? "" : "Any"}
            sx={{
              "& .MuiInputBase-input": {
                outline: "none !important",
              },
            }}
          />
          </div>
                      
    <div style={{display:'flex'}}>

    <>
    <div className='inputLabel'>Alias</div>
      <Autocomplete
      PopperComponent={customPopover}
        filterSelectedOptions
        id="TailList"
        style={{ width: "100%", marginTop: '8px' }}
        size="small"
        open={openTails}
        onOpen={() => {
          setOpenTails(true);
        }}
        onClose={() => {
          setOpenTails(false);
        }}
        value={tailNumber}
        onChange={onTailNumListChange}
        multiple
        isOptionEqualToValue={(option, value) => option.label === value.label}
        getOptionLabel={(option) => option.label}
        options={operatorTailsList}
        loading={operatorTailsList.size < 1}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.label}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
          InputLabelProps={{shrink:false}}
            id="searchtext"
            {...params}
            placeholder={(tailNumber && tailNumber.length !== 0) ? "" : "All"}
            sx={{
              "& .MuiInputBase-input": {
                outline: "none !important",
              },
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {operatorTailsList.size < 1 ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
                   </>

                   </div>  
                                   

      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
          label="Flight Start Date"
          inputFormat="MM/DD/YYYY"
          
          onChange={(date) => setStartDate(date['$d'])}
          renderInput={(params) => <TextField {...params} />}
        />

      <DesktopDatePicker
          label="Flight End Date"
          inputFormat="MM/DD/YYYY"
          minDate={"01/01/2010"}
          openTo="month" 
          views={['month', 'year', 'day']}
          onChange={(date) => setStartDate(date['$d'])}
          renderInput={(params) => <TextField {...params} />}
        />
        </LocalizationProvider> */}
        <div style={{display:'flex'}}>

    <div className='inputLabel'>Date Range</div>
      {/* <Label style={{ marginTop: '0px' }} htmlFor="startDateSelector">Flight Start Date:</Label> */}
      <div style={{display:'flex', width:'100%', paddingTop:'5px'}}>
      <div className={dataContext.darkMode ? 'darkDate' : 'lightDate'} style={{ fontSize: "large", width:'50%' }}>
        <DatePicker

          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          selected={startDate}
          onChange={(date) => setStartDate(date)} />
      </div>

      {/* <Label style={{ marginTop: '0px' }} htmlFor="endDateSelector">End Date:</Label> */}
      <div className={dataContext.darkMode ? 'darkDate' : 'lightDate'} style={{ fontSize: "large", width:'50%' }}>
        <DatePicker

          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          selected={endDate}
          onChange={(date) => setEndDate(date)} />
      </div>
      </div>
      </div>
      <div style={{display:'flex'}}>

      <div className='inputLabel'>Model and Series</div>
      {/* <Label style={{ marginTop: '0px' }} htmlFor="startDateSelector">Flight Start Date:</Label> */}
      <div style={{display:'flex', width:'100%', paddingTop:'5px'}}>
      <div className={dataContext.darkMode ? 'darkDate' : 'lightDate'} style={{ fontSize: "large", width:'50%' }}>
      <Autocomplete
      PopperComponent={customPopover}
        filterSelectedOptions
        id="ModelList"
        style={{ width: "100%", marginTop: '8px' }}
        size="small"
        open={openModel}
        onOpen={() => {
          setOpenModel(true);
        }}
        onClose={() => {
          setOpenModel(false);
        }}
        value={model}
        onChange={onModelListChange}
        multiple
        isOptionEqualToValue={(option, value) => option.label === value.label}
        getOptionLabel={(option) => option.label}
        options={operatorModelList}
        loading={operatorModelList.size < 1}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.label}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
          InputLabelProps={{shrink:false}}
            id="searchtext"
            {...params}
            placeholder={(model && model.length !== 0) ? "" : "All Models"}
            sx={{
              "& .MuiInputBase-input": {
                outline: "none !important",
              },
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {operatorModelList.size < 1 ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      </div>

      {/* <Label style={{ marginTop: '0px' }} htmlFor="endDateSelector">End Date:</Label> */}
      <div className={dataContext.darkMode ? 'darkDate' : 'lightDate'} style={{ fontSize: "large", width:'50%' }}>
      <Autocomplete
      PopperComponent={customPopover}
        filterSelectedOptions
        id="SeriesList"
        style={{ width: "100%", marginTop: '8px' }}
        size="small"
        open={openSeries}
        onOpen={() => {
          setOpenSeries(true);
        }}
        onClose={() => {
          setOpenSeries(false);
        }}
        value={series}
        onChange={onSeriesListChange}
        multiple
        isOptionEqualToValue={(option, value) => option.label === value.label}
        getOptionLabel={(option) => option.label}
        options={operatorSeriesList}
        loading={operatorSeriesList.size < 1}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.label}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
          InputLabelProps={{shrink:false}}
            id="searchtext"
            {...params}
            placeholder={(series && series.length !== 0) ? "" : "All Series"}
            sx={{
              "& .MuiInputBase-input": {
                outline: "none !important",
              },
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {operatorSeriesList.size < 1 ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      </div>
      </div>
      </div>
      <div style={{display:'flex'}}>

<div className='inputLabel'>Mission Type</div>
      <Autocomplete
      PopperComponent={customPopover}
        value={mission}
        id="missionSelector"
        style={{ width: "100%", marginTop: '8px' }}
        size="small"
        open={openMissionList}
        onOpen={() => {
          setOpenMissionList(true);
        }}
        onClose={() => {
          setOpenMissionList(false);
        }}
        onChange={(evt, value) => setMission(value)}
        multiple
        isOptionEqualToValue={(option, value) => option.label === value.label}
        getOptionLabel={(option) => option.label}
        options={operatorMissionList}
        loading={operatorMissionList.size < 1}
        renderOption={(props, option) => {
          return (
            <li {...props} key={props.id + option.id}>
              {option.label}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            id="missiontypeselector"
            {...params}
            placeholder={(mission && mission.length !== 0) ? "" : "All"}
            sx={{
              "& .MuiInputBase-input": {
                outline: "none !important",
              },
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {operatorMissionList.size < 1 ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      </div>
      </SearchFacet>


      <SearchFacet title="Metrics"
                      groupName="metrics"
                      groupText="Search by metrics"
                      >
                      
    {/* <div style={{display:'flex'}}>

    <div className='inputLabel'>Exceedance Type</div>
      <Autocomplete
      PopperComponent={customPopover}
      value={exceedanceType}
        id="ExceedanceTypeSelect"
        style={{ width: "100%", marginTop: '8px' }}
        size="small"
        open={openExceedances}
        onOpen={() => {
          setOpenExceedances(true);
        }}
        onClose={() => {
          setOpenExceedances(false);
        }}
        onChange={(evt, value) => setExceedanceType(value)}
        multiple
        isOptionEqualToValue={(option, value) => option.label === value.label}
        getOptionLabel={(option) => option.label}
        options={exceedanceSubtypeList}
        loading={exceedanceSubtypeList.size < 1}
        renderOption={(props, option) => {
          return (
            <li {...props} key={props.id + option.id}>
              {option.label}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            id="exceedancesubtypeselector"
            {...params}
            placeholder={(exceedanceType && exceedanceType.length !== 0) ? "" : "All"}
            sx={{
              "& .MuiInputBase-input": {
                outline: "none !important",
              },
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {exceedanceSubtypeList.size < 1 ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
</div> */}

<div style={{display:'flex'}}>

<div className='inputLabel'>Exceedance Severity</div>
      <Autocomplete
      PopperComponent={customPopover}
        value={exceedance}
        id="ExceedanceSeverity"
        style={{ width: "100%", marginTop: '8px' }}
        size="small"
        open={openExceedancesSeverity}
        onOpen={() => {
          setOpenExceedancesSeverity(true);
        }}
        onClose={() => {
          setOpenExceedancesSeverity(false);
        }}
        onChange={(evt, value) => setExceedance(value)}
        multiple
        isOptionEqualToValue={(option, value) => option.label === value.label}
        getOptionLabel={(option) => option.label}
        options={severityList}
        loading={severityList.size < 1}
        renderOption={(props, option) => {
          return (
            <li {...props} key={props.id + option.id}>
              {option.label}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            id="exceedanceseverityeselector"
            {...params}
            placeholder={(exceedance && exceedance.length !== 0) ? "" : "All"}
            sx={{
              "& .MuiInputBase-input": {
                outline: "none !important",
              },
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {severityList.size < 1 ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
</div>

 {/* <div style={{display:'flex'}}>

<div className='inputLabel'>Loss of Control Type</div>
      <Autocomplete
      PopperComponent={customPopover}
        value={locType}
        id="locTypeSelect"
        style={{ width: "100%", marginTop: '8px' }}
        size="small"
        open={openLOC}
        onOpen={() => {
          setOpenLOC(true);
        }}
        onClose={() => {
          setOpenLOC(false);
        }}
        onChange={(evt, value) => setLOCType(value)}
        multiple
        isOptionEqualToValue={(option, value) => option.label === value.label}
        getOptionLabel={(option) => option.label}
        options={locSubtypeList}
        loading={locSubtypeList.size < 1}
        renderOption={(props, option) => {
          return (
            <li {...props} key={props.id + option.id}>
              {option.label}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            id="locsubtypeselector"
            {...params}
            placeholder={(locType && locType.length !== 0) ? "" : "All"}
            sx={{
              "& .MuiInputBase-input": {
                outline: "none !important",
              },
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {locSubtypeList.size < 1 ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      </div> */}
      {/* <Label style={{ marginTop: '0px' }} htmlFor="exceedanceSubtypeSelector">Exceedances Types:</Label>
              <div style={{ fontSize:"large" }}>
              <Select
  id="exceedanceSubtypeSelector"
  styles={customStyles}
  closeMenuOnSelect={false}
  placeholder="All"
  components={animatedComponents}
  isMulti
  onChange={evt => setExceedanceType(evt)}
  options={exceedanceSubtypeList}
/>
</div> */}

 <div style={{display:'flex'}}>

<div className='inputLabel'>Obstacle Severity</div>
<Autocomplete
        value={obstacle}
        PopperComponent={customPopover}
        id="obstacleSeveritySelector"
        style={{ width: "100%", marginTop: '8px' }}
        size="small"
        open={openObstSev}
        onOpen={() => {
          setOpenObstSev(true);
        }}
        onClose={() => {
          setOpenObstSev(false);
        }}
        onChange={(evt, value) => setObstacle(value)}
        multiple
        isOptionEqualToValue={(option, value) => option.label === value.label}
        getOptionLabel={(option) => option.label}
        options={obstacleSeverityList}
        loading={obstacleSeverityList.size < 1}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.label}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            id="obstacleSeverity"
            {...params}
            placeholder={(obstacle && obstacle.length !== 0) ? "" : "All"}
            sx={{
              "& .MuiInputBase-input": {
                outline: "none !important",
              },
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {severityList.size < 1 ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
</div>
      </SearchFacet>
      {/* <Label style={{ marginTop: '0px' }} htmlFor="exceedanceSelector">Exceedances Severity:</Label>
              <div style={{ fontSize:"large" }}>
<Select
  id="exceedanceSelector"
  styles={customStyles}
  closeMenuOnSelect={false}
  placeholder="All"
  components={animatedComponents}
  // defaultValue={rolesSelected}
  isMulti
  onChange={evt => setExceedance(evt)}
  options={severityList}
/> */}
      {/* </div> */}

      

      {/* <Label style={{ marginTop: '0px' }} htmlFor="missionSelector">Mission Type:</Label>

              <div style={{ fontSize:"large" }}>
<Select
  id="missionSelector"
  styles={customStyles}
  closeMenuOnSelect={false}
  placeholder="All"
  components={animatedComponents}
  // defaultValue={rolesSelected}
  isMulti
  onChange={evt => setMission(evt)}
  options={operatorMissionList}
/>
              
      </div> */}


     

      {/* <Label style={{ marginTop: '0px' }} htmlFor="obstacleSelector">Obstacles Severity:</Label>
              <div style={{ fontSize:"large" }}>
<Select
  id="obstacleSelector"
  styles={customStyles}
  closeMenuOnSelect={false}
  placeholder="All"
  components={animatedComponents}
  // defaultValue={rolesSelected}
  isMulti
  onChange={evt => setObstacle(evt)}
  options={severityList}
/>
             </div>  */}

      {/* 
             
              <Label style={{ marginTop: '0px' }} htmlFor="locSelector">Loss of Control:</Label>
              <div style={{ fontSize:"large" }}>
      <Select
        id="locSelector"
        styles={customStyles}
        closeMenuOnSelect={false}
        placeholder="All"
        components={animatedComponents}
        // defaultValue={rolesSelected}
        isMulti
        onChange={evt => setLoc(evt)}
        options={trueFalseList}
      />
      </div> */}
      {/* </Stack>
          </FormGroup> */}
      {/* <Button style={{ marginBottom: '200px' }} type="submit">Submit</Button> */}
      {/* </form> */}

    </div>
  )
})

export default SearchMyFlights
