/**
 * Take in:
 * controlName - unique name for control
 * layerList - list of layerIds for setting visibility
 */
import { ConstructionOutlined } from '@mui/icons-material';
import './BasemapSwitchControlStyles.css';

const DEFAULT_TILE_SIZE = 256;

class LayerControl {
  constructor(controlName, layerList, handleLegends) {
    // this.styles = styles || LayerControl.DEFAULT_STYLES;
    this.controlName = controlName || LayerControl.DEFAULT_NAME;
    this.layerList = layerList
    // console.log("In Layer cons")
    // console.log(`Layer List: ${layerList}`)
    this.styles = [];
    this.handleLegends = handleLegends;
    // const dataContext = useContext(DataContext);
    if(layerList){
    // for (const [key, value] of Object.entries(layerList)) {
        Object.keys(layerList).map((key) =>{
      let layer = {};
      layer["title"] = layerList[key]["title"];
      layer["id"] = layerList[key]["id"]
      layer["linkedLayers"] = layerList[key]["linkedLayers"]
      layer["linkedLegends"] = layerList[key]["linkedLegends"]
      layer["defaultChecked"] = layerList[key]["defaultChecked"]

      // console.log(`Value: ${layerList[key]}, Layer: ${layer}`)
    //   layer["url"] = value["url"];
    //   layer["attribution"] = value["attribution"];
    //   layer["type"] = value["type"];
    //   layer["tileSize"] = value["tileSize"];

      this.styles.push(layer);
    })}

    // const defaultStyle = typeof(options) === "string" ? options : options ? options.defaultStyle : undefined;
    // this.defaultStyle = defaultStyle || LayerControl.DEFAULT_STYLE;
    this.defaultStyle = LayerControl.DEFAULT_STYLE;
    this.onDocumentClick = this.onDocumentClick.bind(this);
    // this.events = typeof(options) !== "string" && options ? options.eventListeners : undefined;
  }
  getDefaultPosition() {
    const defaultPosition = "top-right";
    return defaultPosition;
  }
  onAdd(map) {
    this.map = map;
    this.controlContainer = document.createElement("div");
    this.controlContainer.classList.add("mapboxgl-ctrl");
    this.controlContainer.classList.add("mapboxgl-ctrl-group");
    this.mapStyleContainer = document.createElement("div");
    this.styleButton = document.createElement("button");
    this.styleButton.type = "button";
    this.mapStyleContainer.classList.add("mapboxgl-style-list");
    for (const style of this.styles) {
      const styleElement = document.createElement("input");
      styleElement.type = "checkbox";
      // console.log(`CHecked is ${style.defaultChecked}`)
      styleElement.checked = style.defaultChecked;
      // styleElement.setAttribute("name", "basemap-checkbox"); //Because of the Radio buttons, the name must be same!
      styleElement.setAttribute("name", this.controlName);
      styleElement.classList.add("list-checkcontrol-block");
      styleElement.dataset.id = style.id;
      styleElement.dataset.linkedLayers = style.linkedLayers ? style.linkedLayers : '';
      styleElement.dataset.linkedLegends = style.linkedLegends ? style.linkedLegends : '';
    //   styleElement.dataset.attribution = style.attribution;
    //   styleElement.dataset.type = style.type;
    //   styleElement.dataset.tileSize = style.tileSize;

      styleElement.addEventListener("click", event => {
        // console.log(`${JSON.stringify(this.map.getStyle()['layers'], null, 2)}`)
        const srcElement = event.srcElement;
        
        
        // if (srcElement.hasAttribute("checked")) {
        //   return;
        // }

        if (this.events && this.events.onOpen && this.events.onOpen(event)) {
          return;
        }

        // loop all styles and set all layers to invisible if they don't equal the source style id
        // console.log(`${this.styles}`)
        // for (const styleItem of this.styles) {
        //     console.log({styleItem})
            // if(styleItem['id'] === srcElement.dataset.id){
            //     console.log(`${srcElement.dataset.id} is being set to visible`)
            //     this.map.setLayoutProperty(srcElement.dataset.id, 'visibility', 'visible');
            // }
            // else{
            //     console.log(`${styleItem['id']} is being set to none`)
            //     this.map.setLayoutProperty(styleItem['id'], 'visibility', 'none');
            // }

            // console.log(`${srcElement.checked}`)
            if(srcElement.checked === true){
                this.handleVisToggle(srcElement.dataset, 'visible')
                
            }
            else{
                this.handleVisToggle(srcElement.dataset, 'none')
            
        }
        // }

        // const styleJson = LayerControl.DEFAULT_STYLEJSON;
        // styleJson['sources']['raster-tiles']['type'] = srcElement.dataset.type;

        // // NOTE: Need to parse to int because dataset is a DOMStringMap type (all values are converted to string)
        // styleJson['sources']['raster-tiles']['tileSize'] = parseInt(srcElement.dataset.tileSize);

        // // styleJson['sources']['raster-tiles']['url'] = srcElement.dataset.url;
        // styleJson['sources']['raster-tiles']['tiles'][0] = srcElement.dataset.url;
        // styleJson['sources']['raster-tiles']['attribution'] = srcElement.dataset.attribution;

        // this.map.setStyle(styleJson);

        // let nodeList = this.mapStyleContainer.getElementsByTagName("input");

        // for (let i = 0; i < nodeList.length; i++) {
        //   let node = nodeList[i];
        //   if (node.hasAttribute("checked"))
        //     node.checked = false;
        // }
        // srcElement.checked = true;

        if (this.events && this.events.onChange && this.events.onChange(event, style)) {
          return;
        }
      });

      if (style.title.includes(this.defaultStyle)) {
        styleElement.checked = true;
      }
      this.mapStyleContainer.appendChild(styleElement);

      const styleElementLabel = document.createElement("span");
      styleElementLabel.innerText = style.title;

      this.mapStyleContainer.appendChild(styleElementLabel);

      const styleElementBr = document.createElement("br");
      this.mapStyleContainer.appendChild(styleElementBr);
    }
    this.styleButton.classList.add("mapboxgl-ctrl-icon");
    this.styleButton.classList.add("mapboxgl-layer-switcher");
    this.styleButton.addEventListener("mouseover", event => {
      this.openModal();
    });
    this.mapStyleContainer.addEventListener("mouseleave", event => {
      this.closeModal();
    });
    this.styleButton.addEventListener("click", event => {
      if (this.events && this.events.onSelect && this.events.onSelect(event)) {
        return;
      }
      // this.openModal();
    });
    document.addEventListener("click", this.onDocumentClick);
    this.controlContainer.appendChild(this.styleButton);
    this.controlContainer.appendChild(this.mapStyleContainer);
    this.openModal();
    return this.controlContainer;
  }
  onRemove() {
    if (!this.controlContainer || !this.controlContainer.parentNode || !this.map || !this.styleButton) {
      return;
    }
    this.styleButton.removeEventListener("click", this.onDocumentClick);
    this.controlContainer.parentNode.removeChild(this.controlContainer);
    document.removeEventListener("click", this.onDocumentClick);
    this.map = undefined;
  }
  closeModal() {
    if (this.mapStyleContainer && this.styleButton) {
      this.mapStyleContainer.style.display = "none";
      this.styleButton.style.display = "block";
    }
  }
  openModal() {
    // Revisit this to figure out a more graceful auto layer list
    // console.log(`${JSON.stringify(this.map.getStyle()['layers'], null, 2)}`)
    // let scanLayer = this.map.getStyle()['layers']
    // console.log(scanLayer)
    //     if(!this.layerList){
//     for (const layer in scanLayer){
//         console.log(layer)
//         let layerItem = {};
//         layerItem["id"] = scanLayer[layer]['id']
//         layerItem['visbility'] = scanLayer[layer]['layout'] && scanLayer[layer]['layout']['visibility'] ? scanLayer[layer]['layout']['visibility'] : 'visible'

//         console.log({layerItem})
//     }
// }
    if (this.mapStyleContainer && this.styleButton) {
      this.mapStyleContainer.style.display = "block";
      this.mapStyleContainer.style.marginTop = "5px";
      this.mapStyleContainer.style.marginLeft = "3px";
      this.mapStyleContainer.style.marginBottom = "5px";
      this.mapStyleContainer.style.marginRight = "6px";
      this.styleButton.style.display = "none";
    }
  }
  onDocumentClick(event) {
    if (this.controlContainer && !this.controlContainer.contains(event.target)) {
      this.closeModal();
    }
  }
  handleVisToggle(layerNameElement,toggleValue){
    
    // console.log(`Setting ${layerNameElement.id} to ${toggleValue}`)
    this.map.setLayoutProperty(layerNameElement.id, 'visibility', toggleValue);
    if(layerNameElement.linkedLayers && layerNameElement.linkedLayers != ''){
        let splitLayers  = layerNameElement.linkedLayers.split(',')
        // console.log(splitLayers)
    for(let tempLayer of splitLayers){
        this.map.setLayoutProperty(tempLayer, 'visibility', toggleValue);
    }
}
// console.log(layerNameElement)
// console.log(toggleValue)
if(layerNameElement.linkedLegends && layerNameElement.linkedLegend != ''){
  // console.log("Toggle legend for: " + layerNameElement.linkedLegends)
  this.handleLegends(layerNameElement.linkedLegends, toggleValue)
}

  }
}
LayerControl.DEFAULT_STYLE = "Light Basemap";
// LayerControl.DEFAULT_STYLES = [
//     { title: "Light Basemap", uri: "mapbox://styles/mapbox/light-v10" },
//     { title: "Dark Basemap", uri: "mapbox://styles/mapbox/dark-v10" },
//     { title: "Open Street Map", uri: "mapbox://styles/mapbox/outdoors-v11" },
//     { title: "VFR Sectional Basemap", uri: "mapbox://styles/mapbox/satellite-streets-v11" },
//     { title: "Helo Chart Basemap", uri: "mapbox://styles/mapbox/satellite-streets-v11" },
//     { title: "Esri World Imagery (Terrain)", uri: "mapbox://styles/mapbox/outdoors-v11" },
// ];
// LayerControl.BaseMaps = {
//   "Dark Basemap": {
//     url: 'https://basemaps.cartocdn.com/rastertiles/dark_all/{z}/{x}/{y}.png',
//     attribution: '&copy <a href="http://cartodb.com/attributions" target="_blank">CartoDB</a> contributors',
//     type: 'raster',
//     tileSize: DEFAULT_TILE_SIZE,
//   },
//   "Light Basemap": {
//     url: 'https://basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png',
//     attribution: '&copy <a href="http://cartodb.com/attributions" target="_blank">CartoDB</a> contributors',
//     type: 'raster',
//     tileSize: DEFAULT_TILE_SIZE,
//   },
//   "Open Street Map": {
//     url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
//     attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
//     type: 'raster',
//     tileSize: DEFAULT_TILE_SIZE,
//   },
//   "VFR Sectional Basemap": {
//     url: 'https://wms.chartbundle.com/tms/1.0.0/sec/{z}/{x}/{y}.png?origin=nw',
//     attribution: 'Sectional basemap from <a href="http://www.chartbundle.com/" target="_blank">ChartBundle.com',
//     type: 'raster',
//     tileSize: DEFAULT_TILE_SIZE,
//   },
//   "Helo Chart Basemap": {
//     url: 'https://wms.chartbundle.com/tms/1.0.0/hel/{z}/{x}/{y}.png?origin=nw',
//     attribution: 'Helicopter Chart basemap from <a href="http://www.chartbundle.com/" target="_blank">ChartBundle.com',
//     type: 'raster',
//     tileSize: DEFAULT_TILE_SIZE,
//   },
//   "Esri World Imagery (Terrain)": {
//     url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
//     attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
//     type: 'raster',
//     tileSize: DEFAULT_TILE_SIZE,
//   },
//   // "3D Terrain": {
//   //   url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
//   //   attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
//   //   type: 'raster-dem',
//   //   tileSize: 512,
//   // },
// };

LayerControl.DEFAULT_NAME = "basemap-checkbox";
LayerControl.DEFAULT_STYLEJSON = {
  'version': 8,
  'glyphs': 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
  'sources': {
    'raster-tiles': {
      'type': 'raster',
      'tiles': [
        'https://basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png'
      ],
      'tileSize': DEFAULT_TILE_SIZE,
      'attribution': '&copy <a href="http://cartodb.com/attributions" target="_blank">CartoDB</a> contributors'
    }
  },
  'layers': [
    {
      'id': 'basemap-tiles',    // Can be whatever you want to name it
      'type': 'raster',         // Must be raster
      'source': 'raster-tiles', // Must be 'raster-tiles'
      'minzoom': 0,
      'maxzoom': 22
    }
  ]
};

export default LayerControl;
