import {API} from "aws-amplify";

export function getLastFlight(user) {
    const token = user.signInUserSession.idToken.jwtToken;
    const requestInfo = {
      headers: {
        Authorization: token
      },
      body: {
      }
    }

    // console.log({requestInfo})

    let url = "/raise-dev-get-last-flight"

    const data = API.post('raise-gui-api-dev', url, requestInfo)
      return data;
}

export default getLastFlight;