import React, { useEffect, useContext, useState } from 'react'
import DashboardNav from '../components/DashboardNav'
import {
  Accordion
} from '@trussworks/react-uswds'
import getDefinitions from '../services/getDefinitions';
import UserContext from '../components/UserContext'


import './Glossary.css'



const Glossary = () => {

  // const [definitionsList, setDefinitionsList] = useState([]);
  const [accordionItems, setAccordionItems] = useState([]);
  const [contentsItems, setContentsItems] = useState([])
  const userContext = useContext(UserContext)

  useEffect(() => {
  // console.log("Set up definitions")
  
    getDefinitions(userContext.user)
      .then((result) => {
          let sorted = result[0].sort((a, b) => a['name'] > b['name'] ? 1 : -1)
          // console.log(sorted)
        // setDefinitionsList(result) 
        parseData(sorted) 
        })
      .catch((error) => {
        // console.log(error)
          // setError(error)
      })
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [])




const parseData = (list) => {
  // console.log("Populate method")
  // console.log({list})
  let categoryList = Array.from([...new Set(list.map(item => item.category))])

  let dataArray = {}
  setContentsItems(categoryList)

  // console.log(categoryList)
  categoryList.forEach(item => {
    // console.log(item)
    dataArray[item] = []
    // dataArray.push({item : []})
  })

  // console.log({dataArray})


  
  list.forEach((attribute, index) => {
    
      
      let riskSwitch = (attribute['rule_1'] && attribute['rule_2'] && attribute['rule_3'])
      // if(attribute['rule1']  !== '' && attribute['rule2']  !== '' && attribute['rule3']  !== '') {
      // attribute['rule1'] !== '' ? rule1Insert = 'Rule 1' + attribute['rule1'] : rule1Insert = '';
      // attribute['rule2'] !== '' ? rule1Insert = 'Rule 2' + attribute['rule2'] : rule1Insert = '';
      // attribute['rule3'] !== '' ? rule1Insert = 'Rule 3' + attribute['rule3'] : rule1Insert = '';
      // }
      
      dataArray[attribute.category].push({
        key : attribute.category + attribute.name,    
        title: attribute.name,
        content: (
          <>
          <div key={index}>
          {attribute.definition} <br />
          {attribute['rule_1'] || attribute['rule_2'] || attribute['rule_3'] ? 
          <>
          <br />
          <table>
          <tbody>
          <tr>
            <th>Rule</th>
          </tr>
          {attribute['rule_1'] !== ''  ? 
          <tr>
          {riskSwitch? <td className="glossary-td">Low Severity </td> : <></>}
          
              <td className="glossary-td">
              {attribute['rule_1']}
              </td>
            </tr> : <></>}
          {attribute['rule_2'] !== ''  ? <tr>
          {riskSwitch? <td className="glossary-td">Medium Severity </td> : <></>}
              <td className="glossary-td">
              {attribute['rule_2']}
              </td>
            </tr> : <></>}
          {attribute['rule_3'] !== ''  ? <tr>
          {riskSwitch? <td className="glossary-td">High Severity </td> : <></>}
              <td className="glossary-td">
              {attribute['rule_3']}
              </td>
            </tr> : <></>}
            </tbody>
          </table>
          </>
          : <></>}
        <br />
          </div>
          </>
        ),
        expanded: false,
        id: index})
      })
      // console.log(dataArray)
    
     
    setAccordionItems(dataArray)
    
  }







  return (
    <>
    <DashboardNav />
    <div className="stretch_grid-container">
    <h1 id="contents">Glossary of Terms</h1>
      {contentsItems && contentsItems.length > 0 ?
      
      <>
      
        {contentsItems.sort().map((item, index) => {
          
          return(
          <div key={index}>
          <a  key={"a"+index}
              href="/"
              onClick={e => {
              let section = document.getElementById(item);
              e.preventDefault();  // Stop Page Reloading
              section && section.scrollIntoView();
              }}
              >
              {item}
              </a>
            
          </div>
          )
        })}
      </>
      
      :
      
      <>

      </>}


      {contentsItems && contentsItems.length > 0 ?
        <>
        {contentsItems.map((item, index) => {
          return(
            <div key={"ab" + index}>
          <h1 key={"b"+index} style={{paddingTop: '1em'}} id={item}> {item}</h1> 
          <a
              href="/"
              key={"c"+index}
              onClick={e => {
              let section = document.getElementById('contents');
              e.preventDefault();  // Stop Page Reloading
              section && section.scrollIntoView();
              }}
              >
              Back to top
              </a>
          {accordionItems[item] ?
            <Accordion bordered={false} multiselectable={true} items={accordionItems[item]} key={"d"+index}></Accordion>
          :
          <>
          </>
          }
          </div>
          )
        })}
        </>
        
      :
      <>

      </>
      }  
      </div>
    </>
  )
}

export default Glossary
