import {API} from "aws-amplify";

function getUserGroups(user) {
    const token = user.signInUserSession.idToken.jwtToken;
      const requestInfo = {
        headers: {
          Authorization: token
        }
      }
      // console.log({token})
      const data = API.get('raise-gui-api-dev', "/user-groups?username=" + user.username, requestInfo)
      // console.log({data})
      return data;
}

export default getUserGroups;