import React, { useState, useEffect, useRef, useContext } from "react";
import { DataContext } from "../DataContext";
import PropTypes from 'prop-types';
import L from "leaflet";
import 'leaflet.control.layers.tree';
import 'leaflet-groupedlayercontrol';
import 'leaflet-easybutton';
import 'leaflet.fullscreen';
import 'leaflet.markercluster';
import Helicopter from "../../icons/svg/Helicopter";

// Import Styles (requirement for many leaflet plugins)
import 'font-awesome/css/font-awesome.min.css';
import 'leaflet.control.layers.tree/L.Control.Layers.Tree.css';
import 'leaflet-groupedlayercontrol/src/leaflet.groupedlayercontrol.css';
import 'leaflet.fullscreen/Control.FullScreen.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet-easybutton/src/easy-button.css';
import './Map.css';

const propTypes = {
  mapId: PropTypes.string.isRequired,
  startLoc: PropTypes.arrayOf(PropTypes.number),
  zoom: PropTypes.number,
  zoomSensitivity: PropTypes.number,
};

const defaultProps = {
  startLoc: [39.8283, -98.5795],
  zoom: 4,
  zoomSensitivity: 1,
};

const LocSeverityMap = ({
  mapId,
  startLoc,
  zoom,
  zoomSensitivity,
}) => {
  // const mapId = `map-${Math.floor(Math.random(1, 1000) * 1000)}`;

  const MAP_PAN_CONFIG = {
    animate: true,
    duration: 1.5
  }

  // LAYER CONFIG
  // const LOC_SEVERITY_LAYER_NAME = "Loss of Control (LOC) Severity";
  const OBSTACLES_LAYER_NAME = "Obstacles";

  // LEGEND CONFIG
  const LOC_SEVERITY_LEGEND_NAME = "Loss of Control (LOC) Severity Legend";

  // Obtain reference to global data context consumer
  const dataContext = useContext(DataContext);

  const isMounted = useRef(false);
  const tileRef = useRef(null);
  const overlayTileRef = useRef(null);
  const baseControlRef = useRef(null);
  const baseLayersRef = useRef(null);
  const overlayLayersRef = useRef(null);
  const zoomControlRef = useRef(null);
  const fullscreenControlRef = useRef(null);
  const scaleControlRef = useRef(null);
  const dataControlRef = useRef(null);
  // const dataLayerRef = useRef(null);
  const flightLocRef = useRef(null);
  const flightTrackRef = useRef(null);
  const legendsMapRef = useRef(null);
  const activeLegendRef = useRef(null);
  const locSeverityMarkersRef = useRef(null);
  const nonLocSeverityMarkersRef = useRef(null);
  const obstacleMarkersRef = useRef(null);

  // Define layers
  const locSeverityLayer = useRef(null);
  const obstaclesLayer = useRef(null);
  const flightTrackLayer = useRef(null);

  const [overlays, setOverlays] = useState([]);
  const [currentPos, setCurrentPos] = useState(null);
  const [initLoad, setInitLoad] = useState(true);

  /**
   * Generate HTML string containing a FontAwesome icon based on
   * the specified FontAwesome icon string.
   * 
   * See: https://fontawesome.com/v4.7/icons/
   * 
   * Note: Set the CSS for "icons" class to adjust style of all icons (e.g., font size, etc.)
   * 
   * @param {string} faIconCls FontAwesome icon class string (e.g., fa fa-globe)
   * @returns HTML string representing the FontAwesome icon
   */
  // const faIconHtmlStr = (faIconCls) => {
  //   return `<span class="icons"><i class="${faIconCls}" aria-hidden="true"/></span>`;
  // }

  // Define basemap tiles from data context
  const includedBaseMaps = [
    "Light Basemap",
    "Dark Basemap",
    "Open Street Map",
    "VFR Sectional Basemap",
    "Helo Chart Basemap",
    "Esri World Imagery (Terrain)",
  ];

  let baseMaps = includedBaseMaps.reduce(
    (prev, current) => {
      return {
        ...prev, [current]: L.tileLayer(dataContext.baseMaps[current].url, {
          attribution: dataContext.baseMaps[current].attribution,
          maxZoom: dataContext.MAP_MAX_ZOOM,
        })
      }
    }, {}
  );

  var overlayMaps = null;

  // Base tile for the map:
  tileRef.current = baseMaps;
  overlayTileRef.current = overlayMaps;

  const mapStyles = {
    overflow: "hidden",
    width: "100%",
    height: "100%"
  };

  // Options for our map instance:
  // See for map parameter API reference: https://leafletjs.com/reference.html
  // const startingBasemap = props.startingBasemap in baseMaps ? props.startingBasemap;
  const startingBasemap = dataContext.darkMode ? "Dark Basemap" : "Light Basemap";
  const mapParams = {
    center: startLoc,
    zoom: zoom,
    zoomSnap: zoomSensitivity != null && zoomSensitivity >= 0.2 ? zoomSensitivity : 0.25,
    zoomDelta: zoomSensitivity != null && zoomSensitivity >= 0.2 ? zoomSensitivity : 0.25,
    zoomControl: false, // Set to false for custom zoom control integration
    // maxBounds: L.latLngBounds(L.latLng(-150, -240), L.latLng(150, 240)),
    maxZoom: dataContext.MAP_MAX_ZOOM,
    closePopupOnClick: false,
    preferCanvas: true, // Improve performance/scalability for loading layers and shapes
    layers: [tileRef.current[startingBasemap]] // Start with just the base layer (defines the initial base map that will be displayed)
  };

  const buildFeaturedTooltip = (bindTarget, row, { color = null, direction = null, asPopup = false }) => {
    // Build tool tip off all properties
    var tooltipText = "";

    // Start table
    tooltipText += "<table>";
    for (const [key, value] of Object.entries(row)) {
      let iconData = `<td><i class="fa fa-square tooltip-icon-fonts" style="color: ${color}; margin-right: 5px;"></i></td>`;
      tooltipText += `
        <tr>
          ${color !== null ? iconData : ""}
          <td style="color: #989898"><b>${key}&nbsp</b></td>
          <td><b>${value}</b></td>
        </tr>
      `;
    }

    // End table
    tooltipText += "</table>";

    if (asPopup) {
      bindTarget.bindPopup(tooltipText, {
        className: dataContext.darkMode ? "custom-map-popup-dark" : "custom-map-popup-light",
        direction: direction,
        // minWidth: 450,
        maxWidth: 450,
        maxHeight: 400,
        // offset: L.point(0, 180)
      });
    } else {
      bindTarget.bindTooltip(tooltipText, {
        className: dataContext.darkMode ? "custom-map-tooltip-dark" : "custom-map-tooltip-light",
        direction: direction,
        // interactive: true,
        // offset: L.point(0, 180)
      });
    }
  }

  /**
   * Build a new legend based on a set of classes/colors in a map.
   * 
   * @param {object}  cb_map Class/Color break map with keys representing legend labels and values representing the colors.
   * @param {string}  header Title for the legend to build.
   * @param {string}  position Position of the legend. Can be one of 'topleft', 'topright', 'bottomleft', 'bottomright'
   * @param {boolean} keepOriginalText Whether to convert provided legend labels to human readable form.
   * @param {boolean} withPriority Whether to expect the keys in the provided cb_map to represent priority or not. Preserve ascending order by priority if enabled.
   * @returns Leaflet control object representing the built map legend.
   */
  const buildLegend = ({ cb_map, header, position = "topright", keepOriginalText = false, withPriority = false } = {}) => {
    let legend = L.control({ position: position });
    legend.onAdd = function () {
      // Create human readable labels for class/color break map
      let humanReadableMap = {}
      Object.keys(cb_map).map((item) => {
        let key = withPriority ? cb_map[item].label : item;
        let sep = key.includes("_") ? "_" : " ";
        let formattedKey = keepOriginalText ? key : dataContext.capitalizeWords(key, sep);

        if (withPriority) {
          humanReadableMap[item] = { label: formattedKey, color: cb_map[item].color };
        } else {
          humanReadableMap[formattedKey] = cb_map[key];
        }

        return true;
      });

      let div = L.DomUtil.create('div', 'legend-container');
      let labels = [`<strong style="color: black;">${header}</strong>`];
      let categories = withPriority ? Object.keys(humanReadableMap).sort((a, b) => parseInt(a) - parseInt(b)) : Object.keys(humanReadableMap);

      for (let item of categories) {
        let category = withPriority ? humanReadableMap[item].label : item;
        let color = withPriority ? humanReadableMap[item].color : humanReadableMap[category];
        labels.push(
          `<i class="fa fa-circle legend-icon-fonts" style="color: ${color};"><span class="legend-icon-inner-text">${category ? category : "+"}</span></i>`
        );
      }

      div.innerHTML = labels.join("<br>");
      return div;
    }

    // Finally, return the built legend for implementation
    return legend;
  }

  const onEnterFullscreen = () => {
    // TODO Handle any additional actions that should happen once the map has entered fullscreen mode
  }

  const onExitFullscreen = () => {
    // TODO Handle any additional actions that should happen once the map has exited fullscreen mode
  }

  const onPanClicked = (btn, map) => {
    if (map) {
      map.flyTo(startLoc, zoom, MAP_PAN_CONFIG);
    }
  }

  const onZoomClicked = () => {
    // TODO Handle zoom event by obtaining current zoom from map reference
  }

  const onMapMoveEnded = () => {
    // Invalidate size of map to refresh basemap resolution and fill in any rendering gaps
    if (dataContext.locSeverityMapRef.current) {
      let map = dataContext.locSeverityMapRef.current;
      map.invalidateSize();

      // Save current map location in state
      let center = map.getCenter();
      let zoom = map.getZoom();
      // console.log("center: " + center + ", zoom: " + zoom);
      setCurrentPos({ "pos": center, "zoom": zoom });

      // Sync viewport of exceedance severity map with exceedance type map
      if (dataContext.locTypeMapRef.current) {
        if (dataContext.currentMapRef.current === null || dataContext.currentMapRef.current === map) {
          dataContext.setSynchronizedMapView(
            map,
            center,
            zoom,
            dataContext.locTypeMapRef.current
          );
        }
      }
    }
  }

  const onBaseLayerChange = (baseLayer) => {
    // console.log("Changed base layer to", baseLayer);
    // One legend should always be showing
    if (baseLayer.name in baseLayersRef.current) {
      dataContext.locSeverityMapRef.current.removeControl(activeLegendRef.current);
    }
  }

  const clearControls = () => {
    // dataContext.locSeverityMapRef.current.off();
    dataContext.locSeverityMapRef.current.off("moveend", onMapMoveEnded);
    dataContext.locSeverityMapRef.current.off('enterFullscreen', onEnterFullscreen);
    dataContext.locSeverityMapRef.current.off('exitFullscreen', onExitFullscreen);
    dataContext.locSeverityMapRef.current.off("zoomstart", onZoomClicked);
    dataContext.locSeverityMapRef.current.off('baselayerchange', onBaseLayerChange);

    // Remove the overlays control from the map
    if (baseControlRef.current) {
      dataContext.locSeverityMapRef.current.removeControl(baseControlRef.current);
      baseControlRef.current = null;
    }

    if (dataControlRef.current) {
      dataContext.locSeverityMapRef.current.removeControl(dataControlRef.current);
      dataControlRef.current = null;
    }

    if (activeLegendRef.current) {
      dataContext.locSeverityMapRef.current.removeControl(activeLegendRef.current);
      activeLegendRef.current = null;
    }

    if (zoomControlRef.current) {
      dataContext.locSeverityMapRef.current.removeControl(zoomControlRef.current);
      zoomControlRef.current = null;
    }

    if (fullscreenControlRef.current) {
      dataContext.locSeverityMapRef.current.removeControl(fullscreenControlRef.current);
      fullscreenControlRef.current = null;
    }

    if (flightLocRef.current) {
      dataContext.locSeverityMapRef.current.removeControl(flightLocRef.current);
      flightLocRef.current = null;
    }

    if (scaleControlRef.current) {
      dataContext.locSeverityMapRef.current.removeControl(scaleControlRef.current);
      scaleControlRef.current = null;
    }
  }

  const buildControls = () => {
    // See https://leafletjs.com/reference.html#map-event
    // Fired when the center of the map stops changing (e.g. user stopped dragging the map or when a non-centered zoom ends).
    dataContext.locSeverityMapRef.current.on("moveend", onMapMoveEnded);

    if (!baseControlRef.current) {
      baseControlRef.current = L.control.layers(
        tileRef.current, overlayTileRef.current, { collapsed: true }
      ).addTo(dataContext.locSeverityMapRef.current);
    }

    // Add zoomControl:
    if (!zoomControlRef.current) {
      zoomControlRef.current = L.control.zoom({
        position: "topleft"
      }).addTo(dataContext.locSeverityMapRef.current);
    }

    // Add fullscreen control:
    if (!fullscreenControlRef.current) {
      fullscreenControlRef.current = L.control.fullscreen({
        position: 'topleft', // change the position of the button can be topleft, topright, bottomright or bottomleft, default topleft
        title: 'Enter fullscreen mode', // change the title of the button, default Full Screen
        titleCancel: 'Exit fullscreen mode', // change the title of the button when fullscreen is on, default Exit Full Screen
        content: null, // change the content of the button, can be HTML, default null
        forceSeparateButton: true, // force separate button to detach from zoom buttons, default false
        forcePseudoFullscreen: false, // force use of pseudo full screen even if full screen API is available, default false (will only fit bounds of parent if true)
        fullscreenElement: false // Dom element to render in full screen, false by default, fallback to map._container
      }).addTo(dataContext.locSeverityMapRef.current);

      // events are fired when entering or exiting fullscreen.
      dataContext.locSeverityMapRef.current.on('enterFullscreen', onEnterFullscreen);
      dataContext.locSeverityMapRef.current.on('exitFullscreen', onExitFullscreen);
    }

    if (!flightLocRef.current) {
      flightLocRef.current = L.easyButton({
        states: [{
          stateName: 'flight-location',
          // icon: faIconHtmlStr("fa fa-plane"),
          icon: Helicopter(),
          title: 'Pan and zoom to flight',
          onClick: onPanClicked
        }]
      }).addTo(dataContext.locSeverityMapRef.current);

      dataContext.locSeverityMapRef.current.on("zoomstart", onZoomClicked);
    }

    // Add scale control
    // See for scale options: https://leafletjs.com/reference.html#control-scale-l-control-scale
    if (!scaleControlRef.current) {
      scaleControlRef.current = L.control.scale().addTo(dataContext.locSeverityMapRef.current);
    }
  }

  /**
   * Iterate list of marker pointers and remove their click events. Returns null
   * for optional initialization of markers ref list passed in as an argument.
   * 
   * @param {array} markersRef List of marker pointers to clear click events.
   * @returns Null for setting markers ref list to null.
   */
  const clearMarkerClickEvents = (markersRef) => {
    if (markersRef) {
      for (let entry of markersRef) {
        const { marker, clickEvent } = entry;
        marker.off("click", clickEvent)
      }
    }

    // Return null for setting markers ref to null since setting null in function only has local scope
    return null;
  }

  // Detect when this component is unmounted
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    }
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      // Handle corner case for when basemap tile reference is cleared out when filters are applied
      if (!tileRef.current) {
        tileRef.current = includedBaseMaps.reduce(
          (prev, current) => {
            return {
              ...prev, [current]: L.tileLayer(dataContext.baseMaps[current].url, {
                attribution: dataContext.baseMaps[current].attribution,
                maxZoom: dataContext.MAP_MAX_ZOOM,
              })
            }
          }, {}
        );

        // Need to reset the initial map layers from null to starting map so the starting map is selected by default
        mapParams.layers = [tileRef.current[startingBasemap]];
      }

      // Controls:
      if (!dataContext.locSeverityMapRef.current) {
        dataContext.locSeverityMapRef.current = L.map(mapId, mapParams);
      }

      if (!locSeverityLayer.current) {
        locSeverityLayer.current = L.layerGroup();
      }

      if (!obstaclesLayer.current) {
        obstaclesLayer.current = L.layerGroup();
      }

      if (!flightTrackLayer.current) {
        flightTrackLayer.current = L.layerGroup();
      }

      // Add the base layer to the control:
      clearControls();
      buildControls();

      // Build legend colors based on actual track point values
      let legendColors = {
        [LOC_SEVERITY_LEGEND_NAME]: {},
      }

      // Builing polyline for map extent (fitting map bounds to polyline)
      let _polyLineCoords = [];

      // Helper function to build markers and render points from target datasets
      const addMarkers = (dataTarget, key = "track_points", buildPolyLineCoords = true, indicateStartEnd = true) => {
        if (dataTarget && key in dataTarget) {
          // Extract lookup table and data from track points
          let lookup = dataTarget[key].lookup;
          let data = dataTarget[key].data;

          const { exceedanceSeverityColorMap } = dataContext.COLOR_MAPS.current;
          Array.from(data).forEach((point, idx) => {
            let type = point[lookup.exceedance_type] || "NONE";
            type = type.toUpperCase();

            // NOTE: circle marker function requires lat/lon (y, x) coordinate format
            // See https://leafletjs.com/reference.html#circlemarker
            let coords = [point[lookup.flightstate_location_latitude], point[lookup.flightstate_location_longitude]];

            if (buildPolyLineCoords) {
              _polyLineCoords.push(coords);
            }

            // Only build markers for loss of control points
            if (type === "LOSS OF CONTROL") {
              // Create the core/center point that represents the track point
              // Note: color is stroke color (e.g., weight) and fillColor is within circle marker
              let markerRadius = dataContext.MAP_MARKER_RADIUS;
              let markerWeight = dataContext.MAP_MARKER_WEIGHT;

              let severity = point[lookup.exceedance_severity_str];
              let exceedance = point[lookup.exceedance_subtype_str];

              // Get color and label based on ground speed value
              let label = severity;
              let color;

              // Handle corner case for when type is not loc
              // if (type !== "LOSS OF CONTROL") {
              //   severity = "None";
              //   exceedance = "None";
              //   label = "None";
              // }

              color = exceedanceSeverityColorMap[severity.toUpperCase().trim()];
              let priority = dataContext.getExceedanceSeverityPriority(severity);

              legendColors[LOC_SEVERITY_LEGEND_NAME][priority] = { color: color, label: label };

              // Set the color & line weight of the start and end points
              let mColor = color;
              if (dataContext.START_END_SYMBOL_FLAG) {
                if ((idx === 0 || idx === data.length - 1) && indicateStartEnd) {
                  markerRadius += 5;
                  markerWeight += 2;

                  if (idx === 0) mColor = dataContext.FLIGHT_START_POINT_COLOR;
                  if (idx === data.length - 1) mColor = dataContext.FLIGHT_END_POINT_COLOR;
                }
              }

              let locSeverityMarker = L.circleMarker(coords, {
                radius: markerRadius,
                weight: markerWeight,
                color: mColor,
                opacity: 1.0,
                fill: true,
                fillOpacity: 1.0,
                fillColor: color
              });

              // Add non-loc values first so the markers appear behind the loc events that are added later
              // 11/11/2022: Don't add the loc markers if not loc type
              //             Only track all points for building the polyline for the flight track
              // if (type !== "LOSS OF CONTROL") {
              //   locSeverityMarker.addTo(locSeverityLayer.current);
              // }

              let locSeverityTooltipFieldsOfInterest = {
                // "Flight ID": point[lookup.flightid],
                "Latitude": point[lookup.flightstate_location_latitude_str],
                "Longitude": point[lookup.flightstate_location_longitude_str],
                "Loss of Control (LOC)": exceedance,
                "Severity": severity,
                "Time": point[lookup.human_readable_datetime],
                "Phase of Flight": point[lookup.phaseofflight_mavg10_str],
                "AGL (ft)": point[lookup.final_agl_str],
                "Ground Speed (kts)": point[lookup.groundspeed_final_kt_str],
                "Vertical Speed (f/m)": point[lookup.verticalspeed_final_fpm_str],
                "Roll (deg)": point[lookup.flightstate_position_roll_str],
                "Pitch (deg)": point[lookup.flightstate_position_pitch_str],
                "Yaw Rate (deg/s)": point[lookup.flightstate_rates_yawrate_str],
              };

              // Build tooltip for point
              // let formattedDate = new Date(point[lookup.times_timestamp]).toLocaleString('en-US', dataContext.DATE_TIME_CONFIG);
              buildFeaturedTooltip(locSeverityMarker, locSeverityTooltipFieldsOfInterest, {
                color: color,
                asPopup: false
              });

              // const onRemovePopup = () => {
              //   console.log("Removing popup from marker...");
              //   locSeverityMarker.unbindPopup();
              // }

              const onMarkerClick = () => {
                // Only build popup if it hasn't been built already
                if (!locSeverityMarker.getPopup()) {
                  // Build popup on the fly
                  buildFeaturedTooltip(locSeverityMarker, locSeverityTooltipFieldsOfInterest, {
                    color: color,
                    asPopup: true
                  });
                }

                // Manually open the popup
                locSeverityMarker.openPopup();

                // Manually close tooltip
                // NOTE: Need to add minor buffer before triggering this event to prevent it from being consumed by manual openPopup call
                setTimeout(() => {
                  locSeverityMarker.closeTooltip();
                });

                // Destroy the popup as soon as it's closed
                // NOTE: This event currently causes a memory leak
                // locSeverityMarker.getPopup().on("remove", onRemovePopup);
              }

              locSeverityMarker.on("click", onMarkerClick);

              if (!locSeverityMarkersRef.current) {
                locSeverityMarkersRef.current = [];
              }

              if (!nonLocSeverityMarkersRef.current) {
                nonLocSeverityMarkersRef.current = [];
              }

              // Keep track of the marker and the click event so it can be removed during unmount
              // if (type !== "LOSS OF CONTROL") {
              //   nonLocSeverityMarkersRef.current.push({ marker: locSeverityMarker, clickEvent: onMarkerClick });
              // } else {
              //   locSeverityMarkersRef.current.push({ marker: locSeverityMarker, clickEvent: onMarkerClick });
              // }
              locSeverityMarkersRef.current.push({ marker: locSeverityMarker, clickEvent: onMarkerClick });
            }
          });

          // Iterate through loc severity markers and add them to the loc severity layer
          if (locSeverityMarkersRef.current) {
            for (let ref of locSeverityMarkersRef.current) {
              const { marker } = ref;
              marker.addTo(locSeverityLayer.current);
            }
          }
        }
      }

      // Process data and add layers to map if they don't already exist
      if (dataContext.locSeverityMapRef.current) {
        let dataTarget = dataContext.filteredReducedData ? dataContext.filteredReducedData : dataContext.baseReducedData;

        if (dataTarget) {
          // Iterate overlays and remove them before adding new overlays
          for (var overlay of overlays) {
            dataContext.locSeverityMapRef.current.removeLayer(overlay);
          }

          // Clear overlays state
          setOverlays([]);

          let mainMarkers = true;

          // Add main track point markers
          addMarkers(dataTarget, "track_points", mainMarkers, mainMarkers);

          // Determine if the exceedance point markers should be processed as main for building polyline and start/end markers
          mainMarkers = _polyLineCoords.length === 0;

          // Add exceedance point markers to fill in any gaps if needed
          if (mainMarkers) {
            addMarkers(dataTarget, "exceedance_point", mainMarkers, mainMarkers);
          }

          // Build polyline(s) representing flight track and add to separate flight track layer
          const { polyLineCoords = _polyLineCoords } = dataTarget;
          flightTrackRef.current = new L.polyline(polyLineCoords, {
            smoothFactor: 1,
            className: 'Flight Tracks',
            color: "#00ff00", // #03436b
            weight: 2,
            highlight: true
          });

          flightTrackRef.current.addTo(flightTrackLayer.current);

          // Process and render obstacles as a layer
          let obstaclesLookup = dataTarget.obstacles.lookup;
          let obstaclesData = dataTarget.obstacles.data;
          const { obstacleProximityColorMap } = dataContext.COLOR_MAPS.current;
          Array.from(obstaclesData).forEach(obstacle => {
            // NOTE: circle marker function requires lat/lon (y, x) coordinate format
            // See https://leafletjs.com/reference.html#circlemarker
            // console.log("obstacle", obstacle);
            let coords = [obstacle[obstaclesLookup.latitude], obstacle[obstaclesLookup.longitude]];
            // console.log(coords);

            // Note: color is stroke color (e.g., weight) and fillColor is within circle marker
            let markerRadius = 6;
            let markerWeight = 3;
            // let borderColorOff = "#003388FF";
            // let borderColorOn = "#000000";
            // let fillColorOff = "#009E0505";
            // let fillColorOn = "#272D66";

            // Color code obstacles by threat_class
            let threat_class = obstacle[obstaclesLookup.threat_class] || "None";
            let label = threat_class.toUpperCase().trim();
            let fillColorOn = obstacleProximityColorMap[label];
            let borderColorOn = fillColorOn;
            // legendColors[OBSTACLES_LEGEND_NAME][threat_class] = fillColorOn;

            //Load obstacle icon symbols based on the obstacle type and threat level (color)
            var obstacleMarker;
            let symbolSize = dataContext.OBSTACLE_ICON_SIZE;

            //Process the obstacle type string before get its icon name from the predefined dictionary
            let obstacleType = obstacle[obstaclesLookup.type].trim().replace(" ", "_");
            if (dataContext.obstacleTypeSymbols[obstacleType] !== undefined) {
              let obstacleIconColor = dataContext.obstacleTypeSymbols[obstacleType];
              let obstacleIcon = obstacleIconColor["icon"];
              //let obstacleColor = obstacleIconColor["color"];

              //Get the icon
              let obstacleIconSymbol = L.divIcon({
                html: `<i class="${obstacleIcon}" style="font-size:${symbolSize}px; color:${fillColorOn};"></i>`,
                iconSize: [symbolSize, symbolSize],
                className: 'obstacle-symbol-icon'
              });

              //Add the icon to the marker
              obstacleMarker = L.marker(coords, {
                icon: obstacleIconSymbol
              }).addTo(obstaclesLayer.current);

            }
            //If the type doesn't exist in the predefined dictionary, use a circle marker
            else {
              obstacleMarker = L.circleMarker(coords, {
                radius: markerRadius,
                weight: markerWeight,
                color: borderColorOn,
                opacity: 1.0,
                fill: true,
                fillOpacity: 1.0,
                fillColor: fillColorOn,
              }).addTo(obstaclesLayer.current);
            }

            let obstaclesFieldsOfInterest = {
              "Latitude": dataContext.roundStr(obstacle[obstaclesLookup.latitude], 6),
              "Longitude": dataContext.roundStr(obstacle[obstaclesLookup.longitude], 6),
              "Threat": dataContext.capitalizeWords(obstacle[obstaclesLookup.threat_class]),
              "Obstacle Id": obstacle[obstaclesLookup.obstacle_number],
              "Obstacle": obstacle[obstaclesLookup.type],
              "AGL Height (ft)": dataContext.roundStr(obstacle[obstaclesLookup.agl_height]),
              "MSL Height (ft)": dataContext.roundStr(obstacle[obstaclesLookup.msl_height]),
              "Lighting": obstacle[obstaclesLookup.lighting],
            }

            // Add listeners/tooltips to obstacle markers as needed
            // Build tool tip off all properties
            buildFeaturedTooltip(obstacleMarker, obstaclesFieldsOfInterest, {
              color: fillColorOn,
              asPopup: false
            });

            // const onRemovePopup = () => {
            //   console.log("Removing popup from marker...");
            //   obstacleMarker.unbindPopup();
            // }

            const onMarkerClick = () => {
              // Only build popup if it hasn't been built already
              if (!obstacleMarker.getPopup()) {
                // Build popup on the fly
                buildFeaturedTooltip(obstacleMarker, obstaclesFieldsOfInterest, {
                  color: fillColorOn,
                  asPopup: true
                });
              }

              // Manually open the popup
              obstacleMarker.openPopup();

              // Manually close tooltip
              // NOTE: Need to add minor buffer before triggering this event to prevent it from being consumed by manual openPopup call
              setTimeout(() => {
                obstacleMarker.closeTooltip();
              });

              // Destroy the popup as soon as it's closed
              // NOTE: This event currently causes a memory leak
              // obstacleMarker.getPopup().on("remove", onRemovePopup);
            }

            obstacleMarker.on("click", onMarkerClick);

            if (!obstacleMarkersRef.current) {
              obstacleMarkersRef.current = [];
            }

            // Keep track of the marker and the click event so it can be removed during unmount
            obstacleMarkersRef.current.push({ marker: obstacleMarker, clickEvent: onMarkerClick });
          });

          // NOTE: Only add one layer initially for event listeners to work (if other layers exist)
          flightTrackLayer.current.addTo(dataContext.locSeverityMapRef.current); // Fight track layer in back
          locSeverityLayer.current.addTo(dataContext.locSeverityMapRef.current);

          setOverlays([
            flightTrackLayer.current,
            locSeverityLayer.current,
            obstaclesLayer.current,
          ]);

          // Build base layer for switch control between VRS, exceedances, etc.
          baseLayersRef.current = {

          };

          // Build overlay map for layer control to contain all layers
          overlayLayersRef.current = {
            [OBSTACLES_LAYER_NAME]: obstaclesLayer.current,
          }

          // Build flight track polyline to calculate boundaries of flight to zoom/pan on
          let polyline = flightTrackRef.current;
          let validBounds = polyLineCoords.length > 0;

          // Pan map viewport to extent of flight based on flight track polyline bounds
          let trackPointsCheck = dataTarget.track_points && dataTarget.track_points.data && dataTarget.track_points.data.length > 0;
          let exceedancePointsCheck = dataTarget.exceedance_point && dataTarget.exceedance_point.data && dataTarget.exceedance_point.data.length > 0;
          if (trackPointsCheck || exceedancePointsCheck) {
            // Remove cached panning control if it exists and polyline bounds are valid
            if (validBounds) {
              if (flightLocRef.current) {
                dataContext.locSeverityMapRef.current.removeControl(flightLocRef.current);
              }

              // Reset panning control
              flightLocRef.current = L.easyButton({
                states: [{
                  stateName: 'flight-location',
                  // icon: faIconHtmlStr("fa fa-plane"),
                  icon: Helicopter(),
                  title: 'Pan and zoom to flight',
                  onClick: function (btn, map) {
                    map.fitBounds(polyline.getBounds());
                  }
                }]
              }).addTo(dataContext.locSeverityMapRef.current);
            }

            // Set map view to position of first coordinate only if data has not been loaded before and the current position state isn't null
            try {
              if (dataContext.locSeverityMapRef.current && isMounted.current) {
                // Don't animate on map load
                const config = { ...MAP_PAN_CONFIG, animate: false, duration: 0 };
                if (initLoad) {
                  if (validBounds) {
                    dataContext.locSeverityMapRef.current.fitBounds(polyline.getBounds());
                  }

                  setInitLoad(false);
                } else {
                  if (currentPos) {
                    dataContext.locSeverityMapRef.current.flyTo(currentPos.pos, currentPos.zoom, config);
                  } else {
                    if (validBounds) {
                      dataContext.locSeverityMapRef.current.fitBounds(polyline.getBounds());
                    }
                  }
                }
              }
            } catch (error) {
              console.error(error);
            }
          }

          // Create the control layers tree now that the layers have been created
          dataControlRef.current = L.control.layers(
            baseLayersRef.current, overlayLayersRef.current, { collapsed: false, position: "topleft" }
          ).addTo(dataContext.locSeverityMapRef.current);

          // Legends ****
          // Only include color mappings for existing data
          // Build legend for ground speed
          let exceedanceSeverityLegend = buildLegend({ 
            cb_map: legendColors[LOC_SEVERITY_LEGEND_NAME], 
            header: locSeverityMarkersRef.current && locSeverityMarkersRef.current.length > 0 ? LOC_SEVERITY_LEGEND_NAME: "No Loss of Control (LOC) Points", 
            withPriority: true 
          });

          exceedanceSeverityLegend.addTo(dataContext.locSeverityMapRef.current);

          // Build legends map when different base layers have differnet legends to swap between
          // legendsMapRef.current = {
          //   [LOC_SEVERITY_LAYER_NAME]: exceedanceSeverityLegend,
          // }

          // Set initial active legend
          activeLegendRef.current = exceedanceSeverityLegend;

          // Add event listener to VRS layer control so the VRS legened is toggled accordingly
          // NOTE: To prevent bug with baselayerchange event not triggering on first base layer change, only add one of the base layers from layer control
          //       initially to the map. Include all base layers in the associated base layers object that is passed into the L.control.layers constructor.
          dataContext.locSeverityMapRef.current.on('baselayerchange', onBaseLayerChange);
        }
      }
    }

    // Unmount post-processing hook
    return () => {
      clearControls();
      locSeverityLayer.current.clearLayers();
      obstaclesLayer.current.clearLayers();
      flightTrackLayer.current.clearLayers();

      locSeverityMarkersRef.current = clearMarkerClickEvents(locSeverityMarkersRef.current);
      nonLocSeverityMarkersRef.current = clearMarkerClickEvents(nonLocSeverityMarkersRef.current);
      obstacleMarkersRef.current = clearMarkerClickEvents(obstacleMarkersRef.current);

      // Dynamically iterate all layers and remove them
      dataContext.locSeverityMapRef.current.eachLayer(function (layer) {
        dataContext.locSeverityMapRef.current.removeLayer(layer);
      });

      dataContext.locSeverityMapRef.current._initEvents(true);
      dataContext.locSeverityMapRef.current._stop();
      dataContext.locSeverityMapRef.current.remove();

      // Delete all references and initialize state once the component is unmounted
      if (dataContext.currentMapRef.current !== null) {
        dataContext.currentMapRef.current = null;
      }

      dataContext.locSeverityMapRef.current = null;
      tileRef.current = null;
      overlayTileRef.current = null;
      baseControlRef.current = null;
      baseLayersRef.current = null;
      overlayLayersRef.current = null
      zoomControlRef.current = null;
      fullscreenControlRef.current = null;
      scaleControlRef.current = null;
      dataControlRef.current = null;
      flightLocRef.current = null;
      legendsMapRef.current = null;
      activeLegendRef.current = null;
      flightTrackRef.current = null;

      locSeverityLayer.current = null;
      obstaclesLayer.current = null;
      flightTrackLayer.current = null;

      setOverlays(null);
      setCurrentPos(null);
      setInitLoad(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.baseReducedData, dataContext.filteredReducedData]);

  return (
    <div id={mapId} style={mapStyles} />
  )
}

LocSeverityMap.propTypes = propTypes;
LocSeverityMap.defaultProps = defaultProps;

export default LocSeverityMap;
