import React from 'react'
import { useLocation } from "react-router-dom";
import {
    TextInput,
    Label,
    Dropdown
} from '@trussworks/react-uswds'


const ManageApproval = () => {

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();
    var username = query.get('user')
    return (
        <div className="stretch_grid-container">
            <h1>User to edit: {username}</h1>
            <h1>User Registration Approval Request</h1>
            <p>Confirm users information below</p>


            <Label htmlFor="first-name">First Name</Label>
            <TextInput 
                id="first-name" 
                name="first-name" 
                type="text" 
                onChange={evt => this.onChange('username', evt.target.value)}
                required
            />
            <Label htmlFor="first-name">Last Name</Label>
            <TextInput 
                id="last-name" 
                name="last-name" 
                type="text" 
                onChange={evt => this.onChange('username', evt.target.value)}
                required
            />

            <Label htmlFor="email-input">Email</Label>
            <TextInput 
                id="email-input" 
                name="email-input" 
                type="email" 
                onChange={evt => this.onChange('username', evt.target.value)}
                required
            />

            <Label htmlFor="organization">Organization</Label>
            <p id="organization">Helos Flights Inc.</p>


            <Label htmlFor="org-dropdown">Organization</Label>
            <Dropdown id="org-dropdown" name="input-dropdown">
                <option>- Select - </option>
                <option value="value1">Option A</option>
                <option value="value2">Option B</option>
                <option value="value3">Option C</option>
            </Dropdown>


        </div>
       
    )
}

export default ManageApproval


