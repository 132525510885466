import {API} from "aws-amplify";

export function updateUserProfile(authUser, user) {
    const token = authUser.signInUserSession.idToken.jwtToken;
    const requestInfo = {
      headers: {
        Authorization: token
      },
      body: {
        'name': user.name,
        'email':user.email,
        'phoneNumber':user.phoneNumber,
        'secondaryPhone': user.secondaryPhone,
        'phoneIsMobile': user.phoneIsMobile,
        'notificationPref': user.emailNotifications,
        // 'userIdentifier': user.userIdentifier
      }
    }
  // console.log({requestInfo})
    var url = "/update-user-profile"
    
  // console.log("Debugging")
  // console.log({url})

    const data = API.post('raise-gui-api-dev', url, requestInfo)
  // console.log({data})
      return data;

   
}

export default updateUserProfile;