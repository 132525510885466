import React from 'react'
import {
    Tag
} from '@trussworks/react-uswds'
import { FaCheckCircle } from 'react-icons/fa'

const UserStatusTag = (props) => {
    var color = '#94FA8B'
    if (!props.message) {
        color = '#FAF28B'
    }
    if (props.denied) {
        color = 'red'
    }

    let enabledMessage = props.denied ? 'Denied' : props.message ? 'Enabled' : 'Disabled'

    return (
        <div>
            <Tag 
                style={{color: 'black', backgroundColor: color, 'textDecoration': 'none'}}>
                <FaCheckCircle /> {enabledMessage}
            </Tag>
        </div>
    )
}

export default UserStatusTag
