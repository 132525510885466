import React from 'react'

const AdminSettings = () => {
    return (
        <div className="stretch_grid-container">
            <h1>Only admins should be able to view this page</h1>
        </div>
    )
}

export default AdminSettings
