import React, {useState, useEffect, useContext} from 'react'

import getDashboardById from '../services/getDashboardById'
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk'
import UserContext from './UserContext';
import { Col } from 'react-bootstrap';

    const TestDashboardWidgets  = (props) => {

    // const [state, setState] = useState([])
    const [url, setUrl] = useState({})
    const [isLoaded, setIsLoaded] = useState(false)
    // const [error, setError] = useState({})
    const dashName= props.dashboardType + "dashboardContainer"
    const [lastLoadedId, setLastLoadedId] = useState({})
    const [lastLoadedExceedance, setLastLoadedExceedance] = useState({})
    const [dashboard, setDashboard] = useState()
    


    // state = {
    //     url: {},
    //     isLoaded: false,
    //     error: {},
    //     loadedAggregate: false,
    //     dashName: this.props.dashboardType + "dashboardContainer",
    //     lastLoadedId: '',
    //     lastLoadedExceedance: '',
    // }
    const userContext = useContext(UserContext)  

    useEffect(() => {
        // console.log({flight})
        // setFlightId(flight.id)
        
        // console.log(props.flight.flightmetadata__id)
        getDashboardById(userContext.user, props.dashboardType, false, false)
        .then((results) => {
            if ('EmbedUrl' in results) {
                setUrl(results.EmbedUrl);
                setIsLoaded(true);
            }
        })
        .catch((error) => {
            // setError(error);
            console.log(error)
        })
    }, [])

    useEffect(() => {
        if(dashboard){
            // console.log(props.flight.flightmetadata__id)
            dashboard.setParameters({flightid:props.flight.flightmetadata__id})
        }
    }, [props.flight.flightmetadata__id])

    useEffect(() => {
        if(dashboard){
            // console.log(props.exceedance)
            dashboard.setParameters({exceedanceType:props.exceedance ? props.exceedance : "[ALL]"})
        }
    }, [props.exceedance])

    const handleParamChangeCallback = ((event) => {
        // console.log({event})
      })
    

    const getQuicksightDashboardEmbedURL = (embedUrl) => {
        // console.log("Rendering Dashboard")
        const containerDiv = document.getElementById(dashName);
        // console.log("Params:")
        // console.log(props.flight.flightmetadata__id)
        // console.log(props.exceedance)

         //clear any existing embeds so we can embed a new one and not duplicate
         while (containerDiv.lastChild){
            // console.log("Remove a child comp")
            containerDiv.removeChild(containerDiv.lastChild)
        }

        const options = {
            url: embedUrl,
            container: containerDiv,
            parameters: {
                flightid : props.flight.flightmetadata__id,
                exceedanceType : props.exceedance ? props.exceedance : "[ALL]"
            },
            scrolling: "no",
            height: "AutoFit",
            iframeResizeOnSheetChange : true,
            // width: "960px",
            // undoRedoDisabled: true,
            // resetDisabled: true,
            parametersChangeCallback: handleParamChangeCallback,
            // footerPaddingEnabled: true,
        };
    
      // console.log({options})
        setDashboard(QuickSightEmbedding.embedDashboard(options));
        setLastLoadedId(props.flight)
        setLastLoadedExceedance(props.exceedance)
    }
    
    if(isLoaded && !dashboard) {
        if((props.flight && props.flight !== lastLoadedId) || (props.exceedance && props.exceedance !== lastLoadedExceedance)) {
            getQuicksightDashboardEmbedURL(url)
            // setLastLoadedId(propFlight.flightmetadata__id)
        }

        }

        
    return (
            <>
            {props.dashboardTitle ?
            <Col md="auto">
                <h4 style={{minWidth:"200px",alignSelf:"center"}}>{props.dashboardTitle}</h4>
                </Col>
            : <></>}
                <Col>
                <div id={dashName}></div>
                </Col>
            </>
        
    )
}

export default TestDashboardWidgets