import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter as Router} from 'react-router-dom'
import '@trussworks/react-uswds/lib/uswds.css'
import '@trussworks/react-uswds/lib/index.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import UserContext, { UserProvider } from './components/UserContext'


import config from './aws-exports'
import {Amplify} from 'aws-amplify'
import 'bootstrap/dist/css/bootstrap.min.css';

// Amplify.configure(config)


Amplify.configure({
  //Auth from aws-exports.  Manually done until we can configure amplify to import existing API projects
    Auth:{
      cookieStorage: {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        path:'/',
        expires: 7,
        secure: false
      }
    },
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": process.env.REACT_APP_AMPLIFY_IDENTITYPOOL_ID,
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": process.env.REACT_APP_AMPLIFY_USERPOOL_ID,
    "aws_user_pools_web_client_id": process.env.REACT_APP_AMPLIFY_WEBCLIENT_ID,
    "oauth": {
        "domain": process.env.REACT_APP_AMPLIFY_OAUTH,
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid"
        ],
        "redirectSignIn": "http://localhost:3000/",
        "redirectSignOut": "http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    API:{
      endpoints: [
        {
          name: 'raise-gui-api-dev',
          endpoint: process.env.REACT_APP_API + process.env.REACT_APP_USER_BRANCH,
        },
      ],
    },
});


ReactDOM.render(
  <UserProvider >
    <Router>
      <App />
    </Router>
    </UserProvider>, 
  document.getElementById('root')
);
