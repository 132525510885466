import React, { useState, useRef, useEffect, useContext } from 'react';
import { DataContext } from "../../DataContext";
// import PropTypes from 'prop-types';
import CanvasJSReact from '../../../canvasjs-commercial-3.4.12/canvasjs.react';
import { useResizeDetector } from 'react-resize-detector';
import Menu from '@mui/material/Menu';
import ApproachSubchart from './ApproachSubchart';
import '../Chart.css';
import { ConstructionOutlined } from '@mui/icons-material';

// const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const propTypes = {

};

const defaultProps = {

};

// Control/Configure the size of the markers and lines in the chart
const DATA_POINT_SIZE = 8;
const LINE_THICKNESS = 2;

const appUnsTranslation = {
  "0": "Stable",
  "1": "Unstable LOW",
  "2": "Unstable MEDIUM",
  "3": "Unstable HIGH"
}

const humanReadableApproach = {
  'VFR':'Visual',
  'IFR':'Instrument',
  'VFR-Missed':'Go-Around',
  'IFR-Missed':'IFR-Missed',
  'Visual':'VFR',
  'Instrument':'IFR',
  'Go-Around':'VFR-Missed',
  'IFR-Missed':'IFR-Missed',
  'Stable':'Stable',
  "Unstable HIGH":"Unstable HIGH",
  "Unstable MEDIUM":"Unstable MEDIUM",
  "Unstable LOW":"Unstable LOW",

}

// NOTE: Data points list with empty object (e.g., [{}]) will cause render in legend but no data points needed on chart
const LEGEND_CONFIG_TEMPLATE = {
  legendText: "",
  showInLegend: true,
  legendMarkerColor: "",
  legendMarkerType: "square",
  dataPoints: [{}]
};

function LocGroundSpeedScatterChart(props) {
  // Obtain reference to global data context consumer
  const dataContext = useContext(DataContext);

  const { width, height, ref: resizeDetectorRef } = useResizeDetector();

  // Keep reference of chart for event listeners/actions
  const chartRef = useRef(null);
  const isMounted = useRef(false);

  // Approach series lists
  const [approachSubchart, setApproachSubchart] = useState({})

  // Determine if data exists
  const d = dataContext.filteredReducedData ? dataContext.filteredReducedData : dataContext.baseReducedData;
  const trackPointDataExists = d && d.track_points && d.track_points.data && d.track_points.data.length > 0;
  const exceedancePointDataExists = d && d.exceedance_point && d.exceedance_point.data && d.exceedance_point.data.length > 0;
  const dataExists = trackPointDataExists || exceedancePointDataExists;

  const [dataOfInterestExists, setDataOfInterestExists] = useState(true);
  const [dataCalculated, setDataCalculated] = useState(true)

  const { approachColorMap, appSevColorMap } = dataContext.COLOR_MAPS.current;
  const [approachSet, setApproachSet] = useState(new Set())

  // Approach details popover controls
  const [menuOpen, setMenuOpen] = useState(null)
  const open = Boolean(menuOpen)
  


  /**
   * Handler for parsing date object to a formatted string version.
   * 
   * @param {object} e CanvasJS label event object containing the current date value.
   * @returns The formatted date string.
   */
  const dateLabelFormatter = (e) => {
    return dataContext.toHumanReadableDateStr(e.value, true);
  }

  function addApproachSet(e){
    setApproachSet(prevSet => new Set(prevSet.add(e)))
      }

  /**
   * Build and retrieve formatted HTML string representing a featured
   * tooltip given a set of values for a particular row of interest.
   * 
   * row = {
   *   "key_1": "value_1",
   *   "key_2": "value_2",
   *   ...
   *   "key_n": "value_n"
   * }
   * 
   * @param {object} row Fields of interest for current row in dataset.
   * @param {string} color Hexidecimal or RGB(A) color string.
   * @returns Formatted HTML string representing a featured tooltip.
   */
  const getFeaturedTooltip = (row, color = null) => {
    // Build tool tip off all properties
    var tooltipText = "";

    // Start table
    tooltipText += "<table>";
    for (const [key, value] of Object.entries(row)) {
      let iconData = `<td><i class="fa fa-square tooltip-icon-fonts" style="color: ${color}; margin-right: 5px;"></i></td>`;
      tooltipText += `
        <tr>
          ${color !== null && iconData}
          <td style="color: #989898"><b>${key}&nbsp</b></td>
          <td><b>${value}</b></td>
        </tr>
      `;
    }

    // End table
    tooltipText += "</table>";
    return tooltipText;
  }

  // See CanvasJS configuration options at https://canvasjs.com/docs/charts/basics-of-creating-html5-chart/
  // Value format string: https://canvasjs.com/docs/charts/chart-options/axisx/valueformatstring/
  const [options, setOptions] = useState({
    zoomEnabled: true,
    animationEnabled: true,
    title: {
      text: ""
    },
    theme: dataContext.darkMode ? "dark1" : "light1",
    toolTip: {
      enabled: true,          //disable here
      animationEnabled: true, //disable here
      contentFormatter: e => {
        var content = "";
        for (var i = 0; i < e.entries.length; i++) {
          let dataPoint = e.entries[i].dataPoint;
          content = `${content}${dataPoint.tooltipText}`;
        }

        return content;
      }
    },
    axisX: {
      labelFontFamily: dataContext.CHART_FONTS.fontFamily,
      labelFontWeight: dataContext.CHART_FONTS.fontWeight,
      labelAutoFit: true,
      labelAngle: 0,
      title: "",
      gridThickness: 0.5,
      // See: https://canvasjs.com/docs/charts/basics-of-creating-html5-chart/formatting-date-time/
      labelFormatter: dateLabelFormatter,
      labelFontSize: 13,
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
        labelFormatter: dateLabelFormatter,
      },
      // interval: 2,
      // intervalType: "minute",
    },
    axisY: {
      gridThickness: 0.5,
      labelFontFamily: dataContext.CHART_FONTS.fontFamily,
      labelFontSize: dataContext.CHART_FONTS.fontSize,
      labelFontWeight: dataContext.CHART_FONTS.fontWeight,
      title: ""
    },
    legend: {
      fontFamily: dataContext.CHART_FONTS.fontFamily,
      fontSize: dataContext.CHART_FONTS.fontSize,
      fontWeight: dataContext.CHART_FONTS.fontWeight,
    },
    data: [
      { type: "spline", dataPoints: [] }
    ]
  });

  const [subchartOptions, setSubchartOptions] = useState({
    zoomEnabled: true,
    animationEnabled: true,
    title: {
      text: ""
    },
    theme: dataContext.darkMode ? "dark1" : "light1",
    toolTip: {
      enabled: true,          //disable here
      animationEnabled: true, //disable here
      contentFormatter: e => {
        var content = "";
        for (var i = 0; i < e.entries.length; i++) {
          let dataPoint = e.entries[i].dataPoint;
          content = `${content}${dataPoint.tooltipText}`;
        }

        return content;
      }
    },
    axisX: {
      labelFontFamily: dataContext.CHART_FONTS.fontFamily,
      labelFontWeight: dataContext.CHART_FONTS.fontWeight,
      labelAutoFit: true,
      labelAngle: 0,
      title: "",
      gridThickness: 0.5,
      // See: https://canvasjs.com/docs/charts/basics-of-creating-html5-chart/formatting-date-time/
      labelFormatter: dateLabelFormatter,
      labelFontSize: 13,
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
        labelFormatter: dateLabelFormatter,
      },
      // interval: 2,
      // intervalType: "minute",
    },
    axisY: {
      gridThickness: 0.5,
      labelFontFamily: dataContext.CHART_FONTS.fontFamily,
      labelFontSize: dataContext.CHART_FONTS.fontSize,
      labelFontWeight: dataContext.CHART_FONTS.fontWeight,
      title: ""
    },
    legend: {
      fontFamily: dataContext.CHART_FONTS.fontFamily,
      fontSize: dataContext.CHART_FONTS.fontSize,
      fontWeight: dataContext.CHART_FONTS.fontWeight,
    },
    data: [
      { type: "spline", dataPoints: [] }
    ]
  });

  function onClick(e) {
    // console.log(e)
    if(e.dataPoint.approach_id){
    // console.log(e.dataPoint.approach_id)
    // console.log(approachSet)
    // setMenuOpen(e.dataPoint.approach_id)
    props.openMenuItemWithAnchor(e.dataPoint.approach_id, resizeDetectorRef.current)
    }
    // console.log("Back up alert")
    // console.log(e)
    // alert(e.dataPoint.approach_id + " was clicked")
  }

  const handleClose = () => {
    setMenuOpen(null);
  };

  // Component mount/unmount
  useEffect(() => {
    isMounted.current = true;

    // NOTE: identify document element by id on component mount to ensure canvas js chart wraps to parent container
    if (resizeDetectorRef.current) {
      let chartContainer = resizeDetectorRef.current.children[0]; // e.g., canvasjs-react-chart-container-2
      if (chartContainer) {
        chartContainer.style.height = "100%";
        chartContainer.style.width = "100%";
      }
    }

    return () => {
      isMounted.current = false;

      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }

      setOptions({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Detect changes to incomming/ingested data
  useEffect(() => {
    if (isMounted.current) {
      // Obtain reference to active data from context
      let dataTarget = dataContext.filteredReducedData ? dataContext.filteredReducedData : dataContext.baseReducedData;

      if (dataTarget) {
        // Extract lookup table and data from track points or exceedance points if track points aren't available
        let trackDataExists = dataTarget.track_points && dataTarget.track_points.data && dataTarget.track_points.data.length > 0;
        let trackPointsTarget = trackDataExists ? dataTarget.track_points : dataTarget.exceedance_point;

        const { data, lookup } = trackPointsTarget; // Extract lookup table from track points data
        const { exceedanceTypeColorMap } = dataContext.COLOR_MAPS.current;
       
        // Manage new data points and assign colors in legend
        let legendPlaceholdersLookup = {};

        // Extract only required data key/values for rendering data points and tooltips from track points data
        let dataPoints = [];
        let vrsCalculated = dataTarget && dataTarget.flight_meta ? dataTarget.flight_meta.data[0][dataTarget.flight_meta.lookup["vrs_calculated"]] : true;
        let lteCalculated = dataTarget && dataTarget.flight_meta ? dataTarget.flight_meta.data[0][dataTarget.flight_meta.lookup["lte_calculated"]] : true;
        let approachDataPoints = {};
        let colorLegendSeries = [{}];
        // console.log(data)
        // console.log(lookup)
        // find min/max date for scale of x axis
        let dateMin = undefined;
        let dateMax = undefined;
        let colorLegend = new Set();
        let stripLineBuilder = {};
        

        Array.from(data).forEach((point) => {
          let timestampStr = point[lookup.times_timestamp];
          let groundSpeed = point[lookup.groundspeed_final_kt];

          vrsCalculated = point[lookup.vrs_calculated] === true || point[lookup.vrs_calculated] === false ? vrsCalculated && point[lookup.vrs_calculated] : vrsCalculated
          lteCalculated = point[lookup.lte_calculated] === true || point[lookup.lte_calculated] === false ? lteCalculated && point[lookup.lte_calculated] : lteCalculated

          
          if (timestampStr !== null && groundSpeed !== null) {

            let type = point[lookup.exceedance_type] || "NONE";
            type = type.toUpperCase();

            let color = exceedanceTypeColorMap["NONE"]; // Default color

            if (type === "LOSS OF CONTROL") {
              let exceedance = point[lookup.exceedance_subtype] || "NONE";
              exceedance = exceedance.toUpperCase().trim();
              color = exceedanceTypeColorMap[exceedance];
            }

            let loc = type === "LOSS OF CONTROL" ? point[lookup.exceedance_subtype_str] : "None";
            
            // Add new legend placeholder entry if needed
            if (!(loc in legendPlaceholdersLookup)) {
              legendPlaceholdersLookup[loc] = {
                ...LEGEND_CONFIG_TEMPLATE,
                legendText: loc,
                legendMarkerColor: color,
              };
            }

            let pointDate = new Date(timestampStr)
            let fieldsOfInterest = {
              "Time": point[lookup.human_readable_datetime],
              "Loss of Control (LOC)": loc,
              "Phase of Flight": point[lookup.phaseofflight_mavg10_str],
              "Cyclic Position Pitch (%)": point[lookup.flightcontrols_cyclicpositionpitch_str],
              "AGL (ft)": point[lookup.final_agl_str],
              "Ground Speed (kts)": point[lookup.groundspeed_final_kt_str],
              "Vertical Speed (f/m)": point[lookup.verticalspeed_final_fpm_str],
              "Roll (deg)": point[lookup.flightstate_position_roll_str],
              "Pitch (deg)": point[lookup.flightstate_position_pitch_str],
              "Yaw Rate (deg/s)": point[lookup.flightstate_rates_yawrate_str],
            };

            let tooltipText = getFeaturedTooltip(fieldsOfInterest, color);

            
            dataPoints.push({
              flightid: point[lookup.flightid],
              x: pointDate,
              y: dataContext.round(groundSpeed),
              color: color,
              tooltipText: tooltipText,
            });

            if (dateMin === undefined && dateMax === undefined) {
              dateMin = pointDate;
              dateMax = pointDate;
            }

             // Update date min
             if (pointDate < dateMin) {
              dateMin = pointDate;
            }

            // Update date max
            if (pointDate > dateMax) {
              dateMax = pointDate;
            }
          }
        });


         // NOTE: Data points list with empty object (e.g., [{}]) will cause render in legend but no data points needed on chart
         // build a list of legends from colorLegend
        //  console.log(colorLegend)
        // Array.from(colorLegend).sort().forEach((element) => {
        //   colorLegendSeries.push({
        //     legendText: element,
        //   showInLegend: true,
        //   legendMarkerColor: appSevColorMap[humanReadableApproach[element]] ? appSevColorMap[humanReadableApproach[element]] : approachColorMap[humanReadableApproach[element]],
        //   legendMarkerType: "square",
        //   dataPoints: [{}]
        //   })
        // })


        // Iterate through data points and set the line colors to the color of the next point (for variable color shading support)
        Array.from(dataPoints).forEach((currentPoint, idx) => {
          let nextPointExists = (idx + 1) <= dataPoints.length - 1;
          let nextPoint = nextPointExists ? dataPoints[idx + 1] : null;
          currentPoint.lineColor = nextPoint !== null ? nextPoint.color : currentPoint.color;
        });

        // Data points of interest exist when at least one data point exists in the list
        setDataOfInterestExists(dataPoints.length > 0);
        setDataCalculated(vrsCalculated && lteCalculated)

        
        // Get the minimum and maximum possible y values to set appropriate scale for y-axis (and doesn't change when zooming)
        const { min, max } = dataContext.getMinMaxValues(data, lookup.groundspeed_final_kt);
        let padding = (dataContext.getMagnitude(max) / 10) * 5;
        // Adjust padding if it is 0, which indicates all data points have the same y-axis value (e.g., min and max are equal)
        padding = padding === 0 ? 0.1 : padding;

        // Object.keys(approachDataPoints).map(key => {
        //   approachDataPoints[key].options = {...options,
        //     // height:200,
        //   axisX: {
        //     ...options.axisX,
        //     title: "Time"
            
        //     // minimum: dataPoints ? dateMin : undefined,
        //     // maximum: dataPoints ? dateMax : undefined,
        //   },
        //   axisY: {
        //     ...options.axisY,
        //     title:  "Altitude (ft)"
            
        //     // minimum: min - padding,
        //     // maximum: max + padding,
        //   },
        //   data: [
              
        //     {
        //       legendText: approachDataPoints[key].actual.legend.legendText,
        //       showInLegend: approachDataPoints[key].actual.legend.showInLegend,
        //       color: approachDataPoints[key].actual.legend.legendMarkerColor,
              
        //       type: "line",
        //       lineThickness: LINE_THICKNESS,
        //       dataPoints: approachDataPoints[key].actual
        //     },
        //     {
        //       legendText: approachDataPoints[key].nominal.legend.legendText,
        //       showInLegend: approachDataPoints[key].nominal.legend.showInLegend,
        //       color: approachDataPoints[key].nominal.legend.legendMarkerColor,
        //       markerType: 'none',
        //       type: "line",
        //       lineDashType: "dash",
        //       // can't dash legend lines, so we work around
        //       legendMarkerType: "triangle",
        //       legendMarkerColor: "white",
        //       lineThickness: LINE_THICKNESS,
        //       dataPoints: [approachDataPoints[key].nominal[0],
        //                    approachDataPoints[key].nominal[approachDataPoints[key].nominal.length - 1]]
        //     }
        //   ]}
        // })
        // // console.log(approachDataPoints)
        // setApproachSubchart(approachDataPoints)

        // create strip line block
        // console.log(stripLineBuilder)
        // var stripLineBlock = []
        // Object.keys(stripLineBuilder).map(key => {
        //   stripLineBlock.push({
        //     startValue:stripLineBuilder[key] ? stripLineBuilder[key]['startPoint'] : '',
        //       endValue:stripLineBuilder[key] ? stripLineBuilder[key]['endPoint'] : '',
        //       color:"#A6DCF2"
        //   })
        // })
        // Create formatted data structure for canvasjs chart
        // See data series attributes at https://canvasjs.com/docs/charts/chart-options/data/
        // See performance improvement considerations at https://canvasjs.com/forums/topic/performance-improvement-in-scatter-chart/
        setOptions({
          ...options,
          axisX: {
            ...options.axisX,
            minimum: dataPoints ? dateMin : undefined,
            maximum: dataPoints ? dateMax : undefined,
          },
          axisY: {
            ...options.axisY,
            minimum: min - padding,
            maximum: max + padding,
          },
          data: [
            {
              type: "spline",
              markerType: "square",
              lineThickness: LINE_THICKNESS,
              markerSize: DATA_POINT_SIZE,
              dataPoints: dataPoints,
            },
          ]
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.baseReducedData, dataContext.filteredReducedData]);

  useEffect(() => {
    if (isMounted) {
      // Re-render chart if there's a change in theme
      if (chartRef.current) {
        // NOTE: must do it this way since options are copied to chart reference (e.g., not bound by state/props)
        chartRef.current.options.theme = dataContext.darkMode ? "dark1" : "light1";
        chartRef.current.render();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.darkMode]);

  // Dynamically update width and height of chart to fill parent container on dimension change
  useEffect(() => {
    if (isMounted) {
      if (chartRef.current) {
        let yOffset = 20;
        let dh = height - yOffset;
        if (width > 0 && dh > 0) {
          chartRef.current.options.width = width;
          chartRef.current.options.height = dh;
          chartRef.current.render();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height]);

  return (
    <div ref={resizeDetectorRef} style={{ width: "100%", height: "100%" }}>
        
    
    
      {(dataExists && dataOfInterestExists && dataCalculated) && (
        
        <CanvasJSChart options={options} onRef={ref => chartRef.current = ref} />
      )}
      {(!dataExists) && (
        <div>
          <b>No Data</b>
        </div>
      )}
      {(dataExists && !dataCalculated) && (
        <div>
          {/* Customize message as needed */}
          <b>Insufficient Data</b>
        </div>
      )}
      {(dataExists && dataCalculated && !dataOfInterestExists) && (
        <div>
          {/* Customize message as needed */}
          <b>No Findings</b>
        </div>
      )}
    </div>
  );
}

LocGroundSpeedScatterChart.propTypes = propTypes;
LocGroundSpeedScatterChart.defaultProps = defaultProps;

export default LocGroundSpeedScatterChart;
