import React from 'react'
// import UserContext from '../components/UserContext'
// import Navbar from '../components/Navbar'
import './Home.css'
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import UnauthNav from '../components/UnauthNav';
import UnauthBanner from '../components/UnauthBanner';

const Home = () => {
  // const [qrCode, setQrCode] = useState([])
  // const [challengeAnswer, setChallengeAnswer] = useState([])
  // const [showPreferred, setshowPreferred] = useState(false)
  //
  // const userContext = useContext(UserContext)

  // const isAuthenticated = userContext.user && userContext.user.username ? true : false

  const AutoplaySlider = withAutoplay(AwesomeSlider);

  // const addTTOP = () => {
  //   const { user } = this.context
  //   Auth.setupTOTP(user).then((code) => {
  //     const authCode = "otpauth://totp/AWSCognito:"+ user.username + "?secret=" + code + "&issuer=AWSCognito";
  //     this.setState({
  //       qrCode: authCode,
  //       showPreferred: true
  //     })
  //   });
  // }

  // const setPreferredMFA = (authType) => {
  //   const { user } = this.context
  //   Auth.verifyTotpToken(user, this.state.challengeAnswer).then(() => {
  //     Auth.setPreferredMFA(user, authType)
  //       .then(data => console.log('data from verify...: ', data))
  //       .catch(err => console.log('error: ', err))
  //   });
  // }

  const slideImages = [
     process.env.PUBLIC_URL + '/img/Bell206.webp',
     process.env.PUBLIC_URL + '/img/Helo.webp',
     process.env.PUBLIC_URL + '/img/Hughes-Schweizer300Series.webp',
     process.env.PUBLIC_URL + '/img/interiorphotoheli.webp',
     process.env.PUBLIC_URL + '/img/MD500NCockpit.webp',
     process.env.PUBLIC_URL + '/img/TailRotor.webp',
    //  process.env.PUBLIC_URL + '/img/Teeteringrotorhead.webp',
  ]

  
  return (
    <div>
      {/* <Navbar /> */}
      <UnauthNav />
      <div>
        <UnauthBanner />
      </div>
        <div className="">
          <AutoplaySlider
            play={true}
            interval={7000}
            bullets={false}
            style={{height: '500px'}}
          >
            {
              slideImages.map((each, index) => 
                <div key={index}>
                  <img src={each} key={index}></img>
                </div>
              )
            }
            
            
          </AutoplaySlider>
          
        </div>

        <div className="stretch_grid-container" style={{paddingTop: '1.5em'}}>
        
          <h1>Welcome to ASIAS</h1>
          
          <h4 style={{paddingTop: '20px'}}>Introduction</h4>
          <p> 
            ASIAS serves as a central conduit for the exchange of safety information among its stakeholders, 
            providing a valuable resource for the aviation community. 
            The ASIAS vision is focused on the establishment of a comprehensive network of safety information sources 
            shared by stakeholders supporting the global air transportation system.
          </p>

          <h4>ASIAS Industry Collaboration</h4>
          <p>
            The ASIAS procedures, operations and analysis activities are governed by the ASIAS Executive Board (AEB). 
            The AEB has representation from aircraft operators, labor groups, manufacturers and government agencies including FAA aviation safety, 
            participating operators, and air traffic operations. 
            Industry collaboration includes a balance of interests of all ASIAS stakeholders as well as the sharing of safety information and analytical 
            capabilities. It is the goal of ASIAS to provide a reliable source of information to the aviation community that will be used to impact safety 
            decisions and reduce the risk of accidents. 
            This page serves the ASIAS rotorcraft community. For the fixed wing community, please follow <a href="https://portal.asias.aero/web/guest/home">this link to ASIAS.</a></p>
        </div>
    </div>
  )
}

export default Home

