import React, { useState, useContext } from "react";
import { DataContext } from "../DataContext";
// import PropTypes from 'prop-types';
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { withSize } from "react-sizeme";
import DashboardWidget from "../DashboardWidget";
import LoadingSkeleton from "../utils/LoadingSkeleton";
import { IconButton, Tooltip } from "@mui/material";
import AddList from "../AddList";
import SaveIcon from '@mui/icons-material/Save';
import DashboardIcon from '@mui/icons-material/Dashboard';
import UasRiskVectorTilesMap from "../maps/UasRiskVectorTilesMap";
import DataBrowser, { buildDataBrowserColumn } from "../misc/DataBrowser";
import BigNumber from "../misc/BigNumber";
// import UasRiskMap from "../maps/UasRiskMap";

// Need to import styles for core functionality
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const propTypes = {};
const defaultProps = {};

const styles = {
  toolbarContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  toolbarBanner: {
    marginRight: "auto",
  },
};

// NOTE: Don't use global mode on a regex object if it is meant to be reused
// See: https://medium.com/codesnips/js-careful-when-reusing-regex-636b92c6bf07
const gridRegex = /(?<apt>[a-zA-Z]+)(?<grid>[0-9]+)/m; // NOTE: named group syntax is ?<group-name>

function UasRiskAnalysisContent({ size: { width } }) {
  const SAVED_LAYOUTS_KEY = "UasRiskAnalysisLayout";

  const dataContext = useContext(DataContext);
  const uasAggregateMetricsDashboardConfig = dataContext.uasAggregateMetricsDashboardConfig[dataContext.UAS_RISK_ANALYSIS_TAB_KEY];

  const originalItems = Object.keys(uasAggregateMetricsDashboardConfig);

  const initialLayouts = {
    lg: originalItems.map(item => uasAggregateMetricsDashboardConfig[item])
  };

  const loadedLayouts = dataContext.getFromLS(SAVED_LAYOUTS_KEY) || initialLayouts;
  const [layouts, setLayouts] = useState(
    loadedLayouts
  );

  // Initial set of widgets will be based on saved layouts data (defaults to initial layouts if no saved data found)
  const [items, setItems] = useState(loadedLayouts.lg.map(item => item.i));

  const onLayoutChange = (_, allLayouts) => {
    setLayouts(allLayouts);
  };

  const onLayoutSave = (e) => {
    e.stopPropagation();
    dataContext.saveToLS(SAVED_LAYOUTS_KEY, layouts);

    // Show snackbar notification on save
    dataContext.showSnack("top", "center", "Dashboard Layout Saved for UAS Risk Analysis!", dataContext.SNACK_SEVERITY.SUCCESS, 2000);
  };

  const onLayoutReset = (e) => {
    e.stopPropagation();
    const newLoadedLayouts = dataContext.getFromLS(SAVED_LAYOUTS_KEY) || initialLayouts;
    setLayouts(newLoadedLayouts);
    setItems(newLoadedLayouts.lg.map(item => item.i));
    dataContext.showSnack("top", "center", "Dashboard Layout Reset to Last Saved State for UAS Risk Analysis!", dataContext.SNACK_SEVERITY.SUCCESS, 2000);
  }

  const onRemoveItem = (itemId) => {
    // console.log(`Removing ${itemId} from the dashboard...`);
    setItems(items.filter((i) => i !== itemId));
  };

  const onAddItem = (itemId) => {
    setItems([...items, itemId]);
  };

  /**
   * Handle DataBrowser row selection event for drilling down on data record.
   * 
   * @param {string} key Key associated with target dataset for drilldown.
   * @param {any} value Value associated with key mapping in dataset for drilldown.
   */
  const handleRowSelected = (key, value) => {
    // Build data structure that imitates control filter
    // EXAMPLE:
    // {
    //   "phaseOfFlightSelect": {
    //     "id": "phaseOfFlightSelect",
    //     "label": "Standing, Hover Ground Effect",
    //     "column": "phaseofflight_mavg10",
    //     "values": [
    //       "standing",
    //       "hover ground effect"
    //      ]
    //    }
    // }    
    // console.log("Handling drilldown for selected key-value:", [key, value]);

    let filter = {
      [dataContext.UAS_RISK_ANALYSIS_CONTENT_SELECT_KEY]: {
        id: dataContext.UAS_RISK_ANALYSIS_CONTENT_SELECT_KEY,
        label: value,
        column: key,
        values: [value],
        track: true,
      }
    };

    dataContext.addControlFilter(filter);
  }

  const roundingHandlerDefault = (data) => {
    return dataContext.roundStr(data);
  }

  const roundingHandlerLong = (data) => {
    return dataContext.roundStr(data, 6);
  }

  const humanReadableHandler = (data) => {
    return dataContext.capitalizeWords(data);
  }

  // Core widgets list
  const componentList = {
    uasRiskVectorTilesMap:
      dataContext.processing ? <LoadingSkeleton /> :
        <UasRiskVectorTilesMap
          mapId="uasRiskVectorTilesMap"
          startLoc={[-70.9734, 42.3003]} // BOS airport
          zoom={8}
        />,
    nmacDurationByGridDataBrowser:
      dataContext.processing ? <LoadingSkeleton /> :
        <DataBrowser
          primaryTargetKey="gridsumm"
          defaultRowsPerPage={50}
          enableSearch
          primaryCol="gridid"
          onRowSelected={handleRowSelected}
          decorateNulls
          columnsOfInterest={[
            buildDataBrowserColumn({
              col: "gridid",
              alias: "Grid ID",
              width: 60,
              // defaultOrder: "asc",
              customSortingComparator: (gridId1, gridId2, order) => {
                let result = 0;

                let match1 = gridRegex.exec(gridId1);
                let match2 = gridRegex.exec(gridId2);

                const { groups: { apt: apt1 } } = match1;
                const { groups: { apt: apt2 } } = match2;

                // Ascending comparison with just the extracted airport token
                result = apt1.localeCompare(apt2);

                return order === "asc" ? result : -result;
              }
            }),
            buildDataBrowserColumn({
              col: "nmac_dur",
              alias: "NMAC Duration (Seconds)",
              modifier: (data) => dataContext.bigNumberFormatter(data, 2),
              isMetric: true,
              defaultOrder: "desc"
            }),
          ]}
        />,
    nmacLikelihoodByGridDataBrowser:
      dataContext.processing ? <LoadingSkeleton /> :
        <DataBrowser
          primaryTargetKey="gridsumm"
          defaultRowsPerPage={50}
          enableSearch
          primaryCol="gridid"
          onRowSelected={handleRowSelected}
          decorateNulls
          columnsOfInterest={[
            buildDataBrowserColumn({
              col: "gridid",
              alias: "Grid ID",
              width: 60,
              customSortingComparator: (gridId1, gridId2, order) => {
                let result = 0;

                let match1 = gridRegex.exec(gridId1);
                let match2 = gridRegex.exec(gridId2);

                const { groups: { apt: apt1 } } = match1;
                const { groups: { apt: apt2 } } = match2;

                // Ascending comparison with just the extracted airport token
                result = apt1.localeCompare(apt2);

                return order === "asc" ? result : -result;
              }
            }),
            buildDataBrowserColumn({
              col: "nmac_elik",
              alias: "NMAC Encounter Likelihood",
              modifier: (data) => dataContext.bigNumberFormatter(data, 8),
              isMetric: true,
              defaultOrder: "desc"
            }),
          ]}
        />,
    gridCountByAirport:
      dataContext.processing ? <LoadingSkeleton /> :
        <DataBrowser
          primaryTargetKey="gridsumm"
          defaultRowsPerPage={50}
          enableSearch
          primaryCol="apt"
          onRowSelected={handleRowSelected}
          groupByCount
          groupByAlias="Grid Count"
          groupByAsMetric
          groupByDefaultOrder="desc"
          decorateNulls
          columnsOfInterest={[
            buildDataBrowserColumn({
              col: "apt",
              alias: "APT",
              width: 30,
            }),
          ]}
        />,
    riskByAtmAssignedCeilingDataBrowser:
      dataContext.processing ? <LoadingSkeleton /> :
        <DataBrowser
          primaryTargetKey="gridsumm"
          defaultRowsPerPage={50}
          primaryCol="nmac_risk"
          onRowSelected={handleRowSelected}
          groupByCount
          groupByAlias="Grid Count"
          groupByAsMetric
          groupByDefaultOrder="desc"
          decorateNulls
          columnsOfInterest={[
            buildDataBrowserColumn({
              col: "nmac_risk",
              alias: "NMAC Risk",
              width: 60,
            }),
          ]}
        />,
    totalFlightsBigNumber:
      dataContext.processing ? <LoadingSkeleton /> :
        <BigNumber
          source="gridsumm"
          column="totops"
          metric="first"
          subheader="Flight(s)"
        />,
    totalFlightHoursBigNumber:
      dataContext.processing ? <LoadingSkeleton /> :
        <BigNumber
          source="gridsumm"
          column="totdur"
          metric="first"
          subheader="Hours"
          modifier={result => result / 3600} // Convert from seconds to hours
        />,
    gridsummDataBrowser:
      dataContext.processing ? <LoadingSkeleton /> :
        <DataBrowser
          primaryTargetKey="gridsumm"
          primaryCol="gridid"
          defaultRowsPerPage={50}
          onRowSelected={handleRowSelected}
          decorateNulls
          columnsOfInterest={[
            buildDataBrowserColumn({ col: "gridid", alias: "Grid ID" }),
            buildDataBrowserColumn({ col: "globalid", alias: "Global ID" }),
            buildDataBrowserColumn({ col: "ceiling", alias: "Ceiling" }),
            buildDataBrowserColumn({ col: "nmac_rc", alias: "NMAC RC" }),
            buildDataBrowserColumn({ col: "nmac_risk", alias: "NMAC Risk" }),
            buildDataBrowserColumn({ col: "totops", alias: "Total Operations" }),
            buildDataBrowserColumn({ col: "totdur", alias: "Total Duration (Seconds)" }),
            buildDataBrowserColumn({ col: "minagl", alias: "Minimum AGL", modifier: roundingHandlerDefault }),
            buildDataBrowserColumn({ col: "nmac_dur", alias: "NMAC Duration" }),
            buildDataBrowserColumn({ col: "nmac_0_dur", alias: "NMAC 0 FT Duration" }),
            buildDataBrowserColumn({ col: "nmac_50_dur", alias: "NMAC 50 FT Duration" }),
            buildDataBrowserColumn({ col: "nmac_100_dur", alias: "NMAC 100 FT Duration" }),
            buildDataBrowserColumn({ col: "nmac_150_dur", alias: "NMAC 150 FT Duration" }),
            buildDataBrowserColumn({ col: "nmac_200_dur", alias: "NMAC 200 FT Duration" }),
            buildDataBrowserColumn({ col: "nmac_250_dur", alias: "NMAC 250 FT Duration" }),
            buildDataBrowserColumn({ col: "nmac_300_dur", alias: "NMAC 300 FT Duration" }),
            buildDataBrowserColumn({ col: "nmac_350_dur", alias: "NMAC 350 FT Duration" }),
            buildDataBrowserColumn({ col: "nmac_400_dur", alias: "NMAC 400 FT Duration" }),
            buildDataBrowserColumn({ col: "nmac_elik", alias: "NMAC Encounter Likelihood", modifier: roundingHandlerLong }),
            buildDataBrowserColumn({ col: "nmac_0_elik", alias: "NMAC 0 FT Encounter Likelihood", modifier: roundingHandlerLong }),
            buildDataBrowserColumn({ col: "nmac_50_elik", alias: "NMAC 50 FT Encounter Likelihood", modifier: roundingHandlerLong }),
            buildDataBrowserColumn({ col: "nmac_100_elik", alias: "NMAC 100 FT Encounter Likelihood", modifier: roundingHandlerLong }),
            buildDataBrowserColumn({ col: "nmac_150_elik", alias: "NMAC 150 FT Encounter Likelihood", modifier: roundingHandlerLong }),
            buildDataBrowserColumn({ col: "nmac_200_elik", alias: "NMAC 200 FT Encounter Likelihood", modifier: roundingHandlerLong }),
            buildDataBrowserColumn({ col: "nmac_250_elik", alias: "NMAC 250 FT Encounter Likelihood", modifier: roundingHandlerLong }),
            buildDataBrowserColumn({ col: "nmac_300_elik", alias: "NMAC 300 FT Encounter Likelihood", modifier: roundingHandlerLong }),
            buildDataBrowserColumn({ col: "nmac_350_elik", alias: "NMAC 350 FT Encounter Likelihood", modifier: roundingHandlerLong }),
            buildDataBrowserColumn({ col: "nmac_400_elik", alias: "NMAC 400 FT Encounter Likelihood", modifier: roundingHandlerLong }),
            buildDataBrowserColumn({ col: "runwaygrid", alias: "Runway Grid", modifier: humanReadableHandler }),
            buildDataBrowserColumn({ col: "apt", alias: "Airport Code" }),
          ]}
        />,
  };

  return (
    <>
      {/* Dashboard tab management functions - right aligned if display is flex and content is justified as flex-end */}
      <div style={styles.toolbarContainer}>
        <div>
          <IconButton aria-label="save" onClick={onLayoutSave}>
            <Tooltip title="Save Dashboard Tab Layout" placement="top" arrow>
              <SaveIcon />
            </Tooltip>
          </IconButton>

          <IconButton aria-label="reset" onClick={onLayoutReset}>
            <Tooltip title="Reset Dashboard Tab Layout to Last Saved State" placement="top" arrow>
              <DashboardIcon />
            </Tooltip>
          </IconButton>

          <AddList
            items={items}
            onRemoveItem={onRemoveItem}
            onAddItem={onAddItem}
            originalItems={originalItems}
            dashboardKey={dataContext.UAS_RISK_ANALYSIS_TAB_KEY}
            dashboardConfig={dataContext.uasAggregateMetricsDashboardConfig}
          />
        </div>
      </div>

      {/* Add controls here as needed */}


      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={60}
        width={width * (1 / dataContext.appScale)}
        draggableHandle=".drag-handle"
        onLayoutChange={onLayoutChange}
        transformScale={dataContext.appScale} // Required to adapt transform translation offset when dragging
      >
        {items.map((key) => (

          <div
            key={key}
            data-grid={{ w: 6, h: 8, x: 0, y: Infinity }}
          >
            <DashboardWidget
              id={key}
              name={uasAggregateMetricsDashboardConfig[key].name}
              className="drag-handle"
              onRemoveItem={onRemoveItem}
              fullscreenEnabled={false}
            >
              {componentList[key]}
            </DashboardWidget>
          </div>
        ))}
      </ResponsiveGridLayout>
    </>
  );
}

UasRiskAnalysisContent.propTypes = propTypes;
UasRiskAnalysisContent.defaultProps = defaultProps;

export default withSize({ refreshMode: "debounce", refreshRate: 60 })(UasRiskAnalysisContent);
