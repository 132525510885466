import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import { DataContext } from '../DataContext';
import { createTheme, ThemeProvider } from "@mui/material/styles";

const propTypes = {

};

const defaultProps = {

};

function UimcAggregateMetricsThemeProvider(props) {
  const dataContext = useContext(DataContext);
  const theme = createTheme({
    palette: {
      mode: dataContext.darkMode ? "dark" : "light"
    },
  });

  return (
    <ThemeProvider theme={theme}>
        {props.children}
    </ThemeProvider>
  );
}

UimcAggregateMetricsThemeProvider.propTypes = propTypes;
UimcAggregateMetricsThemeProvider.defaultProps = defaultProps;

export default UimcAggregateMetricsThemeProvider;
