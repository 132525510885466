import React from "react";
import { useEffect, useState } from "react";
import {
    Dropdown,
    ErrorMessage
} from '@trussworks/react-uswds'
import '../pages/FleetManagement.css'
import { useTable, useFilters, useSortBy } from 'react-table'
import { matchSorter } from 'match-sorter'


// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    // const count = preFilteredRows.length
  
    return (
      <input
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder="Search username"
      />
    )
  }
  
  function fuzzyTextFilterFn(rows, id, filterValue) {
    // rows.forEach(row => {
    // // console.log({row})
    //   let values = row.values[1].forEach((token, index) => {
    //     token.Name === 'name' ? console.log(index) : console.log("Not found yet")
    //   })
    // // console.log({values})
    // })
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
  }
  
  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = val => !val
  
  // Our table component
  function FleetTable({ columns, data, onSort, operator }) {
    const filterTypes = React.useMemo(
      () => ({
        // Add a new fuzzyTextFilterFn filter type.
        fuzzyText: fuzzyTextFilterFn
      }),
      []
    )
    

    let lastSortOperator = '';
  
  
    const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    )
  
    const {
      getTableProps,   
      getTableBodyProps, 
      headerGroups,
      rows,  
      prepareRow,
      setFilter,  
      setSortBy,
      state: { sortBy }
  } = useTable({
                  columns,
                  data,
                  defaultColumn, // Be sure to pass the defaultColumn option
                  filterTypes,
                  manualSortBy: true,
                  autoResetSortBy: false,
                  initialState: {
                    hiddenColumns: columns.map(column => {
                      if (column.show === false) return column.accessor || column.id;
                    }),
                  }
                },
                useFilters,
                useSortBy
              )
              useEffect(() => {
                  console.log("Sort use effect")
                onSort(sortBy);
              }, [onSort, sortBy]);

             
            //   useEffect(() => {
            //       console.log(lastSortOperator)
            //       if(data && data[0]){
            //           console.log(data[0].operator)
            //       }
            //     if(lastSortOperator && data && data[0] ? (data[0].operator !== lastSortOperator) : false){
            //         console.log("Call sort --------")
            //         setSortBy([{ id: 'jsonAttributes.' + sortByValue, desc: false }])
            //     }}, [data])
              
  
              
            
              const [sortByValue, setSortByValue] = useState()
            
              // Function to link the text box to the hook for filtering
              const onFilterChange = e => {
                // console.log(e.target.value)
                setFilter('1', e.target.value || undefined)
                // setNameFilter(e.target.value)
              }
                ;
              // Function to link the text box to the hook for filtering
              const onFilterSubmit = e => {
                e.preventDefault()
                // let debug = e.target
                // console.log(debug)
                // console.log
                // setFilter('1', e.target.value || undefined)
                // setNameFilter(e.target.value)
              }
                ;
            
              const onSortByChange = e => {
              // console.log("Sort By Listener")
              // console.log(e.target.value)
                setSortByValue(e.target.value)
                lastSortOperator = (data[0].operator)
                setSortBy([{ id: 'jsonAttributes.' + e.target.value, desc: false }])
              // console.log({ data })
              }
              return (
                <>
                <div style={{display:'flex',width:"97vw",justifyContent:'right',alignItems:'end'}}>
                Sort By: 
                <Dropdown style={{ width: '20em', height: '32px', float: 'left', marginLeft:'10px', paddingTop: '0px', marginTop: '0px', marginRight: '10px', paddingBottom: '0px' }}
                  id="sort-dropdown" name="sort-dropdown"
                  onChange={evt => onSortByChange(evt, () => { })}>
                  <option value="">Select an Option</option>
                  <option value="tail">Tail Number</option>
                  <option value="icao_make">Aircraft Make</option>
                </Dropdown>          
                </div>
                {
                  data && data.length > 0 ? <div>{data.length} Aircraft</div> :  <></>
                }
                <table {...getTableProps()} style={{ width: '100%', borderCollapse: 'collapse'}}>
                      <thead>
                          {headerGroups.map(headerGroup => (
                          <tr {...headerGroup.getHeaderGroupProps()} 
                              style={{borderBottom: '1px solid grey'}}>
                              {headerGroup.headers.map(column => (
                              <th
                                  {...column.getHeaderProps()}
                                  style={{
                                  color: 'black',
                                  // padding: '10px',
                                  fontWeight: 'bold',
                                  }}
                              >
                                  {column.render('Header')}
                              </th>
                              ))}
                          </tr>
                          ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                          {rows.map(row => {
                          prepareRow(row)
                          return (
                              <tr {...row.getRowProps()}>
                              {row.cells.map(cell => {
                                  return (
                                  <td
                                      {...cell.getCellProps()}
                                      style={{
                                      padding: '10px',
                                      borderBottom: 'solid 2px grey',
                                      }}
                                  >
                                      {cell.render('Cell')}
                                  </td>
                                  )
                              })}
                              </tr>
                          )
                          })}
                      </tbody>
                      </table>
                      </>
              )
  
  
  
  }

  export default FleetTable