import getPreferences from "../services/getPreferences";
import savePreferences from "../services/savePreferences";

const LS_KEY = "raise-preferences";

/**
   * Retrieve JSON data from local storage.
   * 
   * @param {string} key Key for retrieving stored json data.
   * @returns JSON object containing the target data for retrieval
   */
export async function getFromLS(user, key) {
    let ls = {};
    
    if (global.localStorage) {
      try {
        // console.log("Obtain " + key);
        // Invoke Init, which only does anything if the storage is null.  After init returns grab the key from storage.
        await initLS(user).then((result) => {
          ls = global.localStorage.getItem(LS_KEY) ? JSON.parse(global.localStorage.getItem(LS_KEY)) : {};
          
        })
      }
      catch (e) { 
        // console.log(e)
        return { }
      }
      
    }
   return ls[key]
  }

  /**
     * Retrieve JSON data from local storage.
     * 
     * @param {string} key Key for retrieving stored json data.
     * @returns JSON object containing the target data for retrieval
     */
  export function getAllFromLS() {
      let ls = {};
      if (global.localStorage) {
        try {
          ls = global.localStorage.getItem(LS_KEY) || {};
        }
        catch (e) { }
      }
      return ls;
    }  

/**
   * Save JSON data structure to local storage. Local storage does not support
   * storing and retrieving JSON structures with nested functions since the
   * JSON stringify function cannot parse functions properly.
   * 
   * @param {string} key Key for saving json data to local storage.
   * @param {object} value JSON object containing data to save to local storage.
   */
 export function saveToLS (key, value) {
    if (global.localStorage) {
      let ls = {};
      try {
        ls = JSON.parse(global.localStorage.getItem(LS_KEY)) || {};
      }
      catch (e) { }

      // Save new key/value pair without overwriting existing values
      global.localStorage.setItem(
        LS_KEY,
        JSON.stringify({
          ...ls,
          [key]: value
        })
      );
    }
  }


  /**
   * Check for preferences in session storage
   * If does not exist, pull from S3.
   * Return a boolean of if an update was pulled from S3 or not.
   * 
   * @param {object} user User object used for authenticating user to service endpoint
   */

  async function initLS(user){
    let updateFlag = false;
      if (global.localStorage) {
        try {
          // If raise-preferences doesn't exist grab from service, return updateFlag in case curious about if data had to be fetched.
          if( global.localStorage.getItem(LS_KEY) === null){
              getPreferences(user).then((result) => {
                // console.log(result)
                global.localStorage.setItem(LS_KEY, JSON.stringify(result))
                updateFlag = true;
                return updateFlag;
              })
          }
        }
        catch (e) { }
      }
      
  }

  /**
     * Save JSON data from local storage to S3.
     * 
     * @param {object} user User object for authorizing service.
     * @returns JSON object containing the target data for retrieval
     */
   export function savePrefsToS3(user) {
    savePreferences(user)
  }  