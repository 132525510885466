import {API} from "aws-amplify";

export function getDashboardById(user, dashboardName, undoRedoFlag, resetFlag) {
  const token = user.signInUserSession.idToken.jwtToken;
  const requestInfo = {
    headers: {
      Authorization: token
    },
    body: {
      username: user.username,
      accessToken: user.signInUserSession.accessToken.jwtToken,
      idToken: user.signInUserSession.idToken.jwtToken,
      dashboardName: dashboardName,
      undoRedoFlag : undoRedoFlag,
      resetFlag : resetFlag,
    }
  }

  let url = "/get-dashboard-by-id"
// console.log({url})
    const data = API.post('raise-gui-api-dev', url, requestInfo)
      return data;
}

export default getDashboardById;