import React, { useContext } from 'react';
import UserContext from '../components/UserContext';
import { DataProvider } from '../components/DataContext';
import AggregateMetricsThemeProvider from '../components/styles/AggregateMetricsThemeProvider';
import GeoSearchByHelipadsComponent from './GeoSearchByHelipadsComponent';

const propTypes = {};
const defaultProps = {};

const GeoSearchByHelipadsWrapper = () => {
  const userContext = useContext(UserContext);
  const operator = userContext.user.attributes['custom:operator'];

  return (
    <DataProvider>
      <AggregateMetricsThemeProvider>
        <GeoSearchByHelipadsComponent 
          user={userContext.user}
          operator={operator}
        />
      </AggregateMetricsThemeProvider>
    </DataProvider>
  );
}

GeoSearchByHelipadsWrapper.propTypes = propTypes;
GeoSearchByHelipadsWrapper.defaultProps = defaultProps;

export default GeoSearchByHelipadsWrapper;
