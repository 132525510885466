import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
// import LinearProgress from '@mui/material/LinearProgress';

// See skeleton props at https://mui.com/api/skeleton/
function LoadingSkeleton({variant, animation}) {
    return (
        <Stack spacing={1} style={{height: '100%'}}>
            {/* <LinearProgress /> */}
            <Skeleton variant={variant} animation={animation} height='90%' />
            <Skeleton variant={variant} animation={animation} />
            <Skeleton variant={variant} animation={animation} width='60%' />
        </Stack>
    );
}

// Supported Types:
//     variant   -> 'circular' | 'rectangular' | 'text' | string
//     animation -> 'pulse' | 'wave' | false
LoadingSkeleton.propTypes = {
    variant: PropTypes.oneOf(['circular', 'rectangular', 'text']),
    animation: PropTypes.oneOf(['pulse', 'wave', false]),
}

LoadingSkeleton.defaultProps = {
    variant: 'rectangular',
    animation: 'wave',
}

export default LoadingSkeleton;
