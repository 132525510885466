import React, { useState, useEffect, useRef, useContext } from "react";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { DataContext } from "../DataContext";
import { useResizeDetector } from 'react-resize-detector';
import PropTypes from 'prop-types';
import BasemapSwitchControl from "../controls/BasemapSwitchControl";
import MapboxGLButtonControl from "../controls/MapboxGLButtonControl";
import LayerControl from "../controls/LayerControl";

import cameraPng from '../styles/camera.png';

// Import Styles
import 'mapbox-gl/dist/mapbox-gl.css';
import 'font-awesome/css/font-awesome.min.css';
// import '../styles/css/fontello.css';
import './Map.css';

mapboxgl.accessToken = 'pk.eyJ1IjoiemhqbWFwYm94IiwiYSI6ImNpdDY2NjZtczAzYXgyc2xqbGp0MTI0cmUifQ.ZwiVYpuYfxANYXqBBYTzgQ';

const propTypes = {
  mapId: PropTypes.string.isRequired,
  startLoc: PropTypes.arrayOf(PropTypes.number),
  zoom: PropTypes.number
};

const defaultProps = {
  startLoc: [-98.5795, 39.8283],
  zoom: 3
};

const mapResourceName = 'uimc-aggregate';
const heatResourceName = 'uimc-heat';
const mapLayerId = 'uimc-aggregate-layer';
const wxResourceName = 'wx=resource'
const wxCamLayerId = "wxCamLayer";
const heatMapLayer = "heatMapLayer";

const mapLayerIdCluster = 'uimc-aggregate-cluster-layer';
const mapLayerIdClusterCount = 'uimc-aggregate-clustercount-layer';



const themeColors = {
  "0-19": "#B2F5FD",
  "20-39": "#A0E5FD",
  "40-59": "#8ED1FD",
  "60-79": "#7CB7FC",
  "80-99": "#6A98FC",
  "≥ 100": "#5874FC",
};

const buildFeaturedTooltip = (row, { color = null }) => {
  // Build tool tip off all properties
  var tooltipText = "";

  // Start table
  tooltipText += "<table>";
  for (const [key, value] of Object.entries(row)) {
    let iconData = `<td><i class="fa fa-square tooltip-icon-fonts" style="color: ${color}; margin-right: 5px;"></i></td>`;
    tooltipText += `
      <tr>
        ${color !== null ? iconData : ""}
        <td style="color: #989898"><b>${key}&nbsp</b></td>
        <td><b>${value}</b></td>
      </tr>
    `;
  }

  // End table
  tooltipText += "</table>";
  return tooltipText;
}

const buildLegend = (sections = []) => {
  if (!sections || sections.length === 0) return;
  let legendSections = [];

  Array.from(sections).forEach((section, idx) => {
    const { header = `Legend ${idx}`, colors = {}, modifier = null } = section;
    let categories = [];

    for (const [key, value] of Object.entries(colors)) {
      categories.push({
        label: modifier ? modifier(key) : key,
        color: value,
      });
    }

    let legendSection = <>
      {/* Title */}
      <strong style={{ color: "black" }}>{header}</strong>

      {/* Items/Icons */}
      {categories.map((cate, i) => {
        let category = cate.label;
        const iconColor = { color: cate.color };

        return (
          <div key={`legend-icon-${i}`}>
            <i
              className="fa fa-circle vector-legend-icon-fonts"
              style={iconColor}
            >
              <span className="legend-icon-inner-text">{category}</span>
            </i>
          </div>
        );
      })}
    </>;

    legendSections.push(legendSection);
  });

  //Create the legend container and return
  let legend = <div className="map-overlay " id="legend">
    {legendSections.map((section, idx) => (
      <div key={`legend-section-${idx}`} style={{ marginBottom: 15 }}>
        {section}
      </div>
    ))}
  </div>

  return legend;
}

// Map Clustering Example Docs: https://docs.mapbox.com/mapbox-gl-js/example/cluster/
const UimcEventsMap = ({
  mapId,
  startLoc,
  zoom
}) => {
  // Obtain reference to global data context consumer
  const dataContext = useContext(DataContext);
  const [legendDisplay, setLegendDisplay] = useState(true)

  const defaultBasemap = dataContext.baseMaps[dataContext.darkMode ? "Dark Basemap" : "Light Basemap"];

  // 3D style: 'mapbox://styles/mapbox-map-design/ckhqrf2tz0dt119ny6azh975y'
  const defaultBasemapStyle = dataContext.defaultBasemapStyleJson;
  defaultBasemapStyle['sources']['raster-tiles']['tiles'][0] = defaultBasemap.url;
  defaultBasemapStyle['sources']['raster-tiles']['attribution'] = defaultBasemap.attribution;

  const {
    width: resizeDetectorWidth,
    height: resizeDetectorHeight,
    ref: resizeDetectorRef
  } = useResizeDetector();

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(startLoc[0]);
  const [lat, setLat] = useState(startLoc[1]);
  const [zoomState, setZoomState] = useState(zoom);
  const [legend, setLegend] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [stylesLoaded, setStylesLoaded] = useState(false);

  const isMounted = useRef(false);

  const mapStyles = {
    width: "100%",
    height: "100%",
  };

  const mapParentStyle = {
    position: "relative",
    width: "100%",
    height: "100%"
  };

  const defineLegend = () => {
    const { uimcDaylightTypeColorMap } = dataContext.COLOR_MAPS.current;
    let legendItems = []
  
    // Add perma visible.
    if(map.current.getZoom() >= 4){
    legendItems.push({ header: "Daylight", colors: uimcDaylightTypeColorMap, modifier: key => dataContext.capitalizeWords(key) })
    }
  // console.log()
    if (map.current.style._layers[mapLayerIdCluster].visibility !== "none" )
    {
    legendItems.push({ header: "UIMC Event Count", colors: themeColors })
    }
    // console.log(legendItems)
    return legendItems
  }

  const handleLegends = (legendName, flag) =>{
    let legendItems = defineLegend()
    let legend = buildLegend(legendItems);
    setLegend(legend);
    
    
    // if(legendName === "legend"){
    //   setLegendDisplay(flag ==="visible" ? true : false)
    // }
  }

  // Detect when this component is unmounted
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    }
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      const { uimcDaylightTypeColorMap } = dataContext.COLOR_MAPS.current;

      // Define the function to add source and add layer. This function will be called in map.on("load") and map.on("styledata") event
      const addDataLayer = () => {
        // console.log("Adding data layer for UIMC events map...");
        let dataTarget = dataContext.filteredData ? dataContext.filteredData : dataContext.baseData;
        if (dataTarget) {
          // Extract data and lookup table from gridsumm data in data target
          // console.log(dataTarget)
          const { uimc_events: { data, lookup, geojson: geojs }, wxCameraData } = dataTarget;
          let targetEvents = dataContext.groupBy(data, lookup.id);

          // Drilldown support to sync geojson with current data
          let filteredFeatures = geojs.features.filter(feature => {
            return targetEvents.find(id => {
              return id === feature.properties.id;
            });
          });

          let filteredGeoJson = { ...geojs };
          filteredGeoJson["features"] = filteredFeatures;

          // console.log(filteredGeoJson)
          // console.log("Target events to filter by in map:", targetEvents);
          // console.log("Filtered UIMC events geojson:", filteredGeoJson);

          // Check the map resource status
          // Only add the resource when the defined resource doesn't exist
          let sourceStatus = map.current.getSource(mapResourceName);
          if (sourceStatus === undefined) {
            map.current.addSource(mapResourceName, {
              type: 'geojson',
              data: filteredGeoJson,
              cluster: true,
              clusterMaxZoom: 10, // Max zoom to cluster points on
              clusterRadius: 30,
              clusterProperties: {
                // sum: ['+', ['get', 'facility']],
                // sum: ['+', ['get', 'daylight']],
                sum: ['+', ["get", "daylight"]],
                // sum: [["+", ["accumulated"], ["get", "facility"]], ["get", "daylight"]],
              }
            });
          }
          else {
            sourceStatus.setData(filteredGeoJson);
          }
          let heatSourceStatus = map.current.getSource(heatResourceName);
          if (sourceStatus === undefined) {
            map.current.addSource(heatResourceName, {
              type: 'geojson',
              data: filteredGeoJson,
              // cluster: true,
              // clusterMaxZoom: 14, // Max zoom to cluster points on
              // clusterRadius: 10,
              // clusterProperties: {
              //   // sum: ['+', ['get', 'facility']],
              //   // sum: ['+', ['get', 'daylight']],
              //   sum: ['+', ['get', 'facility'], ["get", "daylight"]],
              //   // sum: [["+", ["accumulated"], ["get", "facility"]], ["get", "daylight"]],
              // }
            });
          }
          else {
            heatSourceStatus.setData(filteredGeoJson);
          }
          
          // Create wxCam geojson

          // let wxGeoJson = {...wxCameraData}
          // let camFeatureList = []
          // // console.log(`FilteredGeoJson: ${filteredGeoJson}`)
          // Object.keys(wxCameraData).map(key => {
          //   // console.log(key)
          
            
          //   let camGeo = {'geometry': {
          //     'coordinates' : [wxCameraData[key]['Latitude'],
          //     [wxCameraData[key]['Longitude']]],
          //   'type' : 'Point'},
          //   'properties': {"Site Name" : key,
          //   "State" :wxCameraData[key]['State']},
          //   'type':'Feature'
          //   }

          //   camFeatureList.push(camGeo)

            
            
          // })
          // console.log(camFeatureList)
          // wxGeoJson['features'] = camFeatureList
          // wxGeoJson['name'] = 'uimc-wx-locations'
          // // console.log(wxGeoJson)

          // console.log(wxCameraData['geojson'])
          let wxSourceStatus = map.current.getSource(wxResourceName);
          if (wxSourceStatus === undefined) {
            map.current.addSource(wxResourceName, {
              type: 'geojson',
              data: wxCameraData['geojson'],
              cluster: false,
              // clusterMaxZoom: 10, // Max zoom to cluster points on
              // clusterRadius: 30,
              // clusterProperties: {
              //   // sum: ['+', ['get', 'facility']],
              //   // sum: ['+', ['get', 'daylight']],
              //   sum: ['+', ['get', 'facility'], ["get", "daylight"]],
              //   // sum: [["+", ["accumulated"], ["get", "facility"]], ["get", "daylight"]],
              // }
            });
          }
          else {
            wxSourceStatus.setData(wxCameraData['geojson']);
          }

          // Remove layers before adding new layers
          if (map.current.getLayer(mapLayerId)) map.current.removeLayer(mapLayerId);
          if (map.current.getLayer(mapLayerIdCluster)) map.current.removeLayer(mapLayerIdCluster);
          if (map.current.getLayer(mapLayerIdClusterCount)) map.current.removeLayer(mapLayerIdClusterCount);
          if (map.current.getLayer(wxCamLayerId)) map.current.removeLayer(wxCamLayerId);

          // Add heat layer
          map.current.addLayer({
            'id': heatMapLayer,
            'type': 'heatmap',
            'source': heatResourceName,
            // 'filter': ['!', ['has', 'point_count']],
            // 'layout': {
            //   "visibility":"none"
            //   // 'icon-image': 'camera',
            //   // 'icon-size' : .5
            // },
            'paint': {
              'heatmap-weight': {"type":"identity","property":"point_count"},
              // 'heatmap-weight': [
              //   'interpolate',
              //   ['linear'],
              //   ['get', 'point_count'],
              //   0,
              //   0,
              //   100,
              //   1
              //   ],
              // Increase the heatmap color weight weight by zoom level
              // heatmap-intensity is a multiplier on top of heatmap-weight
              'heatmap-intensity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              .02,
              5,
              .1,
              15,
              1
              ],
              // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
              // Begin color ramp at 0-stop with a 0-transparancy color
              // to create a blur-like effect.
              'heatmap-color': [
              'interpolate',
              ['linear'],
              ['heatmap-density'],
              0,
              'rgba(33,102,172,0)',
              0.2,
              'rgb(103,169,207)',
              0.4,
              'rgb(209,229,240)',
              0.6,
              'rgb(253,219,199)',
              0.8,
              'rgb(239,138,98)',
              1,
              'rgb(178,24,43)'
              ],
              // Adjust the heatmap radius by zoom level
              'heatmap-radius': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0, 2,
              9, 20
              ],
              // Transition from heatmap to circle layer by zoom level
              'heatmap-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              7,
              1,
              10,
              .7,
              12,
              0
              ]
              }
          });
          // Add points layer
          map.current.addLayer({
            'id': mapLayerId,
            'type': 'circle',
            'source': mapResourceName,
            'filter': ['!', ['has', 'point_count']],
            // 'layout': {
            //   "visibility":"none"
            // },
            'paint': {
              'circle-radius': 5,
              // 'circle-color': '#9fdef1',
              // 'circle-stroke-color': '#fff',
              // 'circle-stroke-width': 1,
              'circle-color': [
                'match',
                ['get', 'daylight'],
                'Day',
                uimcDaylightTypeColorMap['DAY'],
                'Night',
                uimcDaylightTypeColorMap['NIGHT'],
                'Twilight',
                uimcDaylightTypeColorMap['TWILIGHT'],
                '#C9C9C9', // Default gray
              ],
            }
          });

          // Add cluster layer
          map.current.addLayer({
            id: mapLayerIdCluster,
            type: 'circle',
            source: mapResourceName,
            'layout': {
              "visibility":"none"
            },
            filter: ['has', 'point_count'],
            paint: {
              'circle-color': [
                'step',
                ['get', 'point_count'],
                themeColors["0-19"],
                20,
                themeColors["20-39"],
                40,
                themeColors["40-59"],
                60,
                themeColors["60-79"],
                80,
                themeColors["80-99"],
                100,
                themeColors["≥ 100"]
              ],
              // 'circle-radius': [
              //   'step',
              //   ['get', 'point_count'],
              //   10,
              //   5,
              //   15,
              //   10,
              //   20
              // ],
              'circle-radius': [
                'step',
                ['get', 'point_count'],
                10,  // Radius
                20,  // Count
                15,  // Radius
                50,  // Count
                20,  // Radius
                100, // Count
                25,  // Radius
              ],
              "circle-opacity": 0.5,
            }
          });

          // Add cluster labels layer
          map.current.addLayer({
            id: mapLayerIdClusterCount,
            'layout': {
              "visibility":"none"
            },
            type: 'symbol',
            source: mapResourceName,
            filter: ['has', 'point_count'],
            layout: {
              'text-field': '{point_count_abbreviated}',
              'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
              'text-size': 12
            }
          });

          map.current.loadImage(cameraPng, (error, image) =>{
            if (error) throw error;
            if (!map.current.hasImage('camera')) map.current.addImage('camera', image);
          })
          
           // Add wx layer
      map.current.addLayer({
        'id': wxCamLayerId,
        'type': 'symbol',
        'source': wxResourceName,
        // 'filter': ['!', ['has', 'point_count']],
        'layout': {
          'visibility': "none",
          'icon-image': 'camera',
          'icon-size' : .5
        }
      });

           
        }
      }

     

      if (!map.current) {
        // console.log("Initializing UIMC Events map...");
        map.current = new mapboxgl.Map({
          container: mapContainer.current,
          style: defaultBasemapStyle,
          center: [lng, lat],
          zoom: zoomState,
          // See: https://stackoverflow.com/a/47742465
          // transformRequest: (url, resourceType) => {
          //   if (resourceType === 'Source' && url.startsWith('https://raisemaptiles.asias.info')) {
          //     return {
          //       url: url,
          //       headers: { 'Authorization': 'Bearer ' + yourAuthToken }
          //     }
          //   }
          // }
        });

        // Add controls
        const resetViewControl = new MapboxGLButtonControl({
          className: "fa fa-globe",
          title: "Reset View",
          eventHandler: () => map.current.easeTo({
            center: startLoc,
            zoom: zoom,
            duration: 1000,
          }),
          styleStr: "font-size: 1.5em",
        });

        const layerControl = new LayerControl(
          "layerControlWidget",
          {
            "clusterPoints": {
              "title": "Cluster Points",
              "id": mapLayerIdCluster,
              "linkedLayers": [
                mapLayerIdClusterCount,
              ],
              "linkedLegends": "legend",
              "defaultChecked": false
            },
            "wxCamera": {
              "title": "wxCameras",
              "id": wxCamLayerId,
              "defaultChecked": false
            },
            "heatMap": {
              "title": "Heat Map",
              "id": heatMapLayer,
              "defaultChecked": true
            },
           
          },
          handleLegends
        )

        // Add wx layer
        // map.current.addLayer({
        //   'id': wxCamLayerId,
        //   'type': 'point',
        //   'source': mapResourceName,
        //   // 'filter': ['!', ['has', 'point_count']],
        //   'paint': {
        //     'circle-radius': 5,
        //     // 'circle-color': '#9fdef1',
        //     // 'circle-stroke-color': '#fff',
        //     // 'circle-stroke-width': 1,
        //     'circle-color': [
        //       'green', // Default gray
        //     ],
        //   }
        // });

        const scale = new mapboxgl.ScaleControl({
          maxWidth: 80,
          unit: 'imperial'
        });

        map.current.addControl(new mapboxgl.NavigationControl(), 'top-left');
        map.current.addControl(new mapboxgl.FullscreenControl(), 'top-left');
        map.current.addControl(resetViewControl, 'top-left');
        map.current.addControl(layerControl);
        map.current.addControl(new BasemapSwitchControl("basemap-check-control-uimc"));
        map.current.addControl(scale);
        
      }

      if (map.current) {
        // Map event when the basemap was loaded
        map.current.on('load', () => {
          // NOTE: DEMO 3D TERRAIN START
          // map.current.addSource('mapbox-dem', {
          //   'type': 'raster-dem',
          //   'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
          //   'tileSize': 512,
          //   'maxzoom': 14
          // });

          // // Add the DEM source as a terrain layer with exaggerated height
          // map.current.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });

          // // Add sky styling with `setFog` that will show when the map is highly pitched
          // map.current.setFog({
          //   'horizon-blend': 0.3,
          //   'color': '#f8f0e3',
          //   'high-color': '#add8e6',
          //   'space-color': '#d8f2ff',
          //   'star-intensity': 0.0
          // });
          // DEMO 3D TERRAIN END

          // Create legend

          let legendItems = defineLegend()
          const legend = buildLegend(legendItems);

        
          setLegend(legend);

          map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoomState(map.current.getZoom().toFixed(2));
          });

          setMapLoaded(true);
        });

        // Map event when the basemap style loads or changes.
        // NOTE: Runs after map load event on mount
        map.current.on('styledata', () => {
          var mapLayer = map.current.getLayer(mapLayerId);
          if (typeof mapLayer === 'undefined') {
            // Run asynchronously to prevent race codition error for cleaning resources while in use
            setTimeout(() => {
              // console.log("Invoking addDataLayer because map layer is undefined but map has already been loaded...");
              addDataLayer();
            });
          }

          setStylesLoaded(true);
        });

        // Create a popup, but don't add it to the map yet.
        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
          maxWidth: "350px",
          className: dataContext.darkMode ? "custom-map-tooltip-dark" : "custom-map-tooltip-light",
        });

        map.current.on('click', mapLayerIdCluster, (e) => {
          const features = map.current.queryRenderedFeatures(e.point, {
            layers: [mapLayerIdCluster]
          });
          const clusterId = features[0].properties.cluster_id;
          map.current.getSource(mapResourceName).getClusterExpansionZoom(
            clusterId,
            (err, zoom) => {
              if (err) return;

              map.current.easeTo({
                center: features[0].geometry.coordinates,
                zoom: zoom
              });
            }
          );
        });

        map.current.on("click", mapLayerId, (e) => {
          // Remove tooltip version of popup if it is showing
          if (popup) {
            popup.remove();
          }

          // Extract features
          const { properties } = e.features[0];
          let daylight = properties["daylight"] || "NULL";
          let daylightUpper = daylight.trim().toUpperCase();

          let fieldsOfInterest = {
            "Date": properties["start_timestamp"],
            "Duration (seconds)": properties["duration_seconds"],
            "Latitude": properties["start_latitude"],
            "Longitude": properties["start_longitude"],
            "MSL (ft)": properties["start_altitude"],
            "Speed (kts)": properties["start_speed"],
            // "Facility": properties["facility"],
            "Callsign": properties["callsign"] ? properties["callsign"] : "NULL",
            "Aircraft Type": properties["aircraft_type"] || "NULL",
            "Flight Rules": properties["start_flight_rules"],
            "ICAO Category": properties["icao_category"] || "NULL",
            "ICAO Sub-category": properties["icao_sub_category"] || "NULL",
            "Daylight": daylight,
            "Season": properties["season"] || "NULL",
          };

          // let color = getUncertaintyThemeColor(properties["latlon_uncertainty"]);
          let color = uimcDaylightTypeColorMap[daylightUpper] || "#C9C9C9"; // Default gray
          let tooltipHTML = buildFeaturedTooltip(fieldsOfInterest, { color: color });

          let clickablePopup = new mapboxgl.Popup({
            closeButton: false,
            // anchor: "top",
            maxWidth: "350px",
            className: dataContext.darkMode ? "custom-map-tooltip-dark" : "custom-map-tooltip-light",
          });

          clickablePopup.setLngLat(e.lngLat);
          clickablePopup.setHTML(tooltipHTML);
          clickablePopup.addTo(map.current);
        });

        map.current.on('mouseenter', mapLayerId, (e) => {

          // Change the cursor style as a UI indicator.
          map.current.getCanvas().style.cursor = 'pointer';

          // Extract features
          const { properties, geometry } = e.features[0];

          // Copy coordinates array.
          const coordinates = geometry.coordinates.slice();
          let daylight = properties["daylight"] || "NULL";
          let daylightUpper = daylight.trim().toUpperCase();

          let fieldsOfInterest = {
            "Date": properties["start_timestamp"],
            "Duration (seconds)": properties["duration_seconds"],
            "Latitude": properties["start_latitude"],
            "Longitude": properties["start_longitude"],
            "MSL (ft)": properties["start_altitude"],
            "Speed (kts)": properties["start_speed"],
            // "Facility": properties["facility"],
            "Callsign": properties["callsign"] ? properties["callsign"] : "NULL",
            "Aircraft Type": properties["aircraft_type"] || "NULL",
            "Flight Rules": properties["start_flight_rules"],
            "ICAO Category": properties["icao_category"] || "NULL",
            "ICAO Sub-category": properties["icao_sub_category"] || "NULL",
            "Daylight": daylight,
            "Season": properties["season"] || "NULL",
          };

          // let color = getUncertaintyThemeColor(properties["latlon_uncertainty"]);
          let color = uimcDaylightTypeColorMap[daylightUpper] || "#C9C9C9"; // Default gray
          let tooltipHTML = buildFeaturedTooltip(fieldsOfInterest, { color: color });

          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          // Populate the popup and set its coordinates
          // based on the feature found.
          popup.setLngLat(coordinates).setHTML(tooltipHTML).addTo(map.current);
        });

        map.current.on('mouseleave', mapLayerId, () => {
          map.current.getCanvas().style.cursor = '';
          popup.remove();
        });

        map.current.on('zoom', () => {
            handleLegends()
        } )

        // Condition is only satisfied when data is loaded and styles are applied
        // This means that layers should only be removed/added here if a filter is applied or removed after the initial map creation
        if (mapLoaded && stylesLoaded) {
          // console.log("Invoking addDataLayer since data is loaded and styles are applied...");
          addDataLayer();
        }
      }
    }

    // Unmount post-processing hook
    return () => {
      // TODO Cleanup objects as needed

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.baseData, dataContext.filteredData]);

  useEffect(() => {
    // Resize map to fix viewport scaling when parent container is resized
    if (map.current) {
      map.current.resize();
    }

  }, [resizeDetectorWidth, resizeDetectorHeight]);

  return (
    <div ref={resizeDetectorRef} style={mapParentStyle}>
      {dataContext.DEBUG_MODE && (
        <div className="mapbox-custom-sidebar">
          <div>
            Longitude: {lng} | Latitude: {lat} | Zoom: {zoomState}
          </div>
        </div>
      )}

      <div id={mapId} ref={mapContainer} style={mapStyles}>
        {legendDisplay && legend}
      </div>
    </div>

  )
}

UimcEventsMap.propTypes = propTypes;
UimcEventsMap.defaultProps = defaultProps;

export default UimcEventsMap;
