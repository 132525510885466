import React from 'react'
import DashboardNav from '../components/DashboardNav'
import AboutContent from '../components/AboutContent'

const About = () => {
  return (
    <>
    <DashboardNav />
    <AboutContent />
    </>
  )
}

export default About