import React, { Component } from 'react'
import DashboardNav from '../components/DashboardNav'
import Footer from '../components/PageFooter'
import getQuicksightDash from '../services/getFlightDash'
import getArrivalsDepartures from '../services/getArrivalsDepartures'
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk'
import UserContext from '../components/UserContext';
import { NavLink as Link, Redirect } from 'react-router-dom';

export default class ArrDepMetrics extends Component {

    state = {
        url: {},
        isLoaded: false,
        error: {},
        loadedAggregate: false,
    }
    static contextType = UserContext


    componentDidMount() {
        getArrivalsDepartures(this.context.user)
        .then((results) => {
            if ('EmbedUrl' in results) {
                this.setState({url: results.EmbedUrl});
                this.setState({isLoaded: true});
            }
        })
        .catch((error) => {
            this.setState({error: error});
        })
    }

    getQuicksightDashboardEmbedURL = (embedUrl) => {
        // console.log("Rendering Dashboard")
        const containerDiv = document.getElementById("dashboardContainer");

         //clear any existing embeds so we can embed a new one and not duplicate
         while (containerDiv.lastChild){
            // console.log("Remove a child comp")
            containerDiv.removeChild(containerDiv.lastChild)
        }

        const options = {
            url: embedUrl,
            container: containerDiv,
            // parameters: {
            //     flightid : "FODR::1538745771.0:1538751766.0"
            // },
            scrolling: "no",
            height: "AutoFit",
            // width: "960px",
            footerPaddingEnabled: true,
        };
    
      // console.log({options})
        const dashboard = QuickSightEmbedding.embedDashboard(options);
    }

    render() {

        if(this.state.isLoaded && !this.state.loadedAggregate) {
            this.getQuicksightDashboardEmbedURL(this.state.url)
            this.setState({loadedAggregate: true})
        }


        return (
            <div>
                <DashboardNav />
                <div className="stretch_grid-container">
                    <h1>Arrivals/Departures</h1>
                    Note: The Rotorcraft arrivals/departures metric is analyzed using FAA Threaded Track surveillance data, and a unified database of helipads/heliports from the National Flight Data Center (NFDC) and LZControl.  The metric also leverages the phases of flight algorithm from digital copilot to identify approach and departure flight segments then assigns the most likely heliport or “LZ” for off-site operations.
                <div id="dashboardContainer" ></div>

                    <Footer style={{paddingTop: '200px'}}/> 
                </div>
                
            </div>
        )
    }
}
