import React, { useState, useEffect, useContext } from 'react'
import {
    CardGroup,
    CardMedia,
    CardFooter,
    Card,
} from '@trussworks/react-uswds'
import UserContext from '../UserContext';
import getSystemAggs from '../../services/getSystemAggs';
import LoadingSkeleton from "../utils/LoadingSkeleton";

import './FlightHourCards.css'

function FlightHourCards(props) {
    const contextUser = useContext(UserContext)

    const hoursTitle = "Operator Flight Hours"
    const flightTitle = "Operator Total Flights"

    // const [aggregateHours, setAggregateHours] = useState();
    // const [aggreagateFlights, setAggregateFlights] = useState();
    const [operatorHours, setOperatorHours] = useState();
    const [operatorFlights, setOperatorFlights] = useState();

    const [isLoading, setIsLoading] = useState(true);



    useEffect(() => {
        getSystemAggs(contextUser.user)
            .then((result) => {
                for (const [value] of result.entries()) {
                    if (value['operator'] === "Aggregate") {
                        // Removed due to security concerns
                        // setAggregateHours(value['total_flight_hours'])
                        // setAggregateFlights(value['total_flights'])
                    }
                    else {
                        setOperatorHours(value['total_flight_hours'])
                        setOperatorFlights(value['total_flights'])
                    }
                }
                setIsLoading(false)
            })
            .catch((error) => {
                // console.log(error)
                props.setError(error)
            })
    }, [])


    return (
        <>
            {isLoading ?
                <LoadingSkeleton />
                :
                <>
                    <CardGroup key={hoursTitle + "cg"}>
                        <Card key={hoursTitle + "c"}>
                            <CardMedia key={hoursTitle + "cm"}>
                                <div key={hoursTitle + "d"} style={{ width: '100%', textAlign: 'center' }}>
                                    <h6>{hoursTitle}</h6>
                                </div>
                            </CardMedia>
                            <CardFooter key={hoursTitle + "cf"}>
                                <h4 style={{ marginBottom: '0px' }}>{operatorHours}</h4>
                            </CardFooter>
                        </Card>
                    </CardGroup>
                    <CardGroup key={flightTitle + "cg"}>
                        <Card key={flightTitle + "c"}>
                            <CardMedia key={flightTitle + "cm"}>
                                <div key={flightTitle + "d"} style={{ width: '100%', textAlign: 'center' }}>
                                    <h6>{flightTitle}</h6>
                                </div>
                            </CardMedia>
                            <CardFooter key={flightTitle + "cf"}>
                                <h4 style={{ marginBottom: '0px' }}>{operatorFlights}</h4>
                            </CardFooter>
                        </Card>
                    </CardGroup>
                </>
            }
        </>
    )
}

export default FlightHourCards