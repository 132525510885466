import React, { useState, useEffect, useRef, useContext } from "react";
import { DataContext } from "../../DataContext";
// import PropTypes from "prop-types";
import CanvasJSReact from '../../../canvasjs-commercial-3.4.12/canvasjs.react';
import Stack from '@mui/material/Stack';
import StyledSlider, { StyledThumbComponent } from '../../utils/StyledSlider';
import { useResizeDetector } from 'react-resize-detector';
import '../Chart.css';

// const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const propTypes = {};
const defaultProps = {};

// Control number of points (bars) that fit within viewport
const VISIBLE_POINTS_THRESHOLD = 5; // n points (bars) will be visibile within default viewport bounds
const VISIBLE_POINTS_PADDING = -0.5; // Magic number to offset begining viewport minimum value and display full point (bar)

function ObstacleProximityCountBarChart() {
  // Obtain reference to global data context consumer
  const dataContext = useContext(DataContext);
  const { width, height, ref: resizeDetectorRef } = useResizeDetector();

  // Keep reference of chart for event listeners/actions
  const chartRef = useRef(null);
  const chartContainerRef = useRef(null);
  const isMounted = useRef(false);

  // Determine if data exists
  const d = dataContext.filteredData ? dataContext.filteredData : dataContext.baseData;
  const dataExists = d && d.obstacles && d.obstacles.data && d.obstacles.data.length > 0;

  const [dataOfInterestExists, setDataOfInterestExists] = useState(true);
  const [minScrollValue, setMinScrollValue] = useState(0);
  const [maxScrollValue, setMaxScrollValue] = useState(0);
  const [currentScrollValue, setCurrentScrollValue] = useState(0);
  const [viewportMin, setViewportMin] = useState(0);
  const [viewportMax, setViewportMax] = useState(0);

  /**
   * Build and retrieve formatted HTML string representing a featured
   * tooltip given a set of values for a particular row of interest.
   * 
   * row = {
   *   "key_1": "value_1",
   *   "key_2": "value_2",
   *   ...
   *   "key_n": "value_n"
   * }
   * 
   * @param {object} row Fields of interest for current row in dataset.
   * @param {string} color Hexidecimal or RGB(A) color string.
   * @returns Formatted HTML string representing a featured tooltip.
   */
  const getFeaturedTooltip = (row, color = null) => {
    // Build tool tip off all properties
    var tooltipText = "";

    // Start table
    tooltipText += "<table>";
    for (const [key, value] of Object.entries(row)) {
      let iconData = `<td><i class="fa fa-square tooltip-icon-fonts" style="color: ${color}; margin-right: 5px;"></i></td>`;
      tooltipText += `
        <tr>
          ${color !== null && iconData}
          <td style="color: #989898"><b>${key}&nbsp</b></td>
          <td><b>${value}</b></td>
        </tr>
      `;
    }

    // End table
    tooltipText += "</table>";
    return tooltipText;
  }

  // See CanvasJS configuration options at https://canvasjs.com/docs/charts/basics-of-creating-html5-chart/
  // NOTE: axes are inverted for bar charts by default (e.g., horizontal bar chart)
  const [options, setOptions] = useState({
    zoomEnabled: false,
    animationEnabled: true,
    title: {
      text: ""
    },
    legend: {
      fontFamily: dataContext.CHART_FONTS.fontFamily,
      fontSize: dataContext.CHART_FONTS.fontSize,
      fontWeight: dataContext.CHART_FONTS.fontWeight,
    },
    theme: dataContext.darkMode ? "dark1" : "light1",
    toolTip: {
      enabled: true,          //disable here
      animationEnabled: true, //disable here
      contentFormatter: e => {
        var content = "";
        for (var i = 0; i < e.entries.length; i++) {
          let dataPoint = e.entries[i].dataPoint;
          content = `${content}${dataPoint.tooltipText}`;
        }

        return content;
      }
    },
    axisX: {
      labelFontFamily: dataContext.CHART_FONTS.labelFontFamily,
      labelFontWeight: dataContext.CHART_FONTS.labelFontWeight,
      // interval: 1, // Enforce showing all labels (drawback is this shows 1's if only one value is showing)
    },
    axisY: {
      gridThickness: 0.5,
      labelFontFamily: dataContext.CHART_FONTS.fontFamily,
      labelFontSize: dataContext.CHART_FONTS.fontSize,
      labelFontWeight: dataContext.CHART_FONTS.fontWeight,
      title: "",
      interval: 1,
    },
    dataPointMaxWidth: dataContext.MAX_BARCHART_DATAPOINT_WIDTH,
    // dataPointWidth: 100,
    // exportEnabled: true,
    data: [
      { type: "bar", dataPoints: [] }
    ]
  });

  /**
   * Get obstacle counts for each unique exceedance type and produced a 
   * consolidated object containing the rolled up counts, grouped by threat.
   * 
   * @param {object} dataTarget Base dataset or filtered dataset to extract obstacle counts from.
   * @returns Object containing obstacle counts grouped by threat.
   */
  const getObstacleCountsByThreat = (dataTarget) => {
    let grouping = {};
    let relevantCounts = {};

    let obstacleData = dataTarget.obstacles.data;
    let obstacleLookup = dataTarget.obstacles.lookup;

    // Get unique global counts for obstacle (using as a method for providing an entry for each unique obstacle type)
    let obstacle_counts = dataContext.groupByCount(obstacleData, obstacleLookup.type);
    // console.log("Obstacle Counts:", obstacle_counts);

    // Iterate 2D array and tally each occurrence of unique values (e.g., groupby + count)
    for (let row of obstacleData) {
      let obstacle = row[obstacleLookup.type];
      let threat = row[obstacleLookup.threat_class];

      if (threat && threat !== "None") {
        if (!(threat in grouping)) {
          grouping[threat] = { value: threat, count: 0 };

          // Build default values set for threat occurrance (charting requirement)
          grouping[threat]["obstacle_types"] = Object.keys(obstacle_counts).reduce(
            (prev, current) => {
              return current === "null" ? { ...prev } : {
                ...prev,
                [current]: { value: obstacle_counts[current].value, count: 0 }
              }
            }, {}
          );
        }

        // Make an entry for the current exceedance event in the threat slot if it doesn't exist yet
        if (obstacle) {
          // Increment total threat count
          grouping[threat].count++;

          if (!(obstacle in grouping[threat]["obstacle_types"])) {

            grouping[threat]["obstacle_types"][obstacle] = { value: obstacle, count: 0 };
          }

          // Increment total exceedance count for the threat
          grouping[threat]["obstacle_types"][obstacle].count++;
          relevantCounts[obstacle] = true;
        }
      }
    }

    // Prune out obstacles where there are no counts for any threat level to save space on rendered chart
    for (let threat of Object.keys(grouping)) {
      let reduced = Object.keys(grouping[threat].obstacle_types).reduce((prev, current) => {
        return current in relevantCounts ? { ...prev, [current]: { ...grouping[threat].obstacle_types[current] } } : { ...prev };
      }, {});

      grouping[threat].obstacle_types = reduced;
    }

    // console.log("Obstacle Proximity Counts Grouping:", grouping);
    // console.log("Relevant counts:", relevantCounts);

    return grouping;
  }

  /**
   * Handle new minimum and maxium count range and process accordingly.
   * 
   * @param {object} e Event object containing target value representing minimum and maximum count range pair.
   */
  const handleCountRangeChanged = (e) => {
    const { target: { value: newRange = 0, asDelta = false } } = e;
    let targetRange = asDelta ? currentScrollValue + newRange : newRange;
    let lowerBound = (targetRange - VISIBLE_POINTS_THRESHOLD) + VISIBLE_POINTS_PADDING;
    let upperBound = targetRange + VISIBLE_POINTS_PADDING;
    // console.log(`lowerBound = ${lowerBound}, upperBound = ${upperBound}, targetRange = ${targetRange}, asDelta = ${asDelta}`);

    setCurrentScrollValue(targetRange);
    setViewportMin(lowerBound);
    setViewportMax(upperBound);
  }

  /**
   * Drilldown on data on click event. Parse selection and pass forward to
   * data context to handle drilldown filter.
   * 
   * @param {object} e Event object passed from click listener on chart.
   */
  const handleDrilldown = (e) => {
    // Build data structure that imitates control filter
    // EXAMPLE:
    // {
    //   "phaseOfFlightSelect": {
    //     "id": "phaseOfFlightSelect",
    //     "label": "Standing, Hover Ground Effect",
    //     "column": "phaseofflight_mavg10",
    //     "values": [
    //       "standing",
    //       "hover ground effect"
    //      ]
    //    }
    // }
    const { label, type, threat } = e.dataPoint;

    let filter = {
      [dataContext.OBSTACLE_TYPE_SELECT]: {
        id: dataContext.OBSTACLE_TYPE_SELECT,
        label: label,
        column: "type",
        values: [type],
        track: true,
      },
      [dataContext.OBSTACLE_THREAT_SELECT]: {
        id: dataContext.OBSTACLE_THREAT_SELECT,
        label: dataContext.capitalizeWords(threat),
        column: "threat_class",
        values: [threat],
        track: true,
      }
    }

    chartRef.current.toolTip.hide(); // Hide tooltip to prevent errors
    dataContext.addControlFilter(filter);
  }

  // Component mount/unmount
  useEffect(() => {
    isMounted.current = true;

    // NOTE: identify document element by id on component mount to ensure canvas js chart wraps to parent container
    if (resizeDetectorRef.current) {
      let chartContainer = resizeDetectorRef.current.children[0]; // e.g., canvasjs-react-chart-container-2
      if (chartContainer) {
        // console.log("Setting width and height of parent canvasjs container...", chartContainer);
        chartContainer.style.height = "100%";
        chartContainer.style.width = "100%";

        // Because of the mui stack container, the chart container is nested one more layer deep.
        if (chartContainer.children && chartContainer.children.length > 0) {
          chartContainerRef.current = chartContainer.children[0];
          if (chartContainerRef.current.id.includes("canvasjs-react-chart-container")) {
            // console.log("Nested chart container:", chartContainerRef.current);
            chartContainerRef.current.style.height = "100%";
            chartContainerRef.current.style.width = "100%";
          }
        }
      }
    }

    return () => {
      isMounted.current = false;

      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }

      setOptions({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Detect changes to incomming/ingested data
  useEffect(() => {
    if (isMounted.current) {
      // Obtain reference to active data from context
      let dataTarget = dataContext.filteredData ? dataContext.filteredData : dataContext.baseData;
      let dataPointsList = [];
      let obstacleCount = 0;
      let obstacleCountPadded = 0;

      if (dataTarget && dataTarget.obstacles) {
        let counts = getObstacleCountsByThreat(dataTarget);

        // Dynamically build exceedance events data points by threat
        let yMax = 0;
        const { obstacleProximityColorMap } = dataContext.COLOR_MAPS.current;
        for (let threat of Object.keys(counts)) {
          let threatIdx = threat.toUpperCase().trim();
          let obstacle_types = counts[threat]["obstacle_types"];
          let color = threatIdx in obstacleProximityColorMap ? obstacleProximityColorMap[threatIdx] : "";

          let dataPoints = [];

          for (let item of Object.keys(obstacle_types)) {
            // let label = dataContext.capitalizeWords(item);
            let label = item.trim();
            let count = obstacle_types[item].count;
            let fieldsOfInterest = {
              "Obstacle": item,
              "Threat": threat,
              "Count": count.toLocaleString('en-US'),
            };

            let tooltipText = getFeaturedTooltip(fieldsOfInterest, color);

            // Track maximum y value for viewport scaling purposes
            if (count > yMax) {
              yMax = count;
            }

            dataPoints.push({
              y: obstacle_types[item].count,
              label: label,
              type: item,
              threat: threat,
              click: handleDrilldown,
              tooltipText: tooltipText,
            });
          }

          obstacleCount += dataPoints.length;

          dataPointsList.push({
            type: "bar",
            showInLegend: true,
            legendText: `${threatIdx === "NONE" ? "No" : threat} Threat`,
            color: color,
            // indexLabelFontSize: 25,
            dataPoints: dataPoints,
          });
        }

        // Divide exceedance count by total number of keys in data points list to account for space provided for each severity
        obstacleCountPadded = dataPointsList.length > 0 ? parseInt(obstacleCount / dataPointsList.length) : obstacleCount;

        // Compute lower bound and upper bound for viewport
        let lowerBound = (obstacleCountPadded - VISIBLE_POINTS_THRESHOLD) + VISIBLE_POINTS_PADDING;
        let upperBound = obstacleCountPadded + VISIBLE_POINTS_PADDING;

        // Create formatted data structure for canvasjs chart
        // See data series attributes at https://canvasjs.com/docs/charts/chart-options/data/
        setOptions({
          ...options,
          axisX: {
            ...options.axisX,
            // NOTE: Set viewport minimum relative to maximum count to ensure the first datapoint is visible within viewport bounds 
            // For example, if countMax is 100 and VISIBLE_POINTS_THRESHOLD is 10, then viewportMinimum will be 90
            viewportMinimum: obstacleCountPadded > VISIBLE_POINTS_THRESHOLD && lowerBound > 0 ? lowerBound : undefined,
            viewportMaximum: obstacleCountPadded > VISIBLE_POINTS_THRESHOLD ? upperBound : undefined,
            interval: obstacleCount > 4 ? 1 : undefined, // Remove numbers when potentially one exceedance type is showing
          },
          axisY: {
            ...options.axisY,
            minimum: 0,
            maximum: yMax,
          },
          data: dataPointsList,
        });
      }

      // Track min and max counts for slider widget control
      setMinScrollValue(0);
      setMaxScrollValue(obstacleCountPadded);
      setCurrentScrollValue(obstacleCountPadded);

      // Data points of interest exist when at least one data point exists in the list
      setDataOfInterestExists(dataPointsList.length > 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.baseData, dataContext.filteredData]);

  // Detect changes to viewport min/max state
  useEffect(() => {
    if (viewportMax === 0) return;
    // console.log(`Viewport bounds changed: [${viewportMin}, ${viewportMax}]`);
    setOptions({
      ...options,
      axisX: {
        ...options.axisX,
        // NOTE: Set viewport minimum relative to maximum count to ensure the first datapoint is visible within viewport bounds 
        // For example, if countMax is 100 and VISIBLE_POINTS_THRESHOLD is 10, then viewportMinimum will be 90
        viewportMinimum: viewportMin,
        viewportMaximum: viewportMax,
      }
    });
  }, [viewportMin, viewportMax]);

  useEffect(() => {
    if (isMounted.current) {
      // Re-render chart if there's a change in theme
      if (chartRef.current) {
        // NOTE: must do it this way since options are copied to chart reference (e.g., not bound by state/props)
        chartRef.current.options.theme = dataContext.darkMode ? "dark1" : "light1";
        chartRef.current.render();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.darkMode]);

  // Dynamically update width and height of chart to fill parent container on dimension change
  useEffect(() => {
    if (isMounted.current) {
      if (chartRef.current) {
        let yOffset = 20;
        let dh = height - yOffset;
        if (width > 0 && dh > 0) {
          chartRef.current.options.width = width;
          chartRef.current.options.height = dh;
          chartRef.current.render();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height]);

  useEffect(() => {
    if (!chartRef.current) return;
    if (!chartContainerRef.current) return;

    // Don't allow scrolling if the total number of visible points (bars) is less than or equal to the configured threshold
    if (maxScrollValue <= VISIBLE_POINTS_THRESHOLD) return;

    const handleMouseScroll = (e) => {
      e.preventDefault();
      const chart = chartRef.current;
      if (!chart) return;

      let axisX = chart.axisX[0];
      let viewportMin = axisX.get("viewportMinimum");
      let viewportMax = axisX.get("viewportMaximum");
      let interval = e.deltaY < 0 ? 1 : e.deltaY > 0 ? -1 : 0; // axisX.get("minimum")
      let newViewportMin, newViewportMax;

      // console.log(`Scroll changed: interval = ${interval}, currentScrollValue = ${currentScrollValue}`);

      // NOTE: Inverted behavior. Scrolling down results in positive delta y values
      if (interval !== 0) {
        newViewportMax = viewportMax + interval;
        newViewportMin = viewportMin + interval;
        setCurrentScrollValue(currentScrollValue + interval);
      }

      if (newViewportMin >= chart.axisX[0].get("minimum") && newViewportMax <= chart.axisX[0].get("maximum") && (newViewportMax - newViewportMin) > (2 * interval)) {
        chart.axisX[0].set("viewportMinimum", newViewportMin, false);
        chart.axisX[0].set("viewportMaximum", newViewportMax);
      }
    }

    chartContainerRef.current.addEventListener('wheel', handleMouseScroll);
    return () => chartContainerRef.current.removeEventListener("wheel", handleMouseScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentScrollValue, maxScrollValue]);

  return (
    <div ref={resizeDetectorRef} style={{ width: "100%", height: "100%" }}>
      {(dataExists && dataOfInterestExists) && (
        <Stack sx={{ width: "100%", height: "100%" }} spacing={1} direction="row">
          <CanvasJSChart options={options} onRef={ref => chartRef.current = ref} />
          <StyledSlider
            // NOTE: Create dynamic key to make this a controlled slider (prevents error on uncontrolled slider)
            key={`daylight-count-slider-${minScrollValue}-${maxScrollValue}`}
            components={{ Thumb: StyledThumbComponent }}
            sx={{ height: "75%", paddingLeft: 0 }}
            getAriaLabel={() => 'Daylight Count'}
            orientation="vertical"
            // getAriaValueText={valuetext}
            defaultValue={maxScrollValue}
            value={currentScrollValue}
            min={minScrollValue + VISIBLE_POINTS_THRESHOLD}
            max={maxScrollValue}
            valueLabelDisplay="off"
            track={false}
            disabled={maxScrollValue <= VISIBLE_POINTS_THRESHOLD}
            onChange={handleCountRangeChanged}
          // onChangeCommitted={handleCountRangeChanged} // More efficient for rendering performance (if needed)
          />
        </Stack>
      )}
      {(!dataExists) && (
        <div>
          <b>No High Threat, Medium Threat, or Low Threat Data</b>
        </div>
      )}
      {(dataExists && !dataOfInterestExists) && (
        <div>
          {/* Customize message as needed */}
          <b>No High Threat, Medium Threat, or Low Threat Data</b>
        </div>
      )}
    </div>
  );
}

ObstacleProximityCountBarChart.propTypes = propTypes;
ObstacleProximityCountBarChart.defaultProps = defaultProps;

export default ObstacleProximityCountBarChart;
