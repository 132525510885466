import React, { useContext, useEffect, useRef, useState } from 'react';
import { DataContext } from './DataContext';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Tooltip from '@mui/material/Tooltip';
import Popper from '@mui/material/Popper';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/Info';
import debounce from 'lodash/debounce';





const customPopper = (props) => {
  return <Popper {...props} style={styles.popper} placement="right-start" />;
};

const propTypes = {
  id: PropTypes.string.isRequired,
  how: PropTypes.string.isRequired,
  title: PropTypes.string,
  // groupByColumnName: PropTypes.string.isRequired,
  intervalMinimum: PropTypes.number,
  intervalMaximum: PropTypes.number,
  startingLower: PropTypes.number,
  startingUpper: PropTypes.number,
  intervalStep: PropTypes.number,
  intervalUnits: PropTypes.string,
  dataKey: PropTypes.string,
  onSelectionChange: PropTypes.func,
  sx: PropTypes.object,
  style: PropTypes.object,
  disableTooltip: PropTypes.bool,
  tooltipDelay: PropTypes.number,
  tooltipPlacement: PropTypes.oneOf([
    "left", "right", "top", "bottom", "bottom-end", "bottom-start",
    "left-end", "left-start", "right-end", "right-start", "top-end", "top-start"
  ]),
  limitTags: PropTypes.number,
  allowNulls: PropTypes.bool,

  /**
   * Optional filter column to filter by. 
   * Only applies filter if a value is provided and exists in the target dataset.
   */
  filterCol: PropTypes.string,

  /**
   * Individual or list of values to include as possible values in dropdown (e.g., whitelist). 
   * filterCol prop must be provided for filter val to be processed.
   * 
   * Case-insensitive.
   */
  filterVal: PropTypes.any,
};

const defaultProps = {
  title: "Control Label",
  dataKey: "track_points",
  onSelectionChange: _ => { },
  sx: {},
  style: {},
  disableTooltip: false,
  tooltipDelay: 1000,
  tooltipPlacement: "bottom",
  limitTags: 2,
  allowNulls: false,
  filterCol: null,
  filterVal: null,
  rangeMinValue: 0,
  rangeMaxValue: 1,
  existMinValue: .5,
  existMaxValue : 1,
};

const SELECT_ALL_OPTION_LABEL = "Select All";
const ALL_SELECTED_LABEL = "All Selected";
const NONE_SELECTED_LABEL = "None Selected (Null)";

const styles = {
  container: {
    width: 500,
    // height:250,
    maxHeight: 250,
    overflowY: "none",
    overflowX: "none",
    // margin: 5,
    padding: 5,
  }
};

const IntervalControlWidget = (props) => {
  const {
    id,
    how,
    title,
    groupByColumnName,
    dataKey,
    onSelectionChange,
    sx,
    style,
    disableTooltip,
    tooltipDelay,
    tooltipPlacement,
    limitTags,
    allowNulls,
    filterCol,
    filterVal,
    rangeMinValue,
    rangeMaxValue,
    existMaxValue,
    existMinValue
  } = props;

  const dataContext = useContext(DataContext);
  const groupedData = useRef([]);

  // const [selectedOptions, setSelectedOptions] = useState([]);
  const [minValue, setMinValue] = useState(rangeMinValue ? rangeMinValue : 0);
  const [maxValue, setMaxValue] = useState(rangeMaxValue ? rangeMaxValue : 100);
  const [value, setValue] = useState([existMinValue ? existMinValue : rangeMinValue, existMaxValue? existMaxValue : rangeMaxValue]);
  const [defaultResetValue, setDefaultResetValue] = useState([existMinValue ? existMinValue : rangeMinValue, existMaxValue? existMaxValue : rangeMaxValue]);
  // const allSelected = groupedData.current.length === selectedOptions.length;

  const setNullsToDefault = () => {
    // console.log(value)
    // console.log(value.length)
    if(value.length !== 2){
      setValue(defaultResetValue)
    }

    onSelectionChange({
      id: id,
      how: how,
      title: title,
      label: getCurrentLabel(),
      column: groupByColumnName,
      values: defaultResetValue,
      track: false,
      propagate: false
    });
  }

  const filterNullsIfNeeded = (data, allowed) => {
    let filteredData = data;

    if (!allowed) {
      filteredData = filteredData.filter(item => item !== null);
    }

    return filteredData;
  }

  const getCurrentLabel = () => {
    let label = value[0] + " - " + value[1];
    // if (selected && possible) {
    //   if (selected.length === possible.length) {
    //     label = ALL_SELECTED_LABEL;
    //   } else if (selected.length === 0) {
    //     label = NONE_SELECTED_LABEL;
    //   } else {
    //     label = selected.map(item => dataContext.capitalizeWords(item)).join(', ');
    //   }
    // }

    return label;
  }

  // const handleToggleOption = selectedOptions => setSelectedOptions(selectedOptions);
  // const handleClearOptions = () => setSelectedOptions([]);

  // const handleSelectAll = isSelected => {
  //   let newSelection = [];
  //   if (isSelected) {
  //     newSelection = groupedData.current;
  //     setSelectedOptions(newSelection);
  //   } else {
  //     handleClearOptions();
  //   }

    // console.log(`New Selection from Select All (${id}):`, newSelection);
  //   return newSelection;
  // };

  // const handleToggleSelectAll = () => {
  //   return handleSelectAll(!allSelected);
  // };

  const handleChange = (event, newValue) => {
    // console.log(newValue)
    // setValue(newValue);
    
    setNullsToDefault();
    
    onSelectionChange({
      id: id,
      how: how,
      title: title,
      label: getCurrentLabel(),
      column: groupByColumnName,
      values: newValue,
      track: true,
      propagate: true
    });
  };

  const handleChangeDebounce = debounce(handleChange, 300, {
    leading: false,
    trailing: true,
  }) 
  ;

  const handleChangeUsingStateDebounce = (event, newValue) => {
    setValue(newValue)
    handleChangeDebounce(event,  newValue)
  }

  

  // const handleChange = (event, selectedOptions, reason) => {
  //   let result = [];

  //   // console.log("Handling change:", reason);
  //   if (reason === "selectOption" || reason === "removeOption") {
  //     if (selectedOptions.find(option => option === SELECT_ALL_OPTION_LABEL)) {
  //       let toggledResult = handleToggleSelectAll();
  //       result = toggledResult.filter(el => el !== SELECT_ALL_OPTION_LABEL);
  //       // console.log("Toggled select all");
  //     } else {
  //       handleToggleOption(selectedOptions);
  //       result = selectedOptions;
  //       // console.log("Selection changed");
  //     }
  //   } else if (reason === "clear") {
  //     handleClearOptions && handleClearOptions();
  //     // console.log("Selection cleared.");
  //   }

  //   // console.log("|-- New selection:", result);

  //   let currentLabel = getCurrentLabel(result, groupedData.current);
  //   result = result.length === 0 ? [null] : result; // Set value to null if filter list is empty

  //   // Notify selection change to update current label in parent control component
    // onSelectionChange({
    //   id: id,
    //   title: title,
    //   label: currentLabel,
    //   column: groupByColumnName,
    //   values: result,
    //   track: true,
    //   propagate: true
    // });
  // };

  // const optionRenderer = (props, option, { selected }) => {
  //   // Control the state of 'Select All' checkbox
  //   const selectAllProps = option === SELECT_ALL_OPTION_LABEL ? { checked: allSelected } : {};
  //   const nullValueProps = option === null ? { checked: selectedOptions.includes(null) } : {};

  //   return (
  //     <li {...props}>
  //       <Checkbox
  //         color="primary"
  //         icon={icon}
  //         checkedIcon={checkedIcon}
  //         style={{ marginRight: 8 }}
  //         checked={selected}
  //         indeterminate={
  //           option === SELECT_ALL_OPTION_LABEL && selectedOptions.length > 0 && selectedOptions.length < groupedData.current.length
  //         }
  //         {...selectAllProps}
  //         {...nullValueProps}
  //       />
  //       {dataContext.capitalizeWords(option)}
  //     </li>
  //   );
  // };

  const inputRenderer = params => {
    const loadingPlaceholder = `Loading Options for ${title}...`;

    return (
      <TextField
        {...params}
        label={dataContext.processing ? loadingPlaceholder : `Search by ${title}`}
        placeholder={dataContext.processing ? loadingPlaceholder : title}
      />
    );
  };


  // Build possible values list (only load once the base data is loaded and the grouped dataset has been created)
  useEffect(() => {
    if (groupedData.current && groupedData.current.length > 0) return;

    if (dataContext.baseData) {
      // const { data: baseData, lookup: baseLookup } = dataContext.baseData[dataKey];
      // let filterConfig = {
      //   caseSensitive: false,
      //   filterIdx: filterCol ? baseLookup[filterCol] : null,
      //   filterValues: filterVal,
      // };

      // // console.log(`${id} Filter Config:`, filterConfig);

      // // groupedData.current = filterNullsIfNeeded(
      // //   dataContext.groupBy(baseData, baseLookup[groupByColumnName], filterConfig), allowNulls
      // // );

      // let availableSelection = groupedData.current;

      // // Check if filtered data exists and build a new selection list containing only which values are available
      // if (dataContext.filteredData) {
      //   const { data: filteredData, lookup: filteredLookup } = dataContext.filteredData[dataKey];
      //   filterConfig.filterIdx = filterCol ? filteredLookup[filterCol] : null;

      //   availableSelection = filterNullsIfNeeded(
      //     dataContext.groupBy(filteredData, filteredLookup[groupByColumnName], filterConfig), allowNulls
      //   );
      // }

      // Select only what should be selected
      // handleSelectAll(true);
      // setSelectedOptions(availableSelection);

      // console.log(`Initial values for ${id}:`);
      // console.log("|-- Possible Values", groupedData.current);
      // console.log("|-- Available Values", availableSelection);
      // console.log("|-- Label:", label);

      setNullsToDefault();
      onSelectionChange({
        id: id,
        how: how,
        title: title,
        label: getCurrentLabel(),
        column: groupByColumnName,
        values: value,
        track: false,
        propagate: false
      });

      // console.log(`Base possible values for ${id}`, groupedData.current);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.baseData, dataContext.filteredData]);

  // Set default fitlering once base data exists
  useEffect(() => {
    if (groupedData.current && groupedData.current.length > 0) return;

    if (dataContext.baseData) {
     
      setNullsToDefault();
      onSelectionChange({
        id: id,
        how: how,
        title: title,
        label: getCurrentLabel(value),
        column: groupByColumnName,
        values: value,
        track: false,
        default: true,
        propagate: true
      });

      // console.log(`Base possible values for ${id}`, groupedData.current);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.baseData]);

  // useEffect(() => {
  //   if (allSelected) return; // No need to reset if all possible values are already selected

  //   // Clear control filter selection if all filters have been reset from data context
  //   if (dataContext.allFiltersReset) {
  //     // console.log(`Resetting filters for ${id}...`);
  //     // Invoke filter reset handler for this control component and don't propagate changes to data context
  //     handleSelectAll(true);
  //     onSelectionChange({
  //       id: id,
  //       title: title,
  //       label: ALL_SELECTED_LABEL,
  //       column: groupByColumnName,
  //       values: groupedData.current,
  //       track: false,
  //       propagate: false
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dataContext.allFiltersReset]);

  // Listen for filter widget notifications for undo/redo/reset
  // NOTE: THIS SHOULD NOT BE RUNNING ON INITIAL COMPONENT MOUNT... ONLY AS A LISTENER FOR UNDO/REDO/RESET
  useEffect(() => {
    // console.log("Debug")
    if (dataContext.filterWidgetNotifierRef.current) {
      if (Object.keys(dataContext.filterWidgetNotifierRef.current).length > 0) {
        // Determine if the update is for this filter select widget instance
        if (id in dataContext.filterWidgetNotifierRef.current) {
          

          const { propagate = false, values } = dataContext.filterWidgetNotifierRef.current[id];
          
          // console.log(`Matches filter widget: ${title}`);
          
          let newSelectedVals = values;
         
          // This corner case only happens when the last of filters have been undone (no selected values is indicated with [null], not [])
          if (newSelectedVals.length === 0) {
            newSelectedVals = [existMinValue, existMaxValue]
          }

          let labelVals = newSelectedVals;

          // Customize list for labels
          // if (newSelectedVals.length === 1 && newSelectedVals[0] === null) {
          //   labelVals = [];
          // }

          dataContext.setSelectedFilters(id, newSelectedVals);

          // console.log(`Setting selected options from notifier for ${id}:`, labelVals);
          // console.log(labelVals)
          setValue(labelVals);

          // Compute label manually since state will not update before invoking on selection change (race condition issue)
          const label = getCurrentLabel(labelVals, groupedData.current);

          // Invoke props selection change callback to initialize filter selection (don't notify changes for filter tracking)
          // NOTE: Setting values to an empty list so that the updated selection isn't added in filters list again
          // console.log(`New selected values for ${id}`, newSelectedVals);
          // console.log(`Invoking selecton change for ${id} from notification listener (useEffect)...`);
          // console.log("|-- New values:", newSelectedVals);
          // console.log("|-- Label:", label);

          setNullsToDefault();
          onSelectionChange({
            id: id,
            how: how,
            title: title,
            label: label,
            column: groupByColumnName,
            values: newSelectedVals,
            track: false,
            propagate: propagate
          });
        } else {
          // Set selection to possible values (only visible if component is currently mounted)
          let availableSelection = groupedData.current;

          // Check if filtered data exists and build a new selection list containing only which values are available
          if (dataContext.filteredData) {
            const { data: filteredData, lookup: filteredLookup } = dataContext.filteredData[dataKey];

            let filterConfig = {
              caseSensitive: false,
              filterIdx: filterCol ? filteredLookup[filterCol] : null,
              filterValues: filterVal,
            };

            // console.log(`${id} Filter Config:`, filterConfig);

            // availableSelection = filterNullsIfNeeded(
            //   dataContext.groupBy(filteredData, filteredLookup[groupByColumnName], filterConfig), allowNulls
            // );
          }

          // Select only what should be selected
          // handleSelectAll(true);
          // setValue(availableSelection);

          // Compute label manually since state will not update before invoking on selection change (race condition issue)
          const label = getCurrentLabel(availableSelection, groupedData.current);

          // console.log(`Setting values for ${id} as part of the filter widget notifier but wasn't intended for it:`);
          // console.log("|-- Possible Values", groupedData.current);
          // console.log("|-- Available Values", availableSelection);
          // console.log("|-- Label:", label);
          // console.log("Debug 2")
          setNullsToDefault();
          onSelectionChange({
            id: id,
            how: how,
            title: title,
            label: label,
            column: groupByColumnName,
            values: availableSelection,
            track: false,
            propagate: false
          });
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.filterWidgetNotifierRef.current]);

  return (
    <>
      {/* {dataContext.processing && <div><b>Loading Data...</b></div>} */}
      
      {(dataContext.baseData && groupedData.current && !dataContext.processing) &&
        <>
        
        <Tooltip
          title="Confidence Score Range"
          placement={tooltipPlacement}
          enterDelay={tooltipDelay}
          disableFocusListener={disableTooltip}
          disableHoverListener={disableTooltip}
          disableInteractive={disableTooltip}
          disableTouchListener={disableTooltip}
        >
         
          <Box sx={{ ...sx }}
               style={{ ...styles.container, ...style }}>
                 <Tooltip
        title="A confidence score is output for each event for the probability of that event belonging to the same cluster as crossed-referenced NTSB UIMC events. This score is based on binary classification model trained on the clusters of detected events linked to NTSB reports"
        placement={tooltipPlacement}
        enterDelay={tooltipDelay}
        disableFocusListener={disableTooltip}
        disableHoverListener={disableTooltip}
        disableInteractive={disableTooltip}
        disableTouchListener={disableTooltip}>
        <InfoIcon
          fontSize='small'
          color='info'/>
        </Tooltip>
                Confidence Score Range: {value[0]} - {value[1]}
                
          <Slider
                  // getAriaLabel={() => 'Temperature range'}
                  disableSwap
                  value={value}
                  onChange={handleChangeUsingStateDebounce}
                  valueLabelDisplay="auto"
                  step={0.01}
                  min={rangeMinValue}
                  max={rangeMaxValue}
                  // getAriaValueText="aria text"
                />
                </Box>
          
        </Tooltip>
        </>
      }
    </>
  );
}

IntervalControlWidget.propTypes = propTypes;
IntervalControlWidget.defaultProps = defaultProps;

export default IntervalControlWidget;
