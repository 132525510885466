import React, { useState, useContext } from "react";
import { DataContext } from "../DataContext";
// import PropTypes from 'prop-types';
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { withSize } from "react-sizeme";
import DashboardWidget from "../DashboardWidget";
import LoadingSkeleton from "../utils/LoadingSkeleton";
import FlightIdBanner from "./FlightIdBanner";
import PhaseOfFlightControls from "../controls/PhaseOfFlightControls";
import PhaseOfFlightPieChart from "../charts/phaseofflight/PhaseOfFlightPieChart";
import PhaseOfFlightMap from "../maps/PhaseOfFlightMap";
import SingleValueMap from "../maps/SingleValueMap";
import BigNumber from "../misc/BigNumber";
import DataBrowser, { buildDataBrowserColumn } from "../misc/DataBrowser";
// import Toolbar from "../utils/Toolbar";
import { IconButton, Tooltip } from "@mui/material";
import AddList from "../AddList";
import SaveIcon from '@mui/icons-material/Save';
import DashboardIcon from '@mui/icons-material/Dashboard';

// Need to import styles for core functionality
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const propTypes = {};
const defaultProps = {};

const styles = {
  toolbarContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  toolbarBanner: {
    marginRight: "auto",
  },
};

function HazardContent({ size: { width } }) {
  const SAVED_LAYOUTS_KEY = "RiskLayout";

  const dataContext = useContext(DataContext);
  const flightMetricsDashboardConfig = dataContext.flightMetricsDashboardConfig[dataContext.HAZARD_TAB_KEY];

  const originalItems = Object.keys(flightMetricsDashboardConfig);

  const initialLayouts = {
    lg: originalItems.map(item => flightMetricsDashboardConfig[item])
  };

  const loadedLayouts = dataContext.getFromLS(SAVED_LAYOUTS_KEY) || initialLayouts;
  const [layouts, setLayouts] = useState(
    loadedLayouts
  );

  // Initial set of widgets will be based on saved layouts data (defaults to initial layouts if no saved data found)
  const [items, setItems] = useState(loadedLayouts.lg.map(item => item.i));

  const onLayoutChange = (_, allLayouts) => {
    setLayouts(allLayouts);
  };

  const onLayoutSave = (e) => {
    e.stopPropagation();
    dataContext.saveToLS(SAVED_LAYOUTS_KEY, layouts);

    // Show snackbar notification on save
    dataContext.showSnack("top", "center", "Dashboard Layout Saved for Hazard!", dataContext.SNACK_SEVERITY.SUCCESS, 2000);
  };

  const onLayoutReset = (e) => {
    e.stopPropagation();
    const newLoadedLayouts = dataContext.getFromLS(SAVED_LAYOUTS_KEY) || initialLayouts;
    setLayouts(newLoadedLayouts);
    setItems(newLoadedLayouts.lg.map(item => item.i));
    dataContext.showSnack("top", "center", "Dashboard Layout Reset to Last Saved State for Hazard!", dataContext.SNACK_SEVERITY.SUCCESS, 2000);
  }

  const onRemoveItem = (itemId) => {
    // console.log(`Removing ${itemId} from the dashboard...`);
    setItems(items.filter((i) => i !== itemId));
  };

  const onAddItem = (itemId) => {
    setItems([...items, itemId]);
  };

  // Handler Functions for Data Viewers
  const dateTimeHandler = (data) => {
    // return dataContext.toHumanReadableDateStr(new Date(data));
    return dataContext.toHumanReadableDateStr(data);
  }

  const roundingHandlerDefault = (data) => {
    return dataContext.roundStr(data);
  }

  const roundingHandlerLong = (data) => {
    return dataContext.roundStr(data, 6);
  }

  const humanReadableHandler = (data) => {
    return dataContext.capitalizeWords(data);
  }

  /**
   * Handle DataBrowser row selection event for drilling down on data record.
   * 
   * @param {string} key Key associated with target dataset for drilldown.
   * @param {any} value Value associated with key mapping in dataset for drilldown.
   */
   const handleRowSelected = (key, value) => {
    // Build data structure that imitates control filter
    // EXAMPLE:
    // {
    //   "phaseOfFlightSelect": {
    //     "id": "phaseOfFlightSelect",
    //     "label": "Standing, Hover Ground Effect",
    //     "column": "phaseofflight_mavg10",
    //     "values": [
    //       "standing",
    //       "hover ground effect"
    //      ]
    //    }
    // }    
    // console.log("Handling drilldown for selected key-value:", [key, value]);

    let filter = {
      [dataContext.HAZARD_SELECT_KEY]: {
        id: dataContext.HAZARD_SELECT_KEY,
        label: value,
        column: key,
        values: [value],
        track: true,
      }
    };

    dataContext.addControlFilter(filter);
  }

  // Core widgets list
  const componentList = {
      birdstrikeBigNumber:
      dataContext.processing ? <LoadingSkeleton /> :
        <BigNumber
          source="flight_meta"
          column="hazard_flight_score_bird"
          smallerFont={true}
          metric="first"
          subheader="Bird Strikes Hazard Score"
          subheaderSize="4"
          decimalPlaces={2}
          simplifyNumber={true}
        />,
        laserstrikeBigNumber:
      dataContext.processing ? <LoadingSkeleton /> :
        <BigNumber
          source="flight_meta"
          column="hazard_flight_score_laser"
          smallerFont={true}
          metric="first"
          subheader="Laser Strikes Hazard Score"
          subheaderSize="4"
          decimalPlaces={2}
          simplifyNumber={true}
        />,
        uasstrikeBigNumber:
      dataContext.processing ? <LoadingSkeleton /> :
        <BigNumber
          source="flight_meta"
          column="hazard_flight_score_uas"
          smallerFont={true}
          metric="first"
          subheader="UAS Strikes Hazard Score"
          subheaderSize="4"
          decimalPlaces={2}
          simplifyNumber={true}
        />,
      birdstrikeMap:
        dataContext.processing ? <LoadingSkeleton /> :
          <SingleValueMap
          mapId="birdstrike-map"
          zoomSensitivity={0.2}
          fieldOfInterest="hazard_score_bird"
        />,
      laserstrikeMap:
        dataContext.processing ? <LoadingSkeleton /> :
          <SingleValueMap
          mapId="laserstrike-map"
          zoomSensitivity={0.2}
          fieldOfInterest="hazard_score_laser"
        />,
      uasMap:
        dataContext.processing ? <LoadingSkeleton /> :
          <SingleValueMap
          mapId="dronestrike-map"
          zoomSensitivity={0.2}
          fieldOfInterest="hazard_score_uas"
        />,
  };

  return (
    <>
      {/* Dashboard tab management functions - right aligned if display is flex and content is justified as flex-end */}
      <div style={styles.toolbarContainer}>
        <FlightIdBanner style={styles.toolbarBanner} />
        <div>
          <IconButton aria-label="save" onClick={onLayoutSave}>
            <Tooltip title="Save Dashboard Tab Layout" placement="top" arrow>
              <SaveIcon />
            </Tooltip>
          </IconButton>

          <IconButton aria-label="reset" onClick={onLayoutReset}>
            <Tooltip title="Reset Dashboard Tab Layout to Last Saved State" placement="top" arrow>
              <DashboardIcon />
            </Tooltip>
          </IconButton>

          <AddList
            items={items}
            onRemoveItem={onRemoveItem}
            onAddItem={onAddItem}
            originalItems={originalItems}
            dashboardKey={dataContext.HAZARD_TAB_KEY}
            dashboardConfig={dataContext.flightMetricsDashboardConfig}
          />
        </div>
      </div>
      {/* <Toolbar
        onLayoutSave={onLayoutSave}
        AddListProps={{
          items: items,
          onRemoveItem: onRemoveItem,
          onAddItem: onAddItem,
          originalItems: originalItems,
          dashboardKey: dataContext.HAZARD_TAB_KEY
        }}
      /> */}

      {/* Add controls here as needed */}
      {/* <PhaseOfFlightControls /> */}

      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={60}
        width={width * (1 / dataContext.appScale)}
        draggableHandle=".drag-handle"
        onLayoutChange={onLayoutChange}
        transformScale={dataContext.appScale} // Required to adapt transform translation offset when dragging
      >
        {items.map((key) => (

          <div
            key={key}
            data-grid={{ w: 6, h: 8, x: 0, y: Infinity }}
          >
            <DashboardWidget
              id={key}
              name={flightMetricsDashboardConfig[key].name}
              className="drag-handle"
              onRemoveItem={onRemoveItem}
              fullscreenEnabled={false}
            >
              {componentList[key]}
            </DashboardWidget>
          </div>
        ))}
      </ResponsiveGridLayout>
    </>
  );
}

HazardContent.propTypes = propTypes;
HazardContent.defaultProps = defaultProps;

export default withSize({ refreshMode: "debounce", refreshRate: 60 })(HazardContent);
