import React from "react";
import { makeStyles } from '@mui/styles';
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import CloseIcon from '@mui/icons-material/Close';
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: "0.5rem"
  },
  spacer: {
    flexGrow: 1
  },
  body: {
    padding: "0.5rem",
    flexGrow: 1
  }
});

const styles = {
  dragHandle: {
    ':hover': {
      cursor: "all-scroll"
    }
  }
};

const propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  className: PropTypes.string.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  extras: PropTypes.object,
  fullscreenEnabled: PropTypes.bool,
  removeEnabled: PropTypes.bool,
  moveEnabled: PropTypes.bool,
};

const defaultProps = {
  name: "Untitled",
  fullscreenEnabled: true,
  removeEnabled: true,
  moveEnabled: true,
}

// Original props -> { id, className, onRemoveItem, component: Item }
function DashboardWidget({
  id,
  name,
  className,
  onRemoveItem,
  fullscreenEnabled,
  removeEnabled,
  moveEnabled,
  children
}) {
  const classes = useStyles();

  const handleRemoveItem = () => {
    onRemoveItem(id);
  }

  return (
    <Card className={classes.root}>
      <div className={`${classes.header}`}>
        <Typography 
          variant="subtitle1"
          sx={{ fontWeight: "bold" }}
          // gutterBottom
        >
          {name}
        </Typography>
        <div className={classes.spacer} />
        {fullscreenEnabled &&
          <IconButton aria-label="fullscreen" onClick={() => alert("Fullscreen functionality not yet implemented.")}>
            <FullscreenIcon fontSize="small" />
          </IconButton>
        }
     
        {/* NOTE: setting className to props.className is what makes this widget moveable/draggable (sets drag target from parent)*/}
        {moveEnabled &&
        <Tooltip title="Move Widget" placement="top" arrow aria-label={`Move ${name} widget`}>
          <DragIndicatorIcon className={`${className}`} fontSize="small" sx={styles.dragHandle} />
        </Tooltip>
        }
        
        {removeEnabled &&
        <IconButton aria-label={`Remove ${name} widget`} onClick={handleRemoveItem}>
          <Tooltip title="Remove Widget" placement="top" arrow>
            <CloseIcon fontSize="small" />
          </Tooltip>
        </IconButton>
        }

      </div>
      <div className={classes.body}>
        {/* <Item /> */}
        {/* 
        If there are any data integration issues then will need to refactor to pass props down through children
        See https://thewebdev.info/2021/09/18/how-to-pass-props-to-nested-child-components-in-react/ 
        */}
        {children}
      </div>
    </Card>
  );
}

DashboardWidget.propTypes = propTypes;
DashboardWidget.defaultProps = defaultProps;

export default DashboardWidget;
