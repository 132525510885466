import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

const propTypes = {  
  variant: PropTypes.string, 
  value: PropTypes.number,
  text: PropTypes.string,
  textStyle: PropTypes.object,
  style: PropTypes.object,
  sx: PropTypes.object,
};

const defaultProps = {
  variant: "determinate",
  value: 0,
  text: "",
  textStyle: { color: "#000" },
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 22,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    // backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    backgroundColor: "transparent",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const LabeledLinearProgress = (props) => {
  const { variant, value, text, textStyle, style, sx } = props;

  return (
    <div style={{ position: "relative" }}>
      <BorderLinearProgress
        variant={variant}
        value={value}
        style={style}
        sx={sx}
      />
      <Typography
        style={{
          position: "absolute",
          color: "#fff",
          top: 0,
          left: "10px",
          width: "100%",
          // left: "5%",
          // transform: "translateX(-50%)",
          ...textStyle,
        }}
      >
        {text}
      </Typography>
    </div>
  );
}

LabeledLinearProgress.propTypes = propTypes;
LabeledLinearProgress.defaultProps = defaultProps;

export default LabeledLinearProgress;
